import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, Button } from '@material-ui/core';

import hero6 from '../../../assets/images/hero-bg/hero-6.jpg';

// import MarketingHeaders1 from '../../MarketingHeaders/MarketingHeaders1';


import siteimg101 from '../../../assets/images/site-images/001-01.png';
import siteimg102 from '../../../assets/images/site-images/001-02.png';
import siteimg103 from '../../../assets/images/site-images/001-03.png';
import siteimg104 from '../../../assets/images/site-images/001-04.png';
import siteimg105 from '../../../assets/images/site-images/001-05.png';
import siteimg106 from '../../../assets/images/site-images/001-06.png';
import siteimg107 from '../../../assets/images/site-images/001-07.png';
// import siteimg108 from '../../../assets/images/site-images/001-08.png';
import siteimg109 from '../../../assets/images/site-images/001-09.png';
import siteimg110 from '../../../assets/images/site-images/001-10.png';
// import siteimg111 from '../../../assets/images/site-images/001-11.png';
// import siteimg112 from '../../../assets/images/site-images/001-12.png';
// import siteimg113 from '../../../assets/images/site-images/001-13.png';
import siteimg201 from '../../../assets/images/site-images/002-01.png';
import siteimg202 from '../../../assets/images/site-images/002-02.png';
// import siteimg203 from '../../../assets/images/site-images/002-03.png';
import siteimg204 from '../../../assets/images/site-images/002-04.png';
import siteimg205 from '../../../assets/images/site-images/002-05.png';
import siteimg206 from '../../../assets/images/site-images/002-06.png';
import siteimg207 from '../../../assets/images/site-images/002-07.png';
import siteimg208 from '../../../assets/images/site-images/002-08.png';
import siteimg209 from '../../../assets/images/site-images/002-09.png';
import siteimg210 from '../../../assets/images/site-images/002-10.png';
// import siteimg211 from '../../../assets/images/site-images/002-11.png';
// import siteimg212 from '../../../assets/images/site-images/002-12.png';
import siteimg213 from '../../../assets/images/site-images/002-13.png';
// import siteimg216 from '../../../assets/images/site-images/002-16.png';
// import siteimg217 from '../../../assets/images/site-images/002-17.png';
// import siteimg218 from '../../../assets/images/site-images/002-18.png';
// import siteimg220 from '../../../assets/images/site-images/002-20.png';
// import siteimg501 from '../../../assets/images/site-images/005-01.png';
// import siteimg502 from '../../../assets/images/site-images/005-02.png';
// import siteimg503 from '../../../assets/images/site-images/005-03.png';
// import siteimg505 from '../../../assets/images/site-images/005-05.png';
// import siteimg507 from '../../../assets/images/site-images/005-07.png';
// import siteimg509 from '../../../assets/images/site-images/005-09.png';
// import siteimg511 from '../../../assets/images/site-images/005-11.png';
// import siteimg513 from '../../../assets/images/site-images/005-13.png';
// import siteimg515 from '../../../assets/images/site-images/005-15.png';
// import siteimg517 from '../../../assets/images/site-images/005-17.png';
// import siteimg519 from '../../../assets/images/site-images/005-19.png';
// import siteimg521 from '../../../assets/images/site-images/005-21.png';
// import siteimg523 from '../../../assets/images/site-images/005-23.png';
// import siteimg525 from '../../../assets/images/site-images/005-25.png';
import siteimg00600a from '../../../assets/images/site-images/006-00a.png';
// import siteimg00600b from '../../../assets/images/site-images/006-00b.png';
// import siteimg601 from '../../../assets/images/site-images/006-01.png';
// import siteimg602 from '../../../assets/images/site-images/006-02.png';
// import siteimg603 from '../../../assets/images/site-images/006-03.png';
// import siteimg604 from '../../../assets/images/site-images/006-04.png';
// import siteimg605 from '../../../assets/images/site-images/006-05.png';
// import siteimg606 from '../../../assets/images/site-images/006-06.png';
// import siteimg607 from '../../../assets/images/site-images/006-07.png';
// import siteimg608 from '../../../assets/images/site-images/006-08.png';
// import siteimg609 from '../../../assets/images/site-images/006-09.png';
// import siteimg610 from '../../../assets/images/site-images/006-10.png';
// import siteimg611 from '../../../assets/images/site-images/006-11.png';
// import siteimg612 from '../../../assets/images/site-images/006-12.png';
// import siteimg613 from '../../../assets/images/site-images/006-13.png';
// import siteimg614 from '../../../assets/images/site-images/006-14.png';
// import siteimg615 from '../../../assets/images/site-images/006-15.png';
// import siteimg616 from '../../../assets/images/site-images/006-16.png';
// import siteimg617 from '../../../assets/images/site-images/006-17.png';
// import siteimg618 from '../../../assets/images/site-images/006-18.png';
// import siteimg621 from '../../../assets/images/site-images/006-21.png';
// import siteimg622 from '../../../assets/images/site-images/006-22.png';
// import siteimg623 from '../../../assets/images/site-images/006-23.png';
// import siteimg624 from '../../../assets/images/site-images/006-24.png';
// import siteimg625 from '../../../assets/images/site-images/006-25.png';
// import siteimg626 from '../../../assets/images/site-images/006-26.png';
// import siteimg00701a from '../../../assets/images/site-images/007-01a.png';
// import siteimg00701b from '../../../assets/images/site-images/007-01b.png';
// import siteimg702 from '../../../assets/images/site-images/007-02.png';
// import siteimg703 from '../../../assets/images/site-images/007-03.png';
// import siteimg704 from '../../../assets/images/site-images/007-04.png';
// import siteimg705 from '../../../assets/images/site-images/007-05.png';
// import siteimg706 from '../../../assets/images/site-images/007-06.png';
// import siteimg707 from '../../../assets/images/site-images/007-07.png';
// import siteimg708 from '../../../assets/images/site-images/007-08.png';
// import siteimg709 from '../../../assets/images/site-images/007-09.png';
// import siteimg710 from '../../../assets/images/site-images/007-10.png';
// import siteimg711 from '../../../assets/images/site-images/007-11.png';
// import siteimg712 from '../../../assets/images/site-images/007-12.png';
// import siteimg713 from '../../../assets/images/site-images/007-13.png';
// import siteimg714 from '../../../assets/images/site-images/007-14.png';
// import siteimg715 from '../../../assets/images/site-images/007-15.png';
// import siteimg716 from '../../../assets/images/site-images/007-16.png';
// import siteimg901 from '../../../assets/images/site-images/009-01.png';
// import siteimg902 from '../../../assets/images/site-images/009-02.png';
// import siteimg903 from '../../../assets/images/site-images/009-03.png';
// import siteimg905 from '../../../assets/images/site-images/009-05.png';
// import siteimg907 from '../../../assets/images/site-images/009-07.png';
// import siteimg909 from '../../../assets/images/site-images/009-09.png';
import siteimg911 from '../../../assets/images/site-images/009-11.png';
// import siteimg913 from '../../../assets/images/site-images/009-13.png';
// import siteimg915 from '../../../assets/images/site-images/009-15.png';
// import siteimg917 from '../../../assets/images/site-images/009-17.png';
import siteimg919 from '../../../assets/images/site-images/009-19.png';
// import siteimg921 from '../../../assets/images/site-images/009-21.png';
// import siteimg1102 from '../../../assets/images/site-images/011-02.png';
// import siteimg1103 from '../../../assets/images/site-images/011-03.png';
import siteimg1104 from '../../../assets/images/site-images/011-04.png';
// import siteimg1106 from '../../../assets/images/site-images/011-06.png';
// import siteimg1108 from '../../../assets/images/site-images/011-08.png';
// import siteimg1110 from '../../../assets/images/site-images/011-10.png';
// import siteimg1112 from '../../../assets/images/site-images/011-12.png';
// import siteimg1114 from '../../../assets/images/site-images/011-14.png';
// import siteimg1116 from '../../../assets/images/site-images/011-16.png';
// import siteimg1120 from '../../../assets/images/site-images/011-20.png';
import siteimg1301 from '../../../assets/images/site-images/013-01.png';
// import siteimg1302 from '../../../assets/images/site-images/013-02.png';
// import siteimg1304 from '../../../assets/images/site-images/013-04.png';
// import siteimg1308 from '../../../assets/images/site-images/013-08.png';
// import siteimg1310 from '../../../assets/images/site-images/013-10.png';
// import siteimg1312 from '../../../assets/images/site-images/013-12.png';
// import siteimg1314 from '../../../assets/images/site-images/013-14.png';
// import siteimg1315 from '../../../assets/images/site-images/013-15.png';
// import siteimg1316 from '../../../assets/images/site-images/013-16.png';
// import siteimg1317 from '../../../assets/images/site-images/013-17.png';




export default function LivePreviewExample() {
  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-deep-sky">
        {/* <Container className="header-top-section py-2">
          <MarketingHeaders1 />
        </Container> */}
        <div className="divider bg-white-5" />
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image"
            style={{ backgroundImage: 'url(' + hero6 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
          <div className="bg-composed-wrapper--content">
            <div className="py-5">
              <div className="pb-0 pb-lg-4">
                <Container className="text-white pb-5">
                  <Grid container spacing={6} className="py-5">
                    <Grid item lg={6} className="d-flex align-items-center">
                      <div className="text-center text-lg-left">
                        <h2 className="display-3 font-weight-bold text-white">
                          Web3Skills SmartLaw Achievements
                        </h2>
                        <p className="font-size-xl py-3 text-white">
                          Web3Skills SmartLaw is a training tool using
                          node-and-blockchain connected apps to advance the adoption of Web3
                          technologies. Users can freely browse and use Web3
                          applications anonymously or with decentralized
                          profiles that allow the user to benefit from publicly available sovereign data.
                        </p>
                        <div className="pt-3">
                          <Button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            size="large"
                            className="btn-pill shadow-second-sm btn-first bg-night-sky">
                            <span className="btn-wrapper--label">
                              View Achievements
                            </span>
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </span>
                          </Button>
                          <Button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            size="large"
                            className="btn-primary bg-night-skyer btn-pill ml-3">
                            <span>Earn Achievements</span>
                          </Button>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      className="d-flex align-items-center mt-5 mt-lg-0 justify-content-center">
                      <div className="object-skew d-flex flex-wrap align-items-center justify-content-center">
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg101}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg101"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg102}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg102"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg103}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg103"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-xl btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg104}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg104"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg105}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg105"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg106}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg106"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg107}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg107"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg109}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg109"
                            />
                          </div>
                        </div>


                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg110}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg110"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg201}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg201"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg202}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg202"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg204}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg204"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg205}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg205"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg206}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg206"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg207}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg207"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg208}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg208"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg209}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg209"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg210}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg210"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg00600a}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg00600a"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg911}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg212"
                            />
                          </div>
                        </div>


                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg213}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg213"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg919}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg919"
                            />
                          </div>
                        </div>

                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg1104}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg1104"
                            />
                          </div>
                        </div>


                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src={siteimg1301}
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="siteimg1301"
                            />
                          </div>
                        </div>


                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>

            <div className="shape-container-top-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="var(--light)"
                  fillOpacity="1"
                  d="M0,64L48,64C96,64,192,64,288,80C384,96,480,128,576,122.7C672,117,768,75,864,101.3C960,128,1056,224,1152,224C1248,224,1344,128,1392,80L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
