import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Button } from '@material-ui/core';

import {
  // CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';


import avatar1 from '../../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../../assets/images/avatars/avatar2.jpg';

import avatar4 from '../../../assets/images/avatars/avatar4.jpg';
import avatar5 from '../../../assets/images/avatars/avatar5.jpg';
import avatar6 from '../../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../../assets/images/avatars/avatar7.jpg';

export default function LivePreviewExample() {
  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item md={4} lg={12} xl={4}>
            <Card className="card-box">
              <div className="text-center py-3">
                <div className="d-60 rounded-circle border-0 my-2 card-icon-wrapper bg-plum-plate text-white btn-icon mx-auto text-center">
                  <FontAwesomeIcon
                    icon={['fas', 'user-check']}
                    className="font-size-xxl"
                  />
                </div>
                <div className="font-size-xl font-weight-bold pt-2 text-black">
                  Achievement Holders
                </div>
                <div className="opacity-5 pb-3">Achievement Tokens are non-transferable.</div>
                <div className="d-flex justify-content-center">
                  <div className="avatar-wrapper-overlap avatar-wrapper-overlap-hover my-2">
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar1} />
                      </div>
                    </div>
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar4} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider mx-auto w-50 my-3" />
                <div className="text-center">
                  <Button
                    className="p-0 d-30 mx-1 btn-transition-none border-0 btn-outline-dark"
                    variant="outlined">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['far', 'eye']}
                        className="font-size-sm"
                      />
                    </span>
                  </Button>
                  <Button
                    className="p-0 d-30 mx-1 btn-transition-none border-0 btn-outline-dark"
                    variant="outlined">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['far', 'heart']}
                        className="font-size-sm"
                      />
                    </span>
                  </Button>
                  <Button
                    className="p-0 d-30 mx-1 btn-transition-none border-0 btn-outline-dark"
                    variant="outlined">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['fas', 'user']}
                        className="font-size-sm"
                      />
                    </span>
                  </Button>
                </div>
              </div>
              <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
                <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-2">
                  <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                    Active Achievement Holders
                  </h6>
                </div>
                <div className="mx-auto">
                  <CircularProgressbarWithChildren
                    circleRatio={0.75}
                    styles={buildStyles({ rotation: 1 / 2 + 1 / 8 })}
                    value={15}
                    strokeWidth={7}
                    className="m-3 circular-progress-xxl circular-progress-primary">
                    <div className="badge badge-primary p-3 badge-pill h-auto font-weight-normal font-size-lg">
                      150
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className="px-4 pb-4 pt-2">
                  <Button
                    fullWidth
                    className="btn-neutral-dark font-weight-bold text-uppercase font-size-sm hover-scale-sm">
                    View all Token Holders
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={4} lg={12} xl={4}>
            <Card className="card-box">
              <div className="text-center py-3">
                <div className="d-60 rounded-circle border-0 my-2 card-icon-wrapper bg-plum-plate text-white btn-icon mx-auto text-center">
                  <FontAwesomeIcon
                    icon={['fas', 'user-shield']}
                    className="font-size-xl"
                  />
                </div>
                <div className="font-size-xl font-weight-bold pt-2 text-black">
                  Access Token Holders
                </div>
                <div className="opacity-5 pb-3">Access Tokens are transferable.</div>
                <div className="d-flex justify-content-center">
                  <div className="avatar-wrapper-overlap avatar-wrapper-overlap-hover my-2">
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar5} />
                      </div>
                    </div>
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar7} />
                      </div>
                    </div>
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar6} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider mx-auto w-50 my-3" />
                <div className="text-center">
                  <Button
                    className="p-0 d-30 mx-1 btn-transition-none border-0 btn-outline-dark"
                    variant="outlined">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['far', 'eye']}
                        className="font-size-sm"
                      />
                    </span>
                  </Button>
                  <Button
                    className="p-0 d-30 mx-1 btn-transition-none border-0 btn-outline-dark"
                    variant="outlined">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['far', 'heart']}
                        className="font-size-sm"
                      />
                    </span>
                  </Button>
                  <Button
                    className="p-0 d-30 mx-1 btn-transition-none border-0 btn-outline-dark"
                    variant="outlined">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['fas', 'user']}
                        className="font-size-sm"
                      />
                    </span>
                  </Button>
                </div>
              </div>
              <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
                <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-2">
                  <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                    Active Access Tokens
                  </h6>
                </div>
                <div className="mx-auto">
                  <CircularProgressbarWithChildren
                    circleRatio={0.75}
                    styles={buildStyles({ rotation: 1 / 2 + 1 / 8 })}
                    value={65}
                    strokeWidth={7}
                    className="m-3 circular-progress-xxl circular-progress-primary">
                    <div className="badge badge-primary p-3 badge-pill h-auto font-weight-normal font-size-lg">
                      650
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className="px-4 pb-4 pt-2">
                  <Button
                    fullWidth
                    className="btn-neutral-dark font-weight-bold text-uppercase font-size-sm hover-scale-sm">
                    View all Token Holders
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={4} lg={12} xl={4}>
            <Card className="card-box">
              <div className="text-center py-3">
                <div className="d-60 rounded-circle border-0 my-2 card-icon-wrapper bg-happy-fisher text-white btn-icon mx-auto text-center">
                  <FontAwesomeIcon
                    icon={['fas', 'users']}
                    className="font-size-xl"
                  />
                </div>
                <div className="font-size-xl font-weight-bold pt-2 text-black">
                  Current Course Supervisors
                </div>
                <div className="opacity-5 pb-3">Web3Skills SmartLaw Supervisors</div>
                <div className="d-flex justify-content-center">
                  <div className="avatar-wrapper-overlap avatar-wrapper-overlap-hover my-2">
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar7} />
                      </div>
                    </div>
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar6} />
                      </div>
                    </div>
                    <div className="avatar-icon-wrapper avatar-icon">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar1} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider mx-auto w-50 my-1" />
              </div>
              <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
                <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-2">
                  <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                    Web3Skills SmartLaw Active Courses
                  </h6>
                </div>
                <div className="mx-auto">
                  <CircularProgressbarWithChildren
                    value={65}
                    strokeWidth={7}
                    className="m-3 circular-progress-xl circular-progress-first">
                    <span className="text-first font-size-3xl font-weight-normal">
                      65
                    </span>
                  </CircularProgressbarWithChildren>
                </div>
                <p className="mb-3 text-black-50 text-center">
                  Top Web3Skills SmartLaw courses
                </p>
                <div className="px-5 mt-2">
                  <Grid
                    container
                    spacing={6}
                    className="text-black-50 font-size-sm">
                    <Grid item md={6} className="d-flex justify-content-center">
                      <div>
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-primary badge-circle mr-2">
                            newb
                          </div>
                          <span className="d-20 w-auto">Newb Course</span>
                        </div>
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-primary badge-circle mr-2">
                            web3user
                          </div>
                          <span className="d-20 w-auto">Web3 User</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={6} className="d-flex justify-content-center">
                      <div>
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-primary badge-circle mr-2">
                            acctmaker
                          </div>
                          <span className="d-20 w-auto">AccountMaker</span>
                        </div>
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-primary badge-circle mr-2">
                            decentralizer
                          </div>
                          <span className="d-20 w-auto">Decentrailzer</span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="p-4">
                  <Button
                    fullWidth
                    className="btn-neutral-dark font-weight-bold text-uppercase font-size-sm hover-scale-sm">
                    View Courses
                  </Button>
                </div>
              </div>

            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
