import React from 'react';

import { Grid, Container, Card, Button } from '@material-ui/core';

// import svgImage1 from '../../../assets/images/illustrations/business_plan.svg';
// import svgImage13 from '../../../assets/images/illustrations/businesswoman.svg';
// import svgImage14 from '../../../assets/images/illustrations/powerful.svg';
import svgImage15 from '../../../assets/images/illustrations/handshake.svg';
import svgImage16 from '../../../assets/images/illustrations/wireframe.svg';
import svgImage17 from '../../../assets/images/illustrations/time.svg';



export default function LivePreviewExample() {
  return (
    <>
      <Card className="mb-spacing-6-x2">
        <Container className="py-5">
          <Grid container spacing={6}>
            <Grid item lg={4}>
              <div className="feature-box text-center">
                <img
                  src={svgImage15}
                  style={{ height: 200 }}
                  className="mx-auto d-block img-fluid"
                  alt="..."
                />
                <h3 className="font-size-xl font-weight-bold my-3">MetaCollab Gigs</h3>
                <p className="text-black-50 mb-3">
                MetaCollab any of the 5+ live previews we&#39;ve set up to learn why
                  this dashboard template is the last one you&#39;ll ever need!
                </p>
                <Button
                  href="https://promo.metacollab.freeweb3.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-first"
                  size="small"
                  title="Learn more">
                  <span>Learn more</span>
                </Button>
              </div>
            </Grid>
            <Grid item lg={4}>
              <div className="feature-box text-center">
                <img
                  src={svgImage16}
                  style={{ height: 200 }}
                  className="mx-auto d-block img-fluid"
                  alt="..."
                />
                <h3 className="font-size-xl font-weight-bold my-3">
                  Claim Management
                </h3>
                <p className="text-black-50 mb-3">
                Claim Management any of the 5+ live previews we&#39;ve set up to learn why
                  this dashboard template is the last one you&#39;ll ever need!
                </p>
                <Button
                  href="https://tokens.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-first"
                  size="small"
                  title="Learn more">
                  <span>Learn more</span>
                </Button>
              </div>
            </Grid>
            <Grid item lg={4}>
              <div className="feature-box text-center">
                <img
                  src={svgImage17}
                  style={{ height: 200 }}
                  className="mx-auto d-block img-fluid"
                  alt="..."
                />
                <h3 className="font-size-xl font-weight-bold my-3">Dispute Resolution</h3>
                <p className="text-black-50 mb-3">
                Dispute Resolution any of the 5+ live previews we&#39;ve set up to learn why
                  this dashboard template is the last one you&#39;ll ever need!
                </p>
                <Button
                  href="https://adr.freeweb3.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-first"
                  size="small"
                  title="Learn more">
                  <span>Learn more</span>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Card>
    </>
  );
}
