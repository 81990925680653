import React from 'react';

import { Container, Card } from '@material-ui/core';

import folderimg101 from '../../../assets/images/folder-images/001-01.png';
import folderimg102 from '../../../assets/images/folder-images/001-02.png';
import folderimg103 from '../../../assets/images/folder-images/001-03.png';
import folderimg104 from '../../../assets/images/folder-images/001-04.png';
import folderimg105 from '../../../assets/images/folder-images/001-05.png';
import folderimg106 from '../../../assets/images/folder-images/001-06.png';
import folderimg107 from '../../../assets/images/folder-images/001-07.png';
import folderimg108 from '../../../assets/images/folder-images/001-08.png';
import folderimg109 from '../../../assets/images/folder-images/001-09.png';
import folderimg110 from '../../../assets/images/folder-images/001-10.png';
// import folderimg111 from '../../../assets/images/folder-images/001-11.png';
// import folderimg112 from '../../../assets/images/folder-images/001-12.png';
// import folderimg113 from '../../../assets/images/folder-images/001-13.png';
import folderimg201 from '../../../assets/images/folder-images/002-01.png';
import folderimg202 from '../../../assets/images/folder-images/002-02.png';
import folderimg203 from '../../../assets/images/folder-images/002-03.png';
import folderimg204 from '../../../assets/images/folder-images/002-04.png';
import folderimg205 from '../../../assets/images/folder-images/002-05.png';
import folderimg206 from '../../../assets/images/folder-images/002-06.png';
import folderimg207 from '../../../assets/images/folder-images/002-07.png';
import folderimg208 from '../../../assets/images/folder-images/002-08.png';
import folderimg209 from '../../../assets/images/folder-images/002-09.png';
import folderimg210 from '../../../assets/images/folder-images/002-10.png';
import folderimg211 from '../../../assets/images/folder-images/002-11.png';
import folderimg212 from '../../../assets/images/folder-images/002-12.png';
import folderimg213 from '../../../assets/images/folder-images/002-13.png';
import folderimg216 from '../../../assets/images/folder-images/002-16.png';
import folderimg217 from '../../../assets/images/folder-images/002-17.png';
import folderimg218 from '../../../assets/images/folder-images/002-18.png';
import folderimg220 from '../../../assets/images/folder-images/002-20.png';
import folderimg5011 from '../../../assets/images/folder-images/005-011.png';
import folderimg5013 from '../../../assets/images/folder-images/005-013.png';
import folderimg5015 from '../../../assets/images/folder-images/005-015.png';
import folderimg5017 from '../../../assets/images/folder-images/005-017.png';
import folderimg5019 from '../../../assets/images/folder-images/005-019.png';
import folderimg501 from '../../../assets/images/folder-images/005-01.png';
import folderimg5021 from '../../../assets/images/folder-images/005-021.png';
import folderimg5023 from '../../../assets/images/folder-images/005-023.png';
import folderimg5025 from '../../../assets/images/folder-images/005-025.png';
import folderimg502 from '../../../assets/images/folder-images/005-02.png';
import folderimg503 from '../../../assets/images/folder-images/005-03.png';
import folderimg505 from '../../../assets/images/folder-images/005-05.png';
import folderimg507 from '../../../assets/images/folder-images/005-07.png';
import folderimg509 from '../../../assets/images/folder-images/005-09.png';
import folderimg6000 from '../../../assets/images/folder-images/006-000.png';
import folderimg00600b from '../../../assets/images/folder-images/006-00b.png';
import folderimg601 from '../../../assets/images/folder-images/006-01.png';
import folderimg602 from '../../../assets/images/folder-images/006-02.png';
import folderimg603 from '../../../assets/images/folder-images/006-03.png';
import folderimg604 from '../../../assets/images/folder-images/006-04.png';
import folderimg605 from '../../../assets/images/folder-images/006-05.png';
import folderimg606 from '../../../assets/images/folder-images/006-06.png';
import folderimg607 from '../../../assets/images/folder-images/006-07.png';
import folderimg608 from '../../../assets/images/folder-images/006-08.png';
import folderimg609 from '../../../assets/images/folder-images/006-09.png';
import folderimg610 from '../../../assets/images/folder-images/006-10.png';
import folderimg611 from '../../../assets/images/folder-images/006-11.png';
import folderimg612 from '../../../assets/images/folder-images/006-12.png';
import folderimg613 from '../../../assets/images/folder-images/006-13.png';
import folderimg614 from '../../../assets/images/folder-images/006-14.png';
import folderimg615 from '../../../assets/images/folder-images/006-15.png';
import folderimg616 from '../../../assets/images/folder-images/006-16.png';
import folderimg617 from '../../../assets/images/folder-images/006-17.png';
import folderimg618 from '../../../assets/images/folder-images/006-18.png';
import folderimg621 from '../../../assets/images/folder-images/006-21.png';
import folderimg622 from '../../../assets/images/folder-images/006-22.png';
import folderimg623 from '../../../assets/images/folder-images/006-23.png';
import folderimg624 from '../../../assets/images/folder-images/006-24.png';
import folderimg625 from '../../../assets/images/folder-images/006-25.png';
import folderimg626 from '../../../assets/images/folder-images/006-26.png';
import folderimg00701a from '../../../assets/images/folder-images/007-01a.png';
// import folderimg00701b from '../../../assets/images/folder-images/007-01b.png';
import folderimg702 from '../../../assets/images/folder-images/007-02.png';
import folderimg703 from '../../../assets/images/folder-images/007-03.png';
import folderimg704 from '../../../assets/images/folder-images/007-04.png';
import folderimg705 from '../../../assets/images/folder-images/007-05.png';
import folderimg706 from '../../../assets/images/folder-images/007-06.png';
import folderimg707 from '../../../assets/images/folder-images/007-07.png';
import folderimg708 from '../../../assets/images/folder-images/007-08.png';
import folderimg709 from '../../../assets/images/folder-images/007-09.png';
import folderimg710 from '../../../assets/images/folder-images/007-10.png';
import folderimg711 from '../../../assets/images/folder-images/007-11.png';
import folderimg712 from '../../../assets/images/folder-images/007-12.png';
import folderimg713 from '../../../assets/images/folder-images/007-13.png';
import folderimg714 from '../../../assets/images/folder-images/007-14.png';
import folderimg715 from '../../../assets/images/folder-images/007-15.png';
import folderimg716 from '../../../assets/images/folder-images/007-16.png';
import folderimg901 from '../../../assets/images/folder-images/009-01.png';
import folderimg902 from '../../../assets/images/folder-images/009-02.png';
import folderimg903 from '../../../assets/images/folder-images/009-03.png';
import folderimg905 from '../../../assets/images/folder-images/009-05.png';
import folderimg907 from '../../../assets/images/folder-images/009-07.png';
import folderimg909 from '../../../assets/images/folder-images/009-09.png';
import folderimg911 from '../../../assets/images/folder-images/009-11.png';
import folderimg913 from '../../../assets/images/folder-images/009-13.png';
import folderimg915 from '../../../assets/images/folder-images/009-15.png';
import folderimg917 from '../../../assets/images/folder-images/009-17.png';
import folderimg919 from '../../../assets/images/folder-images/009-19.png';
import folderimg921 from '../../../assets/images/folder-images/009-21.png';
import folderimg1102 from '../../../assets/images/folder-images/011-02.png';
import folderimg1103 from '../../../assets/images/folder-images/011-03.png';
import folderimg1104 from '../../../assets/images/folder-images/011-04.png';
import folderimg1106 from '../../../assets/images/folder-images/011-06.png';
import folderimg1108 from '../../../assets/images/folder-images/011-08.png';
import folderimg1110 from '../../../assets/images/folder-images/011-10.png';
import folderimg1112 from '../../../assets/images/folder-images/011-12.png';
import folderimg1114 from '../../../assets/images/folder-images/011-14.png';
import folderimg1116 from '../../../assets/images/folder-images/011-16.png';
import folderimg1120 from '../../../assets/images/folder-images/011-20.png';
import folderimg1301 from '../../../assets/images/folder-images/013-01.png';
import folderimg1302 from '../../../assets/images/folder-images/013-02.png';
import folderimg1304 from '../../../assets/images/folder-images/013-04.png';
import folderimg1308 from '../../../assets/images/folder-images/013-08.png';
import folderimg1310 from '../../../assets/images/folder-images/013-10.png';
import folderimg1312 from '../../../assets/images/folder-images/013-12.png';
import folderimg1314 from '../../../assets/images/folder-images/013-14.png';
import folderimg1315 from '../../../assets/images/folder-images/013-15.png';
import folderimg1316 from '../../../assets/images/folder-images/013-16.png';
import folderimg1317 from '../../../assets/images/folder-images/013-17.png';



// import siteimg101 from '../../../assets/images/site-images/001-01.png';
// import siteimg102 from '../../../assets/images/site-images/001-02.png';
// import siteimg103 from '../../../assets/images/site-images/001-03.png';
// import siteimg104 from '../../../assets/images/site-images/001-04.png';
// import siteimg105 from '../../../assets/images/site-images/001-05.png';
// import siteimg106 from '../../../assets/images/site-images/001-06.png';
// import siteimg107 from '../../../assets/images/site-images/001-07.png';
// import siteimg108 from '../../../assets/images/site-images/001-08.png';
// import siteimg109 from '../../../assets/images/site-images/001-09.png';
// import siteimg110 from '../../../assets/images/site-images/001-10.png';
// import siteimg111 from '../../../assets/images/site-images/001-11.png';
// import siteimg112 from '../../../assets/images/site-images/001-12.png';
// import siteimg113 from '../../../assets/images/site-images/001-13.png';
// import siteimg201 from '../../../assets/images/site-images/002-01.png';
// import siteimg202 from '../../../assets/images/site-images/002-02.png';
// import siteimg203 from '../../../assets/images/site-images/002-03.png';
// import siteimg204 from '../../../assets/images/site-images/002-04.png';
// import siteimg205 from '../../../assets/images/site-images/002-05.png';
// import siteimg206 from '../../../assets/images/site-images/002-06.png';
// import siteimg207 from '../../../assets/images/site-images/002-07.png';
// import siteimg208 from '../../../assets/images/site-images/002-08.png';
// import siteimg209 from '../../../assets/images/site-images/002-09.png';
// import siteimg210 from '../../../assets/images/site-images/002-10.png';
// import siteimg211 from '../../../assets/images/site-images/002-11.png';
// import siteimg212 from '../../../assets/images/site-images/002-12.png';
// import siteimg213 from '../../../assets/images/site-images/002-13.png';
// import siteimg216 from '../../../assets/images/site-images/002-16.png';
// import siteimg217 from '../../../assets/images/site-images/002-17.png';
// import siteimg218 from '../../../assets/images/site-images/002-18.png';
// import siteimg220 from '../../../assets/images/site-images/002-20.png';
// import siteimg501 from '../../../assets/images/site-images/005-01.png';
// import siteimg502 from '../../../assets/images/site-images/005-02.png';
// import siteimg503 from '../../../assets/images/site-images/005-03.png';
// import siteimg505 from '../../../assets/images/site-images/005-05.png';
// import siteimg507 from '../../../assets/images/site-images/005-07.png';
// import siteimg509 from '../../../assets/images/site-images/005-09.png';
// import siteimg511 from '../../../assets/images/site-images/005-11.png';
// import siteimg513 from '../../../assets/images/site-images/005-13.png';
// import siteimg515 from '../../../assets/images/site-images/005-15.png';
// import siteimg517 from '../../../assets/images/site-images/005-17.png';
// import siteimg519 from '../../../assets/images/site-images/005-19.png';
// import siteimg521 from '../../../assets/images/site-images/005-21.png';
// import siteimg523 from '../../../assets/images/site-images/005-23.png';
// import siteimg525 from '../../../assets/images/site-images/005-25.png';
// import siteimg00600a from '../../../assets/images/site-images/006-00a.png';
// import siteimg00600b from '../../../assets/images/site-images/006-00b.png';
// import siteimg601 from '../../../assets/images/site-images/006-01.png';
// import siteimg602 from '../../../assets/images/site-images/006-02.png';
// import siteimg603 from '../../../assets/images/site-images/006-03.png';
// import siteimg604 from '../../../assets/images/site-images/006-04.png';
// import siteimg605 from '../../../assets/images/site-images/006-05.png';
// import siteimg606 from '../../../assets/images/site-images/006-06.png';
// import siteimg607 from '../../../assets/images/site-images/006-07.png';
// import siteimg608 from '../../../assets/images/site-images/006-08.png';
// import siteimg609 from '../../../assets/images/site-images/006-09.png';
// import siteimg610 from '../../../assets/images/site-images/006-10.png';
// import siteimg611 from '../../../assets/images/site-images/006-11.png';
// import siteimg612 from '../../../assets/images/site-images/006-12.png';
// import siteimg613 from '../../../assets/images/site-images/006-13.png';
// import siteimg614 from '../../../assets/images/site-images/006-14.png';
// import siteimg615 from '../../../assets/images/site-images/006-15.png';
// import siteimg616 from '../../../assets/images/site-images/006-16.png';
// import siteimg617 from '../../../assets/images/site-images/006-17.png';
// import siteimg618 from '../../../assets/images/site-images/006-18.png';
// import siteimg621 from '../../../assets/images/site-images/006-21.png';
// import siteimg622 from '../../../assets/images/site-images/006-22.png';
// import siteimg623 from '../../../assets/images/site-images/006-23.png';
// import siteimg624 from '../../../assets/images/site-images/006-24.png';
// import siteimg625 from '../../../assets/images/site-images/006-25.png';
// import siteimg626 from '../../../assets/images/site-images/006-26.png';
// import siteimg00701a from '../../../assets/images/site-images/007-01a.png';
// import siteimg00701b from '../../../assets/images/site-images/007-01b.png';
// import siteimg702 from '../../../assets/images/site-images/007-02.png';
// import siteimg703 from '../../../assets/images/site-images/007-03.png';
// import siteimg704 from '../../../assets/images/site-images/007-04.png';
// import siteimg705 from '../../../assets/images/site-images/007-05.png';
// import siteimg706 from '../../../assets/images/site-images/007-06.png';
// import siteimg707 from '../../../assets/images/site-images/007-07.png';
// import siteimg708 from '../../../assets/images/site-images/007-08.png';
// import siteimg709 from '../../../assets/images/site-images/007-09.png';
// import siteimg710 from '../../../assets/images/site-images/007-10.png';
// import siteimg711 from '../../../assets/images/site-images/007-11.png';
// import siteimg712 from '../../../assets/images/site-images/007-12.png';
// import siteimg713 from '../../../assets/images/site-images/007-13.png';
// import siteimg714 from '../../../assets/images/site-images/007-14.png';
// import siteimg715 from '../../../assets/images/site-images/007-15.png';
// import siteimg716 from '../../../assets/images/site-images/007-16.png';
// import siteimg901 from '../../../assets/images/site-images/009-01.png';
// import siteimg902 from '../../../assets/images/site-images/009-02.png';
// import siteimg903 from '../../../assets/images/site-images/009-03.png';
// import siteimg905 from '../../../assets/images/site-images/009-05.png';
// import siteimg907 from '../../../assets/images/site-images/009-07.png';
// import siteimg909 from '../../../assets/images/site-images/009-09.png';
// import siteimg911 from '../../../assets/images/site-images/009-11.png';
// import siteimg913 from '../../../assets/images/site-images/009-13.png';
// import siteimg915 from '../../../assets/images/site-images/009-15.png';
// import siteimg917 from '../../../assets/images/site-images/009-17.png';
// import siteimg919 from '../../../assets/images/site-images/009-19.png';
// import siteimg921 from '../../../assets/images/site-images/009-21.png';
// import siteimg1102 from '../../../assets/images/site-images/011-02.png';
// import siteimg1103 from '../../../assets/images/site-images/011-03.png';
// import siteimg1104 from '../../../assets/images/site-images/011-04.png';
// import siteimg1106 from '../../../assets/images/site-images/011-06.png';
// import siteimg1108 from '../../../assets/images/site-images/011-08.png';
// import siteimg1110 from '../../../assets/images/site-images/011-10.png';
// import siteimg1112 from '../../../assets/images/site-images/011-12.png';
// import siteimg1114 from '../../../assets/images/site-images/011-14.png';
// import siteimg1116 from '../../../assets/images/site-images/011-16.png';
// import siteimg1120 from '../../../assets/images/site-images/011-20.png';
// import siteimg1301 from '../../../assets/images/site-images/013-01.png';
// import siteimg1302 from '../../../assets/images/site-images/013-02.png';
// import siteimg1304 from '../../../assets/images/site-images/013-04.png';
// import siteimg1308 from '../../../assets/images/site-images/013-08.png';
// import siteimg1310 from '../../../assets/images/site-images/013-10.png';
// import siteimg1312 from '../../../assets/images/site-images/013-12.png';
// import siteimg1314 from '../../../assets/images/site-images/013-14.png';
// import siteimg1315 from '../../../assets/images/site-images/013-15.png';
// import siteimg1316 from '../../../assets/images/site-images/013-16.png';
// import siteimg1317 from '../../../assets/images/site-images/013-17.png';

export default function LivePreviewExample() {
  return (
    <>











<Card className="mb-spacing-6-x2">
<div className="py-5">
<Container className="py-5">
<h2 className="font-weight-bold mb-4">
2024 Portfolio Website Apps - Group 1
</h2>
<p className="text-black-50 font-size-lg">
Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse Web3 connected websites and and view public blockchain content.
</p>
<div className="d-flex flex-wrap align-items-center justify-content-center">
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://tenfinney.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg101"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg101}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://tokens.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg102"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg102}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://adr.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg103"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg103}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg104"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg104}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://commerce.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg105"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg105}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://consulting.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg106"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg106}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://metaverse.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg107"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg107}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://fashion.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg108"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg108}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://clones.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg109"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg109}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3tokens.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg110"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg110}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://tokens.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg111"
className="img-fluid rounded mr-3 shadow-sm"
src="https://freeweb3.infura-ipfs.io/ipfs/Qmc1tYpfY35R1fDvYgT3io7gQxNSPGcgSjU63mxXrTDDkk"
style={{ width: `120` }}
width="120"
/>
</a>
</div>
</div>
</Container>
</div>
</Card>
<Card className="mb-spacing-6-x2">
<div className="py-5">
<Container className="py-5">
<h2 className="font-weight-bold mb-4">
2024 Web3 SmartLaw Legaltech Apps - Group 2
</h2>
<p className="text-black-50 font-size-lg">
Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse Web3 connected websites and and view public blockchain content.
</p>
<div className="d-flex flex-wrap align-items-center justify-content-center">
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ethbuild.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg201"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg201}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://supersite.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg202"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg202}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg203"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg203}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3vm.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg204"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg204}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3bits.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg205"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg205}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://nft.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg206"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg206}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://dashboard.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg207"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg207}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg208"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg208}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg209"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg209}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://nftquery.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg210"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg210}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg211"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg211}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://upload.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg212"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg212}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://users.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg213"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg213}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg216"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg216}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://tracker.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg217"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg217}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://lexq.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg218"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg218}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://emporium.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg220"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg220}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
</div>
</Container>
</div>
</Card>
<Card className="mb-spacing-6-x2">
<div className="py-5">
<Container className="py-5">
<h2 className="font-weight-bold mb-4">
2024 Web3 Tools & Apps - Group 3
</h2>
<p className="text-black-50 font-size-lg">
Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse Web3 connected websites and and view public blockchain content.
</p>
<div className="d-flex flex-wrap align-items-center justify-content-center">
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://lit.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg5011"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg5011}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://grants.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg5013"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg5013}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg5015"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg5015}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg5017"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg5017}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg5019"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg5019}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg501"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg501}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg5021"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg5021}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg5023"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg5023}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg5025"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg5025}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg502"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg502}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://greeting.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg503"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg503}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg505"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg505}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://explore.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg507"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg507}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://scaffold.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg509"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg509}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
</div>
</Container>
</div>
</Card>
<Card className="mb-spacing-6-x2">
<div className="py-5">
<Container className="py-5">
<h2 className="font-weight-bold mb-4">
2023 SmartLaw MetaCollab DBSA - Group 4
</h2>
<p className="text-black-50 font-size-lg">
Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse Web3 connected websites and and view public blockchain content.
</p>
<div className="d-flex flex-wrap align-items-center justify-content-center">
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://metacollab.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg6000"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg6000}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://db.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg00600b"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg00600b}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://handshake.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg601"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg601}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://signhandshake.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg602"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg602}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://creategig.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg603"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg603}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://signgig.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg604"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg604}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://startgig.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg605"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg605}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://cancelgig.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg606"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg606}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://completegig.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg607"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg607}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://addresolver.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg608"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg608}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://confirmresolver.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg609"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg609}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://lockgig.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg610"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg610}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://add3rdpartybene.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg611"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg611}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://resolvegig.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg612"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg612}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://createmetatx.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg613"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg613}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://attachmetatx.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg614"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg614}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://agreementterms.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg615"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg615}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://jxdstmt.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg616"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg616}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://deterministic.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg617"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg617}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://siteuseterms.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg618"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg618}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://querygigs.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg621"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg621}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://funders.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg622"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg622}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://doers.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg623"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg623}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://userguide.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg624"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg624}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://buildagig.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg625"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg625}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://gigprofiles.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg626"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg626}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
</div>
</Container>
</div>
</Card>
<Card className="mb-spacing-6-x2">
<div className="py-5">
<Container className="py-5">
<h2 className="font-weight-bold mb-4">
2024 Web3Skills Apps & Courses - Group 5
</h2>
<p className="text-black-50 font-size-lg">
Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse Web3 connected websites and and view public blockchain content.
</p>
<div className="d-flex flex-wrap align-items-center justify-content-center">
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg00701a"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg00701a}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://gated.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg702"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg702}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3skills.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg703"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg703}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-all.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg704"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg704}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-0x00.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg705"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg705}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-blono.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg706"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg706}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-bore.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg707"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg707}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-core.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg708"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg708}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-daoj.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg709"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg709}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-defij.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg710"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg710}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-desa.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg711"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg711}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-dest.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg712"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg712}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-devj.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg713"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg713}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-did.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg714"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg714}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-legtec.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg715"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg715}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ungated-metafp.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg716"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg716}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
</div>
</Container>
</div>
</Card>
<Card className="mb-spacing-6-x2">
<div className="py-5">
<Container className="py-5">
<h2 className="font-weight-bold mb-4">
2024 SmartLaw Apps - Group 6
</h2>
<p className="text-black-50 font-size-lg">
Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse Web3 connected websites and and view public blockchain content.
</p>
<div className="d-flex flex-wrap align-items-center justify-content-center">
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://terms-conditions.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg901"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg901}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://privacy-policy.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg902"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg902}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://mint.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg903"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg903}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://claims.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg905"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg905}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://evidencelog.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg907"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg907}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://contentloader.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg909"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg909}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://claimmanager.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg911"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg911}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://manageclaim.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg913"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg913}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://evidencemanager.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg915"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg915}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://evidencer.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg917"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg917}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://regency.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg919"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg919}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://legalfund.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg921"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg921}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
</div>
</Container>
</div>
</Card>
<Card className="mb-spacing-6-x2">
<div className="py-5">
<Container className="py-5">
<h2 className="font-weight-bold mb-4">
2024 Master Training & Demo Website Apps - Group 7
</h2>
<p className="text-black-50 font-size-lg">
Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse Web3 connected websites and and view public blockchain content.
</p>
<div className="d-flex flex-wrap align-items-center justify-content-center">
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://elements.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1102"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1102}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://template.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1103"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1103}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3connect.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1104"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1104}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3dapp.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1106"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1106}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://supertoken.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1108"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1108}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1110"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1110}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://acctmgr.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1112"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1112}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3ui.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1114"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1114}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1116"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1116}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1120"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1120}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
</div>
</Container>
</div>
</Card>
<Card className="mb-spacing-6-x2">
<div className="py-5">
<Container className="py-5">
<h2 className="font-weight-bold mb-4">
2024 Starter Frontend App Building Templates - Group 8
</h2>
<p className="text-black-50 font-size-lg">
Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse Web3 connected websites and and view public blockchain content.
</p>
<div className="d-flex flex-wrap align-items-center justify-content-center">
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3msgr.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1301"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1301}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://starter.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1302"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1302}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://darkmode.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1304"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1304}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://videohash.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1308"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1308}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://web3starter.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1310"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1310}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://catalog.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1312"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1312}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://github.com/worldlaw"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1314"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1314}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://tokens.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1315"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1315}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://lawgroup.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1316"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1316}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://dbtree.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="folderimg1317"
className="img-fluid rounded mr-3 shadow-sm"
src={folderimg1317}
style={{ width: `120` }}
width="120"
/>
</a>
</div>
<div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
<a
href="https://ipfsdb.freeweb3.com"
target="_blank"
rel="noopener noreferrer">
<img
alt="Web3Skills SmartLaw Website App AAA"
className="img-fluid rounded mr-3 shadow-sm"
src="https://freeweb3.infura-ipfs.io/ipfs/Qmc1tYpfY35R1fDvYgT3io7gQxNSPGcgSjU63mxXrTDDkk"
style={{ width: `120` }}
width="120"
/>
</a>
</div>
</div>
</Container>
</div>
</Card>

    </>
  );
}
