import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, List, ListItem, Tooltip } from '@material-ui/core';

import CountUp from 'react-countup';
import PerfectScrollbar from 'react-perfect-scrollbar';

import TrendingDownTwoToneIcon from '@material-ui/icons/TrendingDownTwoTone';
import TrendingUpTwoToneIcon from '@material-ui/icons/TrendingUpTwoTone';

export default function LivePreviewExample() {
  return (
    <>

      <Card className="shadow-xxl">
        <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
          <div>
            <h6 className="font-weight-bold font-size-lg mb-1 text-primary">
              Treasury
            </h6>
            <p className="text-black-50 mb-0">Status of Web3 digital assets.</p>
          </div>
          <div className="d-flex align-items-center">
            <Tooltip title="View stats for last week" arrow placement="top">
              <Button className="btn-link mx-1 px-1 py-2 font-weight-bold text-primary">
                <span>LEX</span>
              </Button>
            </Tooltip>
            <Tooltip title="View stats for last month" arrow placement="top">
              <Button className="btn-link mx-1 px-1 py-2 font-weight-bold text-primary opacity-5">
                <span>W3LE</span>
              </Button>
            </Tooltip>
            <Tooltip title="View stats for last year" arrow placement="top">
              <Button className="btn-link ml-1 px-1 py-2 font-weight-bold text-primary opacity-5">
                <span>CLINIC</span>
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="divider" />
        <div className="divider" />
        <h3 className="display-4 mt-5 mb-0 text-center font-weight-bold">
          <small className="opacity-6">$</small>
          <span className="pl-1">
            <CountUp
              start={1.000}
              end={1.265}
              duration={6}
              separator=""
              delay={1}
              decimals={3}
              decimal="."
              prefix=""
              suffix=""
            />
            <small>
              <sup> ETH</sup>
            </small>
          </span>
        </h3>
        <small className="d-block font-weight-bold text-center text-muted text-uppercase">
          Total balance
        </small>
        <div className="divider mt-5" />
        <div className="divider" />
        <div className="scroll-area-sm shadow-overflow">
          <PerfectScrollbar>
            <List component="div" className="list-group-flush">
              <ListItem className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center mr-4">
                  <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 bg-primary">
                    <FontAwesomeIcon
                      icon={['fab', 'ethereum']}
                      className="font-size-lg"
                    />
                  </div>
                  <div>
                    <div className="font-weight-bold">ETH</div>
                    <span className="text-first d-block">Ethereum Main Chain</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="text-right mr-3">
                    <div className="font-weight-bold font-size-lg">
                      1.12387 ETH
                    </div>
                    <div className="font-weight-bold text-black opacity-4">
                      $1,398
                    </div>
                  </div>
                  <div className="font-size-lg text-success">
                    <TrendingUpTwoToneIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center mr-4">
                  <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 bg-second">
                    
                    <FontAwesomeIcon
                      icon={['fab', 'bitcoin']}
                      className="font-size-lg"
                    />
                  </div>
                  <div>
                    <div className="font-weight-bold">BTC</div>
                    <span className="text-second d-block">Bitcoin</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="text-right mr-3">
                    <div className="font-weight-bold font-size-lg">
                      0.4895 BTC
                    </div>
                    <div className="font-weight-bold text-black opacity-4">
                      $16,497
                    </div>
                  </div>
                  <div className="font-size-lg text-success">
                    <TrendingUpTwoToneIcon />
                  </div>
                </div>
              </ListItem>

              <ListItem className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center mr-4">
                  <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 bg-primary">
                    <FontAwesomeIcon
                      icon={['fab', 'ethereum']}
                      className="font-size-lg"
                    />
                  </div>
                  <div>
                    <div className="font-weight-bold">MATIC</div>
                    <span className="text-first d-block">Polygon Chain</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="text-right mr-3">
                    <div className="font-weight-bold font-size-lg">
                      1.12387 ETH
                    </div>
                    <div className="font-weight-bold text-black opacity-4">
                      $1,398
                    </div>
                  </div>
                  <div className="font-size-lg text-success">
                    <TrendingUpTwoToneIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center mr-4">
                  <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 bg-first">
                    
                  <FontAwesomeIcon
                      icon={['fas', 'plug']}
                      className="font-size-lg"
                    />
                  </div>
                  <div>
                    <div className="font-weight-bold">LINK</div>
                    <span className="text-first d-block">Chainlink Token</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="text-right mr-3">
                    <div className="font-weight-bold font-size-lg">
                      1,358 USD
                    </div>
                  </div>
                  <div className="font-size-lg text-danger">
                    <TrendingDownTwoToneIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center mr-4">
                  <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 bg-success">
                    <FontAwesomeIcon
                      icon={['fas', 'dollar-sign']}
                      className="font-size-lg"
                    />
                  </div>
                  <div>
                    <div className="font-weight-bold">USD</div>
                    <span className="text-success d-block">Dollar</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="text-right mr-3">
                    <div className="font-weight-bold font-size-lg">
                      1,358 USD
                    </div>
                  </div>
                  <div className="font-size-lg text-danger">
                    <TrendingDownTwoToneIcon />
                  </div>
                </div>
              </ListItem>

            </List>
            
          </PerfectScrollbar>
        </div>
        <div className="card-footer p-3 text-center">
          <Button size="small" className="py-2 px-4 btn-primary">
            <span className="btn-wrapper--label text-uppercase font-weight-bold">
              View all accounts
            </span>
          </Button>
        </div>
      </Card>
    </>
  );
}
