import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Container, Button } from '@material-ui/core';
import ReactPlayer from 'react-player';

import hero9 from '../../../assets/images/hero-bg/hero-9.jpg';

import supersite02 from '../../../assets/images/supersite/supersite-demos-002.png';

import MarketingHeaders1 from '../../MarketingHeaders/MarketingHeaders4';

export default function LivePreviewExample() {
  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-white">
        <Container className="header-top-section py-2">
          <MarketingHeaders1 />
        </Container>
        <div className="hero-wrapper--content mb-5">
          <div
            className="bg-composed-wrapper--image opacity-6 bg-composed-filter-rm"
            style={{ backgroundImage: 'url(' + hero9 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-white opacity-8" />
          <div className="bg-composed-wrapper--bg bg-mixed-hopes opacity-2" />
          <div className="bg-composed-wrapper--content">
            <Container className="z-over text-black pb-5">
              <Grid container spacing={6} className="pt-5">
                <Grid
                  item
                  lg={6}
                  xl={5}
                  className="order-2 order-lg-1 d-flex align-items-center">
                  <Card className="card-box bg-royal shadow-xxl w-100 object-skew hover-scale-lg text-center p-5 mt-5 mt-lg-0 mr-0 mr-xl-5 text-white">
                    <span className="ribbon-vertical ribbon-vertical--success ribbon-vertical--left text-uppercase">
                      <span>-30%</span>
                    </span>
                    <h5 className="font-size-xl text-uppercase font-weight-bold mb-4 text-white">
                      New User Package
                    </h5>
                    <div className="divider bg-white-10 mb-4" />
                    <div>
                      <div className="mb-2">
                        <span className="display-2 font-weight-bold">$1</span>
                        <span className="opacity-8">MATIC</span>
                      </div>
                      <ul className="list-unstyled opacity-6 mb-4">
                        <li>Open-source license included</li>
                      </ul>
                    </div>
                    <div className="divider bg-white-10 mb-4" />
                    <div className="mb-3 font-size-lg font-weight-bold">
                      License active for <b>365</b> days
                    </div>
                    <Button className="btn-success font-weight-bold shadow-sm-dark text-uppercase font-size-sm d-inline-block">
                      Renew License
                    </Button>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={6}
                  xl={7}
                  className="order-1 order-lg-2 d-flex align-items-center">
                  <div>
                    <h2 className="display-3 font-weight-bold">
                      Web3Skills Tech Courses
                    </h2>
                    <p className="font-size-xl py-3 text-black opacity-8">
                      Web3Skills SmartLaw is a training tool and set of
                      protocols to advance the adoption of Web3 technologies.
                      Users can freely browse and use Web3 applications
                      anonymously or with decentralized profiles that capture
                      the spirit of the user. The core of the Web3Skills
                      SmartLaw system is a ERC-20 token that was minted in 2020
                      at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has
                      lived a dormant life until now.MH9
                    </p>

                    <Grid item lg={12} className="d-flex align-items-center">
                      <Container maxWidth="lg" justify="center">
                        <div className="video-wrapper">
                          <ReactPlayer
                            url={
                              'https://freeweb3.infura-ipfs.io/ipfs/QmNXLzMcr291fBtsbN4192VR6AfH6rzKYP8tBZu6FA6E6c'
                            }
                            width="100%"
                            height="100%"
                            controls={true}
                          />
                        </div>
                      </Container>
                    </Grid>

                    <div className="pt-3">
                      <Button
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"
                        size="large"
                        className="btn-pill shadow-second-sm btn-primary">
                        <span className="btn-wrapper--label">Enroll Now</span>
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </span>
                      </Button>
                      <Button
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"
                        size="large"
                        className="bg-white-50 text-primary btn-pill ml-3 font-size-lg">
                        <span>Course Bundles</span>
                      </Button>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  lg={8}
                  className="d-none d-lg-flex object-skew align-items-center ml-5">
                  <img
                    alt="..."
                    className="w-70 mx-auto d-block img-fluid"
                    src={supersite02}
                  />
                </Grid>
              </Grid>
            </Container>
            <div className="shadow-container-blocks-3 z-below">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="var(--light)"
                  fillOpacity="1"
                  d="M0,160L480,96L960,256L1440,160L1440,320L960,320L480,320L0,320Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
