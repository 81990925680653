import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  IconButton,
  Box,
  Typography,
  Tabs,
  Tab,
  Popover,
  LinearProgress,
  Badge,
  Button,
  Link,
  List,
  ListItem,
  Divider,
  Grid
} from '@material-ui/core';

import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import avatar6 from '../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';

import timeline001 from '../../assets/images/timeline/timeline-400w-001.png';
import timeline002 from '../../assets/images/timeline/timeline-400w-002.png';
import timeline005 from '../../assets/images/timeline/timeline-400w-005.png';
import timeline006 from '../../assets/images/timeline/timeline-400w-006.png';
import timeline008 from '../../assets/images/timeline/timeline-400w-008.png';
import timeline009 from '../../assets/images/timeline/timeline-400w-009.png';
import timeline011 from '../../assets/images/timeline/timeline-400w-011.png';
import timeline014 from '../../assets/images/timeline/timeline-400w-014.png';
import timeline015 from '../../assets/images/timeline/timeline-400w-015.png';
import timeline016 from '../../assets/images/timeline/ca.png';
import timeline017 from '../../assets/images/timeline/wy.png';
import timeline018 from '../../assets/images/timeline/nv.png';
import timeline019 from '../../assets/images/timeline/lexdao-adr-12-2021-600w.png';




import profile1 from '../../assets/images/illustrations/scott-stevenson-tenfinney-profile-005.png';
import CameraTwoToneIcon from '@material-ui/icons/CameraTwoTone';

import LocalPharmacyTwoToneIcon from '@material-ui/icons/LocalPharmacyTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';


import PerfectScrollbar from 'react-perfect-scrollbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const HeaderDots = () => {
  // const chartHeaderDotsOptions = {
  //   chart: {
  //     toolbar: {
  //       show: false
  //     },
  //     sparkline: {
  //       enabled: true
  //     },
  //     stacked: true
  //   },
  //   dataLabels: {
  //     enabled: true
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: '65%'
  //     }
  //   },
  //   stroke: {
  //     show: false,
  //     width: 0,
  //     colors: ['transparent']
  //   },
  //   colors: ['#7a7b97', 'rgba(122, 123, 151, 0.15)'],
  //   fill: {
  //     opacity: 1
  //   },
  //   legend: {
  //     show: false
  //   },
  //   labels: [
  //     'Monday',
  //     'Tuesday',
  //     'Wednesday',
  //     'Thursday',
  //     'Friday',
  //     'Saturday',
  //     'Sunday',
  //     'Last week',
  //     'Last month',
  //     'Last year',
  //     'Last quarter'
  //   ],
  //   xaxis: {
  //     crosshairs: {
  //       width: 1
  //     }
  //   },
  //   yaxis: {
  //     min: 0
  //   }
  // };
  // const chartHeaderDotsData = [
  //   {
  //     name: 'Net Profit',
  //     data: [100, 150, 200, 250, 300, 500, 750, 1000]
  //   },
  //   {
  //     name: 'Net Loss',
  //     data: [50, 60, 80, 100, 125, 150, 200, 300]
  //   }
  // ];

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        <span className="pr-2">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-redder-lights badge-circle ' }}>
            <Button
              onClick={handleClick1}
              className="btn-transition-none bg-neutral-primary text-primary font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-75 rounded position-relative">
              <span>
                <CameraTwoToneIcon />
              </span>
            </Button>
          </Badge>

          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{
              paper: 'app-header-dots'
            }}>
            <div className="popover-custom-lg overflow-hidden">
              <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                <div className="bg-composed-wrapper--content text-white px-2 py-4">
                  <h4 className="font-size-xl text-white font-weight-bold mb-2">
                    Web3 Legal Engineering
                  </h4>
                  <p className="opacity-8 mb-0">
                    Blockchains &#38; nodes since{' '}
                    <b className="text-info">2018</b>
                  </p>
                </div>
              </div>
              <div className="mx-1">
                <Tabs
                  className="nav-tabs-primary"
                  value={value}
                  variant="fullWidth"
                  onChange={handleChange}>
                  <Tab label="Timeline" />
                  <Tab label="Access Code" />
                  {/* <Tab label="Digital Assets" /> */}
                </Tabs>
              </div>
              <div className="vh-100">
                <PerfectScrollbar>
                  <TabPanel value={value} index={0}>
                    <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Jan.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2018
                          </h4>
                          <p>Web3Skills formed</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline002}
                                width="90"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Dec.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2019
                          </h4>
                          <Link
                            href="https://twitter.com/tenfinney/status/1211825952545656833?s=20&t=MZCKrOL_UTGB3I1aSNwswg"
                            rel="noopener nofollow"
                            target="_blank">
                            Joined LexDAO
                          </Link>

                          <div className="avatar-wrapper-overlap mt-2 mb-1">
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar1} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar2} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar6} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar1} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar2} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar6} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={timeline017} />
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline017}
                                width="90"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Feb.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2020
                          </h4>
                          <p>Web3 Legal Engineering formed</p>
                          {/* <div className="avatar-wrapper-overlap mt-2 mb-1">
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar2} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar6} />
                              </div>
                            </div>
                          </div> */}
                          <div className="mt-3 mb-1">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline005}
                                width="200"
                              />
                            </a>
                          </div>
                          <div className="mt-3">
                            <Grid item xs={12}>
                              <a
                                href="#/"
                                target="_blank"
                                rel="noopener noreferrer"

                              >
                                <img
                                  alt="..."
                                  className="img-fluid rounded mr-3 shadow-sm"
                                  src={timeline016}
                                  width="90"
                                />
                              </a>
                              <a
                                href="#/"
                                target="_blank"
                                rel="noopener noreferrer"

                              >
                                <img
                                  alt="..."
                                  className="img-fluid rounded mr-3 shadow-sm"
                                  src={timeline018}
                                  width="90"
                                />
                              </a>
                            </Grid>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Jan.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2021
                          </h4>
                          <p>SmartLaw in the Metaverse formed</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline009}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">May</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2021
                          </h4>
                          <p>ADR in the Metaverse formed</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline014}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">June</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2021
                          </h4>
                          <p>Joined the MetaGame project</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline011}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Jan.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2022
                          </h4>
                          <p>
                            Curated live events in Web3-connected open-metaverse
                          </p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src="https://freeweb3.infura-ipfs.io/ipfs/QmW7bCrwxC52GdbJFTm74iwbgubB8VqnfoacrzugpyaNb1"
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Mar.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2022
                          </h4>
                          <p>
                            Model Rules for Metaverse Court System based on N.D. Cal.
                          </p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline008}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">May.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2022
                          </h4>
                          <p>Access tokens used for gated content</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline006}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Sept.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2022
                          </h4>
                          <p>SmartLaw Digital Asset Minter released</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline015}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Dec.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2022
                          </h4>
                          <p>SmartLaw pre-alpha super-site released</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline001}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">Dec.</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2023
                          </h4>
                          <p>Metaverse Mock Court System</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={timeline019}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">All</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2023
                          </h4>
                          <p>Web3Skills Courses Marketing</p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer"

                            >
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={profile1}
                                width="200"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className="text-center my-2">
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-second m-0 d-130">
                          <FontAwesomeIcon
                            icon={['fas', 'fingerprint']}
                            className="d-flex align-self-center display-1"
                          />
                        </div>
                      </div>
                      <p className="font-weight-bold font-size-lg mb-1 mt-4 text-black">
                        2023 Access Code
                      </p>
                      {/* <p className="text-black-50 mb-0">The acces code is: {' ' } */}
                      <h4 className="text-bold text-danger">8675309 </h4>
                      {/* </p> */}
                    </div>
                  </TabPanel>
                  {/* <TabPanel value={value} index={2}>
                    <div className="text-center text-black font-size-lg pb-1 font-weight-bold">
                      Total Digital Assets
                      <small className="d-block text-black-50">
                        Total minted assets for selected period
                      </small>
                    </div>
                    <div className="px-2 pb-3 pt-2">
                      <Chart
                        options={chartHeaderDotsOptions}
                        series={chartHeaderDotsData}
                        type="bar"
                        height={148}
                      />
                    </div>
                  </TabPanel> */}
                </PerfectScrollbar>
              </div>
              <Divider />
              <div className="text-center py-3">
                <Badge
                  color="error"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}>
                  <Button
                    component={NavLink}
                    to="/About"
                    className="btn-gradient bg-midnight-bloom px-4"
                    variant="contained">
                    <span className="btn-wrapper--label">
                      See more informatiuon
                    </span>
                  </Button>
                </Badge>
              </div>
            </div>
          </Popover>
        </span>

        <span className="pr-2">
          {/* <Badge
            variant="dot"
            overlap="circle"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-first badge-circle-sm' }}> */}
          <Button
            onClick={handleClick3}
            className="btn-transition-none bg-neutral-primary text-primary font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-75 rounded position-relative">
            <span>
              <LocalPharmacyTwoToneIcon />
            </span>
          </Button>
          {/* </Badge> */}
          <Popover
            open={open3}
            anchorEl={anchorEl3}
            onClose={handleClose3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <Box className="overflow-hidden border-0 bg-second p-0 dropdown-mega-menu-sm">
              <div className="px-3 py-3 text-center">
                <div className="mx-3 mb-2 mt-3 d-inline-block text-center">
                  <IconButton
                    href="https://evidencer.freeweb3.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="
                  btn-primary p-0 bg-nice-redora-darker d-inline-block text-center text-white d-50 rounded btn-icon border-0 mb-2">
                    <FontAwesomeIcon
                      icon={['fas', 'lock']}
                      className="font-size-xl"
                    />
                  </IconButton>
                  <div className="d-block text-white-50">Encrypt</div>
                </div>
                <div className="mx-3 mb-2 mt-3 d-inline-block text-center">
                  <IconButton
                    href="https://evidencer.freeweb3.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn-primary p-0 bg-happy-green d-inline-block text-center text-white d-50 rounded btn-icon border-0 mb-2">
                    <FontAwesomeIcon
                      icon={['fas', 'bullhorn']}
                      className="font-size-xl"
                    />
                  </IconButton>
                  <div className="d-block text-white-50">Broadcast</div>
                </div>
                <div className="mx-3 mb-2 mt-3 d-inline-block text-center">
                  <IconButton
                    href="https://evidencer.freeweb3.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn-primary p-0 bg-serious-blue d-inline-block text-center text-white d-50 rounded btn-icon border-0 mb-2">
                    <FontAwesomeIcon
                      icon={['fas', 'unlock']}
                      className="font-size-xl"
                    />
                  </IconButton>
                  <div className="d-block text-white-50">Decode</div>
                </div>
              </div>
              <div className="divider opacity-2 bg-white mb-3" />
              <div className="text-center mb-3">
                <Button
                  href="https://evidencer.freeweb3.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="text"
                  size="large"
                  className="text-white btn-transparent bg-night-skyer">
                  SmartLaw Evidencer
                </Button>
              </div>
            </Box>
          </Popover>
        </span>
      </div>

      <span className="pr-2">
        {/* <Badge
          variant="dot"
          overlap="circle"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          badgeContent=" "
          classes={{ badge: 'bg-first badge-circle-sm' }}> */}
        <Button
          onClick={handleClick2}
          className="btn-transition-none bg-neutral-primary text-primary font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-75 rounded position-relative">
          <span>
            <VerifiedUserTwoToneIcon />
          </span>
        </Button>
        {/* </Badge> */}
        <Popover
          open={open2}
          anchorEl={anchorEl2}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          <List
            component="div"
            className="list-group-flush text-left bg-transparent">
            <ListItem>
              <div className="align-box-row">
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  variant="dot">
                  <Avatar alt="Travis Howard" src={avatar7} />
                </Badge>
                <div className="pl-3">
                  <Grid>
                    <span className="font-weight-bold d-block">
                      Scott Stevenson is a SmartLaw certified legal engineer.*
                    </span>
                    <small className="pb-0 text-black-50 d-block">
                      Web3 open-metaverse content is provided to create and distributes digital assets
                    </small>
                    <small className="pb-0 text-black-50 d-block">
                      for consumption in a Web3 environment.
                    </small>
                    <br />
                    <small className="pb-0 text-black-50 d-block">
                      <span className="text-danger">&nbsp;{' '}*&nbsp;{' '}</span>LexDAO, LexClinic, and LexLabs is relied upon for providing ongoing educational material
                    </small>
                    <small className="pb-0 text-black-50 d-block">
                      to establish a SmartLaw curriculum designed to Web3 Legal Engineering standards.
                    </small>
                  </Grid>

                </div>
              </div>
            </ListItem>

            <Grid>
              <ListItem className="d-block py-2">
                <div className="align-box-row mb-1">
                  <div>
                    <small className="font-weight-bold">
                      Scott Stevenson J.D. has completed <span className="text-danger">&nbsp;{' '}1&nbsp;{' '}</span>steps, <span className="text-danger">&nbsp;{' '}2&nbsp;{' '}</span>courses, <span className="text-danger">&nbsp;{' '}3&nbsp;{' '}</span>proved knowledgeable in computing systems <span className="text-danger">&nbsp;{' '}4&nbsp;{' '}</span> using Web3 technologies as it is applies to operations of law, and <span className="text-danger">&nbsp;{' '}5&nbsp;{' '}</span>satisfied the identity requirements for earning the SmartLaw Certified Legal Engineer digital asset. The digital asset is issued by Web3 Legal Engineering as an 1155 standard token using the Etherem Virtual Machine &#40;EVM&#41; operating system. The digital asset acts as proof of the certification status. The credential is recorded on the Polygon public blockchain
                      <Link
                        href="https://chainlist.org/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="text-primary font-weight-bold">
                        &nbsp;{' '}EVM chain provider 137.{' '}&nbsp;
                      </Link>
                    </small>
                  </div>
                </div>


                <LinearProgress
                  variant="determinate"
                  value={100}
                  className="progress-bar-success progress-animated-alt progress-bar-rounded"
                />
                <div className="align-box-row progress-bar--label mt-1 ">
                  <small className="text-dark">0</small>
                  <small className="ml-auto">100%</small>
                </div>
              </ListItem>
            </Grid>



            <ListItem className="d-block text-center p-3">
              <Button
                component={NavLink}
                to="/Connect"
                variant="contained"
                size="small"
                className="btn-primary">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                </span>
                <span className="btn-wrapper--label">Home</span>
              </Button>
            </ListItem>
          </List>
        </Popover>
      </span>
    </>
  );
};

export default HeaderDots;
