import React from 'react';

import { PageTitle } from 'layout-components';
import { Grid } from '@material-ui/core';
import { ExampleWrapperSeamless } from 'layout-components';

import BlocksListsSmall1 from '../../example-components/Members/BlocksListsSmall1';
import BlocksListsSmall2 from '../../example-components/Members/BlocksListsSmall2';
import BlocksListsSmall3 from '../../example-components/Members/BlocksListsSmall3';
import BlocksListsSmall4 from '../../example-components/Members/BlocksListsSmall4';
// import BlocksListsSmall5 from '../../example-components/BlocksListsSmall/BlocksListsSmall5';
// import BlocksListsSmall6 from '../../example-components/BlocksListsSmall/BlocksListsSmall6';
// import BlocksListsSmall7 from '../../example-components/BlocksListsSmall/BlocksListsSmall7';
import BlocksListsSmall8 from '../../example-components/Members/BlocksListsSmall8';
import BlocksListsSmall9 from '../../example-components/Members/BlocksListsSmall9';
// import BlocksListsSmall10 from '../../example-components/BlocksListsSmall/BlocksListsSmall10';

export default function Members() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills SmartLaw Feature Dashboard"
        titleDescription="The tiiles below are feature-rich representations of decentralized organization management. They query the blockchain and present the information in a easily-digestible way. Any information collected on this site is held in a 
        secure database under the terms of use and privacy policy. https://www.airtable.com/security"
      />

      <Grid container spacing={6}>
        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall4 />
          </ExampleWrapperSeamless>
        </Grid>
        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall3 />
          </ExampleWrapperSeamless>
        </Grid>
        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall1 />
          </ExampleWrapperSeamless>
        </Grid>
        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall8 />
          </ExampleWrapperSeamless>
        </Grid>
        {/* <Grid item xl={6}>
          <ExampleWrapperSeamless>
          <BlocksListsSmall5 />
          </ExampleWrapperSeamless>
        </Grid> */}
        {/* <Grid item xl={6}>
          <ExampleWrapperSeamless>
          <BlocksListsSmall6 />
          </ExampleWrapperSeamless>
        </Grid> */}
        {/* <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall7 />
          </ExampleWrapperSeamless>
        </Grid> */}
        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall2 />
          </ExampleWrapperSeamless>
        </Grid>
        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall9 />
          </ExampleWrapperSeamless>
        </Grid>
        {/* <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall10 />
          </ExampleWrapperSeamless>
        </Grid> */}
      </Grid>
    </>
  );
}
