import React from 'react';

import { Grid, Container, Card, Button } from '@material-ui/core';

import { NavLink } from 'react-router-dom';

export default function LivePreviewExample() {
  return (
    <>

<Container className="py-5 pt-lg-0 z-over" style={{ marginTop: '-50px' }}>
<Grid container spacing={6} className="text-center text-second pb-5">
<Grid item lg={10} xl={8} className="mx-auto">
<div className="font-size-sm d-inline-flex bg-neutral-primary text-primary px-4 rounded-lg text-white py-2 text-uppercase">
Continued Educatiuon for Lawyers
</div>
<h3 className="display-4 font-weight-bold my-4 text-uppercase">
Web3Skills SmartLaw offers CLE content
</h3>
<p className="text-black-50 line-height-2 font-size-xl px-3 px-xl-5 mb-0">
Web3 Legal Engineering offers CLE content for to the ABA and state
</p>
</Grid>
</Grid>
<Grid container spacing={0} className="z-over pb-lg-5">
<Grid item lg={4} className="d-flex align-items-center">
<div className="text-white w-100 overflow-hidden pt-3 pt-lg-5 mb-5 mb-lg-0 bg-night-sky rounded br-lg-left-0">
<div className="text-center py-3 py-lg-5">
<div className="font-weight-bold display-3 p-2 mb-3">
Who?
</div>
<div className="font-weight-bold display-4 p-2 mb-3">
State Bar requirements
</div>
<div className="font-weight-bold display-5 p-2 mb-3">
Are lawyers required to take CLE's from both the ABA and their local state bar associations?
</div>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
CLE requirements vary by state, so the answer to this question depends on the particular state's rules.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
It is important for lawyers to check the specific CLE requirements for their state to ensure they are meeting all of the necessary obligations to maintain their license to practice law.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mt-2 mb-2">
It is important for lawyers to check the specific CLE requirements for their state to ensure they are meeting all of the necessary obligations to maintain their license to practice law.
</p>
</div>
<div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
<div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-5">
<div className="display-2 font-weight-bold">
<span> . </span>
<span className="font-size-lg"> . </span>
</div>
</div>
<div className="text-center py-3 py-lg-5">
<div className="font-weight-bold display-3 p-2 mb-3">
International
</div>
<div className="font-weight-bold display-4 p-2 mb-3">
Continued Learning
</div>
<div className="font-weight-bold display-5 p-2 mb-3">
Are lawyers in other countries required to take CLEs or their equivalent?
</div>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
Continuing Legal Education (CLE) requirements for lawyers vary from country to country. In some countries, CLE is mandatory for lawyers, while in others, it is not required at all.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
In the United States, CLE is mandatory in most states, and the American Bar Association (ABA) sets some minimum standards for CLE programs. However, in other countries, such as the United Kingdom, lawyers are not required to participate in formal CLE programs, but they are expected to engage in ongoing professional development and training.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
In some countries, lawyers may be required to complete a certain number of hours of professional development or training each year, but it may not be called "CLE." For example, in Australia, lawyers are required to complete "Continuing Professional Development" (CPD) hours each year to maintain their practicing certificate.
</p>
<p className="text-left text-white-50 line-height-2 font-size-xl px-2 px-xl-3 mb-2">
Therefore, the answer to this question depends on the specific country and its rules and regulations governing the legal profession.
</p>
</div>
<div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
<div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-5">
<div className="display-2 font-weight-bold">
<span>Free </span>
<span className="font-size-lg">Consultation</span>
</div>
</div>
</div>
</Grid>
<Grid item lg={4}>
<Card className="bg-second text-white bg-night-skyer rounded mb-5 mb-lg-0">
<div className="text-center py-3 py-lg-5">
<div className="font-weight-bold display-3 p-2 mb-3">
CLE & the ABA
</div>
<div className="font-weight-bold display-4 p-2 mb-3">
Continued Legal Education
</div>
<div className="font-weight-bold display-5 p-2 mb-3">
What is a CLE and why are ABA lawyers required to register and take them?
</div>
<p className="text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mt-2 mb-2">
CLE stands for "Continuing Legal Education," which refers to the ongoing education and training required of lawyers after they have been admitted to the bar and licensed to practice law.
</p>
<p className="text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mt-2 mb-2">
The American Bar Association (ABA) requires its lawyers to complete a certain number of CLE credits in order to maintain their license to practice law. These credits can be obtained through attending courses, seminars, workshops, or conferences that cover a variety of legal topics.
</p>
<p className="text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mt-2 mb-2">
The purpose of CLE requirements is to ensure that lawyers stay current with changes in the law, maintain their competence as legal professionals, and provide high-quality legal services to their clients. These requirements help to protect the public by ensuring that lawyers are up-to-date with the latest legal developments and ethical obligations.
</p>
</div>
<div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
<div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-5">
<div className="display-2 font-weight-bold">
<span> . </span>
<span className="font-size-lg"> . </span>
</div>
</div>
<div className="text-center py-3 py-lg-5">
<div className="font-weight-bold display-3 p-2 mb-3">
Accreditation
</div>
<div className="font-weight-bold display-4 p-2 mb-3">
Producing CLEs
</div>
<div className="font-weight-bold display-5 p-2 mb-3">
My organization is producing a CLE program. How can we get our program accredited? Does the ABA accredit programs?
</div>
<p className="text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mt-2 mb-2">
The ABA is not an accrediting body or regulatory agency, but a provider of continuing legal education to the legal community at large. CLE accreditation is given on a state-by-state basis. There is no nationwide accreditation since the MCLE programs are administered by the state supreme courts through a special CLE commission or board.
</p>
<p className="text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mt-2 mb-2">
Overall, the process for MCLE accreditation is a lengthy one, requiring about two to three months of advance preparation. Each state with an MCLE requirement administers its own program with its own distinct set of rules, regulations, and sponsor fees. Therefore, a CLE provider seeking MCLE accreditation of its programs must apply to each state individually.
</p>
<p className="text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mt-2 mb-2">
Individual attorneys may also request accreditation from their particular states after the program has taken place by providing course materials, a special application, and possibly a small fee. Again, each state has its own procedure. To learn more, visit the website of the Continuing Legal Education Regulators Association (CLEreg), which contains links to individual MCLE state websites where you may download or review the MCLE rules and regulations. You will also find contact names, addresses and telephone numbers for jurisdiction regulators, and a Uniform Application for Accreditation.
</p>
<p className="text-white-50 line-height-2 font-size-xl px-2 px-xl-3 mb-2">
x
</p>
</div>
<div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
<div className="divider bg-white opacity-2" />
<div className="p-4 p-lg-5">
<div className="d-flex align-items-center justify-content-between pb-3">
<div className="font-weight-bold font-size-lg">
Custom Spaces
</div>
<div className="bg-primary text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
Ready Now
</div>
</div>
<div className="divider opacity-2" />
<div className="d-flex align-items-center justify-content-between py-3">
<div className="font-weight-bold font-size-lg">
Custom Assets
</div>
<div className="bg-primary text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
Ready Now
</div>
</div>
<div className="divider opacity-2" />
<div className="d-flex align-items-center justify-content-between pt-3">
<div className="font-weight-bold font-size-lg">
Hyperlinked Content
</div>
<div className="bg-primary text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
Ready Now
</div>
</div>
</div>
<div className="divider bg-white opacity-2" />
<div className="d-flex bg-white-10 rounded-bottom align-items-center justify-content-between py-4 px-5">
<div>
<Button
component={NavLink}
to="/Members"
className="rounded-sm font-weight-bold hover-scale-lg px-4 btn-first"
size="large">
SmartLaw Dashboard
</Button>
</div>
</div>
</Card>
</Grid>
<Grid item lg={4} className="d-flex align-items-center">
<div className="text-white w-100 overflow-hidden pt-3 pt-lg-5 mb-5 mb-lg-0 bg-night-sky rounded br-lg-left-0">
<div className="text-center py-3 py-lg-5">
<div className="font-weight-bold display-3 p-2 mb-3">
CA vs ABA
</div>
<div className="font-weight-bold display-4 p-2 mb-3">
California’s MCLE
</div>
<div className="font-weight-bold display-5 p-2 mb-3">
Explain the difference between the California State Bar Association MCLE program compared to the ABA CLE program of continued education for lawyers.
</div>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
The California State Bar Association Mandatory Continuing Legal Education (MCLE) program and the American Bar Association (ABA) Continuing Legal Education (CLE) program are two different programs that have different requirements and guidelines for lawyers seeking to fulfill their ongoing education requirements.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
The California State Bar Association MCLE program is mandatory for all lawyers licensed to practice law in California, and requires lawyers to complete 25 hours of approved CLE courses every three years. Of those 25 hours, lawyers must complete four hours of legal ethics, one hour of elimination of bias, and one hour of competence issues (commonly referred to as "substance abuse" or "mental health" CLE).
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
The California State Bar Association has specific guidelines and requirements for CLE providers seeking approval for their courses, including the content of the course, the qualifications of the presenter, and the methods of delivery.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
The ABA CLE program is not mandatory, but is widely recognized and respected by the legal community. The ABA provides accreditation for CLE programs offered by providers who meet the ABA's standards and guidelines. The ABA does not require a specific number of hours for lawyers to complete, but instead encourages lawyers to participate in ongoing education to maintain their professional competence.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-2 px-lg-3 mt-2 mb-2">
The ABA provides a wide range of CLE programs covering various legal topics, including webinars, conferences, and publications. The ABA's standards for accreditation are similar to those of the California State Bar Association, but may vary in some respects, such as the number of required hours for specific topics.
</p>
<p className="text-left text-white-90 line-height-2 font-size-xxl px-2 px-xl-3 mb-2">
In summary, the California State Bar Association MCLE program is a mandatory program with specific requirements for California lawyers to fulfill their ongoing education requirements, while the ABA CLE program is a voluntary program that provides accreditation for a variety of CLE programs offered by providers who meet the ABA's standards and guidelines.
</p>
</div>
<div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
<div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-2">
<div className="display-2 font-weight-bold ml-4">
<span>INTL </span>
<span className="font-size-lg"> CLEs & Certs</span>
</div>
</div>
</div>
</Grid>
</Grid>
<Grid container spacing={6} className="text-center text-second pb-5">
<Grid item lg={10} xl={8} className="mx-auto">
<div className="font-size-sm d-inline-flex bg-neutral-primary text-primary px-4 rounded-lg text-white py-2 text-uppercase">
Continued Educatiuon for Lawyers
</div>
<h3 className="display-4 font-weight-bold my-4 text-uppercase">
Web3Skills SmartLaw offers CLE content
</h3>
<p className="text-black-50 line-height-2 font-size-xl px-3 px-xl-5 mb-0">
Web3 Legal Engineering offers CLE content for to the ABA and state
</p>
</Grid>
</Grid>
<hr></hr>
<hr></hr>
<Grid container spacing={0} className="z-over pb-lg-5">
<Grid item lg={12} className="d-flex align-items-center">
<div className="text-white w-100 overflow-hidden pt-3 pt-lg-5 mb-5 mb-lg-0 bg-night-sky rounded br-lg-left-4">
<div className="text-center py-3 py-lg-5">
<div className="font-weight-bold display-3 p-2 mb-3">
Process to Offer CLEs
</div>
<div className="font-weight-bold display-4 p-2 mb-3">
Contact the State ABAs
</div>
<div className="font-weight-bold display-5 p-2 mb-3">
What is the process to offer Continued Learning Education (CLE) to state bar associations and who do I contact to get the process started?
</div>
<p className="text-left text-white-50 line-height-2 font-size-lg px-xl-5 mt-2 mb-2">
The process to offer Continued Learning Education (CLE) to state bar associations may vary depending on the specific requirements and procedures of each state. However, in general, the following steps can be helpful:
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-xl-5 mt-2 mb-2">
Identify the state bar association(s) that you want to offer CLE programs to. You can find a list of state bar associations on the website of the American Bar Association (ABA).
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-xl-5 mt-2 mb-2">
Review the CLE requirements of the state bar association(s) you are interested in offering programs to. Each state has its own set of requirements regarding the types of programs that qualify for CLE credit, the number of credits that lawyers must earn each year, and other details.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-xl-5 mt-2 mb-2">
Develop CLE programs that meet the requirements of the state bar association(s). The programs should be educational in nature and relevant to the practice of law. They can be presented in various formats, such as live webinars, online courses, or in-person seminars.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-xl-5 mt-2 mb-2">
Submit an application to the state bar association(s) to have your CLE programs accredited for credit. The application process typically involves submitting detailed information about the program, including the title, description, objectives, and faculty bios.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-xl-5 mt-2 mb-2">
If your application is approved, you can begin offering your CLE programs to lawyers in the state. You will need to keep track of attendance and provide certificates of completion to lawyers who attend the programs.
</p>
<p className="text-left text-white-50 line-height-2 font-size-lg px-xl-5 mt-2 mb-2">
To get the process started, you can contact the CLE department of the state bar association(s) you are interested in offering programs to. They can provide you with more information about the specific requirements and procedures for accreditation.
</p>
</div>
<div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
<div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-2">
<div className="display-2 font-weight-bold ml-4">
<span>ABA </span>
<span className="font-size-lg"> CLEs & MCLEs</span>
</div>
</div>
</div>
</Grid>
</Grid>
</Container>


    </>
  );
}
