import React from 'react';

import { Grid, Container, Card, Button } from '@material-ui/core';

import GaugeChart from 'react-gauge-chart';

import { NavLink } from 'react-router-dom';



import metacollab01 from '../../../assets/images/metacollab/metacollab-001-400w.png';
import metacollab02 from '../../../assets/images/metacollab/metacollab-002-400w.png';
import metacollab05 from '../../../assets/images/metacollab/metacollab-005-400w.png';
import metacollab06 from '../../../assets/images/metacollab/metacollab-006-400w.png';
import metacollab08 from '../../../assets/images/metacollab/metacollab-008-400w.png';
import metacollab10 from '../../../assets/images/metacollab/metacollab-010-400w.png';
import metacollab11 from '../../../assets/images/metacollab/metacollab-011-400w.png';
import metacollab12 from '../../../assets/images/metacollab/metacollab-012-400w.png';

import slmetaverse01 from '../../../assets/images/sl-metaverse/sl-metaverse-600w-01.png';
import slmetaverse02 from '../../../assets/images/sl-metaverse/sl-metaverse-600w-02.png';
import slmetaverse04 from '../../../assets/images/sl-metaverse/sl-metaverse-600w-04.png';
import slmetaverse05 from '../../../assets/images/sl-metaverse/sl-metaverse-600w-05.png';
import slmetaverse08 from '../../../assets/images/sl-metaverse/sl-metaverse-600w-08.png';
import slmetaverse19 from '../../../assets/images/sl-metaverse/sl-metaverse-600w-19.png';



import hero1 from '../../../assets/images/hero-bg/lex-metaverse.png';


import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';

export default function LivePreviewExample() {
return (
<>
<div className="hero-wrapper bg-arielle-smile bg-composed-wrapper">
<div className="flex-grow-1 w-100 d-flex align-items-center">
<div
className="bg-composed-wrapper--image opacity-4"
style={{ backgroundImage: 'url(' + hero1 + ')' }}
/>


<div className="bg-composed-wrapper--bg bg-second opacity-7" />
<div className="bg-composed-wrapper--content align-self-center p-4 p-xl-5">




<Container className="pb-5 py-lg-5 text-center">


<div className="mb-4">
<Button
component={NavLink}
to="/PageError404"
size="medium"
className="btn-primary shadow-second-sm btn-pill">
<span className="text-white text-uppercase btn-wrapper--label">
Understanding Web3Skills SmartLaw 
</span>
</Button>

<h4 className="mt-5 font-weight-bold text-white display-3">
Learn Web3Skills & SmartLaw to prepare for the Metaverse 
</h4>
<Grid item md={11} lg={10} className="mx-auto">
<p className="text-white opacity-6 mt-3 mb-4 font-size-xxl">
Watch these repeating demos and follow the instructions for self-guided Web3 courses, legal engineer training, &#38; metaverse dispute resolutions. 
</p>
</Grid>
</div>
<Grid container spacing={6}>





<Grid item md={6}>
<a
href="https://clinic.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src="https://freeweb3.infura-ipfs.io/ipfs/QmZmXhU1STYLrSdxnLCXoEFazvrZAHeoetPeptHf8gCUW8"
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Web3Skills Headers HD &#40;1:06 min.&#41;
</p>
</Grid>


<Grid item md={6}>
<a
href="https://clinic.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src="https://freeweb3.infura-ipfs.io/ipfs/QmWFQUPX4r5Vj77Jk4uV3iAtyqx2jVMC9CnCt94CE9y628"
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Smartlaw Headers HD &#40;46 sec.&#41;



</p>
</Grid>


<div className="mb-4">

<Grid item md={12}>
<a
href="https://clinic.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent ">
<img
src="https://freeweb3.infura-ipfs.io/ipfs/QmPCdKSxScChyNqMgVB6qNop4KiHsQhPFNTANocMiwTMgD"
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3 mb-5">
Admin Acct Mgmt Sidebar HD &#40;55 sec.&#41;


</p>
</Grid>
</div>

</Grid>


<Button
component={NavLink}
to="/PageError404"
size="medium"
className="btn-primary shadow-second-sm btn-pill">
<span className="text-white text-uppercase btn-wrapper--label">
See All Site-Use Demos
</span>
</Button>



<br />
<br />

<hr />

<br />
<br />



<div className="mb-4">

<h4 className="mt-5 font-weight-bold text-white display-3">
Disputable Buyer-Seller Agreements
</h4>
<Grid item md={11} lg={10} className="mx-auto">
<p className="text-white opacity-6 mt-3 mb-4 font-size-xxl">
SmartLaw_Template executes Web3 transactions on public blockchains and nodes using the decentralized web and provides a rich user experience in a 3-dimensional webspace.
</p>
<Button
href="https://www.voxels.com/play?coords=SE@481W,71S"
rel="noopener noreferrer"
target="_blank"
size="large"
className="btn-primary bg-vicious-stance shadow-second-sm btn-pill">
<span className="text-white text-uppercase btn-wrapper--label">
Metacollab in the Metaverse
</span>
</Button>
</Grid>
</div>
<Grid container spacing={6}>

<Grid item md={6}>
<div className="card shadow-sm-dark rounded-lg bg-transparent">
<div className="card-img-wrapper">
<div className="card-badges card-badges-bottom">
<div className="badge badge-pill h-auto py-1 px-3 badge-success shadow-xxl">
Active
</div>
</div>
<a
href="https://handshake.metacollab.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={metacollab01}
className="rounded-lg img-fluid"
alt="..."
/>
</a>

</div>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Collaborate with Others
</p>
</div>

</Grid>

<Grid item md={6}>
<div className="card shadow-sm-dark rounded-lg bg-transparent">
<div className="card-img-wrapper">
<div className="card-badges card-badges-bottom">
</div>
<a
href="https://creategig.metacollab.freeweb3.com"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={metacollab02}
className="rounded-lg img-fluid"
alt="..."
/>
</a>

</div>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Gigs are Disputable Agreements
</p>
</div>

</Grid>


<Grid item md={4}>
<a
href="https://creategig.metacollab.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={metacollab08}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Connecting Players to Gigs &#38; Gigs to Funders
</p>
</Grid>


<Grid item md={4}>
<a
href="https://creategig.metacollab.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={metacollab05}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Logical Legal Flow
</p>
</Grid>

<Grid item md={4}>
<a
href="https://creategig.metacollab.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={metacollab06}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Smart-Contract Driven - EVM
</p>
</Grid>


<Grid item md={4}>
<a
href="https://creategig.metacollab.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={metacollab10}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Creating a Gig
</p>
</Grid>

<Grid item md={4}>
<a
href="https://creategig.metacollab.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={metacollab11}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Contract Performance Flow
</p>
</Grid>

<Grid item md={4}>
<a
href="https://creategig.metacollab.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={metacollab12}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Contract Dispute Flow 
</p>
</Grid>



</Grid>




<br />
<br />

<hr />

<br />
<br />
















































<div className="mb-4">
<Button
href="https://www.voxels.com/play?coords=SE@481W,71S"
rel="noopener noreferrer"
target="_blank"
size="medium"
className="btn-primary shadow-second-sm btn-pill">
<span className="text-white text-uppercase btn-wrapper--label">
Dispute Resolution in the Metaverse
</span>
</Button>

<h4 className="mt-5 font-weight-bold text-white display-3">
Interactive Metaverse Courthouse Experience
</h4>
<Grid item md={11} lg={10} className="mx-auto">
<p className="text-white opacity-6 mt-3 mb-4 font-size-xxl">
SmartLaw_Template executes Web3 transactions on public blockchains and nodes using the decentralized web and provides a rich user experience in a 3-dimensional webspace.
</p>
</Grid>
</div>
<Grid container spacing={6}>









<Grid item md={12}>
<a
href="https://www.voxels.com/play?coords=W@465W,100S,7.5U"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={slmetaverse02}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
SmartLaw Clubhouse
</p>
</Grid>

<Grid item md={6}>
<a
href="https://www.voxels.com/play?coords=W@465W,100S,7.5U"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={slmetaverse01}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Metaverse Municipal Services
</p>
</Grid>




<Grid item md={6}>
<a
href="https://www.voxels.com/play?coords=W@465W,100S,7.5U"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={slmetaverse19}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Alternative Dispute Resolution
</p>
</Grid>


<Grid item md={6}>
<a
href="https://www.voxels.com/play?coords=W@465W,100S,7.5U"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={slmetaverse05}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
SmartLaw Learning-Journey a&#38; Game
</p>
</Grid>

<Grid item md={6}>
<a
href="https://www.voxels.com/play?coords=W@465W,100S,7.5U"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={slmetaverse04}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
LexDAO-LexClinic
</p>
</Grid>

<Grid item md={6}>
<a
href="https://www.voxels.com/play?coords=W@465W,100S,7.5U"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src={slmetaverse08}
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Web3 Open-Metaverse Commerce
</p>
</Grid>


<Grid item md={6}>
<a
href="https://www.voxels.com/play?coords=W@465W,100S,7.5U"
target="_blank"
rel="noopener noreferrer"
className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
<img
src="https://freeweb3.infura-ipfs.io/ipfs/QmW7bCrwxC52GdbJFTm74iwbgubB8VqnfoacrzugpyaNb1"
className="rounded-lg img-fluid"
alt="..."
/>
</a>
<p className="text-white font-weight-bold font-size-xxl pt-3">
Web3 Open-Metaverse Avatars
</p>
</Grid>




</Grid>



</Container>





</div>
</div>
</div>








<div className="py-4 feature-box">
<Container className="py-5">
<div className="mb-4 text-center">
<div className="badge badge-neutral-success text-primary mb-3 h-auto py-2 px-4 font-size-xs badge-pill font-weight-normal">
Unlimited Creative Options
</div>
<h4 className="font-weight-bold text-second display-3">
Build a metaverse project with Web3Skills SmartLaw.
</h4>
<Grid item md={11} lg={10} className="mx-auto">
<p className="text-second opacity-6 mt-3 mb-5 font-size-xxl">
Web3Skills SmartLaw provides a metaverse marketplace to showcase, promote, buy and sell digital assets in public Web3 connected metaverse locations. 
</p>
</Grid>
</div>
<Grid container spacing={6} className="mt-5">
<Grid item xl={4} className="d-flex align-items-center">
<Grid container spacing={0} className="w-100 mb-3 mb-xl-0">
<Grid item lg={4} xl={12}>
<div className="feature-box pr-4">
<h2 className="font-size-2xl font-weight-bold my-3 text-primary">
Things we can do in the metaverse today!
</h2>
<h3 className="font-size-xl font-weight-bold my-3 text-primary">
Purchase a metaverse parcel.
</h3>
<p className="text-black-50 mb-3">
Purchasing details
</p>
</div>
</Grid>
<Grid item lg={4} xl={12}>
<div className="feature-box pr-4">
<h3 className="font-size-xl font-weight-bold my-3 text-primary">
Lease a parcel location.
</h3>
<p className="text-black-50 mb-3">
Leasing details
</p>
</div>
</Grid>
<Grid item lg={4} xl={12}>
<div className="feature-box pr-4">
<h3 className="font-size-xl font-weight-bold my-3 text-primary">
Sub-lease a portion of a parcel.
</h3>
<p className="text-black-50 mb-3">
Sub-Leasing details
</p>
</div>
</Grid>
<Grid item lg={4} xl={12}>
<div className="feature-box pr-4">
<h3 className="font-size-xl font-weight-bold my-3 text-primary">
Lease a gallery wall in the metaverse.
</h3>
<p className="text-black-50 mb-3">
Gallery wall in the metaverse details
</p>
</div>
</Grid>
</Grid>
</Grid>
<Grid item md={8} xl={5} className="d-flex align-items-center">
<Card className="bg-second shadow-xxl card-box card-box-hover-rise card-box-hover rounded-lg text-center p-3 p-xl-4 mb-4 mb-md-0 d-block">
<div className="d-100 object-skew hover-scale-sm icon-blob btn-icon text-info mx-auto">
<svg
className="blob-wrapper opacity-2"
viewBox="0 0 600 600"
xmlns="http://www.w3.org/2000/svg">
<g transform="translate(300,300)">
<path
d="M155,-128.8C192.2,-77,207.7,-13,197.7,50.9C187.7,114.8,152.2,178.6,96.7,208.2C41.1,237.9,-34.6,233.4,-102.6,204C-170.6,174.7,-231.1,120.6,-246.7,55.4C-262.4,-9.9,-233.2,-86.3,-184.6,-140.7C-136,-195.2,-68,-227.6,-4.6,-223.9C58.9,-220.3,117.8,-180.6,155,-128.8Z"
fill="currentColor"
/>
</g>
</svg>
<div className="blob-icon-wrapper text-info">
<NotificationsActiveTwoToneIcon />
</div>
</div>
<h5 className="font-weight-bold font-size-lg text-info mb-2">
Disputable Collaborative Gigs
</h5>
<p className="mb-4 text-white-50">
All components from the General dashboard are for particiapants to create their user profiles on self.id.
</p>




<GaugeChart
animate={true} 
id="chartsGauges1B"
nrOfLevels={5}
arcWidth={0.3}
arcPadding={0.1} 
cornerRadius={3} 
arcsLength={[0.3, 0.5, 0.2]}
colors={['#5BE12C', '#F5CD19', '#EA4228']}
percent={1.00}
/>



<Button
component={NavLink}
to="/Overview"
className="btn-first badge-wrapper transition-base rounded-pill py-2 px-4 text-capitalize font-size-sm mt-3 d-inline-flex">
<span>Web3Skills SmartLaw </span>
<div
className="badge badge-warning badge-position badge-position--top-right shadow-none badge-circle"
id="NewNotificationsTooltip1">
New notifications
</div>
</Button>
</Card>
</Grid>
<Grid item md={4} xl={3} className="d-flex align-items-center">
<div className="py-2 py-xl-4">
<div className="d-flex align-items-end flex-column">
<div className="feature-box">
<h3 className="font-size-lg font-weight-bold my-3">
<div className="display-4 text-primary font-weight-bold">
Live Events &#38; Stage Shows
</div>
</h3>
<p className="text-black-50 font-size-lg mb-0">
This is just one example of what comes packed in this
template.
</p>
</div>
</div>
</div>
</Grid>
</Grid>
</Container>
</div>   
</>
);
}
