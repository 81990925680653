import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';

// import BlocksIcons1 from '../../example-components/BlocksIcons/BlocksIcons1';
import BlocksIcons2 from '../../example-components/Clecs/BlocksIcons2';
import BlocksIcons3 from '../../example-components/Clecs/BlocksIcons3';
import BlocksIcons7 from '../../example-components/Clecs/BlocksIcons7';
// import BlocksIcons9 from '../../example-components/BlocksIcons/BlocksIcons9';
import OverviewCLE from '../../example-components/Overview/OverviewCLE';
import OverviewMCLECourses from '../../example-components/Overview/OverviewMCLECourses';


import MarketingHero161 from '../../example-components/About/MarketingHero161';
// import MarketingHero1611 from '../../example-components/MarketingHero/MarketingHero1611';

import Overview5 from '../../example-components/Overview/Overview5';
import Overview51 from '../../example-components/Overview/Overview51';

export default function Clecs() {
  return (
    <>
      <PageTitle
        titleHeading="Services provided by Web3 Legal Engineering"
        titleDescription="Browse, select and purchase Web3 SmartLaw Services"
      />

      {/* <ExampleWrapperSeamless>
        <BlocksIcons1 />
      </ExampleWrapperSeamless> */}

      <ExampleWrapperSeamless>
      <OverviewMCLECourses/>
      </ExampleWrapperSeamless>
      
      {/* <ExampleWrapperSeamless>
        <MarketingHero1611 />
      </ExampleWrapperSeamless> */}

      <ExampleWrapperSeamless>
        <Overview51 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <MarketingHero161 />
      </ExampleWrapperSeamless>


      <ExampleWrapperSeamless>
      <OverviewCLE/>
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <BlocksIcons2 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <BlocksIcons7 />
      </ExampleWrapperSeamless>
 
      <ExampleWrapperSeamless>
        <BlocksIcons3 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <Overview5 />
      </ExampleWrapperSeamless>

      {/* <ExampleWrapperSeamless>
        <BlocksIcons9 />
      </ExampleWrapperSeamless> */}
    </>
  );
}
