import React from 'react';

import { Grid, Card, Button } from '@material-ui/core';

import avatar3 from '../../../assets/images/avatars/avatar3.jpg';
import avatar2 from '../../../assets/images/avatars/avatar2.jpg';
// import stock7 from '../../../assets/images/stock-photos/stock-7.jpg';
// import stock6 from '../../../assets/images/stock-photos/stock-6.jpg';

// import profileImg001 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-001.png' ;
// import profileImg002 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-002.png' ;
// import profileImg003 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
// import profileImg004 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-004.png' ;
// import profileImg005 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-005.png' ;
// import profileImg006 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-006.png' ;
// import profileImg007 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-007.png' ;
// import profileImg008 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-008.png' ;
// import profileImg009 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-009.png' ;
// import profileImg011 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-011.png' ;
// import profileImg012 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-012.png' ;
import profileImg015 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-015.png';
// import profileImg020 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-020.png' ;
// import profileImg022 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-022.png' ;
// import profileImg028 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-028.png' ;
import profileImg031 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-031.png';
// import profileImg034 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-034.png' ;
// import profileImg037 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-037.png' ;
// import profileImg041 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-041.png' ;
// import profileImg042 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-042.png' ;
// import profileImg047 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-047.png' ;
// import profileImg048 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-048.png' ;
// import profileImg054 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-054.png' ;
// import profileImg060 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-060.png' ;
// import profileImg061 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-061.png' ;

export default function LivePreviewExample() {
  return (
    <>
      <Grid container spacing={6}>
        <Grid item lg={6} className="d-flex">
          <Card>
            <div className="card-badges">
              <div className="badge badge-pill badge-warning h-auto px-3 py-1">
                Blog Posts
              </div>
            </div>
            <a
              href="#/"
              target="_blank"
              rel="noopener noreferrer"
              className="image-title-overlay d-block h-280px"
              title="...">
              <div className="image-title-overlay--bottom px-5 py-4">
                <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                  Web3Skills SmartLaw
                </h3>
              </div>
              <img
                alt="..."
                className="card-img-top img-fit-container"
                src={profileImg031}
              />
            </a>
            <div className="p-5">
              <div className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper mr-2">
                    <div className="avatar-icon">
                      <img alt="..." src={avatar2} />
                    </div>
                  </div>
                  <div>
                    <a
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold"
                      title="...">
                      Scott W Stevenson
                    </a>
                    <span className="text-black-50 d-block">
                      SmartLaw Legal Engineer
                    </span>
                  </div>
                </div>
                <div>
                  <Button className="btn-neutral-primary py-1 px-2 d-block">
                    <span className="font-size-lg font-weight-bold">23</span>
                    <span className="d-block opacity-6">Reviews</span>
                  </Button>
                </div>
              </div>
              <p className="text-black font-size-xl">
                Learn the best of Web3 without it becoming too complex. Fun,
                like infortainment.
              </p>
              <p className="card-text font-size-lg">
                Web3Skills SmartLaw is a training tool and set of protocols to
                advance the adoption of Web3 technologies. Users can freely
                browse and use Web3 applications anonymously or with
                decentralized profiles that capture the spirit of the user. The
                core of the Web3Skills SmartLaw system is a ERC-20 token that
                was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA
                and has lived a dormant life until now.BI3
              </p>
              <div className="d-flex justify-content-between pt-2">
                <Button className="btn-first hover-scale-lg">Learn more</Button>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item lg={6} className="d-flex">
          <Card>
            <div className="px-3 pt-3 position-relative">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="image-title-overlay d-block rounded d-block h-280px"
                title="...">
                <div className="card-badges">
                  <div className="badge badge-pill badge-success h-auto px-3 py-1">
                    New Content
                  </div>
                </div>
                <div className="image-title-overlay--bottom p-4">
                  <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                    Web3Skills SmartLaw
                  </h3>
                </div>
                <img
                  alt="..."
                  className="rounded img-fit-container"
                  src={profileImg015}
                />
              </a>
            </div>
            <div className="p-4">
              <div className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper mr-2">
                    <div className="avatar-icon">
                      <img alt="..." src={avatar3} />
                    </div>
                  </div>
                  <div>
                    <a
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold"
                      title="...">
                      Scott A1 Stevenson
                    </a>
                    <span className="text-black-50 d-block">
                      SmartLaw_API Web3 Legal Engineer
                    </span>
                  </div>
                </div>
                <div>
                  <Button className="btn-neutral-primary py-1 px-2 d-block">
                    <span className="font-size-lg font-weight-bold">23</span>
                    <span className="d-block opacity-6">Reviews</span>
                  </Button>
                </div>
              </div>
              <p className="text-black font-size-xl">
                Learn the best of Web3 without it becoming too complex. Fun,
                like infortainment.
              </p>
              <p className="card-text font-size-lg">
                Web3Skills SmartLaw is a training tool and set of protocols to
                advance the adoption of Web3 technologies. Users can freely
                browse and use Web3 applications anonymously or with
                decentralized profiles that capture the spirit of the user. The
                core of the Web3Skills SmartLaw system is a ERC-20 token that
                was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA
                and has lived a dormant life until now.BI3{' '}
              </p>
              <div className="d-flex justify-content-between pt-2">
                <Button className="btn-first hover-scale-lg">Learn more</Button>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
