import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  LinearProgress,
  Card,
  // CardContent,
  Button
} from '@material-ui/core';

import Slider from 'react-slick';



import topicthmb001 from '../../../assets/images/legal-dao/topic-thmb-001.png';
import topicthmb002 from '../../../assets/images/legal-dao/topic-thmb-002.png';
import topicthmb003 from '../../../assets/images/legal-dao/topic-thmb-003.png';
import topicthmb004 from '../../../assets/images/legal-dao/topic-thmb-004.png';
import topicthmb005 from '../../../assets/images/legal-dao/topic-thmb-005.png';
import topicthmb006 from '../../../assets/images/legal-dao/topic-thmb-006.png';
import topicthmb007 from '../../../assets/images/legal-dao/topic-thmb-007.png';
import topicthmb008 from '../../../assets/images/legal-dao/topic-thmb-008.png';
import topicthmb009 from '../../../assets/images/legal-dao/topic-thmb-009.png';
import topicthmb010 from '../../../assets/images/legal-dao/topic-thmb-010.png';
import topicthmb011 from '../../../assets/images/legal-dao/topic-thmb-011.png';
import topicthmb012 from '../../../assets/images/legal-dao/topic-thmb-012.png';
import topicthmb013 from '../../../assets/images/legal-dao/topic-thmb-013.png';
import topicthmb014 from '../../../assets/images/legal-dao/topic-thmb-014.png';
import topicthmb015 from '../../../assets/images/legal-dao/topic-thmb-015.png';
import topicthmb016 from '../../../assets/images/legal-dao/topic-thmb-016.png';
import topicthmb017 from '../../../assets/images/legal-dao/topic-thmb-017.png';
import topicthmb018 from '../../../assets/images/legal-dao/topic-thmb-018.png';
import topicthmb019 from '../../../assets/images/legal-dao/topic-thmb-019.png';
import topicthmb020 from '../../../assets/images/legal-dao/topic-thmb-020.png';
import topicthmb021 from '../../../assets/images/legal-dao/topic-thmb-021.png';
import topicthmb022 from '../../../assets/images/legal-dao/topic-thmb-022.png';
import topicthmb023 from '../../../assets/images/legal-dao/topic-thmb-023.png';
import topicthmb024 from '../../../assets/images/legal-dao/topic-thmb-024.png';
import topicthmb025 from '../../../assets/images/legal-dao/topic-thmb-025.png';
import topicthmb026 from '../../../assets/images/legal-dao/topic-thmb-026.png';
import topicthmb027 from '../../../assets/images/legal-dao/topic-thmb-027.png';
import topicthmb028 from '../../../assets/images/legal-dao/topic-thmb-028.png';
import topicthmb029 from '../../../assets/images/legal-dao/topic-thmb-029.png';
import topicthmb030 from '../../../assets/images/legal-dao/topic-thmb-030.png';
import topicthmb031 from '../../../assets/images/legal-dao/topic-thmb-031.png';
import topicthmb032 from '../../../assets/images/legal-dao/topic-thmb-032.png';
import topicthmb033 from '../../../assets/images/legal-dao/topic-thmb-033.png';
import topicthmb034 from '../../../assets/images/legal-dao/topic-thmb-034.png';
import topicthmb035 from '../../../assets/images/legal-dao/topic-thmb-035.png';
import topicthmb036 from '../../../assets/images/legal-dao/topic-thmb-036.png';
import topicthmb037 from '../../../assets/images/legal-dao/topic-thmb-037.png';
import topicthmb038 from '../../../assets/images/legal-dao/topic-thmb-038.png';
import topicthmb039 from '../../../assets/images/legal-dao/topic-thmb-039.png';
import topicthmb040 from '../../../assets/images/legal-dao/topic-thmb-040.png';
import topicthmb041 from '../../../assets/images/legal-dao/topic-thmb-041.png';
import topicthmb042 from '../../../assets/images/legal-dao/topic-thmb-042.png';
import topicthmb043 from '../../../assets/images/legal-dao/topic-thmb-043.png';
import topicthmb044 from '../../../assets/images/legal-dao/topic-thmb-044.png';
import topicthmb045 from '../../../assets/images/legal-dao/topic-thmb-045.png';
import topicthmb046 from '../../../assets/images/legal-dao/topic-thmb-046.png';
import topicthmb047 from '../../../assets/images/legal-dao/topic-thmb-047.png';
import topicthmb048 from '../../../assets/images/legal-dao/topic-thmb-048.png';
import topicthmb049 from '../../../assets/images/legal-dao/topic-thmb-049.png';
import topicthmb050 from '../../../assets/images/legal-dao/topic-thmb-050.png';
import topicthmb051 from '../../../assets/images/legal-dao/topic-thmb-051.png';
import topicthmb052 from '../../../assets/images/legal-dao/topic-thmb-052.png';
import topicthmb053 from '../../../assets/images/legal-dao/topic-thmb-053.png';
import topicthmb054 from '../../../assets/images/legal-dao/topic-thmb-054.png';
import topicthmb055 from '../../../assets/images/legal-dao/topic-thmb-055.png';
import topicthmb056 from '../../../assets/images/legal-dao/topic-thmb-056.png';
import topicthmb057 from '../../../assets/images/legal-dao/topic-thmb-057.png';
import topicthmb058 from '../../../assets/images/legal-dao/topic-thmb-058.png';
import topicthmb059 from '../../../assets/images/legal-dao/topic-thmb-059.png';
import topicthmb060 from '../../../assets/images/legal-dao/topic-thmb-060.png';
import topicthmb061 from '../../../assets/images/legal-dao/topic-thmb-061.png';
import topicthmb062 from '../../../assets/images/legal-dao/topic-thmb-062.png';
import topicthmb063 from '../../../assets/images/legal-dao/topic-thmb-063.png';
import topicthmb064 from '../../../assets/images/legal-dao/topic-thmb-064.png';
import topicthmb065 from '../../../assets/images/legal-dao/topic-thmb-065.png';
import topicthmb066 from '../../../assets/images/legal-dao/topic-thmb-066.png';
import topicthmb067 from '../../../assets/images/legal-dao/topic-thmb-067.png';
import topicthmb068 from '../../../assets/images/legal-dao/topic-thmb-068.png';
import topicthmb069 from '../../../assets/images/legal-dao/topic-thmb-069.png';
import topicthmb070 from '../../../assets/images/legal-dao/topic-thmb-070.png';
import topicthmb071 from '../../../assets/images/legal-dao/topic-thmb-071.png';
import topicthmb072 from '../../../assets/images/legal-dao/topic-thmb-072.png';
import topicthmb073 from '../../../assets/images/legal-dao/topic-thmb-073.png';
import topicthmb074 from '../../../assets/images/legal-dao/topic-thmb-074.png';
import topicthmb075 from '../../../assets/images/legal-dao/topic-thmb-075.png';
import topicthmb076 from '../../../assets/images/legal-dao/topic-thmb-076.png';
import topicthmb077 from '../../../assets/images/legal-dao/topic-thmb-077.png';
import topicthmb078 from '../../../assets/images/legal-dao/topic-thmb-078.png';
import topicthmb079 from '../../../assets/images/legal-dao/topic-thmb-079.png';
import topicthmb080 from '../../../assets/images/legal-dao/topic-thmb-080.png';
import topicthmb081 from '../../../assets/images/legal-dao/topic-thmb-081.png';
import topicthmb082 from '../../../assets/images/legal-dao/topic-thmb-082.png';
import topicthmb083 from '../../../assets/images/legal-dao/topic-thmb-083.png';
import topicthmb084 from '../../../assets/images/legal-dao/topic-thmb-084.png';
import topicthmb085 from '../../../assets/images/legal-dao/topic-thmb-085.png';
import topicthmb086 from '../../../assets/images/legal-dao/topic-thmb-086.png';
import topicthmb087 from '../../../assets/images/legal-dao/topic-thmb-087.png';
import topicthmb088 from '../../../assets/images/legal-dao/topic-thmb-088.png';
import topicthmb089 from '../../../assets/images/legal-dao/topic-thmb-089.png';
import topicthmb090 from '../../../assets/images/legal-dao/topic-thmb-090.png';
import topicthmb091 from '../../../assets/images/legal-dao/topic-thmb-091.png';
import topicthmb092 from '../../../assets/images/legal-dao/topic-thmb-092.png';
import topicthmb093 from '../../../assets/images/legal-dao/topic-thmb-093.png';
import topicthmb094 from '../../../assets/images/legal-dao/topic-thmb-094.png';
import topicthmb095 from '../../../assets/images/legal-dao/topic-thmb-095.png';
import topicthmb096 from '../../../assets/images/legal-dao/topic-thmb-096.png';
import topicthmb097 from '../../../assets/images/legal-dao/topic-thmb-097.png';
import topicthmb098 from '../../../assets/images/legal-dao/topic-thmb-098.png';
import topicthmb099 from '../../../assets/images/legal-dao/topic-thmb-099.png';
import topicthmb100 from '../../../assets/images/legal-dao/topic-thmb-100.png';
import topicthmb101 from '../../../assets/images/legal-dao/topic-thmb-101.png';
import topicthmb102 from '../../../assets/images/legal-dao/topic-thmb-102.png';
import topicthmb103 from '../../../assets/images/legal-dao/topic-thmb-103.png';
import topicthmb104 from '../../../assets/images/legal-dao/topic-thmb-104.png';
import topicthmb105 from '../../../assets/images/legal-dao/topic-thmb-105.png';
import topicthmb106 from '../../../assets/images/legal-dao/topic-thmb-106.png';
import topicthmb107 from '../../../assets/images/legal-dao/topic-thmb-107.png';
import topicthmb108 from '../../../assets/images/legal-dao/topic-thmb-108.png';
import topicthmb109 from '../../../assets/images/legal-dao/topic-thmb-109.png';
import topicthmb110 from '../../../assets/images/legal-dao/topic-thmb-110.png';
import topicthmb111 from '../../../assets/images/legal-dao/topic-thmb-111.png';
import topicthmb112 from '../../../assets/images/legal-dao/topic-thmb-112.png';
import topicthmb113 from '../../../assets/images/legal-dao/topic-thmb-113.png';
import topicthmb114 from '../../../assets/images/legal-dao/topic-thmb-114.png';
import topicthmb115 from '../../../assets/images/legal-dao/topic-thmb-115.png';
import topicthmb116 from '../../../assets/images/legal-dao/topic-thmb-116.png';
import topicthmb117 from '../../../assets/images/legal-dao/topic-thmb-117.png';
import topicthmb118 from '../../../assets/images/legal-dao/topic-thmb-118.png';
import topicthmb119 from '../../../assets/images/legal-dao/topic-thmb-119.png';
import topicthmb120 from '../../../assets/images/legal-dao/topic-thmb-120.png';
import topicthmb121 from '../../../assets/images/legal-dao/topic-thmb-121.png';
import topicthmb122 from '../../../assets/images/legal-dao/topic-thmb-122.png';
import topicthmb123 from '../../../assets/images/legal-dao/topic-thmb-123.png';
import topicthmb124 from '../../../assets/images/legal-dao/topic-thmb-124.png';
import topicthmb125 from '../../../assets/images/legal-dao/topic-thmb-125.png';
import topicthmb126 from '../../../assets/images/legal-dao/topic-thmb-126.png';
import topicthmb127 from '../../../assets/images/legal-dao/topic-thmb-127.png';
import topicthmb128 from '../../../assets/images/legal-dao/topic-thmb-128.png';
import topicthmb129 from '../../../assets/images/legal-dao/topic-thmb-129.png';
import topicthmb130 from '../../../assets/images/legal-dao/topic-thmb-130.png';
import topicthmb131 from '../../../assets/images/legal-dao/topic-thmb-131.png';
import topicthmb132 from '../../../assets/images/legal-dao/topic-thmb-132.png';
import topicthmb133 from '../../../assets/images/legal-dao/topic-thmb-133.png';
import topicthmb134 from '../../../assets/images/legal-dao/topic-thmb-134.png';
import topicthmb135 from '../../../assets/images/legal-dao/topic-thmb-135.png';
import topicthmb136 from '../../../assets/images/legal-dao/topic-thmb-136.png';
import topicthmb137 from '../../../assets/images/legal-dao/topic-thmb-137.png';
import topicthmb138 from '../../../assets/images/legal-dao/topic-thmb-138.png';
import topicthmb139 from '../../../assets/images/legal-dao/topic-thmb-139.png';
import topicthmb140 from '../../../assets/images/legal-dao/topic-thmb-140.png';
import topicthmb141 from '../../../assets/images/legal-dao/topic-thmb-141.png';
import topicthmb142 from '../../../assets/images/legal-dao/topic-thmb-142.png';
import topicthmb143 from '../../../assets/images/legal-dao/topic-thmb-143.png';
import topicthmb144 from '../../../assets/images/legal-dao/topic-thmb-144.png';
import topicthmb145 from '../../../assets/images/legal-dao/topic-thmb-145.png';
import topicthmb146 from '../../../assets/images/legal-dao/topic-thmb-146.png';
import topicthmb147 from '../../../assets/images/legal-dao/topic-thmb-147.png';
import topicthmb148 from '../../../assets/images/legal-dao/topic-thmb-148.png';
import topicthmb149 from '../../../assets/images/legal-dao/topic-thmb-149.png';
import topicthmb150 from '../../../assets/images/legal-dao/topic-thmb-150.png';
import topicthmb151 from '../../../assets/images/legal-dao/topic-thmb-151.png';
import topicthmb152 from '../../../assets/images/legal-dao/topic-thmb-152.png';
import topicthmb153 from '../../../assets/images/legal-dao/topic-thmb-153.png';
import topicthmb154 from '../../../assets/images/legal-dao/topic-thmb-154.png';
import topicthmb155 from '../../../assets/images/legal-dao/topic-thmb-155.png';
import topicthmb156 from '../../../assets/images/legal-dao/topic-thmb-156.png';
import topicthmb157 from '../../../assets/images/legal-dao/topic-thmb-157.png';
import topicthmb158 from '../../../assets/images/legal-dao/topic-thmb-158.png';
import topicthmb159 from '../../../assets/images/legal-dao/topic-thmb-159.png';
import topicthmb160 from '../../../assets/images/legal-dao/topic-thmb-160.png';
import topicthmb161 from '../../../assets/images/legal-dao/topic-thmb-161.png';
import topicthmb162 from '../../../assets/images/legal-dao/topic-thmb-162.png';
import topicthmb163 from '../../../assets/images/legal-dao/topic-thmb-163.png';
import topicthmb164 from '../../../assets/images/legal-dao/topic-thmb-164.png';
import topicthmb165 from '../../../assets/images/legal-dao/topic-thmb-165.png';
import topicthmb166 from '../../../assets/images/legal-dao/topic-thmb-166.png';
import topicthmb167 from '../../../assets/images/legal-dao/topic-thmb-167.png';
import topicthmb168 from '../../../assets/images/legal-dao/topic-thmb-168.png';
import topicthmb169 from '../../../assets/images/legal-dao/topic-thmb-169.png';
import topicthmb170 from '../../../assets/images/legal-dao/topic-thmb-170.png';
import topicthmb171 from '../../../assets/images/legal-dao/topic-thmb-171.png';
import topicthmb172 from '../../../assets/images/legal-dao/topic-thmb-172.png';
import topicthmb173 from '../../../assets/images/legal-dao/topic-thmb-173.png';
import topicthmb174 from '../../../assets/images/legal-dao/topic-thmb-174.png';
import topicthmb175 from '../../../assets/images/legal-dao/topic-thmb-175.png';
import topicthmb176 from '../../../assets/images/legal-dao/topic-thmb-176.png';
import topicthmb177 from '../../../assets/images/legal-dao/topic-thmb-177.png';
import topicthmb178 from '../../../assets/images/legal-dao/topic-thmb-178.png';
import topicthmb179 from '../../../assets/images/legal-dao/topic-thmb-179.png';
import topicthmb180 from '../../../assets/images/legal-dao/topic-thmb-180.png';
import topicthmb181 from '../../../assets/images/legal-dao/topic-thmb-181.png';
import topicthmb182 from '../../../assets/images/legal-dao/topic-thmb-182.png';
import topicthmb183 from '../../../assets/images/legal-dao/topic-thmb-183.png';
import topicthmb184 from '../../../assets/images/legal-dao/topic-thmb-184.png';
import topicthmb185 from '../../../assets/images/legal-dao/topic-thmb-185.png';
import topicthmb186 from '../../../assets/images/legal-dao/topic-thmb-186.png';
import topicthmb187 from '../../../assets/images/legal-dao/topic-thmb-187.png';
import topicthmb188 from '../../../assets/images/legal-dao/topic-thmb-188.png';





function SliderArrowNext(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  );
}

export default function LivePreviewExample() {

  const widgetsCarousels5A = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />
  };


  const widgetsCarousels5B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />
  };


  return (
    <>
      <Grid container spacing={6} className="align-items-center">

        <Grid item lg={6}>
          <Card className="card-box d-block p-4">
            <div className="card-tr-actions">
              <Button
                variant="text"
                className="p-0 d-30 border-0 btn-transition-none text-dark"
                disableRipple>
                <FontAwesomeIcon
                  icon={['fas', 'ellipsis-h']}
                  className="font-size-lg"
                />
              </Button>
            </div>
            <Slider {...widgetsCarousels5A}>



















            <div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden d-100">
<img alt="topicthmb001" src={topicthmb001} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 1">
LexAutomatico Discussion Topic 1
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 1
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={99}
/>
<div className="font-weight-bold text-black-50 pl-2">
99%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 1
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb002" src={topicthmb002} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 2">
LexAutomatico Discussion Topic 2
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 2
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={98.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
98.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 2
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb003" src={topicthmb003} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 3">
LexAutomatico Discussion Topic 3
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 3
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={98}
/>
<div className="font-weight-bold text-black-50 pl-2">
98%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 3
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb004" src={topicthmb004} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 4">
LexAutomatico Discussion Topic 4
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 4
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={97.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
97.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 4
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb005" src={topicthmb005} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 5">
LexAutomatico Discussion Topic 5
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 5
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={97}
/>
<div className="font-weight-bold text-black-50 pl-2">
97%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 5
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb006" src={topicthmb006} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 6">
LexAutomatico Discussion Topic 6
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 6
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={96.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
96.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 6
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb007" src={topicthmb007} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 7">
LexAutomatico Discussion Topic 7
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 7
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={96}
/>
<div className="font-weight-bold text-black-50 pl-2">
96%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 7
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb008" src={topicthmb008} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 8">
LexAutomatico Discussion Topic 8
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 8
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={95.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
95.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 8
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb009" src={topicthmb009} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 9">
LexAutomatico Discussion Topic 9
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 9
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={95}
/>
<div className="font-weight-bold text-black-50 pl-2">
95%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 9
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb010" src={topicthmb010} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 10">
LexAutomatico Discussion Topic 10
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 10
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={94.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
94.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 10
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb011" src={topicthmb011} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 11">
LexAutomatico Discussion Topic 11
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 11
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={94}
/>
<div className="font-weight-bold text-black-50 pl-2">
94%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 11
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb012" src={topicthmb012} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 12">
LexAutomatico Discussion Topic 12
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 12
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={93.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
93.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 12
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb013" src={topicthmb013} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 13">
LexAutomatico Discussion Topic 13
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 13
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={93}
/>
<div className="font-weight-bold text-black-50 pl-2">
93%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 13
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb014" src={topicthmb014} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 14">
LexAutomatico Discussion Topic 14
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 14
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={92.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
92.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 14
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb015" src={topicthmb015} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 15">
LexAutomatico Discussion Topic 15
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 15
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={92}
/>
<div className="font-weight-bold text-black-50 pl-2">
92%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 15
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb016" src={topicthmb016} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 16">
LexAutomatico Discussion Topic 16
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 16
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={91.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
91.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 16
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb017" src={topicthmb017} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 17">
LexAutomatico Discussion Topic 17
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 17
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={91}
/>
<div className="font-weight-bold text-black-50 pl-2">
91%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 17
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb018" src={topicthmb018} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 18">
LexAutomatico Discussion Topic 18
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 18
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={90.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
90.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 18
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb019" src={topicthmb019} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 19">
LexAutomatico Discussion Topic 19
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 19
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={90}
/>
<div className="font-weight-bold text-black-50 pl-2">
90%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 19
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb020" src={topicthmb020} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 20">
LexAutomatico Discussion Topic 20
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 20
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={89.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
89.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 20
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb021" src={topicthmb021} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 21">
LexAutomatico Discussion Topic 21
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 21
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={89}
/>
<div className="font-weight-bold text-black-50 pl-2">
89%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 21
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb022" src={topicthmb022} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 22">
LexAutomatico Discussion Topic 22
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 22
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={88.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
88.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 22
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb023" src={topicthmb023} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 23">
LexAutomatico Discussion Topic 23
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 23
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={88}
/>
<div className="font-weight-bold text-black-50 pl-2">
88%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 23
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb024" src={topicthmb024} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 24">
LexAutomatico Discussion Topic 24
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 24
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={87.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
87.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 24
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb025" src={topicthmb025} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 25">
LexAutomatico Discussion Topic 25
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 25
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={87}
/>
<div className="font-weight-bold text-black-50 pl-2">
87%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 25
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb026" src={topicthmb026} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 26">
LexAutomatico Discussion Topic 26
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 26
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={86.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
86.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 26
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb027" src={topicthmb027} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 27">
LexAutomatico Discussion Topic 27
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 27
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={86}
/>
<div className="font-weight-bold text-black-50 pl-2">
86%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 27
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb028" src={topicthmb028} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 28">
LexAutomatico Discussion Topic 28
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 28
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={85.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
85.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 28
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb029" src={topicthmb029} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 29">
LexAutomatico Discussion Topic 29
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 29
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={85}
/>
<div className="font-weight-bold text-black-50 pl-2">
85%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 29
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb030" src={topicthmb030} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 30">
LexAutomatico Discussion Topic 30
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 30
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={84.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
84.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 30
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb031" src={topicthmb031} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 31">
LexAutomatico Discussion Topic 31
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 31
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={84}
/>
<div className="font-weight-bold text-black-50 pl-2">
84%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 31
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb032" src={topicthmb032} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 32">
LexAutomatico Discussion Topic 32
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 32
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={83.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
83.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 32
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb033" src={topicthmb033} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 33">
LexAutomatico Discussion Topic 33
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 33
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={83}
/>
<div className="font-weight-bold text-black-50 pl-2">
83%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 33
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb034" src={topicthmb034} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 34">
LexAutomatico Discussion Topic 34
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 34
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={82.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
82.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 34
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb035" src={topicthmb035} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 35">
LexAutomatico Discussion Topic 35
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 35
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={82}
/>
<div className="font-weight-bold text-black-50 pl-2">
82%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 35
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb036" src={topicthmb036} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 36">
LexAutomatico Discussion Topic 36
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 36
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={81.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
81.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 36
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb037" src={topicthmb037} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 37">
LexAutomatico Discussion Topic 37
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 37
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={81}
/>
<div className="font-weight-bold text-black-50 pl-2">
81%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 37
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb038" src={topicthmb038} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 38">
LexAutomatico Discussion Topic 38
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 38
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={80.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
80.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 38
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb039" src={topicthmb039} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 39">
LexAutomatico Discussion Topic 39
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 39
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={80}
/>
<div className="font-weight-bold text-black-50 pl-2">
80%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 39
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb040" src={topicthmb040} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 40">
LexAutomatico Discussion Topic 40
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 40
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={79.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
79.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 40
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb041" src={topicthmb041} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 41">
LexAutomatico Discussion Topic 41
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 41
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={79}
/>
<div className="font-weight-bold text-black-50 pl-2">
79%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 41
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb042" src={topicthmb042} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 42">
LexAutomatico Discussion Topic 42
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 42
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={78.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
78.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 42
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb043" src={topicthmb043} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 43">
LexAutomatico Discussion Topic 43
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 43
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={78}
/>
<div className="font-weight-bold text-black-50 pl-2">
78%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 43
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb044" src={topicthmb044} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 44">
LexAutomatico Discussion Topic 44
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 44
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={77.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
77.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 44
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb045" src={topicthmb045} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 45">
LexAutomatico Discussion Topic 45
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 45
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={77}
/>
<div className="font-weight-bold text-black-50 pl-2">
77%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 45
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb046" src={topicthmb046} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 46">
LexAutomatico Discussion Topic 46
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 46
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={76.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
76.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 46
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>


































            </Slider>
          </Card>
        </Grid>








        <Grid item lg={6}>
          <Card className="card-box d-block p-4">
            <div className="card-tr-actions">
              <Button
                variant="text"
                className="p-0 d-30 border-0 btn-transition-none text-dark"
                disableRipple>
                <FontAwesomeIcon
                  icon={['fas', 'ellipsis-h']}
                  className="font-size-lg"
                />
              </Button>
            </div>
            <Slider {...widgetsCarousels5B}>
              
              
              
              
            <div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden d-100">
<img alt="topicthmb047" src={topicthmb047} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 47">
LexAutomatico Discussion Topic 47
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 47
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={76}
/>
<div className="font-weight-bold text-black-50 pl-2">
76%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 47
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb048" src={topicthmb048} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 48">
LexAutomatico Discussion Topic 48
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 48
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={75.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
75.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 48
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb049" src={topicthmb049} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 49">
LexAutomatico Discussion Topic 49
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 49
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={75}
/>
<div className="font-weight-bold text-black-50 pl-2">
75%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 49
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb050" src={topicthmb050} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 50">
LexAutomatico Discussion Topic 50
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 50
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={74.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
74.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 50
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb051" src={topicthmb051} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 51">
LexAutomatico Discussion Topic 51
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 51
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={74}
/>
<div className="font-weight-bold text-black-50 pl-2">
74%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 51
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb052" src={topicthmb052} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 52">
LexAutomatico Discussion Topic 52
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 52
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={73.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
73.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 52
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb053" src={topicthmb053} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 53">
LexAutomatico Discussion Topic 53
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 53
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={73}
/>
<div className="font-weight-bold text-black-50 pl-2">
73%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 53
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb054" src={topicthmb054} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 54">
LexAutomatico Discussion Topic 54
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 54
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={72.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
72.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 54
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb055" src={topicthmb055} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 55">
LexAutomatico Discussion Topic 55
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 55
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={72}
/>
<div className="font-weight-bold text-black-50 pl-2">
72%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 55
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb056" src={topicthmb056} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 56">
LexAutomatico Discussion Topic 56
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 56
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={71.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
71.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 56
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb057" src={topicthmb057} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 57">
LexAutomatico Discussion Topic 57
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 57
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={71}
/>
<div className="font-weight-bold text-black-50 pl-2">
71%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 57
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb058" src={topicthmb058} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 58">
LexAutomatico Discussion Topic 58
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 58
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={70.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
70.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 58
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb059" src={topicthmb059} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 59">
LexAutomatico Discussion Topic 59
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 59
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={70}
/>
<div className="font-weight-bold text-black-50 pl-2">
70%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 59
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb060" src={topicthmb060} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 60">
LexAutomatico Discussion Topic 60
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 60
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={69.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
69.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 60
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb061" src={topicthmb061} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 61">
LexAutomatico Discussion Topic 61
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 61
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={69}
/>
<div className="font-weight-bold text-black-50 pl-2">
69%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 61
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb062" src={topicthmb062} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 62">
LexAutomatico Discussion Topic 62
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 62
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={68.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
68.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 62
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb063" src={topicthmb063} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 63">
LexAutomatico Discussion Topic 63
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 63
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={68}
/>
<div className="font-weight-bold text-black-50 pl-2">
68%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 63
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb064" src={topicthmb064} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 64">
LexAutomatico Discussion Topic 64
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 64
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={67.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
67.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 64
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb065" src={topicthmb065} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 65">
LexAutomatico Discussion Topic 65
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 65
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={67}
/>
<div className="font-weight-bold text-black-50 pl-2">
67%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 65
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb066" src={topicthmb066} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 66">
LexAutomatico Discussion Topic 66
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 66
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={66.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
66.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 66
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb067" src={topicthmb067} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 67">
LexAutomatico Discussion Topic 67
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 67
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={66}
/>
<div className="font-weight-bold text-black-50 pl-2">
66%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 67
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb068" src={topicthmb068} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 68">
LexAutomatico Discussion Topic 68
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 68
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={65.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
65.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 68
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb069" src={topicthmb069} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 69">
LexAutomatico Discussion Topic 69
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 69
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={65}
/>
<div className="font-weight-bold text-black-50 pl-2">
65%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 69
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb070" src={topicthmb070} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 70">
LexAutomatico Discussion Topic 70
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 70
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={64.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
64.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 70
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb071" src={topicthmb071} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 71">
LexAutomatico Discussion Topic 71
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 71
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={64}
/>
<div className="font-weight-bold text-black-50 pl-2">
64%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 71
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb072" src={topicthmb072} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 72">
LexAutomatico Discussion Topic 72
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 72
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={63.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
63.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 72
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb073" src={topicthmb073} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 73">
LexAutomatico Discussion Topic 73
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 73
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={63}
/>
<div className="font-weight-bold text-black-50 pl-2">
63%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 73
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb074" src={topicthmb074} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 74">
LexAutomatico Discussion Topic 74
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 74
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={62.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
62.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 74
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb075" src={topicthmb075} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 75">
LexAutomatico Discussion Topic 75
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 75
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={62}
/>
<div className="font-weight-bold text-black-50 pl-2">
62%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 75
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb076" src={topicthmb076} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 76">
LexAutomatico Discussion Topic 76
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 76
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={61.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
61.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 76
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb077" src={topicthmb077} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 77">
LexAutomatico Discussion Topic 77
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 77
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={61}
/>
<div className="font-weight-bold text-black-50 pl-2">
61%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 77
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb078" src={topicthmb078} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 78">
LexAutomatico Discussion Topic 78
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 78
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={60.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
60.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 78
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb079" src={topicthmb079} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 79">
LexAutomatico Discussion Topic 79
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 79
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={60}
/>
<div className="font-weight-bold text-black-50 pl-2">
60%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 79
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb080" src={topicthmb080} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 80">
LexAutomatico Discussion Topic 80
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 80
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={59.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
59.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 80
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb081" src={topicthmb081} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 81">
LexAutomatico Discussion Topic 81
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 81
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={59}
/>
<div className="font-weight-bold text-black-50 pl-2">
59%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 81
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb082" src={topicthmb082} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 82">
LexAutomatico Discussion Topic 82
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 82
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={58.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
58.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 82
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb083" src={topicthmb083} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 83">
LexAutomatico Discussion Topic 83
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 83
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={58}
/>
<div className="font-weight-bold text-black-50 pl-2">
58%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 83
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb084" src={topicthmb084} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 84">
LexAutomatico Discussion Topic 84
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 84
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={57.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
57.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 84
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb085" src={topicthmb085} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 85">
LexAutomatico Discussion Topic 85
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 85
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={57}
/>
<div className="font-weight-bold text-black-50 pl-2">
57%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 85
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb086" src={topicthmb086} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 86">
LexAutomatico Discussion Topic 86
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 86
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={56.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
56.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 86
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb087" src={topicthmb087} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 87">
LexAutomatico Discussion Topic 87
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 87
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={56}
/>
<div className="font-weight-bold text-black-50 pl-2">
56%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 87
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb088" src={topicthmb088} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 88">
LexAutomatico Discussion Topic 88
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 88
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={55.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
55.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 88
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb089" src={topicthmb089} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 89">
LexAutomatico Discussion Topic 89
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 89
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={55}
/>
<div className="font-weight-bold text-black-50 pl-2">
55%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 89
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb090" src={topicthmb090} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 90">
LexAutomatico Discussion Topic 90
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 90
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={54.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
54.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 90
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb091" src={topicthmb091} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 91">
LexAutomatico Discussion Topic 91
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 91
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={54}
/>
<div className="font-weight-bold text-black-50 pl-2">
54%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 91
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb092" src={topicthmb092} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 92">
LexAutomatico Discussion Topic 92
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 92
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={53.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
53.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 92
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb093" src={topicthmb093} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 93">
LexAutomatico Discussion Topic 93
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 93
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={53}
/>
<div className="font-weight-bold text-black-50 pl-2">
53%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 93
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb094" src={topicthmb094} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 94">
LexAutomatico Discussion Topic 94
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 94
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={52.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
52.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 94
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>




            </Slider>
          </Card>
        </Grid>




        <Grid item lg={6}>
          <Card className="card-box d-block p-4">
            <div className="card-tr-actions">
              <Button
                variant="text"
                className="p-0 d-30 border-0 btn-transition-none text-dark"
                disableRipple>
                <FontAwesomeIcon
                  icon={['fas', 'ellipsis-h']}
                  className="font-size-lg"
                />
              </Button>
            </div>
            <Slider {...widgetsCarousels5B}>
              
              
              
              
            <div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden d-100">
<img alt="topicthmb095" src={topicthmb095} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 95">
LexAutomatico Discussion Topic 95
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 95
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={52}
/>
<div className="font-weight-bold text-black-50 pl-2">
52%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 95
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb096" src={topicthmb096} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 96">
LexAutomatico Discussion Topic 96
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 96
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={51.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
51.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 96
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb097" src={topicthmb097} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 97">
LexAutomatico Discussion Topic 97
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 97
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={51}
/>
<div className="font-weight-bold text-black-50 pl-2">
51%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 97
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb098" src={topicthmb098} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 98">
LexAutomatico Discussion Topic 98
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 98
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={50.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
50.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 98
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb099" src={topicthmb099} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 99">
LexAutomatico Discussion Topic 99
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 99
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={50}
/>
<div className="font-weight-bold text-black-50 pl-2">
50%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 99
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb100" src={topicthmb100} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 100">
LexAutomatico Discussion Topic 100
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 100
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={49.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
49.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 100
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb101" src={topicthmb101} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 101">
LexAutomatico Discussion Topic 101
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 101
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={49}
/>
<div className="font-weight-bold text-black-50 pl-2">
49%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 101
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb102" src={topicthmb102} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 102">
LexAutomatico Discussion Topic 102
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 102
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={48.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
48.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 102
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb103" src={topicthmb103} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 103">
LexAutomatico Discussion Topic 103
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 103
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={48}
/>
<div className="font-weight-bold text-black-50 pl-2">
48%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 103
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb104" src={topicthmb104} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 104">
LexAutomatico Discussion Topic 104
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 104
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={47.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
47.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 104
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb105" src={topicthmb105} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 105">
LexAutomatico Discussion Topic 105
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 105
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={47}
/>
<div className="font-weight-bold text-black-50 pl-2">
47%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 105
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb106" src={topicthmb106} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 106">
LexAutomatico Discussion Topic 106
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 106
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={46.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
46.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 106
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb107" src={topicthmb107} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 107">
LexAutomatico Discussion Topic 107
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 107
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={46}
/>
<div className="font-weight-bold text-black-50 pl-2">
46%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 107
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb108" src={topicthmb108} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 108">
LexAutomatico Discussion Topic 108
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 108
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={45.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
45.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 108
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb109" src={topicthmb109} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 109">
LexAutomatico Discussion Topic 109
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 109
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={45}
/>
<div className="font-weight-bold text-black-50 pl-2">
45%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 109
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb110" src={topicthmb110} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 110">
LexAutomatico Discussion Topic 110
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 110
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={44.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
44.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 110
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb111" src={topicthmb111} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 111">
LexAutomatico Discussion Topic 111
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 111
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={44}
/>
<div className="font-weight-bold text-black-50 pl-2">
44%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 111
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb112" src={topicthmb112} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 112">
LexAutomatico Discussion Topic 112
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 112
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={43.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
43.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 112
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb113" src={topicthmb113} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 113">
LexAutomatico Discussion Topic 113
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 113
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={43}
/>
<div className="font-weight-bold text-black-50 pl-2">
43%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 113
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb114" src={topicthmb114} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 114">
LexAutomatico Discussion Topic 114
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 114
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={42.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
42.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 114
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb115" src={topicthmb115} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 115">
LexAutomatico Discussion Topic 115
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 115
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={42}
/>
<div className="font-weight-bold text-black-50 pl-2">
42%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 115
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb116" src={topicthmb116} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 116">
LexAutomatico Discussion Topic 116
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 116
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={41.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
41.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 116
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb117" src={topicthmb117} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 117">
LexAutomatico Discussion Topic 117
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 117
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={41}
/>
<div className="font-weight-bold text-black-50 pl-2">
41%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 117
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb118" src={topicthmb118} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 118">
LexAutomatico Discussion Topic 118
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 118
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={40.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
40.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 118
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb119" src={topicthmb119} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 119">
LexAutomatico Discussion Topic 119
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 119
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={40}
/>
<div className="font-weight-bold text-black-50 pl-2">
40%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 119
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb120" src={topicthmb120} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 120">
LexAutomatico Discussion Topic 120
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 120
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={39.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
39.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 120
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb121" src={topicthmb121} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 121">
LexAutomatico Discussion Topic 121
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 121
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={39}
/>
<div className="font-weight-bold text-black-50 pl-2">
39%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 121
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb122" src={topicthmb122} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 122">
LexAutomatico Discussion Topic 122
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 122
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={38.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
38.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 122
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb123" src={topicthmb123} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 123">
LexAutomatico Discussion Topic 123
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 123
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={38}
/>
<div className="font-weight-bold text-black-50 pl-2">
38%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 123
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb124" src={topicthmb124} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 124">
LexAutomatico Discussion Topic 124
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 124
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={37.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
37.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 124
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb125" src={topicthmb125} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 125">
LexAutomatico Discussion Topic 125
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 125
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={37}
/>
<div className="font-weight-bold text-black-50 pl-2">
37%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 125
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb126" src={topicthmb126} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 126">
LexAutomatico Discussion Topic 126
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 126
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={36.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
36.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 126
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb127" src={topicthmb127} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 127">
LexAutomatico Discussion Topic 127
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 127
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={36}
/>
<div className="font-weight-bold text-black-50 pl-2">
36%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 127
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb128" src={topicthmb128} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 128">
LexAutomatico Discussion Topic 128
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 128
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={35.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
35.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 128
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb129" src={topicthmb129} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 129">
LexAutomatico Discussion Topic 129
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 129
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={35}
/>
<div className="font-weight-bold text-black-50 pl-2">
35%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 129
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb130" src={topicthmb130} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 130">
LexAutomatico Discussion Topic 130
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 130
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={34.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
34.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 130
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb131" src={topicthmb131} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 131">
LexAutomatico Discussion Topic 131
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 131
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={34}
/>
<div className="font-weight-bold text-black-50 pl-2">
34%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 131
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb132" src={topicthmb132} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 132">
LexAutomatico Discussion Topic 132
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 132
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={33.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
33.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 132
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb133" src={topicthmb133} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 133">
LexAutomatico Discussion Topic 133
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 133
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={33}
/>
<div className="font-weight-bold text-black-50 pl-2">
33%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 133
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb134" src={topicthmb134} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 134">
LexAutomatico Discussion Topic 134
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 134
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={32.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
32.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 134
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb135" src={topicthmb135} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 135">
LexAutomatico Discussion Topic 135
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 135
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={32}
/>
<div className="font-weight-bold text-black-50 pl-2">
32%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 135
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb136" src={topicthmb136} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 136">
LexAutomatico Discussion Topic 136
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 136
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={31.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
31.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 136
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb137" src={topicthmb137} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 137">
LexAutomatico Discussion Topic 137
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 137
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={31}
/>
<div className="font-weight-bold text-black-50 pl-2">
31%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 137
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb138" src={topicthmb138} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 138">
LexAutomatico Discussion Topic 138
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 138
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={30.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
30.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 138
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb139" src={topicthmb139} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 139">
LexAutomatico Discussion Topic 139
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 139
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={30}
/>
<div className="font-weight-bold text-black-50 pl-2">
30%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 139
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb140" src={topicthmb140} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 140">
LexAutomatico Discussion Topic 140
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 140
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={29.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
29.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 140
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb141" src={topicthmb141} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 141">
LexAutomatico Discussion Topic 141
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 141
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={29}
/>
<div className="font-weight-bold text-black-50 pl-2">
29%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 141
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>






            </Slider>
          </Card>
        </Grid>




        <Grid item lg={6}>
          <Card className="card-box d-block p-4">
            <div className="card-tr-actions">
              <Button
                variant="text"
                className="p-0 d-30 border-0 btn-transition-none text-dark"
                disableRipple>
                <FontAwesomeIcon
                  icon={['fas', 'ellipsis-h']}
                  className="font-size-lg"
                />
              </Button>
            </div>
            <Slider {...widgetsCarousels5B}>
              
              
            <div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb142" src={topicthmb142} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 142">
LexAutomatico Discussion Topic 142
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 142
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={28.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
28.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 142
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden d-100">
<img alt="topicthmb143" src={topicthmb143} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 143">
LexAutomatico Discussion Topic 143
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 143
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={28}
/>
<div className="font-weight-bold text-black-50 pl-2">
28%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 143
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb144" src={topicthmb144} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 144">
LexAutomatico Discussion Topic 144
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 144
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={27.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
27.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 144
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb145" src={topicthmb145} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 145">
LexAutomatico Discussion Topic 145
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 145
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={27}
/>
<div className="font-weight-bold text-black-50 pl-2">
27%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 145
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb146" src={topicthmb146} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 146">
LexAutomatico Discussion Topic 146
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 146
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={26.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
26.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 146
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb147" src={topicthmb147} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 147">
LexAutomatico Discussion Topic 147
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 147
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={26}
/>
<div className="font-weight-bold text-black-50 pl-2">
26%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 147
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb148" src={topicthmb148} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 148">
LexAutomatico Discussion Topic 148
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 148
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={25.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
25.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 148
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb149" src={topicthmb149} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 149">
LexAutomatico Discussion Topic 149
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 149
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={25}
/>
<div className="font-weight-bold text-black-50 pl-2">
25%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 149
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb150" src={topicthmb150} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 150">
LexAutomatico Discussion Topic 150
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 150
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={24.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
24.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 150
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb151" src={topicthmb151} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 151">
LexAutomatico Discussion Topic 151
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 151
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={24}
/>
<div className="font-weight-bold text-black-50 pl-2">
24%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 151
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb152" src={topicthmb152} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 152">
LexAutomatico Discussion Topic 152
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 152
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={23.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
23.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 152
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb153" src={topicthmb153} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 153">
LexAutomatico Discussion Topic 153
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 153
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={23}
/>
<div className="font-weight-bold text-black-50 pl-2">
23%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 153
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb154" src={topicthmb154} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 154">
LexAutomatico Discussion Topic 154
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 154
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={22.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
22.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 154
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb155" src={topicthmb155} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 155">
LexAutomatico Discussion Topic 155
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 155
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={22}
/>
<div className="font-weight-bold text-black-50 pl-2">
22%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 155
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb156" src={topicthmb156} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 156">
LexAutomatico Discussion Topic 156
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 156
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={21.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
21.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 156
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb157" src={topicthmb157} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 157">
LexAutomatico Discussion Topic 157
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 157
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={21}
/>
<div className="font-weight-bold text-black-50 pl-2">
21%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 157
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb158" src={topicthmb158} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 158">
LexAutomatico Discussion Topic 158
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 158
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={20.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
20.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 158
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb159" src={topicthmb159} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 159">
LexAutomatico Discussion Topic 159
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 159
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={20}
/>
<div className="font-weight-bold text-black-50 pl-2">
20%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 159
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb160" src={topicthmb160} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 160">
LexAutomatico Discussion Topic 160
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 160
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={19.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
19.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 160
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb161" src={topicthmb161} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 161">
LexAutomatico Discussion Topic 161
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 161
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={19}
/>
<div className="font-weight-bold text-black-50 pl-2">
19%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 161
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden ">
<img alt="topicthmb162" src={topicthmb162} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 162">
LexAutomatico Discussion Topic 162
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 162
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={18.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
18.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 162
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb163" src={topicthmb163} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 163">
LexAutomatico Discussion Topic 163
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 163
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={18}
/>
<div className="font-weight-bold text-black-50 pl-2">
18%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 163
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb164" src={topicthmb164} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 164">
LexAutomatico Discussion Topic 164
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 164
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={17.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
17.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 164
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb165" src={topicthmb165} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 165">
LexAutomatico Discussion Topic 165
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 165
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={17}
/>
<div className="font-weight-bold text-black-50 pl-2">
17%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 165
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb166" src={topicthmb166} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 166">
LexAutomatico Discussion Topic 166
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 166
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={16.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
16.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 166
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb167" src={topicthmb167} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 167">
LexAutomatico Discussion Topic 167
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 167
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={16}
/>
<div className="font-weight-bold text-black-50 pl-2">
16%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 167
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb168" src={topicthmb168} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 168">
LexAutomatico Discussion Topic 168
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 168
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={15.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
15.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 168
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb169" src={topicthmb169} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 169">
LexAutomatico Discussion Topic 169
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 169
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={15}
/>
<div className="font-weight-bold text-black-50 pl-2">
15%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 169
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb170" src={topicthmb170} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 170">
LexAutomatico Discussion Topic 170
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 170
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={14.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
14.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 170
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb171" src={topicthmb171} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 171">
LexAutomatico Discussion Topic 171
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 171
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={14}
/>
<div className="font-weight-bold text-black-50 pl-2">
14%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 171
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb172" src={topicthmb172} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 172">
LexAutomatico Discussion Topic 172
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 172
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={13.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
13.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 172
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb173" src={topicthmb173} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 173">
LexAutomatico Discussion Topic 173
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 173
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={13}
/>
<div className="font-weight-bold text-black-50 pl-2">
13%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 173
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb174" src={topicthmb174} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 174">
LexAutomatico Discussion Topic 174
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 174
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={12.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
12.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 174
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb175" src={topicthmb175} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 175">
LexAutomatico Discussion Topic 175
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 175
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={12}
/>
<div className="font-weight-bold text-black-50 pl-2">
12%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 175
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb176" src={topicthmb176} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 176">
LexAutomatico Discussion Topic 176
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 176
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={11.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
11.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 176
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb177" src={topicthmb177} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 177">
LexAutomatico Discussion Topic 177
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 177
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={11}
/>
<div className="font-weight-bold text-black-50 pl-2">
11%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 177
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb178" src={topicthmb178} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 178">
LexAutomatico Discussion Topic 178
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 178
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={10.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
10.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 178
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb179" src={topicthmb179} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 179">
LexAutomatico Discussion Topic 179
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 179
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={10}
/>
<div className="font-weight-bold text-black-50 pl-2">
10%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 179
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb180" src={topicthmb180} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 180">
LexAutomatico Discussion Topic 180
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 180
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={9.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
9.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 180
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb181" src={topicthmb181} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 181">
LexAutomatico Discussion Topic 181
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 181
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={9}
/>
<div className="font-weight-bold text-black-50 pl-2">
9%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 181
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb182" src={topicthmb182} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 182">
LexAutomatico Discussion Topic 182
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 182
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={8.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
8.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 182
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb183" src={topicthmb183} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 183">
LexAutomatico Discussion Topic 183
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 183
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={8}
/>
<div className="font-weight-bold text-black-50 pl-2">
8%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 183
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb184" src={topicthmb184} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 184">
LexAutomatico Discussion Topic 184
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 184
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={7.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
7.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 184
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb185" src={topicthmb185} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 185">
LexAutomatico Discussion Topic 185
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 185
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={7}
/>
<div className="font-weight-bold text-black-50 pl-2">
7%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 185
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb186" src={topicthmb186} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 186">
LexAutomatico Discussion Topic 186
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 186
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={6.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
6.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 186
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb187" src={topicthmb187} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 187">
LexAutomatico Discussion Topic 187
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 187
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={6}
/>
<div className="font-weight-bold text-black-50 pl-2">
6%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 187
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>
<div>
<div>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper rounded-circle mr-3">
<div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
<div className="rounded-circle overflow-hidden">
<img alt="topicthmb188" src={topicthmb188} />
</div>
</div>
</div>
<div className="w-100">
<a
href="https://lexdao-topics.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold font-size-lg"
title="LexAutomatico Topic 188">
LexAutomatico Discussion Topic 188
</a>
<span className="text-black-50 d-block">
LexAutomatico Topic Discussion 188
</span>
<div className="d-flex align-items-center pt-1">
<LinearProgress
variant="determinate"
className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
value={5.5}
/>
<div className="font-weight-bold text-black-50 pl-2">
5.5%
</div>
</div>
</div>
</div>
<p className="text-black-50 font-size-md mt-3 mb-0">
LexAutomatico 2023 Discussions – House of Automation Topic 188
</p>
<div className="mt-4 mb-5 font-size-sm p-3 bg-secondary rounded-sm">
<div className="d-flex justify-content-between">
<span className="font-weight-bold">LexAutomatico Supervisor</span>
<span className="text-black-50">Scott Stevenson, J.D.</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Member Since:</span>
<span className="text-black-50">January 2020</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Contact Info:</span>
<span className="text-black-50">@tenfinney</span>
</div>
<div className="d-flex justify-content-between py-2">
<span className="font-weight-bold">Token Id:</span>
<span className="text-black-50">0x1234561234561234561234561234</span>
</div>
<div className="d-flex justify-content-between">
<span className="font-weight-bold">DID:</span>
<span className="text-black-50">0xv49384fj234087f24f7t24fj2489fj</span>
</div>
</div>
</div>
</div>






            </Slider>
          </Card>
        </Grid>











      </Grid>
    </>
  );
}
