import React, { useState } from 'react';

import { Grid, Card, List, ListItem } from '@material-ui/core';

import GaugeChart from 'react-gauge-chart';

import space from '../../../assets/images/stock-photos/hero-10.png';
import smartlawsite from '../../../assets/images/appimages/token-minter-11-800w.png';

import logo7 from '../../../assets/images/logos/smartlaw-web3skills-royal-wm.png';

import logo1 from '../../../assets/images/achievements/web3vm-newb-1.png';
import logo2 from '../../../assets/images/achievements/web3vm-newb-2.png';
import logo3 from '../../../assets/images/achievements/web3vm-newb-3.png';
import logo4 from '../../../assets/images/achievements/web3vm-newb-4.png';
import logo5 from '../../../assets/images/achievements/web3vm-newb-5.png';
import logo6 from '../../../assets/images/achievements/web3vm-newb-6.png';

// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';


import LocalPlayTwoToneIcon from '@material-ui/icons/LocalPlayTwoTone';
import Rating from '@material-ui/lab/Rating';

export default function LivePreviewExample() {
  const [value, setValue] = useState(4);

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <Grid container spacing={0}>
          <Grid
            item
            xl={6}
            className="d-flex z-over align-items-center justify-content-center">
            <div className="text-center  w-100 p-5 ">
              <img
                alt="..."
                className="img-fluid mt-2 mx-auto rounded"
                style={{ width: '100%' }}
                src={smartlawsite}
              />
            </div>
          </Grid>

          <Grid
            item
            xl={6}
            className="d-flex z-over align-items-center justify-content-center">
            <div className="text-center w-100 pt-4 ">
              <img
                alt="..."
                className="img-fluid mt-2 mx-auto"
                style={{ height: '100px' }}
                src={logo7}
              />

              <div className="pt-3 mx-auto font-size-xl">
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </div>

              <p className="font-size-lg text-dark px-3 my-4">
                Achievments are earned by buying the course for a smalll token
                amount, reviewing the content and answering a few basic
                questions. As the skill level increases the achievents follow
                the progress and subsequent achivements are earned. Level 1 is
                considered the starter level and easiest to obtain. Level 6 is
                considered a user that has advanced skills in the achievent
                category.
              </p>

              <List className="list-group-flush mb-4 mb-lg-0 text-left">
                <ListItem
                  component="a"
                  button
                  href="#/"
                  disableRipple
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <img
                        className="img-fit-container"
                        alt="..."
                        src={logo1}
                      />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Newb Level 1 Achievement
                      </div>
                      <div className="text-black-50">Web3 Addresses</div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="badge badge-neutral-primary text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                      $1 MATIC
                    </div>
                  </div>
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  disableRipple
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <img
                        className="img-fit-container"
                        alt="..."
                        src={logo2}
                      />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Newb Level 2 Achievement
                      </div>
                      <div className="text-black-50">
                        Navigating a SmartLaw_API
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="badge badge-neutral-primary text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                      $5 MATIC
                    </div>
                  </div>
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  disableRipple
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <img
                        className="img-fit-container"
                        alt="..."
                        src={logo3}
                      />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Newb Level 3 Achievement
                      </div>
                      <div className="text-black-50">
                        Connecting a Web3 Account to a Browser
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="badge badge-neutral-primary text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                      $10 MATIC
                    </div>
                  </div>
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  disableRipple
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <img
                        className="img-fit-container"
                        alt="..."
                        src={logo4}
                      />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Newb Level 4 Achievement
                      </div>
                      <div className="text-black-50">
                        Execute a Blockchain Transaction
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="badge badge-neutral-primary text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                      $20 MATIC
                    </div>
                  </div>
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-bottom d-flex bg-white hover-scale-rounded align-items-center py-3">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <img
                        className="img-fit-container"
                        alt="..."
                        src={logo5}
                      />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Newb Level 5 Achievement
                      </div>
                      <div className="text-black-50">
                        Query a Token and Account
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="badge badge-neutral-primary text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                      $50 MATIC
                    </div>
                  </div>
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-bottom d-flex bg-white hover-scale-rounded align-items-center py-3">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <img
                        className="img-fit-container"
                        alt="..."
                        src={logo6}
                      />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Newb Level 6 Achievement
                      </div>
                      <div className="text-black-50">
                        Obtain a Player Avatar Wearable
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="badge badge-neutral-primary text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                      $100 MATIC
                    </div>
                  </div>
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid item xl={12}>
            <div className="hero-wrapper bg-composed-wrapper h-100 rounded br-xl-left-0">
              <div className="flex-grow-1 w-100 d-flex align-items-end">
                <div
                  className="bg-composed-wrapper--image rounded br-xl-left-0 opacity-9 bg-composed-filter-rm"
                  style={{ backgroundImage: 'url(' + space + ')' }}
                />
                {/* <div className="bg-composed-wrapper--bg bg-second opacity-4 rounded br-xl-left-0" /> */}
                {/* <div className="bg-composed-wrapper--bg bg-night-sky opacity-5 rounded br-xl-left-0" /> */}
                {/* <div className="bg-composed-wrapper--bg bg-mixed-hopes opacity-5 rounded br-xl-left-0" /> */}
                <div className="bg-composed-wrapper--content align-self-center p-4 p-xl-5">
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <Card className="bg-night-skyer card-box-hover-rise card-box-hover rounded-lg text-center p-3 p-xl-4 mb-4 mb-md-0 d-block">
                        <div className="d-100 object-skew hover-scale-sm icon-blob btn-icon text-first mx-auto">
                          <div className="blob-icon-wrapper d-flex align-items-center justify-content-center">
                            <div className="badge-position text-white d-50">
                              <VerifiedUserTwoToneIcon className="text-white w-100 h-100" />
                            </div>


                          </div>
                        </div>
                        <h5 className="font-weight-bold font-size-xl text-white mb-2">
                          Achievement Tokens Issued
                        </h5>
                        <p className="mb-4 text-white-50">
                          Achievement Tokens are earned but are not
                          transferable. They can be used to access contents and
                          areas of the metaverse where they are required.
                        </p>
                        <GaugeChart
                          id="chartsGauges1A"
                          nrOfLevels={24}
                          colors={['rgb(173,255,47)', 'rgba(255,0,3,0.6)']}
                          arcWidth={0.3}
                          percent={0.67}
                        />
                        <a
                          href="#/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="badge-wrapper transition-base rounded-pill py-2 px-4 text-capitalize font-size-sm bg-white-10 text-white mt-3 d-inline-flex">
                          <span>SmartLaw Tokens Dashboard</span>
                        </a>
                      </Card>
                    </Grid>
                    <Grid item md={6}>
                      <Card className="bg-night-skyer card-box-hover-rise card-box-hover rounded-lg text-center p-3 p-xl-4 d-block">
                        <div className="d-100 object-skew hover-scale-sm icon-blob btn-icon text-success mx-auto">
                          <div className="blob-icon-wrapper d-flex align-items-center justify-content-center">
                            <div className="badge-position d-50">
                              <LocalPlayTwoToneIcon className="w-100 h-100" />
                              <div className="badge badge-success badge-position shadow-sm-dark pulse-animation badge-position--bottom-right badge-circle">
                                Pending
                              </div>
                            </div>
                          </div>
                        </div>
                        <h5 className="font-weight-bold font-size-xl text-white mb-2">
                          Access Tokens Issued
                        </h5>
                        <p className="mb-4 text-white-50">
                          Access Tokens are earned, purchased or air-dropped and
                          can be used to access contents and areas of the
                          metaverse where they are required.
                        </p>
                        <GaugeChart
                          id="chartsGauges1B"
                          nrOfLevels={24}
                          colors={['rgb(173,255,47)', 'rgba(255,0,3,0.6)']}
                          arcWidth={0.3}
                          percent={0.43}
                        />
                        <a
                          href="#/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="badge-wrapper transition-base rounded-pill py-2 px-4 text-capitalize font-size-sm bg-white-10 text-white mt-3 d-inline-flex">
                          <span>SmartLaw Tokens Dashboard</span>
                          <div className="badge badge-success badge-position badge-position--top-right shadow-sm-dark badge-circle">
                            Finished
                          </div>
                        </a>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
