import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';

import MarketingPartners3 from '../../example-components/MarketingPartners/MarketingPartners3';
import MarketingPartners21 from '../../example-components/MarketingPartners/MarketingPartners21';
import MarketingPartners22 from '../../example-components/MarketingPartners/MarketingPartners22';

export default function MarketingPartners() {
  return (
    <>
      <PageTitle
        titleHeading="Achievement Token Catalog"
        titleDescription="Achievement tokens are earned by doing tasks. A non-transferable ERC-1155 token is issued once the course lesson is completed"
      />

      <ExampleWrapperSeamless>
        <MarketingPartners21 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <MarketingPartners22 />
      </ExampleWrapperSeamless>


      <ExampleWrapperSeamless>
        <MarketingPartners3 />
      </ExampleWrapperSeamless>

    </>
  );
}
