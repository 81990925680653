import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, CardContent, Button } from '@material-ui/core';

import avatar1 from '../../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../../assets/images/avatars/avatar2.jpg';
import avatar3 from '../../../assets/images/avatars/avatar3.jpg';

// import stock4 from '../../../assets/images/stock-photos/stock-4.jpg';
// import stock5 from '../../../assets/images/stock-photos/stock-5.jpg';
// import stock6 from '../../../assets/images/stock-photos/stock-6.jpg';

// import profileImg001 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-001.png' ;
// import profileImg002 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-002.png' ;
// import profileImg003 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png' ;
// import profileImg004 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-004.png' ;
// import profileImg005 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-005.png' ;
// import profileImg006 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-006.png' ;
// import profileImg007 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-007.png' ;
// import profileImg008 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-008.png' ;
// import profileImg009 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-009.png' ;
import profileImg011 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-011.png' ;
// import profileImg012 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-012.png' ;
// import profileImg015 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-015.png' ;
// import profileImg020 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-020.png' ;
// import profileImg022 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-022.png' ;
// import profileImg028 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-028.png' ;
// import profileImg031 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-031.png' ;
// import profileImg034 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-034.png' ;
import profileImg037 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-037.png' ;
// import profileImg041 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-041.png' ;
// import profileImg042 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-042.png' ;
// import profileImg047 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-047.png' ;
// import profileImg048 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-048.png' ;
import profileImg054 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-054.png' ;
// import profileImg060 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-060.png' ;
// import profileImg061 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-061.png' ;

export default function LivePreviewExample() {
  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item xl={4} lg={6}>
            <a
              className="card card-box-hover-rise bg-white"
              href="#/"
              target="_blank" 
rel="noopener noreferrer"

>

              <div className="card-badges">
                <div className="badge badge-pill badge-first">New Courses</div>
              </div>
              <img alt="..." className="card-img-top" src={profileImg011} />
              <CardContent className="card-body-avatar px-4 pb-4">
                <div className="avatar-icon-wrapper rounded-pill border-white border-3 avatar-icon-wrapper--sm">
                  <div className="avatar-icon rounded-pill">
                    <img alt="..." src={avatar2} />
                  </div>
                </div>
                <h5 className="card-title font-weight-bold font-size-xl">
                Web3Skills SmartLaw
                </h5>
                <p className="card-text">
                Learn the best of Web3 without it becoming too complex. Fun, like infortainment.
                </p>
                <div className="card-date text-black-50 mt-2">
                  <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                  <small>added moments ago</small>
                </div>
                <br />
                <div className="text-center">
                  <Button
                    size="small"
                    variant="text"
                    className="btn-outline-first hover-scale-sm btn-pill mt-1">
                    View Details
                  </Button>
                </div>
              </CardContent>
            </a>
          </Grid>
          <Grid item xl={4} lg={6}>
            <a
              className="card card-box-hover-rise bg-white"
              href="#/"
              target="_blank" 
rel="noopener noreferrer"

>

              <div className="card-badges">
                <div className="badge badge-pill badge-first mr-2">
                  Collaborations
                </div>
              </div>
              <img alt="..." className="card-img-top" src={profileImg037} />
              <CardContent className="card-body-avatar px-4 pb-4">
                <div className="avatar-icon-wrapper rounded-pill border-white border-3 avatar-icon-wrapper--sm">
                  <div className="avatar-icon rounded-pill">
                    <img alt="..." src={avatar1} />
                  </div>
                </div>
                <h5 className="card-title font-weight-bold font-size-xl">
                Web3Skills SmartLaw
                </h5>
                <p className="card-text">
                Learn the best of Web3 without it becoming too complex. Fun, like infortainment.
                </p>
                <div className="card-date text-black-50 mt-2">
                  <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                  <small>added moments ago</small>
                </div>
                <br />
                <div className="text-center">
                  <Button
                    size="small"
                    variant="text"
                    className="btn-outline-first hover-scale-sm btn-pill mt-1">
                    View Details
                  </Button>
                </div>
              </CardContent>
            </a>
          </Grid>
          <Grid item xl={4} lg={12}>
            <a
              className="card card-box-hover-rise bg-white"
              href="#/"
              target="_blank" 
rel="noopener noreferrer"

>

              <div className="card-badges">
                <div className="badge badge-primary">WorldLaw Evidencer</div>
              </div>
              <img alt="..." className="card-img-top" src={profileImg054} />
              <CardContent className="card-body-avatar px-4 pb-4">
                <div className="avatar-icon-wrapper rounded-pill border-white border-3 avatar-icon-wrapper--sm">
                  <div className="avatar-icon rounded-pill">
                    <img alt="..." src={avatar3} />
                  </div>
                </div>
                <h5 className="card-title font-weight-bold font-size-xl">
                Web3Skills SmartLaw
                </h5>
                <p className="card-text">
                Learn the best of Web3 without it becoming too complex. Fun, like infortainment.
                </p>
                <div className="card-date text-black-50 mt-2">
                  <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                  <small>added moments ago</small>
                </div>
                <br />
                <div className="text-center">
                  <Button
                    size="small"
                    variant="text"
                    className="btn-outline-first hover-scale-sm btn-pill mt-1">
                    View Details
                  </Button>
                </div>
              </CardContent>
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
