import React from 'react';

// https://www.section.io/engineering-education/integrate-airtable-with-nextjs/
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/legend
// https://community.airtable.com/t/using-react-frontend-form-to-connect-to-airtable/49345
// https://blog.jakoblind.no/react-forms-hooks/

import { PageTitle } from 'layout-components';
// import { Grid } from '@material-ui/core';

import { ExampleWrapperSeamless } from 'layout-components';
import { ExampleWrapperSimple } from 'layout-components';
import FormsControls1 from '../../example-components/FormsControls/Metadata';
import PageProfileGrants from '../../example-components/PageProfileGrants/PageProfile1';
import PageRegisterCover1 from '../../example-components/PageRegisterCover/Did';
import PageRegisterGrantee from '../../example-components/PageRegisterGrantee/PageRegisterCover1';
import PageRegisterGrant from '../../example-components/PageRegisterGrant/PageRegisterCover1';
// import FormsDatepicker1 from '../../example-components/FormsDatepicker/FormsDatepicker1';
// import FormsDatepicker2 from '../../example-components/FormsDatepicker/FormsDatepicker2';
// import FormsInputSelect1 from '../../example-components/FormsInputSelect/FormsInputSelect1';
// import FormsInputSelect2 from '../../example-components/FormsInputSelect/FormsInputSelect2';
import FormsTextareaAutosize1 from '../../example-components/FormsTextareaAutosize/FormsTextareaAutosize1';
// import FormsWysiwygEditor1 from '../../example-components/FormsWysiwygEditor/FormsWysiwygEditor1';
// import FormsWysiwygEditor2 from '../../example-components/FormsWysiwygEditor/FormsWysiwygEditor2';
import FormsClipboard2 from '../../example-components/FormsClipboard/FormsClipboard2';
import FormsTypeahead1 from '../../example-components/FormsTypeahead/FormsTypeahead1';
import FormsTypeahead2 from '../../example-components/FormsTypeahead/FormsTypeahead2';
import FormsTypeahead3 from '../../example-components/FormsTypeahead/FormsTypeahead3';
import FormsTypeahead4 from '../../example-components/FormsTypeahead/FormsTypeahead4';
// import FormsSlider1 from '../../example-components/FormsSlider/FormsSlider1';
// import FormsSlider3 from '../../example-components/FormsSlider/FormsSlider3';
import FormsValidation1 from '../../example-components/Claimant/FormsValidation1';
import FormsValidation2 from '../../example-components/Claimant/FormsValidation2';
// import ElementsProgressBars4 from '../../example-components/ElementsProgressBars/ProgressBars4';
// import ElementsProgressBars5 from '../../example-components/ElementsProgressBars/ProgressBars5';

// import Iframe from 'react-iframe';

export default function Grmake() {
  return (
    <>
      <PageTitle
        titleHeading="SmartLaw Grant Proposals"
        titleDescription="Fill out the following forms to be considered for a SmartLaw Grant from Web3 Legal Engineering."
      />

      <ExampleWrapperSeamless>
        <PageProfileGrants />
      </ExampleWrapperSeamless>

      <ExampleWrapperSimple>
        <FormsTypeahead3 />
      </ExampleWrapperSimple>

      <ExampleWrapperSeamless>
        <PageRegisterCover1 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSimple>
        <PageRegisterGrantee />
      </ExampleWrapperSimple>

      <ExampleWrapperSimple>
        <FormsTypeahead2 />
      </ExampleWrapperSimple>

      <ExampleWrapperSimple>
        <PageRegisterGrant />
      </ExampleWrapperSimple>




      <ExampleWrapperSeamless>
        <FormsControls1 />
      </ExampleWrapperSeamless>



      <ExampleWrapperSeamless>
        <FormsTextareaAutosize1 />
      </ExampleWrapperSeamless>


      <ExampleWrapperSimple>
        <FormsTypeahead1 />
      </ExampleWrapperSimple>



      <ExampleWrapperSeamless>
        <FormsTypeahead4 />
      </ExampleWrapperSeamless>



      <ExampleWrapperSimple>
        <FormsValidation1 />
      </ExampleWrapperSimple>



      <ExampleWrapperSimple>
        <FormsClipboard2 />
      </ExampleWrapperSimple>


      <ExampleWrapperSimple>
        <FormsValidation2 />
      </ExampleWrapperSimple>
    </>
  );
}
