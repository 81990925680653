import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, Button } from '@material-ui/core';

import hero6 from '../../../assets/images/hero-bg/hero-6.jpg';
import ReactPlayer from 'react-player';



export default function LivePreviewExample() {
  return (
    <>


      <div className="hero-wrapper bg-composed-wrapper bg-deep-sky">

        <div className="divider bg-white-5" />
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image"
            style={{ backgroundImage: 'url(' + hero6 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
          <div className="bg-composed-wrapper--content">
            <div className="py-5">
              <div className="pb-0 pb-lg-4">
                <Container className="text-white pb-5">
                  <Grid container spacing={6} className="py-5">
                    <Grid item lg={6} className="d-flex align-items-center">
                      <div className="text-center text-lg-left">
                        <h2 className="display-3 font-weight-bold text-white">
                          Web3 Courses presented in the open metaverse
                        </h2>
                        <p className="font-size-xl py-3 text-white">
                          Web3Skills SmartLaw is a training tool and set of
                          protocols to advance the adoption of Web3
                          technologies. Users can freely browse and use Web3
                          applications anonymously or with decentralized
                          profiles that capture the spirit of the user.
                        </p>
                        <div className="pt-3">
                          <Button
                            href="https://tokens.freeweb3.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            size="large"
                            className="btn-pill shadow-second-sm btn-first bg-night-sky">
                            <span className="btn-wrapper--label">
                              View Achievements
                            </span>
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </span>
                          </Button>
                          <Button
                            href="https://tokens.freeweb3.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            size="large"
                            className="btn-primary bg-night-skyer btn-pill ml-3">
                            <span>Earn Achievements</span>
                          </Button>
                        </div>
                      </div>
                    </Grid>






                    <Grid
                      item
                      lg={6}
                      className="d-flex align-items-center mt-5 mt-lg-0 justify-content-center">
                      <div className="object-skew d-flex flex-wrap align-items-center justify-content-center">
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-xl btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/Qmf2LJh5qv3vLTqiHPBcsDkBNjXNEvCYragoF2oZueQT2i"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x004"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/Qmf2LJh5qv3vLTqiHPBcsDkBNjXNEvCYragoF2oZueQT2i"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x005"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmbXqBCxUi7oVjr7mM1xdnrMsZpsF422ySUvEWogx9v3yB"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x006"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmPvvnMrRJwbLzrjN4FNWA7PM98j8zWHgBCAXhr76kbJNb"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x007"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmXA94kFj7sPPi6q1DnEm6NRVcat2ABPL5N5fQSrQfqaEK"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x008"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmbEVoBnwfiJ6Bs3SDLGmJkFnnGrZugaSvvzANGVnafhi7"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x009"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmRSMm5PJ4wBdTVjWJJcqiewGzKWxuCF8N1nM248GUZnjY"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x010"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmTrcy1pvtyM1fYFmXzHP7jLTf1fvBQ4ngXnNy7pGxb4UA"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x011"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmWG5JLWaTpLvnDcZA6iju5G7LK99yxp7oDPfR6GAj5Zjt"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x012"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmUcigqfSnUHD7VeuxgdXQv5sqSv2DFrfXnfEv3JTi4aJE"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x013"
                            />
                          </div>
                        </div>
                        <div className="m-3 hover-scale-lg">
                          <div className="rounded-sm btn-icon d-120 shadow-sm-dark bg-secondary hover-scale-rounded d-flex align-self-stretch">
                            <img
                              src="https://freeweb3.infura-ipfs.io/ipfs/QmP7ERzhp1fW7xpGz942YaaM21DJnpPn6mt8XEarrm8RWY"
                              style={{ height: 120 }}
                              className="m-auto img-fluid"
                              alt="stageverse2023320x014"
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
            <div className="shape-container-top-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="var(--light)"
                  fillOpacity="1"
                  d="M0,64L48,64C96,64,192,64,288,80C384,96,480,128,576,122.7C672,117,768,75,864,101.3C960,128,1056,224,1152,224C1248,224,1344,128,1392,80L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
              </svg>
            </div>

            <Container>
              <Grid item xs={12} md={12} className="p-3">
                <div className="text-center">
                  <div className="mt-3 line-height-sm">
                    <div className="video-wrapper mb-1 mt-1">
                      <ReactPlayer
                        url={
                          'https://freeweb3.infura-ipfs.io/ipfs/QmQeG6tk3h7w644rYW6beSUeQrsfYKrx1jNu5AU4TW3US2'
                        }
                        width="100%"
                        height="100%"
                        controls={true}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Container>

          </div>
        </div>
      </div>








    </>
  );
}
