import React from 'react';

import { Grid } from '@material-ui/core';



import lj0001400w from '../../../assets/images/lady-justice/00-01-400w.png';
import lj0003400w from '../../../assets/images/lady-justice/00-03-400w.png';
import lj0004400w from '../../../assets/images/lady-justice/00-04-400w.png';
import lj0005400w from '../../../assets/images/lady-justice/00-05-400w.png';
import lj0006400w from '../../../assets/images/lady-justice/00-06-400w.png';
import lj0007400w from '../../../assets/images/lady-justice/00-07-400w.png';
import lj0014400w from '../../../assets/images/lady-justice/00-14-400w.png';
import phase0310ladyjusticehistory400w from '../../../assets/images/lady-justice/phase03-10-lady-justice-history-400w.png';


export default function LivePreviewExample() {
  return (
    <>
      <div className="mb-spacing-6-x2">
        <Grid container spacing={6}>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={lj0001400w}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  Smart-Contract Wizard
                </div>
                <p className="mb-0 text-black-50">
                  Describe how OpenZeppelin's smart-contract Wizard works and
                  explain the best way to implement the use of it's features.
                </p>
                <br />
                <div className="font-size-xs mb-0 text-black-50">
                  OpenZeppelin's smart contract Wizard is a web-based tool that
                  allows developers to quickly and easily create custom smart
                  contracts using pre-built templates and modules. The Wizard is
                  designed to make it easier for developers to create secure and
                  reliable smart contracts, without needing to have in-depth
                  knowledge of Solidity or blockchain development.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  The Wizard offers several features, including:
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Template selection
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  The Wizard offers a range of templates for
                  different types of smart contracts, such as ERC-20 tokens,
                  ERC-721 tokens, and more. Developers can select the template
                  that best suits their needs, and then customize it as
                  necessary.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Module selection
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  The Wizard also offers a range of modules
                  that can be added to a smart contract to provide additional
                  functionality, such as access control, gas optimization, and
                  more. Developers can select the modules that they need and add
                  them to their smart contract.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Customization
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Once a template and modules have been selected,
                  developers can customize their smart contract by modifying the
                  parameters and variables in the contract. The Wizard provides
                  a user-friendly interface for making these changes, with clear
                  explanations of what each parameter does and how it affects
                  the contract.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Code generation
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Once the smart contract has been customized,
                  the Wizard generates the Solidity code for the contract
                  automatically. Developers can then download the code and use
                  it in their own projects.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  To implement the use of the Wizard's features effectively,
                  developers should follow these best practices:
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Choose the right template
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  The Wizard offers a range of
                  templates, each designed for a specific type of smart
                  contract. Developers should choose the template that best
                  matches their needs, as this will make it easier to customize
                  the contract and ensure that it includes all the necessary
                  functionality.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Only add necessary modules: The Wizard offers a range of
                  modules that can be added to a smart contract, but developers
                  should only add the modules that they actually need. Adding
                  unnecessary modules can make the contract more complex and
                  potentially less secure.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Test thoroughly
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Before deploying a smart contract created
                  with the Wizard, developers should thoroughly test it to
                  ensure that it works as expected and is secure. This can
                  include unit testing, integration testing, and more.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Keep up-to-date
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  The Wizard is regularly updated with new
                  templates and modules, as well as bug fixes and security
                  updates. Developers should make sure to keep up-to-date with
                  these updates to ensure that their smart contracts remain
                  secure and reliable.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Overall, the Wizard is a powerful tool that can help
                  developers create secure and reliable smart contracts quickly
                  and easily. By following these best practices, developers can
                  make the most of its features and ensure that their contracts
                  are of high quality.
                </div>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={lj0007400w}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  Governance Smart-Contract
                </div>
                <p className="mb-0 text-black-50">
                  What is the most popular governance smart-contracts found
                  found at OpenZeppelin's Github repository?
                </p>
                <br />
                <div className="font-size-xs mb-0 text-black-50 mt-2">
                  OpenZeppelin's Github repository includes several smart
                  contracts that can be used for governance purposes, such as
                  managing voting rights, decision-making, and more. Among
                  these, the most popular governance smart contract found in
                  OpenZeppelin's Github repository is the "Governance" contract.
                </div>
                <div className="font-size-xs mb-0 text-black-50 mt-2">
                  The Governance contract is designed to facilitate on-chain
                  governance for decentralized autonomous organizations (DAOs)
                  and other blockchain-based projects. It includes features such
                  as proposal creation, voting, and execution, as well as
                  mechanisms for managing voting power and decision-making
                  thresholds. The contract is highly customizable, allowing
                  developers to configure a wide range of parameters to suit the
                  needs of their particular application.
                </div>
                <div className="font-size-xs mb-0 text-black-50 mt-2">
                  In addition to the Governance contract, OpenZeppelin's
                  repository includes other smart contracts that can be used for
                  governance, such as the "AccessControl" contract, which
                  provides a flexible and secure way to manage roles and
                  permissions within a smart contract, and the "Ownable"
                  contract, which provides a basic ownership mechanism.
                </div>
                <div className="font-size-xs mb-0 text-black-50 mt-2">
                  Overall, OpenZeppelin's Github repository provides a range of
                  options for developers looking to implement governance
                  mechanisms in their smart contracts, and the Governance
                  contract is a popular choice for those building DAOs and other
                  decentralized applications.
                </div>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={lj0004400w}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  Governance Functions
                </div>
                <p className="mb-0 text-black-50">
                  Explain in detail the functions of the Governance smart
                  contract found in OpenZeppelin's Github repository?
                </p>
                <br />
                <div className="font-size-xs mb-0 text-black-50">
                  The Governance smart contract found in OpenZeppelin's Github
                  repository is designed to facilitate on-chain governance for
                  decentralized autonomous organizations (DAOs) and other
                  blockchain-based projects. It includes a variety of functions
                  and features that allow stakeholders to propose and vote on
                  changes to the system, as well as mechanisms for managing
                  voting power and decision-making thresholds.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Here is a breakdown of the main functions of the Governance
                  contract:
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Proposal creation
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  The Governance contract allows stakeholders
                  to create proposals for changes to the system. Proposals can
                  be submitted by any address that has the necessary
                  permissions, and can include details such as the proposed
                  change, the rationale behind it, and any relevant technical
                  specifications.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Voting
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  Once a proposal has been submitted, stakeholders can
                  vote on whether or not to accept it. Each address that has
                  voting power can cast a vote, with the weight of the vote
                  depending on the amount of voting power held by the address.
                  The Governance contract includes several different voting
                  strategies, such as simple majority, supermajority, and
                  quadratic voting.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Execution
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  If a proposal is approved by the necessary number
                  of votes, it can be executed automatically by the Governance
                  contract. The contract will check that all necessary
                  conditions have been met, and then carry out the proposed
                  change.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Voting power management
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The Governance contract includes
                  mechanisms for managing voting power and decision-making
                  thresholds. For example, it allows voting power to be
                  transferred between addresses, or to be delegated to a trusted
                  third party. It also allows for different levels of
                  decision-making thresholds to be set for different types of
                  proposals, depending on the importance or complexity of the
                  proposed change.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Proposal tracking
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The Governance contract keeps track of all
                  proposals that have been submitted, including their status
                  (such as "in progress", "passed", or "failed"), their voting
                  results, and the date they were submitted. This allows
                  stakeholders to easily track the progress of proposals and
                  keep up-to-date with any changes to the system.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Overall, the Governance smart contract provides a flexible and
                  customizable framework for on-chain governance, allowing DAOs
                  and other decentralized applications to manage decision-making
                  and propose changes to the system in a transparent and
                  democratic way.
                </div>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={lj0005400w}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  Governance Standards
                </div>
                <p className="mb-0 text-black-50">
                  What are the most popular ERC standards used on the Ethereum
                  blockchain for governance and member management?
                </p>
                <br />
                <div className="font-size-xs mb-0 text-black-50">
                  The Ethereum blockchain has several ERC standards that can be
                  used for governance and member management. Some of the most
                  popular ERC standards for these purposes include:
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-20
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  While ERC-20 is primarily used for creating fungible
                  tokens, it can also be used for governance and member
                  management. Many decentralized autonomous organizations (DAOs)
                  and other blockchain-based projects use ERC-20 tokens as a way
                  to manage voting rights and decision-making within their
                  communities.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-223
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-223 standard is similar to ERC-20, but with
                  additional features designed to prevent token loss and other
                  issues. It can be used for governance and member management in
                  the same way as ERC-20.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-1155
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  While ERC-1155 is primarily used for creating both
                  fungible and non-fungible tokens, it can also be used for
                  governance and member management. It includes features such as
                  batch transfers and multi-token contracts, which can be used
                  to manage voting rights and other governance functions within
                  a community.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-1404
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-1404 standard is specifically designed for
                  security tokens and includes features that make it easier to
                  comply with regulatory requirements. It can be used for
                  governance and member management in the context of security
                  token offerings (STOs) and other similar applications.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-900
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-900 standard is a new standard for managing
                  membership in decentralized autonomous organizations (DAOs).
                  It includes features such as membership tracking, voting
                  rights management, and more.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Overall, these ERC standards provide a variety of options for
                  governance and member management on the Ethereum blockchain,
                  and can be used in a variety of decentralized applications and
                  platforms.
                </div>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={lj0006400w}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  ERC-20 Token Standard.
                </div>
                <p className="mb-0 text-black-50">
                  Explain the functions of an ERC-20 token.
                </p>
                <br />
                <div className="font-size-xs mb-0 text-black-50">
                  An ERC-20 token is a standard for fungible tokens on the
                  Ethereum blockchain. It allows developers to create and manage
                  digital assets that are interchangeable and can represent any
                  type of asset or utility.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Here are some of the key functions of an ERC-20 token:
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Transferability
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-20 tokens can be transferred between
                  users, just like any other cryptocurrency. This allows for the
                  easy exchange of tokens for other assets or currencies.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Smart contract integration
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-20 tokens can be integrated
                  into smart contracts, allowing for the creation of complex
                  decentralized applications (dApps) that can manage, exchange,
                  or utilize these tokens.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Interchangeability
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  Each ERC-20 token is identical and
                  interchangeable with every other token of the same type. This
                  means that one token is always worth the same as any other
                  token of the same type, regardless of who owns it.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Divisibility
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-20 tokens can be divided into smaller units,
                  making them highly divisible and flexible. This allows for the
                  easy exchange of tokens for other assets or currencies.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Wide adoption
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-20 standard is widely adopted by many
                  blockchain projects, making it easy to integrate with other
                  platforms and services.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Overall, ERC-20 tokens provide a powerful and flexible way to
                  represent any type of asset or utility on the Ethereum
                  blockchain. They offer new opportunities for creators and
                  businesses to create digital assets, incentivize users, and
                  build decentralized applications.
                </div>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={lj0003400w}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  721 Token Standard.
                </div>
                <p className="mb-0 text-black-50">
                  Explain the functions of an ERC-721 token.
                </p>
                <br />
                <div className="font-size-xs mb-0 text-black-50">
                  An ERC-721 token is a non-fungible token standard on the
                  Ethereum blockchain, meaning that each token is unique and not
                  interchangeable. It allows developers to create digital assets
                  that are one-of-a-kind and can represent ownership of
                  real-world or virtual items.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Here are some of the key functions of an ERC-721 token:
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Transferability
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  ERC-721 tokens can be transferred between
                  users, just like any other cryptocurrency. This allows for the
                  ownership of the underlying asset to be transferred without
                  the need for a physical transfer.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Smart contract integration
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-721 tokens can be integrated
                  into smart contracts, allowing for the creation of complex
                  decentralized applications (dApps) that can manage, exchange,
                  or utilize these tokens.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Interoperability
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-721 tokens are interoperable with other
                  Ethereum-based standards, such as ERC-20 and ERC-1155. This
                  means that they can be used in a wide range of applications
                  and platforms, making them more versatile and valuable.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Built-in metadata
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-721 tokens can include built-in
                  metadata, such as descriptions, images, and other information
                  about the token. This can be useful for creating rich and
                  interactive user experiences.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Unique ownership
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  Each ERC-721 token is unique, with its own
                  distinct identifier. This means that ownership of a particular
                  token can be verified on the blockchain, and the token itself
                  can represent ownership of a specific asset, such as a piece
                  of art, a collectible, or a real estate property.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Scarcity and rarity
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-721 tokens can be used to create
                  digital assets that are scarce and rare, which can increase
                  their value and appeal to collectors.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Customizable attributes
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-721 tokens can have customizable
                  attributes that can be used to describe the underlying asset,
                  such as the artist, year of creation, and other relevant
                  details.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Wide adoption
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-721 standard is widely adopted by many
                  blockchain projects, making it easy to integrate with other
                  platforms and services.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Overall, ERC-721 tokens provide a powerful way to represent
                  ownership of unique digital assets on the Ethereum blockchain.
                  They offer new opportunities for creators and collectors
                  alike, and have the potential to revolutionize ownership and
                  asset management in a variety of industries.
                </div>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={phase0310ladyjusticehistory400w}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  1155 Token Standard
                </div>
                <p className="mb-0 text-black-50">
                  Explain the functions of an ERC-1155 token.
                </p>
                <br />
                <div className="font-size-xs mb-0 text-black-50">
                  An ERC-1155 token is a standard for fungible and non-fungible
                  tokens on the Ethereum blockchain. It was introduced as an
                  improvement over the ERC-20 and ERC-721 token standards,
                  offering more flexibility and efficiency.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Here are some of the key functions of an ERC-1155 token:
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Transferability
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-1155 tokens can be transferred between
                  users, just like any other cryptocurrency. This allows for the
                  ownership of the underlying asset to be transferred without
                  the need for a physical transfer.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Smart contract integration
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-1155 tokens can be integrated
                  into smart contracts, allowing for the creation of complex
                  decentralized applications (dApps) that can manage, exchange,
                  or utilize these tokens.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Interoperability
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-1155 tokens are interoperable with other
                  Ethereum-based standards, such as ERC-20 and ERC-721. This
                  means that they can be used in a wide range of applications
                  and platforms, making them more versatile and valuable.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Built-in metadata
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-1155 tokens can include built-in
                  metadata, such as descriptions, images, and other information
                  about the token. This can be useful for creating rich and
                  interactive user experiences.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Batch transfers
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-1155 tokens allow for batch transfers,
                  meaning that multiple tokens can be transferred in a single
                  transaction. This can help to reduce transaction fees and
                  improve efficiency.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Multi-token support
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-1155 allows for multiple tokens to be
                  managed by a single contract. This means that developers can
                  create a single contract to manage a variety of different
                  assets, including fungible tokens (such as cryptocurrencies)
                  and non-fungible tokens (such as collectibles).
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Reduced gas costs
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-1155 tokens use a more efficient
                  transfer mechanism than previous token standards. This reduces
                  the amount of gas required for transactions, making it cheaper
                  and faster to use.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                Wide adoption
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-1155 standard is widely adopted by many
                  blockchain projects, making it easy to integrate with other
                  platforms and services.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Overall, ERC-1155 tokens provide a more flexible, efficient,
                  and powerful way to manage a variety of different assets on
                  the Ethereum blockchain.
                </div>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={lj0014400w}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  Other Token Standards
                </div>
                <p className="mb-0 text-black-90">
                  Other popular and common ERC standards used on the
                  Ethereum blockchain?
                </p>
                <br />
                <div className="font-size-xs mb-0 text-black-50">
                  In addition to ERC-20, ERC-721, and ERC-1155, there are
                  several other ERC standards that are popular and commonly used
                  on the Ethereum blockchain. Some of these include:
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-165
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-165 standard is used to implement a
                  standardized interface for contract discovery. It allows smart
                  contracts to check if another contract supports a certain set
                  of methods and can be used to enable more efficient
                  interoperability between contracts.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-223
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-223 standard is similar to ERC-20, but with
                  additional safety features designed to prevent token loss and
                  other issues. It is often used for security tokens and other
                  types of assets that require additional security.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-2233
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-2233 standard is similar to ERC-223, but
                  with additional features designed to make it easier to build
                  tokenized games and other applications. It includes support
                  for in-game rewards, tokens as in-game currency, and more.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-2981
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-2981 standard is a newer standard that
                  defines a set of rules for creating and managing NFTs that can
                  be used in gaming and other applications. It includes a
                  royalty payment mechanism that allows creators to earn a
                  percentage of any future sales of their NFTs.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-1400
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-1400 standard is used for creating and
                  managing security tokens, which are tokens that represent
                  ownership of a real-world asset such as equity or debt. It
                  includes features such as investor restrictions and regulatory
                  compliance.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-777
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-777 standard is a newer token standard that
                  improves upon the ERC-20 standard by providing additional
                  features such as more efficient token transfers and built-in
                  support for tokenized securities.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-777
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-777 standard is similar to ERC-20, but with
                  additional features designed to make it easier to build
                  complex token systems. It includes support for hooks and token
                  metadata, among other things.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-721x
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-721x standard is an extension of the ERC-721
                  standard that allows for the creation of scalable and
                  upgradable NFT collections. It adds features such as batch
                  transfers and upgrades to the original standard.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-721v2
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-721v2 standard is an updated version of the
                  ERC-721 standard, with additional features such as batch
                  transfers and improved interoperability with other standards.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-721x+721y
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  ERC-721x+721y is a combination of the ERC-721x
                  and ERC-721y standards that allows for the creation of
                  scalable and upgradable NFT collections that can be used in a
                  wide range of applications.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-998
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-998 standard allows for the creation of
                  composable NFTs, which are NFTs that can own and interact with
                  other NFTs. This enables the creation of more complex NFT
                  systems, such as NFT-based games and marketplaces.
                </div>
                <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-948
                </div>

                <div className="font-size-xs mb-0 text-black-50">
                  The ERC-948 standard defines a set of rules for
                  creating and managing token-based membership systems. It can
                  be used to create tokenized membership programs, loyalty
                  programs, and other similar applications.
                </div>
                <div className="font-size-xs mb-0 text-black-50">
                  Overall, these ERC standards provide a wide range of options
                  for creating and managing different types of tokens on the
                  Ethereum blockchain, and are widely used in a variety of
                  applications and platforms.
                </div>
              </div>
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
