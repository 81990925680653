import React from 'react';
import { Container, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
const topAchievments = [
{ title: 'Newb' , level: 'Level 1', year: 2020 },
{ title: 'Newb' , level: 'Level 2', year: 2020 },
{ title: 'Newb' , level: 'Level 3', year: 2020 },
{ title: 'Newb' , level: 'Level 4', year: 2020 },
{ title: 'Newb' , level: 'Level 5', year: 2020 },
{ title: 'Newb' , level: 'Level 6', year: 2020 },
{ title: 'Airdrop Surprise' , level: 'Level 1', year: 2020 },
{ title: 'Airdrop Surprise' , level: 'Level 2', year: 2020 },
{ title: 'Airdrop Surprise' , level: 'Level 3', year: 2020 },
{ title: 'Airdrop Surprise' , level: 'Level 4', year: 2020 },
{ title: 'Airdrop Surprise' , level: 'Level 5', year: 2020 },
{ title: 'Airdrop Surprise' , level: 'Level 6', year: 2020 },
{ title: 'Ethereum' , level: 'Level 1', year: 2020 },
{ title: 'Ethereum' , level: 'Level 2', year: 2020 },
{ title: 'Ethereum' , level: 'Level 3', year: 2020 },
{ title: 'Ethereum' , level: 'Level 4', year: 2020 },
{ title: 'Ethereum' , level: 'Level 5', year: 2020 },
{ title: 'Ethereum' , level: 'Level 6', year: 2020 },
{ title: 'Nodist' , level: 'Level 1', year: 2020 },
{ title: 'Nodist' , level: 'Level 2', year: 2020 },
{ title: 'Nodist' , level: 'Level 3', year: 2020 },
{ title: 'Nodist' , level: 'Level 4', year: 2020 },
{ title: 'Nodist' , level: 'Level 5', year: 2020 },
{ title: 'Nodist' , level: 'Level 6', year: 2020 },
{ title: 'Tidbits' , level: 'Level 1', year: 2020 },
{ title: 'Tidbits' , level: 'Level 2', year: 2020 },
{ title: 'Tidbits' , level: 'Level 3', year: 2020 },
{ title: 'Tidbits' , level: 'Level 4', year: 2020 },
{ title: 'Tidbits' , level: 'Level 5', year: 2020 },
{ title: 'Tidbits' , level: 'Level 6', year: 2020 },
{ title: 'Wardens' , level: 'Level 1', year: 2020 },
{ title: 'Wardens' , level: 'Level 2', year: 2020 },
{ title: 'Wardens' , level: 'Level 3', year: 2020 },
{ title: 'Wardens' , level: 'Level 4', year: 2020 },
{ title: 'Wardens' , level: 'Level 5', year: 2020 },
{ title: 'Wardens' , level: 'Level 6', year: 2020 },
{ title: 'Judiciary' , level: 'Level 1', year: 2020 },
{ title: 'Judiciary' , level: 'Level 2', year: 2020 },
{ title: 'Judiciary' , level: 'Level 3', year: 2020 },
{ title: 'Judiciary' , level: 'Level 4', year: 2020 },
{ title: 'Judiciary' , level: 'Level 5', year: 2020 },
{ title: 'Judiciary' , level: 'Level 6', year: 2020 },
{ title: 'Space Traveler' , level: 'Level 1', year: 2020 },
{ title: 'Space Traveler' , level: 'Level 2', year: 2020 },
{ title: 'Space Traveler' , level: 'Level 3', year: 2020 },
{ title: 'Space Traveler' , level: 'Level 4', year: 2020 },
{ title: 'Space Traveler' , level: 'Level 5', year: 2020 },
{ title: 'Space Traveler' , level: 'Level 6', year: 2020 },
{ title: 'Miner' , level: 'Level 1', year: 2020 },
{ title: 'Miner' , level: 'Level 2', year: 2020 },
{ title: 'Miner' , level: 'Level 3', year: 2020 },
{ title: 'Miner' , level: 'Level 4', year: 2020 },
{ title: 'Miner' , level: 'Level 5', year: 2020 },
{ title: 'Miner' , level: 'Level 6', year: 2020 },
{ title: 'Architect' , level: 'Level 1', year: 2020 },
{ title: 'Architect' , level: 'Level 2', year: 2020 },
{ title: 'Architect' , level: 'Level 3', year: 2020 },
{ title: 'Architect' , level: 'Level 4', year: 2020 },
{ title: 'Architect' , level: 'Level 5', year: 2020 },
{ title: 'Architect' , level: 'Level 6', year: 2020 },
{ title: 'Beaconer' , level: 'Level 1', year: 2020 },
{ title: 'Beaconer' , level: 'Level 2', year: 2020 },
{ title: 'Beaconer' , level: 'Level 3', year: 2020 },
{ title: 'Beaconer' , level: 'Level 4', year: 2020 },
{ title: 'Beaconer' , level: 'Level 5', year: 2020 },
{ title: 'Beaconer' , level: 'Level 6', year: 2020 },
{ title: 'Heavy Mech' , level: 'Level 1', year: 2020 },
{ title: 'Heavy Mech' , level: 'Level 2', year: 2020 },
{ title: 'Heavy Mech' , level: 'Level 3', year: 2020 },
{ title: 'Heavy Mech' , level: 'Level 4', year: 2020 },
{ title: 'Heavy Mech' , level: 'Level 5', year: 2020 },
{ title: 'Heavy Mech' , level: 'Level 6', year: 2020 },
{ title: 'Seen And Heard' , level: 'Level 1', year: 2020 },
{ title: 'Seen And Heard' , level: 'Level 2', year: 2020 },
{ title: 'Seen And Heard' , level: 'Level 3', year: 2020 },
{ title: 'Seen And Heard' , level: 'Level 4', year: 2020 },
{ title: 'Seen And Heard' , level: 'Level 5', year: 2020 },
{ title: 'Seen And Heard' , level: 'Level 6', year: 2020 },
{ title: 'Future Self' , level: 'Level 1', year: 2020 },
{ title: 'Future Self' , level: 'Level 2', year: 2020 },
{ title: 'Future Self' , level: 'Level 3', year: 2020 },
{ title: 'Future Self' , level: 'Level 4', year: 2020 },
{ title: 'Future Self' , level: 'Level 5', year: 2020 },
{ title: 'Future Self' , level: 'Level 6', year: 2020 },
{ title: 'Mechanic' , level: 'Level 1', year: 2020 },
{ title: 'Mechanic' , level: 'Level 2', year: 2020 },
{ title: 'Mechanic' , level: 'Level 3', year: 2020 },
{ title: 'Mechanic' , level: 'Level 4', year: 2020 },
{ title: 'Mechanic' , level: 'Level 5', year: 2020 },
{ title: 'Mechanic' , level: 'Level 6', year: 2020 },
{ title: 'Renewer' , level: 'Level 1', year: 2020 },
{ title: 'Renewer' , level: 'Level 2', year: 2020 },
{ title: 'Renewer' , level: 'Level 3', year: 2020 },
{ title: 'Renewer' , level: 'Level 4', year: 2020 },
{ title: 'Renewer' , level: 'Level 5', year: 2020 },
{ title: 'Renewer' , level: 'Level 6', year: 2020 },
{ title: 'Leisurely Pursuits' , level: 'Level 1', year: 2020 },
{ title: 'Leisurely Pursuits' , level: 'Level 2', year: 2020 },
{ title: 'Leisurely Pursuits' , level: 'Level 3', year: 2020 },
{ title: 'Leisurely Pursuits' , level: 'Level 4', year: 2020 },
{ title: 'Leisurely Pursuits' , level: 'Level 5', year: 2020 },
{ title: 'Leisurely Pursuits' , level: 'Level 6', year: 2020 },
{ title: 'Who Cdc' , level: 'Level 1', year: 2020 },
{ title: 'Who Cdc' , level: 'Level 2', year: 2020 },
{ title: 'Who Cdc' , level: 'Level 3', year: 2020 },
{ title: 'Who Cdc' , level: 'Level 4', year: 2020 },
{ title: 'Who Cdc' , level: 'Level 5', year: 2020 },
{ title: 'Who Cdc' , level: 'Level 6', year: 2020 },
{ title: 'Bug Hunter' , level: 'Level 1', year: 2020 },
{ title: 'Bug Hunter' , level: 'Level 2', year: 2020 },
{ title: 'Bug Hunter' , level: 'Level 3', year: 2020 },
{ title: 'Bug Hunter' , level: 'Level 4', year: 2020 },
{ title: 'Bug Hunter' , level: 'Level 5', year: 2020 },
{ title: 'Bug Hunter' , level: 'Level 6', year: 2020 },
{ title: 'Bug Killer' , level: 'Level 1', year: 2020 },
{ title: 'Bug Killer' , level: 'Level 2', year: 2020 },
{ title: 'Bug Killer' , level: 'Level 3', year: 2020 },
{ title: 'Bug Killer' , level: 'Level 4', year: 2020 },
{ title: 'Bug Killer' , level: 'Level 5', year: 2020 },
{ title: 'Bug Killer' , level: 'Level 6', year: 2020 },
{ title: 'On Target' , level: 'Level 1', year: 2020 },
{ title: 'On Target' , level: 'Level 2', year: 2020 },
{ title: 'On Target' , level: 'Level 3', year: 2020 },
{ title: 'On Target' , level: 'Level 4', year: 2020 },
{ title: 'On Target' , level: 'Level 5', year: 2020 },
{ title: 'On Target' , level: 'Level 6', year: 2020 },
{ title: 'Account Maker' , level: 'Level 1', year: 2020 },
{ title: 'Account Maker' , level: 'Level 2', year: 2020 },
{ title: 'Account Maker' , level: 'Level 3', year: 2020 },
{ title: 'Account Maker' , level: 'Level 4', year: 2020 },
{ title: 'Account Maker' , level: 'Level 5', year: 2020 },
{ title: 'Account Maker' , level: 'Level 6', year: 2020 },
{ title: 'Announcer' , level: 'Level 1', year: 2020 },
{ title: 'Announcer' , level: 'Level 2', year: 2020 },
{ title: 'Announcer' , level: 'Level 3', year: 2020 },
{ title: 'Announcer' , level: 'Level 4', year: 2020 },
{ title: 'Announcer' , level: 'Level 5', year: 2020 },
{ title: 'Announcer' , level: 'Level 6', year: 2020 },
{ title: 'Big Brain' , level: 'Level 1', year: 2020 },
{ title: 'Big Brain' , level: 'Level 2', year: 2020 },
{ title: 'Big Brain' , level: 'Level 3', year: 2020 },
{ title: 'Big Brain' , level: 'Level 4', year: 2020 },
{ title: 'Big Brain' , level: 'Level 5', year: 2020 },
{ title: 'Big Brain' , level: 'Level 6', year: 2020 },
{ title: 'Big Show' , level: 'Level 1', year: 2020 },
{ title: 'Big Show' , level: 'Level 2', year: 2020 },
{ title: 'Big Show' , level: 'Level 3', year: 2020 },
{ title: 'Big Show' , level: 'Level 4', year: 2020 },
{ title: 'Big Show' , level: 'Level 5', year: 2020 },
{ title: 'Big Show' , level: 'Level 6', year: 2020 },
{ title: 'Bonded Pig' , level: 'Level 1', year: 2020 },
{ title: 'Bonded Pig' , level: 'Level 2', year: 2020 },
{ title: 'Bonded Pig' , level: 'Level 3', year: 2020 },
{ title: 'Bonded Pig' , level: 'Level 4', year: 2020 },
{ title: 'Bonded Pig' , level: 'Level 5', year: 2020 },
{ title: 'Bonded Pig' , level: 'Level 6', year: 2020 },
{ title: 'Brickler' , level: 'Level 1', year: 2020 },
{ title: 'Brickler' , level: 'Level 2', year: 2020 },
{ title: 'Brickler' , level: 'Level 3', year: 2020 },
{ title: 'Brickler' , level: 'Level 4', year: 2020 },
{ title: 'Brickler' , level: 'Level 5', year: 2020 },
{ title: 'Brickler' , level: 'Level 6', year: 2020 },
{ title: 'Decentralizer' , level: 'Level 1', year: 2020 },
{ title: 'Decentralizer' , level: 'Level 2', year: 2020 },
{ title: 'Decentralizer' , level: 'Level 3', year: 2020 },
{ title: 'Decentralizer' , level: 'Level 4', year: 2020 },
{ title: 'Decentralizer' , level: 'Level 5', year: 2020 },
{ title: 'Decentralizer' , level: 'Level 6', year: 2020 },
{ title: 'Detector' , level: 'Level 1', year: 2020 },
{ title: 'Detector' , level: 'Level 2', year: 2020 },
{ title: 'Detector' , level: 'Level 3', year: 2020 },
{ title: 'Detector' , level: 'Level 4', year: 2020 },
{ title: 'Detector' , level: 'Level 5', year: 2020 },
{ title: 'Detector' , level: 'Level 6', year: 2020 },
{ title: 'Documenteer' , level: 'Level 1', year: 2020 },
{ title: 'Documenteer' , level: 'Level 2', year: 2020 },
{ title: 'Documenteer' , level: 'Level 3', year: 2020 },
{ title: 'Documenteer' , level: 'Level 4', year: 2020 },
{ title: 'Documenteer' , level: 'Level 5', year: 2020 },
{ title: 'Documenteer' , level: 'Level 6', year: 2020 },
{ title: 'Entertainer' , level: 'Level 1', year: 2020 },
{ title: 'Entertainer' , level: 'Level 2', year: 2020 },
{ title: 'Entertainer' , level: 'Level 3', year: 2020 },
{ title: 'Entertainer' , level: 'Level 4', year: 2020 },
{ title: 'Entertainer' , level: 'Level 5', year: 2020 },
{ title: 'Entertainer' , level: 'Level 6', year: 2020 },
{ title: 'Feast' , level: 'Level 1', year: 2020 },
{ title: 'Feast' , level: 'Level 2', year: 2020 },
{ title: 'Feast' , level: 'Level 3', year: 2020 },
{ title: 'Feast' , level: 'Level 4', year: 2020 },
{ title: 'Feast' , level: 'Level 5', year: 2020 },
{ title: 'Feast' , level: 'Level 6', year: 2020 },
{ title: 'Fire Starter' , level: 'Level 1', year: 2020 },
{ title: 'Fire Starter' , level: 'Level 2', year: 2020 },
{ title: 'Fire Starter' , level: 'Level 3', year: 2020 },
{ title: 'Fire Starter' , level: 'Level 4', year: 2020 },
{ title: 'Fire Starter' , level: 'Level 5', year: 2020 },
{ title: 'Fire Starter' , level: 'Level 6', year: 2020 },
{ title: 'Good Show' , level: 'Level 1', year: 2020 },
{ title: 'Good Show' , level: 'Level 2', year: 2020 },
{ title: 'Good Show' , level: 'Level 3', year: 2020 },
{ title: 'Good Show' , level: 'Level 4', year: 2020 },
{ title: 'Good Show' , level: 'Level 5', year: 2020 },
{ title: 'Good Show' , level: 'Level 6', year: 2020 },
{ title: 'Helping Hand' , level: 'Level 1', year: 2020 },
{ title: 'Helping Hand' , level: 'Level 2', year: 2020 },
{ title: 'Helping Hand' , level: 'Level 3', year: 2020 },
{ title: 'Helping Hand' , level: 'Level 4', year: 2020 },
{ title: 'Helping Hand' , level: 'Level 5', year: 2020 },
{ title: 'Helping Hand' , level: 'Level 6', year: 2020 },
{ title: 'Hunter' , level: 'Level 1', year: 2020 },
{ title: 'Hunter' , level: 'Level 2', year: 2020 },
{ title: 'Hunter' , level: 'Level 3', year: 2020 },
{ title: 'Hunter' , level: 'Level 4', year: 2020 },
{ title: 'Hunter' , level: 'Level 5', year: 2020 },
{ title: 'Hunter' , level: 'Level 6', year: 2020 },
{ title: 'Iam Idea' , level: 'Level 1', year: 2020 },
{ title: 'Iam Idea' , level: 'Level 2', year: 2020 },
{ title: 'Iam Idea' , level: 'Level 3', year: 2020 },
{ title: 'Iam Idea' , level: 'Level 4', year: 2020 },
{ title: 'Iam Idea' , level: 'Level 5', year: 2020 },
{ title: 'Iam Idea' , level: 'Level 6', year: 2020 },
{ title: 'Insurer' , level: 'Level 1', year: 2020 },
{ title: 'Insurer' , level: 'Level 2', year: 2020 },
{ title: 'Insurer' , level: 'Level 3', year: 2020 },
{ title: 'Insurer' , level: 'Level 4', year: 2020 },
{ title: 'Insurer' , level: 'Level 5', year: 2020 },
{ title: 'Insurer' , level: 'Level 6', year: 2020 },
{ title: 'Internationaler' , level: 'Level 1', year: 2020 },
{ title: 'Internationaler' , level: 'Level 2', year: 2020 },
{ title: 'Internationaler' , level: 'Level 3', year: 2020 },
{ title: 'Internationaler' , level: 'Level 4', year: 2020 },
{ title: 'Internationaler' , level: 'Level 5', year: 2020 },
{ title: 'Internationaler' , level: 'Level 6', year: 2020 },
{ title: 'Lawdler' , level: 'Level 1', year: 2020 },
{ title: 'Lawdler' , level: 'Level 2', year: 2020 },
{ title: 'Lawdler' , level: 'Level 3', year: 2020 },
{ title: 'Lawdler' , level: 'Level 4', year: 2020 },
{ title: 'Lawdler' , level: 'Level 5', year: 2020 },
{ title: 'Lawdler' , level: 'Level 6', year: 2020 },
{ title: 'Magician' , level: 'Level 1', year: 2020 },
{ title: 'Magician' , level: 'Level 2', year: 2020 },
{ title: 'Magician' , level: 'Level 3', year: 2020 },
{ title: 'Magician' , level: 'Level 4', year: 2020 },
{ title: 'Magician' , level: 'Level 5', year: 2020 },
{ title: 'Magician' , level: 'Level 6', year: 2020 },
{ title: 'Navigator' , level: 'Level 1', year: 2020 },
{ title: 'Navigator' , level: 'Level 2', year: 2020 },
{ title: 'Navigator' , level: 'Level 3', year: 2020 },
{ title: 'Navigator' , level: 'Level 4', year: 2020 },
{ title: 'Navigator' , level: 'Level 5', year: 2020 },
{ title: 'Navigator' , level: 'Level 6', year: 2020 },
{ title: 'Needle Threader' , level: 'Level 1', year: 2020 },
{ title: 'Needle Threader' , level: 'Level 2', year: 2020 },
{ title: 'Needle Threader' , level: 'Level 3', year: 2020 },
{ title: 'Needle Threader' , level: 'Level 4', year: 2020 },
{ title: 'Needle Threader' , level: 'Level 5', year: 2020 },
{ title: 'Needle Threader' , level: 'Level 6', year: 2020 },
{ title: 'Networker' , level: 'Level 1', year: 2020 },
{ title: 'Networker' , level: 'Level 2', year: 2020 },
{ title: 'Networker' , level: 'Level 3', year: 2020 },
{ title: 'Networker' , level: 'Level 4', year: 2020 },
{ title: 'Networker' , level: 'Level 5', year: 2020 },
{ title: 'Networker' , level: 'Level 6', year: 2020 },
{ title: 'On Fire' , level: 'Level 1', year: 2020 },
{ title: 'On Fire' , level: 'Level 2', year: 2020 },
{ title: 'On Fire' , level: 'Level 3', year: 2020 },
{ title: 'On Fire' , level: 'Level 4', year: 2020 },
{ title: 'On Fire' , level: 'Level 5', year: 2020 },
{ title: 'On Fire' , level: 'Level 6', year: 2020 },
{ title: 'On Timer' , level: 'Level 1', year: 2020 },
{ title: 'On Timer' , level: 'Level 2', year: 2020 },
{ title: 'On Timer' , level: 'Level 3', year: 2020 },
{ title: 'On Timer' , level: 'Level 4', year: 2020 },
{ title: 'On Timer' , level: 'Level 5', year: 2020 },
{ title: 'On Timer' , level: 'Level 6', year: 2020 },
{ title: 'Pawn Tokener' , level: 'Level 1', year: 2020 },
{ title: 'Pawn Tokener' , level: 'Level 2', year: 2020 },
{ title: 'Pawn Tokener' , level: 'Level 3', year: 2020 },
{ title: 'Pawn Tokener' , level: 'Level 4', year: 2020 },
{ title: 'Pawn Tokener' , level: 'Level 5', year: 2020 },
{ title: 'Pawn Tokener' , level: 'Level 6', year: 2020 },
{ title: 'Researcher' , level: 'Level 1', year: 2020 },
{ title: 'Researcher' , level: 'Level 2', year: 2020 },
{ title: 'Researcher' , level: 'Level 3', year: 2020 },
{ title: 'Researcher' , level: 'Level 4', year: 2020 },
{ title: 'Researcher' , level: 'Level 5', year: 2020 },
{ title: 'Researcher' , level: 'Level 6', year: 2020 },
{ title: 'Resolver' , level: 'Level 1', year: 2020 },
{ title: 'Resolver' , level: 'Level 2', year: 2020 },
{ title: 'Resolver' , level: 'Level 3', year: 2020 },
{ title: 'Resolver' , level: 'Level 4', year: 2020 },
{ title: 'Resolver' , level: 'Level 5', year: 2020 },
{ title: 'Resolver' , level: 'Level 6', year: 2020 },
{ title: 'Service Pro' , level: 'Level 1', year: 2020 },
{ title: 'Service Pro' , level: 'Level 2', year: 2020 },
{ title: 'Service Pro' , level: 'Level 3', year: 2020 },
{ title: 'Service Pro' , level: 'Level 4', year: 2020 },
{ title: 'Service Pro' , level: 'Level 5', year: 2020 },
{ title: 'Service Pro' , level: 'Level 6', year: 2020 },
{ title: 'Social Supporter' , level: 'Level 1', year: 2020 },
{ title: 'Social Supporter' , level: 'Level 2', year: 2020 },
{ title: 'Social Supporter' , level: 'Level 3', year: 2020 },
{ title: 'Social Supporter' , level: 'Level 4', year: 2020 },
{ title: 'Social Supporter' , level: 'Level 5', year: 2020 },
{ title: 'Social Supporter' , level: 'Level 6', year: 2020 },
{ title: 'Strategist' , level: 'Level 1', year: 2020 },
{ title: 'Strategist' , level: 'Level 2', year: 2020 },
{ title: 'Strategist' , level: 'Level 3', year: 2020 },
{ title: 'Strategist' , level: 'Level 4', year: 2020 },
{ title: 'Strategist' , level: 'Level 5', year: 2020 },
{ title: 'Strategist' , level: 'Level 6', year: 2020 },
{ title: 'Surprise' , level: 'Level 1', year: 2020 },
{ title: 'Surprise' , level: 'Level 2', year: 2020 },
{ title: 'Surprise' , level: 'Level 3', year: 2020 },
{ title: 'Surprise' , level: 'Level 4', year: 2020 },
{ title: 'Surprise' , level: 'Level 5', year: 2020 },
{ title: 'Surprise' , level: 'Level 6', year: 2020 },
{ title: 'Team Player' , level: 'Level 1', year: 2020 },
{ title: 'Team Player' , level: 'Level 2', year: 2020 },
{ title: 'Team Player' , level: 'Level 3', year: 2020 },
{ title: 'Team Player' , level: 'Level 4', year: 2020 },
{ title: 'Team Player' , level: 'Level 5', year: 2020 },
{ title: 'Team Player' , level: 'Level 6', year: 2020 },
{ title: 'Transfer Tech' , level: 'Level 1', year: 2020 },
{ title: 'Transfer Tech' , level: 'Level 2', year: 2020 },
{ title: 'Transfer Tech' , level: 'Level 3', year: 2020 },
{ title: 'Transfer Tech' , level: 'Level 4', year: 2020 },
{ title: 'Transfer Tech' , level: 'Level 5', year: 2020 },
{ title: 'Transfer Tech' , level: 'Level 6', year: 2020 },
{ title: 'Web3VM User' , level: 'Level 1', year: 2020 },
{ title: 'Web3VM User' , level: 'Level 2', year: 2020 },
{ title: 'Web3VM User' , level: 'Level 3', year: 2020 },
{ title: 'Web3VM User' , level: 'Level 4', year: 2020 },
{ title: 'Web3VM User' , level: 'Level 5', year: 2020 },
{ title: 'Web3VM User' , level: 'Level 6', year: 2020 },
];
export default function LivePreviewExample() {
return (
<>
<div className="p-5">
<Container>
<Autocomplete
multiple
id="fixed-tags-demo"
options={topAchievments}
getOptionLabel={(option) => option.title}
defaultValue={[topAchievments[6], topAchievments[13]]}
renderTags={(value, getTagProps) =>
value.map((option, index) => (
<Chip
label={option.title}
{...getTagProps({ index })}
disabled={index === 0}
/>
))
}
renderInput={(params) => (
<TextField
{...params}
label="Fixed tag"
variant="outlined"
placeholder="Favorites"
fullWidth
/>
)}
/>
</Container>
</div>
</>
);
}
