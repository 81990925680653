import React from 'react';
import lexautomatico05 from '../../../assets/images/lexautomatica/LEXAUTOMATICO-logo-05-200w.png';
import lexautomatica003200w from '../../../assets/images/lexautomatica/lexautomatica-003-200w.png';

export default function LivePreviewExample() {
  return (
    <>

<div>
        <a
          href="https://web3legaltools.com"
          target="_blank"
          rel="noopener noreferrer"
          className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
          <img src={lexautomatica003200w} className="" alt="..." />
          <div className="p-3 bg-secondary unded-bottom p-xl-4">
            <img src={lexautomatico05} alt="..." style={{ height: 40 }} />
            <p className="text-second opacity-8 mt-4 mb-0">
              Web3Skills_SmartLaw course and service descriptions. 5686
            </p>
          </div>
        </a>
      </div>


    </>
  );
}
