import React from 'react';

import { Grid } from '@material-ui/core';

// import illustration1 from '../../../assets/images/illustrations/analysis.svg';
// import illustration2 from '../../../assets/images/illustrations/businessman.svg';
// import illustration3 from '../../../assets/images/illustrations/handshake.svg';
// import illustration4 from '../../../assets/images/illustrations/moving.svg';
// import illustration5 from '../../../assets/images/illustrations/credit_card.svg';
// import illustration6 from '../../../assets/images/illustrations/authentication.svg';
// import illustration7 from '../../../assets/images/illustrations/online_shopping.svg';
import illustration8 from '../../../assets/images/illustrations/private-keys.png';
import illustration9 from '../../../assets/images/legal-solutions/legal-solutions-023.png';
import illustration10 from '../../../assets/images/legal-solutions/legal-solutions-024.png';
import illustration11 from '../../../assets/images/legal-solutions/legal-solutions-006.png';





export default function LivePreviewExample() {
  return (
    <>
      <div className="mb-spacing-6-x2">
        <Grid container spacing={6}>
          <Grid item md={6} xl={3}>
            <a
              href="https://users.onelaw.us/"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={illustration8}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                Private Keys &#38; Decentralized IDs
                </div>
                <p className="mb-0 text-black-50">
                  View any of the 5+ live previews we&#39;ve set up to learn why
                  this dashboard template is the last one you&#39;ll ever need!
                </p>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com/#/"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={illustration11}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  Digital Assets
                </div>
                <p className="mb-0 text-black-50">
                  View any of the 5+ live previews we&#39;ve set up to learn why
                  this dashboard template is the last one you&#39;ll ever need!
                </p>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://tokens.freeweb3.com/#/"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={illustration9}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  Decentralized Storage
                </div>
                <p className="mb-0 text-black-50">
                  View any of the 5+ live previews we&#39;ve set up to learn why
                  this dashboard template is the last one you&#39;ll ever need!
                </p>
              </div>
            </a>
          </Grid>
          <Grid item md={6} xl={3}>
            <a
              href="https://www.voxels.com/play?coords=NW@195E,128S"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
              <img
                src={illustration10}
                style={{ height: 110 }}
                alt="..."
                className="mt-3"
              />
              <div className="px-4 pt-2 pb-4">
                <div className="font-size-lg font-weight-bold mb-2">
                  Encryption &#38; Content Gating
                </div>
                <p className="mb-0 text-black-50">
                  View any of the 5+ live previews we&#39;ve set up to learn why
                  this dashboard template is the last one you&#39;ll ever need!
                </p>
              </div>
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
