import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';

import { NavLink } from 'react-router-dom';

// import hero4 from '../../../assets/images/hero-bg/hero-2.jpg';
// import stock3 from '../../../assets/images/stock-photos/2022-demo-images-art-009-800w.png';

import userseth from '../../../assets/images/stock-photos/users-eth-400w.png';


import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import Iframe from 'react-iframe';

export default function LivePreviewExample() {
  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={7}
                      xl={6}
                      className="d-flex align-items-center">
                      <Grid item md={10} lg={8} xl={7} className="mx-auto">
                        <div className="py-4">
                          <div className="text-center">
                            <h1 className="display-3 my-3 font-weight-bold text-primary">
                              Create a Decentralized ID
                            </h1>
                            <p className="font-size-lg mb-0 px-4 text-primary-50">
                              SmartLaw provides tools for Web3 ecosystem to
                              advance legaltech . 8 .
                            </p>
                          </div>
                          <div className="text-center my-4">
                            <h1 className="display-4 mb-1 font-weight-bold">
                              Create your Decentralized ID Here:
                            </h1>
                            <p className="font-size-lg mb-0 text-black-50">
                              Start benefitting from Web3 right away. Accounts
                              are free and are used to collect achievment and
                              access tokens.
                            </p>
                          </div>

                          <Iframe
                            id=""
                            src="https://airtable.com/embed/shrHCYODrvfgCmjZk?backgroundColor=gray"
                            frameborder="0"
                            onmousewheel=""
                            width="100%"
                            height="600"
                            // height="3240"
                            className=""
                            display="block"
                            position="relative"
                            background="transparent"
                            border="1px"
                          />

                          <div className="mt-5 mb-3">
                            <label className="font-weight-bold mb-2">
                              Account Owner Name
                            </label>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Enter the owner of the ID here."
                            />
                          </div>
                          <div className="mb-3">
                            <label className="font-weight-bold mb-2">
                              Profile Description
                            </label>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Enter the profile description"
                            />
                          </div>
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                Password
                              </label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Enter a password"
                              type="password"
                            />
                          </div>
                          <Grid item md={12}>
                            <div>
                              <label className="font-weight-bold mb-2">
                                Profile Image
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Attach a profile image"
                              />
                            </div>
                          </Grid>
                          <Grid item md={12}>
                            <div>
                              <label className="font-weight-bold mb-2">
                                Favorite Emogi
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Attach a favorite emogi"
                              />
                            </div>
                          </Grid>
                          <Grid item md={12}>
                            <div>
                              <label className="font-weight-bold mb-2">
                                Background Image
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Select a background image"
                              />
                            </div>
                          </Grid>
                          <Grid item md={12}>
                            <div>
                              <label className="font-weight-bold mb-2">
                                Birthdate
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter the birthdate"
                              />
                            </div>
                          </Grid>
                          <Grid item sm={12} lg={12}>
                            <div className="mb-3">
                              <label className="font-weight-bold mb-2">
                                URL
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter a content URL"
                              />
                            </div>
                          </Grid>
                          <Grid item sm={12} lg={12}>
                            <div className="mb-3">
                              <label className="font-weight-bold mb-2">
                                Home Location
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter the owner's home country"
                              />
                            </div>
                          </Grid>
                          <Grid item sm={12} lg={12}>
                            <div className="mb-3">
                              <label className="font-weight-bold mb-2">
                                Residence Location
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter the owner's country of residence"
                              />
                            </div>
                          </Grid>
                          <Grid item sm={12} lg={12}>
                            <div className="mb-3">
                              <label className="font-weight-bold mb-2">
                                Affiliations
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter any affiliations"
                              />
                            </div>
                          </Grid>
                          <Grid item sm={12} lg={12}>
                            <div className="mb-3">
                              <label className="font-weight-bold mb-2">
                                Nationalities
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter additional jurisdictions"
                              />
                            </div>
                          </Grid>
                          <Grid item sm={12} lg={12}>
                            <div className="mb-3">
                              <label className="font-weight-bold mb-2">
                                IPFS URL
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter an immutable hash"
                              />
                            </div>
                          </Grid>
                          <Grid item md={12}>
                            <div className="mb-3">
                              <label className="font-weight-bold mb-2">
                                Enter any memorable integer
                              </label>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter any memorable integer"
                              />
                            </div>
                          </Grid>
                          <div className="form-group my-3">
                            By clicking the <strong>Create account</strong>{' '}
                            button below you agree to our terms of service and
                            privacy statement.
                          </div>

                          <div className="text-center mb-4">
                            <Button className="btn-primary text-uppercase font-weight-bold font-size-sm my-3">
                              Create a Decentralized ID now
                            </Button>
                          </div>
                          <div className="text-center mb-2">
                              <Tooltip
                                classes={{
                                  tooltip: 'tooltip-secondary text-nowrap'
                                }}
                                arrow
                                placement="right"
                                title="Back to dashboard">
                                <Button
                                  component={NavLink}
                                  variant="contained"
                                  className="btn-primary btn-icon m-1 p-0 shadow-none text-center font-size-lg d-40 rounded"
                                  to="/About">
                                  <FontAwesomeIcon
                                    icon={['fas', 'arrow-left']}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} xl={6} className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image opacity-5"
                            style={{
                              backgroundImage: 'url(' + sideImage + ')'
                            }}
                          />
                          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                          <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                          <div className="bg-composed-wrapper--content text-center p-5">
                            <div className="text-white px-0 px-lg-2 px-xl-4">

                            <a
                            href="https://adr.freeweb3.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="card-img-wrapper rounded">
                            <div className="img-wrapper-overlay">
                              <div className="overlay-btn-wrapper">
                                <div className="rounded-circle d-40 mx-auto d-flex align-items-center justify-content-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'plus-circle']}
                                    className="font-size-lg"
                                  />
                                </div>
                              </div>
                            </div>
                            <img
                              alt="..."
                              className="card-img-top rounded"
                              src={userseth}
                              style={{ width: 300 }}
                            />
                          </a>

                              <h1 className="display-3 mb-4 font-weight-bold text-white">
                                Decentralized IDs:
                              </h1>
                              <p className="font-size-lg mb-0 opacity-8">
                                SmartLaw provides tools for Web3 ecosystem to
                                advance legaltech . 11.
                              </p>
                              <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                              <div>
                                <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                                  <span className="btn-wrapper--label">
                                    Learn more about Decentralized IDs
                                  </span>
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon
                                      icon={['fas', 'arrow-right']}
                                    />
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hero-footer pb-4">
                          <List
                            component="div"
                            className="nav-pills nav-neutral-secondary d-flex">
                            <Tooltip title="Github" arrow>
                              <ListItem
                                component="a"
                                button
                                href="#/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-size-lg text-white-50">
                                <FontAwesomeIcon icon={['fab', 'github']} />
                              </ListItem>
                            </Tooltip>
                            <Tooltip title="Twitter" arrow>
                              <ListItem
                                component="a"
                                button
                                href="#/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-size-lg text-white-50">
                                <FontAwesomeIcon icon={['fab', 'twitter']} />
                              </ListItem>
                            </Tooltip>
                            <Tooltip title="Discord" arrow>
                              <ListItem
                                component="a"
                                button
                                href="#/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-size-lg text-white-50">
                                <FontAwesomeIcon icon={['fab', 'discord']} />
                              </ListItem>
                            </Tooltip>
                          </List>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
