import React from 'react';

import { PageTitle } from 'layout-components';

import DashboardMonitoring1 from '../../example-components/Achieve/DashboardMonitoring1';
import DashboardMonitoring2 from '../../example-components/Achieve/DashboardMonitoring2';
import DashboardMonitoring4 from '../../example-components/Achieve/DashboardMonitoring4';
import DashboardMonitoring6 from '../../example-components/Achieve/DashboardMonitoring6';
import DashboardCommerce7 from '../../example-components/Tokens/DashboardCommerce7';
import DashboardCommerce21 from '../../example-components/Tokens/DashboardCommerce21';

import { ExampleWrapperSeamless } from 'layout-components';


import Overview4 from '../../example-components/Overview/Overview4';


export default function Achieve() {
  return (
    <>
      <PageTitle
        titleHeading="SmartLaw Digital Asset Dashboard"
        titleDescription="This dashboard shows the features and application of Web3Skills SmartLaw."
      />


      <ExampleWrapperSeamless>
      <Overview4 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
      <DashboardCommerce7 />
      </ExampleWrapperSeamless>
      
      <ExampleWrapperSeamless>
      <DashboardCommerce21 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
      <DashboardMonitoring6 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
      <DashboardMonitoring1 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
      <DashboardMonitoring2 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
      <DashboardMonitoring4 />
      </ExampleWrapperSeamless>







    </>
  );
}
