import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';
import { ExampleWrapperSimple } from 'layout-components';


import ElementsTimelines6 from '../../example-components/Nav/Timelines6';
import BlocksImages13 from '../../example-components/Credcert/BlocksImages13';
import MarketingIcons12 from '../../example-components/Apps/MarketingIcons12';



export default function Tstandards() {
  return (
    <>
      <PageTitle
        titleHeading="EVM Token Standards & Node Computing Features"
        titleDescription="The course available in Web3Skills are presented in order of the Supervisors preferences."
      />

      <ExampleWrapperSimple>
        <ElementsTimelines6 />
      </ExampleWrapperSimple>

      <ExampleWrapperSeamless>
        <MarketingIcons12 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <BlocksImages13 />
      </ExampleWrapperSeamless>





    </>
  );
}
