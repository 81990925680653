import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  InputAdornment,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem,
  TextField
} from '@material-ui/core';

import achieve001 from '../../../assets/images/badges2023/achieve-001-prefinal-200w.png';
import achieve002 from '../../../assets/images/badges2023/achieve-002-prefinal-200w.png';
import achieve003 from '../../../assets/images/badges2023/achieve-003-prefinal-200w.png';
import achieve004 from '../../../assets/images/badges2023/achieve-004-prefinal-200w.png';
import achieve005 from '../../../assets/images/badges2023/achieve-005-prefinal-200w.png';
import achieve006 from '../../../assets/images/badges2023/achieve-006-prefinal-200w.png';
import achieve007 from '../../../assets/images/badges2023/achieve-007-prefinal-200w.png';
import achieve008 from '../../../assets/images/badges2023/achieve-008-prefinal-200w.png';
import achieve009 from '../../../assets/images/badges2023/achieve-009-prefinal-200w.png';
import achieve010 from '../../../assets/images/badges2023/achieve-010-prefinal-200w.png';
import achieve011 from '../../../assets/images/badges2023/achieve-011-prefinal-200w.png';
import achieve012 from '../../../assets/images/badges2023/achieve-012-prefinal-200w.png';
import achieve013 from '../../../assets/images/badges2023/achieve-013-prefinal-200w.png';
import achieve014 from '../../../assets/images/badges2023/achieve-014-prefinal-200w.png';
import achieve015 from '../../../assets/images/badges2023/achieve-015-prefinal-200w.png';
import achieve016 from '../../../assets/images/badges2023/achieve-016-prefinal-200w.png';
import achieve017 from '../../../assets/images/badges2023/achieve-017-prefinal-200w.png';
import achieve018 from '../../../assets/images/badges2023/achieve-018-prefinal-200w.png';
import achieve019 from '../../../assets/images/badges2023/achieve-019-prefinal-200w.png';
import achieve020 from '../../../assets/images/badges2023/achieve-020-prefinal-200w.png';
import achieve021 from '../../../assets/images/badges2023/achieve-021-prefinal-200w.png';
import achieve022 from '../../../assets/images/badges2023/achieve-022-prefinal-200w.png';
import achieve023 from '../../../assets/images/badges2023/achieve-023-prefinal-200w.png';
import achieve024 from '../../../assets/images/badges2023/achieve-024-prefinal-200w.png';
import achieve025 from '../../../assets/images/badges2023/achieve-025-prefinal-200w.png';
import achieve026 from '../../../assets/images/badges2023/achieve-026-prefinal-200w.png';
import achieve027 from '../../../assets/images/badges2023/achieve-027-prefinal-200w.png';
import achieve028 from '../../../assets/images/badges2023/achieve-028-prefinal-200w.png';
import achieve029 from '../../../assets/images/badges2023/achieve-029-prefinal-200w.png';
import achieve030 from '../../../assets/images/badges2023/achieve-030-prefinal-200w.png';
import achieve031 from '../../../assets/images/badges2023/achieve-031-prefinal-200w.png';
import achieve032 from '../../../assets/images/badges2023/achieve-032-prefinal-200w.png';
import achieve033 from '../../../assets/images/badges2023/achieve-033-prefinal-200w.png';
import leg001 from '../../../assets/images/badges2023/leg-001-prefinal-200w.png';
import leg002 from '../../../assets/images/badges2023/leg-002-prefinal-200w.png';
import leg003 from '../../../assets/images/badges2023/leg-003-prefinal-200w.png';
import leg004 from '../../../assets/images/badges2023/leg-004-prefinal-200w.png';
import leg005 from '../../../assets/images/badges2023/leg-005-prefinal-200w.png';
import leg006 from '../../../assets/images/badges2023/leg-006-prefinal-200w.png';
import leg007 from '../../../assets/images/badges2023/leg-007-prefinal-200w.png';
import leg008 from '../../../assets/images/badges2023/leg-008-prefinal-200w.png';
import leg009 from '../../../assets/images/badges2023/leg-009-prefinal-200w.png';
import leg010 from '../../../assets/images/badges2023/leg-010-prefinal-200w.png';
import leg011 from '../../../assets/images/badges2023/leg-011-prefinal-200w.png';
import leg012 from '../../../assets/images/badges2023/leg-012-prefinal-200w.png';
import leg013 from '../../../assets/images/badges2023/leg-013-prefinal-200w.png';
import leg014 from '../../../assets/images/badges2023/leg-014-prefinal-200w.png';
import leg015 from '../../../assets/images/badges2023/leg-015-prefinal-200w.png';
import leg016 from '../../../assets/images/badges2023/leg-016-prefinal-200w.png';
import leg017 from '../../../assets/images/badges2023/leg-017-prefinal-200w.png';
import leg018 from '../../../assets/images/badges2023/leg-018-prefinal-200w.png';
import leg019 from '../../../assets/images/badges2023/leg-019-prefinal-200w.png';
import leg020 from '../../../assets/images/badges2023/leg-020-prefinal-200w.png';
import leg021 from '../../../assets/images/badges2023/leg-021-prefinal-200w.png';
import leg022 from '../../../assets/images/badges2023/leg-022-prefinal-200w.png';
import leg023 from '../../../assets/images/badges2023/leg-023-prefinal-200w.png';
import leg024 from '../../../assets/images/badges2023/leg-024-prefinal-200w.png';
import leg025 from '../../../assets/images/badges2023/leg-025-prefinal-200w.png';
import scale001 from '../../../assets/images/badges2023/scale-001-prefinal-200w.png';
import scale002 from '../../../assets/images/badges2023/scale-002-prefinal-200w.png';
import scale003 from '../../../assets/images/badges2023/scale-003-prefinal-200w.png';
import scale004 from '../../../assets/images/badges2023/scale-004-prefinal-200w.png';
import scale005 from '../../../assets/images/badges2023/scale-005-prefinal-200w.png';
import scale006 from '../../../assets/images/badges2023/scale-006-prefinal-200w.png';
import scale007 from '../../../assets/images/badges2023/scale-007-prefinal-200w.png';
import scale008 from '../../../assets/images/badges2023/scale-008-prefinal-200w.png';
import scale009 from '../../../assets/images/badges2023/scale-009-prefinal-200w.png';
import scale010 from '../../../assets/images/badges2023/scale-010-prefinal-200w.png';
import scale011 from '../../../assets/images/badges2023/scale-011-prefinal-200w.png';
import scale012 from '../../../assets/images/badges2023/scale-012-prefinal-200w.png';
import scale013 from '../../../assets/images/badges2023/scale-013-prefinal-200w.png';
import scale014 from '../../../assets/images/badges2023/scale-014-prefinal-200w.png';
import tri001 from '../../../assets/images/badges2023/tri-001-prefinal-200w.png';
import tri002 from '../../../assets/images/badges2023/tri-002-prefinal-200w.png';
import tri003 from '../../../assets/images/badges2023/tri-003-prefinal-200w.png';
import tri004 from '../../../assets/images/badges2023/tri-004-prefinal-200w.png';
import tri005 from '../../../assets/images/badges2023/tri-005-prefinal-200w.png';
import tri006 from '../../../assets/images/badges2023/tri-006-prefinal-200w.png';
import tri007 from '../../../assets/images/badges2023/tri-007-prefinal-200w.png';
import tri008 from '../../../assets/images/badges2023/tri-008-prefinal-200w.png';
import tri009 from '../../../assets/images/badges2023/tri-009-prefinal-200w.png';
import tri010 from '../../../assets/images/badges2023/tri-010-prefinal-200w.png';
import tri011 from '../../../assets/images/badges2023/tri-011-prefinal-200w.png';
import tri012 from '../../../assets/images/badges2023/tri-012-prefinal-200w.png';
import tri013 from '../../../assets/images/badges2023/tri-013-prefinal-200w.png';
import tri014 from '../../../assets/images/badges2023/tri-014-prefinal-200w.png';
import tri015 from '../../../assets/images/badges2023/tri-015-prefinal-200w.png';
import tri016 from '../../../assets/images/badges2023/tri-016-prefinal-200w.png';
import tri017 from '../../../assets/images/badges2023/tri-017-prefinal-200w.png';
import tri018 from '../../../assets/images/badges2023/tri-018-prefinal-200w.png';
import tri019 from '../../../assets/images/badges2023/tri-019-prefinal-200w.png';
import tri020 from '../../../assets/images/badges2023/tri-020-prefinal-200w.png';
import tri021 from '../../../assets/images/badges2023/tri-021-prefinal-200w.png';
import tri022 from '../../../assets/images/badges2023/tri-022-prefinal-200w.png';
import tri023 from '../../../assets/images/badges2023/tri-023-prefinal-200w.png';
import tri024 from '../../../assets/images/badges2023/tri-024-prefinal-200w.png';
import tri025 from '../../../assets/images/badges2023/tri-025-prefinal-200w.png';
import tri026 from '../../../assets/images/badges2023/tri-026-prefinal-200w.png';
import tri027 from '../../../assets/images/badges2023/tri-027-prefinal-200w.png';




import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

export default function LivePreviewExample() {
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  return (
    <>








      <Grid container spacing={6}>
        <Grid item xl={6} className="d-flex">
          <Card className="card-box w-100">
            <div className="card-header">
              <div className="card-header--title">
                <small className="d-block text-uppercase mt-1">Legal Engineering Certification</small>
                <b>The Path to Web3 Legal Engineering Certification</b>
              </div>
              <div>
                <Button className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
                </Button>
              </div>
            </div>
            <List component="div" className="list-group-flush">
              <ListItem
                component="a"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 1"
                        src={achieve001}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 1
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={4}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 1 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      96<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="b"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 2"
                        src={achieve002}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 2
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={8}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 2 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      92<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="c"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 3"
                        src={achieve003}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 3
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={12}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 3 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      88<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="d"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 4"
                        src={achieve004}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 4
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={16}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 4 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      84<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="e"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 5"
                        src={achieve005}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 5
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={20}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 5 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      80<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="f"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 6"
                        src={achieve006}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 6
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={24}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 6 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      76<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="g"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 7"
                        src={achieve007}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 7
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={28}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 7 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      72<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="h"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 8"
                        src={achieve008}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 8
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={32}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 8 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      68<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="i"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 9"
                        src={achieve009}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 9
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={36}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 9 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      64<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="j"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 10"
                        src={achieve010}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 10
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={40}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 10 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      60<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="k"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 11"
                        src={achieve011}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 11
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={44}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 11 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      56<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="l"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 12"
                        src={achieve012}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 12
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={48}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 12 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      52<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="m"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 13"
                        src={achieve013}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 13
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={52}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 13 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      48<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="n"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 14"
                        src={achieve014}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 14
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={56}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 14 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      44<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="o"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 15"
                        src={achieve015}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 15
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={60}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 15 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      40<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="p"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 16"
                        src={achieve016}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 16
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={64}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 16 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      36<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="q"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 17"
                        src={achieve017}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 17
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={68}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 17 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      32<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="r"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 18"
                        src={achieve018}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 18
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={72}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 18 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      28<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="s"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 19"
                        src={achieve019}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 19
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={76}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 19 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      24<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="t"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 20"
                        src={achieve020}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 20
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={80}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 20 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      20<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="u"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 21"
                        src={achieve021}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 21
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={84}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 21 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      16<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="v"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 22"
                        src={achieve022}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 22
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={88}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 22 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      12<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 23"
                        src={achieve023}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 23
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={92}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 23 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      8<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 24"
                        src={achieve024}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 24
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 24 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 25"
                        src={achieve025}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 25
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 25 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 26"
                        src={achieve026}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 26
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 26 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 27"
                        src={achieve027}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 27
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 27 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="b"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 28"
                        src={achieve028}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 28
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={8}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 28 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      92<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="c"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 29"
                        src={achieve029}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 29
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={12}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 29 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      88<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="d"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 30"
                        src={achieve030}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 30
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={16}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 30 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      84<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="e"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 31"
                        src={achieve031}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 31
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={20}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 31 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      80<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="f"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 32"
                        src={achieve032}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 32
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={24}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 32 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      76<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="g"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 33"
                        src={achieve033}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 33
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={28}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 33 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      72<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="h"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 34"
                        src={leg001}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 34
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={32}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 34 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      68<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="i"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 35"
                        src={leg002}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 35
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={36}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 35 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      64<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="j"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 36"
                        src={leg003}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 36
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={40}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 36 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      60<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="k"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 37"
                        src={leg004}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 37
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={44}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 37 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      56<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="l"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 38"
                        src={leg005}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 38
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={48}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 38 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      52<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="m"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 39"
                        src={leg006}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 39
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={52}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 39 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      48<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="n"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 40"
                        src={leg007}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 40
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={56}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 40 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      44<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="o"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 41"
                        src={leg008}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 41
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={60}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 41 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      40<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="p"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 42"
                        src={leg009}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 42
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={64}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 42 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      36<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="q"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 43"
                        src={leg010}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 43
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={68}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 43 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      32<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="r"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 44"
                        src={leg011}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 44
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={72}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 44 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      28<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="s"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 45"
                        src={leg012}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 45
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={76}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 45 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      24<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="t"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 46"
                        src={leg013}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 46
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={80}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 46 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      20<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="u"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 47"
                        src={leg014}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 47
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={84}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 47 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      16<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="v"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 48"
                        src={leg015}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 48
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={88}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 48 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      12<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 49"
                        src={leg016}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 49
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={92}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 49 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      8<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 50"
                        src={leg017}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 50
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 50 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 51"
                        src={leg018}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 51
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 51 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 52"
                        src={leg019}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 52
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 52 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 53"
                        src={leg020}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 53
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 53 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="b"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 54"
                        src={leg021}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 54
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={8}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 54 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      92<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="c"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 55"
                        src={leg022}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 55
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={12}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 55 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      88<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="d"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 56"
                        src={leg023}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 56
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={16}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 56 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      84<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="e"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 57"
                        src={leg024}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 57
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={20}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 57 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      80<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="f"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 58"
                        src={leg025}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 58
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={24}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 58 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      76<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="g"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 59"
                        src={scale001}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 59
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={28}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 59 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      72<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="h"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 60"
                        src={scale002}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 60
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={32}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 60 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      68<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="i"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 61"
                        src={scale003}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 61
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={36}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 61 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      64<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="j"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 62"
                        src={scale004}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 62
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={40}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 62 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      60<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="k"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 63"
                        src={scale005}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 63
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={44}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 63 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      56<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="l"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 64"
                        src={scale006}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 64
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={48}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 64 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      52<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="m"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 65"
                        src={scale007}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 65
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={52}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 65 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      48<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="n"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 66"
                        src={scale008}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 66
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={56}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 66 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      44<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="o"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 67"
                        src={scale009}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 67
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={60}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 67 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      40<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="p"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 68"
                        src={scale010}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 68
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={64}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 68 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      36<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="q"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 69"
                        src={scale011}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 69
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={68}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 69 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      32<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="r"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 70"
                        src={scale012}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 70
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={72}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 70 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      28<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="s"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 71"
                        src={scale013}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 71
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={76}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 71 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      24<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="t"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 72"
                        src={scale014}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 72
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={80}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 72 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      20<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="u"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 73"
                        src={tri001}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 73
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={84}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 73 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      16<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="v"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 74"
                        src={tri002}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 74
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={88}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 74 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      12<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 75"
                        src={tri003}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 75
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={92}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 75 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      8<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 76"
                        src={tri004}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 76
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 76 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 77"
                        src={tri005}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 77
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 77 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 78"
                        src={tri006}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 78
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 78 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 79"
                        src={tri007}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 79
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={96}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 79 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      4<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 80"
                        src={tri008}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 80
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={8}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 80 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      92<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 81"
                        src={tri009}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 81
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={12}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 81 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      88<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 82"
                        src={tri010}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 82
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={16}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 82 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      84<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 83"
                        src={tri011}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 83
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={20}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 83 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      80<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 84"
                        src={tri012}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 84
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={24}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 84 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      76<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 85"
                        src={tri013}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 85
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={28}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 85 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      72<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 86"
                        src={tri014}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 86
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={32}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 86 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      68<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 87"
                        src={tri015}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 87
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={36}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 87 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      64<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 88"
                        src={tri016}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 88
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={40}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 88 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      60<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 89"
                        src={tri017}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 89
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={44}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 89 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      56<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 90"
                        src={tri018}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 90
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={48}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 90 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      52<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 91"
                        src={tri019}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 91
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={52}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 91 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      48<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 92"
                        src={tri020}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 92
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={56}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 92 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      44<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 93"
                        src={tri021}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 93
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={60}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 93 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      40<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 94"
                        src={tri022}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 94
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={64}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 94 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      36<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 95"
                        src={tri023}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 95
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={68}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 95 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      32<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 96"
                        src={tri024}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 96
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={72}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 96 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      28<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 97"
                        src={tri025}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 97
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={76}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 97 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      24<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 98"
                        src={tri026}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 98
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={80}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 98 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      20<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="z"
                button
                href="https://lexdao-history.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 99"
                        src={tri027}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    2023 Web3Skills SmartLaw Achievement Token 99
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={84}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 99 description goes here. Join Web3SKills to learn more.
                    </div>
                    <div className="opacity-5">
                      16<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
            </List>
          </Card>
        </Grid>






































        <Grid item xl={6} className="d-flex">
          <Card className="card-box w-100">
            <div className="card-header">
              <div className="card-header--title">
                <small className="d-block text-uppercase mt-1">
                  Web3Skills SmartLaw
                </small>
                <b>Web3Skills SmartLaw Journey Discussions</b>
              </div>
              <div className="avatar-icon-wrapper avatar-initials shadow-none d-40 mr-0">
                <div className="avatar-icon text-white bg-night-sky d-40 font-size-sm">
                  SS
                </div>
                <div
                  className="badge badge-warning badge-position badge-position--bottom-center badge-circle-inner"
                  title="Badge bottom center">
                  Online
                </div>
              </div>
            </div>
            <div
              className={clsx(
                'd-flex transition-base align-items-center justify-content-between py-2 px-4',
                { 'bg-secondary': !inputBg }
              )}>
              <div>
                <Button
                  size="small"
                  className="btn-link p-0 btn-transition-none btn-link-warning">
                  <span className="btn-wrapper--label font-size-sm">
                    Web3 Legal Engineering Certification
                  </span>
                </Button>
              </div>
              <div className="font-size-sm font-weight-bold">
                Supervisor: Scott Stevenson
              </div>
            </div>
            <div className="divider" />
            <div
              className={clsx(
                'd-flex align-items-center transition-base px-4 py-1',
                { 'py-3 bg-secondary': inputBg }
              )}>
              <div className="search-wrapper w-100">
                <TextField
                  variant="outlined"
                  size="small"
                  className="bg-white w-100"
                  classes={{ root: 'input-border-0' }}
                  id="input-with-icon-textfield225-1"
                  placeholder="Search Web3 Legal Engineering …"
                  onFocus={toggleInputBg}
                  onBlur={toggleInputBg}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="divider" />
            <List component="div" className="list-group-flush">
              <ListItem
                component="a"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 1"
                        src={achieve001}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 1
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 1 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="b"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 2"
                        src={achieve002}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 2
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 2 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="c"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 3"
                        src={achieve003}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 3
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 3 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="d"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 4"
                        src={achieve004}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 4
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 4 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="e"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 5"
                        src={achieve005}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 5
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 5 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="f"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 6"
                        src={achieve006}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 6
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 6 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="g"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 7"
                        src={achieve007}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 7
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 7 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="h"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 8"
                        src={achieve008}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 8
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 8 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="I"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 9"
                        src={achieve009}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 9
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 9 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="j"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 10"
                        src={achieve010}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 10
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 10 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="k"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 11"
                        src={achieve011}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 11
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 11 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="l"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 12"
                        src={achieve012}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 12
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 12 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="m"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 13"
                        src={achieve013}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 13
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 13 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="n"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 14"
                        src={achieve014}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 14
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 14 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="o"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 15"
                        src={achieve015}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 15
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 15 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="p"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 16"
                        src={achieve016}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 16
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 16 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="q"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 17"
                        src={achieve017}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 17
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 17 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="r"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 18"
                        src={achieve018}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 18
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 18 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="s"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 19"
                        src={achieve019}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 19
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 19 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="t"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 20"
                        src={achieve020}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 20
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 20 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="u"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 21"
                        src={achieve021}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 21
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 21 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="v"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 22"
                        src={achieve022}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 22
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 22 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 23"
                        src={achieve023}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 23
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 23 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 24"
                        src={achieve024}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 24
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 24 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="a"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 25"
                        src={achieve025}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 25
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 25 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="b"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 26"
                        src={achieve026}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 26
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 26 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="c"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 27"
                        src={achieve027}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 27
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 27 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="d"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 28"
                        src={achieve028}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 28
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 28 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="e"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 29"
                        src={achieve029}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 29
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 29 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="f"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 30"
                        src={achieve030}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 30
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 30 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="g"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 31"
                        src={achieve031}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 31
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 31 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="h"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 32"
                        src={achieve032}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 32
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 32 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="I"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 33"
                        src={achieve033}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 33
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 33 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="j"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 34"
                        src={leg001}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 34
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 34 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="k"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 35"
                        src={leg002}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 35
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 35 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="l"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 36"
                        src={leg003}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 36
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 36 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="m"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 37"
                        src={leg004}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 37
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 37 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="n"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 38"
                        src={leg005}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 38
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 38 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="o"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 39"
                        src={leg006}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 39
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 39 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="p"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 40"
                        src={leg007}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 40
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 40 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="q"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 41"
                        src={leg008}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 41
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 41 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="r"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 42"
                        src={leg009}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 42
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 42 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="s"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 43"
                        src={leg010}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 43
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 43 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="t"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 44"
                        src={leg011}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 44
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 44 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="u"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 45"
                        src={leg012}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 45
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 45 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="v"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 46"
                        src={leg013}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 46
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 46 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 47"
                        src={leg014}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 47
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 47 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 48"
                        src={leg015}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 48
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 48 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="a"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 49"
                        src={leg016}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 49
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 49 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="b"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 50"
                        src={leg017}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 50
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 50 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="c"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 51"
                        src={leg018}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 51
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 51 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="d"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 52"
                        src={leg019}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 52
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 52 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="e"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 53"
                        src={leg020}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 53
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 53 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="f"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 54"
                        src={leg021}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 54
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 54 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="g"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 55"
                        src={leg022}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 55
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 55 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="h"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 56"
                        src={leg023}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 56
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 56 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="I"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 57"
                        src={leg024}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 57
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 57 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="j"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 58"
                        src={leg025}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 58
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 58 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="k"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 59"
                        src={scale001}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 59
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 59 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="l"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 60"
                        src={scale002}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 60
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 60 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="m"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 61"
                        src={scale003}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 61
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 61 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="n"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 62"
                        src={scale004}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 62
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 62 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="o"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 63"
                        src={scale005}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 63
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 63 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="p"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 64"
                        src={scale006}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 64
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 64 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="q"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 65"
                        src={scale007}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 65
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 65 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="r"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 66"
                        src={scale008}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 66
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 66 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="s"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 67"
                        src={scale009}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 67
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 67 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="t"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 68"
                        src={scale010}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 68
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 68 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="u"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 69"
                        src={scale011}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 69
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 69 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="v"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 70"
                        src={scale012}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 70
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 70 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 71"
                        src={scale013}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 71
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 71 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 72"
                        src={scale014}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 72
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 72 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="a"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 73"
                        src={tri001}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 73
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 73 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="b"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 74"
                        src={tri002}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 74
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 74 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="c"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 75"
                        src={tri003}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 75
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 75 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="d"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 76"
                        src={tri004}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 76
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 76 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="e"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 77"
                        src={tri005}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 77
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 77 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="f"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 78"
                        src={tri006}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 78
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 78 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="g"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 79"
                        src={tri007}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 79
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 79 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="h"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 80"
                        src={tri008}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 80
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 80 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="i"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 81"
                        src={tri009}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 81
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 81 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="j"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 82"
                        src={tri010}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 82
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 82 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="k"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 83"
                        src={tri011}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 83
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 83 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="l"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 84"
                        src={tri012}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 84
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 84 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="m"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 85"
                        src={tri013}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 85
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 85 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="n"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 86"
                        src={tri014}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 86
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 86 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="o"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 87"
                        src={tri015}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 87
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 87 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="p"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 88"
                        src={tri016}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 88
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 88 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="q"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 89"
                        src={tri017}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 89
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 89 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="r"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 90"
                        src={tri018}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 90
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 90 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="s"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 91"
                        src={tri019}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 91
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 91 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="t"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 92"
                        src={tri020}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 92
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 92 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="u"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 93"
                        src={tri021}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 93
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 93 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="v"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 94"
                        src={tri022}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 94
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 94 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="w"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 95"
                        src={tri023}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 95
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 95 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 96"
                        src={tri024}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 96
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 96 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 97"
                        src={tri025}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 97
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 97 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 98"
                        src={tri026}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 98
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 98 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
              <ListItem
                component="x"
                button
                href="https://tokens.freeweb3.com/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
                  <div className="text-center py-1">
                    <div className="rounded-circle text-white d-100">
                      <img
                        className="d-100 rounded-lg"
                        alt="Web3Skills Achievement 99"
                        src={tri027}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold font-size-sm text-black">
                    <div className="badge badge-first badge-circle border-white border-1 mr-2">
                      Completed
                    </div>
                    2023 Web3Skills SmartLaw Achievement Token 99
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div className="text-primary">
                      2023 Web3Skills Achievement 99 description goes here. Join Web3SKills to learn more.
                    </div>
                  </div>
                  <div className="d-flex justify-content-between font-size-xs">
                  </div>
                </div>
                <Button size="small" className="btn-neutral-dark px-3">
                  <span className="btn-wrapper—icon ml-2 mr-2">
                    <FontAwesomeIcon icon={['fas', 'landmark']} />
                  </span>
                  <span className="btn-wrapper—label mr-2">Join</span>
                </Button>
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>

    </>
  );
}
