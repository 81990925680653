import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, List, ListItem } from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';

export default function LivePreviewExample() {
  return (
    <>
      <Card className="card-box p-0 mb-spacing-6-x2">
        <div className="bg-composed-wrapper bg-plum-plate">
          <div className="bg-composed-wrapper--image bg-premium-dark opacity-2" />
          <div className="bg-composed-wrapper--image bg-composed-img-1" />
          <div className="bg-composed-wrapper--content text-center text-light p-5">
            <h5 className="mb-2 font-size-xl font-weight-bold text-white">
              Achievement Artifacts
            </h5>
            <p className="mb-0 text-white font-size-lg opacity-8">
              Scroll the Achievment tokens
            </p>
          </div>
        </div>
        <div className="shadow-overflow">
          <PerfectScrollbar
            className="scroll-area"
            option={{ wheelPropagation: false }}>
            <List
              component="div"
              className="nav-neutral-primary nav-lg nav-pills-rounded flex-column p-3">
              <ListItem
                button
                selected
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 1</span>
                <div className="ml-auto">
                  <div className="mr-3 badge badge-pill badge-primary">82</div>
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 2</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 3</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 4</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 5</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>

              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 6</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 7</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 8</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 9</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="nav-link-icon opacity-7">
                  <FontAwesomeIcon icon={['fas', 'bullseye']} />
                </div>
                <span>Achievement 10</span>
                <div className="ml-auto">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="font-size-xs opacity-3"
                  />
                </div>
              </ListItem>
            </List>
          </PerfectScrollbar>
        </div>
        <div className="card-footer d-flex py-3 justify-content-center">
          <Button
            href="https://lexq.freeweb3.com"
            rel="noopener noreferrer"
            target="_blank"
            size="medium"
            className="btn-primary text-white text-uppercase shadow-second-sm btn-primary">
            <span className="btn-wrapper--label">
              <small className="font-weight-bold font-size-xs text-uppercase px-4">
                View All Achievement Tokens
              </small>
            </span>
          </Button>
        </div>
      </Card>
    </>
  );
}
