import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  Checkbox,
  Button,
  List,
  ListItem,
  Tooltip,
  Divider
} from '@material-ui/core';

import { connect } from 'react-redux';
import Rating from '@material-ui/lab/Rating';

import { setHeaderDrawerToggle } from '../../reducers/ThemeOptions';

import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import avatar6 from '../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';

import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import CountUp from 'react-countup';
import Chart from 'react-apexcharts';
import PerfectScrollbar from 'react-perfect-scrollbar';

const HeaderDrawer = (props) => {
  const chartHeaderDrawerOptions = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },

    stroke: {
      color: '#1bc943',
      curve: 'smooth',
      width: 4
    },
    colors: ['#1bc943'],
    fill: {
      color: '1bc943',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.7,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    xaxis: {
      crosshairs: {
        width: 1
      }
    },
    yaxis: {
      min: 0
    }
  };
  const chartHeaderDrawerData = [
    {
      name: 'Monthly Analytics',
      data: [47, 38, 56, 24, 45, 54, 38, 56, 24, 65]
    }
  ];
  const [value, setValue] = useState(4);

  const { headerDrawerToggle, setHeaderDrawerToggle } = props;

  const toogleHeaderDrawer = () => {
    setHeaderDrawerToggle(!headerDrawerToggle);
  };

  return (
    <>
      <div className="app-drawer-wrapper">
        <Button
          size="small"
          onClick={toogleHeaderDrawer}
          className={clsx(
            'btn-transition-none navbar-toggler bg-transparent p-0 hamburger hamburger--elastic',
            { 'is-active': headerDrawerToggle }
          )}
          disableRipple>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
      </div>

      <div className="app-drawer-content">
        <Tooltip arrow title="Close drawer" placement="left">
          <Button
            size="small"
            onClick={toogleHeaderDrawer}
            className="close-drawer-btn bg-white p-0 d-40"
            id="CloseDrawerTooltip">
            <div
              className={clsx('navbar-toggler hamburger hamburger--elastic', {
                'is-active': headerDrawerToggle
              })}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </div>
          </Button>
        </Tooltip>
        <div className="vh-100 shadow-overflow">
          <PerfectScrollbar>
            <div className="p-4">
              <div className="text-center">
                <div className="font-weight-bold font-size-lg mb-0 text-black">
                  Web3Skills Achievements Target
                </div>
                <p className="text-black-50">
                  Progress towards achievments target.
                </p>
              </div>
              <div className="rounded p-4 text-white bg-deep-sky">
                <div className="d-flex mb-3 px-0 justify-content-center">
                  <CircularProgressbarWithChildren
                    circleRatio={0.75}
                    styles={buildStyles({
                      textColor: 'var(--white)',
                      pathColor: 'rgba(255,255,255,.95)',
                      trailColor: 'rgba(255,255,255,.1)',
                      rotation: 1 / 2 + 1 / 8
                    })}
                    value={63}
                    strokeWidth={8}
                    className="circular-progress-xl">
                    <span className="font-weight-bold font-size-xl">63%</span>
                  </CircularProgressbarWithChildren>
                </div>
                <Button
                  size="small"
                  variant="contained"
                  className="shadow-none w-100 bg-white text-uppercase font-size-xs font-weight-bold">
                  Acquire Token Now
                </Button>
              </div>
            </div>
            <div className="divider" />
            <div className="p-4 text-center">
              <h4 className="mb-0 display-3 mt-1 font-weight-bold">
                Tokens Minted:{' '}
                <span className="pr-1">
                  <CountUp
                    start={0}
                    end={1.234}
                    duration={4}
                    separator=""
                    delay={0}
                    decimals={3}
                    decimal=","
                    prefix=""
                    suffix=""
                  />
                </span>
              </h4>
              <Chart
                options={chartHeaderDrawerOptions}
                series={chartHeaderDrawerData}
                type="area"
                height={120}
              />
              <Grid container spacing={3} className="mt-4">
                <Grid item md={6}>
                  <span className="opacity-6 pb-2">Current month</span>
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="font-weight-bold font-size-lg">
                      <small className="opacity-6 pr-1">tokens:</small>
                      362
                    </span>
                    <div className="badge badge-neutral-danger text-primary ml-2">
                      8%
                    </div>
                  </div>
                </Grid>
                <Grid item md={6}>
                  <span className="opacity-6 pb-2">Same mo. last year</span>
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="font-weight-bold font-size-lg">
                      <small className="opacity-6 pr-1">tokens:</small>
                      546
                    </span>
                    <div className="badge badge-neutral-success text-primary ml-2">
                      13%
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="pb-2 pt-4 text-center">
                <Button
                  size="small"
                  variant="text"
                  className="btn-outline-primary">
                  <span className="btn-wrapper--label">
                    View achievements report
                  </span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                  </span>
                </Button>
              </div>
            </div>
            <div className="divider" />
            <div className="p-4">
              <div className="text-center">
                <div className="font-weight-bold font-size-lg mb-0 text-black">
                  Web3Skills Current Tasks
                </div>
                <p className="text-black-50">Your daily tasks list</p>
              </div>
              <div className="rounded p-3 bg-secondary">
                <div className="task-wrapper">
                  <div className="task-item">
                    <div className="align-box-row">
                      <Checkbox className="align-self-center mr-2" />
                      <div>
                        <b>Submit content to the repository</b>
                        <p className="text-black-50 mt-1 mb-0">
                          All content created for the metaverse project must be available for in-world placement.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="task-item">
                    <div className="align-box-row">
                      <Checkbox className="align-self-center mr-2" />
                      <div>
                        <b>Course 1 - Lesson 1</b>
                        <p className="text-black-50 mt-1 mb-0">
                          Instructions for Course 1 - Lesson 1 
                        </p>
                        <div className="timeline-list mt-3">
                          <div className="timeline-item timeline-item-icon">
                            <div className="timeline-item--content">
                              <div className="timeline-item--icon-wrapper bg-first font-size-xl text-white">
                                <FontAwesomeIcon icon={['fas', 'clock']} />
                              </div>
                              <h4 className="timeline-item--label mb-2 font-weight-bold">
                                Task 1
                              </h4>
                              <p>
                                Click the button to start the course timer.
                              </p>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-item--content">
                              <div className="timeline-item--icon" />
                              <h4 className="timeline-item--label mb-2 font-weight-bold">
                                Task 2
                              </h4>
                              <p>
                                Click the button to review the course material
                              </p>
                              <div className="avatar-wrapper-overlap mt-2 mb-1">
                                <div className="avatar-icon-wrapper avatar-icon-sm">
                                  <div className="avatar-icon">
                                    <img alt="..." src={avatar1} />
                                  </div>
                                </div>
                                <div className="avatar-icon-wrapper avatar-icon-sm">
                                  <div className="avatar-icon">
                                    <img alt="..." src={avatar7} />
                                  </div>
                                </div>
                                <div className="avatar-icon-wrapper avatar-icon-sm">
                                  <div className="avatar-icon">
                                    <img alt="..." src={avatar1} />
                                  </div>
                                </div>
                                <div className="avatar-icon-wrapper avatar-icon-sm">
                                  <div className="avatar-icon">
                                    <img alt="..." src={avatar2} />
                                  </div>
                                </div>
                                <div className="avatar-icon-wrapper avatar-icon-sm">
                                  <div className="avatar-icon">
                                    <img alt="..." src={avatar6} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-item--content">
                              <div className="timeline-item--icon" />
                              <h4 className="timeline-item--label mb-2 font-weight-bold">
                                Task 3
                                <div className="badge badge-neutral-info text-info ml-2">
                                  New
                                </div>
                              </h4>
                              <p>
                                Answer the multiple choice questions on the form and submit your written answers 
                              </p>
                              <div className="mt-3">
                                <Button
                                  size="small"
                                  variant="contained"
                                  className="btn-primary">
                                  Submit the Answers
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="task-item">
                    <div className="align-box-row">
                      <Checkbox className="align-self-center mr-2" />
                      <div className="w-100">
                        <b>Course work submission</b>
                        <p className="text-black-50 mt-1">
                          When the lesson is complete, all work must be submitted and reviewed prior to receiving an achievemnt token.
                        </p>

                        <List component="div">
                          <ListItem className="bg-white d-flex justify-content-between align-items-center p-4">
                            <div className="w-100">
                              <div className="d-flex flex-wrap justify-content-between mb-2">
                                <small className="line-height-xs text-uppercase text-muted">
                                  Bonus Achievement
                                </small>
                                <small className="line-height-xs text-uppercase text-success">
                                  Due 10-days after sumission.
                                </small>
                              </div>
                              <h6 className="py-1">
                                <a
                                  href="#/"
                                  className="font-weight-bold"
                                  target="_blank" 
rel="noopener noreferrer"

>

                                  #101 - Acquire an avatar wearable
                                </a>
                              </h6>
                              <p className="mb-3">
                                SmartLaw_API avatars allow for customization. To earn an extra achievement and a metaverse digital asset, click the button below and complete a transaction 
                              </p>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="badge badge-pill badge-danger px-3">
                                  Bonus
                                </div>
                                <div>
                                  <Button
                                    size="small"
                                    className="btn-primary"
                                    variant="contained">
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={['fas', 'plus']}
                                        className="font-size-sm"
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Submit Proof
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </ListItem>
                          <Divider />
                          <ListItem className="d-flex justify-content-between align-items-center align-content-center bg-white">
                            <Tooltip
                              title="There are 653 new comments available!"
                              placement="top">
                              <Button
                                href="#/"
                                target="_blank" 
rel="noopener noreferrer"

                                size="small">
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['far', 'comments']} />
                                </span>
                                <span className="btn-wrapper--label ml-1">
                                  653
                                </span>
                              </Button>
                            </Tooltip>
                            <div className="d-flex align-items-center">
                              <Rating
                                name="simple-controlled"
                                value={value}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                              />
                            </div>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="p-4">
              <div className="text-center">
                <div className="font-weight-bold font-size-lg mb-0 text-black">
                  Current Tokens Statistics
                </div>
                <p className="text-black-50">Latest reports available</p>
              </div>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <div className="bg-secondary rounded py-2 px-1">
                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                      <FontAwesomeIcon
                        icon={['fas', 'fire']}
                        className="font-size-lg text-first mr-2"
                      />
                      <div>436</div>
                    </div>
                    <div className="text-black-50 text-center opacity-6 pt-3">
                      <b>Achievement</b> tokens
                    </div>
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="bg-secondary rounded py-2 px-1">
                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                      <FontAwesomeIcon
                        icon={['fas', 'fire']}
                        className="font-size-lg text-first mr-2"
                      />
                      <div>843</div>
                    </div>
                    <div className="text-black-50 text-center opacity-6 pt-3">
                      <b>Access</b> tokens
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      <div
        onClick={toogleHeaderDrawer}
        className={clsx('app-drawer-overlay', {
          'is-active': headerDrawerToggle
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderDrawerToggle: (enable) => dispatch(setHeaderDrawerToggle(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDrawer);
