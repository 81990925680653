import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Button,
  List,
  ListItem,
  Tooltip,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Card,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  FormLabel,
  FormGroup,
  InputAdornment,
  IconButton,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// import AccountCircle from '@material-ui/icons/AccountCircle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import Iframe from 'react-iframe';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 200
  }
}));
export default function LivePreviewExample() {
  const [value2, setValue2] = useState('Controlled');
  const handleChange2 = (event) => {
    setValue2(event.target.value);
  };
  const [state, setState] = useState({
    grant_committee_m1: false,
    grant_committee_m2: false,
    grant_committee_m3: false,
    grant_committee_m4: false,
    grant_committee_m5: false,
    grant_committee_m6: false,
    grant_committee_m7: false,
    grant_committee_m8: false,
    grant_committee_m9: false,
    grant_committee_m10: false,
    grant_committee_m11: false
  });
  const handleChange3 = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const {
    grant_committee_m1,
    grant_committee_m2,
    grant_committee_m3,
    grant_committee_m4,
    grant_committee_m5,
    grant_committee_m6,
    grant_committee_m7,
    grant_committee_m8,
    grant_committee_m9,
    grant_committee_m10,
    grant_committee_m11
  } = state;
  const error =
    [
      grant_committee_m1,
      grant_committee_m2,
      grant_committee_m3,
      grant_committee_m4,
      grant_committee_m5,
      grant_committee_m6,
      grant_committee_m7,
      grant_committee_m8,
      grant_committee_m9,
      grant_committee_m10,
      grant_committee_m11
    ].filter((v) => v).length === 0;
  const classes = useStyles();
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="app-content p-0">
        <div className="app-inner-content-layout--main">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content">
              <Grid container spacing={0} className="min-vh-100">
                <Grid item lg={7} xl={6} className="d-flex align-items-center">
                  <Grid item md={10} lg={8} xl={7} className="mx-auto">
                    <h1 className="display-4 mb-3 font-weight-bold">
                      Applicant/Organization Information
                    </h1>

                    <Iframe
                      id=""
                      src="https://airtable.com/embed/shrrD0oUc8WdVZBBG?backgroundColor=gray"
                      frameborder="0"
                      onmousewheel=""
                      width="100%"
                      height="600"
                      // height="2454"
                      className="mb-3"
                      display="block"
                      position="relative"
                      background="transparent"
                      border="1px"
                    />

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Applicant Organization | 035
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 035 | "
                        name="applicant_org_formfield_035"
                        type="textarea"
                        helperText="Helper note sits just below the form field 035 | Please provide information for the lead organization applying for the Seed Grant. Lead organizations may be local governments, 501(c)(3) non-profit organizations considered a public charity by the IRS, or informal resident-led groups."
                      />

                      <Divider className="my-4" />
                      <FormControl required error={error} component="fieldset">
                        <FormLabel id="demo-radio-buttons-group-label">
                          Is this an existing business entity?
                        </FormLabel>

                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="no"
                          name="radio-buttons-group">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          You must select on of the two options above.
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          EIN | 036
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 036 | "
                        name="ein_formfield_036"
                        type="textarea"
                        helperText="Helper note sits just below the form field 036 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          LEETH SPONSOR [CEO/Executive Director name, title] |
                          037
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 037 | "
                        name="leeth_sponsor_formfield_037"
                        type="textarea"
                        helperText="Helper note sits just below the form field 037 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Country of Entity | 038
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 038 | "
                        name="country_of_entity_formfield_038"
                        type="textarea"
                        helperText="Helper note sits just below the form field 038 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          If USA, which State | 039
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 039 | "
                        name="usa_state_formfield_039"
                        type="textarea | AlabamaAlaskaArizonaArkansasCaliforniaColoradoConnecticutDelawareFloridaGeorgiaHawaiiIdahoIllinoisIndianaIowaKansasKentuckyLouisianaMaineMarylandMassachusettsMichiganMinnesotaMississippiMissouriMontanaNebraskaNevadaNew HampshireNew JerseyNew MexicoNew YorkNorth CarolinaNorth DakotaOhioOklahomaOregonPennsylvaniaRhode IslandSouth CarolinaSouth DakotaTennesseeTexasUtahVermontVirginiaWashingtonWest VirginiaWisconsinWyomingAmerican SamoaGuamNorthern Mariana IslandsPuerto RicoU.S. Virgin IslandsFederally Recognized Tribe"
                        helperText="Helper note sits just below the form field 039 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Legal Jurisdiction (if known) | 040
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 040 | "
                        name="jurisdiction_formfield_040"
                        type="textarea"
                        helperText="Helper note sits just below the form field 040 | Select a jurisdiction"
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Do you have a partnering organization? yes/no | 041
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 041 | "
                        name="partner_org_formfield_041"
                        type="boolean"
                        helperText="Helper note sits just below the form field 041 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Do you have a Fiscal Sponsor? yes/no | 042
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 042 | "
                        name="fiscal_sponsor_formfield_042"
                        type="boolean"
                        helperText="Helper note sits just below the form field 042 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          What financial resources are available to support this
                          project. | 043
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 043 | "
                        name="supportive_fin_resources_formfield_043"
                        type="textarea"
                        helperText="Helper note sits just below the form field 043 | How will you match the $10,000 seed grant? How do you plan to cover costs such as the Project Coordinator, SmartLaw Coach and other project expenses?"
                      />
                    </div>

                    <Grid container spacing={4}>
                      <Grid item xs={12} lg={4}>
                        <Card className="p-4 mb-4">
                          <Divider className="my-4" />
                          <FormControl
                            required
                            error={error}
                            component="fieldset">
                            <FormLabel component="legend">
                              Pick Grant Committee Members for Review
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m1}
                                    onChange={handleChange3(
                                      'grant_committee_m1'
                                    )}
                                    value="grant_committee_m1"
                                  />
                                }
                                label="GCM Name 1"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m2}
                                    onChange={handleChange3(
                                      'grant_committee_m2'
                                    )}
                                    value="grant_committee_m2"
                                  />
                                }
                                label="GCM Name 2"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m3}
                                    onChange={handleChange3(
                                      'grant_committee_m3'
                                    )}
                                    value="grant_committee_m3"
                                  />
                                }
                                label="GCM Name 3"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m4}
                                    onChange={handleChange3(
                                      'grant_committee_m4'
                                    )}
                                    value="grant_committee_m4"
                                  />
                                }
                                label="GCM Name 4"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m5}
                                    onChange={handleChange3(
                                      'grant_committee_m5'
                                    )}
                                    value="grant_committee_m5"
                                  />
                                }
                                label="GCM Name 5"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m6}
                                    onChange={handleChange3(
                                      'grant_committee_m6'
                                    )}
                                    value="grant_committee_m6"
                                  />
                                }
                                label="GCM Name 6"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m7}
                                    onChange={handleChange3(
                                      'grant_committee_m7'
                                    )}
                                    value="grant_committee_m7"
                                  />
                                }
                                label="GCM Name 7"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m8}
                                    onChange={handleChange3(
                                      'grant_committee_m8'
                                    )}
                                    value="grant_committee_m8"
                                  />
                                }
                                label="GCM Name 8"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m9}
                                    onChange={handleChange3(
                                      'grant_committee_m9'
                                    )}
                                    value="grant_committee_m9"
                                  />
                                }
                                label="GCM Name 9"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m10}
                                    onChange={handleChange3(
                                      'grant_committee_m10'
                                    )}
                                    value="grant_committee_m10"
                                  />
                                }
                                label="GCM Name 10"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={grant_committee_m11}
                                    onChange={handleChange3(
                                      'grant_committee_m11'
                                    )}
                                    value="grant_committee_m11"
                                  />
                                }
                                label="GCM Name 11"
                              />
                            </FormGroup>
                            <FormHelperText>
                              Select at least one Grant Committee Member to
                              review your grant proposal.
                            </FormHelperText>
                          </FormControl>
                        </Card>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Card className="p-4 mb-4">
                          <Divider className="my-4" />
                          <div>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-amount">
                                Grant Amount Requested
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                value={values.amount}
                                onChange={handleChange('amount')}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                                labelWidth={100}
                              />
                            </FormControl>
                            <TextField
                              fullWidth
                              className="m-2"
                              id="filled-multiline-flexible"
                              label="Multiline"
                              multiline
                              rowsMax="4"
                              value={value2}
                              onChange={handleChange2}
                              variant="filled"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="filled-textarea"
                              label="Multiline Placeholder"
                              placeholder="Placeholder"
                              multiline
                              variant="filled"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="filled-multiline-static"
                              label="Multiline"
                              multiline
                              rows="4"
                              defaultValue="Grant field default value"
                              variant="filled"
                            />
                          </div>
                          <div>
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-flexible"
                              label="Multiline"
                              multiline
                              rowsMax="4"
                              value={value2}
                              onChange={handleChange2}
                              variant="outlined"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-textarea"
                              label="Multiline Placeholder"
                              placeholder="Placeholder"
                              multiline
                              variant="outlined"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="Multiline"
                              multiline
                              rows="4"
                              defaultValue="Grant field default value"
                              variant="outlined"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="Multiline"
                              multiline
                              rows="4"
                              defaultValue="Grant field default value"
                              variant="outlined"
                            />
                          </div>
                          <Divider className="my-4" />
                          <div>
                            <FormControl
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined">
                              <InputLabel htmlFor="filled-adornment-password">
                                Secret
                              </InputLabel>
                              <FilledInput
                                id="filled-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end">
                                      {values.showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </div>
                        </Card>
                        <Card className="p-4 mb-4">
                          <Divider className="my-4" />
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={12}>
                              <div className="p-3">
                                <TextField
                                  fullWidth
                                  className="m-2"
                                  id="standard-basic"
                                  defaultValue="Enter the Country of at least one of the Project Principals"
                                  label="Project Location"
                                  size="small"
                                />
                                <TextField
                                  fullWidth
                                  className="m-2"
                                  id="filled-basic"
                                  defaultValue="Enter the State if un the USA"
                                  label="Enter USA State name (if required)"
                                  variant="filled"
                                  size="small"
                                />
                                <TextField
                                  fullWidth
                                  className="m-2"
                                  id="outlined-basic"
                                  defaultValue="Enter County name if in California"
                                  label="California county name"
                                  variant="outlined"
                                  size="small"
                                />
                                <TextField
                                  fullWidth
                                  className="m-2"
                                  label="EIN"
                                  id="outlined-size-small"
                                  defaultValue="Enter the EIN for the projects or other tax id Code"
                                  variant="outlined"
                                  size="small"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={5} xl={6} className="d-flex">
                  <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image opacity-5"
                        style={{
                          backgroundImage: 'url(' + sideImage + ')'
                        }}
                      />
                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                      <div className="bg-composed-wrapper--content text-center p-5">
                        <div className="text-white px-0 px-lg-2 px-xl-4">
                          <h1 className="display-3 mb-4 font-weight-bold text-white">
                          Applicant/Organization Information
                          </h1>
                          <p className="font-size-lg mb-0 opacity-8">
                            SmartLaw provides tools for Web3 ecosystem to
                            advance legaltech. 11
                          </p>
                          <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                          <div>
                            <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                              <span className="btn-wrapper--label">
                                Learn more about Grant Applicant/Organization Information
                              </span>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'arrow-right']}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero-footer pb-4">
                      <List
                        component="div"
                        className="nav-pills nav-neutral-secondary d-flex">
                        <Tooltip title="Github" arrow>
                          <ListItem
                            component="a"
                            button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-size-lg text-white-50">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
