import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';

import BlocksImages15 from '../../example-components/Credcert/BlocksImages15';
export default function Grmachine() {
  return (
    <>
      <PageTitle
        titleHeading="Web3 Legal Engineering Grant Making & Scoring System"
        titleDescription="Legal Engineering requires proficiency in law and tech. Prerequisites include LexDAO membership, a 3L or greater legal education (or equivalent) and a basic understanding of web3 technologies found in the LexClinic House of Automation."
      />

      <ExampleWrapperSeamless>
        <BlocksImages15 />
      </ExampleWrapperSeamless>
    </>
  );
}
