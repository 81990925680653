import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Button } from '@material-ui/core';

// import avatar1 from '../../../assets/images/avatars/avatar1.jpg';
// import avatar2 from '../../../assets/images/avatars/avatar2.jpg';

// import avatar6 from '../../../assets/images/avatars/avatar6.jpg';

// import milestone1 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-015.png';
// import milestone2 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-020.png';
// import milestone3 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-008.png';
// import milestone4 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-009.png';
// import w3logo from '../../../assets/images/w3-logo.gif';
import smartlawlogo from '../../../assets/images/smartlaw-drkblue-100x.png';



import ak_flag from '../../../assets/images/us-w80/ak.png';
import al_flag from '../../../assets/images/us-w80/al.png';
import ar_flag from '../../../assets/images/us-w80/ar.png';
import az_flag from '../../../assets/images/us-w80/az.png';
import ca_flag from '../../../assets/images/us-w80/ca.png';
import co_flag from '../../../assets/images/us-w80/co.png';
import ct_flag from '../../../assets/images/us-w80/ct.png';
import de_flag from '../../../assets/images/us-w80/de.png';
import fl_flag from '../../../assets/images/us-w80/fl.png';
import ga_flag from '../../../assets/images/us-w80/ga.png';
import hi_flag from '../../../assets/images/us-w80/hi.png';
import ia_flag from '../../../assets/images/us-w80/ia.png';
import id_flag from '../../../assets/images/us-w80/id.png';
import il_flag from '../../../assets/images/us-w80/il.png';
import in_flag from '../../../assets/images/us-w80/in.png';
import ks_flag from '../../../assets/images/us-w80/ks.png';
import ky_flag from '../../../assets/images/us-w80/ky.png';
import la_flag from '../../../assets/images/us-w80/la.png';
import ma_flag from '../../../assets/images/us-w80/ma.png';
import md_flag from '../../../assets/images/us-w80/md.png';
import me_flag from '../../../assets/images/us-w80/me.png';
import mi_flag from '../../../assets/images/us-w80/mi.png';
import mn_flag from '../../../assets/images/us-w80/mn.png';
import mo_flag from '../../../assets/images/us-w80/mo.png';
import ms_flag from '../../../assets/images/us-w80/ms.png';
import mt_flag from '../../../assets/images/us-w80/mt.png';
import nc_flag from '../../../assets/images/us-w80/nc.png';
import nd_flag from '../../../assets/images/us-w80/nd.png';
import ne_flag from '../../../assets/images/us-w80/ne.png';
import nh_flag from '../../../assets/images/us-w80/nh.png';
import nj_flag from '../../../assets/images/us-w80/nj.png';
import nm_flag from '../../../assets/images/us-w80/nm.png';
import nv_flag from '../../../assets/images/us-w80/nv.png';
import ny_flag from '../../../assets/images/us-w80/ny.png';
import oh_flag from '../../../assets/images/us-w80/oh.png';
import ok_flag from '../../../assets/images/us-w80/ok.png';
import or_flag from '../../../assets/images/us-w80/or.png';
import pa_flag from '../../../assets/images/us-w80/pa.png';
import ri_flag from '../../../assets/images/us-w80/ri.png';
import sc_flag from '../../../assets/images/us-w80/sc.png';
import sd_flag from '../../../assets/images/us-w80/sd.png';
import tn_flag from '../../../assets/images/us-w80/tn.png';
import tx_flag from '../../../assets/images/us-w80/tx.png';
import ut_flag from '../../../assets/images/us-w80/ut.png';
import va_flag from '../../../assets/images/us-w80/va.png';
import vt_flag from '../../../assets/images/us-w80/vt.png';
import wa_flag from '../../../assets/images/us-w80/wa.png';
import wi_flag from '../../../assets/images/us-w80/wi.png';
import wv_flag from '../../../assets/images/us-w80/wv.png';
import wy_flag from '../../../assets/images/us-w80/wy.png';



export default function LivePreviewExample() {
  return (
    <>
<Grid container spacing={6}>
<Grid item lg={4}>
<div className="timeline-list timeline-list-offset mb-5">
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">CA</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
California Legislation &#38; Regulations
</h4>
<p>
California: In 2018, the state passed a law called the Virtual Currency Act that regulates businesses that engage in virtual currency transactions, including those that issue or sell digital tokens. Additionally, California's Money Transmission Act requires any entity engaging in the business of money transmission to obtain a license. Assembly Bill 2150 (AB 2150): Allows corporations to use blockchain technology for the issuance and transfer of stock certificates. California Consumer Privacy Act (CCPA): regulates how businesses can collect, store, and share personal information of California residents.
</p>
<div className="mt-3">
<a href="http://leginfo.legislature.ca.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="CA"
className="img-fluid rounded mr-3 shadow-sm"
src={ca_flag}
width="80"
/>
</a>
<a href="http://leginfo.legislature.ca.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="CA"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help CA
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">ID</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Idaho Legislation &#38; Regulations
</h4>
<p>
Idaho: In 2022, Idaho Governor signed HB 583, the Digital Asset Act amends Title 28 of the Idaho Code, which governs commercial transactions generally, to include a new chapter dedicated to regulation of digital assets.
</p>
<div className="mt-3">
<a href="https://legislature.idaho.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="ID"
className="img-fluid rounded mr-3 shadow-sm"
src={id_flag}
width="80"
/>
</a>
<a href="https://legislature.idaho.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="ID"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help ID
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">WY</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Wyoming Legislation &#38; Regulations
</h4>
<p>
Wyoming: Wyoming has been a leader in enacting cryptocurrency and blockchain-friendly legislation. In 2018, the state passed several bills related to blockchain and cryptocurrency, including the Wyoming Utility Token Act which exempts certain digital tokens from state securities laws and the Wyoming Money Transmitter Act which exempts virtual currency transactions from state money transmitter laws.
</p>
<div className="mt-3">
<a href="https://www.wyoleg.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="WY"
className="img-fluid rounded mr-3 shadow-sm"
src={wy_flag}
width="80"
/>
</a>
<a href="https://www.wyoleg.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="WY"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help WY
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">CO</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Colorado Legislation &#38; Regulations
</h4>
<p>
Colorado: In 2019, Colorado passed the Digital Token Act which exempts certain digital tokens from state securities laws if they meet certain criteria, such as being used for a "consumptive purpose." Colorado Digital Token Act: exempts certain digital tokens from securities regulations.
</p>
<div className="mt-3">
<a href="http://leg.colorado.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="CO"
className="img-fluid rounded mr-3 shadow-sm"
src={co_flag}
width="80"
/>
</a>
<a href="http://leg.colorado.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="CO"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help CO
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">HI</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Hawaii Legislation &#38; Regulations
</h4>
<p>
Hawaii: In 2019, Hawaii passed a law that allows banks to hold digital assets on behalf of their customers, making it easier for Hawaiians to access cryptocurrency.
</p>
<div className="mt-3">
<a href="http://www.capitol.hawaii.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="HI"
className="img-fluid rounded mr-3 shadow-sm"
src={hi_flag}
width="80"
/>
</a>
<a href="http://www.capitol.hawaii.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="HI"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help HI
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">NV</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Nevada Legislation &#38; Regulations
</h4>
<p>
Nevada: Nevada Senate Bill 398: recognizes blockchain technology as a type of electronic record for purposes of the Uniform Electronic Transactions Act and provides specific exemptions for businesses that use blockchain technology for record-keeping purposes.
</p>
<div className="mt-3">
<a href="https://www.leg.state.nv.us/" target="_blank" rel="noopener noreferrer">
<img
alt="NV"
className="img-fluid rounded mr-3 shadow-sm"
src={nv_flag}
width="80"
/>
</a>
<a href="https://www.leg.state.nv.us/" target="_blank" rel="noopener noreferrer">
<img
alt="NV"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help NV
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">WA</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Washington Legislation &#38; Regulations
</h4>
<p>
Washington: Washington House Bill 1045: allows corporations to issue and transfer shares using blockchain technology. The Washington State Uniform Electronic Transactions Act (UETA): recognizes digital signatures as legally binding.
</p>
<div className="mt-3">
<a href="http://leg.wa.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="WA"
className="img-fluid rounded mr-3 shadow-sm"
src={wa_flag}
width="80"
/>
</a>
<a href="http://leg.wa.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="WA"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help WA
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">UT</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Utah Legislation &#38; Regulations
</h4>
<p>
Utah: Utah Senate Bill 213: exempts certain blockchain tokens from securities regulations.
</p>
<div className="mt-3">
<a href="https://le.utah.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="UT"
className="img-fluid rounded mr-3 shadow-sm"
src={ut_flag}
width="80"
/>
</a>
<a href="https://le.utah.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="UT"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help UT
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">OR</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Oregon Legislation &#38; Regulations
</h4>
<p>
Oregon: No current legislation.
</p>
<div className="mt-3">
<a href="https://www.oregonlegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="OR"
className="img-fluid rounded mr-3 shadow-sm"
src={or_flag}
width="80"
/>
</a>
<a href="https://www.oregonlegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="OR"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help OR
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">AZ</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Arizona Legislation &#38; Regulations
</h4>
<p>
Illinois: In 2017, Illinois passed a law that recognizes smart contracts as legally binding agreements. The state also has a money transmitter license requirement that may apply to cryptocurrency businesses. Illinois has not enacted any specific laws or regulations related to cryptocurrency, but the Illinois Department of Financial and Professional Regulation has issued guidance on the applicability of existing state laws to virtual currency transactions.
</p>
<div className="mt-3">
<a href="https://www.azleg.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="AZ"
className="img-fluid rounded mr-3 shadow-sm"
src={az_flag}
width="80"
/>
</a>
<a href="https://www.azleg.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="AZ"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help AZ
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">NM</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
New Mexico Legislation &#38; Regulations
</h4>
<p>
New Mexico: There are currently no state-specific laws or regulations related to cryptography, digital tokens, or decentralized finance.
</p>
<div className="mt-3">
<a href="https://www.nmlegis.gov/ " target="_blank" rel="noopener noreferrer">
<img
alt="NM"
className="img-fluid rounded mr-3 shadow-sm"
src={nm_flag}
width="80"
/>
</a>
<a href="https://www.nmlegis.gov/ " target="_blank" rel="noopener noreferrer">
<img
alt="NM"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help NM
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">OK</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Oklahoma Legislation &#38; Regulations
</h4>
<p>
Oklahoma: There are currently no state-specific laws or regulations related to cryptography, digital tokens, or decentralized finance.
</p>
<div className="mt-3">
<a href="http://www.oklegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="OK"
className="img-fluid rounded mr-3 shadow-sm"
src={ok_flag}
width="80"
/>
</a>
<a href="http://www.oklegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="OK"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help OK
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">TX</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Texas Legislation &#38; Regulations
</h4>
<p>
Texas: In June 2019, Texas passed a law that provides a legal framework for blockchain signatures and smart contracts. There are currently no other state-specific laws or regulations related to cryptography, digital tokens, or decentralized finance. Texas: In 2019, Texas passed the Virtual Currency Bill, which defines virtual currencies and requires those who deal with virtual currencies to be licensed. However, there is no specific legislation related to decentralized finance or non-fungible tokens in Texas.
</p>
<div className="mt-3">
<a href="https://capitol.texas.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="TX"
className="img-fluid rounded mr-3 shadow-sm"
src={tx_flag}
width="80"
/>
</a>
<a href="https://capitol.texas.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="TX"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help TX
</Button>
</div>
</div>
</div>
</div>
</Grid>
<Grid item lg={4}>
<div className="timeline-list timeline-list-offset timeline-list-offset-dot mb-5">
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">IL</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Illinois Legislation &#38; Regulations
</h4>
<p>
Illinois: In 2017, Illinois passed a law that recognizes smart contracts as legally binding agreements. The state also has a money transmitter license requirement that may apply to cryptocurrency businesses. Illinois has not enacted any specific laws or regulations related to cryptocurrency, but the Illinois Department of Financial and Professional Regulation has issued guidance on the applicability of existing state laws to virtual currency transactions.
</p>
<div className="mt-3">
<a href="http://www.ilga.gov/ " target="_blank" rel="noopener noreferrer">
<img
alt="IL"
className="img-fluid rounded mr-3 shadow-sm"
src={il_flag}
width="80"
/>
</a>
<a href="http://www.ilga.gov/ " target="_blank" rel="noopener noreferrer">
<img
alt="IL"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help IL
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">IN</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Indiana Legislation &#38; Regulations
</h4>
<p>
Indiana: Indiana does not have any state-specific laws or regulations related to cryptocurrency or blockchain technology.
</p>
<div className="mt-3">
<a href="http://iga.in.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="IN"
className="img-fluid rounded mr-3 shadow-sm"
src={in_flag}
width="80"
/>
</a>
<a href="http://iga.in.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="IN"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help IN
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">IA</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Iowa Legislation &#38; Regulations
</h4>
<p>
Iowa: Iowa does not have any state-specific laws or regulations related to cryptocurrency or blockchain technology.
</p>
<div className="mt-3">
<a href="https://www.legis.iowa.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="IA"
className="img-fluid rounded mr-3 shadow-sm"
src={ia_flag}
width="80"
/>
</a>
<a href="https://www.legis.iowa.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="IA"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help IA
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">KS</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Kansas Legislation &#38; Regulations
</h4>
<p>
Kansas: Kansas does not have any state-specific laws or regulations related to cryptocurrency or blockchain technology.
</p>
<div className="mt-3">
<a href="http://www.kslegislature.org/li/ " target="_blank" rel="noopener noreferrer">
<img
alt="KS"
className="img-fluid rounded mr-3 shadow-sm"
src={ks_flag}
width="80"
/>
</a>
<a href="http://www.kslegislature.org/li/ " target="_blank" rel="noopener noreferrer">
<img
alt="KS"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help KS
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">MI</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Michigan Legislation &#38; Regulations
</h4>
<p>
Michigan: Michigan has not enacted any specific laws or regulations related to cryptocurrency, but the state's Uniform Securities Act may apply to certain digital asset offerings.
</p>
<div className="mt-3">
<a href="http://www.legislature.mi.gov/(S(vyioe1zsf0d4hlnnu5y3k0c2))/mileg.aspx?page=home" target="_blank" rel="noopener noreferrer">
<img
alt="MI"
className="img-fluid rounded mr-3 shadow-sm"
src={mi_flag}
width="80"
/>
</a>
<a href="http://www.legislature.mi.gov/(S(vyioe1zsf0d4hlnnu5y3k0c2))/mileg.aspx?page=home" target="_blank" rel="noopener noreferrer">
<img
alt="MI"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help MI
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">MN</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Minnesota Legislation &#38; Regulations
</h4>
<p>
Minnesota: Minnesota does not have any state-specific laws or regulations related to cryptocurrency or blockchain technology.
</p>
<div className="mt-3">
<a href="https://www.leg.state.mn.us/" target="_blank" rel="noopener noreferrer">
<img
alt="MN"
className="img-fluid rounded mr-3 shadow-sm"
src={mn_flag}
width="80"
/>
</a>
<a href="https://www.leg.state.mn.us/" target="_blank" rel="noopener noreferrer">
<img
alt="MN"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help MN
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">MO</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Missouri Legislation &#38; Regulations
</h4>
<p>
Missouri: Missouri does not have any state-specific laws or regulations related to cryptocurrency or blockchain technology.
</p>
<div className="mt-3">
<a href="https://www.mo.gov/government/legislative-branch/" target="_blank" rel="noopener noreferrer">
<img
alt="MO"
className="img-fluid rounded mr-3 shadow-sm"
src={mo_flag}
width="80"
/>
</a>
<a href="https://www.mo.gov/government/legislative-branch/" target="_blank" rel="noopener noreferrer">
<img
alt="MO"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help MO
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">NE</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Nebraska Legislation &#38; Regulations
</h4>
<p>
Nebraska: Nebraska does not have any state-specific laws or regulations related to cryptocurrency or blockchain technology.
</p>
<div className="mt-3">
<a href="https://nebraskalegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="NE"
className="img-fluid rounded mr-3 shadow-sm"
src={ne_flag}
width="80"
/>
</a>
<a href="https://nebraskalegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="NE"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help NE
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">ND</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
North Dakota" Legislation &#38; Regulations
</h4>
<p>
North Dakota: In 2019, North Dakota passed a law that exempts certain cryptocurrency businesses from the state's money transmitter license requirement if they meet certain criteria.
</p>
<div className="mt-3">
<a href="https://www.legis.nd.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="ND"
className="img-fluid rounded mr-3 shadow-sm"
src={nd_flag}
width="80"
/>
</a>
<a href="https://www.legis.nd.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="ND"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help ND
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">OH</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Ohio Legislation &#38; Regulations
</h4>
<p>
Ohio: In 2019, Ohio passed a law that recognizes blockchain signatures and smart contracts as legally binding electronic records. The state also has a money transmitter license requirement that may apply to cryptocurrency businesses.
</p>
<div className="mt-3">
<a href="https://www.legislature.ohio.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="OH"
className="img-fluid rounded mr-3 shadow-sm"
src={oh_flag}
width="80"
/>
</a>
<a href="https://www.legislature.ohio.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="OH"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help OH
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">SD</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
South Dakota" Legislation &#38; Regulations
</h4>
<p>
South Dakota: South Dakota has not enacted any specific laws or regulations related to cryptocurrency.
</p>
<div className="mt-3">
<a href="http://sdlegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="SD"
className="img-fluid rounded mr-3 shadow-sm"
src={sd_flag}
width="80"
/>
</a>
<a href="http://sdlegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="SD"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help SD
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">WI</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Wisconsin Legislation &#38; Regulations
</h4>
<p>
Wisconsin: Wisconsin has not enacted any specific laws or regulations related to cryptocurrency. However, the state's securities laws may apply to certain digital asset offerings.
</p>
<div className="mt-3">
<a href="http://legis.wisconsin.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="WI"
className="img-fluid rounded mr-3 shadow-sm"
src={wi_flag}
width="80"
/>
</a>
<a href="http://legis.wisconsin.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="WI"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help WI
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">NY</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
New York Legislation &#38; Regulations
</h4>
<p>
New York: BitLicense: New York has a regulation known as the BitLicense, which requires any company engaged in virtual currency business activities to obtain a license from the New York State Department of Financial Services (NYDFS). This includes activities related to the issuance of digital tokens, as well as providing financial services related to virtual currencies.New York State Senate Bill S9033: This bill proposes to amend the New York State Technology Law to include a definition of "blockchain technology" and to provide for the use of blockchain technology in state recordkeeping.
</p>
<div className="mt-3">
<a href="https://nyassembly.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="NY"
className="img-fluid rounded mr-3 shadow-sm"
src={ny_flag}
width="80"
/>
</a>
<a href="https://nyassembly.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="NY"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help NY
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">MA</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Massachusetts Legislation &#38; Regulations
</h4>
<p>
Massachusetts: No specific laws or regulations related to issuing digital tokens or using decentralized finance and non-fungible tokens have been passed in Massachusetts.
</p>
<div className="mt-3">
<a href="https://malegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="MA"
className="img-fluid rounded mr-3 shadow-sm"
src={ma_flag}
width="80"
/>
</a>
<a href="https://malegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="MA"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help MA
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">CT</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Connecticut Legislation &#38; Regulations
</h4>
<p>
Connecticut: Connecticut Senate Bill No. 500: This bill proposes to establish a task force to study the use of blockchain technology in state recordkeeping.
</p>
<div className="mt-3">
<a href="https://www.cga.ct.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="CT"
className="img-fluid rounded mr-3 shadow-sm"
src={ct_flag}
width="80"
/>
</a>
<a href="https://www.cga.ct.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="CT"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help CT
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">RI</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Rhode Island" Legislation &#38; Regulations
</h4>
<p>
Rhode Island: No specific laws or regulations related to issuing digital tokens or using decentralized finance and non-fungible tokens have been passed in Rhode Island.
</p>
<div className="mt-3">
<a href="http://www.rilin.state.ri.us/Pages/Default.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="RI"
className="img-fluid rounded mr-3 shadow-sm"
src={ri_flag}
width="80"
/>
</a>
<a href="http://www.rilin.state.ri.us/Pages/Default.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="RI"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help RI
</Button>
</div>
</div>
</div>
</div>
</Grid>
<Grid item lg={4}>
<div className="timeline-list timeline-list-offset timeline-list-offset-dot mb-5">
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">VT</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Vermont Legislation &#38; Regulations
</h4>
<p>
Vermont: Digital Currency Limited Liability Company Act: Vermont has a law that allows for the creation of digital currency limited liability companies (DLLCs), which can issue digital tokens as part of their business activities. Vermont House Bill H.868: This bill proposes to create a blockchain-based system for state recordkeeping.
</p>
<div className="mt-3">
<a href="https://legislature.vermont.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="VT"
className="img-fluid rounded mr-3 shadow-sm"
src={vt_flag}
width="80"
/>
</a>
<a href="https://legislature.vermont.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="VT"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help VT
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">NH</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
New Hampshire Legislation &#38; Regulations
</h4>
<p>
New Hampshire:No specific laws or regulations related to issuing digital tokens or using decentralized finance and non-fungible tokens have been passed in New Hampshire.
</p>
<div className="mt-3">
<a href="http://www.gencourt.state.nh.us/" target="_blank" rel="noopener noreferrer">
<img
alt="NH"
className="img-fluid rounded mr-3 shadow-sm"
src={nh_flag}
width="80"
/>
</a>
<a href="http://www.gencourt.state.nh.us/" target="_blank" rel="noopener noreferrer">
<img
alt="NH"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help NH
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">ME</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Maine Legislation &#38; Regulations
</h4>
<p>
Maine: No specific laws or regulations related to issuing digital tokens or using decentralized finance and non-fungible tokens have been passed in Maine.
</p>
<div className="mt-3">
<a href="http://legislature.maine.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="ME"
className="img-fluid rounded mr-3 shadow-sm"
src={me_flag}
width="80"
/>
</a>
<a href="http://legislature.maine.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="ME"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help ME
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">GA</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Georgia Legislation &#38; Regulations
</h4>
<p>
Georgia: Georgia has not yet passed any specific legislation related to digital tokens, decentralized finance or non-fungible tokens.
</p>
<div className="mt-3">
<a href="http://www.legis.ga.gov/en-US/default.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="GA"
className="img-fluid rounded mr-3 shadow-sm"
src={ga_flag}
width="80"
/>
</a>
<a href="http://www.legis.ga.gov/en-US/default.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="GA"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help GA
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">FL</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Florida Legislation &#38; Regulations
</h4>
<p>
Florida: Florida passed the Florida Blockchain Bill in 2019, which establishes a blockchain task force to study the use and applicability of blockchain technology. However, there is no specific legislation related to digital tokens, decentralized finance or non-fungible tokens in Florida.
</p>
<div className="mt-3">
<a href="http://www.leg.state.fl.us/Welcome/index.cfm?CFID=156970768&amp;CFTOKEN=65926224" target="_blank" rel="noopener noreferrer">
<img
alt="FL"
className="img-fluid rounded mr-3 shadow-sm"
src={fl_flag}
width="80"
/>
</a>
<a href="http://www.leg.state.fl.us/Welcome/index.cfm?CFID=156970768&amp;CFTOKEN=65926224" target="_blank" rel="noopener noreferrer">
<img
alt="FL"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help FL
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">VA</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Virginia Legislation &#38; Regulations
</h4>
<p>
Virginia: Virginia passed the Digital Identity Study Joint Resolution in 2020, which established a joint subcommittee to study the use of digital identity verification systems, including blockchain-based systems. However, there is no specific legislation related to digital tokens, decentralized finance or non-fungible tokens in Virginia.
</p>
<div className="mt-3">
<a href="https://virginiageneralassembly.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="VA"
className="img-fluid rounded mr-3 shadow-sm"
src={va_flag}
width="80"
/>
</a>
<a href="https://virginiageneralassembly.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="VA"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help VA
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">NC</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
North Carolin Legislation &#38; Regulations
</h4>
<p>
North Carolina: North Carolina has not yet passed any specific legislation related to digital tokens, decentralized finance or non-fungible tokens.
</p>
<div className="mt-3">
<a href="https://www.ncleg.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="NC"
className="img-fluid rounded mr-3 shadow-sm"
src={nc_flag}
width="80"
/>
</a>
<a href="https://www.ncleg.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="NC"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help NC
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">LA</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Louisiana Legislation &#38; Regulations
</h4>
<p>
Louisiana: Louisiana has not yet passed any specific legislation related to digital tokens, decentralized finance or non-fungible tokens.
</p>
<div className="mt-3">
<a href="http://www.legis.la.gov/legis/home.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="LA"
className="img-fluid rounded mr-3 shadow-sm"
src={la_flag}
width="80"
/>
</a>
<a href="http://www.legis.la.gov/legis/home.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="LA"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help LA
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">MS</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Mississippi Legislation &#38; Regulations
</h4>
<p>
Mississippi: Mississippi has not yet passed any specific legislation related to digital tokens, decentralized finance or non-fungible tokens.
</p>
<div className="mt-3">
<a href="http://www.legislature.ms.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="MS"
className="img-fluid rounded mr-3 shadow-sm"
src={ms_flag}
width="80"
/>
</a>
<a href="http://www.legislature.ms.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="MS"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help MS
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">AL</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Alabama Legislation &#38; Regulations
</h4>
<p>
Alabama: Alabama has not yet passed any specific legislation related to digital tokens, decentralized finance or non-fungible tokens.
</p>
<div className="mt-3">
<a href="http://www.legislature.state.al.us/aliswww/default.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="AL"
className="img-fluid rounded mr-3 shadow-sm"
src={al_flag}
width="80"
/>
</a>
<a href="http://www.legislature.state.al.us/aliswww/default.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="AL"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help AL
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">AR</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Arkansas Legislation &#38; Regulations
</h4>
<p>
Arkansas: Arkansas has not yet passed any specific legislation related to digital tokens, decentralized finance or non-fungible tokens.
</p>
<div className="mt-3">
<a href="http://www.arkleg.state.ar.us/assembly/2019/2019R/Pages/Home.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="AR"
className="img-fluid rounded mr-3 shadow-sm"
src={ar_flag}
width="80"
/>
</a>
<a href="http://www.arkleg.state.ar.us/assembly/2019/2019R/Pages/Home.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="AR"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help AR
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">AK</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Alaska Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="http://w3.legis.state.ak.us/index.php" target="_blank" rel="noopener noreferrer">
<img
alt="AK"
className="img-fluid rounded mr-3 shadow-sm"
src={ak_flag}
width="80"
/>
</a>
<a href="http://w3.legis.state.ak.us/index.php" target="_blank" rel="noopener noreferrer">
<img
alt="AK"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help AK
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">DE</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Delaware Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="http://legis.delaware.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="DE"
className="img-fluid rounded mr-3 shadow-sm"
src={de_flag}
width="80"
/>
</a>
<a href="http://legis.delaware.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="DE"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help DE
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">KY</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Kentucky Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="https://legislature.ky.gov/Pages/index.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="KY"
className="img-fluid rounded mr-3 shadow-sm"
src={ky_flag}
width="80"
/>
</a>
<a href="https://legislature.ky.gov/Pages/index.aspx" target="_blank" rel="noopener noreferrer">
<img
alt="KY"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help KY
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">MD</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Maryland Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="http://mgaleg.maryland.gov/webmga/frm1st.aspx?tab=home" target="_blank" rel="noopener noreferrer">
<img
alt="MD"
className="img-fluid rounded mr-3 shadow-sm"
src={md_flag}
width="80"
/>
</a>
<a href="http://mgaleg.maryland.gov/webmga/frm1st.aspx?tab=home" target="_blank" rel="noopener noreferrer">
<img
alt="MD"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help MD
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">MT</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Montana Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="https://leg.mt.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="MT"
className="img-fluid rounded mr-3 shadow-sm"
src={mt_flag}
width="80"
/>
</a>
<a href="https://leg.mt.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="MT"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help MT
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">NJ</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
New Jersey Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="https://www.njleg.state.nj.us/" target="_blank" rel="noopener noreferrer">
<img
alt="NJ"
className="img-fluid rounded mr-3 shadow-sm"
src={nj_flag}
width="80"
/>
</a>
<a href="https://www.njleg.state.nj.us/" target="_blank" rel="noopener noreferrer">
<img
alt="NJ"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help NJ
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">PA</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Pennsylvania Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="https://www.legis.state.pa.us/" target="_blank" rel="noopener noreferrer">
<img
alt="PA"
className="img-fluid rounded mr-3 shadow-sm"
src={pa_flag}
width="80"
/>
</a>
<a href="https://www.legis.state.pa.us/" target="_blank" rel="noopener noreferrer">
<img
alt="PA"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help PA
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">SC</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
South Carolina Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="https://www.scstatehouse.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="SC"
className="img-fluid rounded mr-3 shadow-sm"
src={sc_flag}
width="80"
/>
</a>
<a href="https://www.scstatehouse.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="SC"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help SC
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">TN</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Tennessee Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="http://www.legislature.state.tn.us/" target="_blank" rel="noopener noreferrer">
<img
alt="TN"
className="img-fluid rounded mr-3 shadow-sm"
src={tn_flag}
width="80"
/>
</a>
<a href="http://www.legislature.state.tn.us/" target="_blank" rel="noopener noreferrer">
<img
alt="TN"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help TN
</Button>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item-offset">WV</div>
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary text-white">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
West Virginia Legislation &#38; Regulations
</h4>
<p>
Regulatory status unknown but may be in effect. Check local legilative resources.
</p>
<div className="mt-3">
<a href="http://www.wvlegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="WV"
className="img-fluid rounded mr-3 shadow-sm"
src={wv_flag}
width="80"
/>
</a>
<a href="http://www.wvlegislature.gov/" target="_blank" rel="noopener noreferrer">
<img
alt="WV"
className="shadow-sm"
src={smartlawlogo}
width="80"
/>
</a>
</div>
<div className="mt-3">
<Button size="small" className="btn-primary">
Help WV
</Button>
</div>
</div>
</div>
</div>
</Grid>
</Grid>

    </>
  );
}
