import React from 'react';

import { PageTitle } from 'layout-components';
import { Container, Grid, Button, Tooltip, Link } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ElementsIcons1 from '../../example-components/Autosignup/Icons1';
import ElementsIcons2 from '../../example-components/Autosignup/Icons2';
import ElementsIcons4 from '../../example-components/Autosignup/Icons4';

import Tables2 from '../../example-components/Pmdash/Tables2';

import { ExampleWrapperSeamless } from 'layout-components';


import Iframe from 'react-iframe';

export default function Autosignup() {
  return (
    <>
      <PageTitle
        titleHeading="LexAutomatico Sign-up Form"
        titleDescription="This is the starting point in applying to the automation clinic. Fill out the following form and it will be reviewed by group members."
      />


<Grid container spacing={2}>
        <Grid item xl={4}>
          <div className="bg-night-sky mb-5 rounded">
            <div className="pt-5 pb-4">
              <h4 className="heading-2 heading-2-demo pb-1 text-white">
                LexAutomatico
              </h4>
              <p className="mb-3 px-4">
                LexAutomatico includes .......
              </p>
              <div className="px-5">
                <ElementsIcons1 />
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xl={4}>
          <div className="bg-light-pure-darker mb-5 rounded">
            <div className="pt-5 pb-4">
              <h4 className="heading-2 heading-2-demo pb-1 text-white">
                Web3 Legal Tools
              </h4>
              <p className="mb-3 px-4">Web3LegalTools offers ......</p>
              <div className="px-5">
                <ElementsIcons4 />
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xl={4}>
          <div className="bg-plum-plate mb-5 rounded">
            <div className="pt-5 pb-4">
              <h4 className="heading-2 heading-2-demo pb-1 text-white">
                Web3Skills
              </h4>
              <p className="mb-3 px-4">Web3Skills courses provide ...</p>
              <div className="px-5">
                <ElementsIcons2 />
              </div>
            </div>
          </div>
        </Grid>
        
      </Grid>

      
      <Container>
        <div className="p-1">
          <label className="font-weight-bold mb-2">
            House of Automation Clinic
          </label>
          <h5 className="font-size-xl mb-1 font-weight-bold">
            Profiles and Integrations
          </h5>
          <p className="text-black-50 mb-4">Links to a current databases.</p>
        </div>
        <div className="mb-3"></div>

        <hr />

        <Iframe
          id=""
          src="https://airtable.com/embed/shrbSP1ur01PSMwdd?backgroundColor=cyan"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="4168"
          className=""
          display="block"
          position="relative"
          background="transparent"
          border="1px"
        />

        <hr />

        <Grid>
          <Tooltip arrow title="Github">
            <Button
              className="m-2 d-40 p-0 btn-icon btn-github"
              href="https://airtable.com/appBBhQFafyI3oHIG/pagdr4kQMbBqMveLN?h2OVl=rec27PORA6CPRU9MM"
              rel="noopener nofollow"
              target="_blank">
              <FontAwesomeIcon
                icon={['fab', 'github']}
                className="font-size-xl"
              />
            </Button>
          </Tooltip>

          <Link
            href="https://airtable.com/appBBhQFafyI3oHIG/pagdr4kQMbBqMveLN?h2OVl=rec27PORA6CPRU9MM"
            rel="noopener nofollow"
            target="_blank">
            SmartLaw Decentralized ID
          </Link>
        </Grid>

        <hr />

        <Grid>
          <Tooltip arrow title="Github">
            <Button
              className="m-2 d-40 p-0 btn-icon btn-github"
              href="https://airtable.com/appMWDVEdxPz6SDFU/pag086Ozg9UMuqTcz?MZ9yD=recmvCyVWJTW5SjWd"
              rel="noopener nofollow"
              target="_blank">
              <FontAwesomeIcon
                icon={['fab', 'github']}
                className="font-size-xl"
              />
            </Button>
          </Tooltip>

          <Link
            href="https://airtable.com/appMWDVEdxPz6SDFU/pag086Ozg9UMuqTcz?MZ9yD=recmvCyVWJTW5SjWd"
            rel="noopener nofollow"
            target="_blank">
            Test Clinic Members Intake
          </Link>
        </Grid>

        <hr />

        <Grid>
          <Tooltip arrow title="Github">
            <Button
              className="m-2 d-40 p-0 btn-icon btn-github"
              href="https://airtable.com/appMWDVEdxPz6SDFU/pagWgXY4NHYbQFcO0?W6bsu=recmvCyVWJTW5SjWd"
              rel="noopener nofollow"
              target="_blank">
              <FontAwesomeIcon
                icon={['fab', 'github']}
                className="font-size-xl"
              />
            </Button>
          </Tooltip>

          <Link
            href="https://airtable.com/appMWDVEdxPz6SDFU/pagWgXY4NHYbQFcO0?W6bsu=recmvCyVWJTW5SjWd"
            rel="noopener nofollow"
            target="_blank">
            Clinic Participant Profile
          </Link>
        </Grid>
      </Container>

      <hr />

      <hr />

      <ExampleWrapperSeamless>
          <Tables2 />
        </ExampleWrapperSeamless>




    </>
  );
}
