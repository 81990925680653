import React from 'react';

import { Grid } from '@material-ui/core';

import img0010100w from '../../../assets/images/lady-justice/00-10-100w.png';
export default function LivePreviewExample() {
  return (
    <>
<div className="mb-spacing-6-x2">
<Grid container spacing={6}>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Fraud"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Fraud
</div>
<p className="mb-0 text-black-50">
Digital token issuers can be charged with fraud if they make false or misleading statements about the value or potential for profit of their tokens. Remedies for fraud may include fines, restitution, and potentially even imprisonment.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
Digital token issuers can be charged with fraud if they make false or misleading statements about the value or potential for profit of their tokens. Remedies for fraud may include fines, restitution, and potentially even imprisonment.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Computer fraud and abuse"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Computer fraud and abuse
</div>
<p className="mb-0 text-black-50">
If a digital token issuer engages in unauthorized access to a computer system or uses a computer to commit fraud or other illegal activity, they can be charged with computer fraud and abuse. Remedies can include fines and imprisonment.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
If a digital token issuer engages in unauthorized access to a computer system or uses a computer to commit fraud or other illegal activity, they can be charged with computer fraud and abuse. Remedies can include fines and imprisonment.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Conspiracy to assist in sanctions evasion"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Conspiracy to assist in sanctions evasion
</div>
<p className="mb-0 text-black-50">
If a digital token issuer is found to have conspired to help another party evade economic sanctions, they may face charges of conspiracy to assist in sanctions evasion. Remedies can include fines and imprisonment.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
If a digital token issuer is found to have conspired to help another party evade economic sanctions, they may face charges of conspiracy to assist in sanctions evasion. Remedies can include fines and imprisonment.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Extortion"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Extortion
</div>
<p className="mb-0 text-black-50">
If a digital token issuer uses threats or coercion to obtain money or other valuables from someone, they can be charged with extortion. Remedies can include fines and imprisonment.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
If a digital token issuer uses threats or coercion to obtain money or other valuables from someone, they can be charged with extortion. Remedies can include fines and imprisonment.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="False statements"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
False statements
</div>
<p className="mb-0 text-black-50">
Under 18 USC 1001, it is illegal to make false statements to federal officials or agencies. In the context of digital tokens, this can include providing false information to the Securities and Exchange Commission (SEC) or other regulators about the token's value, purpose, or potential risks.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
Under 18 USC 1001, it is illegal to make false statements to federal officials or agencies. In the context of digital tokens, this can include providing false information to the Securities and Exchange Commission (SEC) or other regulators about the token's value, purpose, or potential risks.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Identity theft"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Identity theft
</div>
<p className="mb-0 text-black-50">
If a digital token issuer uses someone else's identity to engage in fraud or other illegal activity, they can be charged with identity theft. Remedies can include fines, restitution, and imprisonment.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
If a digital token issuer uses someone else's identity to engage in fraud or other illegal activity, they can be charged with identity theft. Remedies can include fines, restitution, and imprisonment.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Money laundering"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Money laundering 18 USC 1956
</div>
<p className="mb-0 text-black-50">
Money laundering is a claim under 18 USC 1956, which prohibits the use of financial transactions to conceal the proceeds of illegal activity. In the context of digital tokens, this can include using the token to hide the source of illegal funds or using the token to transfer funds across borders.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
Money laundering is a claim under 18 USC 1956, which prohibits the use of financial transactions to conceal the proceeds of illegal activity. In the context of digital tokens, this can include using the token to hide the source of illegal funds or using the token to transfer funds across borders.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Obstruction of justice"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Obstruction of justice
</div>
<p className="mb-0 text-black-50">
Obstruction of justice occurs when someone interferes with an investigation or prosecution of a crime. Remedies for obstruction of justice can include fines and imprisonment.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
Obstruction of justice occurs when someone interferes with an investigation or prosecution of a crime. Remedies for obstruction of justice can include fines and imprisonment.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Securities fraud"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Securities fraud
</div>
<p className="mb-0 text-black-50">
This is one of the most common claims brought against digital token issuers. Under 18 USC 1348, securities fraud occurs when a person or company uses deception or misrepresentation to sell or purchase securities. In the context of digital tokens, this can include making false or misleading statements about the token's value or potential for profit.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
This is one of the most common claims brought against digital token issuers. Under 18 USC 1348, securities fraud occurs when a person or company uses deception or misrepresentation to sell or purchase securities. In the context of digital tokens, this can include making false or misleading statements about the token's value or potential for profit.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Tax evasion"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Tax evasion
</div>
<p className="mb-0 text-black-50">
If a digital token issuer fails to pay taxes on their token sales, they can be charged with tax evasion. Remedies for tax evasion can include fines, restitution, and imprisonment.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
If a digital token issuer fails to pay taxes on their token sales, they can be charged with tax evasion. Remedies for tax evasion can include fines, restitution, and imprisonment.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Violations of SEC regulations"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Violations of SEC regulations
</div>
<p className="mb-0 text-black-50">
Finally, digital token issuers can also be subject to claims under 17 CFR, which includes a range of regulations related to securities offerings and trading. These can include violations of registration requirements, failure to comply with disclosure rules, or engaging in insider trading.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
Finally, digital token issuers can also be subject to claims under 17 CFR, which includes a range of regulations related to securities offerings and trading. These can include violations of registration requirements, failure to comply with disclosure rules, or engaging in insider trading.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="Wire fraud"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
Wire fraud
</div>
<p className="mb-0 text-black-50">
Wire fraud is another common claim under 18 USC 1343, which prohibits the use of wire communication to commit fraud. In the context of digital tokens, this can include using email or social media to deceive investors or misrepresent the value of the token.
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
Wire fraud is another common claim under 18 USC 1343, which prohibits the use of wire communication to commit fraud. In the context of digital tokens, this can include using email or social media to deceive investors or misrepresent the value of the token.
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="RESERVED"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
RESERVED
</div>
<p className="mb-0 text-black-50">
RESERVED LG
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
RESERVED MD
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="RESERVED"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
RESERVED
</div>
<p className="mb-0 text-black-50">
RESERVED LG
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
RESERVED MD
</div>
</div>
</a>
</Grid>
<Grid item md={6} xl={3}>
<a
href="https://tokens.freeweb3.com/"
target="_blank"
rel="noopener noreferrer"
className="d-block card card-box-hover-rise text-left hover-scale-sm card-box">
<img
src={img0010100w}
style={{ height: 110 }}
alt="RESERVED"
className="mt-3"
/>
<div className="px-4 pt-2 pb-4">
<div className="font-size-lg font-weight-bold mb-2">
RESERVED
</div>
<p className="mb-0 text-black-50">
RESERVED LG
</p>
<br/>
<div className="font-size-xs mb-0 text-black-50">
RESERVED MD
</div>
</div>
</a>
</Grid>
</Grid>
</div>



    </>
  );
}
