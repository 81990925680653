import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';

import smartlawlogo from '../../../assets/images/smartlaw-drkblue-100x.png';

export default function LivePreviewExample() {
  return (
    <>
      <Grid container spacing={6}>
        <div className="font-weight-bold text-primary display-4 mt-4 ml-5 mb-1">
          Node & Blockchain Strategy for the Legal Professionals
        </div>

        <div className="font-weight-bold text-primary display-5 mt-4 ml-5 mb-1">
          20 Reasons why law firm need a dedicated node-based content storage
          strategy using systems like IPFS, public blockchains and Web3
          technologies.
        </div>

        <Grid item lg={4}>
          <div className="timeline-list timeline-list-offset mb-5">
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">1</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Decentralization
                </h4>
                <p>
                  A node-based content storage strategy using IPFS, public
                  blockchains, and Web3 technologies provides a decentralized
                  approach to content storage, ensuring that the content is not
                  controlled by a single entity.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">2</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Immutable Records
                </h4>
                <p>
                  Using public blockchains and IPFS provides an immutable record
                  of all transactions, ensuring that the content is tamper-proof
                  and easily verifiable.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">3</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Smart Contracts
                </h4>
                <p>
                  By using public blockchains, law firms can leverage smart
                  contracts to automate various aspects of legal work, such as
                  contract drafting and management.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">4</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Client Trust
                </h4>
                <p>
                  By implementing a blockchain strategy, law firms can build
                  trust with clients by providing a secure and transparent
                  platform for legal transactions.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">5</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Increased Security
                </h4>
                <p>
                  A node-based content storage strategy provides enhanced
                  security and protection against cyber threats, as it is much
                  more difficult to hack a decentralized network than a
                  centralized one. Blockchain technology provides a higher level
                  of security than traditional systems, which makes it ideal for
                  law firms that deal with sensitive and confidential data.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">6</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Increased Transparency
                </h4>
                <p>
                  Using public blockchains and IPFS provides increased
                  transparency, as all transactions are publicly visible and
                  auditable by providing a decentralized ledger that is
                  accessible to all parties involved in a transaction.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className="timeline-list timeline-list-offset timeline-list-offset-dot mb-5">
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">7</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Improved Content Management Efficiency
                </h4>
                <p>
                  By using IPFS and public blockchains, law firms can benefit
                  from streamline many of the time-consuming processes
                  associated with legal work such as faster and more efficient
                  content storage, retrieval, and sharing.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">8</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Service Cost Reduction
                </h4>
                <p>
                  A node-based content storage strategy using IPFS, public
                  blockchains, and Web3 technologies provides a decentralized
                  approach to content storage, ensuring that the content is not
                  controlled by a single entity.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">9</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Data Storage Cost Reduction
                </h4>
                <p>
                  Using public blockchains and IPFS provides an immutable record
                  of all transactions, ensuring that the content is tamper-proof
                  and easily verifiable.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">10</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Document Drafting Efficiency
                </h4>
                <p>
                  By using public blockchains, law firms can leverage smart
                  contracts to automate various aspects of legal work, such as
                  contract drafting and management.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">11</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Enhanced Accessibility
                </h4>
                <p>
                  By implementing a blockchain strategy, law firms can build
                  trust with clients by providing a secure and transparent
                  platform for legal transactions.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">12</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Enhanced Collaboration
                </h4>
                <p>
                  A node-based content storage strategy provides enhanced
                  security and protection against cyber threats, as it is much
                  more difficult to hack a decentralized network than a
                  centralized one. Blockchain technology provides a higher level
                  of security than traditional systems, which makes it ideal for
                  law firms that deal with sensitive and confidential data.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className="timeline-list timeline-list-offset timeline-list-offset-dot mb-5">
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">13</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Greater Resilience and Reduced Risk
                </h4>
                <p>
                  Using public blockchains and IPFS provides increased
                  transparency, as all transactions are publicly visible and
                  auditable by providing a decentralized ledger that is
                  accessible to all parties involved in a transaction.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">14</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Compliance with Regulations(N)
                </h4>
                <p>
                  By using IPFS and public blockchains, law firms can benefit
                  from streamline many of the time-consuming processes
                  associated with legal work such as faster and more efficient
                  content storage, retrieval, and sharing.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">15</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Greater Control
                </h4>
                <p>
                  Decentralized content storage solutions enable greater control
                  over content, ensuring that only authorized parties can access
                  it.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">16</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Eliminates Middlemen
                </h4>
                <p>
                  Using public blockchains and IPFS can eliminate the need for
                  intermediaries, such as brokers and agents, reducing costs and
                  streamlining legal transactions.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">17</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Improved Record Keeping
                </h4>
                <p>
                  Public blockchains provide an auditable and tamper-proof
                  record of all transactions, which can help law firms with
                  record-keeping and compliance.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">18</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  International Transactions
                </h4>
                <p>
                  Blockchain technology allows for international transactions to
                  occur in a faster, more secure, and more cost-effective manner
                  than traditional methods.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">19</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Innovation
                </h4>
                <p>
                  By adopting cutting-edge technologies like IPFS and public
                  blockchains, law firms can stay ahead of the curve and
                  differentiate themselves from competitors.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
            <div className="timeline-item timeline-item-icon">
              <div className="timeline-item-offset">20</div>
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                  Future-proofing(N)
                </h4>
                <p>
                  Adopting a node-based content storage strategy using IPFS,
                  public blockchains, and Web3 technologies can future-proof law
                  firms by ensuring they are well-equipped to handle the
                  challenges of an increasingly digital world.
                </p>
                <div className="mt-3">
                  <a
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      alt="Nodes"
                      className="shadow-sm"
                      src={smartlawlogo}
                      width="80"
                    />
                  </a>
                </div>
                <div className="mt-3"></div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
