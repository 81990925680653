import React from 'react';

import { PageTitle } from 'layout-components';
import { Grid } from '@material-ui/core';
import { ExampleWrapperSeamless } from 'layout-components';

import BlocksChartsLarge3 from '../../example-components/Stats/BlocksChartsLarge3';
import BlocksChartsLarge6 from '../../example-components/Stats/BlocksChartsLarge6';
import BlocksChartsLarge8 from '../../example-components/Stats/BlocksChartsLarge8';
export default function Stats() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills SmartLaw Statistics and Analytics"
        titleDescription="The tiiles below are feature-rich representations of decentralized organization management. They query the blockchain and present the information in a easily-digestible way. Any information collected on this site is held in a 
        secure database under the terms of use and privacy policy. https://www.airtable.com/security"
      />

      <Grid container spacing={6}>
        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksChartsLarge3 />
          </ExampleWrapperSeamless>
        </Grid>
        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksChartsLarge6 />
          </ExampleWrapperSeamless>
          <ExampleWrapperSeamless>
            <BlocksChartsLarge8 />
          </ExampleWrapperSeamless>
        </Grid>
      </Grid>
    </>
  );
}
