import React from 'react';

import {
  Grid,
  Container,
  Card,
  CardContent,
  Button
} from '@material-ui/core';

export default function LivePreviewExample() {
  return (
    <>
      <div className="bg-night-sky">
        <Container className="py-3 py-xl-5">
          <div className="d-block d-xl-flex mb-5 justify-content-between">
            <div>
              <h1 className="display-3 text-white mb-2 font-weight-bold">
              Metaverse Pro Plans &#38; Pricing
              </h1>
              <p className="font-size-lg text-white">
              Review and select the plan that works best for an introduction into the metaverse.
              </p>
            </div>
          </div>
          <div className="divider" />
          <Grid container spacing={6} className="d-flex align-items-center">
            <Grid item xl={4}>
              <Card className="mb-5 card-box-hover-rise card-box-hover">
                <CardContent className="px-4 pb-4 pt-3 text-center">
                  <h3 className="display-4 my-3 font-weight-bold text-dark">
                  Starter Jam
                  </h3>
                  <span className="display-2 font-weight-bold">
                    <small className="font-size-lg"></small>
                    475
                  </span>
                  <p className="text-black-50 mb-0">
                  MATIC
                  </p>
                  <Button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    size="large"
                    fullWidth
                    className="btn-primary font-weight-bold text-uppercase my-4">
                    Buy now
                  </Button>
                  <ul className="list-unstyled text-left mb-3 font-weight-bold font-size-sm">
                    <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 1
                    </li>
                    <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 2
                    </li>
                    <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 3
                    </li>
                    <li className="px-4 py-2 text-black-50">
                      <div className="badge badge-primary badge-circle-inner mr-2">
                        Danger
                      </div>
                      Reward 1 
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xl={4}>
              <Card className="card-box shadow-xxl border-3 border-primary mb-5">
                <CardContent className="px-5 pb-5 pt-4 text-center">
                  <h3 className="my-3 font-weight-bold text-dark">
                  Starter Jam + Lease
                  </h3>
                  <span className="display-2 font-weight-bold">
                    2,500
                  </span>
                  <p className="text-black-50 mb-0">
                  MATIC
                  </p>
                  <Button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    size="large"
                    fullWidth
                    className="btn-primary font-weight-bold text-uppercase my-4">
                    Buy now
                  </Button>
                  <ul className="list-unstyled text-left mb-3 font-weight-bold font-size-sm">
                  <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 1
                    </li>
                    <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 2
                    </li>
                    <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 3
                    </li>
                    <li className="px-4 py-2 text-black-50">
                      <div className="badge badge-primary badge-circle-inner mr-2">
                        Danger
                      </div>
                      Reward 1 
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xl={4}>
              <Card className="mb-5 card-box-hover-rise card-box-hover">
                <CardContent className="px-4 pb-4 pt-3 text-center">
                  <h3 className="display-4 my-3 font-weight-bold text-dark">
                  Web3 Profiles &#38; Achievements
                  </h3>
                  <span className="display-2 font-weight-bold">
                    <small className="font-size-lg"></small>
                    7,600
                  </span>
                  <p className="text-black-50 mb-0">
                    MATIC
                  </p>
                  <Button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    size="large"
                    fullWidth
                    className="btn-primary font-weight-bold text-uppercase my-4">
                    Buy now
                  </Button>
                  <ul className="list-unstyled text-left mb-3 font-weight-bold font-size-sm">
                  <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 1
                    </li>
                    <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 2
                    </li>
                    <li className="px-4 py-2">
                      <div className="badge badge-success badge-circle-inner mr-2">
                        Success
                      </div>
                      Feature 3
                    </li>
                    <li className="px-4 py-2 text-black-50">
                      <div className="badge badge-primary badge-circle-inner mr-2">
                        Danger
                      </div>
                      Reward 1 
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>

          </Grid>

        </Container>
      </div>
    </>
  );
}
