import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Menu, List, ListItem, Button } from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';


import avatar1 from '../../../assets/images/avatars/ethereans-00136-w-hex-88-200x.png';
import avatar2 from '../../../assets/images/avatars/ethereans-00259-r-hex-103-200x.png';
import avatar3 from '../../../assets/images/avatars/ethereans-01011-t-hex-3F3-200x.png';
import avatar4 from '../../../assets/images/avatars/ethereans-01045-w-hex-415-200x.png';


export default function LivePreviewExample() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card className="card-box bg-secondary">
        <div className="card-header-alt d-flex justify-content-between p-4">
          <div>
            <h6 className="font-weight-bold font-size-lg mb-1 text-primary">
              Open Opportunities, Issues and Tasks
            </h6>
          </div>
          <div className="d-flex align-items-center">

            <div className="card-header--actions">
              <div>
                <Button
                  onClick={handleClick}
                  size="small"
                  className="btn-neutral-dark">
                  <span className="btn-wrapper--label"></span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon
                      icon={['fas', 'ellipsis-h']}
                      className="opacity-8 font-size-lg"
                    />
                  </span>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorEl)}
                  classes={{ list: 'p-0' }}
                  onClose={handleClose}>
                  <div className="dropdown-menu-xl">
                    <List
                      component="div"
                      className="nav-pills nav-neutral-dark flex-column px-3">
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"

                      >

                        <span>View all reports</span>
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"

                        selected>
                        <span>View active items</span>
                        <div className="badge badge-pill badge-success ml-auto">
                          23
                        </div>
                      </ListItem>

                      <div className="divider" />

                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"

                      >

                        <div className="nav-link-icon">
                          <FontAwesomeIcon icon={['fas', 'download']} />
                        </div>
                        <span>Download</span>
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"

                      >

                        <div className="nav-link-icon">
                          <FontAwesomeIcon icon={['far', 'file-excel']} />
                        </div>
                        <span>Export to CSV</span>
                      </ListItem>
                    </List>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>




        <div className="px-3 pb-3">
          <div className="scroll-area rounded bg-white shadow-overflow">
            <PerfectScrollbar>








              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="font-weight-bold">
                      <a
                        href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/41"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black">
                        Open opportunity for <span className="font-weight-bold text-first">worldlaw.eth</span> acct: 0x1300....8178
                      </a>
                    </div>
                    <small className="d-flex pt-2 align-items-center">
                      <div className="avatar-icon-wrapper avatar-icon-xs mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar1} />
                        </div>
                      </div>
                      <a
                        href="https://github.com/wl-uiux/smartlaw-dashboard/blob/main/README.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        Opportunity, issue or proposal description here
                      </a>
                      <span className="pl-2 text-black-50">
                        Opened: January 1, 2023
                      </span>
                    </small>
                  </div>
                  <div>
                    <div className="badge badge-success">Completed</div>
                  </div>
                </div>




                <div className="divider my-3" />
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="font-weight-bold">
                      <a
                        href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/1"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black">
                        Open opportunity for <span className="font-weight-bold text-first">taxdao.eth</span> acct: 0xdf01....6b13
                      </a>
                    </div>
                    <small className="d-flex pt-2 align-items-center">
                      <div className="avatar-icon-wrapper avatar-icon-xs mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar2} />
                        </div>
                      </div>
                      <a
                        href="https://github.com/wl-uiux/smartlaw-dashboard/blob/main/README.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        Opportunity, issue or proposal description here
                      </a>
                      <span className="pl-2 text-black-50">
                        Opened: January 1, 2023
                      </span>
                    </small>
                  </div>
                  <div>
                    <div className="badge badge-success">Completed</div>
                  </div>
                </div>

                <div className="divider my-3" />
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="font-weight-bold">
                      <a
                        href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/6"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black">
                        Open opportunity for <span className="font-weight-bold text-first">erichdylus.eth</span> acct: 0xb7f4....8B1D
                      </a>
                    </div>
                    <small className="d-flex pt-2 align-items-center">
                      <div className="avatar-icon-wrapper avatar-icon-xs mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar2} />
                        </div>
                      </div>
                      <a
                        href="https://github.com/wl-uiux/smartlaw-dashboard/blob/main/README.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        Opportunity, issue or proposal description here
                      </a>
                      <span className="pl-2 text-black-50">
                        Opened: January 1, 2023
                      </span>
                    </small>
                  </div>
                  <div>
                    <div className="badge badge-success">Completed</div>
                  </div>
                </div>



                <div className="divider my-3" />
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="font-weight-bold">
                      <a
                        href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/69"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black">
                        Open opportunity for <span className="font-weight-bold text-first">james.lexdao.eth</span> acct: 0xd5B3....6A2E
                      </a>
                    </div>
                    <small className="d-flex pt-2 align-items-center">
                      <div className="avatar-icon-wrapper avatar-icon-xs mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar3} />
                        </div>
                      </div>
                      <a
                        href="https://github.com/wl-uiux/smartlaw-dashboard/blob/main/README.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        Opportunity, issue or proposal description here
                      </a>
                      <span className="pl-2 text-black-50">
                        Opened: January 1, 2023
                      </span>
                    </small>
                  </div>
                  <div>
                    <div className="badge badge-primary">Assigned</div>
                  </div>
                </div>

                <div className="divider my-3" />
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="font-weight-bold">
                      <a
                        href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/2"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black">
                        Open opportunity for <span className="font-weight-bold text-first">jordanteague.eth</span> acct: 0xe3bb....8c4b
                      </a>
                    </div>
                    <small className="d-flex pt-2 align-items-center">
                      <div className="avatar-icon-wrapper avatar-icon-xs mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar4} />
                        </div>
                      </div>
                      <a
                        href="https://github.com/wl-uiux/smartlaw-dashboard/blob/main/README.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        Opportunity, issue or proposal description here
                      </a>
                      <span className="pl-2 text-black-50">
                        Opened: January 1, 2023
                      </span>
                    </small>
                  </div>
                  <div>
                    <div className="badge badge-primary">Assigned</div>
                  </div>
                </div>








                <div className="divider my-3" />
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="font-weight-bold">
                      <a
                        href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/153"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black">
                        Open opportunity for <span className="font-weight-bold text-first">dunsmoor.eth</span> acct: 0xA5F4....d5E0
                      </a>
                    </div>
                    <small className="d-flex pt-2 align-items-center">
                      <div className="avatar-icon-wrapper avatar-icon-xs mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar1} />
                        </div>
                      </div>
                      <a
                        href="https://github.com/wl-uiux/smartlaw-dashboard/blob/main/README.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        Opportunity, issue or proposal description here
                      </a>
                      <span className="pl-2 text-black-50">
                        Opened: January 1, 2023
                      </span>
                    </small>
                  </div>
                  <div>
                    <div className="badge badge-info">Pending</div>
                  </div>
                </div>



                <div className="divider my-3" />

              </div>



            </PerfectScrollbar>
          </div>
        </div>
      </Card>
    </>
  );
}
