import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';
import { ExampleWrapperSimple } from 'layout-components';

import WidgetsCalendars1 from '../../example-components/Calendar/Calendars1';
import WidgetsCalendars2 from '../../example-components/Calendar/Calendars2';
export default function Calendar() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills SmartLaw Event Calendar"
        titleDescription="Browse the days, weeks and months to see what is happening in the metaverse."
      />

      <ExampleWrapperSeamless>
        <WidgetsCalendars1 />
      </ExampleWrapperSeamless>
      <ExampleWrapperSimple>
        <WidgetsCalendars2 />
      </ExampleWrapperSimple>
    </>
  );
}
