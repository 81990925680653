import React, { Component } from 'react';
import {
  InputAdornment,
  Grid,
  Button,
  Container,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';

import { Formik } from 'formik';
import * as Yup from 'yup';
// import NameIcon from '@material-ui/icons/SupervisorAccount';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
const validationSchema = Yup.object({
  name: Yup.string('Enter a name').required('Name is required'),
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required')
});
const Form = (props) => {
  const {
    // values: { name, email },
    values: { name },

    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid
  } = props;
  console.table(props);
  return (
    <>
      <div className="app-content p-0">
        <div className="app-inner-content-layout--main">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content">
              <Grid container spacing={0} className="min-vh-100">
                <Grid item lg={5} xl={6} className="d-flex">
                  <Container className="py-4">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-3">
                        Claimant/Grantee Signature
                      </label>
                    </div>

                    {/* <form noValidate autoComplete="off"> */}
                    <form onSubmit={handleSubmit}>
                      <TextField
                        variant="outlined"
                        className="mb-4"
                        name="name"
                        helperText={touched.name ? errors.name : ''}
                        error={Boolean(errors.name)}
                        label="Web3 Public Key"
                        value={name}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FingerprintIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                      {/* <TextField
          variant="outlined"
          className="mb-4"
          name="email"
          helperText={touched.email ? errors.email : ''}
          error={Boolean(errors.email)}
          label="Email"
          fullWidth
          value={email}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            )
          }}
        /> */}
                      <TextField
                        fullWidth
                        error
                        id="filled-error-helper-text"
                        label="Provide a legal signature or identifier"
                        defaultValue="Signer's Name or Mark"
                        helperText="Enter a legal identifier above."
                        variant="filled"
                      />
                      <div className="text-center mt-2">
                        <Button
                          type="submit"
                          size="large"
                          className="btn-first "
                          disabled={!isValid}>
                          Sign Form
                        </Button>
                      </div>
                    </form>
                  </Container>
                </Grid>

                <Grid item lg={5} xl={6} className="d-flex">
                  <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image opacity-5"
                        style={{
                          backgroundImage: 'url(' + sideImage + ')'
                        }}
                      />
                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                      <div className="bg-composed-wrapper--content text-center p-5">
                        <div className="text-white px-0 px-lg-2 px-xl-4">
                          <h1 className="display-3 mb-4 font-weight-bold text-white">
                            Signing the Grant Application Forms
                          </h1>
                          <p className="font-size-lg mb-0 opacity-8">
                            SmartLaw provides tools for Web3 ecosystem to
                            advance legaltech . 11.
                          </p>
                          <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                          <div>
                            <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                              <span className="btn-wrapper--label">
                                Learn more about Signing the Grant Application
                                Forms
                              </span>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'arrow-right']}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero-footer pb-4">
                      <List
                        component="div"
                        className="nav-pills nav-neutral-secondary d-flex">
                        <Tooltip title="Github" arrow>
                          <ListItem
                            component="a"
                            button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-size-lg text-white-50">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

class LivePreviewExample extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  submit = (data) => {
    console.log(data);
  };
  render() {
    const values = { name: '', email: '', confirmPassword: '', password: '' };
    return (
      <>
        <Formik
          render={(props) => <Form {...props} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={this.submit}
        />
      </>
    );
  }
}
export default LivePreviewExample;
