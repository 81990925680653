import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSimple } from 'layout-components';

import ElementsTimelines6 from '../../example-components/Nav/Timelines6';
import ElementsTimelines8 from '../../example-components/Nav/Timelines8';
export default function Nav() {
  return (
    <>
      <PageTitle
        titleHeading="Skills for Navigating Web3"
        titleDescription="The course available in Web3Skills are presented in order of the Supervisors preferences."
      />
      <ExampleWrapperSimple>
        <ElementsTimelines6 />
      </ExampleWrapperSimple>
      <ExampleWrapperSimple>
        <ElementsTimelines8 />
      </ExampleWrapperSimple>
    </>
  );
}
