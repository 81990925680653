import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';
import { Grid } from '@material-ui/core';


import MarketingPricingTables3 from '../../example-components/Metaverse/MarketingPricingTables3';
import MarketingPricingTables4 from '../../example-components/Metaverse/MarketingPricingTables4';
import MarketingPricingTables6 from '../../example-components/Metaverse/MarketingPricingTables6';
import MarketingHero222 from '../../example-components/About/MarketingHero222';



import BlocksListsSmall6 from '../../example-components/Members/BlocksListsSmall6';
import BlocksListsSmall7 from '../../example-components/Members/BlocksListsSmall7';

import Overview3 from '../../example-components/Overview/Overview3';



export default function Metaverse() {
  return (
    <>
      <PageTitle
        titleHeading="Navigating the Web3 Metaverse"
        titleDescription="Get started in the web3 open metaverse by starting slow and working your way into metaverse profiles, assets and achievements."
      />


<ExampleWrapperSeamless>
        <MarketingHero222 />
      </ExampleWrapperSeamless>

<hr/>

      <ExampleWrapperSeamless>
        <Overview3 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <MarketingPricingTables6 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <MarketingPricingTables4 />
      </ExampleWrapperSeamless>
      
      <ExampleWrapperSeamless>
        <MarketingPricingTables3 />
      </ExampleWrapperSeamless>


      <Grid container spacing={6}>
      <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall6 />
          </ExampleWrapperSeamless>
        </Grid>

        <Grid item xl={6}>
          <ExampleWrapperSeamless>
            <BlocksListsSmall7 />
          </ExampleWrapperSeamless>
        </Grid>
    </Grid>

    </>
  );
}
