import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';

import BlocksSparklinesSmall4 from '../../example-components/Opstats/BlocksSparklinesSmall4';
import BlocksSparklinesSmall7 from '../../example-components/Opstats/BlocksSparklinesSmall7';
export default function Opstats() {
  return (
    <>
      <PageTitle
        titleHeading="Snapshot of Token Operations"
        titleDescription="Web3Skills SmartLaw operates with ERC standad tokens. ERC-20 standard for paying for services, ERC-721 for assets, and ERC-1155 for Achievements and Access Tokens"
      />

      <ExampleWrapperSeamless>
        <BlocksSparklinesSmall4 />
      </ExampleWrapperSeamless>
      <ExampleWrapperSeamless>
        <BlocksSparklinesSmall7 />
      </ExampleWrapperSeamless>
    </>
  );
}
