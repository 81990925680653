import React from 'react';
import {
  Grid,
  Button,
  Container,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Iframe from 'react-iframe';

const topAchievments = [
  { title: 'Newb', level: 'Level 1', year: 2020 },
  { title: 'Newb', level: 'Level 2', year: 2020 },
  { title: 'Newb', level: 'Level 3', year: 2020 },
  { title: 'Newb', level: 'Level 4', year: 2020 },
  { title: 'Newb', level: 'Level 5', year: 2020 },
  { title: 'Newb', level: 'Level 6', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 1', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 2', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 3', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 4', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 5', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 6', year: 2020 },
  { title: 'Ethereum', level: 'Level 1', year: 2020 },
  { title: 'Ethereum', level: 'Level 2', year: 2020 },
  { title: 'Ethereum', level: 'Level 3', year: 2020 },
  { title: 'Ethereum', level: 'Level 4', year: 2020 },
  { title: 'Ethereum', level: 'Level 5', year: 2020 },
  { title: 'Ethereum', level: 'Level 6', year: 2020 },
  { title: 'Nodist', level: 'Level 1', year: 2020 },
  { title: 'Nodist', level: 'Level 2', year: 2020 },
  { title: 'Nodist', level: 'Level 3', year: 2020 },
  { title: 'Nodist', level: 'Level 4', year: 2020 },
  { title: 'Nodist', level: 'Level 5', year: 2020 },
  { title: 'Nodist', level: 'Level 6', year: 2020 },
  { title: 'Tidbits', level: 'Level 1', year: 2020 },
  { title: 'Tidbits', level: 'Level 2', year: 2020 },
  { title: 'Tidbits', level: 'Level 3', year: 2020 },
  { title: 'Tidbits', level: 'Level 4', year: 2020 },
  { title: 'Tidbits', level: 'Level 5', year: 2020 },
  { title: 'Tidbits', level: 'Level 6', year: 2020 },
  { title: 'Wardens', level: 'Level 1', year: 2020 },
  { title: 'Wardens', level: 'Level 2', year: 2020 },
  { title: 'Wardens', level: 'Level 3', year: 2020 },
  { title: 'Wardens', level: 'Level 4', year: 2020 },
  { title: 'Wardens', level: 'Level 5', year: 2020 },
  { title: 'Wardens', level: 'Level 6', year: 2020 },
  { title: 'Judiciary', level: 'Level 1', year: 2020 },
  { title: 'Judiciary', level: 'Level 2', year: 2020 },
  { title: 'Judiciary', level: 'Level 3', year: 2020 },
  { title: 'Judiciary', level: 'Level 4', year: 2020 },
  { title: 'Judiciary', level: 'Level 5', year: 2020 },
  { title: 'Judiciary', level: 'Level 6', year: 2020 },
  { title: 'Space Traveler', level: 'Level 1', year: 2020 },
  { title: 'Space Traveler', level: 'Level 2', year: 2020 },
  { title: 'Space Traveler', level: 'Level 3', year: 2020 },
  { title: 'Space Traveler', level: 'Level 4', year: 2020 },
  { title: 'Space Traveler', level: 'Level 5', year: 2020 },
  { title: 'Space Traveler', level: 'Level 6', year: 2020 },
  { title: 'Miner', level: 'Level 1', year: 2020 },
  { title: 'Miner', level: 'Level 2', year: 2020 },
  { title: 'Miner', level: 'Level 3', year: 2020 },
  { title: 'Miner', level: 'Level 4', year: 2020 },
  { title: 'Miner', level: 'Level 5', year: 2020 },
  { title: 'Miner', level: 'Level 6', year: 2020 },
  { title: 'Architect', level: 'Level 1', year: 2020 },
  { title: 'Architect', level: 'Level 2', year: 2020 },
  { title: 'Architect', level: 'Level 3', year: 2020 },
  { title: 'Architect', level: 'Level 4', year: 2020 },
  { title: 'Architect', level: 'Level 5', year: 2020 },
  { title: 'Architect', level: 'Level 6', year: 2020 },
  { title: 'Beaconer', level: 'Level 1', year: 2020 },
  { title: 'Beaconer', level: 'Level 2', year: 2020 },
  { title: 'Beaconer', level: 'Level 3', year: 2020 },
  { title: 'Beaconer', level: 'Level 4', year: 2020 },
  { title: 'Beaconer', level: 'Level 5', year: 2020 },
  { title: 'Beaconer', level: 'Level 6', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 1', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 2', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 3', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 4', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 5', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 6', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 1', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 2', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 3', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 4', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 5', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 6', year: 2020 },
  { title: 'Future Self', level: 'Level 1', year: 2020 },
  { title: 'Future Self', level: 'Level 2', year: 2020 },
  { title: 'Future Self', level: 'Level 3', year: 2020 },
  { title: 'Future Self', level: 'Level 4', year: 2020 },
  { title: 'Future Self', level: 'Level 5', year: 2020 },
  { title: 'Future Self', level: 'Level 6', year: 2020 },
  { title: 'Mechanic', level: 'Level 1', year: 2020 },
  { title: 'Mechanic', level: 'Level 2', year: 2020 },
  { title: 'Mechanic', level: 'Level 3', year: 2020 },
  { title: 'Mechanic', level: 'Level 4', year: 2020 },
  { title: 'Mechanic', level: 'Level 5', year: 2020 },
  { title: 'Mechanic', level: 'Level 6', year: 2020 },
  { title: 'Renewer', level: 'Level 1', year: 2020 },
  { title: 'Renewer', level: 'Level 2', year: 2020 },
  { title: 'Renewer', level: 'Level 3', year: 2020 },
  { title: 'Renewer', level: 'Level 4', year: 2020 },
  { title: 'Renewer', level: 'Level 5', year: 2020 },
  { title: 'Renewer', level: 'Level 6', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 1', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 2', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 3', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 4', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 5', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 6', year: 2020 },
  { title: 'Who Cdc', level: 'Level 1', year: 2020 },
  { title: 'Who Cdc', level: 'Level 2', year: 2020 },
  { title: 'Who Cdc', level: 'Level 3', year: 2020 },
  { title: 'Who Cdc', level: 'Level 4', year: 2020 },
  { title: 'Who Cdc', level: 'Level 5', year: 2020 },
  { title: 'Who Cdc', level: 'Level 6', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 1', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 2', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 3', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 4', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 5', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 6', year: 2020 },
  { title: 'Bug Killer', level: 'Level 1', year: 2020 },
  { title: 'Bug Killer', level: 'Level 2', year: 2020 },
  { title: 'Bug Killer', level: 'Level 3', year: 2020 },
  { title: 'Bug Killer', level: 'Level 4', year: 2020 },
  { title: 'Bug Killer', level: 'Level 5', year: 2020 },
  { title: 'Bug Killer', level: 'Level 6', year: 2020 },
  { title: 'On Target', level: 'Level 1', year: 2020 },
  { title: 'On Target', level: 'Level 2', year: 2020 },
  { title: 'On Target', level: 'Level 3', year: 2020 },
  { title: 'On Target', level: 'Level 4', year: 2020 },
  { title: 'On Target', level: 'Level 5', year: 2020 },
  { title: 'On Target', level: 'Level 6', year: 2020 },
  { title: 'Account Maker', level: 'Level 1', year: 2020 },
  { title: 'Account Maker', level: 'Level 2', year: 2020 },
  { title: 'Account Maker', level: 'Level 3', year: 2020 },
  { title: 'Account Maker', level: 'Level 4', year: 2020 },
  { title: 'Account Maker', level: 'Level 5', year: 2020 },
  { title: 'Account Maker', level: 'Level 6', year: 2020 },
  { title: 'Announcer', level: 'Level 1', year: 2020 },
  { title: 'Announcer', level: 'Level 2', year: 2020 },
  { title: 'Announcer', level: 'Level 3', year: 2020 },
  { title: 'Announcer', level: 'Level 4', year: 2020 },
  { title: 'Announcer', level: 'Level 5', year: 2020 },
  { title: 'Announcer', level: 'Level 6', year: 2020 },
  { title: 'Big Brain', level: 'Level 1', year: 2020 },
  { title: 'Big Brain', level: 'Level 2', year: 2020 },
  { title: 'Big Brain', level: 'Level 3', year: 2020 },
  { title: 'Big Brain', level: 'Level 4', year: 2020 },
  { title: 'Big Brain', level: 'Level 5', year: 2020 },
  { title: 'Big Brain', level: 'Level 6', year: 2020 },
  { title: 'Big Show', level: 'Level 1', year: 2020 },
  { title: 'Big Show', level: 'Level 2', year: 2020 },
  { title: 'Big Show', level: 'Level 3', year: 2020 },
  { title: 'Big Show', level: 'Level 4', year: 2020 },
  { title: 'Big Show', level: 'Level 5', year: 2020 },
  { title: 'Big Show', level: 'Level 6', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 1', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 2', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 3', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 4', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 5', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 6', year: 2020 },
  { title: 'Brickler', level: 'Level 1', year: 2020 },
  { title: 'Brickler', level: 'Level 2', year: 2020 },
  { title: 'Brickler', level: 'Level 3', year: 2020 },
  { title: 'Brickler', level: 'Level 4', year: 2020 },
  { title: 'Brickler', level: 'Level 5', year: 2020 },
  { title: 'Brickler', level: 'Level 6', year: 2020 },
  { title: 'Decentralizer', level: 'Level 1', year: 2020 },
  { title: 'Decentralizer', level: 'Level 2', year: 2020 },
  { title: 'Decentralizer', level: 'Level 3', year: 2020 },
  { title: 'Decentralizer', level: 'Level 4', year: 2020 },
  { title: 'Decentralizer', level: 'Level 5', year: 2020 },
  { title: 'Decentralizer', level: 'Level 6', year: 2020 },
  { title: 'Detector', level: 'Level 1', year: 2020 },
  { title: 'Detector', level: 'Level 2', year: 2020 },
  { title: 'Detector', level: 'Level 3', year: 2020 },
  { title: 'Detector', level: 'Level 4', year: 2020 },
  { title: 'Detector', level: 'Level 5', year: 2020 },
  { title: 'Detector', level: 'Level 6', year: 2020 },
  { title: 'Documenteer', level: 'Level 1', year: 2020 },
  { title: 'Documenteer', level: 'Level 2', year: 2020 },
  { title: 'Documenteer', level: 'Level 3', year: 2020 },
  { title: 'Documenteer', level: 'Level 4', year: 2020 },
  { title: 'Documenteer', level: 'Level 5', year: 2020 },
  { title: 'Documenteer', level: 'Level 6', year: 2020 },
  { title: 'Entertainer', level: 'Level 1', year: 2020 },
  { title: 'Entertainer', level: 'Level 2', year: 2020 },
  { title: 'Entertainer', level: 'Level 3', year: 2020 },
  { title: 'Entertainer', level: 'Level 4', year: 2020 },
  { title: 'Entertainer', level: 'Level 5', year: 2020 },
  { title: 'Entertainer', level: 'Level 6', year: 2020 },
  { title: 'Feast', level: 'Level 1', year: 2020 },
  { title: 'Feast', level: 'Level 2', year: 2020 },
  { title: 'Feast', level: 'Level 3', year: 2020 },
  { title: 'Feast', level: 'Level 4', year: 2020 },
  { title: 'Feast', level: 'Level 5', year: 2020 },
  { title: 'Feast', level: 'Level 6', year: 2020 },
  { title: 'Fire Starter', level: 'Level 1', year: 2020 },
  { title: 'Fire Starter', level: 'Level 2', year: 2020 },
  { title: 'Fire Starter', level: 'Level 3', year: 2020 },
  { title: 'Fire Starter', level: 'Level 4', year: 2020 },
  { title: 'Fire Starter', level: 'Level 5', year: 2020 },
  { title: 'Fire Starter', level: 'Level 6', year: 2020 },
  { title: 'Good Show', level: 'Level 1', year: 2020 },
  { title: 'Good Show', level: 'Level 2', year: 2020 },
  { title: 'Good Show', level: 'Level 3', year: 2020 },
  { title: 'Good Show', level: 'Level 4', year: 2020 },
  { title: 'Good Show', level: 'Level 5', year: 2020 },
  { title: 'Good Show', level: 'Level 6', year: 2020 },
  { title: 'Helping Hand', level: 'Level 1', year: 2020 },
  { title: 'Helping Hand', level: 'Level 2', year: 2020 },
  { title: 'Helping Hand', level: 'Level 3', year: 2020 },
  { title: 'Helping Hand', level: 'Level 4', year: 2020 },
  { title: 'Helping Hand', level: 'Level 5', year: 2020 },
  { title: 'Helping Hand', level: 'Level 6', year: 2020 },
  { title: 'Hunter', level: 'Level 1', year: 2020 },
  { title: 'Hunter', level: 'Level 2', year: 2020 },
  { title: 'Hunter', level: 'Level 3', year: 2020 },
  { title: 'Hunter', level: 'Level 4', year: 2020 },
  { title: 'Hunter', level: 'Level 5', year: 2020 },
  { title: 'Hunter', level: 'Level 6', year: 2020 },
  { title: 'Iam Idea', level: 'Level 1', year: 2020 },
  { title: 'Iam Idea', level: 'Level 2', year: 2020 },
  { title: 'Iam Idea', level: 'Level 3', year: 2020 },
  { title: 'Iam Idea', level: 'Level 4', year: 2020 },
  { title: 'Iam Idea', level: 'Level 5', year: 2020 },
  { title: 'Iam Idea', level: 'Level 6', year: 2020 },
  { title: 'Insurer', level: 'Level 1', year: 2020 },
  { title: 'Insurer', level: 'Level 2', year: 2020 },
  { title: 'Insurer', level: 'Level 3', year: 2020 },
  { title: 'Insurer', level: 'Level 4', year: 2020 },
  { title: 'Insurer', level: 'Level 5', year: 2020 },
  { title: 'Insurer', level: 'Level 6', year: 2020 },
  { title: 'Internationaler', level: 'Level 1', year: 2020 },
  { title: 'Internationaler', level: 'Level 2', year: 2020 },
  { title: 'Internationaler', level: 'Level 3', year: 2020 },
  { title: 'Internationaler', level: 'Level 4', year: 2020 },
  { title: 'Internationaler', level: 'Level 5', year: 2020 },
  { title: 'Internationaler', level: 'Level 6', year: 2020 },
  { title: 'Lawdler', level: 'Level 1', year: 2020 },
  { title: 'Lawdler', level: 'Level 2', year: 2020 },
  { title: 'Lawdler', level: 'Level 3', year: 2020 },
  { title: 'Lawdler', level: 'Level 4', year: 2020 },
  { title: 'Lawdler', level: 'Level 5', year: 2020 },
  { title: 'Lawdler', level: 'Level 6', year: 2020 },
  { title: 'Magician', level: 'Level 1', year: 2020 },
  { title: 'Magician', level: 'Level 2', year: 2020 },
  { title: 'Magician', level: 'Level 3', year: 2020 },
  { title: 'Magician', level: 'Level 4', year: 2020 },
  { title: 'Magician', level: 'Level 5', year: 2020 },
  { title: 'Magician', level: 'Level 6', year: 2020 },
  { title: 'Navigator', level: 'Level 1', year: 2020 },
  { title: 'Navigator', level: 'Level 2', year: 2020 },
  { title: 'Navigator', level: 'Level 3', year: 2020 },
  { title: 'Navigator', level: 'Level 4', year: 2020 },
  { title: 'Navigator', level: 'Level 5', year: 2020 },
  { title: 'Navigator', level: 'Level 6', year: 2020 },
  { title: 'Needle Threader', level: 'Level 1', year: 2020 },
  { title: 'Needle Threader', level: 'Level 2', year: 2020 },
  { title: 'Needle Threader', level: 'Level 3', year: 2020 },
  { title: 'Needle Threader', level: 'Level 4', year: 2020 },
  { title: 'Needle Threader', level: 'Level 5', year: 2020 },
  { title: 'Needle Threader', level: 'Level 6', year: 2020 },
  { title: 'Networker', level: 'Level 1', year: 2020 },
  { title: 'Networker', level: 'Level 2', year: 2020 },
  { title: 'Networker', level: 'Level 3', year: 2020 },
  { title: 'Networker', level: 'Level 4', year: 2020 },
  { title: 'Networker', level: 'Level 5', year: 2020 },
  { title: 'Networker', level: 'Level 6', year: 2020 },
  { title: 'On Fire', level: 'Level 1', year: 2020 },
  { title: 'On Fire', level: 'Level 2', year: 2020 },
  { title: 'On Fire', level: 'Level 3', year: 2020 },
  { title: 'On Fire', level: 'Level 4', year: 2020 },
  { title: 'On Fire', level: 'Level 5', year: 2020 },
  { title: 'On Fire', level: 'Level 6', year: 2020 },
  { title: 'On Timer', level: 'Level 1', year: 2020 },
  { title: 'On Timer', level: 'Level 2', year: 2020 },
  { title: 'On Timer', level: 'Level 3', year: 2020 },
  { title: 'On Timer', level: 'Level 4', year: 2020 },
  { title: 'On Timer', level: 'Level 5', year: 2020 },
  { title: 'On Timer', level: 'Level 6', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 1', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 2', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 3', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 4', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 5', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 6', year: 2020 },
  { title: 'Researcher', level: 'Level 1', year: 2020 },
  { title: 'Researcher', level: 'Level 2', year: 2020 },
  { title: 'Researcher', level: 'Level 3', year: 2020 },
  { title: 'Researcher', level: 'Level 4', year: 2020 },
  { title: 'Researcher', level: 'Level 5', year: 2020 },
  { title: 'Researcher', level: 'Level 6', year: 2020 },
  { title: 'Resolver', level: 'Level 1', year: 2020 },
  { title: 'Resolver', level: 'Level 2', year: 2020 },
  { title: 'Resolver', level: 'Level 3', year: 2020 },
  { title: 'Resolver', level: 'Level 4', year: 2020 },
  { title: 'Resolver', level: 'Level 5', year: 2020 },
  { title: 'Resolver', level: 'Level 6', year: 2020 },
  { title: 'Service Pro', level: 'Level 1', year: 2020 },
  { title: 'Service Pro', level: 'Level 2', year: 2020 },
  { title: 'Service Pro', level: 'Level 3', year: 2020 },
  { title: 'Service Pro', level: 'Level 4', year: 2020 },
  { title: 'Service Pro', level: 'Level 5', year: 2020 },
  { title: 'Service Pro', level: 'Level 6', year: 2020 },
  { title: 'Social Supporter', level: 'Level 1', year: 2020 },
  { title: 'Social Supporter', level: 'Level 2', year: 2020 },
  { title: 'Social Supporter', level: 'Level 3', year: 2020 },
  { title: 'Social Supporter', level: 'Level 4', year: 2020 },
  { title: 'Social Supporter', level: 'Level 5', year: 2020 },
  { title: 'Social Supporter', level: 'Level 6', year: 2020 },
  { title: 'Strategist', level: 'Level 1', year: 2020 },
  { title: 'Strategist', level: 'Level 2', year: 2020 },
  { title: 'Strategist', level: 'Level 3', year: 2020 },
  { title: 'Strategist', level: 'Level 4', year: 2020 },
  { title: 'Strategist', level: 'Level 5', year: 2020 },
  { title: 'Strategist', level: 'Level 6', year: 2020 },
  { title: 'Surprise', level: 'Level 1', year: 2020 },
  { title: 'Surprise', level: 'Level 2', year: 2020 },
  { title: 'Surprise', level: 'Level 3', year: 2020 },
  { title: 'Surprise', level: 'Level 4', year: 2020 },
  { title: 'Surprise', level: 'Level 5', year: 2020 },
  { title: 'Surprise', level: 'Level 6', year: 2020 },
  { title: 'Team Player', level: 'Level 1', year: 2020 },
  { title: 'Team Player', level: 'Level 2', year: 2020 },
  { title: 'Team Player', level: 'Level 3', year: 2020 },
  { title: 'Team Player', level: 'Level 4', year: 2020 },
  { title: 'Team Player', level: 'Level 5', year: 2020 },
  { title: 'Team Player', level: 'Level 6', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 1', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 2', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 3', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 4', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 5', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 6', year: 2020 },
  { title: 'Web3VM User', level: 'Level 1', year: 2020 },
  { title: 'Web3VM User', level: 'Level 2', year: 2020 },
  { title: 'Web3VM User', level: 'Level 3', year: 2020 },
  { title: 'Web3VM User', level: 'Level 4', year: 2020 },
  { title: 'Web3VM User', level: 'Level 5', year: 2020 },
  { title: 'Web3VM User', level: 'Level 6', year: 2020 }
];
export default function LivePreviewExample() {
  return (
    <>
      <div className="app-content p-0">
        <div className="app-inner-content-layout--main">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content">
              <Grid container spacing={0} className="min-vh-100">
                <Grid item lg={7} xl={6} className="d-flex align-items-center">
                  <Grid item md={10} lg={8} xl={7} className="mx-auto">
                    <Iframe
                      id=""
                      src="https://airtable.com/embed/shr1paglo2XBG1b1i?backgroundColor=gray"
                      frameborder="0"
                      onmousewheel=""
                      width="100%"
                      height="600"
                      // height="2915"
                      className=""
                      display="block"
                      position="relative"
                      background="transparent"
                      border="1px"
                    />

                    <h2 className="display-4 mt-3 text-first font-weight-bold">
                      Select a grant category
                    </h2>

                    <div className="p-1">
                      <Container>
                        <Autocomplete
                          id="combo-box-demo"
                          options={topAchievments}
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Grant Categories"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Container>
                    </div>

                    <h1 className="display-4 mt-5 mb-1 font-weight-bold">
                      Project Goals and Objectives
                    </h1>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Abstract a.k.a. Executive Summary, Project
                          Description, Project Summary | 060
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 060 | "
                        name="abstract_summary_formfield_060"
                        type="textarea"
                        helperText="Helper note sits just below the form field 060 | Abstracts are often the most important part of a proposal as it is a reviewer’s first impression of a proposal. Includes a clear and concise one-page description of the project emphasizing the objectives, needs for project, plan for execution of project, and measurable outcomes."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Project Goals | 061
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 061 | "
                        name="project_goals_primary_formfield_061"
                        type="textarea"
                        helperText="Helper note sits just below the form field 061 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Goals | 062
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 062 | "
                        name="project_goals_secondary_community_formfield_062"
                        type="textarea"
                        helperText="Helper note sits just below the form field 062 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Goals and Objectives a.k.a. Expected Outcomes, General
                          Objectives, Specific Outcomes | 063
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 063 | "
                        name="objectives_expected_outcomes_formfield_063"
                        type="textarea"
                        helperText="Helper note sits just below the form field 063 | quantifiable evidence that there will be a high social return on their investment"
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Project Members and Staff | 064
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 064 | Goals are a general statement of what the proposed project will accomplish. Identifies expected outcomes as relate to short-term and long-term goals. Describes measurable outcomes for the proposed project. (Evaluations report the success of an entire project. Objectives report the success of a specific part of a project. Evaluations are often comprised of many objectives that include measurable outcomes.) Normally includes at least three (3) objectives, with at least three (3) activities for each objective. Activities may be duplicated between objectives."
                        name="executive_admin_formfield_064"
                        type="textarea"
                        helperText="Helper note sits just below the form field 064 | Most important is to briefly overview why your values and goals align with the organization's and how you will help them. You should also touch on how you match the position requirements. By reviewing how you align with the organization and how your skills match what they're looking for, you can forecast the contents of your cover letter before you move into your argument."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Describe and Justify the Need | 065
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 065 | "
                        name="describe_justify_the_need_formfield_065"
                        type="textarea"
                        helperText="Helper note sits just below the form field 065 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          State Benefits | 066
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 066 | "
                        name="state_the_benefits_formfield_066"
                        type="textarea"
                        helperText="Helper note sits just below the form field 066 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Provide a Path of Action | 067
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 067 | "
                        name="action_path_formfield_067"
                        type="textarea"
                        helperText="Helper note sits just below the form field 067 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Your target audience | 068
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 068 | "
                        name="target_audience_formfield_068"
                        type="textarea"
                        helperText="Helper note sits just below the form field 068 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          How you will reach them | 069
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 069 | "
                        name="how_you_will_reach_them_formfield_069"
                        type="textarea"
                        helperText="Helper note sits just below the form field 069 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          What you will do to serve them | 070
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 070 | "
                        name="services_to_the_community_formfield_070"
                        type="textarea"
                        helperText="Helper note sits just below the form field 070 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          What makes your organization uniquely positioned | 071
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 071 | "
                        name="unique_organization_position_formfield_071"
                        type="textarea"
                        helperText="Helper note sits just below the form field 071 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          How you plan to implement your project | 072
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 072 | "
                        name="project_implementation_formfield_072"
                        type="textarea"
                        helperText="Helper note sits just below the form field 072 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          What the impact will be | 073
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 073 | "
                        name="intended_impacts_formfield_073"
                        type="textarea"
                        helperText="Helper note sits just below the form field 073 | "
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid item lg={5} xl={6} className="d-flex">
                  <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image opacity-5"
                        style={{
                          backgroundImage: 'url(' + sideImage + ')'
                        }}
                      />
                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                      <div className="bg-composed-wrapper--content text-center p-5">
                        <div className="text-white px-0 px-lg-2 px-xl-4">
                          <h1 className="display-3 mb-4 font-weight-bold text-white">
                          Project Goals and Objectives
                          </h1>
                          <p className="font-size-lg mb-0 opacity-8">
                            SmartLaw provides tools for Web3 ecosystem to
                            advance legaltech. 11.
                          </p>
                          <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                          <div>
                            <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                              <span className="btn-wrapper--label">
                                Learn more about Grant Project Goals and Objectives
                              </span>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'arrow-right']}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero-footer pb-4">
                      <List
                        component="div"
                        className="nav-pills nav-neutral-secondary d-flex">
                        <Tooltip title="Github" arrow>
                          <ListItem
                            component="a"
                            button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-size-lg text-white-50">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
