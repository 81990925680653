import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Box,
  Typography,
  Popover,
  Menu,
  Button,
  List,
  ListItem,
  Divider
} from '@material-ui/core';

import CalendarTodayTwoToneIcon from '@material-ui/icons/CalendarTodayTwoTone';
import CollectionsTwoToneIcon from '@material-ui/icons/CollectionsTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone';



const HeaderMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mega-menu-popover' : undefined;

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <div className="app-header-menu">

      <Button
          component={NavLink}
          to="/About"
          size="small"
          className="btn-transition-none btn-neutral-primary mr-3">
          Sidebar Menu
        </Button>



        <Button
          size="small"
          onClick={handleClick}
          className="btn-transition-none btn-neutral-primary mr-3">
          Site Map
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          <div className="popover-custom-xxl bg-heavy-rain p-0">
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-first p-3">
                  <Typography
                    component="div"
                    className="pb-2 text-capitalize text-first font-size-lg">
                    <span>Web3Skills</span>
                  </Typography>

                  <ListItem
                    button
                    component={NavLink}
                    to="/Catalog">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>Course Catalog</span>
                    <div className="ml-auto badge badge-pill badge-first">
                      2
                    </div>
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/Tokens">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>Course Achievements</span>
                    <div className="ml-auto badge badge-pill badge-first">
                      3
                    </div>
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/Catalog">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>Web3 Legal Courses</span>
                    <div className="ml-auto badge badge-pill badge-first">
                      4
                    </div>
                  </ListItem>
                  <ListItem
                    button
                    href="https://web3skills.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span className="font-weight-bold text-first">Web3Skills.com</span>
                    <div className="ml-auto badge badge-pill badge-first">
                      1
                    </div>
                  </ListItem>
                  <Divider className="mt-3 mb-2" />
                  <ListItem className="px-0 pb-0">
                    <Button 
                    component={NavLink}
                    to="/About"
                    size="small" className="btn-outline-first w-100">
                      <span className="btn-wrapper--label btn-outline">Learn more</span>
                    </Button>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-primary p-3">
                  <Typography className="pb-2 text-capitalize text-primary font-size-lg">
                    <span>SmartLaw</span>
                  </Typography>
                  <ListItem
                    button
                    component={NavLink}
                    to="/Forensics">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>Forensic Image Tool</span>
                    <div className="ml-auto badge badge-pill badge-primary">
                      1
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="https://web3vm.com">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>Encrypt-Upload</span>
                    <div className="ml-auto badge badge-pill badge-primary">
                      2
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="https://evidencer.freeweb3.com">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>Evidence Management</span>
                    <div className="ml-auto badge badge-pill badge-primary">
                      3
                    </div>
                  </ListItem>
                  <ListItem
                    button
                    href="https://tokens.freeweb3.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span className="font-weight-bold text-primary">Token Minter</span>
                    <div className="ml-auto badge badge-pill badge-primary">
                      4
                    </div>
                  </ListItem>

                  <Divider className="mt-3 mb-2" />
                  <ListItem className="px-0 pb-0">
                    <Button 
                                        component={NavLink}
                                        to="/About"
                    size="small" className="btn-outline-primary w-100">
                      <span className="btn-wrapper--label">Learn more</span>
                    </Button>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4}>
                <List component="div" className="nav-neutral-second p-3">
                  <Typography className="pb-2 text-capitalize text-second font-size-lg">
                    <span>Web3 3D Workspaces</span>
                  </Typography>

                  <ListItem
                    button
                    component={NavLink}
                    to="/Metaverse">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>Content Services </span>
                    <div className="ml-auto badge badge-pill badge-second text-white">
                      1
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="https://www.voxels.com/play?coords=SE@262E,1S">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>Application Hub</span>
                    <div className="ml-auto badge badge-pill badge-second text-white">
                      2
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="https://www.voxels.com/play?coords=NW@195E,129S">                    
                      <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span>SmartLaw Clubhouse</span>
                    <div className="ml-auto badge badge-pill badge-second text-white">
                      3
                    </div>
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/FormsDatepicker">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className="font-size-xs opacity-3"
                      />
                    </div>
                    <span className="font-weight-bold text-second">
                      Mock Courtroom
                      </span>
                      <div className="ml-auto badge badge-pill badge-second text-white">
                      4
                    </div>
                  </ListItem>
                  <Divider className="mt-3 mb-2" />
                  <ListItem className="px-0 pb-0">
                    <Button 
                    component={NavLink}
                    to="/About"
                    size="small" className="btn-outline-secondart w-100">
                      <span className="btn-wrapper--label">Learn more</span>
                    </Button>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </div>
        </Popover>

        <Button
          onClick={handleClickMenu}
          size="small"
          className="btn-transition-none btn-neutral-primary mr-3">
          Quick Links
        </Button>
        <Menu
          anchorEl={anchorElMenu}
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
          classes={{ list: 'p-0' }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          <Box className="overflow-hidden border-0 bg-midnight-bloom p-3 dropdown-mega-menu-md">
            <div className="text-center">
              <div className="font-weight-bold font-size-xl mb-1 text-white">
                SmartLaw Quick Links
              </div>
              <p className="text-white-50 mb-3">
                Choose the <b className="text-white">Web3 connected</b> quick link.
              </p>
            </div>
            <div className="d-flex flex-wrap">
              <div className="w-50 p-2">
                <Button
                  href="https://web3skills.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block bg-white p-3 text-primary">
                  <div>
                    <CalendarTodayTwoToneIcon className="h1 d-block my-2 text-primary" />
                    <div className="text-black font-size-md font-weight-bold">
                      Web3Skills Courses
                    </div>
                  </div>
                </Button>
              </div>
              <div className="w-50 p-2">
                <Button
                  href="https://tokens.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block bg-white p-3 text-primary">
                  <div>
                    <CollectionsTwoToneIcon className="h1 d-block my-2 text-danger" />
                    <div className="text-black font-size-md font-weight-bold">
                      SmartLaw Digital Assets
                    </div>
                  </div>
                </Button>
              </div>
              <div className="w-50 p-2">
                <Button
                  href="https://www.voxels.com/play?coords=NE@262E,13S"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block bg-white p-3 text-primary">
                  <div>
                    <DnsTwoToneIcon className="h1 d-block my-2 text-warning" />
                    <div className="text-black font-size-md font-weight-bold">
                      Web3 3D Workspaces 
                    </div>
                  </div>
                </Button>
              </div>
              <div className="w-50 p-2">
                <Button
                  href="https://evidencer.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block bg-white p-3 text-primary">
                  <div>
                    <HomeWorkTwoToneIcon className="h1 d-block my-2 text-first" />
                    <div className="text-black font-size-md font-weight-bold">
                      Evidence & Claim Management
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </Box>
        </Menu>
      </div>
    </>
  );
};

export default HeaderMenu;
