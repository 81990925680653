import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Container,
  Card,
  Button,
  List,
  ListItem
} from '@material-ui/core';

import { NavLink } from 'react-router-dom';

import achieve001 from '../../../assets/images/badges2023/achieve-001-prefinal-200w.png';
// import achieve002 from '../../../assets/images/badges2023/achieve-002-prefinal-200w.png';
import achieve003 from '../../../assets/images/badges2023/achieve-003-prefinal-200w.png';
import achieve004 from '../../../assets/images/badges2023/achieve-004-prefinal-200w.png';
// import achieve005 from '../../../assets/images/badges2023/achieve-005-prefinal-200w.png';
// import achieve006 from '../../../assets/images/badges2023/achieve-006-prefinal-200w.png';
// import achieve007 from '../../../assets/images/badges2023/achieve-007-prefinal-200w.png';
// import achieve008 from '../../../assets/images/badges2023/achieve-008-prefinal-200w.png';
// import achieve009 from '../../../assets/images/badges2023/achieve-009-prefinal-200w.png';
import achieve010 from '../../../assets/images/badges2023/achieve-010-prefinal-200w.png';
// import achieve011 from '../../../assets/images/badges2023/achieve-011-prefinal-200w.png';
// import achieve012 from '../../../assets/images/badges2023/achieve-012-prefinal-200w.png';
// import achieve013 from '../../../assets/images/badges2023/achieve-013-prefinal-200w.png';
import achieve014 from '../../../assets/images/badges2023/achieve-014-prefinal-200w.png';
import achieve015 from '../../../assets/images/badges2023/achieve-015-prefinal-200w.png';
// import achieve016 from '../../../assets/images/badges2023/achieve-016-prefinal-200w.png';
// import achieve017 from '../../../assets/images/badges2023/achieve-017-prefinal-200w.png';
import achieve018 from '../../../assets/images/badges2023/achieve-018-prefinal-200w.png';
import achieve019 from '../../../assets/images/badges2023/achieve-019-prefinal-200w.png';
// import achieve020 from '../../../assets/images/badges2023/achieve-020-prefinal-200w.png';
// import achieve021 from '../../../assets/images/badges2023/achieve-021-prefinal-200w.png';
import achieve022 from '../../../assets/images/badges2023/achieve-022-prefinal-200w.png';
import achieve023 from '../../../assets/images/badges2023/achieve-023-prefinal-200w.png';
// import achieve024 from '../../../assets/images/badges2023/achieve-024-prefinal-200w.png';
// import achieve025 from '../../../assets/images/badges2023/achieve-025-prefinal-200w.png';
// import achieve026 from '../../../assets/images/badges2023/achieve-026-prefinal-200w.png';
// import achieve027 from '../../../assets/images/badges2023/achieve-027-prefinal-200w.png';
// import achieve028 from '../../../assets/images/badges2023/achieve-028-prefinal-200w.png';
// import achieve029 from '../../../assets/images/badges2023/achieve-029-prefinal-200w.png';
// import achieve030 from '../../../assets/images/badges2023/achieve-030-prefinal-200w.png';
// import achieve031 from '../../../assets/images/badges2023/achieve-031-prefinal-200w.png';
// import achieve032 from '../../../assets/images/badges2023/achieve-032-prefinal-200w.png';
// import achieve033 from '../../../assets/images/badges2023/achieve-033-prefinal-200w.png';
// import leg001 from '../../../assets/images/badges2023/leg-001-prefinal-200w.png';
// import leg002 from '../../../assets/images/badges2023/leg-002-prefinal-200w.png';
// import leg003 from '../../../assets/images/badges2023/leg-003-prefinal-200w.png';
// import leg004 from '../../../assets/images/badges2023/leg-004-prefinal-200w.png';
// import leg005 from '../../../assets/images/badges2023/leg-005-prefinal-200w.png';
// import leg006 from '../../../assets/images/badges2023/leg-006-prefinal-200w.png';
// import leg007 from '../../../assets/images/badges2023/leg-007-prefinal-200w.png';
// import leg008 from '../../../assets/images/badges2023/leg-008-prefinal-200w.png';
import leg009 from '../../../assets/images/badges2023/leg-009-prefinal-200w.png';
// import leg010 from '../../../assets/images/badges2023/leg-010-prefinal-200w.png';
import leg011 from '../../../assets/images/badges2023/leg-011-prefinal-200w.png';
import leg012 from '../../../assets/images/badges2023/leg-012-prefinal-200w.png';
// import leg013 from '../../../assets/images/badges2023/leg-013-prefinal-200w.png';
// import leg014 from '../../../assets/images/badges2023/leg-014-prefinal-200w.png';
import leg015 from '../../../assets/images/badges2023/leg-015-prefinal-200w.png';
// import leg016 from '../../../assets/images/badges2023/leg-016-prefinal-200w.png';
// import leg017 from '../../../assets/images/badges2023/leg-017-prefinal-200w.png';
import leg018 from '../../../assets/images/badges2023/leg-018-prefinal-200w.png';
import leg019 from '../../../assets/images/badges2023/leg-019-prefinal-200w.png';
// import leg020 from '../../../assets/images/badges2023/leg-020-prefinal-200w.png';
// import leg021 from '../../../assets/images/badges2023/leg-021-prefinal-200w.png';
// import leg022 from '../../../assets/images/badges2023/leg-022-prefinal-200w.png';
// import leg023 from '../../../assets/images/badges2023/leg-023-prefinal-200w.png';
import leg024 from '../../../assets/images/badges2023/leg-024-prefinal-200w.png';
import leg025 from '../../../assets/images/badges2023/leg-025-prefinal-200w.png';
// import scale001 from '../../../assets/images/badges2023/scale-001-prefinal-200w.png';
// import scale002 from '../../../assets/images/badges2023/scale-002-prefinal-200w.png';
// import scale003 from '../../../assets/images/badges2023/scale-003-prefinal-200w.png';
// import scale004 from '../../../assets/images/badges2023/scale-004-prefinal-200w.png';
// import scale005 from '../../../assets/images/badges2023/scale-005-prefinal-200w.png';
// import scale006 from '../../../assets/images/badges2023/scale-006-prefinal-200w.png';
// import scale007 from '../../../assets/images/badges2023/scale-007-prefinal-200w.png';
import scale008 from '../../../assets/images/badges2023/scale-008-prefinal-200w.png';
import scale009 from '../../../assets/images/badges2023/scale-009-prefinal-200w.png';
import scale010 from '../../../assets/images/badges2023/scale-010-prefinal-200w.png';
// import scale011 from '../../../assets/images/badges2023/scale-011-prefinal-200w.png';
// import scale012 from '../../../assets/images/badges2023/scale-012-prefinal-200w.png';
// import scale013 from '../../../assets/images/badges2023/scale-013-prefinal-200w.png';
// import scale014 from '../../../assets/images/badges2023/scale-014-prefinal-200w.png';
// import tri001 from '../../../assets/images/badges2023/tri-001-prefinal-200w.png';
// import tri002 from '../../../assets/images/badges2023/tri-002-prefinal-200w.png';
// import tri003 from '../../../assets/images/badges2023/tri-003-prefinal-200w.png';
// import tri004 from '../../../assets/images/badges2023/tri-004-prefinal-200w.png';
// import tri005 from '../../../assets/images/badges2023/tri-005-prefinal-200w.png';
// import tri006 from '../../../assets/images/badges2023/tri-006-prefinal-200w.png';
// import tri007 from '../../../assets/images/badges2023/tri-007-prefinal-200w.png';
import tri008 from '../../../assets/images/badges2023/tri-008-prefinal-200w.png';
// import tri009 from '../../../assets/images/badges2023/tri-009-prefinal-200w.png';
// import tri010 from '../../../assets/images/badges2023/tri-010-prefinal-200w.png';
// import tri011 from '../../../assets/images/badges2023/tri-011-prefinal-200w.png';
import tri012 from '../../../assets/images/badges2023/tri-012-prefinal-200w.png';
import tri013 from '../../../assets/images/badges2023/tri-013-prefinal-200w.png';
import tri014 from '../../../assets/images/badges2023/tri-014-prefinal-200w.png';
import tri015 from '../../../assets/images/badges2023/tri-015-prefinal-200w.png';
// import tri016 from '../../../assets/images/badges2023/tri-016-prefinal-200w.png';
// import tri017 from '../../../assets/images/badges2023/tri-017-prefinal-200w.png';
// import tri018 from '../../../assets/images/badges2023/tri-018-prefinal-200w.png';
// import tri019 from '../../../assets/images/badges2023/tri-019-prefinal-200w.png';
// import tri020 from '../../../assets/images/badges2023/tri-020-prefinal-200w.png';
import tri021 from '../../../assets/images/badges2023/tri-021-prefinal-200w.png';
// import tri022 from '../../../assets/images/badges2023/tri-022-prefinal-200w.png';
// import tri023 from '../../../assets/images/badges2023/tri-023-prefinal-200w.png';
// import tri024 from '../../../assets/images/badges2023/tri-024-prefinal-200w.png';
// import tri025 from '../../../assets/images/badges2023/tri-025-prefinal-200w.png';
// import tri026 from '../../../assets/images/badges2023/tri-026-prefinal-200w.png';
// import tri027 from '../../../assets/images/badges2023/tri-027-prefinal-200w.png';


import hero8 from '../../../assets/images/hero-bg/hero-8.jpg';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';



export default function LivePreviewExample() {
  return (
    <>




      <div className="hero-wrapper bg-composed-wrapper bg-second">
        <div className="hero-wrapper--content flex-column">
          <div className="shape-container-bottom-2 w-100">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="var(--white)"
                fillOpacity="1"
                d="M0,288L34.3,250.7C68.6,213,137,139,206,144C274.3,149,343,235,411,229.3C480,224,549,128,617,101.3C685.7,75,754,117,823,122.7C891.4,128,960,96,1029,112C1097.1,128,1166,192,1234,186.7C1302.9,181,1371,107,1406,69.3L1440,32L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
            </svg>
          </div>
          <div
            className="bg-composed-wrapper--image opacity-3"
            style={{ backgroundImage: 'url(' + hero8 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-premium-dark opacity-3" />
          <div className="bg-composed-wrapper--bg bg-deep-sky opacity-3" />
          <div className="bg-composed-wrapper--bg bg-sunrise-purple opacity-4" />
          <div className="bg-composed-wrapper--content pb-5">
            <Container className="z-over text-white pt-5">
              <Grid container spacing={6} className="py-5">
                <Grid item lg={6}>
                  <div className="text-center text-lg-left py-0 pr-0 pr-xl-3 py-xl-5">
                    <div className="pr-0 pr-xl-5 text-white">
                      <h1 className="display-3 mb-3 text-white font-weight-bold">
                        2023 MCLEs for California
                      </h1>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
                        For Practitioners of Law
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Chief Compliance Officers
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Private Investigators
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Insurance Companies
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Law Enforcement
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        The potential of Web3 technologies to transform legal practice.
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Understanding blockchain technology and its potential impact on legal practice
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Navigating regulatory compliance and legal challenges in Web3 industries
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Cryptographic protocols and digital signatures
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Cryptography and encryption methods for secure data transmission
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Cybersecurity and hacking prevention in blockchain systems
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Privacy and data protection in decentralized systems
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Digital identity verification and authentication in decentralized networks
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Utilizing node-computing and distributed computing in legal practice
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Smart contracts and their potential applications in legal practice
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Non-fungible tokens (NFTs) and digital asset ownership
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Investigating and verifying digital artifacts and information on the blockchain
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Practitioners of Law
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Chief Compliance Officers
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Private Investigators
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Insurance Companies
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        For Law Enforcement
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        The potential of Web3 technologies to transform compliance and regulatory processes.
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Understanding blockchain technology and its potential impact on compliance
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Tracing and verifying digital artifacts and information on the blockchain
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Utilizing node-computing and distributed computing in compliance monitoring
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Cybersecurity and data protection in Web3 insurance
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Compliance challenges and opportunities in Web3 insurance
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Investigating and settling claims in Web3 systems
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Investigating cryptocurrency transactions and tracing funds on the blockchain
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Non-fungible tokens (NFTs) and their potential regulatory implications
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Digital forensics in Web3 ecosystems
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Conducting due diligence and background checks in Web3
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        <span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
                        Securing digital evidence in Web3 investigations
                      </p>
                      <p className="font-size-lg m-0 py-0 text-white opacity-7">
                        The Web3Skills SmartLaw tokens features immutability and
                      </p>
                      <div className="d-block mt-4">
                        <Button
                          component={NavLink}
                          to="/Login"
                          className="btn-primary bg-night-sky px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg mr-3">
                          <span className="btn-wrapper--label">
                            Try Web3Skills Now
                          </span>
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={6} className="d-flex align-items-center">
                  <Card className="bg-secondary m-5 m-lg-0 object-skew hover-scale-lg shadow-sm-dark w-100 card-box">
                    <List component="div" className="list-group-flush">
                      <ListItem
                        component="a"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex rounded-top align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE One"
                              src={achieve001}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 1
                            </div>
                            <div className="text-black-50">
                              Using Web3 Addresses
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-success text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="b"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Two"
                              src={achieve003}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 2
                            </div>
                            <div className="text-black-50">
                              Navigating a SmartLaw API
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-success text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="c"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Three"
                              src={achieve004}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 3
                            </div>
                            <div className="text-black-50">
                              Connecting a Web3 Account
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-success text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="d"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Four"
                              src={achieve010}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 4
                            </div>
                            <div className="text-black-50">
                              Execute a Blockchain Transaction
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-success text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="e"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Five"
                              src={achieve014}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 5
                            </div>
                            <div className="text-black-50">
                              Explore Token Gating
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-danger text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="f"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Six"
                              src={achieve015}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 6
                            </div>
                            <div className="text-black-50">
                              Connect a Web3 Application
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-danger text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="g"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Seven"
                              src={achieve018}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 7
                            </div>
                            <div className="text-black-50">
                              Query a Token and Account
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-danger text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Eight"
                              src={achieve019}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 8
                            </div>
                            <div className="text-black-50">
                              Shallow Dive into DeFi
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-danger text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Nine"
                              src={achieve022}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 9
                            </div>
                            <div className="text-black-50">
                              A look into the Metaverse
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-warning text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="j"
                        button
                        href="tokens.freeweb3.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="MCLE Ten"
                              src={achieve023}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              MCLE Activity 10
                            </div>
                            <div className="text-black-50">
                              Obtain a Player Avatar Wearable
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-warning text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                    </List>
                  </Card>
                </Grid>
              </Grid>
              <Container className="pt-lg-5">
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve001}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve001"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve003}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve003"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve004}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve004"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve010}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve010"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve014}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve014"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve015}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve015"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve018}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve018"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve019}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve019"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve022}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve022"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={achieve023}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="achieve023"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={leg009}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="leg009"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={leg011}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="leg011"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={leg012}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="leg012"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={leg015}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="leg015"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={leg018}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="leg018"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={leg019}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="leg019"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={leg024}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="leg024"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={leg025}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="leg025"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={scale008}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="scale008"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={scale009}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="scale009"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={scale010}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="scale010"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={tri008}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="tri008"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={tri012}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="tri012"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={tri013}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="tri013"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={tri014}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="tri014"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={tri015}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="tri015"
                  />
                </div>
                <div className="rounded-circle btn-icon avatar-icon bg-primary d-100 shadow-sm-dark hover-scale-lg d-flex align-self-stretch m-1">
                  <img
                    src={tri021}
                    style={{ height: 100 }}
                    className="m-auto img-fluid"
                    alt="tri021"
                  />
                </div>
              </Container>
            </Container>
          </div>
          <div className="shape-container-top-1 w-100">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="var(--white)"
                fillOpacity="1"
                d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,202.7C672,213,768,235,864,213.3C960,192,1056,128,1152,133.3C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
