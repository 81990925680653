import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Container,
  Card,
  Button,
  List,
  ListItem
} from '@material-ui/core';

import { NavLink } from 'react-router-dom';

// import logo1 from '../../../assets/images/achievements/airdrop_surprise-level_1.png';
// import logo2 from '../../../assets/images/achievements/airdrop_surprise-level_2.png';
// import logo3 from '../../../assets/images/achievements/airdrop_surprise-level_3.png';
// import logo4 from '../../../assets/images/achievements/airdrop_surprise-level_4.png';
// import logo5 from '../../../assets/images/achievements/airdrop_surprise-level_5.png';
// import logo6 from '../../../assets/images/achievements/airdrop_surprise-level_6.png';

import logo1 from '../../../assets/images/achievements/web3vm-newb-1.png';
import logo2 from '../../../assets/images/achievements/web3vm-newb-2.png';
import logo3 from '../../../assets/images/achievements/web3vm-newb-3.png';
import logo4 from '../../../assets/images/achievements/web3vm-newb-4.png';
import logo5 from '../../../assets/images/achievements/web3vm-newb-5.png';
import logo6 from '../../../assets/images/achievements/web3vm-newb-6.png';



import hero8 from '../../../assets/images/hero-bg/hero-8.jpg';

export default function LivePreviewExample() {
  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-second">
        <div className="hero-wrapper--content flex-column">
          <div className="shape-container-bottom-2 w-100">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="var(--white)"
                fillOpacity="1"
                d="M0,288L34.3,250.7C68.6,213,137,139,206,144C274.3,149,343,235,411,229.3C480,224,549,128,617,101.3C685.7,75,754,117,823,122.7C891.4,128,960,96,1029,112C1097.1,128,1166,192,1234,186.7C1302.9,181,1371,107,1406,69.3L1440,32L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
            </svg>
          </div>
          <div
            className="bg-composed-wrapper--image opacity-3"
            style={{ backgroundImage: 'url(' + hero8 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-premium-dark opacity-3" />
          <div className="bg-composed-wrapper--bg bg-deep-sky opacity-3" />
          <div className="bg-composed-wrapper--bg bg-sunrise-purple opacity-4" />
          <div className="bg-composed-wrapper--content pb-5">





            <Container className="z-over text-white pt-5">
              <Grid container spacing={6} className="py-5">
                <Grid item lg={6}>
                  <div className="text-center text-lg-left py-0 pr-0 pr-xl-3 py-xl-5">
                    <div className="pr-0 pr-xl-5 text-white">
                      <h1 className="display-3 mb-3 text-white font-weight-bold">
                        Web3Skills Quests
                      </h1>
                      <p className="font-size-xxl m-0 py-3 text-white-50">
                        Join the Web3Skills journey to level up your education in node-based computing and public blockchains.
                      </p>
                      <p className="font-size-lg m-0 py-2 text-white opacity-7">
                        The Web3Skills SmartLaw tokens features immutability and upgradeability through the intelligent user-interface.  Once a token is reserved the metadata can be updated with customizeable properties and distributed to user accounts.  This can act as a credential, achievement, digital assets, or access token.  
                      </p>
                      <div className="d-block mt-4">
                        <Button
                          component={NavLink}
                          to="/Login"
                          className="btn-primary bg-night-sky px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg mr-3">
                          <span className="btn-wrapper--label">
                          Try Web3Skills Now
                          </span>
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={6} className="d-flex align-items-center">
                  <Card className="bg-secondary m-5 m-lg-0 object-skew hover-scale-lg shadow-sm-dark w-100 card-box">
                    <List component="div" className="list-group-flush">
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank" 
rel="noopener noreferrer"

                        className="d-flex rounded-top align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="..."
                              src={logo1}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                              Web3Skills Quest 1
                            </div>
                            <div className="text-black-50">Newb Level-1: Web3 Addresses</div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-success text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                          1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank" 
rel="noopener noreferrer"

                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="..."
                              src={logo2}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                            Web3Skills Quest 2
                            </div>
                            <div className="text-black-50">Newb Level-2: Navigating a SmartLaw_API</div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-warning text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                          1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank" 
rel="noopener noreferrer"

                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="..."
                              src={logo3}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                            Web3Skills Quest 3
                            </div>
                            <div className="text-black-50">Newb Level-3: Connecting a Web3 Account to a Browser</div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-success text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                            1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank" 
rel="noopener noreferrer"

                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="..."
                              src={logo4}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                            Web3Skills Quest 4
                            </div>
                            <div className="text-black-50">Newb Level-4: Execute a Blockchain Transaction</div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-success text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                          1 MATIC
                          </div>
                        </div>
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank" 
rel="noopener noreferrer"

                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="..."
                              src={logo5}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                            Web3Skills Quest 5
                            </div>
                            <div className="text-black-50">Newb Level-5 - Query a Token and Account</div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-danger text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                          1 MATIC
                          </div>
                        </div>
                      </ListItem>
                       <ListItem
                        component="a"
                        button
                        href="#/"
                        target="_blank" 
rel="noopener noreferrer"

                        className="d-flex align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon mr-3 d-50">
                            <img
                              className="img-fit-container"
                              alt="..."
                              src={logo6}
                            />
                          </div>
                          <div>
                            <div className="font-weight-bold text-black">
                            Web3Skills Quest 6
                            </div>
                            <div className="text-black-50">Newb Level-6: Obtain a Player Avatar Wearable</div>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="badge badge-neutral-danger text-primary font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                          1 MATIC
                          </div>
                        </div>
                      </ListItem>
                    </List>
                  </Card>
                </Grid>
              </Grid>
              <Container className="pt-lg-5">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <div className="rounded-circle btn-icon bg-white d-110 shadow-sm-dark m-4 hover-scale-lg d-flex align-self-stretch">
                    <img
                      src={logo1}
                      style={{ height: 46 }}
                      className="m-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="rounded-circle btn-icon bg-white d-80 shadow-sm-dark m-4 hover-scale-lg d-flex align-self-stretch">
                    <img
                      src={logo2}
                      style={{ height: 49 }}
                      className="m-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="rounded-circle btn-icon bg-white d-140 shadow-sm-dark m-4 hover-scale-lg d-flex align-self-stretch">
                    <img
                      src={logo3}
                      style={{ height: 51 }}
                      className="m-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="rounded-circle btn-icon bg-white d-70 shadow-sm-dark m-4 hover-scale-lg d-flex align-self-stretch">
                    <img
                      src={logo4}
                      style={{ height: 55 }}
                      className="m-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="rounded-circle btn-icon bg-white d-110 shadow-sm-dark m-4 hover-scale-lg d-flex align-self-stretch">
                    <img
                      src={logo5}
                      style={{ height: 41 }}
                      className="m-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="rounded-circle btn-icon bg-white d-100 shadow-sm-dark m-4 hover-scale-lg d-flex align-self-stretch">
                    <img
                      src={logo6}
                      style={{ height: 51 }}
                      className="m-auto img-fluid"
                      alt="..."
                    />
                  </div>
                </div>
              </Container>



              <Container className="z-over text-white pt-5 ">
        <Grid container spacing={6} className="py-5">
          <Grid item md={12}>
            <a
              href="https://clinic.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="card modal-content card-box-hover-rise rounded-lg bg-transparent">
              <img
                src="https://freeweb3.infura-ipfs.io/ipfs/QmUPqTKdzt6kpkhjyT3UjanqVXXgBFNAT8X7VcmirouKnN"
                className="rounded-lg img-fluid"
                alt="..."
              />
            </a>
            <p className="text-primary font-weight-bold font-size-xxl pt-3">
              Site Headers &#40;5:38 min.&#41;
            </p>
          </Grid>
        </Grid>
      </Container>

      


            </Container>
          </div>
          <div className="shape-container-top-1 w-100">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="var(--white)"
                fillOpacity="1"
                d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,202.7C672,213,768,235,864,213.3C960,192,1056,128,1152,133.3C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
