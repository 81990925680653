import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip
} from '@material-ui/core';


import daoimg001 from '../../../assets/images/legal-dao/dao-img-001-400x.png';
import daoimg002 from '../../../assets/images/legal-dao/dao-img-002-400x.png';
import daoimg003 from '../../../assets/images/legal-dao/dao-img-003-400x.png';
import daoimg004 from '../../../assets/images/legal-dao/dao-img-004-400x.png';
import daoimg005 from '../../../assets/images/legal-dao/dao-img-005-400x.png';
import daoimg006 from '../../../assets/images/legal-dao/dao-img-006-400x.png';
import daoimg007 from '../../../assets/images/legal-dao/dao-img-007-400x.png';
import daoimg008 from '../../../assets/images/legal-dao/dao-img-008-400x.png';
import daoimg009 from '../../../assets/images/legal-dao/dao-img-009-400x.png';
import daoimg010 from '../../../assets/images/legal-dao/dao-img-010-400x.png';
import daoimg011 from '../../../assets/images/legal-dao/dao-img-011-400x.png';
import daoimg012 from '../../../assets/images/legal-dao/dao-img-012-400x.png';
import daoimg013 from '../../../assets/images/legal-dao/dao-img-013-400x.png';
import daoimg014 from '../../../assets/images/legal-dao/dao-img-014-400x.png';
import daoimg015 from '../../../assets/images/legal-dao/dao-img-015-400x.png';
import daoimg016 from '../../../assets/images/legal-dao/dao-img-016-400x.png';
import daoimg017 from '../../../assets/images/legal-dao/dao-img-017-400x.png';
import daoimg018 from '../../../assets/images/legal-dao/dao-img-018-400x.png';
import daoimg019 from '../../../assets/images/legal-dao/dao-img-019-400x.png';
import daoimg020 from '../../../assets/images/legal-dao/dao-img-020-400x.png';
import daoimg021 from '../../../assets/images/legal-dao/dao-img-021-400x.png';
import daoimg022 from '../../../assets/images/legal-dao/dao-img-022-400x.png';
import daoimg023 from '../../../assets/images/legal-dao/dao-img-023-400x.png';
import daoimg024 from '../../../assets/images/legal-dao/dao-img-024-400x.png';
import daoimg025 from '../../../assets/images/legal-dao/dao-img-025-400x.png';
import daoimg026 from '../../../assets/images/legal-dao/dao-img-026-400x.png';
import daoimg027 from '../../../assets/images/legal-dao/dao-img-027-400x.png';
import daoimg028 from '../../../assets/images/legal-dao/dao-img-028-400x.png';
import daoimg029 from '../../../assets/images/legal-dao/dao-img-029-400x.png';
import daoimg030 from '../../../assets/images/legal-dao/dao-img-030-400x.png';
import daoimg031 from '../../../assets/images/legal-dao/dao-img-031-400x.png';
import daoimg032 from '../../../assets/images/legal-dao/dao-img-032-400x.png';
import daoimg033 from '../../../assets/images/legal-dao/dao-img-033-400x.png';
import daoimg034 from '../../../assets/images/legal-dao/dao-img-034-400x.png';
import daoimg035 from '../../../assets/images/legal-dao/dao-img-035-400x.png';
import daoimg036 from '../../../assets/images/legal-dao/dao-img-036-400x.png';
import daoimg037 from '../../../assets/images/legal-dao/dao-img-037-400x.png';
import daoimg038 from '../../../assets/images/legal-dao/dao-img-038-400x.png';
import daoimg039 from '../../../assets/images/legal-dao/dao-img-039-400x.png';
import daoimg040 from '../../../assets/images/legal-dao/dao-img-040-400x.png';
import daoimg041 from '../../../assets/images/legal-dao/dao-img-041-400x.png';
import daoimg042 from '../../../assets/images/legal-dao/dao-img-042-400x.png';
import daoimg043 from '../../../assets/images/legal-dao/dao-img-043-400x.png';
import daoimg044 from '../../../assets/images/legal-dao/dao-img-044-400x.png';
import daoimg045 from '../../../assets/images/legal-dao/dao-img-045-400x.png';
import daoimg046 from '../../../assets/images/legal-dao/dao-img-046-400x.png';
import daoimg047 from '../../../assets/images/legal-dao/dao-img-047-400x.png';
import daoimg048 from '../../../assets/images/legal-dao/dao-img-048-400x.png';
import daoimg049 from '../../../assets/images/legal-dao/dao-img-049-400x.png';
import daoimg050 from '../../../assets/images/legal-dao/dao-img-050-400x.png';
import daoimg051 from '../../../assets/images/legal-dao/dao-img-051-400x.png';
import daoimg052 from '../../../assets/images/legal-dao/dao-img-052-400x.png';
import daoimg053 from '../../../assets/images/legal-dao/dao-img-053-400x.png';
import daoimg054 from '../../../assets/images/legal-dao/dao-img-054-400x.png';
import daoimg055 from '../../../assets/images/legal-dao/dao-img-055-400x.png';
import daoimg056 from '../../../assets/images/legal-dao/dao-img-056-400x.png';
import daoimg057 from '../../../assets/images/legal-dao/dao-img-057-400x.png';
import daoimg058 from '../../../assets/images/legal-dao/dao-img-058-400x.png';
import daoimg059 from '../../../assets/images/legal-dao/dao-img-059-400x.png';
import daoimg060 from '../../../assets/images/legal-dao/dao-img-060-400x.png';
import daoimg061 from '../../../assets/images/legal-dao/dao-img-061-400x.png';
import daoimg062 from '../../../assets/images/legal-dao/dao-img-062-400x.png';
import daoimg063 from '../../../assets/images/legal-dao/dao-img-063-400x.png';
import daoimg064 from '../../../assets/images/legal-dao/dao-img-064-400x.png';
import daoimg065 from '../../../assets/images/legal-dao/dao-img-065-400x.png';
import daoimg066 from '../../../assets/images/legal-dao/dao-img-066-400x.png';
import daoimg067 from '../../../assets/images/legal-dao/dao-img-067-400x.png';
import daoimg068 from '../../../assets/images/legal-dao/dao-img-068-400x.png';
import daoimg069 from '../../../assets/images/legal-dao/dao-img-069-400x.png';
import daoimg070 from '../../../assets/images/legal-dao/dao-img-070-400x.png';
import daoimg071 from '../../../assets/images/legal-dao/dao-img-071-400x.png';
import daoimg072 from '../../../assets/images/legal-dao/dao-img-072-400x.png';
import daoimg073 from '../../../assets/images/legal-dao/dao-img-073-400x.png';
import daoimg074 from '../../../assets/images/legal-dao/dao-img-074-400x.png';
import daoimg075 from '../../../assets/images/legal-dao/dao-img-075-400x.png';
import daoimg076 from '../../../assets/images/legal-dao/dao-img-076-400x.png';
import daoimg077 from '../../../assets/images/legal-dao/dao-img-077-400x.png';
import daoimg078 from '../../../assets/images/legal-dao/dao-img-078-400x.png';
import daoimg079 from '../../../assets/images/legal-dao/dao-img-079-400x.png';
import daoimg080 from '../../../assets/images/legal-dao/dao-img-080-400x.png';
import daoimg081 from '../../../assets/images/legal-dao/dao-img-081-400x.png';
import daoimg082 from '../../../assets/images/legal-dao/dao-img-082-400x.png';
import daoimg083 from '../../../assets/images/legal-dao/dao-img-083-400x.png';
import daoimg084 from '../../../assets/images/legal-dao/dao-img-084-400x.png';
import daoimg085 from '../../../assets/images/legal-dao/dao-img-085-400x.png';
import daoimg086 from '../../../assets/images/legal-dao/dao-img-086-400x.png';
import daoimg087 from '../../../assets/images/legal-dao/dao-img-087-400x.png';
import daoimg088 from '../../../assets/images/legal-dao/dao-img-088-400x.png';
import daoimg089 from '../../../assets/images/legal-dao/dao-img-089-400x.png';
import daoimg090 from '../../../assets/images/legal-dao/dao-img-090-400x.png';
import daoimg091 from '../../../assets/images/legal-dao/dao-img-091-400x.png';
import daoimg092 from '../../../assets/images/legal-dao/dao-img-092-400x.png';
import daoimg093 from '../../../assets/images/legal-dao/dao-img-093-400x.png';
import daoimg094 from '../../../assets/images/legal-dao/dao-img-094-400x.png';
import daoimg095 from '../../../assets/images/legal-dao/dao-img-095-400x.png';
import daoimg096 from '../../../assets/images/legal-dao/dao-img-096-400x.png';
import daoimg097 from '../../../assets/images/legal-dao/dao-img-097-400x.png';
import daoimg098 from '../../../assets/images/legal-dao/dao-img-098-400x.png';
import daoimg099 from '../../../assets/images/legal-dao/dao-img-099-400x.png';
import daoimg100 from '../../../assets/images/legal-dao/dao-img-100-400x.png';
import daoimg101 from '../../../assets/images/legal-dao/dao-img-101-400x.png';
import daoimg102 from '../../../assets/images/legal-dao/dao-img-102-400x.png';
import daoimg103 from '../../../assets/images/legal-dao/dao-img-103-400x.png';
import daoimg104 from '../../../assets/images/legal-dao/dao-img-104-400x.png';
import daoimg105 from '../../../assets/images/legal-dao/dao-img-105-400x.png';
import daoimg106 from '../../../assets/images/legal-dao/dao-img-106-400x.png';
import daoimg107 from '../../../assets/images/legal-dao/dao-img-107-400x.png';
import daoimg108 from '../../../assets/images/legal-dao/dao-img-108-400x.png';
import daoimg109 from '../../../assets/images/legal-dao/dao-img-109-400x.png';
import daoimg110 from '../../../assets/images/legal-dao/dao-img-110-400x.png';
import daoimg111 from '../../../assets/images/legal-dao/dao-img-111-400x.png';
import daoimg112 from '../../../assets/images/legal-dao/dao-img-112-400x.png';
import daoimg113 from '../../../assets/images/legal-dao/dao-img-113-400x.png';
import daoimg114 from '../../../assets/images/legal-dao/dao-img-114-400x.png';
import daoimg115 from '../../../assets/images/legal-dao/dao-img-115-400x.png';
import daoimg116 from '../../../assets/images/legal-dao/dao-img-116-400x.png';
import daoimg117 from '../../../assets/images/legal-dao/dao-img-117-400x.png';
import daoimg118 from '../../../assets/images/legal-dao/dao-img-118-400x.png';
import daoimg119 from '../../../assets/images/legal-dao/dao-img-119-400x.png';
import daoimg120 from '../../../assets/images/legal-dao/dao-img-120-400x.png';
import daoimg121 from '../../../assets/images/legal-dao/dao-img-121-400x.png';
import daoimg122 from '../../../assets/images/legal-dao/dao-img-122-400x.png';
import daoimg123 from '../../../assets/images/legal-dao/dao-img-123-400x.png';
import daoimg124 from '../../../assets/images/legal-dao/dao-img-124-400x.png';
import daoimg125 from '../../../assets/images/legal-dao/dao-img-125-400x.png';
import daoimg126 from '../../../assets/images/legal-dao/dao-img-126-400x.png';
import daoimg127 from '../../../assets/images/legal-dao/dao-img-127-400x.png';
import daoimg128 from '../../../assets/images/legal-dao/dao-img-128-400x.png';
import daoimg129 from '../../../assets/images/legal-dao/dao-img-129-400x.png';
import daoimg130 from '../../../assets/images/legal-dao/dao-img-130-400x.png';
import daoimg131 from '../../../assets/images/legal-dao/dao-img-131-400x.png';
import daoimg132 from '../../../assets/images/legal-dao/dao-img-132-400x.png';
import daoimg133 from '../../../assets/images/legal-dao/dao-img-133-400x.png';
import daoimg134 from '../../../assets/images/legal-dao/dao-img-134-400x.png';
import daoimg135 from '../../../assets/images/legal-dao/dao-img-135-400x.png';
import daoimg136 from '../../../assets/images/legal-dao/dao-img-136-400x.png';
import daoimg137 from '../../../assets/images/legal-dao/dao-img-137-400x.png';
import daoimg138 from '../../../assets/images/legal-dao/dao-img-138-400x.png';
import daoimg139 from '../../../assets/images/legal-dao/dao-img-139-400x.png';
import daoimg140 from '../../../assets/images/legal-dao/dao-img-140-400x.png';
import daoimg141 from '../../../assets/images/legal-dao/dao-img-141-400x.png';
import daoimg142 from '../../../assets/images/legal-dao/dao-img-142-400x.png';
import daoimg143 from '../../../assets/images/legal-dao/dao-img-143-400x.png';
import daoimg144 from '../../../assets/images/legal-dao/dao-img-144-400x.png';
import daoimg145 from '../../../assets/images/legal-dao/dao-img-145-400x.png';
import daoimg146 from '../../../assets/images/legal-dao/dao-img-146-400x.png';
import daoimg147 from '../../../assets/images/legal-dao/dao-img-147-400x.png';
import daoimg148 from '../../../assets/images/legal-dao/dao-img-148-400x.png';
import daoimg149 from '../../../assets/images/legal-dao/dao-img-149-400x.png';
import daoimg150 from '../../../assets/images/legal-dao/dao-img-150-400x.png';
import daoimg151 from '../../../assets/images/legal-dao/dao-img-151-400x.png';
import daoimg152 from '../../../assets/images/legal-dao/dao-img-152-400x.png';
import daoimg153 from '../../../assets/images/legal-dao/dao-img-153-400x.png';
import daoimg154 from '../../../assets/images/legal-dao/dao-img-154-400x.png';
import daoimg155 from '../../../assets/images/legal-dao/dao-img-155-400x.png';
import daoimg156 from '../../../assets/images/legal-dao/dao-img-156-400x.png';
import daoimg157 from '../../../assets/images/legal-dao/dao-img-157-400x.png';
import daoimg158 from '../../../assets/images/legal-dao/dao-img-158-400x.png';
import daoimg159 from '../../../assets/images/legal-dao/dao-img-159-400x.png';
import daoimg160 from '../../../assets/images/legal-dao/dao-img-160-400x.png';
import daoimg161 from '../../../assets/images/legal-dao/dao-img-161-400x.png';
import daoimg162 from '../../../assets/images/legal-dao/dao-img-162-400x.png';
import daoimg163 from '../../../assets/images/legal-dao/dao-img-163-400x.png';
import daoimg164 from '../../../assets/images/legal-dao/dao-img-164-400x.png';
import daoimg165 from '../../../assets/images/legal-dao/dao-img-165-400x.png';
import daoimg166 from '../../../assets/images/legal-dao/dao-img-166-400x.png';
import daoimg167 from '../../../assets/images/legal-dao/dao-img-167-400x.png';
import daoimg168 from '../../../assets/images/legal-dao/dao-img-168-400x.png';
import daoimg169 from '../../../assets/images/legal-dao/dao-img-169-400x.png';
import daoimg170 from '../../../assets/images/legal-dao/dao-img-170-400x.png';
import daoimg171 from '../../../assets/images/legal-dao/dao-img-171-400x.png';
import daoimg172 from '../../../assets/images/legal-dao/dao-img-172-400x.png';
import daoimg173 from '../../../assets/images/legal-dao/dao-img-173-400x.png';
import daoimg174 from '../../../assets/images/legal-dao/dao-img-174-400x.png';
import daoimg175 from '../../../assets/images/legal-dao/dao-img-175-400x.png';
import daoimg176 from '../../../assets/images/legal-dao/dao-img-176-400x.png';
import daoimg177 from '../../../assets/images/legal-dao/dao-img-177-400x.png';
import daoimg178 from '../../../assets/images/legal-dao/dao-img-178-400x.png';
import daoimg179 from '../../../assets/images/legal-dao/dao-img-179-400x.png';
import daoimg180 from '../../../assets/images/legal-dao/dao-img-180-400x.png';
import daoimg181 from '../../../assets/images/legal-dao/dao-img-181-400x.png';
import daoimg182 from '../../../assets/images/legal-dao/dao-img-182-400x.png';
import daoimg183 from '../../../assets/images/legal-dao/dao-img-183-400x.png';
import daoimg184 from '../../../assets/images/legal-dao/dao-img-184-400x.png';
import daoimg185 from '../../../assets/images/legal-dao/dao-img-185-400x.png';
import daoimg186 from '../../../assets/images/legal-dao/dao-img-186-400x.png';
import daoimg187 from '../../../assets/images/legal-dao/dao-img-187-400x.png';
import daoimg188 from '../../../assets/images/legal-dao/dao-img-188-400x.png';



export default function LivePreviewExample() {
  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header pr-2">
          <div className="card-header--title">SmartLaw State Bar Administrators</div>
          <div className="card-header--actions">
            <Tooltip title="Refresh">
              <Button size="small" className="btn-neutral-primary">
                <FontAwesomeIcon icon={['fas', 'cog']} spin />
              </Button>
            </Tooltip>
          </div>
        </div>
        <CardContent>
          <div className="table-responsive-md">
            <Table className="table table-borderless table-hover text-nowrap mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="text-left">Discussion Topic</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Progress</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>






              <tr>
<td>1</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 1">
DAO Discussion Topic 1
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 1">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg001" src={daoimg001} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>2</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 2">
DAO Discussion Topic 2
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 2">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg002" src={daoimg002} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>3</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 3">
DAO Discussion Topic 3
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 3">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg003" src={daoimg003} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>4</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 4">
DAO Discussion Topic 4
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 4">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg004" src={daoimg004} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>5</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 5">
DAO Discussion Topic 5
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 5">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg005" src={daoimg005} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>6</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 6">
DAO Discussion Topic 6
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 6">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg006" src={daoimg006} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>7</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 7">
DAO Discussion Topic 7
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 7">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg007" src={daoimg007} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>8</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 8">
DAO Discussion Topic 8
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 8">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg008" src={daoimg008} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>9</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 9">
DAO Discussion Topic 9
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 9">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg009" src={daoimg009} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>10</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 10">
DAO Discussion Topic 10
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 10">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg010" src={daoimg010} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>11</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 11">
DAO Discussion Topic 11
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 11">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg011" src={daoimg011} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>12</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 12">
DAO Discussion Topic 12
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 12">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg012" src={daoimg012} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>13</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 13">
DAO Discussion Topic 13
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 13">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg013" src={daoimg013} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>14</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 14">
DAO Discussion Topic 14
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 14">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg014" src={daoimg014} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>15</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 15">
DAO Discussion Topic 15
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 15">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg015" src={daoimg015} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>16</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 16">
DAO Discussion Topic 16
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 16">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg016" src={daoimg016} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>17</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 17">
DAO Discussion Topic 17
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 17">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg017" src={daoimg017} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>18</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 18">
DAO Discussion Topic 18
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 18">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg018" src={daoimg018} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>19</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 19">
DAO Discussion Topic 19
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 19">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg019" src={daoimg019} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>20</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 20">
DAO Discussion Topic 20
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 20">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg020" src={daoimg020} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>21</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 21">
DAO Discussion Topic 21
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 21">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg021" src={daoimg021} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>22</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 22">
DAO Discussion Topic 22
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 22">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg022" src={daoimg022} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>23</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 23">
DAO Discussion Topic 23
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 23">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg023" src={daoimg023} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>24</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 24">
DAO Discussion Topic 24
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 24">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg024" src={daoimg024} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>25</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 25">
DAO Discussion Topic 25
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 25">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg025" src={daoimg025} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>26</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 26">
DAO Discussion Topic 26
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 26">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg026" src={daoimg026} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>27</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 27">
DAO Discussion Topic 27
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 27">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg027" src={daoimg027} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>28</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 28">
DAO Discussion Topic 28
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 28">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg028" src={daoimg028} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>29</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 29">
DAO Discussion Topic 29
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 29">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg029" src={daoimg029} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>30</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 30">
DAO Discussion Topic 30
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 30">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg030" src={daoimg030} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>31</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 31">
DAO Discussion Topic 31
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 31">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg031" src={daoimg031} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>32</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 32">
DAO Discussion Topic 32
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 32">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg032" src={daoimg032} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>33</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 33">
DAO Discussion Topic 33
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 33">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg033" src={daoimg033} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>34</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 34">
DAO Discussion Topic 34
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 34">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg034" src={daoimg034} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>35</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 35">
DAO Discussion Topic 35
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 35">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg035" src={daoimg035} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>36</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 36">
DAO Discussion Topic 36
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 36">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg036" src={daoimg036} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>37</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 37">
DAO Discussion Topic 37
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 37">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg037" src={daoimg037} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>38</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 38">
DAO Discussion Topic 38
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 38">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg038" src={daoimg038} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>39</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 39">
DAO Discussion Topic 39
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 39">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg039" src={daoimg039} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>40</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 40">
DAO Discussion Topic 40
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 40">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg040" src={daoimg040} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>41</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 41">
DAO Discussion Topic 41
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 41">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg041" src={daoimg041} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>42</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 42">
DAO Discussion Topic 42
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 42">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg042" src={daoimg042} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>43</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 43">
DAO Discussion Topic 43
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 43">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg043" src={daoimg043} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>44</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 44">
DAO Discussion Topic 44
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 44">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg044" src={daoimg044} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>45</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 45">
DAO Discussion Topic 45
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 45">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg045" src={daoimg045} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>46</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 46">
DAO Discussion Topic 46
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 46">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg046" src={daoimg046} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>47</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 47">
DAO Discussion Topic 47
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 47">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg047" src={daoimg047} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>48</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 48">
DAO Discussion Topic 48
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 48">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg048" src={daoimg048} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>49</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 49">
DAO Discussion Topic 49
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 49">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg049" src={daoimg049} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>50</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 50">
DAO Discussion Topic 50
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 50">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg050" src={daoimg050} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>51</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 51">
DAO Discussion Topic 51
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 51">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg051" src={daoimg051} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>52</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 52">
DAO Discussion Topic 52
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 52">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg052" src={daoimg052} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>53</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 53">
DAO Discussion Topic 53
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 53">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg053" src={daoimg053} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>54</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 54">
DAO Discussion Topic 54
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 54">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg054" src={daoimg054} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>55</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 55">
DAO Discussion Topic 55
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 55">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg055" src={daoimg055} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>56</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 56">
DAO Discussion Topic 56
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 56">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg056" src={daoimg056} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>57</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 57">
DAO Discussion Topic 57
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 57">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg057" src={daoimg057} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>58</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 58">
DAO Discussion Topic 58
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 58">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg058" src={daoimg058} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>59</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 59">
DAO Discussion Topic 59
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 59">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg059" src={daoimg059} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>60</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 60">
DAO Discussion Topic 60
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 60">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg060" src={daoimg060} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>61</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 61">
DAO Discussion Topic 61
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 61">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg061" src={daoimg061} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>62</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 62">
DAO Discussion Topic 62
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 62">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg062" src={daoimg062} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>63</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 63">
DAO Discussion Topic 63
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 63">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg063" src={daoimg063} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>64</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 64">
DAO Discussion Topic 64
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 64">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg064" src={daoimg064} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>65</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 65">
DAO Discussion Topic 65
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 65">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg065" src={daoimg065} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>66</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 66">
DAO Discussion Topic 66
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 66">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg066" src={daoimg066} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>67</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 67">
DAO Discussion Topic 67
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 67">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg067" src={daoimg067} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>68</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 68">
DAO Discussion Topic 68
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 68">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg068" src={daoimg068} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>69</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 69">
DAO Discussion Topic 69
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 69">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg069" src={daoimg069} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>70</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 70">
DAO Discussion Topic 70
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 70">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg070" src={daoimg070} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>71</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 71">
DAO Discussion Topic 71
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 71">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg071" src={daoimg071} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>72</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 72">
DAO Discussion Topic 72
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 72">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg072" src={daoimg072} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>73</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 73">
DAO Discussion Topic 73
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 73">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg073" src={daoimg073} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>74</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 74">
DAO Discussion Topic 74
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 74">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg074" src={daoimg074} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>75</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 75">
DAO Discussion Topic 75
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 75">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg075" src={daoimg075} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>76</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 76">
DAO Discussion Topic 76
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 76">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg076" src={daoimg076} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>77</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 77">
DAO Discussion Topic 77
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 77">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg077" src={daoimg077} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>78</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 78">
DAO Discussion Topic 78
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 78">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg078" src={daoimg078} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>79</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 79">
DAO Discussion Topic 79
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 79">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg079" src={daoimg079} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>80</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 80">
DAO Discussion Topic 80
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 80">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg080" src={daoimg080} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>81</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 81">
DAO Discussion Topic 81
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 81">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg081" src={daoimg081} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>82</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 82">
DAO Discussion Topic 82
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 82">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg082" src={daoimg082} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>83</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 83">
DAO Discussion Topic 83
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 83">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg083" src={daoimg083} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>84</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 84">
DAO Discussion Topic 84
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 84">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg084" src={daoimg084} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>85</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 85">
DAO Discussion Topic 85
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 85">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg085" src={daoimg085} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>86</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 86">
DAO Discussion Topic 86
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 86">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg086" src={daoimg086} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>87</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 87">
DAO Discussion Topic 87
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 87">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg087" src={daoimg087} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>88</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 88">
DAO Discussion Topic 88
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 88">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg088" src={daoimg088} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>89</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 89">
DAO Discussion Topic 89
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 89">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg089" src={daoimg089} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>90</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 90">
DAO Discussion Topic 90
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 90">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg090" src={daoimg090} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>91</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 91">
DAO Discussion Topic 91
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 91">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg091" src={daoimg091} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>92</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 92">
DAO Discussion Topic 92
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 92">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg092" src={daoimg092} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>93</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 93">
DAO Discussion Topic 93
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 93">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg093" src={daoimg093} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>94</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 94">
DAO Discussion Topic 94
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 94">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg094" src={daoimg094} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>95</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 95">
DAO Discussion Topic 95
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 95">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg095" src={daoimg095} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>96</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 96">
DAO Discussion Topic 96
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 96">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg096" src={daoimg096} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>97</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 97">
DAO Discussion Topic 97
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 97">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg097" src={daoimg097} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>98</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 98">
DAO Discussion Topic 98
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 98">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg098" src={daoimg098} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>99</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 99">
DAO Discussion Topic 99
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 99">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg099" src={daoimg099} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>100</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 100">
DAO Discussion Topic 100
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 100">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg100" src={daoimg100} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>101</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 101">
DAO Discussion Topic 101
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 101">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg101" src={daoimg101} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>102</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 102">
DAO Discussion Topic 102
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 102">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg102" src={daoimg102} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>103</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 103">
DAO Discussion Topic 103
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 103">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg103" src={daoimg103} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>104</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 104">
DAO Discussion Topic 104
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 104">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg104" src={daoimg104} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>105</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 105">
DAO Discussion Topic 105
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 105">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg105" src={daoimg105} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>106</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 106">
DAO Discussion Topic 106
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 106">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg106" src={daoimg106} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>107</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 107">
DAO Discussion Topic 107
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 107">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg107" src={daoimg107} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>108</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 108">
DAO Discussion Topic 108
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 108">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg108" src={daoimg108} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>109</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 109">
DAO Discussion Topic 109
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 109">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg109" src={daoimg109} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>110</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 110">
DAO Discussion Topic 110
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 110">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg110" src={daoimg110} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>111</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 111">
DAO Discussion Topic 111
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 111">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg111" src={daoimg111} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>112</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 112">
DAO Discussion Topic 112
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 112">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg112" src={daoimg112} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>113</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 113">
DAO Discussion Topic 113
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 113">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg113" src={daoimg113} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>114</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 114">
DAO Discussion Topic 114
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 114">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg114" src={daoimg114} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>115</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 115">
DAO Discussion Topic 115
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 115">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg115" src={daoimg115} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>116</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 116">
DAO Discussion Topic 116
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 116">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg116" src={daoimg116} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>117</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 117">
DAO Discussion Topic 117
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 117">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg117" src={daoimg117} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>118</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 118">
DAO Discussion Topic 118
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 118">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg118" src={daoimg118} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>119</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 119">
DAO Discussion Topic 119
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 119">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg119" src={daoimg119} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>120</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 120">
DAO Discussion Topic 120
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 120">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg120" src={daoimg120} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>121</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 121">
DAO Discussion Topic 121
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 121">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg121" src={daoimg121} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>122</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 122">
DAO Discussion Topic 122
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 122">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg122" src={daoimg122} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>123</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 123">
DAO Discussion Topic 123
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 123">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg123" src={daoimg123} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>124</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 124">
DAO Discussion Topic 124
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 124">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg124" src={daoimg124} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>125</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 125">
DAO Discussion Topic 125
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 125">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg125" src={daoimg125} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>126</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 126">
DAO Discussion Topic 126
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 126">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg126" src={daoimg126} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>127</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 127">
DAO Discussion Topic 127
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 127">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg127" src={daoimg127} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>128</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 128">
DAO Discussion Topic 128
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 128">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg128" src={daoimg128} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>129</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 129">
DAO Discussion Topic 129
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 129">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg129" src={daoimg129} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>130</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 130">
DAO Discussion Topic 130
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 130">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg130" src={daoimg130} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>131</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 131">
DAO Discussion Topic 131
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 131">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg131" src={daoimg131} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>132</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 132">
DAO Discussion Topic 132
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 132">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg132" src={daoimg132} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>133</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 133">
DAO Discussion Topic 133
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 133">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg133" src={daoimg133} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>134</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 134">
DAO Discussion Topic 134
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 134">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg134" src={daoimg134} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>135</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 135">
DAO Discussion Topic 135
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 135">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg135" src={daoimg135} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>136</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 136">
DAO Discussion Topic 136
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 136">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg136" src={daoimg136} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>137</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 137">
DAO Discussion Topic 137
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 137">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg137" src={daoimg137} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>138</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 138">
DAO Discussion Topic 138
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 138">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg138" src={daoimg138} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>139</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 139">
DAO Discussion Topic 139
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 139">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg139" src={daoimg139} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>140</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 140">
DAO Discussion Topic 140
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 140">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg140" src={daoimg140} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>141</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 141">
DAO Discussion Topic 141
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 141">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg141" src={daoimg141} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>142</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 142">
DAO Discussion Topic 142
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 142">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg142" src={daoimg142} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>143</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 143">
DAO Discussion Topic 143
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 143">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg143" src={daoimg143} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>144</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 144">
DAO Discussion Topic 144
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 144">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg144" src={daoimg144} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>145</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 145">
DAO Discussion Topic 145
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 145">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg145" src={daoimg145} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>146</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 146">
DAO Discussion Topic 146
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 146">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg146" src={daoimg146} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>147</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 147">
DAO Discussion Topic 147
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 147">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg147" src={daoimg147} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>148</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 148">
DAO Discussion Topic 148
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 148">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg148" src={daoimg148} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>149</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 149">
DAO Discussion Topic 149
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 149">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg149" src={daoimg149} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>150</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 150">
DAO Discussion Topic 150
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 150">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg150" src={daoimg150} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>151</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 151">
DAO Discussion Topic 151
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 151">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg151" src={daoimg151} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>152</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 152">
DAO Discussion Topic 152
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 152">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg152" src={daoimg152} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>153</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 153">
DAO Discussion Topic 153
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 153">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg153" src={daoimg153} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>154</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 154">
DAO Discussion Topic 154
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 154">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg154" src={daoimg154} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>155</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 155">
DAO Discussion Topic 155
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 155">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg155" src={daoimg155} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>156</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 156">
DAO Discussion Topic 156
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 156">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg156" src={daoimg156} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>157</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 157">
DAO Discussion Topic 157
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 157">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg157" src={daoimg157} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>158</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 158">
DAO Discussion Topic 158
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 158">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg158" src={daoimg158} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>159</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 159">
DAO Discussion Topic 159
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 159">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg159" src={daoimg159} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>160</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 160">
DAO Discussion Topic 160
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 160">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg160" src={daoimg160} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>161</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 161">
DAO Discussion Topic 161
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 161">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg161" src={daoimg161} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>162</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 162">
DAO Discussion Topic 162
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 162">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg162" src={daoimg162} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>163</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 163">
DAO Discussion Topic 163
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 163">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg163" src={daoimg163} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>164</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 164">
DAO Discussion Topic 164
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 164">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg164" src={daoimg164} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>165</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 165">
DAO Discussion Topic 165
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 165">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg165" src={daoimg165} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>166</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 166">
DAO Discussion Topic 166
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 166">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg166" src={daoimg166} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>167</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 167">
DAO Discussion Topic 167
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 167">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg167" src={daoimg167} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>168</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 168">
DAO Discussion Topic 168
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 168">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg168" src={daoimg168} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>169</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 169">
DAO Discussion Topic 169
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 169">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg169" src={daoimg169} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>170</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 170">
DAO Discussion Topic 170
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 170">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg170" src={daoimg170} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>171</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 171">
DAO Discussion Topic 171
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 171">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg171" src={daoimg171} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>172</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 172">
DAO Discussion Topic 172
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 172">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg172" src={daoimg172} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>173</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 173">
DAO Discussion Topic 173
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 173">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg173" src={daoimg173} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>174</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 174">
DAO Discussion Topic 174
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 174">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg174" src={daoimg174} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>175</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 175">
DAO Discussion Topic 175
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 175">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg175" src={daoimg175} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>176</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 176">
DAO Discussion Topic 176
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 176">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg176" src={daoimg176} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>177</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 177">
DAO Discussion Topic 177
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 177">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg177" src={daoimg177} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>178</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 178">
DAO Discussion Topic 178
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 178">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg178" src={daoimg178} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>179</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 179">
DAO Discussion Topic 179
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 179">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg179" src={daoimg179} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>180</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 180">
DAO Discussion Topic 180
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 180">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg180" src={daoimg180} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>181</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 181">
DAO Discussion Topic 181
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 181">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg181" src={daoimg181} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>182</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 182">
DAO Discussion Topic 182
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 182">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg182" src={daoimg182} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>183</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 183">
DAO Discussion Topic 183
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 183">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg183" src={daoimg183} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>184</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 184">
DAO Discussion Topic 184
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 184">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg184" src={daoimg184} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>185</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 185">
DAO Discussion Topic 185
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 185">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg185" src={daoimg185} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>186</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 186">
DAO Discussion Topic 186
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 186">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg186" src={daoimg186} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>187</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 187">
DAO Discussion Topic 187
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 187">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg187" src={daoimg187} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>188</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
</div>
<div>
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="font-weight-bold text-black"
title="Legal Topic 188">
DAO Discussion Topic 188
</a>
<span className="text-black-50 d-block">
<a
href="https://lexdao-topics.freeweb3.com" rel="noopener noreferrer" target="_blank"
className="text-first ml-3"
title="Legal Discussion Title 188">
Legislation Politics Money Banking Treasury Federal Reserve
</a>
</span>
<img alt="DAOImg188" src={daoimg188} />
</div>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>




              </tbody>
            </Table>
          </div>
        </CardContent>
        <div className="card-footer d-flex justify-content-between">
          <Button variant="text" className="btn-outline-primary" size="small">
            Export
          </Button>
          <div>
            <Button size="small" color="primary" variant="contained">
              See full list
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}
