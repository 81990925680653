import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Button } from '@material-ui/core';

import package58800a11000w from '../../../assets/images/grants/package-588-00a-1-1000w.png';
import package58800b11000w from '../../../assets/images/grants/package-588-00b-1-1000w.png';
import package5880111000w from '../../../assets/images/grants/package-588-01-1-1000w.png';
import package5880211000w from '../../../assets/images/grants/package-588-02-1-1000w.png';
import package5880311000w from '../../../assets/images/grants/package-588-03-1-1000w.png';
import package5880411000w from '../../../assets/images/grants/package-588-04-1-1000w.png';
import package5880511000w from '../../../assets/images/grants/package-588-05-1-1000w.png';
import package5880611000w from '../../../assets/images/grants/package-588-06-1-1000w.png';
import package5880711000w from '../../../assets/images/grants/package-588-07-1-1000w.png';
import package5880811000w from '../../../assets/images/grants/package-588-08-1-1000w.png';
import package5880911000w from '../../../assets/images/grants/package-588-09-1-1000w.png';
import package5881011000w from '../../../assets/images/grants/package-588-10-1-1000w.png';
import package5881111000w from '../../../assets/images/grants/package-588-11-1-1000w.png';
import package5881211000w from '../../../assets/images/grants/package-588-12-1-1000w.png';
import package5881311000w from '../../../assets/images/grants/package-588-13-1-1000w.png';
import package5881411000w from '../../../assets/images/grants/package-588-14-1-1000w.png';
import package5881511000w from '../../../assets/images/grants/package-588-15-1-1000w.png';
import package5881611000w from '../../../assets/images/grants/package-588-16-1-1000w.png';
import package5881711000w from '../../../assets/images/grants/package-588-17-1-1000w.png';
import package5881811000w from '../../../assets/images/grants/package-588-18-1-1000w.png';
import package5881911000w from '../../../assets/images/grants/package-588-19-1-1000w.png';
import package5882011000w from '../../../assets/images/grants/package-588-20-1-1000w.png';
import package588pdfcocommunity9011000w from '../../../assets/images/grants/package-588-pdfco-community-9-01-1000w.png';
import package588pdfcocommunity9021000w from '../../../assets/images/grants/package-588-pdfco-community-9-02-1000w.png';
import package588pdfcocommunity9031000w from '../../../assets/images/grants/package-588-pdfco-community-9-03-1000w.png';
import package588pdfcocommunity9041000w from '../../../assets/images/grants/package-588-pdfco-community-9-04-1000w.png';
import package588pdfcocommunity9051000w from '../../../assets/images/grants/package-588-pdfco-community-9-05-1000w.png';
import package588pdfcocommunity9061000w from '../../../assets/images/grants/package-588-pdfco-community-9-06-1000w.png';
import package588pdfcocommunity9071000w from '../../../assets/images/grants/package-588-pdfco-community-9-07-1000w.png';
import package588pdfcocommunity9081000w from '../../../assets/images/grants/package-588-pdfco-community-9-08-1000w.png';
import package588pdfcocommunity9091000w from '../../../assets/images/grants/package-588-pdfco-community-9-09-1000w.png';
import package588pdfcocommunity9101000w from '../../../assets/images/grants/package-588-pdfco-community-9-10-1000w.png';
import package588pdfcocommunity9111000w from '../../../assets/images/grants/package-588-pdfco-community-9-11-1000w.png';
import package588pdfcocommunity9121000w from '../../../assets/images/grants/package-588-pdfco-community-9-12-1000w.png';
import package588pdfcocommunity9131000w from '../../../assets/images/grants/package-588-pdfco-community-9-13-1000w.png';
import package588pdfcocommunity9141000w from '../../../assets/images/grants/package-588-pdfco-community-9-14-1000w.png';
import package588pdfcocommunity9151000w from '../../../assets/images/grants/package-588-pdfco-community-9-15-1000w.png';
import package588pdfcocommunity9161000w from '../../../assets/images/grants/package-588-pdfco-community-9-16-1000w.png';
import package588pdfcocommunity9171000w from '../../../assets/images/grants/package-588-pdfco-community-9-17-1000w.png';
import package588pdfcocommunity9181000w from '../../../assets/images/grants/package-588-pdfco-community-9-18-1000w.png';
import package588pdfcocommunity9191000w from '../../../assets/images/grants/package-588-pdfco-community-9-19-1000w.png';
import package588pdfcocommunity9201000w from '../../../assets/images/grants/package-588-pdfco-community-9-20-1000w.png';
import package588pdfcocommunity9211000w from '../../../assets/images/grants/package-588-pdfco-community-9-21-1000w.png';
import package588pdfcocommunity9221000w from '../../../assets/images/grants/package-588-pdfco-community-9-22-1000w.png';
import package588pdfcocommunity9231000w from '../../../assets/images/grants/package-588-pdfco-community-9-23-1000w.png';
import package588pdfcocommunity9241000w from '../../../assets/images/grants/package-588-pdfco-community-9-24-1000w.png';
import package588pdfcocommunity9251000w from '../../../assets/images/grants/package-588-pdfco-community-9-25-1000w.png';
import package588pdfcocommunity9261000w from '../../../assets/images/grants/package-588-pdfco-community-9-26-1000w.png';
import package588pdfcocommunity9271000w from '../../../assets/images/grants/package-588-pdfco-community-9-27-1000w.png';
import package588pdfcoprofile011000w from '../../../assets/images/grants/package-588-pdfco-profile-01-1000w.png';
import package588pdfcoprofile021000w from '../../../assets/images/grants/package-588-pdfco-profile-02-1000w.png';
import package588pdfcoprofile031000w from '../../../assets/images/grants/package-588-pdfco-profile-03-1000w.png';
import package588pdfcoprofile041000w from '../../../assets/images/grants/package-588-pdfco-profile-04-1000w.png';
import package588pdfcoprofile051000w from '../../../assets/images/grants/package-588-pdfco-profile-05-1000w.png';
import package588pdfcoprofile061000w from '../../../assets/images/grants/package-588-pdfco-profile-06-1000w.png';
import package588pdfcoprofile071000w from '../../../assets/images/grants/package-588-pdfco-profile-07-1000w.png';
import package588pdfcoprofile081000w from '../../../assets/images/grants/package-588-pdfco-profile-08-1000w.png';
import package588pdfcoprofile091000w from '../../../assets/images/grants/package-588-pdfco-profile-09-1000w.png';
import package588pdfcoprofile101000w from '../../../assets/images/grants/package-588-pdfco-profile-10-1000w.png';
import package588pdfcoprofile111000w from '../../../assets/images/grants/package-588-pdfco-profile-11-1000w.png';
import package588pdfcoprofile121000w from '../../../assets/images/grants/package-588-pdfco-profile-12-1000w.png';
import package588pdfcoprofile131000w from '../../../assets/images/grants/package-588-pdfco-profile-13-1000w.png';
import package588pdfcoprofile141000w from '../../../assets/images/grants/package-588-pdfco-profile-14-1000w.png';
import package588pdfcoprofile151000w from '../../../assets/images/grants/package-588-pdfco-profile-15-1000w.png';
import package588pdfcoprofile161000w from '../../../assets/images/grants/package-588-pdfco-profile-16-1000w.png';
import package588pdfcoprofile171000w from '../../../assets/images/grants/package-588-pdfco-profile-17-1000w.png';
import package588pdfcoprofile181000w from '../../../assets/images/grants/package-588-pdfco-profile-18-1000w.png';
import package588pdfcoprofile191000w from '../../../assets/images/grants/package-588-pdfco-profile-19-1000w.png';
import package588pdfcoprofile201000w from '../../../assets/images/grants/package-588-pdfco-profile-20-1000w.png';
import package588pdfcoprofile211000w from '../../../assets/images/grants/package-588-pdfco-profile-21-1000w.png';
import package588pdfcoprofile221000w from '../../../assets/images/grants/package-588-pdfco-profile-22-1000w.png';
import package588pdfcoprofile231000w from '../../../assets/images/grants/package-588-pdfco-profile-23-1000w.png';
import package588pdfcoprofile241000w from '../../../assets/images/grants/package-588-pdfco-profile-24-1000w.png';
import package588pdfcoprofile251000w from '../../../assets/images/grants/package-588-pdfco-profile-25-1000w.png';
import package588pdfcoprofile261000w from '../../../assets/images/grants/package-588-pdfco-profile-26-1000w.png';
import package588pdfcoprofile271000w from '../../../assets/images/grants/package-588-pdfco-profile-27-1000w.png';
import package588pdfcoprofile281000w from '../../../assets/images/grants/package-588-pdfco-profile-28-1000w.png';
import package588pdfcoprofile291000w from '../../../assets/images/grants/package-588-pdfco-profile-29-1000w.png';
import package588pdfcoprofile301000w from '../../../assets/images/grants/package-588-pdfco-profile-30-1000w.png';
import package588pdfcoprofile311000w from '../../../assets/images/grants/package-588-pdfco-profile-31-1000w.png';
import package588pdfcoprofile321000w from '../../../assets/images/grants/package-588-pdfco-profile-32-1000w.png';
import package588pdfcoprofile331000w from '../../../assets/images/grants/package-588-pdfco-profile-33-1000w.png';
import package588pdfcoprofile341000w from '../../../assets/images/grants/package-588-pdfco-profile-34-1000w.png';
import package588pdfcoprofile351000w from '../../../assets/images/grants/package-588-pdfco-profile-35-1000w.png';
import package588pdfcoprofile361000w from '../../../assets/images/grants/package-588-pdfco-profile-36-1000w.png';
import package588pdfcoprofile371000w from '../../../assets/images/grants/package-588-pdfco-profile-37-1000w.png';
import package588pdfcoprofile381000w from '../../../assets/images/grants/package-588-pdfco-profile-38-1000w.png';
import package588pdfcoprofile391000w from '../../../assets/images/grants/package-588-pdfco-profile-39-1000w.png';
import package588pdfcoprofile401000w from '../../../assets/images/grants/package-588-pdfco-profile-40-1000w.png';
import package588pdfcoprofile411000w from '../../../assets/images/grants/package-588-pdfco-profile-41-1000w.png';
import package588pdfcoprofile421000w from '../../../assets/images/grants/package-588-pdfco-profile-42-1000w.png';
import package588pdfcoprofile431000w from '../../../assets/images/grants/package-588-pdfco-profile-43-1000w.png';
import package588pdfcoprofile441000w from '../../../assets/images/grants/package-588-pdfco-profile-44-1000w.png';
import package588pdfcoprofile451000w from '../../../assets/images/grants/package-588-pdfco-profile-45-1000w.png';
import package588pdfcoprofile461000w from '../../../assets/images/grants/package-588-pdfco-profile-46-1000w.png';
import package588pdfcoprofile471000w from '../../../assets/images/grants/package-588-pdfco-profile-47-1000w.png';
import package588pdfcoprofile481000w from '../../../assets/images/grants/package-588-pdfco-profile-48-1000w.png';
import package588pdfcoprofile491000w from '../../../assets/images/grants/package-588-pdfco-profile-49-1000w.png';
import package588pdfcoprofile501000w from '../../../assets/images/grants/package-588-pdfco-profile-50-1000w.png';
import package588pdfcoprofile511000w from '../../../assets/images/grants/package-588-pdfco-profile-51-1000w.png';
import package588pdfcoprofile521000w from '../../../assets/images/grants/package-588-pdfco-profile-52-1000w.png';
import package588pdfcoprofile531000w from '../../../assets/images/grants/package-588-pdfco-profile-53-1000w.png';
import package588pdfcoprofile541000w from '../../../assets/images/grants/package-588-pdfco-profile-54-1000w.png';
import package588pdfcoprofile561000w from '../../../assets/images/grants/package-588-pdfco-profile-56-1000w.png';

export default function LivePreviewExample() {
  return (
    <>
<div className="mb-spacing-6">
<Grid container spacing={6}>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmdNxcrU7147H2sZcJjZj42nKBb9m9w86mZokSz9PniUHG"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 1
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package58800a11000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmdgDtLbxuEP9sW2RHg9tmqpGroQweDhNnNZs9wibaMfn9"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 2
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package58800b11000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/Qmc3HTGzK4zJ894gPAcqQjvi1oe4i9hgzn2hBY9jAJWkTK"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 3
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880111000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmaJrEDbp4d37kxzjNB31B1B8cnuE7GQoj8UeN84BVwYa1"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 4
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880211000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmcTNjEwNYukMCrzg67VFEqDVom1nW29ZyPNdn2ZEwAEo9"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 5
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880311000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmaPUKQiPoeSms4BKQ7E78R43UzTeqBzRUVVrpSHunUdPQ"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 6
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880411000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmSpXMd7m3uDdU57vMpmX1QdUzcVbWXXjmA5cQgBuueBrr"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 7
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880511000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmWPin4bmF6bWrfCcCrshXtuJZLmo4jeKBtGp8iSjM4F9G"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 8
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880611000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmRNMGHSZK3JCgUqVTJwEjBUq9wkfGoKDcSvcWRimcTg4J"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 9
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880711000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmPMJQq2BSKPGc8qEEZ7hApkqekF9eowLYQwSAF9WE64ei"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 10
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880811000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmXRmTMPZ6jhDfnoZm1JQUpLtPHdBrb87BRc9oLsdZygGp"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 11
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5880911000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/Qmf7E2rCYv7xQ42m2L5x6MxPRSWizCQtNHsRQc894m6Tg9"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 12
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881011000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUr3oC8C5oixBZMNoLagN86yycE3szGMgder8FvEtKGvB"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 13
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881111000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmR4tDF2Ug2xme6LkDzg4aDsezZomzuAYJEKDkneYtbR7c"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 14
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881211000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmPNVwpLABswRcdtdcjvWUYma51iGo3GSxXXk1zdAPSAh3"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 15
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881311000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmTLG6QQEnkudqPLeM5aeyUyB9RtUyyfFnVuq6VU95V9PP"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 16
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881411000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUpNu9teSRNLLeJ5ZiXyd7PLr94PifB9tAiEtoNLZegAV"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 17
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881511000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmYMSSf7cRQU6a993drxzojwEKaKQjkXJy2mTXbTvg5pci"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 18
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881611000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUT89kpoa5Xbw7q3kNRdAEdLE6Uw9MVgF7aqKWTkwnF4Y"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 19
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881711000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmR6eK1hzWBRqUGDEaqjiwKos9UJAos9no2jj9a2SKXyk5"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 20
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881811000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmbTutK3fhpkTmQbcm2Au6d6QpVMWENsNN7B5SNS7mcamz"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 21
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5881911000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmTB5t3iX28PqbK8oTYY2n6bY5dovk1u5JNB6xVHnKnQha"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 22
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package5882011000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmQEj5YkcTRLkNC5idbhMjU6kqmRPfDSmz1stvoASPaQZA"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 23
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9011000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/Qma1zYtjLdQX4uMQP2bUfFEjYnqyVLqwTj38HcLbNWdk6r"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 24
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9021000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmaWndC9APCaVcCDVdQsyfuNm8yZZC6t7X9ttx9JXazh8j"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 25
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9031000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmWdeH8Z1iAq2A4xQkdnXQbsnHco6vLPmnzn7ymzsGfiYZ"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 26
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9041000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmYysE3AvfJtn5JnPF2YaJYwcEC1DvTfJA6FogAqCb8sG7"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 27
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9051000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/Qmc7PM5p49GFaKVAa9hAX22Jf1oSyivYGBCcRcX3PeL9Bz"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 28
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">{ }View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9061000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmQKdzeDbeCtM9uW67xZRvX44jnpRdJUkfiLaSMzAWAmVR"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 29
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9071000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmQC9B3GSEwEbDFKjSum3iCHp1t9U5iyEPkqmRSXVigXJQ"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 30
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9081000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmW6JQmbqpPfs8bNv9SHXEkmgrP2QzJwkTM2xDzDitytyp"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 31
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9091000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmTkbNEwVQMLscVtYCgs1nq38nf9rThTK3V6AEem6qJBR1"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 32
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9101000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/Qmb3ArMekGfSeJMu21hogvunWi6HtozjdsGDPENk5yPj79"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 33
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9111000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmafoD27XGBGkVG1UgrQkny1mqwBAEh4iTxb2f6uWtaWJ9"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 34
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9121000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmbqfSuKgYH3hLKUh24VUZUrrA19jL2K6z2h7Joj1vjqvK"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 35
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9131000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmY8TdB1By7m6Xn6SA9rQh6s5W7wXwZbDxfwMC1skgwXct"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 36
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9141000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmNkrdn8WNr2rJeBvuEhN5AkwSJ9HghEdmCDZKGPKCVXz8"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 37
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9151000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmZQjdq6nF3y6D6orZiavDFcbP9YyWNNqLEk2iVfasYM9U"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 38
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9161000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmSj6TcwqdjsYvikjpK7QACQi7QQk6ZJ9b9eCB4cA8vsjc"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 39
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9171000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmSNArwv8dY12SJ6hL6vHi53j3m1PGtaGaPJkTGGcsA3ax"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 40
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9181000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmZvZaarCSmuvjcmwVKWGBnhMdmk3aSbBfpPyLGhwmFYSq"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 41
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9191000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmTbgVuXgMpgb11psb7CwGbStKqzG6rYus9C6gHQxEPTrg"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 42
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9201000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmYdn7JFdVKaYwqdPXeA8fEpqxqqqKVjfyQWpZwCHkQ9cK"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 43
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9211000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmegTqnwQamUd9KPhCW64CYESMqEKUotFxJm5r6Ns7pN46"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 44
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9221000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUt6S4PnZhYvzUBHoSpEgbrPqSehFfo41SnTC8vjVCrJG"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 45
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9231000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmVHsT3eYtnd1URzRiMocyLt2PtQgYGnpWw5WX5FoqLYFQ"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 46
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9241000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmYVcGNqCgsAQcYBCQXg34rUzy9JrmMVM5EaZGYHtP2ACz"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 47
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9251000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmXFWoF1hNPSKoWaKmk5dXU9XM6zJoi5ocxgzZQTn8pjvX"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 48
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9261000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmeitQuxpNrWAt6fZBhth1rEmv9PqiUz1BMPxoBnzhaNAj"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 49
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcocommunity9271000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmTmBXBGv5ZMSpUH755eTrnHfQL14bHDxBq9kPwFRGMHBJ"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 50
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile011000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUPRXUPCNzRxZNTZTx8LPHtrqiJWy3rqe5j9Ps1G4cdTR"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 51
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile021000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmSJ2aTr2wYX2vwTMq2b99AEnf2NQZtrkzuoTRKzgaWNF7"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 52
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile031000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmNcydVviQkjXdnetD45Yp3maDe4Cg9r3WWQxnoYxbL1so"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 53
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile041000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/Qmat99QJP6sfCL1B6ZWNppeBbcJohQknTvoz8hWnJguyXq"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 54
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile051000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmcHZ6Yceu73UasRdoiBBuiZCrHv5Qokq9P1bcmL3ppCUg"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 55
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile061000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmYKvGzNfhshkXFNPKK2pi61ng3kW7n1pUmHUDAkhytsCU"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 56
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile071000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmbcUZuNamteSWx12WHEJEtLnjQktqQBhWu97qDffZmHgq"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 57
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">{ }View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile081000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmW6ePPMSmiS3xbw3ddhX1cf7JiPGHbYnXNjECDbw6mWo8"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 58
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile091000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmeT2nm8r5UYrYefzq5ggWfDdWpNfY8iZjVr2EKuEthsU9"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 59
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile101000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmVh6vhheHRZxPWkkdTkiMLLmZDhBieuJKwgs4d8msYbDD"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 60
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile111000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmP5hpftZkfpUbpQe36z84hubuyMxMKiQX6oxCFnbN81WP"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 61
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile121000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmabYu1yX4PL4DN7ZBjYG2XMraWR7L5pnq4ThGUGG5RJgF"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 62
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile131000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmY6fnowC4VCu2nLy8rf5itXMHFXKZPA9M8htLshRM6Uzw"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 63
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile141000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmPog3JLsDPqAVgYGRncWqZgCfNLfpp94X3Kkf55sAKkvM"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 64
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile151000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmZGFn77osu2SiWTrcHrnrgSRGHv5cUs9fwXKTK4wvzNiY"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 65
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile161000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmQdJ6pz7ZxuUMpknnzWX8vWLjbqPTh142aGq8Smt29U6A"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 66
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile171000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmeeKC5hdb4fXSgDqgpeAFcXETjH4kCvJZERLpVMdnEzzq"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 67
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile181000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmavB77fuCFpcA6uVSHZrYub5AuVGS6qQFYHqk8JzoX9ZX"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 68
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile191000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmT3Fc56cqTtd25PhezQ9J9CuTqmhTiZgbPRqM1PWf3m9Z"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 69
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile201000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUq4aBUfigvcdTivQPAD6A7MU9pjnofMfn8JcpBEKqQym"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 70
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile211000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmcmPTi8Aj9dWe4pRZQELZQZdifFyu3aQZEeqNDuafv4NX"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 71
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile221000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmZSGvPHmhqZ4u9B9MCe8SwAhyx5zWaNeGM2SqCBTwGB2P"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 72
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile231000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmSUxfNMPczW7fq3fNn64VFwxaPMg3hPN1oUxWRfGChkAT"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 73
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile241000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmTUJKnmJ1FBLHY8Gn5V78sSk7LmsURo9DXDdRQT6mcrY8"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 74
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile251000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmdDfYSgVaTTnQNGfYyeuw331YtGz6ThjT5ZpeSVLrcvue"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 75
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile261000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmdGc7fw14FNzzKDeBbWPHPZewhgDd79WbCiicxJXNdf5x"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 76
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile271000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmTTU35a7jMe2hFba6gxwr6RDVgBEwTwVcWX7eGDuhDeR9"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 77
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile281000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmZKDdAQPH5gj1hATHsw1CMyGuXKSkJWsyi8HCsFqsyZky"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 78
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile291000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmT6A2fZ66RuonQ8D7RPFwBjCFqmsrR8uNxq3hiC8BP9Lm"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 79
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile301000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUftiycVyKUcdVx5CGfbNe3d68Et3D9fqW2Azthw87kgx"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 80
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile311000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmYEPYa5XduzZE2jYPcFvtk6N4xD1eTpCcqeU8nQ6f4shq"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 81
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile321000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmVd9yawTz96U5F6hj9X6H1EkxKf4ngDEXkWpCDW49kATy"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 82
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile331000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmNeG9zT6m2rXDduz48qQuQ3jb1zSEaTPTinbPZAM1wqNC"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 83
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile341000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmfSccaa2HDPTTJnFvhpihuKWQq2poVwKtFfoGauuZG8ut"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 84
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile351000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmaK4VddkFoyVHaW4D9BvEKomzUgF3LGFjh2BPyp1RUk67"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 85
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile361000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmX7jFFR5SX3pWA8LkcahWhgTwBrTCVgx1gooH5eVxXTXT"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 86
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">{ }View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile371000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUqBi8kt3bofxwr3hAgEbcuPcctSeNvSqTEDoKvoDFuSL"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 87
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile381000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmXXGF7Pre7F4R3JknKA9fXHjie4mNGf4oK22K8sWYn7Aj"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 88
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile391000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmdRGXVXtX8Yj9CLrknRSu1gyyfVhXozotD731qXK4j1TX"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 89
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile401000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmcEq6oYNziJjs8No7RiAigJuuyscP1RFrzmxqdgGEVWgh"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 90
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile411000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmRgW27hvYcyE2aDgm2nna3vbvXmhodPkNuXdnnXR6CkhZ"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 91
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile421000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmbBf4KSLD5TJ3J3ZUKJfacnjw5bsEK7A7wND4yzRBV2W7"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 92
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile431000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmUApLZNP3irumh3SPd37ENDygW98JwMJwmDhP17imZcjB"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 93
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile441000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmcRFRLRu9Tqr2cUvHSj7ontGQCEmtEf67M4FicWbA612G"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 94
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile451000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmeptHypm1KHFyi9wiGARGVCLUaPZuCgok6bu8TEMhWEtc"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 95
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile461000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmWHcq1uSJfPy91AeZwtqzRuXrGH7Pt9JMQ8e9nNi2u7Kv"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 96
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile471000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/Qmbdt2XKgGJ1Z7bkAJXNs2Fo4jAzDah5742kX4N4itWyoE"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 97
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile481000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmSXhRdwoourheLuLQiv5phXhyZ55oVdLgaecXEoryqQiY"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 98
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile491000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmT2vE76ZaMYKX8GV9tBCqtnShz281s8pzG2tXHF5jRQBy"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 99
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile501000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmNZjgBwRb2QjDqm6zXMWWCHZJv9t5ovwqkuxfJdBoQ41n"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 100
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile511000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/Qmd12ixmSQ5SrdSQRmiqfSizPzPVSTe2asFgyKhFEGrGk3"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 101
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile521000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmSTbdJRPg7P1H1d8tGkW2bSpiXguB2GdwoSFxnxPUKyKa"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 102
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile531000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmQ8xLdwv4Qjk6o8Knk49sUhu6ctUTWZCLf7Q1msRvMrvA"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 103
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile541000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
<Grid item xl={4}>
<Card className="shadow-xxl">
<a
href="https://freeweb3.infura-ipfs.io/ipfs/QmVy3tQcR9hZWyUMrhNGHrdX6uU9SrqL4JB4LgFPSYSFCR"
target="_blank"
rel="noopener noreferrer"
className="card-img-wrapper rounded">
<div className="img-wrapper-overlay">
<div className="overlay-btn-wrapper">
<div className="avatar-icon-wrapper mx-auto mb-2">
</div>
<div className="font-size-lg font-weight-bold">
Grant Profile WIP page 104
</div>
<div className="text-white-50 pb-4">
Web3 Legal Engineering
</div>
<Button
size="small"
className="btn-first btn-pill px-4 hover-scale-sm">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'download']} />
</span>
<span className="btn-wrapper—label">View Report</span>
</Button>
</div>
</div>
<div className="card-badges">
</div>
<img
src={package588pdfcoprofile561000w}
className="card-img-top rounded"
alt="smartlaw-grant-588"
/>
</a>
</Card>
</Grid>
Web3Skills SmartLaw
</Grid>
</div>
    </>
  );
}
