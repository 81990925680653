import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Button } from '@material-ui/core';

// import stock1 from '../../../assets/images/stock-photos/stock-1.jpg';
// import stock2 from '../../../assets/images/stock-photos/stock-2.jpg';
// import stock3 from '../../../assets/images/stock-photos/stock-3.jpg';
import avatar5 from '../../../assets/images/avatars/avatar5.jpg';

// import profileImg001 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-001.png' ;
// import profileImg002 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-002.png' ;
// import profileImg003 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import profileImg004 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-004.png';
import profileImg005 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-005.png';
import profileImg006 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-006.png';
// import profileImg007 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-007.png' ;
// import profileImg008 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-008.png' ;
// import profileImg009 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-009.png' ;
// import profileImg011 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-011.png' ;
// import profileImg012 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-012.png' ;
// import profileImg015 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-015.png' ;
// import profileImg020 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-020.png' ;
// import profileImg022 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-022.png' ;
// import profileImg028 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-028.png' ;
// import profileImg031 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-031.png' ;
// import profileImg034 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-034.png' ;
// import profileImg037 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-037.png' ;
// import profileImg041 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-041.png' ;
// import profileImg042 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-042.png' ;
// import profileImg047 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-047.png' ;
// import profileImg048 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-048.png' ;
// import profileImg054 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-054.png' ;
// import profileImg060 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-060.png' ;
// import profileImg061 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-061.png' ;

export default function LivePreviewExample() {
  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <Button className="btn-github m-2 btn-icon hover-scale-lg btn-animated-icon d-50 p-0 border-0 rounded-lg">
                      <span className="btn-wrapper--icon d-flex">
                        <FontAwesomeIcon
                          icon={['fab', 'github']}
                          className="font-size-lg"
                        />
                      </span>
                    </Button>
                    <Button className="btn-twitter m-2 btn-icon hover-scale-lg btn-animated-icon d-50 p-0 border-0 rounded-lg">
                      <span className="btn-wrapper--icon d-flex">
                        <FontAwesomeIcon
                          icon={['fab', 'twitter']}
                          className="font-size-lg"
                        />
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-pill badge-primary">Contact</div>
                </div>
                <img
                  src={profileImg004}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <div className="avatar-icon-wrapper mx-auto mb-2">
                      <div className="avatar-icon">
                        <img alt="..." src={avatar5} />
                      </div>
                    </div>
                    <div className="font-size-lg font-weight-bold">
                      Scott C Stevenson
                    </div>
                    <div className="text-white-50 pb-4">
                      Web3 Legal Engineer
                    </div>
                    <Button
                      size="small"
                      className="btn-first btn-pill px-4 hover-scale-sm">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </span>
                      <span className="btn-wrapper--label">View Profile</span>
                    </Button>
                  </div>
                </div>
                <div className="card-badges">
                  <div className="badge badge-pill badge-primary text-white">
                    Supervisor
                  </div>
                </div>
                <img
                  src={profileImg005}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <Button
                      variant="text"
                      className="btn-outline-secondary shadow-sm-dark btn-pill d-inline-flex justify-content-center align-items-center border-2 p-0 d-40 m-1">
                      <FontAwesomeIcon icon={['fab', 'github']} />
                    </Button>
                    <Button
                      variant="text"
                      className="btn-outline-secondary shadow-sm-dark btn-pill d-inline-flex justify-content-center align-items-center border-2 p-0 d-40 m-1">
                      <FontAwesomeIcon icon={['fab', 'twitter']} />
                    </Button>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-primary">Contact</div>
                </div>
                <img
                  src={profileImg006}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <Button className="btn-github m-2 btn-icon hover-scale-lg btn-animated-icon d-50 p-0 border-0 rounded-lg">
                      <span className="btn-wrapper--icon d-flex">
                        <FontAwesomeIcon
                          icon={['fab', 'github']}
                          className="font-size-lg"
                        />
                      </span>
                    </Button>
                    <Button className="btn-twitter m-2 btn-icon hover-scale-lg btn-animated-icon d-50 p-0 border-0 rounded-lg">
                      <span className="btn-wrapper--icon d-flex">
                        <FontAwesomeIcon
                          icon={['fab', 'twitter']}
                          className="font-size-lg"
                        />
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-pill badge-primary">Contact</div>
                </div>
                <img
                  src={profileImg004}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <div className="avatar-icon-wrapper mx-auto mb-2">
                      <div className="avatar-icon">
                        <img alt="..." src={avatar5} />
                      </div>
                    </div>
                    <div className="font-size-lg font-weight-bold">
                      Scott C Stevenson
                    </div>
                    <div className="text-white-50 pb-4">
                      Web3 Legal Engineer
                    </div>
                    <Button
                      size="small"
                      className="btn-first btn-pill px-4 hover-scale-sm">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </span>
                      <span className="btn-wrapper--label">View Profile</span>
                    </Button>
                  </div>
                </div>
                <div className="card-badges">
                  <div className="badge badge-pill badge-primary text-white">
                    Supervisor
                  </div>
                </div>
                <img
                  src={profileImg005}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <Button
                      variant="text"
                      className="btn-outline-secondary shadow-sm-dark btn-pill d-inline-flex justify-content-center align-items-center border-2 p-0 d-40 m-1">
                      <FontAwesomeIcon icon={['fab', 'github']} />
                    </Button>
                    <Button
                      variant="text"
                      className="btn-outline-secondary shadow-sm-dark btn-pill d-inline-flex justify-content-center align-items-center border-2 p-0 d-40 m-1">
                      <FontAwesomeIcon icon={['fab', 'twitter']} />
                    </Button>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-primary">Contact</div>
                </div>
                <img
                  src={profileImg006}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <Button className="btn-github m-2 btn-icon hover-scale-lg btn-animated-icon d-50 p-0 border-0 rounded-lg">
                      <span className="btn-wrapper--icon d-flex">
                        <FontAwesomeIcon
                          icon={['fab', 'github']}
                          className="font-size-lg"
                        />
                      </span>
                    </Button>
                    <Button className="btn-twitter m-2 btn-icon hover-scale-lg btn-animated-icon d-50 p-0 border-0 rounded-lg">
                      <span className="btn-wrapper--icon d-flex">
                        <FontAwesomeIcon
                          icon={['fab', 'twitter']}
                          className="font-size-lg"
                        />
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-pill badge-primary">Contact</div>
                </div>
                <img
                  src={profileImg004}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <div className="avatar-icon-wrapper mx-auto mb-2">
                      <div className="avatar-icon">
                        <img alt="..." src={avatar5} />
                      </div>
                    </div>
                    <div className="font-size-lg font-weight-bold">
                      Scott C Stevenson
                    </div>
                    <div className="text-white-50 pb-4">
                      Web3 Legal Engineer
                    </div>
                    <Button
                      size="small"
                      className="btn-first btn-pill px-4 hover-scale-sm">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </span>
                      <span className="btn-wrapper--label">View Profile</span>
                    </Button>
                  </div>
                </div>
                <div className="card-badges">
                  <div className="badge badge-pill badge-primary text-white">
                    Supervisor
                  </div>
                </div>
                <img
                  src={profileImg005}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card className="shadow-xxl">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="card-img-wrapper rounded">
                <div className="img-wrapper-overlay">
                  <div className="overlay-btn-wrapper">
                    <Button
                      variant="text"
                      className="btn-outline-secondary shadow-sm-dark btn-pill d-inline-flex justify-content-center align-items-center border-2 p-0 d-40 m-1">
                      <FontAwesomeIcon icon={['fab', 'github']} />
                    </Button>
                    <Button
                      variant="text"
                      className="btn-outline-secondary shadow-sm-dark btn-pill d-inline-flex justify-content-center align-items-center border-2 p-0 d-40 m-1">
                      <FontAwesomeIcon icon={['fab', 'twitter']} />
                    </Button>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-primary">Contact</div>
                </div>
                <img
                  src={profileImg006}
                  className="card-img-top rounded"
                  alt="..."
                />
              </a>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
