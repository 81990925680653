import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Card, CardContent } from '@material-ui/core';

import Trend from 'react-trend';
import CountUp from 'react-countup';



import achieve001 from '../../../assets/images/badges2023/achieve-001-prefinal-200w.png';
import achieve002 from '../../../assets/images/badges2023/achieve-002-prefinal-200w.png';
import achieve003 from '../../../assets/images/badges2023/achieve-003-prefinal-200w.png';
import achieve004 from '../../../assets/images/badges2023/achieve-004-prefinal-200w.png';
// import achieve005 from '../../../assets/images/badges2023/achieve-005-prefinal-200w.png';
// import achieve006 from '../../../assets/images/badges2023/achieve-006-prefinal-200w.png';
// import achieve007 from '../../../assets/images/badges2023/achieve-007-prefinal-200w.png';
// import achieve008 from '../../../assets/images/badges2023/achieve-008-prefinal-200w.png';
// import achieve009 from '../../../assets/images/badges2023/achieve-009-prefinal-200w.png';
// import achieve010 from '../../../assets/images/badges2023/achieve-010-prefinal-200w.png';
// import achieve011 from '../../../assets/images/badges2023/achieve-011-prefinal-200w.png';
// import achieve012 from '../../../assets/images/badges2023/achieve-012-prefinal-200w.png';
// import achieve013 from '../../../assets/images/badges2023/achieve-013-prefinal-200w.png';
// import achieve014 from '../../../assets/images/badges2023/achieve-014-prefinal-200w.png';
// import achieve015 from '../../../assets/images/badges2023/achieve-015-prefinal-200w.png';
// import achieve016 from '../../../assets/images/badges2023/achieve-016-prefinal-200w.png';
// import achieve017 from '../../../assets/images/badges2023/achieve-017-prefinal-200w.png';
// import achieve018 from '../../../assets/images/badges2023/achieve-018-prefinal-200w.png';
// import achieve019 from '../../../assets/images/badges2023/achieve-019-prefinal-200w.png';
// import achieve020 from '../../../assets/images/badges2023/achieve-020-prefinal-200w.png';
// import achieve021 from '../../../assets/images/badges2023/achieve-021-prefinal-200w.png';
// import achieve022 from '../../../assets/images/badges2023/achieve-022-prefinal-200w.png';
// import achieve023 from '../../../assets/images/badges2023/achieve-023-prefinal-200w.png';
// import achieve024 from '../../../assets/images/badges2023/achieve-024-prefinal-200w.png';
// import achieve025 from '../../../assets/images/badges2023/achieve-025-prefinal-200w.png';
// import achieve026 from '../../../assets/images/badges2023/achieve-026-prefinal-200w.png';
// import achieve027 from '../../../assets/images/badges2023/achieve-027-prefinal-200w.png';
// import achieve028 from '../../../assets/images/badges2023/achieve-028-prefinal-200w.png';
// import achieve029 from '../../../assets/images/badges2023/achieve-029-prefinal-200w.png';
// import achieve030 from '../../../assets/images/badges2023/achieve-030-prefinal-200w.png';
// import achieve031 from '../../../assets/images/badges2023/achieve-031-prefinal-200w.png';
// import achieve032 from '../../../assets/images/badges2023/achieve-032-prefinal-200w.png';
// import achieve033 from '../../../assets/images/badges2023/achieve-033-prefinal-200w.png';
import leg001 from '../../../assets/images/badges2023/leg-001-prefinal-200w.png';
import leg002 from '../../../assets/images/badges2023/leg-002-prefinal-200w.png';
// import leg003 from '../../../assets/images/badges2023/leg-003-prefinal-200w.png';
// import leg004 from '../../../assets/images/badges2023/leg-004-prefinal-200w.png';
// import leg005 from '../../../assets/images/badges2023/leg-005-prefinal-200w.png';
// import leg006 from '../../../assets/images/badges2023/leg-006-prefinal-200w.png';
// import leg007 from '../../../assets/images/badges2023/leg-007-prefinal-200w.png';
// import leg008 from '../../../assets/images/badges2023/leg-008-prefinal-200w.png';
// import leg009 from '../../../assets/images/badges2023/leg-009-prefinal-200w.png';
// import leg010 from '../../../assets/images/badges2023/leg-010-prefinal-200w.png';
// import leg011 from '../../../assets/images/badges2023/leg-011-prefinal-200w.png';
// import leg012 from '../../../assets/images/badges2023/leg-012-prefinal-200w.png';
// import leg013 from '../../../assets/images/badges2023/leg-013-prefinal-200w.png';
// import leg014 from '../../../assets/images/badges2023/leg-014-prefinal-200w.png';
// import leg015 from '../../../assets/images/badges2023/leg-015-prefinal-200w.png';
// import leg016 from '../../../assets/images/badges2023/leg-016-prefinal-200w.png';
// import leg017 from '../../../assets/images/badges2023/leg-017-prefinal-200w.png';
// import leg018 from '../../../assets/images/badges2023/leg-018-prefinal-200w.png';
// import leg019 from '../../../assets/images/badges2023/leg-019-prefinal-200w.png';
// import leg020 from '../../../assets/images/badges2023/leg-020-prefinal-200w.png';
// import leg021 from '../../../assets/images/badges2023/leg-021-prefinal-200w.png';
// import leg022 from '../../../assets/images/badges2023/leg-022-prefinal-200w.png';
// import leg023 from '../../../assets/images/badges2023/leg-023-prefinal-200w.png';
// import leg024 from '../../../assets/images/badges2023/leg-024-prefinal-200w.png';
// import leg025 from '../../../assets/images/badges2023/leg-025-prefinal-200w.png';
import scale001 from '../../../assets/images/badges2023/scale-001-prefinal-200w.png';
import scale002 from '../../../assets/images/badges2023/scale-002-prefinal-200w.png';
// import scale003 from '../../../assets/images/badges2023/scale-003-prefinal-200w.png';
// import scale004 from '../../../assets/images/badges2023/scale-004-prefinal-200w.png';
// import scale005 from '../../../assets/images/badges2023/scale-005-prefinal-200w.png';
// import scale006 from '../../../assets/images/badges2023/scale-006-prefinal-200w.png';
// import scale007 from '../../../assets/images/badges2023/scale-007-prefinal-200w.png';
// import scale008 from '../../../assets/images/badges2023/scale-008-prefinal-200w.png';
// import scale009 from '../../../assets/images/badges2023/scale-009-prefinal-200w.png';
// import scale010 from '../../../assets/images/badges2023/scale-010-prefinal-200w.png';
// import scale011 from '../../../assets/images/badges2023/scale-011-prefinal-200w.png';
// import scale012 from '../../../assets/images/badges2023/scale-012-prefinal-200w.png';
// import scale013 from '../../../assets/images/badges2023/scale-013-prefinal-200w.png';
// import scale014 from '../../../assets/images/badges2023/scale-014-prefinal-200w.png';
import tri001 from '../../../assets/images/badges2023/tri-001-prefinal-200w.png';
import tri002 from '../../../assets/images/badges2023/tri-002-prefinal-200w.png';
// import tri003 from '../../../assets/images/badges2023/tri-003-prefinal-200w.png';
// import tri004 from '../../../assets/images/badges2023/tri-004-prefinal-200w.png';
// import tri005 from '../../../assets/images/badges2023/tri-005-prefinal-200w.png';
// import tri006 from '../../../assets/images/badges2023/tri-006-prefinal-200w.png';
// import tri007 from '../../../assets/images/badges2023/tri-007-prefinal-200w.png';
// import tri008 from '../../../assets/images/badges2023/tri-008-prefinal-200w.png';
// import tri009 from '../../../assets/images/badges2023/tri-009-prefinal-200w.png';
// import tri010 from '../../../assets/images/badges2023/tri-010-prefinal-200w.png';
// import tri011 from '../../../assets/images/badges2023/tri-011-prefinal-200w.png';
// import tri012 from '../../../assets/images/badges2023/tri-012-prefinal-200w.png';
// import tri013 from '../../../assets/images/badges2023/tri-013-prefinal-200w.png';
// import tri014 from '../../../assets/images/badges2023/tri-014-prefinal-200w.png';
// import tri015 from '../../../assets/images/badges2023/tri-015-prefinal-200w.png';
// import tri016 from '../../../assets/images/badges2023/tri-016-prefinal-200w.png';
// import tri017 from '../../../assets/images/badges2023/tri-017-prefinal-200w.png';
// import tri018 from '../../../assets/images/badges2023/tri-018-prefinal-200w.png';
// import tri019 from '../../../assets/images/badges2023/tri-019-prefinal-200w.png';
// import tri020 from '../../../assets/images/badges2023/tri-020-prefinal-200w.png';
// import tri021 from '../../../assets/images/badges2023/tri-021-prefinal-200w.png';
// import tri022 from '../../../assets/images/badges2023/tri-022-prefinal-200w.png';
// import tri023 from '../../../assets/images/badges2023/tri-023-prefinal-200w.png';
// import tri024 from '../../../assets/images/badges2023/tri-024-prefinal-200w.png';
// import tri025 from '../../../assets/images/badges2023/tri-025-prefinal-200w.png';
// import tri026 from '../../../assets/images/badges2023/tri-026-prefinal-200w.png';
// import tri027 from '../../../assets/images/badges2023/tri-027-prefinal-200w.png';





export default function LivePreviewExample() {
  return (
    <>
      <Card className="card-box mt-5 mb-spacing-6-x2">
        <div className="card-header-alt p-4">
          <h6 className="font-weight-bold font-size-lg mb-1 text-black">
            Current Achievments Status
          </h6>
          <p className="text-black-50 mb-0">
            Reports for what we sold this week.
          </p>
        </div>
        <div className="divider" />
        <CardContent className="pt-3 px-4 pb-4">
          <Table className="table table-alternate-spaced mb-0">
            <thead>
              <tr>
                <th className="text-left" style={{ width: '10%' }}>
                  Badge Design
                </th>
                <th className="text-center">Achievement Name</th>
                <th className="text-center">Issued</th>
                <th className="text-center">In-progress</th>
                <th className="text-left">Category</th>
                <th className="text-center" style={{ width: '15%' }}>
                  Issuance Trends
                </th>
                <th className="text-right">Achievement Income</th>
              </tr>
            </thead>

            <tbody>






              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 1 Skill"
                          src={achieve001}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 9 Skill">
                      2023 Web3Skills Journey 1
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-1: Web3 Addresses
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold ">54</span>
                </td>
                <td className="text-center">
                  <span className="text-primary font-weight-bold">374</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Starter Course</div>
                </td>
                <td>
                  <Trend
                    data={[0, 10, 5, 22, 3.6, 11]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={2675}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-down']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 2 Skill"
                          src={achieve002}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 1 Skill">
                      2023 Web3Skills Journey 2
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-2: Navigating a SmartLaw_API
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">86</span>
                </td>
                <td className="text-center">
                  <span className="text-primary font-weight-bold">310</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Starter Course</div>
                </td>
                <td>
                  <Trend
                    data={[3, 12, 5, 9, 8, 19, 4.6, 5]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={1950}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-down']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 3 Skill"
                          src={achieve003}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 10 Skill">
                      2023 Web3Skills Journey 3
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-3: Connecting a Web3 Account to a blockchain
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">356</span>
                </td>
                <td className="text-center">
                  <span className="text-primary">74</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Starter Course</div>
                </td>
                <td>
                  <Trend
                    data={[0, 10, 12, 14, 5, 22, 3.6, 11]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={2240}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 4 Skill"
                          src={achieve004}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 2 Skill">
                      2023 Web3Skills Journey 4
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-4: Execute a Blockchain Transaction
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">181</span>
                </td>
                <td className="text-center">
                  <span className="text-primary">64</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Intermediate</div>
                </td>
                <td>
                  <Trend
                    data={[9, 8, 19, 4.6, 3, 12, 5, 5]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={1550}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 5 Skill"
                          src={leg001}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 3 Skill">
                      2023 Web3Skills Journey 5
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-5: Explore Token Gating
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">281</span>
                </td>
                <td className="text-center">
                  <span className="text-primary">84</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Intermediate</div>
                </td>
                <td>
                  <Trend
                    data={[9, 8, 19, 4.6, 3, 12, 5, 5]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={1200}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 6 Skill"
                          src={leg002}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 4 Skill">
                      2023 Web3Skills Journey 6
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-6: Connect a Web3 Application
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">162</span>
                </td>
                <td className="text-center">
                  <span className="text-primary">684</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Intermediate</div>
                </td>
                <td>
                  <Trend
                    data={[9, 8, 19, 4.6, 3, 12, 5, 5]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={1600}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 7 Skill"
                          src={tri001}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 5 Skill">
                      2023 Web3Skills Journey 7
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-7: Query a Token and Account
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">162</span>
                </td>
                <td className="text-center">
                  <span className="text-primary">684</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Intermediate</div>
                </td>
                <td>
                  <Trend
                    data={[9, 8, 19, 4.6, 3, 12, 5, 5]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={1600}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 8 Skill"
                          src={tri002}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 6 Skill">
                      2023 Web3Skills Journey 8
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-8: Shallow Dive into DeFi
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">162</span>
                </td>
                <td className="text-center">
                  <span className="text-primary">684</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Intermediate</div>
                </td>
                <td>
                  <Trend
                    data={[9, 8, 19, 4.6, 3, 12, 5, 5]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={1600}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 9 Skill"
                          src={scale001}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 7 Skill">
                      2023 Web3Skills Journey 9
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-9: A look into the Metaverse
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">162</span>
                </td>
                <td className="text-center">
                  <span className="text-primary">684</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Intermediate</div>
                </td>
                <td>
                  <Trend
                    data={[9, 8, 19, 4.6, 3, 12, 5, 5]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={1600}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className="divider"></tr>
              <tr>
                <td>
                  <div className="avatar-icon-wrapper avatar-initials d-100 mr-1">
                    <div className="text-center py-1">
                      <div className="avatar-icon rounded-circle text-white d-100 bg-first">
                        <img
                          className="img-fit-container d-100 rounded-lg"
                          alt="Week 10 Skill"
                          src={scale002}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <a
                      href="https://web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold text-black"
                      title="Week 8 Skill">
                      2023 Web3Skills Journey 10
                    </a>
                    <span className="text-black-50 d-block">
                      Newb Level-10: Obtain a Player Avatar Wearable
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span className="font-weight-bold">162</span>
                </td>
                <td className="text-center">
                  <span className="text-primary">684</span>
                </td>
                <td className="text-left">
                  <div className="badge badge-first">Intermediate</div>
                </td>
                <td>
                  <Trend
                    data={[9, 8, 19, 4.6, 3, 12, 5, 5]}
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-in"
                    radius={15}
                    smooth
                    stroke="var(--first)"
                    strokeLinecap="round"
                    strokeWidth={5}
                  />
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="font-weight-bold font-size-lg pr-2">
                      <CountUp
                        start={0}
                        end={1600}
                        duration={6}
                        delay={0}
                        separator=","
                        decimals={0}
                        decimal=","
                        prefix="$"
                        suffix=" MATIC"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="font-size-sm opacity-5"
                    />
                  </div>
                </td>
              </tr>







            </tbody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}
