import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';


import BlocksProfilesSmall4 from '../../example-components/BlocksProfilesSmall/BlocksProfilesSmall4';
import BlocksProfilesSmall5 from '../../example-components/BlocksProfilesSmall/BlocksProfilesSmall5';
import BlocksProfilesSmall8 from '../../example-components/BlocksProfilesSmall/BlocksProfilesSmall8';



export default function Superv() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills Supervisor Profiles"
        titleDescription="Web3Skills SmartLaw offers Web3 based legal tech courses from legal engineers from LexDAO and other decentralized associations."
      />



      <ExampleWrapperSeamless>
        <BlocksProfilesSmall4 />
      </ExampleWrapperSeamless>


      <ExampleWrapperSeamless>
        <BlocksProfilesSmall5 />
      </ExampleWrapperSeamless>



      <ExampleWrapperSeamless>
        <BlocksProfilesSmall8 />
      </ExampleWrapperSeamless>



    </>
  );
}
