import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, HashRouter } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';

// Layout Blueprints
import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

// Example Pages
import Stats from './example-pages/Stats';
import Signups from './example-pages/Signups';
import Connect from './example-pages/Connect';
import Clecs from './example-pages/Clecs';
import Credcert from './example-pages/Credcert';
import Grmachine from './example-pages/Grmachine';
import Members from './example-pages/Members';
import Superv from './example-pages/Superv';
import BlocksProgressHorizontal from './example-pages/BlocksProgressHorizontal';
import Opstats from './example-pages/Opstats';
import Basicstats from './example-pages/Basicstats';
import Tokens from './example-pages/Tokens';
import Achieve from './example-pages/Achieve';
import Autosignup from './example-pages/Autosignup';
import Statereg from './example-pages/Statereg';
import ElementsNavigationMenus from './example-pages/ElementsNavigationMenus';
import Grmake from './example-pages/Grmake';
import Slcourses from './example-pages/Slcourses';
import Lexag from './example-pages/Lexag';
import Achengine from './example-pages/Achengine';
import Nav from './example-pages/Nav';
import Tstandards from './example-pages/Tstandards';
import MarketingHeaders from './example-pages/MarketingHeaders';
import About from './example-pages/About';
import Apps from './example-pages/Apps';
import Fedreg from './example-pages/Fedreg';
import MarketingPartners from './example-pages/MarketingPartners';
import Metaverse from './example-pages/Metaverse';
import Calendar from './example-pages/Calendar';
import Catalog from './example-pages/Catalog';
import Redes from './example-pages/Redes';




const Sltoks = lazy(() =>
  import('./example-pages/Sltoks')
);
const Forensics = lazy(() => import('./example-pages/Forensics'));
const Process = lazy(() =>
  import('./example-pages/Process')
);

const Livemarkets = lazy(() => import('./example-pages/Livemarkets'));
const Metadata = lazy(() => import('./example-pages/Metadata'));
const FormsClipboard = lazy(() => import('./example-pages/FormsClipboard'));
const Recolor = lazy(() => import('./example-pages/Recolor'));
const FormsDatepicker = lazy(() => import('./example-pages/FormsDatepicker'));
const Jury = lazy(() => import('./example-pages/Jury'));
const FormsInputMask = lazy(() => import('./example-pages/FormsInputMask'));
const FormsInputSelect = lazy(() => import('./example-pages/FormsInputSelect'));
const Membapp = lazy(() => import('./example-pages/Membapp'));
const Mint = lazy(() => import('./example-pages/Mint'));
const Grwalk = lazy(() =>
  import('./example-pages/Grwalk')
);
const FormsStepperScoring = lazy(() =>
  import('./example-pages/FormsStepperScoring')
);
const FormsTextareaAutosize = lazy(() =>
  import('./example-pages/FormsTextareaAutosize')
);
const FormsTimepicker = lazy(() => import('./example-pages/FormsTimepicker'));
const FormsTypeahead = lazy(() => import('./example-pages/FormsTypeahead'));
const Ddr = lazy(() => import('./example-pages/Ddr'));
const Claimant = lazy(() => import('./example-pages/Claimant'));
const Claim = lazy(() =>
  import('./example-pages/Claim')
);
const Login = lazy(() => import('./example-pages/Login'));
const PageLoginOverlay = lazy(() => import('./example-pages/PageLoginOverlay'));
const Did = lazy(() =>
  import('./example-pages/Did')
);
const PageRegisterOverlay = lazy(() =>
  import('./example-pages/PageRegisterOverlay')
);
const Rereq = lazy(() => import('./example-pages/Rereq'));
const Overview = lazy(() => import('./example-pages/Overview'));
const Pmdash = lazy(() => import('./example-pages/Pmdash'));
const Homelinks = lazy(() => import('./example-pages/Homelinks'));
const Calplus = lazy(() => import('./example-pages/Calplus'));
const Contmgr = lazy(() => import('./example-pages/Contmgr'));
const Profile = lazy(() => import('./example-pages/Profile'));
const Invoice = lazy(() => import('./example-pages/Invoice'));
const PageError404 = lazy(() => import('./example-pages/PageError404'));

const PageError505 = lazy(() => import('./example-pages/PageError505'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    Live previews can be slower than production builds.
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        ReactDOM.render(
        {/* <HashRouter basename="/#"> */}
        <HashRouter>

          <Suspense fallback={<SuspenseLoading />}>
            <Switch>
              <Redirect exact from="/" to="/Overview" />
              <Route path={['/Overview']}>
                <PresentationLayout>
                  {/* The rest of your app goes here */}

                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Overview" component={Overview} />
                    </motion.div>
                  </Switch>
                </PresentationLayout>
              </Route>

              <Route
                path={[
                  '/Login',
                  '/PageLoginOverlay',
                  '/Did',
                  '/PageRegisterOverlay',
                  '/Rereq',
                  '/PageError404',
                  '/PageError505'
                  // '/PageLoginIllustration',
                  // '/PageRegisterIllustration',
                  // '/PageRecoverIllustration',
                  // '/PageRecoverOverlay',
                ]}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route
                        path="/Login"
                        component={Login}
                      />
                      <Route
                        path="/PageLoginOverlay"
                        component={PageLoginOverlay}
                      />
                      <Route
                        path="/Did"
                        component={Did}
                      />
                      <Route
                        path="/PageRegisterOverlay"
                        component={PageRegisterOverlay}
                      />
                      <Route
                        path="/Rereq"
                        component={Rereq}
                      />
                      <Route path="/PageError404" component={PageError404} />
                      <Route path="/PageError505" component={PageError505} />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>

              <Route
                path={[
                  '/Achieve',
                  '/Tokens',
                  '/Autosignup',
                  '/Statereg',
                  '/ElementsNavigationMenus',
                  '/Grmake',
                  '/Slcourses',
                  '/Lexag',
                  '/Achengine',
                  '/Nav',
                  '/Tstandards',
                  '/Stats',
                  '/Signups',
                  '/Connect',
                  '/Clecs',
                  '/Credcert',
                  '/Grmachine',
                  '/Members',
                  '/Superv',
                  '/BlocksProgressHorizontal',
                  '/Opstats',
                  '/Basicstats',
                  '/MarketingHeaders',
                  '/About',
                  '/Apps',
                  '/Fedreg',
                  '/MarketingPartners',
                  '/Metaverse',
                  '/Calendar',
                  '/Catalog',
                  '/Redes',
                  '/Sltoks',
                  '/Forensics',
                  '/Process',
                  '/Livemarkets',
                  '/Metadata',
                  '/FormsClipboard',
                  '/Recolor',
                  '/FormsDatepicker',
                  '/Jury',
                  '/FormsInputMask',
                  '/FormsInputSelect',
                  '/Membapp',
                  '/Mint',
                  '/Grwalk',
                  '/FormsStepperScoring',
                  '/FormsTextareaAutosize',
                  '/FormsTimepicker',
                  '/FormsTypeahead',
                  '/Ddr',
                  '/Claimant',
                  '/Claim',
                  '/Pmdash',
                  '/Homelinks',
                  '/Invoice'
                ]}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route
                        path="/Achieve"
                        component={Achieve}
                      />
                      <Route
                        path="/Tokens"
                        component={Tokens}
                      />

                      <Route path="/Autosignup" component={Autosignup} />
                      <Route
                        path="/Statereg"
                        component={Statereg}
                      />
                      <Route
                        path="/ElementsNavigationMenus"
                        component={ElementsNavigationMenus}
                      />
                      <Route
                        path="/Grmake"
                        component={Grmake}
                      />
                      <Route
                        path="/Slcourses"
                        component={Slcourses}
                      />
                      <Route
                        path="/Lexag"
                        component={Lexag}
                      />
                      <Route
                        path="/Achengine"
                        component={Achengine}
                      />
                      <Route
                        path="/Nav"
                        component={Nav}
                      />
                      <Route
                        path="/Tstandards"
                        component={Tstandards}
                      />
                      <Route
                        path="/Stats"
                        component={Stats}
                      />
                      <Route
                        path="/Signups"
                        component={Signups}
                      />
                      <Route
                        path="/Connect"
                        component={Connect}
                      />
                      {/* <Route
                        path="/Slfaq"
                        component={Slfaq}
                      /> */}
                      <Route path="/Clecs" component={Clecs} />
                      <Route path="/Credcert" component={Credcert} />

                      <Route
                        path="/Grmachine"
                        component={Grmachine}
                      />
                      <Route
                        path="/Members"
                        component={Members}
                      />
                      <Route
                        path="/Superv"
                        component={Superv}
                      />
                      <Route
                        path="/BlocksProgressHorizontal"
                        component={BlocksProgressHorizontal}
                      />
                      <Route
                        path="/Opstats"
                        component={Opstats}
                      />
                      <Route
                        path="/Basicstats"
                        component={Basicstats}
                      />
                      <Route
                        path="/MarketingHeaders"
                        component={MarketingHeaders}
                      />

                      <Route path="/About" component={About} />

                      <Route
                        path="/Apps"
                        component={Apps}
                      />
                      <Route
                        path="/Fedreg"
                        component={Fedreg}
                      />
                      <Route
                        path="/MarketingPartners"
                        component={MarketingPartners}
                      />
                      <Route
                        path="/Metaverse"
                        component={Metaverse}
                      />
                      <Route
                        path="/Calendar"
                        component={Calendar}
                      />
                      <Route
                        path="/Catalog"
                        component={Catalog}
                      />
                      <Route
                        path="/Redes"
                        component={Redes}
                      />
                      <Route
                        path="/Sltoks"
                        component={Sltoks}
                      />
                      <Route
                        path="/Forensics"
                        component={Forensics}
                      />
                      <Route
                        path="/Process"
                        component={Process}
                      />
                      {/* <Route
                        path="/Faq"
                        component={Faq}
                      /> */}
                      <Route
                        path="/Livemarkets"
                        component={Livemarkets}
                      />
                      <Route path="/Metadata" 
                      component={Metadata} 
                      />
                      <Route
                        path="/FormsClipboard"
                        component={FormsClipboard}
                      />
                      <Route
                        path="/Recolor"
                        component={Recolor}
                      />
                      <Route
                        path="/FormsDatepicker"
                        component={FormsDatepicker}
                      />
                      <Route
                        path="/Jury"
                        component={Jury}
                      />
                      <Route
                        path="/FormsInputMask"
                        component={FormsInputMask}
                      />
                      <Route
                        path="/FormsInputSelect"
                        component={FormsInputSelect}
                      />
                      <Route path="/Membapp"
                        component={Membapp}
                        />
                      <Route path="/Mint" 
                      component={Mint} 
                      />
                      <Route
                        path="/Grwalk"
                        component={Grwalk}
                      />
                      <Route
                        path="/FormsStepperScoring"
                        component={FormsStepperScoring}
                      />
                      <Route
                        path="/FormsTextareaAutosize"
                        component={FormsTextareaAutosize}
                      />
                      <Route
                        path="/FormsTimepicker"
                        component={FormsTimepicker}
                      />
                      <Route
                        path="/FormsTypeahead"
                        component={FormsTypeahead}
                      />
                      <Route path="/Ddr" component={Ddr} />
                      <Route
                        path="/Claimant"
                        component={Claimant}
                      />
                      <Route
                        path="/Claim"
                        component={Claim}
                      />
                      <Route path="/Pmdash" component={Pmdash} />
                      <Route
                        path="/Homelinks"
                        component={Homelinks}
                      />
                      <Route path="/Invoice" component={Invoice} />




                      
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route
                path={['/Calplus', '/Contmgr', '/Profile']}>
                <CollapsedSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Calplus" component={Calplus} />
                      <Route
                        path="/Contmgr"
                        component={Contmgr}
                      />
                      <Route path="/Profile" component={Profile} />
                    </motion.div>
                  </Switch>
                </CollapsedSidebar>
              </Route>
            </Switch>
          </Suspense>
        </HashRouter>
        , root );
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
