import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  InputAdornment,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem,
  TextField
} from '@material-ui/core';

import achieve1 from '../../../assets/images/achievements/pawn_tokener-level_1.png';
import achieve2 from '../../../assets/images/achievements/renewer-level_2.png';
import achieve3 from '../../../assets/images/achievements/ethereum-level_3.png';
import achieve4 from '../../../assets/images/achievements/web3vm_user-level_3.png';
import achieve5 from '../../../assets/images/achievements/decentralizer-level_3.png';
import achieve6 from '../../../assets/images/achievements/airdrop_surprise-level_4.png';
import achieve7 from '../../../assets/images/achievements/account_maker-level_5.png';
import achieve8 from '../../../assets/images/achievements/web3vm-newb-6.png';

import nextachieve1 from '../../../assets/images/achievements/07-nodist-level_1.png';
import nextachieve2 from '../../../assets/images/achievements/08-transfer_tech-level_1.png';
import nextachieve3 from '../../../assets/images/achievements/20-team_player-level_1.png';
import nextachieve4 from '../../../assets/images/achievements/23-architect-level_1.png';
import nextachieve5 from '../../../assets/images/achievements/25-announcer-level_1.png';
import nextachieve6 from '../../../assets/images/achievements/31-networker-level_1.png';

import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

export default function LivePreviewExample() {
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xl={6} className="d-flex">
          <Card className="card-box w-100">
            <div className="card-header">
              <div className="card-header--title">
                <small className="d-block text-uppercase mt-1">Status</small>
                <b>In-progress Achievements (Levels 1 - 6)</b>
              </div>
              <div>
                <Button className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
                </Button>
              </div>
            </div>

            <List component="div" className="list-group-flush">
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                  <div className="text-center py-1">
                    <div className="avatar-icon rounded-circle text-white d-50 bg-first">
                      <img
                        className="img-fit-container d-50 rounded-lg"
                        alt="..."
                        src={achieve1}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    Pawn Tokener (DeFi)
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={10}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      Level 1 is the "Starter" Token
                    </div>
                    <div className="opacity-5">
                      90<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                  <div className="text-center py-1">
                    <div className="avatar-icon rounded-circle text-white d-50 bg-first">
                      <img
                        className="img-fit-container d-50 rounded-lg"
                        alt="..."
                        src={achieve2}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    Renewer Tokenomics
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={25}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      Level 2 is the "On My Way" Token
                    </div>
                    <div className="opacity-5">
                      75<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                  <div className="text-center py-1">
                    <div className="avatar-icon rounded-circle text-white d-50 bg-first">
                      <img
                        className="img-fit-container d-50 rounded-lg"
                        alt="..."
                        src={achieve3}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    Ethereum EVM Achievement
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={40}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      Level 3 "Getting There" Level Token
                    </div>
                    <div className="opacity-5">
                      60<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                  <div className="text-center py-1">
                    <div className="avatar-icon rounded-circle text-white d-50 bg-first">
                      <img
                        className="img-fit-container d-50 rounded-lg"
                        alt="..."
                        src={achieve4}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">Web3 Users</div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={40}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      Level 3 "Getting There" Level Token
                    </div>
                    <div className="opacity-5">
                      60<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>

              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                  <div className="text-center py-1">
                    <div className="avatar-icon rounded-circle text-white d-50 bg-first">
                      <img
                        className="img-fit-container d-50 rounded-lg"
                        alt="..."
                        src={achieve5}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    Decentralizer Achievement
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={40}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      Level 3 "Getting There" Level Token
                    </div>
                    <div className="opacity-5">
                      60<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>

              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                  <div className="text-center py-1">
                    <div className="avatar-icon rounded-circle text-white d-50 bg-first">
                      <img
                        className="img-fit-container d-50 rounded-lg"
                        alt="..."
                        src={achieve6}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    Airdrop Surprise Achievement
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs my-2 progress-bar-success"
                    value={60}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      Level 4 is the "Arrived" Level Token
                    </div>
                    <div className="opacity-5">
                      40<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                  <div className="text-center py-1">
                    <div className="avatar-icon rounded-circle text-white d-50 bg-first">
                      <img
                        className="img-fit-container d-50 rounded-lg"
                        alt="..."
                        src={achieve7}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    Account Maker Achievement
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={75}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      Level 5 is the "Made It" Level Token
                    </div>
                    <div className="opacity-5">
                      25<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>

              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                  <div className="text-center py-1">
                    <div className="avatar-icon rounded-circle text-white d-50 bg-first">
                      <img
                        className="img-fit-container d-50 rounded-lg"
                        alt="..."
                        src={achieve8}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="font-weight-bold text-black">
                    Newb Achievement
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-animated-alt my-2 progress-bar-success"
                    value={90}
                  />
                  <div className="d-flex justify-content-between font-size-xs">
                    <div className="text-primary">
                      Level 6 is the "Master" Level Token
                    </div>
                    <div className="opacity-5">
                      10<sup>%</sup> to go
                    </div>
                  </div>
                </div>
              </ListItem>
            </List>
          </Card>
        </Grid>

        <Grid item xl={6} className="d-flex">
          <Card className="card-box w-100">
            <div className="card-header">
              <div className="card-header--title">
                <small className="d-block text-uppercase mt-1">
                  Web3Skills SmartLaw
                </small>
                <b>Recommended Next Achievements</b>
              </div>
              <div className="avatar-icon-wrapper avatar-initials shadow-none d-40 mr-0">
                <div className="avatar-icon text-white bg-night-sky d-40 font-size-sm">
                  SS
                </div>
                <div
                  className="badge badge-warning badge-position badge-position--bottom-center badge-circle-inner"
                  title="Badge bottom center">
                  Online
                </div>
              </div>
            </div>
            <div
              className={clsx(
                'd-flex transition-base align-items-center justify-content-between py-2 px-4',
                { 'bg-secondary': !inputBg }
              )}>
              <div>
                <Button
                  size="small"
                  className="btn-link p-0 btn-transition-none btn-link-warning">
                  <span className="btn-wrapper--label font-size-sm">
                    Edit next achievements
                  </span>
                </Button>
              </div>
              <div className="font-size-sm font-weight-bold">
                Scott Stevenson
              </div>
            </div>
            <div className="divider" />
            <div
              className={clsx(
                'd-flex align-items-center transition-base px-4 py-1',
                { 'py-3 bg-secondary': inputBg }
              )}>
              <div className="search-wrapper w-100">
                <TextField
                  variant="outlined"
                  size="small"
                  className="bg-white w-100"
                  classes={{ root: 'input-border-0' }}
                  id="input-with-icon-textfield225-1"
                  placeholder="Search achievements..."
                  onFocus={toggleInputBg}
                  onBlur={toggleInputBg}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="divider" />
            <List component="div" className="list-group-flush">
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper d-50 mr-3">
                    <div className="avatar-icon rounded-circle d-50">
                      <img alt="..." src={nextachieve1} />
                    </div>
                  </div>
                  <div>
                    <div className="font-weight-bold font-size-sm text-black">
                      Nodist Achievement
                    </div>
                    <div className="d-flex align-items-center font-size-xs">
                      <div className="badge badge-first badge-circle border-white border-1 mr-2">
                        Completed
                      </div>
                      <div className="text-primary">
                        Learn the basics of node computing using blockchains and
                        content storage systems. Blockchains act as a
                        chronological ledger of all previous transactions stored
                        in order.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button size="small" className="btn-neutral-dark px-3">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'plus']} />
                    </span>
                    <span className="btn-wrapper--label">Enroll</span>
                  </Button>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper d-50 mr-3">
                    <div className="avatar-icon rounded-circle d-50">
                      <img alt="..." src={nextachieve2} />
                    </div>
                  </div>
                  <div>
                    <div className="font-weight-bold font-size-sm text-black">
                      Transfer Tech Achievement
                    </div>
                    <div className="d-flex align-items-center font-size-xs">
                      <div className="badge badge-first badge-circle border-white border-1 mr-2">
                        Completed
                      </div>
                      <div className="text-primary">
                        Apply practical methods of deploying and managing a
                        nodeJS based website and Web3 application. Course
                        incluses the Ethereum Virtual Machine, and the
                        Inter-planetary File System.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button size="small" className="btn-neutral-dark px-3">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'plus']} />
                    </span>
                    <span className="btn-wrapper--label">Enroll</span>
                  </Button>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper d-50 mr-3">
                    <div className="avatar-icon rounded-circle d-50">
                      <img alt="..." src={nextachieve3} />
                    </div>
                  </div>
                  <div>
                    <div className="font-weight-bold font-size-sm text-black">
                      Team Player Achievement
                    </div>
                    <div className="d-flex align-items-center font-size-xs">
                      <div className="badge badge-first badge-circle border-white border-1 mr-2">
                        Completed
                      </div>
                      <div className="text-primary">
                        Decentrally collaborate with others by joining a
                        Web3-based working group and rewarding each other
                        thought digitally recoreded achievements.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    size="small"
                    className="btn-neutral-dark px-3"
                    disabled>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'plus']} />
                    </span>
                    <span className="btn-wrapper--label">Enroll</span>
                  </Button>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper d-50 mr-3">
                    <div className="avatar-icon rounded-circle d-50">
                      <img alt="..." src={nextachieve4} />
                    </div>
                  </div>
                  <div>
                    <div className="font-weight-bold font-size-sm text-black">
                      Architect Achievement
                    </div>
                    <div className="d-flex align-items-center font-size-xs">
                      <div className="badge badge-first badge-circle border-white border-1 mr-2">
                        Completed
                      </div>
                      <div className="text-primary">
                        Learn the basics of using templates to shorten the
                        application develoipment time. Course includes Material
                        UI, Chakra UI with other popular front-end tools.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button size="small" className="btn-neutral-dark px-3">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'plus']} />
                    </span>
                    <span className="btn-wrapper--label">Enroll</span>
                  </Button>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper d-50 mr-3">
                    <div className="avatar-icon rounded-circle d-50">
                      <img alt="..." src={nextachieve5} />
                    </div>
                  </div>
                  <div>
                    <div className="font-weight-bold font-size-sm text-black">
                      Announcer Achievement
                    </div>
                    <div className="d-flex align-items-center font-size-xs">
                      <div className="badge badge-first badge-circle border-white border-1 mr-2">
                        Completed
                      </div>
                      <div className="text-primary">
                        All good projects need promotional support. This course
                        outlines some practical tools and ojectives to keep
                        projects visible in a crowded space.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button size="small" className="btn-neutral-dark px-3">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'plus']} />
                    </span>
                    <span className="btn-wrapper--label">Enroll</span>
                  </Button>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper d-50 mr-3">
                    <div className="avatar-icon rounded-circle d-50">
                      <img alt="..." src={nextachieve6} />
                    </div>
                  </div>
                  <div>
                    <div className="font-weight-bold font-size-sm text-black">
                      Network Achievement
                    </div>
                    <div className="d-flex align-items-center font-size-xs">
                      <div className="badge badge-first badge-circle border-white border-1 mr-2">
                        Completed
                      </div>
                      <div className="text-primary">
                        Most everyone now has a router in their home or
                        business. Learn the basic of IP addresses and ports that
                        your computer, browser, router, and modem use to
                        communicate with each other.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button size="small" className="btn-neutral-dark px-3">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'plus']} />
                    </span>
                    <span className="btn-wrapper--label">Enroll</span>
                  </Button>
                </div>
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
