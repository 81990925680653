import React from 'react';

import { PageTitle } from 'layout-components';
import { Container } from '@material-ui/core';



import Tables22 from '../../example-components/Pmdash/Tables22';



import ElementsTimelinesWebStateRegs from '../../example-components/Nav/TimelinesWebStateRegs';
import BlocksImages17 from '../../example-components/Credcert/BlocksImages17';

import { ExampleWrapperSeamless } from 'layout-components';

// https://airtable.com/appBBhQFafyI3oHIG/pagdr4kQMbBqMveLN?h2OVl=rec27PORA6CPRU9MM
// https://airtable.com/appMWDVEdxPz6SDFU/pag086Ozg9UMuqTcz?MZ9yD=recmvCyVWJTW5SjWd
// https://airtable.com/appMWDVEdxPz6SDFU/pagWgXY4NHYbQFcO0?W6bsu=recmvCyVWJTW5SjWd

// import Iframe from 'react-iframe';

export default function Statereg() {
  return (
    <>
      <PageTitle
        titleHeading="SmartLaw US State Legislation Resources"
        titleDescription="List state specific laws, legislation and regulations about and pertaining to cryptography including issuing digital tokens, using decentralized finance and non-fungible tokens in the United States of America."
      />

      <ExampleWrapperSeamless>
        <BlocksImages17 />
      </ExampleWrapperSeamless>

      <Container>
        <div className="p-1">
          <label className="font-weight-bold mb-2">SmartLaw Group</label>
          <h5 className="font-size-xl mb-1 font-weight-bold">
            State by State Regulations
          </h5>
          <p className="text-black-50 mb-4">
            &#40;may not be up-to-date, for general reference purposes ONLY&#41;
          </p>
        </div>
        <div className="mb-3"></div>

        <ExampleWrapperSeamless>
          <Tables22 />
        </ExampleWrapperSeamless>


        <ExampleWrapperSeamless>
          <ElementsTimelinesWebStateRegs />
        </ExampleWrapperSeamless>


      </Container>

      <hr />
    </>
  );
}
