import React from 'react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Button } from '@material-ui/core';

import product4 from '../../../assets/images/logos/stock-pro1.png';
import product5 from '../../../assets/images/logos/stock-pro2.png';
import product6 from '../../../assets/images/logos/stock-pro3.png';

export default function LivePreviewExample() {
  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item lg={4}>
            <Card className="d-flex flex-column card-box text-center">
              <div className="card-tr-actions">
              </div>
              <Card className="card-transparent mx-auto hover-scale-sm mt-3">
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="card-img-wrapper card-box-hover rounded">
                  <img
                    alt="..."
                    className="card-img-top rounded-sm"
                    src={product4}
                    style={{ width: 240 }}
                  />
                </a>
              </Card>
              <div className="card-header-alt d-flex flex-column justify-content-center p-3">
                <h6 className="font-weight-bold font-size-lg mb-2 text-black">
                Web3Skills Intro Courses 
                </h6>
                <p className="text-black-50 font-size-sm mb-0">
                  You can build unlimited layout styles using any of the 500+
                  included components and elements. Powerful, unique template
                  built for React and Material-UI.
                </p>
              </div>

              <div className="pb-3">
                <div className="badge badge-neutral-primary text-primary badge-pill font-weight-normal font-size-lg font-weight-bold h-auto py-2 px-3">
                  199 MATIC
                </div>
              </div>

              <div className="divider w-50 mx-auto" />

              <div className="py-2 d-flex align-items-center justify-content-center">
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-danger">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-first">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-warning">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-deep-blue">
                  &nbsp;
                </a>
              </div>

              <div className="divider w-50 mx-auto" />

              <div className="py-3">
                <Button className="btn-primary text-uppercase font-weight-bold font-size-xs">
                  Buy Now
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card className="d-flex flex-column card-box text-center">
              <div className="card-tr-actions">
              </div>
              <Card className="card-transparent mx-auto hover-scale-sm mt-3">
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="card-img-wrapper card-box-hover rounded">
                  <img
                    alt="..."
                    className="card-img-top rounded-sm"
                    src={product5}
                    style={{ width: 200 }}
                  />
                </a>
              </Card>
              <div className="card-header-alt d-flex flex-column justify-content-center p-3">
                <h6 className="font-weight-bold font-size-lg mb-2 text-black">
                  SmartLaw Token Minting Certification
                </h6>
                <p className="text-black-50 font-size-sm mb-0">
                  You can build unlimited layout styles using any of the 500+
                  included components and elements. Powerful, unique template
                  built for React and Material-UI.
                </p>
              </div>

              <div className="pb-3">
                <div className="badge badge-neutral-primary text-primary badge-pill font-weight-normal font-size-lg font-weight-bold h-auto py-2 px-3">
                  399 MATIC 
                </div>
              </div>

              <div className="divider w-50 mx-auto" />

              <div className="py-2 d-flex align-items-center justify-content-center">
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-danger">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-first">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-warning">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-deep-blue">
                  &nbsp;
                </a>
              </div>

              <div className="divider w-50 mx-auto" />

              <div className="py-3">
                <Button className="btn-primary text-uppercase font-weight-bold font-size-xs">
                  Buy Now
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card className="d-flex flex-column card-box text-center">
              <div className="card-tr-actions">
              </div>
              <Card className="card-transparent mx-auto hover-scale-sm mt-3">
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="card-img-wrapper card-box-hover rounded">
                  <img
                    alt="..."
                    className="card-img-top rounded-sm"
                    src={product6}
                    style={{ width: 220 }}
                  />
                </a>
              </Card>
              <div className="card-header-alt d-flex flex-column justify-content-center p-3">
                <h6 className="font-weight-bold font-size-lg mb-2 text-black">
                  Metaverse Pro - Starter Jam
                </h6>
                <p className="text-black-50 font-size-sm mb-0">
                  You can build unlimited layout styles using any of the 500+
                  included components and elements. Powerful, unique template
                  built for React and Material-UI.
                </p>
              </div>

              <div className="pb-3">
                <div className="badge badge-neutral-primary text-primary badge-pill font-weight-normal font-size-lg font-weight-bold h-auto py-2 px-3">
                  499 MATIC
                </div>
              </div>

              <div className="divider w-50 mx-auto" />

              <div className="py-2 d-flex align-items-center justify-content-center">
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-danger">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-first">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-warning">
                  &nbsp;
                </a>
                <a
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="m-2 d-20 rounded-circle btn-swatch bg-deep-blue">
                  &nbsp;
                </a>
              </div>

              <div className="divider w-50 mx-auto" />

              <div className="py-3">
                <Button className="btn-primary text-uppercase font-weight-bold font-size-xs">
                  Buy Now
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
