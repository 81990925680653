import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  InputAdornment,
  FormControlLabel,
  Typography,
  Checkbox,
  Tabs,
  Tab,
  LinearProgress,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';



import avatar1 from '../../../assets/images/avatars/ethereans-00136-w-hex-88-200x.png';
import avatar2 from '../../../assets/images/avatars/ethereans-00259-r-hex-103-200x.png';
import avatar3 from '../../../assets/images/avatars/ethereans-01011-t-hex-3F3-200x.png';
import avatar4 from '../../../assets/images/avatars/ethereans-01045-w-hex-415-200x.png';
import avatar5 from '../../../assets/images/avatars/ethereans-01410-r-hex-582-200x.png';
import avatar6 from '../../../assets/images/avatars/ethereans-02762-w-hex-ACA-200x.png';
import avatar7 from '../../../assets/images/avatars/ethereans-02893-t-hex-B4D-200x.png';
import avatar8 from '../../../assets/images/avatars/ethereans-04168-r-hex-1048-200x.png';
import avatar9 from '../../../assets/images/avatars/ethereans-04201-r-hex-1069-200x.png';
import avatar10 from '../../../assets/images/avatars/ethereans-04651-t-hex-122B-200x.png';
import avatar11 from '../../../assets/images/avatars/ethereans-04736-w-hex-1280-200x.png';
import avatar12 from '../../../assets/images/avatars/ethereans-04878-r-hex-130E-200x.png';
import avatar13 from '../../../assets/images/avatars/ethereans-04991-t-hex-137F-200x.png';
import avatar14 from '../../../assets/images/avatars/ethereans-05660-t-hex-161C-200x.png';
import avatar15 from '../../../assets/images/avatars/ethereans-05976-w-hex-1758-200x.png';
// import avatar16 from '../../../assets/images/avatars/ethereans-06824-w-hex-1AA8-200x.png';
// import avatar17 from '../../../assets/images/avatars/ethereans-07345-w-hex-1CB1-200x.png';
// import avatar18 from '../../../assets/images/avatars/ethereans-07758-t-hex-1E4E-200x.png';
// import avatar19 from '../../../assets/images/avatars/ethereans-08694-w-hex-21F6-200x.png';
// import avatar20 from '../../../assets/images/avatars/ethereans-09327-r-hex-246F-200x.png';
// import avatar21 from '../../../assets/images/avatars/ethereans-10697-r-hex-29C9-200x.png';
// import avatar22 from '../../../assets/images/avatars/ethereans-6016-w-hex-1780-200x.png';






import hero1 from '../../../assets/images/hero-bg/hero-1.jpg';

import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';

import Filter1TwoToneIcon from '@material-ui/icons/Filter1TwoTone';
import Filter2TwoToneIcon from '@material-ui/icons/Filter2TwoTone';
import Filter3TwoToneIcon from '@material-ui/icons/Filter3TwoTone';
import Filter4TwoToneIcon from '@material-ui/icons/Filter4TwoTone';
import Filter5TwoToneIcon from '@material-ui/icons/Filter5TwoTone';
import Filter6TwoToneIcon from '@material-ui/icons/Filter6TwoTone';
import Filter7TwoToneIcon from '@material-ui/icons/Filter7TwoTone';
import Filter8TwoToneIcon from '@material-ui/icons/Filter8TwoTone';
import Filter9TwoToneIcon from '@material-ui/icons/Filter9TwoTone';

import { Link } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function LivePreviewExample() {
  const [checked1, setChecked1] = useState(true);

  const handleChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [completed, setCompleted] = useState(0);

  React.useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Card className="card-box">
        <Grid container spacing={0}>
          <Grid item xl={5}>
            <div className="hero-wrapper bg-composed-wrapper bg-plum-plate h-100 m-4 m-xl-0 rounded-lg br-xl-right-0">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div
                  className="bg-composed-wrapper--image rounded-lg br-xl-right-0"
                  style={{ backgroundImage: 'url(' + hero1 + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-second opacity-5 rounded-lg br-xl-right-0" />
                <div className="bg-composed-wrapper--content p-4 p-lg-5">
                  <div className="d-flex align-items-center">
                    <div className="badge badge-pill bg-night-sky badge-first px-4 h-auto py-1">
                      Easy, Fast &#38; Free
                    </div>
                    <Tooltip
                      placement="right"
                      arrow
                      title="It's math and cryptography.">
                      <span className="text-white-50 pl-3" placement="right">
                        <FontAwesomeIcon icon={['far', 'question-circle']} />
                      </span>
                    </Tooltip>
                  </div>
                  <div className="text-white mt-3">
                    <h1 className="display-4 my-3 font-weight-bold text-white">
                      04 DAO Administration
                    </h1>
                    <p className="font-size-lg mb-0 text-white-50">
                      Connecting to Web3 is easier than most people think. Each
                      user must copy a private key to a safe place and then use
                      it to make access to Web3 applications and websites.
                    </p>
                    <div className="divider border-2 my-4 border-light opacity-2 rounded w-25" />
                    <div>
                      <Button className="hover-scale-lg btn-info bg-sunny-morning">
                        <span className="btn-wrapper--label text-primary">
                          <a
                            href="https://www.voxels.com/play?coords=NE@262E,13S"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-second">
                            Browse Web3 applications in the metaverse
                          </a>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero-footer pb-4">
                <List component="div" className="nav-transparent">
                  <Tooltip title="Twitter" arrow>
                    <ListItem
                      button
                      className="btn-link btn-icon d-40 p-0 font-size-lg text-white-50">
                      <FontAwesomeIcon icon={['fab', 'twitter']} />
                    </ListItem>
                  </Tooltip>

                  <Tooltip title="github" arrow>
                    <ListItem
                      button
                      className="btn-link btn-icon d-40 p-0 font-size-lg text-white-50">
                      <FontAwesomeIcon icon={['fab', 'github']} />
                    </ListItem>
                  </Tooltip>

                  <Tooltip title="Instagram" arrow>
                    <ListItem
                      button
                      className="btn-link btn-icon d-40 p-0 font-size-lg text-white-50">
                      <FontAwesomeIcon icon={['fab', 'discord']} />
                    </ListItem>
                  </Tooltip>
                </List>
              </div>
            </div>
          </Grid>
          <Grid item xl={7}>
            <div className="mt-4 mt-xl-0">
              <div className="tabs-bordered p-4">
                <Tabs
                  className="nav-tabs-primary"
                  value={value}
                  variant="fullWidth"
                  onChange={handleChange}>
                  <Tab label="Web3 Instructions" />
                  <Tab label="SmartLaw Tokens" />
                  <Tab label="Web3 Connect" />
                </Tabs>
              </div>
            </div>

            <div className="p-4 pt-0">
              <TabPanel value={value} index={0}>
                <div className="mb-spacing-6">
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <Card className="card-box">
                        <div className="card-indicator bg-first" />
                        <CardContent className="px-4 py-3">
                          <div className="pb-3 d-flex justify-content-between">
                            <a
                              href="https://freeweb3.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              Create an EVM Private Key
                            </a>
                          </div>
                          <div className="d-flex align-items-center justify-content-start">
                            <div className="badge badge-first px-3">
                              Starter Task
                            </div>
                            <div className="font-size-sm text-primary px-2">
                              <FontAwesomeIcon
                                icon={['far', 'clock']}
                                className="mr-1"
                              />
                              1:00 min.
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={6}>
                      <Card className="card-box">
                        <div className="card-indicator bg-primary" />
                        <CardContent className="px-4 py-3">
                          <div className="pb-3 d-flex justify-content-between">
                            <a
                              href="tokens.freeweb3.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              Mint an Access Token
                            </a>
                          </div>
                          <div className="d-flex align-items-center justify-content-start">
                            <div className="badge badge-primary px-3">
                              Intermediate
                            </div>
                            <div className="font-size-sm text-dark px-2">
                              <FontAwesomeIcon
                                icon={['far', 'clock']}
                                className="mr-1"
                              />
                              5:00 min
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>








                <div className="timeline-list mt-4">
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter1TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
deUNA
<div className="badge badge-neutral-info text-primary ml-2">
Admin
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://docs.google.com/document/d/1MvZlc7Kb3iCcxYOkNOEExKHDgpSvTZEpTcAUKdIqjGQ/edit"
rel="noopener noreferrer"
target="_blank">
Link 01 &nbsp;
</a>
<a
href="https://docs.google.com/document/d/1MvZlc7Kb3iCcxYOkNOEExKHDgpSvTZEpTcAUKdIqjGQ/edit"
rel="noopener noreferrer"
target="_blank">
~/docs.google.com/document/d/1
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter2TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
orangeCharter
<div className="badge badge-neutral-info text-primary ml-2">
Admin
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://docs.google.com/document/d/1W1mQkslRnkh89vu3kV_b9ksukv13pyaroWBzyzZ2esg/edit#heading=h.wnwdzl5hw7e9"
rel="noopener noreferrer"
target="_blank">
Link 02 &nbsp;
</a>
<a
href="https://docs.google.com/document/d/1W1mQkslRnkh89vu3kV_b9ksukv13pyaroWBzyzZ2esg/edit#heading=h.wnwdzl5hw7e9"
rel="noopener noreferrer"
target="_blank">
~/docs.google.com/document/d/1
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter3TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
LexDAO Independent Contractor Consulting Agreement Contract Vendor
<div className="badge badge-neutral-info text-primary ml-2">
Admin
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://docs.google.com/document/d/1XihzWyrNlPSU8BtnMP7eAqRM7Oms3atxxm4mqwOv__w/edit#"
rel="noopener noreferrer"
target="_blank">
Link 03 &nbsp;
</a>
<a
href="https://docs.google.com/document/d/1XihzWyrNlPSU8BtnMP7eAqRM7Oms3atxxm4mqwOv__w/edit#"
rel="noopener noreferrer"
target="_blank">
~/docs.google.com/document/d/1
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter4TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
2021 Rare Pizzas Payment Tracking
<div className="badge badge-neutral-info text-primary ml-2">
Admin
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://docs.google.com/spreadsheets/d/194FwIWkbaUbn0aPlkdB0b1gpipADUDZMeU8AwjzbwwM/edit#gid=1907882428"
rel="noopener noreferrer"
target="_blank">
Link 04 &nbsp;
</a>
<a
href="https://docs.google.com/spreadsheets/d/194FwIWkbaUbn0aPlkdB0b1gpipADUDZMeU8AwjzbwwM/edit#gid=1907882428"
rel="noopener noreferrer"
target="_blank">
~/docs.google.com/spreadsheets
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter5TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
Startup Operations Handbook
<div className="badge badge-neutral-info text-primary ml-2">
Admin
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://docs.google.com/document/d/1dXuYwyAXzsAUONCOZT-78zgIeQheeOipZQLRSN7Uw9A/edit#"
rel="noopener noreferrer"
target="_blank">
Link 05 &nbsp;
</a>
<a
href="https://docs.google.com/document/d/1dXuYwyAXzsAUONCOZT-78zgIeQheeOipZQLRSN7Uw9A/edit#"
rel="noopener noreferrer"
target="_blank">
~/docs.google.com/document/d/1
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter6TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
Forked from SillyTuna (opinion only)
<div className="badge badge-neutral-info text-primary ml-2">
Admin
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://docs.google.com/spreadsheets/d/1V38L5P4bMqcF8IDHNgC1mMzQ5FV9JXXA4qZq6B_k9ZQ/edit#gid=835671460"
rel="noopener noreferrer"
target="_blank">
Link 06 &nbsp;
</a>
<a
href="https://docs.google.com/spreadsheets/d/1V38L5P4bMqcF8IDHNgC1mMzQ5FV9JXXA4qZq6B_k9ZQ/edit#gid=835671460"
rel="noopener noreferrer"
target="_blank">
~/docs.google.com/spreadsheets
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter7TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
LexDAO form 1024 prep
<div className="badge badge-neutral-info text-primary ml-2">
Admin
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://docs.google.com/document/d/1eB8o9heTQNKL9IWPKwsm8UcOdqtZgLz7G88Gu1JS0V0/edit"
rel="noopener noreferrer"
target="_blank">
Link 07 &nbsp;
</a>
<a
href="https://docs.google.com/document/d/1eB8o9heTQNKL9IWPKwsm8UcOdqtZgLz7G88Gu1JS0V0/edit"
rel="noopener noreferrer"
target="_blank">
~/docs.google.com/document/d/1
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter8TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
LexDAO Coordination Document
<div className="badge badge-neutral-info text-primary ml-2">
Admin
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://docs.google.com/document/d/1DuSVUg70WzniTLjFea93h4vIh1yAZTiHTP_XAcYR5EI/edit#heading=h.myk9bui192bz"
rel="noopener noreferrer"
target="_blank">
Link 08 &nbsp;
</a>
<a
href="https://docs.google.com/document/d/1DuSVUg70WzniTLjFea93h4vIh1yAZTiHTP_XAcYR5EI/edit#heading=h.myk9bui192bz"
rel="noopener noreferrer"
target="_blank">
~/docs.google.com/document/d/1
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-8 text-white">
<span className="sidebar-icon text-white">
<Filter9TwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item—header mb-2 font-weight-bold text-primary">
Web3Skills SmartLaw Token Minter
<div className="badge badge-neutral-info text-primary ml-2">
XFR/MINT/QUERY
</div>
</h4>
<h4 className="timeline-item--label font-weight-bold text-secondary">
<a
href="https://tokens.freeweb3.com"
rel="noopener noreferrer"
target="_blank">
Link 09 &nbsp;
</a>
<a
href="https://tokens.freeweb3.com"
rel="noopener noreferrer"
target="_blank">
~/tokens.freeweb3.com
</a>
</h4>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-light-pure-darker text-white">
<span className="sidebar-icon text-white">
<AccountBoxTwoToneIcon />
</span>
</div>
</div>
<h4 className="timeline-item--label mb-2 font-weight-bold">
01 Membership Support
<div className="badge badge-neutral-info text-primary ml-2">
QUERY
</div>
</h4>
<h4 className="timeline-item--label mb-2 font-weight-bold">
Create an anonymous account for free.
<div className="badge badge-neutral-info text-primary ml-2">
It’s math.
</div>
</h4>
<h5
className=" mb-2 text-primary d-flex
">
Go to the &nbsp;
<Link
className="text-first font-weight-bold d-flex"
href="https://users.onelaw.us"
rel="noopener noreferrer"
target="_blank">
User Account maker
</Link>
&nbsp; website
</h5>
<p>
Right click and "inspect" the page. A private key
appears. Refresh your screen as many times as you want
to generate new key. &nbsp;{' '}
<span className="text-first font-weight-bold">
Important Note ! MAKE SURE TO COPY YOUR PRIVATE KEY IN
A SAFE PLACE because it will disappear on each
refresh. &nbsp;
</span>{' '}
This key only exists on your computer and once you
browser refreshes it will be lost forever. Make sure to
keep it in a safe place that cannot be accessed by any
unauthorized party.
</p>
<div className="avatar-wrapper-overlap mt-2 mb-1">
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar1} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar2} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar3} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar4} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar5} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar6} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar7} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar8} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar9} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar10} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar11} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar12} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar13} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar14} />
</div>
</div>
<div className="avatar-icon-wrapper avatar-icon-sm">
<div className="avatar-icon">
<img alt="member_avatar" src={avatar15} />
</div>
</div>
</div>
</div>
</div>
<div className="timeline-item timeline-item-icon">
<div className="timeline-item--content">
<div className="timeline-item--content">
<div className="timeline-item--icon-wrapper bg-primary opacity-9 text-white">
<span className="sidebar-icon text-white">
<AccountBoxTwoToneIcon />
</span>
</div>
</div>
<p>
Join Web3Skills for digital asset querying instructions.
</p>
<div className="mt-2">
<Button
size="small"
color="primary"
variant="contained">
<a
href="https://tokens.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
className="text-white">
01 Create a Digital Asset Now
</a>
</Button>
</div>
</div>
</div>
</div>
</TabPanel>














              <TabPanel value={value} index={1}>
                <div className="mb-spacing-6">
                  <Grid container spacing={6}>
                    <Grid item md={4}>
                      <Card className="card-box text-black-50 p-3">
                        <div className="display-3 text-black font-weight-bold">
                          310
                        </div>
                        <div className="divider mt-2 mb-3 border-2 w-25 bg-first rounded border-first" />
                        <div className="font-weight-bold font-size-sm text-uppercase">
                          Achievement
                          <br />
                          Tokens
                        </div>
                      </Card>
                    </Grid>
                    <Grid item md={4}>
                      <Card className="card-box text-black-50 p-3">
                        <div className="display-3 text-black font-weight-bold">
                          680
                        </div>
                        <div className="divider mt-2 mb-3 border-2 w-25 bg-success rounded border-success" />
                        <div className="font-weight-bold font-size-sm text-uppercase">
                          Access
                          <br />
                          Tokens
                        </div>
                      </Card>
                    </Grid>
                    <Grid item md={4}>
                      <Card className="card-box text-black-50 p-3">
                        <div className="display-3 text-black font-weight-bold">
                          357
                        </div>
                        <div className="divider mt-2 mb-3 border-2 w-25 bg-warning rounded border-warning" />
                        <div className="font-weight-bold font-size-sm text-uppercase">
                          Content
                          <br />
                          Tokens
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
                <Card className="card-box mb-4">
                  <CardContent>
                    <div className="text-left mb-3">
                      <div className="d-50 rounded border-0 mb-1 card-icon-wrapper bg-night-sky text-white btn-icon mx-auto text-center shadow-warning">
                        <FontAwesomeIcon
                          icon={['fas', 'landmark']}
                          className="display-4"
                        />
                      </div>

                      <div className="mt-3 line-height-sm">
                        <span className="text-black-50 pl-1">Over &nbsp;</span>
                        <b className="font-size-lg text-black">1,500</b>
                        <span className="text-black-50 pl-1">
                          SmartLaw tokens issued in 2022.
                        </span>
                      </div>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={completed}
                      className="progress-animated-alt progress-lg progress-bar-primary">
                      76%
                    </LinearProgress>
                  </CardContent>
                </Card>
                <div className="text-center">
                  <Button variant="text" className="btn-outline-primary">
                    Create new report
                  </Button>
                </div>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <Card className="shadow-none border-0">
                  <CardContent className="py-0">
                    <div className="card-header d-block p-3 pt-0 rounded bg-light">
                      <div className="text-muted text-center mb-3">
                        <small>Sign in with</small>
                      </div>
                      <div className="text-center">
                        <Button className="btn-google bg-premium-dark mr-4">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={['fab', 'ethereum']} />
                          </span>
                          <span className="btn-wrapper--label">Web3</span>
                        </Button>
                        <Button className="btn-success bg-vicious-stance mr-4">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={['fab', 'google']} />
                          </span>
                          <span className="btn-wrapper--label">Google</span>
                        </Button>
                        <Button className="btn-twitter bg-premium-dark">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={['fab', 'twitter']} />
                          </span>
                          <span className="btn-wrapper--label">Twitter</span>
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                  <CardContent>
                    <div className="text-center text-muted mb-3">
                      <small>Or sign in with credentials</small>
                    </div>
                    <div className="mb-3">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="textfield-email"
                        label="Email address (not required for Web3 login)."
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineTwoToneIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="textfield-password"
                        label="Password (not required for Web3 login)."
                        type="password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockTwoToneIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center font-size-md">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked1}
                            onChange={handleChange1}
                            value="checked1"
                            color="primary"
                          />
                        }
                        label="Remember me"
                      />
                      <div>
                        <a
                          href="https://freeweb3.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-first">
                          Recover password
                        </a>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        size="large"
                        className="font-weight-bold btn-second px-4 my-2">
                        Sign in
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </TabPanel>
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
