import React from 'react';

import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  List,
} from '@material-ui/core';

import profileImg012 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-012.png' ;
import profileImg047 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-047.png' ;
import profileImg062 from '../../../assets/images/legal-solutions/token-minter-9.png' ;




export default function LivePreviewExample() {
  return (
    <>
      <div className="bg-night-sky py-3 py-xl-5">
        <Container className="py-3 py-xl-5">
          <Card className="modal-content">
            <div className="card-header bg-night-skyer d-flex justify-content-center">
              <div className="text-center my-4">
                <h1 className="display-4 text-white mb-2 font-weight-bold">
                Metaverse Pro Plans &#38; Pricing
                </h1>
                <p className="font-size-lg mb-1 text-white">
                  Review and select the plan that works best for an introduction into the metaverse.
                </p>
              </div>
            </div>
            <CardContent className="p-3">
              <List
                component="div"
                className="nav-line mb-4 nav-tabs-primary d-flex align-items-center justify-content-center">
              </List>
              <div className="container-fluid">
                <Grid container spacing={6}>
                  <Grid item xl={4}>
                    <div className="divider-v divider-v-lg" />
                    <div className="py-3">
                      <div className="feature-box text-center mt-2 mb-5">
                        <img
                          src={profileImg062}
                          className="w-140 mx-auto d-block img-fluid"
                          alt="..."
                        />
                        <h3 className="font-size-xxl font-weight-bold mt-4">
                          Starter Jam
                        </h3>
                        <p className="text-black-50 mb-4">
                        This includes a live presentation of some of the newest Web3 applications and a Q&#38;A on how these applications are focused on specific use cases. This package inclused a self-guided journey with easy-to-follow lessons on basic Web3 applications. Lessons include transacting with a blockchain and saving content to nodes. Starter Jam includes 2-hour live consultation on how to implent basic Web3 feautures to the use-case.
                        </p>
                        <Button
                          href="#/"
                          target="_blank" 
rel="noopener noreferrer"

                          variant="text"
                          className="btn-first"
                          title="Learn more">
                          <span>Purchase now</span>
                        </Button>
                          <h3 className="font-size-xxl font-weight-bold mt-4 text-center">
                          475 MATIC
                        </h3>
                      </div>
                      <div className="divider my-4" />
                      <ul className="list-unstyled text-left font-weight-bold font-size-sm">
                      <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 1
                        </li>
                        <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 2
                        </li>
                        <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 3
                        </li>
                        <li className="px-4 py-2 text-black-50">
                          <div className="badge badge-primary badge-circle-inner mr-2">
                            Primary
                          </div>
                          Reward 1
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xl={4}>
                    <div className="divider-v divider-v-lg" />
                    <div className="py-3">
                      <div className="feature-box text-center mt-2 mb-5 ">
                        <img
                          style={{ height: 250 }}
                          src={profileImg047}
                          className="w-140 mx-auto d-block img-fluid"
                          alt="..."
                        />
                        <h3 className="font-size-xxl font-weight-bold mt-4">
                        Starter Jam + Lease
                        </h3>
                        <p className="text-black-50 mb-4">
                        Everything from the Starter Jam Package andwith a customized space in a leading Web3 metaverse. Select your location as you would for a convention or special event. Each client will recive a three 2-meter x 2-meter wall where source assets are placed with corresponing hyperlinks to the contents. Spaces can be accessed anonymously or through a Web3 connected account. The metaverse Lease Package also includes a live curated event to introcued the client's guests to learn about the metaverse. Live audio feed and either a YouTube or Twitch video feed is included.
                        </p>
                        <Button
                          href="#/"
                          target="_blank" 
rel="noopener noreferrer"

                          className="btn-first"
                          title="Learn more">
                          <span>Purchase now</span>
                        </Button>
                        <h3 className="font-size-xxl font-weight-bold mt-4 text-center">
                          2,500 MATIC
                        </h3>

                      </div>
                      <div className="divider my-4" />
                      <ul className="list-unstyled text-left font-weight-bold font-size-sm">
                        <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 1
                        </li>
                        <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 2
                        </li>
                        <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 3
                        </li>
                        <li className="px-4 py-2 text-black-50">
                          <div className="badge badge-primary badge-circle-inner mr-2">
                            Primary
                          </div>
                          Reward 1
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xl={4}>
                    <div className="py-3">
                      <div className="feature-box text-center mt-2 mb-5">
                        <img
                          style={{ height: 250 }}
                          src={profileImg012}
                          className="w-140 mx-auto d-block img-fluid"
                          alt="..."
                        />
                        <h3 className="font-size-xxl font-weight-bold mt-4">
                        Web3 Profiles &#38; Achievements
                        </h3>
                        <p className="text-black-50 mb-4">
                        A Web3 connected metaverse allows user to create their own access and asset account using private-key cryptography. User access information and content through an automated signing system verifying each user. This is usually called private-key encryption and is distinct from server-based authentication. With Web3, authentication is primarily a login and connecting exercise. While Web2 websites usually use an email address stored in a centralized server in combination with a password; Web3 appplications use a private key.
                        </p>
                        <Button
                          href="#/"
                          target="_blank" 
rel="noopener noreferrer"

                          variant="text"
                          className="btn-first"
                          title="Learn more">
                          <span>Purchase now</span>
                        </Button>
                        <h3 className="font-size-xxl font-weight-bold mt-4 text-center">
                          7,600 MATIC
                        </h3>
                      </div>
                      <div className="divider my-4" />
                      <ul className="list-unstyled text-left font-weight-bold font-size-sm">
                      <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 1
                        </li>
                        <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 2
                        </li>
                        <li className="px-4 py-2">
                          <div className="badge badge-success badge-circle-inner mr-2">
                            Success
                          </div>
                          Feature 3
                        </li>
                        <li className="px-4 py-2 text-black-50">
                          <div className="badge badge-primary badge-circle-inner mr-2">
                            Primary
                          </div>
                          Reward 1
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Container>
      </div>
    </>
  );
}
