import React, { useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Divider,
  Button,
  List,
  ListItem,
  TextField
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDropzone } from 'react-dropzone';
import CountUp from 'react-countup';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import hero1 from '../../../assets/images/hero-bg/hero-8.jpg';
import stock3 from '../../../assets/images/stock-photos/stock-6.jpg';
import stock1 from '../../../assets/images/stock-photos/stock-4.jpg';
import stock2 from '../../../assets/images/stock-photos/stock-5.jpg';
import people1 from '../../../assets/images/stock-photos/people-1.png';
import people3 from '../../../assets/images/stock-photos/people-3.png';
import people2 from '../../../assets/images/stock-photos/people-2.png';
import avatar1 from '../../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../../assets/images/avatars/avatar2.jpg';
import avatar3 from '../../../assets/images/avatars/avatar3.jpg';
import avatar4 from '../../../assets/images/avatars/avatar4.jpg';
import avatar5 from '../../../assets/images/avatars/avatar5.jpg';
import avatar6 from '../../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../../assets/images/avatars/avatar7.jpg';

import tri016 from '../../../assets/images/badges2023/tri-016-prefinal-200w.png';
import tri017 from '../../../assets/images/badges2023/tri-017-prefinal-200w.png';
import tri018 from '../../../assets/images/badges2023/tri-018-prefinal-200w.png';
import tri019 from '../../../assets/images/badges2023/tri-019-prefinal-200w.png';
import tri020 from '../../../assets/images/badges2023/tri-020-prefinal-200w.png';
import tri021 from '../../../assets/images/badges2023/tri-021-prefinal-200w.png';
import tri022 from '../../../assets/images/badges2023/tri-022-prefinal-200w.png';
import tri023 from '../../../assets/images/badges2023/tri-023-prefinal-200w.png';
import tri024 from '../../../assets/images/badges2023/tri-024-prefinal-200w.png';
import tri025 from '../../../assets/images/badges2023/tri-025-prefinal-200w.png';


export default function LivePreviewExample() {
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);
  const [activeTab, setActiveTab] = useState('1');

  const tabSwitch = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTab2, setActiveTab2] = useState('1');
  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };
  const [ setFiles] = useState([]);
  
  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  return (
    <>
      <div className="app-inner-content-layout">
        <div className="app-inner-content-layout--main bg-white p-0">
          <div className="hero-wrapper mx-5 rounded-bottom shadow-xxl bg-composed-wrapper bg-second">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div
                className="bg-composed-wrapper--image rounded-bottom opacity-3"
                style={{ backgroundImage: 'url(' + hero1 + ')' }}
              />
              <div className="bg-composed-wrapper--bg rounded-bottom bg-deep-sky opacity-4" />
              <div className="bg-composed-wrapper--content px-3 pt-5">
                <Container className="pt-4">







                  <div className="d-block d-md-flex align-items-start">
                    <div className="dropzone rounded-circle shadow-sm-dark mr-md-3">
                      <div
                        {...getRootProps({
                          className: 'dropzone-upload-wrapper'
                        })}>
                        <input {...getInputProps()} />
                     </div>
                    </div>

                    <div className="d-flex text-white flex-column pl-md-2">
                      <div className="d-block d-md-flex align-items-center">
                        <div className="my-3 my-md-0">
                          <div className="d-flex align-items-end">
                            <div className="font-size-xxl font-weight-bold text-warning">
                              Web3 LegalTech Grants
                            </div>
                          </div>
                          <div className="font-weight-bold mt-1 font-size-lg text-white-50">
                            from Web3 Legal Engineering
                          </div>
                        </div>
                        <div className="ml-auto">
                          <Button
                            size="small"
                            className="btn-first mr-4 shadow-none rounded-lg text-uppercase line-height-1 font-weight-bold font-size-xs px-3 w-auto py-0 d-40">
                            Profile
                          </Button>
                          <Button
                            size="small"
                            className="btn-first mr-4 shadow-none rounded-lg text-uppercase line-height-1 font-weight-bold font-size-xs px-3 w-auto py-0 d-40">
                            History
                          </Button>
                          <Button className="btn-icon rounded-lg shadow-none hover-scale-lg d-40 p-0 btn-warning">
                            <FontAwesomeIcon icon={['fas', 'plus']} />
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex font-size-xl py-4 align-items-center">
                        <div className="mr-2">
                          438{' '}
                          <span className="font-size-sm text-white-50">
                            projects
                          </span>
                        </div>
                        <div className="mr-2">
                          12,459{' '}
                          <span className="font-size-sm text-white-50">
                            messages
                          </span>
                        </div>
                        <div className="mr-2">
                          84{' '}
                          <span className="font-size-sm text-white-50">
                            issues
                          </span>
                        </div>
                      </div>
                      <div className="font-size-lg">
                        1 Web3Skills SmartLaw is a training tool and set of
                        protocols to advance the adoption of Web3 technologies.
                        Users can freely browse and use Web3 applications
                        anonymously or with decentralized profiles that capture
                        the spirit of the user. PP1
                      </div>
                    </div>
                  </div>

                  <List className="d-flex nav-tabs justify-content-center nav-tabs-warning tabs-animated tabs-animated-shadow my-5">
                    <ListItem
                      button
                      selected={activeTab2 === '1'}
                      className="bg-white-10 mx-2 rounded-lg"
                      onClick={() => {
                        toggle2('1');
                      }}>
                      <span className="font-size-lg text-white px-2 py-1">
                        Grants Dashboard
                      </span>
                      <div className="divider" />
                    </ListItem>
                    <ListItem
                      button
                      selected={activeTab2 === '2'}
                      className="bg-white-10 mx-2 rounded-lg"
                      onClick={() => {
                        toggle2('2');
                      }}>
                      <span className="font-size-lg text-white px-2 py-1">
                        Recent Posts
                      </span>
                      <div className="divider" />
                    </ListItem>
                    <ListItem
                      button
                      selected={activeTab2 === '3'}
                      className="bg-white-10 mx-2 rounded-lg"
                      onClick={() => {
                        toggle2('3');
                      }}>
                      <span className="font-size-lg text-white px-2 py-1">
                        Grant Timelines
                      </span>
                      <div className="divider" />
                    </ListItem>
                    <ListItem
                      button
                      selected={activeTab2 === '4'}
                      className="bg-white-10 mx-2 rounded-lg"
                      onClick={() => {
                        toggle2('4');
                      }}>
                      <span className="font-size-lg text-white px-2 py-1">
                        Grantees
                      </span>
                      <div className="divider" />
                    </ListItem>
                  </List>








                </Container>
              </div>
            </div>
          </div>

          <Container className="z-over py-5">


            <div
              className={clsx('tab-item-wrapper overflow-visible d-none', {
                'd-block active': activeTab2 === '1'
              })}
              index={1}>
              <div className="mb-spacing-6-x2">
                <Grid container spacing={6}>




                  
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'address-card']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'user']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'bullhorn']}
                            className="font-size-xl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-dark d-none d-xl-block">
                            Target achieved
                          </span>
                        </div>
                      </div>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Recent Proposals
                      </div>
                      <div className="opacity-7">Latest statistics</div>
                    </Card>
                  </Grid>

                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['far', 'address-card']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'user']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'plug']}
                            className="font-size-xl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-dark d-none d-xl-block">
                            Target achieved
                          </span>
                        </div>
                      </div>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Recent Disbursements
                      </div>
                      <div className="opacity-7">Latest statistics</div>
                    </Card>
                  </Grid>

                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['far', 'address-card']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'user']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>

                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'users']}
                            className="font-size-xl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Target achieved
                          </span>
                        </div>
                      </div>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Current Grantees
                      </div>
                      <div className="opacity-7">Latest statistics</div>
                      <br />
                    </Card>
                  </Grid>
                </Grid>
              </div>
















              <Divider className="p-1 mb-5" />
























              <Card className="card-box mb-spacing-6-x2">
                <Grid container spacing={0}>
                  <Grid item xl={4} className="p-4">
                    <div className="divider-v divider-v-lg" />

                    {/* <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Reports</b>
                        <div className="text-black-50">
                          Monthly sales reports
                        </div>
                      </div>
                      <div className="font-weight-bold text-danger font-size-xl">
                        <CountUp
                          start={1.0}
                          end={2.363}
                          duration={6}
                          delay={2}
                          separator=""
                          decimals={3}
                          decimal=","
                        />
                      </div>
                    </div> */}

                    <div className="divider my-3" />
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>SmartLaw Users</b>
                        <div className="text-black-50">Visitors last week</div>
                      </div>
                      <div className="font-weight-bold text-primary font-size-xl">
                        <CountUp
                          start={0}
                          end={987}
                          duration={6}
                          delay={2}
                          separator=""
                          decimals={0}
                          decimal=","
                        />
                      </div>
                    </div>

                    <div className="divider my-3" />
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Tokens Disbursed</b>
                        <div className="text-black-50">
                          Total average weekly report
                        </div>
                      </div>
                      <div className="font-weight-bold text-primary font-size-xl">
                        <CountUp
                          start={0}
                          end={483}
                          duration={6}
                          delay={2}
                          separator=""
                          decimals={0}
                          decimal=","
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xl={4} className="p-4">
                    <div className="divider-v divider-v-lg" />

                    {/* <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Stats</b>
                        <div className="text-black-50">Last month targets</div>
                      </div>
                      <div className="font-weight-bold text-warning font-size-xl">
                        $1,260 MATIC
                      </div>
                    </div> */}

                    <div className="divider my-3" />
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Rewards</b>
                        <div className="text-black-50">Weekly Pay-Out</div>
                      </div>
                      <div className="font-weight-bold text-primary font-size-xl">
                        1,305 MATIC
                      </div>
                    </div>

                    <div className="divider my-3" />
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Token Orders</b>
                        <div className="text-black-50">
                          Total 1155 Master Tokens Ordered
                        </div>
                      </div>
                      <div className="font-weight-bold text-primary font-size-xl">
                        65
                      </div>
                    </div>
                  </Grid>

                  <Grid item xl={4} className="p-4">
                    {/* <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Deliveries</b>
                        <div className="text-black-50">
                          Deliveries total report
                        </div>
                      </div>
                      <div className="font-weight-bold text-info font-size-xl">
                        $33,1k
                      </div>
                    </div> */}

                    <div className="divider my-3" />
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Token Roles Added</b>
                        <div className="text-black-50">
                          Total number of role holders
                        </div>
                      </div>
                      <div className="font-weight-bold text-primary font-size-xl">
                        34
                      </div>
                    </div>
                    <div className="divider my-3" />
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>SmartLaw Tokens Issued</b>
                        <div className="text-black-50">
                          Access &#38; Achievement tokens
                        </div>
                      </div>
                      <div className="font-weight-bold text-primary font-size-xl">
                        <CountUp
                          start={0}
                          end={685}
                          duration={6}
                          delay={2}
                          separator=""
                          decimals={0}
                          decimal=","
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
















              <div className="mb-spacing-6-x2">
                <Grid container spacing={6}>

                {/* <Grid item sm={12} md={12} lg={12} className="d-flex"> */}
                  <Grid item sm={12} md={6} lg={12}>
                    <Card className="shadow-xxl">
                      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
                        <div>
                          <h6 className="font-weight-bold font-size-lg mb-1 text-primary">
                            Recent DAO Activity
                          </h6>
                          <p className="text-black-50 mb-0">
                            Your recent digital asset activities
                          </p>
                        </div>
                        <ButtonGroup size="small" variant="text">
                          <Button
                            className={clsx('btn-primary btn-transition-none', {
                              active: activeTab === '1'
                            })}
                            onClick={() => {
                              tabSwitch('1');
                            }}>
                            Activity
                          </Button>
                          <Button
                            className={clsx('btn-primary btn-transition-none', {
                              active: activeTab === '2'
                            })}
                            onClick={() => {
                              tabSwitch('2');
                            }}>
                            Transactions
                          </Button>
                        </ButtonGroup>
                      </div>
                      <div className="divider" />
                      <div className="divider" />
                      <div
                        className={clsx('tab-item-wrapper', {
                          active: activeTab === '1'
                        })}
                        index={1}>
                        <div className="pl-3">
                          <div className="scroll-area shadow-overflow">
                            <PerfectScrollbar>
                              <div className="timeline-list timeline-list-offset timeline-list-offset-dot py-3">
                                <div className="timeline-item">
                                  <div className="timeline-item-offset">
                                    1 Dec
                                  </div>
                                  <div className="timeline-item--content">
                                    <div className="timeline-item--icon" />
                                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                                      <div className="badge badge-success">
                                        Organize
                                      </div>
                                    </h4>
                                    <p>
                                      Created <b>public/private key-pair</b> for{' '}
                                      <b>DAO use</b>.
                                    </p>
                                  </div>
                                </div>
                                <div className="timeline-item">
                                  <div className="timeline-item-offset">
                                    5 Dec
                                  </div>
                                  <div className="timeline-item--content">
                                    <div className="timeline-item--icon" />
                                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                                      Invite code sent
                                    </h4>
                                    <p>Your associates joined the platform.</p>
                                    <div className="avatar-wrapper-overlap mt-2 mb-1">
                                      <div className="avatar-icon-wrapper avatar-icon-sm">
                                        <div className="avatar-icon">
                                          <img alt="..." src={avatar1} />
                                        </div>
                                      </div>
                                      <div className="avatar-icon-wrapper avatar-icon-sm">
                                        <div className="avatar-icon">
                                          <img alt="..." src={avatar2} />
                                        </div>
                                      </div>
                                      <div className="avatar-icon-wrapper avatar-icon-sm">
                                        <div className="avatar-icon">
                                          <img alt="..." src={avatar6} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="timeline-item">
                                  <div className="timeline-item-offset">
                                    9 Dec
                                  </div>
                                  <div className="timeline-item--content">
                                    <div className="timeline-item--icon" />
                                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                                      Uploaded documents
                                    </h4>
                                    <p>
                                      Uploaded these documents onto the
                                      interface.
                                    </p>
                                    <div className="mt-3">
                                      <a
                                        href="#/"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img
                                          alt="..."
                                          className="img-fluid rounded mr-3 shadow-sm"
                                          src={people1}
                                          width="70"
                                        />
                                      </a>
                                      <a
                                        href="#/"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img
                                          alt="..."
                                          className="img-fluid rounded shadow-sm"
                                          src={people3}
                                          width="70"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="timeline-item">
                                  <div className="timeline-item-offset">
                                    Dec 11
                                  </div>
                                  <div className="timeline-item--content">
                                    <div className="timeline-item--icon" />
                                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                                      Profile verification
                                    </h4>
                                    <p>
                                      The required documents have been recorded.
                                    </p>
                                    <div className="mt-2">
                                      <Button
                                        size="small"
                                        className="btn-first">
                                        Submit remaining docs
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="timeline-item">
                                  <div className="timeline-item-offset">
                                    Dec 12
                                  </div>
                                  <div className="timeline-item--content">
                                    <div className="timeline-item--icon" />
                                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                                      Joined Web3Skills
                                    </h4>
                                    <p>
                                      Welcome to the Web3Skills. Enjoy the
                                      courses!
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </PerfectScrollbar>
                          </div>
                        </div>
                        <div className="card-footer p-3 text-center">
                          <Button
                            size="small"
                            className="py-2 px-4 btn-primary">
                            <span className="btn-wrapper--label text-uppercase font-weight-bold">
                              View all activity
                            </span>
                          </Button>
                        </div>
                      </div>
                      <div
                        className={clsx('tab-item-wrapper', {
                          active: activeTab === '2'
                        })}
                        index={2}>
                        <div className="scroll-area shadow-overflow">
                          <PerfectScrollbar>
                            <List component="div" className="list-group-flush">
                              <ListItem className="d-flex justify-content-between align-items-center py-3">
                                <div className="d-flex align-items-center mr-4">
                                  <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill font-size-lg mr-3 bg-primary">
                                    <FontAwesomeIcon
                                      className="font-size-lg text-white"
                                      icon={['fas', 'fingerprint']}
                                    />
                                  </div>
                                  <div>
                                    <div className="font-weight-bold">
                                      Received Bitcoin
                                    </div>
                                    <span className="text-black opacity-5 d-block">
                                      To <b>Bitcoin Wallet</b>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="text-right mr-3">
                                    <div className="font-weight-bold font-size-lg">
                                      0.234894 BTC
                                    </div>
                                    <div className="font-weight-bold text-black opacity-4">
                                      $4,123
                                    </div>
                                  </div>
                                </div>
                              </ListItem>
                              <ListItem className="d-flex justify-content-between align-items-center py-3">
                                <div className="d-flex align-items-center mr-4">
                                  <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill font-size-lg mr-3 bg-primary">
                                    <FontAwesomeIcon
                                      className="font-size-lg text-white"
                                      icon={['fas', 'fingerprint']}
                                    />
                                  </div>
                                  <div>
                                    <div className="font-weight-bold">
                                      Sent Ethereum
                                    </div>
                                    <span className="text-black opacity-5 d-block">
                                      To <b>Main Treasury</b>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="text-right mr-3">
                                    <div className="font-weight-bold font-size-lg">
                                      1.3984 ETH
                                    </div>
                                    <div className="font-weight-bold text-black opacity-4">
                                      $1,776 USD
                                    </div>
                                  </div>
                                </div>
                              </ListItem>
                              <ListItem className="d-flex justify-content-between align-items-center py-3">
                                <div className="d-flex align-items-center mr-4">
                                  <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill font-size-lg mr-3 bg-primary">
                                    <FontAwesomeIcon
                                      className="font-size-lg text-white"
                                      icon={['fas', 'fingerprint']}
                                    />
                                  </div>
                                  <div>
                                    <div className="font-weight-bold">
                                      Withdraw to bank account
                                    </div>
                                    <span className="text-black opacity-5 d-block">
                                      From <b>Main Treasury</b>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="text-right mr-3">
                                    <div className="font-weight-bold text-first font-size-lg">
                                      $23,549 USD
                                    </div>
                                  </div>
                                </div>
                              </ListItem>
                              <ListItem className="py-4 text-center text-black-50 d-block">
                                You've reached the end of the list!
                              </ListItem>
                            </List>
                          </PerfectScrollbar>
                        </div>
                        <div className="card-footer p-3 text-center">
                          <Button
                            size="small"
                            className="py-2 px-4 btn-primary">
                            <span className="btn-wrapper--label text-uppercase font-weight-bold">
                              View all transactions
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Grid>








                  <Grid item sm={12} md={6} lg={12}>
                    <Card className="overflow-visible shadow-xxl w-100">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="card-header--title">
                        <small className="d-block text-uppercase mt-1">
                            Messages
                          </small>
                          <b>Messenger Window</b>
                        </div>                        
                        <div className="avatar-icon-wrapper avatar-initials shadow-none d-40 mr-0">
                          <div className="avatar-icon text-white bg-night-sky d-40 font-size-sm">
                            LEX
                          </div>
                          <div
                            className="badge badge-warning badge-position badge-position--bottom-center badge-circle-inner"
                            title="Badge bottom center">
                            Online
                          </div>
                        </div>
                      </div>
                      <div
                        className={clsx(
                          'd-flex transition-base align-items-center justify-content-between py-2 px-4',
                          { 'bg-secondary': !inputBg }
                        )}>
                        <div>
                          <Button
                            size="small"
                            className="btn-link p-0 btn-transition-none btn-link-danger">
                            <span className="btn-wrapper--label font-size-sm">
                              Delete all
                            </span>
                          </Button>
                        </div>
                        <div className="font-size-sm font-weight-bold">
                          SmartLaw Grants Committee
                        </div>
                      </div>
                      <div className="divider" />
                      <div
                        className={clsx(
                          'd-flex align-items-center transition-base px-4 py-1',
                          { 'py-3 bg-secondary': inputBg }
                        )}>
                        <div className="search-wrapper w-100">
                          <TextField
                            variant="outlined"
                            size="small"
                            className="bg-white w-100"
                            classes={{ root: 'input-border-0' }}
                            id="input-with-icon-textfield225-1"
                            placeholder="Search messages..."
                            onFocus={toggleInputBg}
                            onBlur={toggleInputBg}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchTwoToneIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className="divider" />
                      <List component="div" className="list-group-flush">
                        <ListItem
                          component="a"
                          button
                          href="#/"
                          disableRipple
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper d-50 mr-3">
                              <div className="avatar-icon rounded-circle d-50">
                                <img alt="..." src={avatar7} />
                              </div>
                            </div>
                            <div>
                              <div className="font-weight-bold font-size-sm text-black">
                                Committee Member 1
                              </div>
                              <div className="d-flex align-items-center font-size-xs">
                                <div className="badge badge-success badge-circle border-white border-1 mr-2">
                                  Completed
                                </div>
                                <div className="text-success">Online</div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button
                              size="small"
                              className="btn-neutral-dark px-3">
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fas', 'plus']} />
                              </span>
                              <span className="btn-wrapper--label">Add</span>
                            </Button>
                          </div>
                        </ListItem>
                        <ListItem
                          component="a"
                          button
                          href="#/"
                          disableRipple
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper d-50 mr-3">
                              <div className="avatar-icon rounded-circle d-50">
                                <img alt="..." src={avatar6} />
                              </div>
                            </div>
                            <div>
                              <div className="font-weight-bold font-size-sm text-black">
                                Committee Member 2
                              </div>
                              <div className="d-flex align-items-center font-size-xs">
                                <div className="badge badge-success badge-circle border-white border-1 mr-2">
                                  Completed
                                </div>
                                <div className="text-success">Online</div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button
                              size="small"
                              className="btn-neutral-dark px-3">
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fas', 'plus']} />
                              </span>
                              <span className="btn-wrapper--label">Add</span>
                            </Button>
                          </div>
                        </ListItem>
                        <ListItem
                          component="a"
                          button
                          href="#/"
                          disableRipple
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper d-50 mr-3">
                              <div className="avatar-icon rounded-circle d-50">
                                <img alt="..." src={avatar5} />
                              </div>
                            </div>
                            <div>
                              <div className="font-weight-bold font-size-sm text-black">
                                Committee Member 3
                              </div>
                              <div className="d-flex align-items-center font-size-xs">
                                <div className="badge badge-danger badge-circle border-white border-1 mr-2">
                                  Completed
                                </div>
                                <div className="text-danger">Offline</div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button
                              size="small"
                              className="btn-neutral-dark px-3"
                              disabled>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fas', 'plus']} />
                              </span>
                              <span className="btn-wrapper--label">Add</span>
                            </Button>
                          </div>
                        </ListItem>
                        <ListItem
                          component="a"
                          button
                          href="#/"
                          disableRipple
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper d-50 mr-3">
                              <div className="avatar-icon rounded-circle d-50">
                                <img alt="..." src={avatar4} />
                              </div>
                            </div>
                            <div>
                              <div className="font-weight-bold font-size-sm text-black">
                                Committee Member 4
                              </div>
                              <div className="d-flex align-items-center font-size-xs">
                                <div className="badge badge-warning badge-circle border-white border-1 mr-2">
                                  Completed
                                </div>
                                <div className="text-warning">In a meeting</div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button
                              size="small"
                              className="btn-neutral-dark px-3">
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fas', 'plus']} />
                              </span>
                              <span className="btn-wrapper--label">Add</span>
                            </Button>
                          </div>
                        </ListItem>
                      </List>
                    </Card>
                  </Grid>
                </Grid>





                <Divider className="mb-5" />










                <Grid item sm={12} md={12} lg={12}>





                    <Card className="overflow-visible shadow-xxl w-100">



                      <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="card-header--title">
                        <small className="d-block text-uppercase mt-1">
                            Intergrations and Interfaces
                          </small>
                          <b>Interactive Forms & Reports</b>
                        </div>
                        <div className="avatar-icon-wrapper avatar-initials shadow-none d-40 mr-0">
                          <div className="avatar-icon text-white bg-night-sky d-40 font-size-sm">
                            LEX
                          </div>
                          <div
                            className="badge badge-warning badge-position badge-position--bottom-center badge-circle-inner"
                            title="Badge bottom center">
                            Online
                          </div>
                        </div>
                      </div>
                      <div
                        className={clsx(
                          'd-flex transition-base align-items-center justify-content-between py-2 px-4',
                          { 'bg-secondary': !inputBg }
                        )}>
                        <div>
                          <Button
                            size="small"
                            className="btn-link p-0 btn-transition-none btn-link-primary">
                            <span className="btn-wrapper--label font-size-sm">
                              Refresh List
                            </span>
                          </Button>
                        </div>
                        <div className="font-size-sm font-weight-bold">
                          SmartLaw Grants Application Preparation and Processing
                        </div>
                      </div>
                      <div className="divider" />
                      <div
                        className={clsx(
                          'd-flex align-items-center transition-base px-4 py-1',
                          { 'py-3 bg-secondary': inputBg }
                        )}>
                        <div className="search-wrapper w-100">
                          <TextField
                            variant="outlined"
                            size="small"
                            className="bg-white w-100"
                            classes={{ root: 'input-border-0' }}
                            id="input-with-icon-textfield225-1"
                            placeholder="Search messages..."
                            onFocus={toggleInputBg}
                            onBlur={toggleInputBg}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchTwoToneIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className="divider" />
                      <List component="div" className="list-group-flush">








                      <ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/pagaHETpAxvPUfKc1?6GHoL=rec27PORA6CPRU9MM"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri016} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
01 - Decentralized ID
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/pagtRr5oQFWh5t9Ah?xCXE1=reccoKR7tjcfaNmVm"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri017} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
02 – Grantee Profile & Organization Background
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/pagtYSBq6fMYPaa2y?yPL0B=recvxckNgpLEGFWST"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri018} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
03 – FastTrack Grant Application
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/pagsmmHnCGOuvOKnV?Shljt=rec8F26MNZ0Mv1UVv"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri019} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
04 – Grant Profile
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/pagAPpmEA2vIU8LC7?nWE6R=recy1ndocFnhe8OtU"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri020} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
05 – Applicant Organization Information
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/pag0hfRqDs9lIt91q?hvfs4=recVuwqSa3hDqHhPG"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri021} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
06 – Community Information
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/pagDmeHhXwWB4wkxQ?Ppzmg=reccisIoJTaljs3II"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri022} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
07 – Project Goals & Objectives
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/paglGMUnuw9GT0NkP?GsOsF=recw6Mv7Skmp90FfX"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri023} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
08 – Needs & Project Specifics
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/paglC7EbIgvZzjpfn?yk8jY=recnz0Qgj9bjuMmuo"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri024} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
09 – Expected Outcomes
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://airtable.com/appBBhQFafyI3oHIG/pagvP6J8Gr9JeNlM6?3zgzb=recrFjKADl9LLNLTz"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper d-50 mr-3">
<div className="avatar-icon rounded-circle d-50">
<img alt="..." src={tri025} />
</div>
</div>
<div>
<div className="font-weight-bold font-size-sm text-black">
10 - Signature Blocks
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="badge badge-success badge-circle border-white border-1 mr-2">
Completed
</div>
<div className="text-success">Completed</div>
</div>
</div>
</div>
<div>
<Button
size="small"
className="btn-neutral-dark px-3">
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'plus']} />
</span>
<span className="btn-wrapper—label ml-1">Add/Edit</span>
</Button>
</div>
</ListItem>













                      </List>
                    </Card>
                  </Grid>



































              </div>
            </div>












































            <div
              className={clsx('tab-item-wrapper overflow-visible d-none', {
                'd-block active': activeTab2 === '4'
              })}
              index={4}>
              <Grid container spacing={6}>
                <Grid item xl={6}>
                  <Card className="card-box p-4">
                    <div className="d-flex align-items-center justify-content-between py-2">
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper d-50 mr-3 avatar-initials">
                          <div className="avatar-icon rounded-circle d-50 shadow-sm font-weight-normal text-white bg-danger">
                            TS
                          </div>
                        </div>
                        <div>
                          <a
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold"
                            title="...">
                            Scott 3 Stevenson
                          </a>
                          <span className="text-black-50 d-block">
                            SmartLaw_API Content Builder
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="badge rounded-circle badge-neutral-success text-success d-30 btn-icon p-0 mr-1">
                          <FontAwesomeIcon icon={['fas', 'check']} />
                        </div>
                        <span className="font-size-xs text-success">
                          Profile Verified
                        </span>
                      </div>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <div className="text-center">
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'eye']} />
                        </span>
                      </Button>
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'heart']} />
                        </span>
                      </Button>
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'user']} />
                        </span>
                      </Button>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <Grid container spacing={6} className="text-center">
                      <Grid item sm={4}>
                        <div className="text-black-50">Projects</div>
                        <b className="font-size-lg">381</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Reviews</div>
                        <b className="font-size-lg">129</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Revenue</div>
                        <b className="font-size-lg">$691</b>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xl={6}>
                  <Card className="card-box p-4">
                    <div className="d-flex align-items-center justify-content-between py-2">
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper d-50 mr-3">
                          <div className="avatar-icon rounded-circle d-50 shadow-sm">
                            <img alt="..." src={avatar2} />
                          </div>
                        </div>
                        <div>
                          <a
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold"
                            title="...">
                            Grantee Ceee
                          </a>
                          <span className="text-black-50 d-block">
                            SmartLaw_API Content Builder
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="badge rounded-circle badge-neutral-warning text-warning d-30 btn-icon p-0 mr-1">
                          <FontAwesomeIcon icon={['far', 'clock']} />
                        </div>
                        <span className="font-size-xs text-warning">
                          Review Pending
                        </span>
                      </div>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <div className="text-center">
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'eye']} />
                        </span>
                      </Button>
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'heart']} />
                        </span>
                      </Button>
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'user']} />
                        </span>
                      </Button>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <Grid container spacing={6} className="text-center">
                      <Grid item sm={4}>
                        <div className="text-black-50">Projects</div>
                        <b className="font-size-lg">34</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Reviews</div>
                        <b className="font-size-lg">21</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Revenue</div>
                        <b className="font-size-lg">$283</b>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xl={6}>
                  <Card className="card-box p-4">
                    <div className="d-flex align-items-center justify-content-between py-2">
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper d-50 mr-3">
                          <div className="avatar-icon rounded-circle d-50 shadow-sm">
                            <img alt="..." src={avatar7} />
                          </div>
                        </div>
                        <div>
                          <a
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold"
                            title="...">
                            Grantee Geee
                          </a>
                          <span className="text-black-50 d-block">
                            Lead UX Designer, Spotify
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="badge rounded-circle badge-neutral-danger text-danger d-30 btn-icon p-0 mr-1">
                          <FontAwesomeIcon icon={['fas', 'times']} />
                        </div>
                        <span className="font-size-xs text-danger">
                          Rejected
                        </span>
                      </div>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <div className="text-center">
                      <Button
                        disabled
                        className="p-0 d-30 btn-icon rounded-sm border-0 mx-1 btn-transition-none btn-outline-primary">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'eye']} />
                        </span>
                      </Button>
                      <Button
                        disabled
                        className="p-0 d-30 btn-icon rounded-sm border-0 mx-1 btn-transition-none btn-outline-primary">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'heart']} />
                        </span>
                      </Button>
                      <Button
                        disabled
                        className="p-0 d-30 btn-icon rounded-sm border-0 mx-1 btn-transition-none btn-outline-primary">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'user']} />
                        </span>
                      </Button>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <Grid
                      container
                      spacing={6}
                      className="text-center opacity-6">
                      <Grid item sm={4}>
                        <div className="text-black-50">Projects</div>
                        <b className="font-size-lg">0</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Reviews</div>
                        <b className="font-size-lg">0</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Revenue</div>
                        <b className="font-size-lg">$0</b>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xl={6}>
                  <Card className="card-box p-4">
                    <div className="d-flex align-items-center justify-content-between py-2">
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper d-50 mr-3 avatar-initials">
                          <div className="avatar-icon rounded-circle d-50 shadow-sm font-weight-normal text-white bg-danger">
                            TS
                          </div>
                        </div>
                        <div>
                          <a
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold"
                            title="...">
                            Grantee Beee
                          </a>
                          <span className="text-black-50 d-block">
                            SmartLaw_API Content Builder
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="badge rounded-circle badge-neutral-success text-success d-30 btn-icon p-0 mr-1">
                          <FontAwesomeIcon icon={['fas', 'check']} />
                        </div>
                        <span className="font-size-xs text-success">
                          Profile Verified
                        </span>
                      </div>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <div className="text-center">
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'eye']} />
                        </span>
                      </Button>
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'heart']} />
                        </span>
                      </Button>
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'user']} />
                        </span>
                      </Button>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <Grid container spacing={6} className="text-center">
                      <Grid item sm={4}>
                        <div className="text-black-50">Projects</div>
                        <b className="font-size-lg">381</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Reviews</div>
                        <b className="font-size-lg">129</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Revenue</div>
                        <b className="font-size-lg">$691</b>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xl={6}>
                  <Card className="card-box p-4">
                    <div className="d-flex align-items-center justify-content-between py-2">
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper d-50 mr-3">
                          <div className="avatar-icon rounded-circle d-50 shadow-sm">
                            <img alt="..." src={avatar2} />
                          </div>
                        </div>
                        <div>
                          <a
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold"
                            title="...">
                            Grantee Deee
                          </a>
                          <span className="text-black-50 d-block">
                            Senior Consultant, Google
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="badge rounded-circle badge-neutral-warning text-warning d-30 btn-icon p-0 mr-1">
                          <FontAwesomeIcon icon={['far', 'clock']} />
                        </div>
                        <span className="font-size-xs text-warning">
                          Review Pending
                        </span>
                      </div>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <div className="text-center">
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'eye']} />
                        </span>
                      </Button>
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'heart']} />
                        </span>
                      </Button>
                      <Button
                        className="p-0 d-30 btn-icon rounded-sm mx-1 btn-transition-none btn-outline-primary border-0"
                        variant="text">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'user']} />
                        </span>
                      </Button>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <Grid container spacing={6} className="text-center">
                      <Grid item sm={4}>
                        <div className="text-black-50">Projects</div>
                        <b className="font-size-lg">34</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Reviews</div>
                        <b className="font-size-lg">21</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Revenue</div>
                        <b className="font-size-lg">$283</b>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xl={6}>
                  <Card className="card-box p-4">
                    <div className="d-flex align-items-center justify-content-between py-2">
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper d-50 mr-3">
                          <div className="avatar-icon rounded-circle d-50 shadow-sm">
                            <img alt="..." src={avatar7} />
                          </div>
                        </div>
                        <div>
                          <a
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold"
                            title="...">
                            Grantee Jaay
                          </a>
                          <span className="text-black-50 d-block">
                            Lead UX Designer, Spotify
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="badge rounded-circle badge-neutral-danger text-danger d-30 btn-icon p-0 mr-1">
                          <FontAwesomeIcon icon={['fas', 'times']} />
                        </div>
                        <span className="font-size-xs text-danger">
                          Rejected
                        </span>
                      </div>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <div className="text-center">
                      <Button
                        disabled
                        className="p-0 d-30 btn-icon rounded-sm border-0 mx-1 btn-transition-none btn-outline-primary">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'eye']} />
                        </span>
                      </Button>
                      <Button
                        disabled
                        className="p-0 d-30 btn-icon rounded-sm border-0 mx-1 btn-transition-none btn-outline-primary">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'heart']} />
                        </span>
                      </Button>
                      <Button
                        disabled
                        className="p-0 d-30 btn-icon rounded-sm border-0 mx-1 btn-transition-none btn-outline-primary">
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'user']} />
                        </span>
                      </Button>
                    </div>
                    <div className="divider mx-auto my-3 w-100" />
                    <Grid
                      container
                      spacing={6}
                      className="text-center opacity-6">
                      <Grid item sm={4}>
                        <div className="text-black-50">Projects</div>
                        <b className="font-size-lg">0</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Reviews</div>
                        <b className="font-size-lg">0</b>
                      </Grid>
                      <Grid item sm={4}>
                        <div className="text-black-50">Revenue</div>
                        <b className="font-size-lg">$0</b>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div
              className={clsx('tab-item-wrapper overflow-visible d-none', {
                'd-block active': activeTab2 === '3'
              })}
              index={3}>
              <div className="py-5">
                <div className="pt-3 pb-1">
                  <div className="timeline-list timeline-list-horizontal">
                    <ul className="d-flex justify-content-center flex-wrap">
                      <li className="timeline-item timeline-item-icon">
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon-wrapper bg-danger text-white">
                            <FontAwesomeIcon icon={['far', 'gem']} />
                          </div>
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            Lunch with investors
                          </h4>
                          <p>
                            Start benefitting from Web3 right away. Accounts are
                            free and are used to collect achievment and access
                            tokens.PP1x1
                          </p>
                        </div>
                      </li>
                      <li className="timeline-item timeline-item-icon">
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon-wrapper bg-success text-white">
                            <FontAwesomeIcon icon={['far', 'keyboard']} />
                          </div>
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            Create new accounts
                          </h4>
                          <p>
                            Start benefitting from Web3 right away. Accounts are
                            free and are used to collect achievment and access
                            tokens.PP1x2
                          </p>
                          <div className="mt-3">
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={people3}
                                width="70"
                              />
                            </a>
                            <a
                              href="#/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img
                                alt="..."
                                className="img-fluid rounded shadow-sm"
                                src={people2}
                                width="70"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="timeline-item timeline-item-icon">
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon-wrapper bg-midnight-bloom text-white">
                            <FontAwesomeIcon icon={['far', 'bell']} />
                          </div>
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            Lunch with investors
                          </h4>
                          <p>
                            Start benefitting from Web3 right away. Accounts are
                            free and are used to collect achievment and access
                            tokens.PP1z3
                          </p>
                          <div className="mt-2">
                            <Button
                              size="small"
                              color="primary"
                              variant="contained">
                              Submit Report
                            </Button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={clsx('tab-item-wrapper overflow-visible d-none', {
                'd-block active': activeTab2 === '2'
              })}
              index={2}>
              <Grid container spacing={6}>
                <Grid item lg={6}>
                  <Card className="shadow-sm rounded-lg overflow-hidden mb-5">
                    <div className="card-img-wrapper rounded">
                      <a
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="img-wrapper-overlay p-4 p-xl-5 img-wrapper-overlay--visible rounded">
                        <div className="overlay-btn-wrapper card-body text-white text-center">
                          <h5 className="px-2 font-weight-bold display-4 mb-4">
                            Web3Skills
                          </h5>
                          <p className="font-size-lg text-white-50 mb-0">
                            2 Web3Skills SmartLaw is a training tool and set of
                            protocols to advance the adoption of Web3
                            technologies. Users can freely browse and use Web3
                            applications anonymously or with decentralized
                            profiles that capture the spirit of the user. The
                            core of the Web3Skills SmartLaw system is a ERC-20
                            token that was minted in 2020 at
                            0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has
                            lived a dormant life until now.PP1b
                          </p>
                          <div className="mt-4">
                            <div className="avatar-icon-wrapper mx-auto mb-2">
                              <div className="avatar-icon shadow-sm-dark">
                                <img alt="..." src={avatar6} />
                              </div>
                            </div>
                            <div>Misty AnnOne</div>
                          </div>
                        </div>
                        <div className="card-badges card-badges-top">
                          <div className="badge badge-pill badge-danger">
                            Development
                          </div>
                        </div>
                      </a>
                      <img
                        src={stock1}
                        className="card-overlay-image img-fit-container rounded"
                        alt="..."
                      />
                    </div>
                  </Card>
                  <Card className="shadow-sm rounded-lg overflow-hidden mb-5">
                    <div className="card-img-wrapper rounded">
                      <a
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-4 p-lg-5 img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                        <div className="overlay-btn-wrapper d-block p-3 p-lg-5 text-left text-white">
                          <h5 className="font-weight-bold display-3 mb-3">
                            Web3Skills
                          </h5>
                          <p className="font-size-lg mb-3">
                            Start benefitting from Web3 right away. Accounts are
                            free and are used to collect achievment and access
                            tokens.PP1c
                          </p>
                          <p className="font-size-md text-white-50">
                            3 Web3Skills SmartLaw is a training tool and set of
                            protocols to advance the adoption of Web3
                            technologies. Users can freely browse and use Web3
                            applications anonymously or with decentralized
                            profiles that capture the spirit of the user. The
                            core of the Web3Skills SmartLaw system is a ERC-20
                            token that was minted in 2020 at
                            0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has
                            lived a dormant life until now.PP1c
                          </p>
                          <p className="font-size-md text-white-50">
                            View any of the 5+ live previews we&#39;ve set up to
                            learn why this dashboard template is the last one
                            you&#39;ll ever need!
                          </p>
                          <div className="divider bg-white opacity-1 my-4" />
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="avatar-icon-wrapper mr-3">
                                <div className="avatar-icon shadow-sm-dark">
                                  <img alt="..." src={avatar3} />
                                </div>
                              </div>
                              <div className="font-size-lg">
                                Scott Stevenson 4
                              </div>
                            </div>
                            <div className="d-flex align-items-center text-white-50">
                              <FontAwesomeIcon
                                icon={['far', 'clock']}
                                className="mr-2"
                              />
                              <small>5 days ago</small>
                            </div>
                          </div>
                        </div>
                        <div className="card-badges card-badges-top">
                          <div className="badge badge-pill badge-success">
                            Marketing
                          </div>
                        </div>
                      </a>
                      <img
                        src={stock3}
                        className="card-overlay-image img-fit-container rounded"
                        alt="..."
                      />
                    </div>
                  </Card>
                </Grid>
                <Grid item lg={6}>
                  <Card className="shadow-sm rounded-lg overflow-hidden hover-scale-sm mb-5">
                    <div className="card-img-wrapper rounded">
                      <div className="img-wrapper-overlay align-items-end img-wrapper-overlay--visible p-4 p-xl-5">
                        <div className="overlay-btn-wrapper p-4 card-body text-white">
                          <h5 className="px-2 font-weight-bold display-4 mb-4">
                            Web3Skills
                          </h5>
                          <p className="font-size-lg mb-0 text-white-50">
                            4 Web3Skills SmartLaw is a training tool and set of
                            protocols to advance the adoption of Web3
                            technologies. Users can freely browse and use Web3
                            applications anonymously or with decentralized
                            profiles that capture the spirit of the user. The
                            core of the Web3Skills SmartLaw system is a ERC-20
                            token that was minted in 2020 at
                            0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has
                            lived a dormant life until now.PP1d
                          </p>
                          <div className="mt-4">
                            <div className="avatar-icon-wrapper mx-auto mb-2">
                              <div className="avatar-icon shadow-sm-dark">
                                <img alt="..." src={avatar7} />
                              </div>
                            </div>
                            <div>Scott MX Stevenson</div>
                          </div>
                        </div>
                      </div>
                      <div className="card-badges">
                        <div className="badge badge-pill badge-neutral-success text-success">
                          Marketing
                        </div>
                      </div>
                      <img
                        src={stock2}
                        className="card-overlay-image img-fit-container rounded"
                        alt="..."
                      />
                    </div>
                  </Card>
                  <Card className="shadow-sm rounded-lg overflow-hidden mb-5">
                    <div className="card-img-wrapper rounded">
                      <a
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                        <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper mr-3">
                              <div className="avatar-icon shadow-sm-dark">
                                <img alt="..." src={avatar3} />
                              </div>
                            </div>
                            <div>
                              <div>Scott SW Stevenson</div>
                              <span className="text-white-50">
                                UI Engineer, Apple Inc.
                              </span>
                            </div>
                          </div>
                          <div className="divider bg-white opacity-2 my-3 my-lg-5" />
                          <h5 className="font-weight-bold display-3">
                            Web3Skills
                          </h5>
                          <p className="font-size-lg my-3 text-white-50">
                            Start benefitting from Web3 right away. Accounts are
                            free and are used to collect achievment and access
                            tokens.PP1d
                          </p>
                          <p className="text-light">
                            5 Web3Skills SmartLaw is a training tool and set of
                            protocols to advance the adoption of Web3
                            technologies. Users can freely browse and use Web3
                            applications anonymously or with decentralized
                            profiles that capture the spirit of the user. The
                            core of the Web3Skills SmartLaw system is a ERC-20
                            token that was minted in 2020 at
                            0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has
                            lived a dormant life until now.PP1e
                          </p>
                          <div className="divider bg-white opacity-2 my-3 my-lg-5" />
                          <div className="d-flex align-items-center text-white-50">
                            <FontAwesomeIcon
                              icon={['far', 'clock']}
                              className="mr-2"
                            />
                            <small>added 3 days ago</small>
                          </div>
                        </div>
                        <div className="card-badges card-badges-bottom">
                          <div className="badge badge-first">Articles</div>
                        </div>
                      </a>
                      <img
                        src={stock3}
                        className="card-overlay-image img-fit-container rounded"
                        alt="..."
                      />
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </div>








          </Container>











          <div
            className="hero-wrapper bg-composed-wrapper bg-second z-below"
            style={{ marginTop: '-150px' }}>
            <div className="hero-wrapper--content flex-column">
              <div className="shape-container-bottom-2 w-100">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path
                    fill="var(--white)"
                    fillOpacity="1"
                    d="M0,288L34.3,250.7C68.6,213,137,139,206,144C274.3,149,343,235,411,229.3C480,224,549,128,617,101.3C685.7,75,754,117,823,122.7C891.4,128,960,96,1029,112C1097.1,128,1166,192,1234,186.7C1302.9,181,1371,107,1406,69.3L1440,32L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
                </svg>
              </div>
              <div
                className="bg-composed-wrapper--image opacity-3"
                style={{ backgroundImage: 'url(' + hero1 + ')' }}
              />
              <div className="bg-composed-wrapper--bg bg-deep-sky opacity-4" />
              <div className="bg-composed-wrapper--content pb-5">
                <Container className="py-5 text-center">
                  <div className="py-5 py-sm-0">
                    <div>
                      <h4 className="font-weight-bold text-white display-4">
                        Take a look at this Grant Profile Maker. It's Web3.
                      </h4>
                      <Grid item md={8} lg={6} className="mx-auto">
                        <p className="text-white opacity-8 mt-3 mb-5 font-size-xl">
                          Start benefitting from Web3 right away. Accounts are
                          free and are used to collect achievment and access
                          tokens.PP1e
                        </p>
                      </Grid>
                    </div>
                    <Button className="btn-success px-5 font-size-lg shadow-sm-dark py-3 btn-pill mx-2">
                      Try Web3 Now
                    </Button>
                    <Button className="btn-primary bg-white px-4 text-second shadow-sm font-weight-bold py-2 btn-pill mx-2">
                      Learn more
                    </Button>
                  </div>
                </Container>














                <div className="mb-spacing-6-x2">
                <Grid container spacing={4}>




                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>

                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/0x-zrx-logo.svg/1200px-0x-zrx-logo.svg.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://gov.0x.org/t/0xdao-grant-program-framework-v1/962"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/0x-zrx-logo.svg/1200px-0x-zrx-logo.svg.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/0x-zrx-logo.svg/1200px-0x-zrx-logo.svg.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>

                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        0xDAO Grant Framework XXX
                      </div>
                      <div className="opacity-7">
                        {' '}
                        0xDAO Grant Framework details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://1inch.io/img/logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shr899d3K9Bw963CP"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://1inch.io/img/logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://1inch.io/img/logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        1inch Grant Program
                      </div>
                      <div className="opacity-7">
                        1inch Grant Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://global-uploads.webflow.com/617702c73410810254ccd237/6283fe24dde5a0e2f008f9a4_aave-aave-logo-min.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://aavegrants.org/apply-for-a-grant"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://global-uploads.webflow.com/617702c73410810254ccd237/6283fe24dde5a0e2f008f9a4_aave-aave-logo-min.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://global-uploads.webflow.com/617702c73410810254ccd237/6283fe24dde5a0e2f008f9a4_aave-aave-logo-min.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Aave Grants
                      </div>
                      <div className="opacity-7">Aave Grants details here</div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://avatars.githubusercontent.com/u/23182699?s=200&amp;v=4"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://algorandfoundation.submittable.com/submit"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://avatars.githubusercontent.com/u/23182699?s=200&amp;v=4"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://avatars.githubusercontent.com/u/23182699?s=200&amp;v=4"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Algorand Foundation
                      </div>
                      <div className="opacity-7">
                        Algorand Foundation details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1235596780047339521/oRt6SiEN_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdvS-eyBhF3T15kyijCQkKvQbE52iMup0Q2aktfb5tUqnNHhQ/viewform"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1235596780047339521/oRt6SiEN_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1235596780047339521/oRt6SiEN_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Arweave Investments & Grants
                      </div>
                      <div className="opacity-7">
                        Arweave Investments & Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cryptototem.com/wp-content/uploads/2021/10/Aurora-logo.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shrm0FfqwJJ1D0HFr"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cryptototem.com/wp-content/uploads/2021/10/Aurora-logo.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cryptototem.com/wp-content/uploads/2021/10/Aurora-logo.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Aurora Grants
                      </div>
                      <div className="opacity-7">
                        Aurora Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://miro.medium.com/fit/c/176/176/1*Ut-0J5rp8GckMgDDFNHl0A.jpeg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shrvglCZ24jaH73oe"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://miro.medium.com/fit/c/176/176/1*Ut-0J5rp8GckMgDDFNHl0A.jpeg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://miro.medium.com/fit/c/176/176/1*Ut-0J5rp8GckMgDDFNHl0A.jpeg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Aztec Grants
                      </div>
                      <div className="opacity-7">
                        Aztec Grants Application details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.balancer.fi/ecosystem/community/grants"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Ecosystem Community Grants
                      </div>
                      <div className="opacity-7">
                        Ecosystem Community Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://forms.monday.com/forms/e4ad18734e3c7153b166b4374dd9a7e7"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Binance X Fellowship Application
                      </div>
                      <div className="opacity-7">
                        Binance X Fellowship Application details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1493309659599486986/5ETdHxeE_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://share.hsforms.com/1WWFukIl9TGa19lf5ocAaQQ53n4j"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1493309659599486986/5ETdHxeE_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1493309659599486986/5ETdHxeE_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Celo Grants
                      </div>
                      <div className="opacity-7">Celo Grants details here</div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjwgBqyunZ6hokHLbFCAqfW8H-0n3Fg_dY2DI4BvUOY_NIeVIYRG3QD5zR6QHyGeyhX84&amp;usqp=CAU"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdeW4HreYZJLDOoN-tdD4F3KkYR_KwNCkd5hhdylXbkqjNCuA/closedform"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjwgBqyunZ6hokHLbFCAqfW8H-0n3Fg_dY2DI4BvUOY_NIeVIYRG3QD5zR6QHyGeyhX84&amp;usqp=CAU"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjwgBqyunZ6hokHLbFCAqfW8H-0n3Fg_dY2DI4BvUOY_NIeVIYRG3QD5zR6QHyGeyhX84&amp;usqp=CAU"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        CENNZnet Grants
                      </div>
                      <div className="opacity-7">
                        CENNZnet Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://chainlinkgrants.typeform.com/to/efEbsq?typeform-source=chain.link"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Chainlink Grant Program
                      </div>
                      <div className="opacity-7">
                        Chainlink Grant Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSf8jHW0S9W7S-j0wHnebYb5V1g36kQLkHwre44WoDQQn3X9lQ/viewform"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Crypto Community Developer Grants
                      </div>
                      <div className="opacity-7">
                        Crypto Community Developer Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://www.xdefi.io/wp-content/uploads/2022/05/favicon.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfDgkvKuBSaNOrmFYjQOYoLZPGtDGNHZKbVmZz1OvnbQKYjVA/viewform"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://www.xdefi.io/wp-content/uploads/2022/05/favicon.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://www.xdefi.io/wp-content/uploads/2022/05/favicon.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Connext Grants
                      </div>
                      <div className="opacity-7">
                        Connext Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://avatars.githubusercontent.com/u/10818037?s=280&amp;v=4"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://consensysgrants.submittable.com/submit"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://avatars.githubusercontent.com/u/10818037?s=280&amp;v=4"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://avatars.githubusercontent.com/u/10818037?s=280&amp;v=4"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        ConsenSys Grants
                      </div>
                      <div className="opacity-7">
                        ConsenSys Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1417359604623679488/CGzQIEVX_400x400.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shrcC9kREciOLQULE"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1417359604623679488/CGzQIEVX_400x400.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1417359604623679488/CGzQIEVX_400x400.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Cronos Ecosystem Grants
                      </div>
                      <div className="opacity-7">
                        Cronos Ecosystem Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://github.com/crustio/Crust-Grants-Program/blob/main/applications/application_template.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Crust Grants Program
                      </div>
                      <div className="opacity-7">
                        Crust Grants Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1415111778338545664/5aXkuTup_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://www.defieducationfund.org/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1415111778338545664/5aXkuTup_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1415111778338545664/5aXkuTup_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        DeFi Education Fund
                      </div>
                      <div className="opacity-7">
                        DeFi Education Fund details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cryptocurrencyjobs.co/startups/assets/logos/rabbithole.42c2eb893a82380205f18e4f3a55380fa11b45d69bbc7122ae6880699a7bd01a.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://tzconnect.typeform.com/to/DE7TcIgf?typeform-source=grantr.app"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cryptocurrencyjobs.co/startups/assets/logos/rabbithole.42c2eb893a82380205f18e4f3a55380fa11b45d69bbc7122ae6880699a7bd01a.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cryptocurrencyjobs.co/startups/assets/logos/rabbithole.42c2eb893a82380205f18e4f3a55380fa11b45d69bbc7122ae6880699a7bd01a.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Down The Rabbit Hole Grants
                      </div>
                      <div className="opacity-7">
                        Down The Rabbit Hole Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://www.dydxgrants.com/grants-application"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        DxDy Grants
                      </div>
                      <div className="opacity-7">DxDy Grants details here</div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/d/d0/Eth-diamond-rainbow.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://esp.ethereum.foundation/applicants/small-grants/apply"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/d/d0/Eth-diamond-rainbow.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/d/d0/Eth-diamond-rainbow.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Ethereum Foundation Small Grants
                      </div>
                      <div className="opacity-7">
                        Ethereum Foundation Small Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://thegivingblock.com/wp-content/uploads/2021/07/Fantom-FTM-Logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://fantom.foundation/blog/new-335m-ftm-incentive-program-with-gitcoin-grants/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://thegivingblock.com/wp-content/uploads/2021/07/Fantom-FTM-Logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://thegivingblock.com/wp-content/uploads/2021/07/Fantom-FTM-Logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        New 335m FTM Incentive Program
                      </div>
                      <div className="opacity-7">
                        New 335m FTM Incentive Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Filecoin.svg/1200px-Filecoin.svg.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://github.com/filecoin-project/devgrants/issues/new?assignees=SeedingTrees&labels=&template=microgrant.md&title=Next+Step+Microgrant%3A+%3CYour+Title+Here%3E"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Filecoin.svg/1200px-Filecoin.svg.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Filecoin.svg/1200px-Filecoin.svg.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Filecoin devgrants
                      </div>
                      <div className="opacity-7">
                        Filecoin devgrants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/10052.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://gitcoin.co/grants/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/10052.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/10052.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Gitcoin Grants
                      </div>
                      <div className="opacity-7">
                        Gitcoin Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/3945.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://open.harmony.one/300m-on-bounties-grants-daos/apply-for-grants-or-dao"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/3945.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/3945.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Harmony Grants
                      </div>
                      <div className="opacity-7">
                        Harmony Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://hedera.com/assets/images/HH-logo-Black.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://www.hbarfoundation.org/apply-form"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://hedera.com/assets/images/HH-logo-Black.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://hedera.com/assets/images/HH-logo-Black.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        HBAR Grants
                      </div>
                      <div className="opacity-7">HBAR Grants details here</div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://discord.com/invite/7CGPQ8fWHt"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Hyperscale
                      </div>
                      <div className="opacity-7">Hyperscale details here</div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSeODAt32FqxdwU7K2v6nIsikqSylq42WflojmvQyaXaO8hT-A/viewform"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Idle Grants
                      </div>
                      <div className="opacity-7">Idle Grants details here</div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://messari.s3.amazonaws.com/images/agora-images/tNbKZuTE_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdkgcNckqj6s9IkX0cSg6eiicYg2IPMASZeTQ2KO9kjnma28g/viewform"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://messari.s3.amazonaws.com/images/agora-images/tNbKZuTE_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://messari.s3.amazonaws.com/images/agora-images/tNbKZuTE_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Interchain
                      </div>
                      <div className="opacity-7">Interchain details here</div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://kadena.io/grants/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Kadena Grants
                      </div>
                      <div className="opacity-7">
                        Kadena Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1423729299442503683/j9yXgh9F_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://lego.lido.fi/how-can-i-apply"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1423729299442503683/j9yXgh9F_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1423729299442503683/j9yXgh9F_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Lido Ecosystem Grants Org
                      </div>
                      <div className="opacity-7">
                        Lido Ecosystem Grants Org details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/3640.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://github.com/livepeer/Grant-Program"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/3640.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/3640.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        livepeer/Grant-Program
                      </div>
                      <div className="opacity-7">
                        livepeer/Grant-Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com/public/images/cf7821fd-5ab1-4316-86f5-4605ad6a3872_295x295.jpeg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://forum.daohaus.club/t/guide-meta-gamma-delta-grants-application-process/10626"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com/public/images/cf7821fd-5ab1-4316-86f5-4605ad6a3872_295x295.jpeg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com/public/images/cf7821fd-5ab1-4316-86f5-4605ad6a3872_295x295.jpeg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Meta Gamma Delta – DAOHaus
                      </div>
                      <div className="opacity-7">
                        Meta Gamma Delta – DAOHaus details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1372883007695060992/3CzDQZV2_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://molochdao.gitbook.io/handbook/grant-pipeline-procedures/how-and-when-to-put-a-proposal-on-chain/application"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1372883007695060992/3CzDQZV2_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1372883007695060992/3CzDQZV2_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Moloch Grant Application
                      </div>
                      <div className="opacity-7">
                        Moloch Grant Application details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://gblobscdn.gitbook.com/assets%2F-LxR8Ppz-_JQ7mRRKKgl%2F-MKppt9oUQ4umIswoE7i%2F-MKptF3pZsCgvqv3aZ2g%2Fmstable_logo_circle_m_black_smaller.png?alt=media&amp;token=c26c5ed2-e50c-4324-be3c-05fb3328ce86"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shrmPojq6wrSDyC3m"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://gblobscdn.gitbook.com/assets%2F-LxR8Ppz-_JQ7mRRKKgl%2F-MKppt9oUQ4umIswoE7i%2F-MKptF3pZsCgvqv3aZ2g%2Fmstable_logo_circle_m_black_smaller.png?alt=media&amp;token=c26c5ed2-e50c-4324-be3c-05fb3328ce86"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://gblobscdn.gitbook.com/assets%2F-LxR8Ppz-_JQ7mRRKKgl%2F-MKppt9oUQ4umIswoE7i%2F-MKptF3pZsCgvqv3aZ2g%2Fmstable_logo_circle_m_black_smaller.png?alt=media&amp;token=c26c5ed2-e50c-4324-be3c-05fb3328ce86"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        mStable Grants Application
                      </div>
                      <div className="opacity-7">
                        mStable Grants Application details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://messari.s3.amazonaws.com/images/agora-images/OuUrrEqX_400x400.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shrY3sDi7JnWtLNI6"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://messari.s3.amazonaws.com/images/agora-images/OuUrrEqX_400x400.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://messari.s3.amazonaws.com/images/agora-images/OuUrrEqX_400x400.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        NEAR Foundation Grants Program
                      </div>
                      <div className="opacity-7">
                        NEAR Foundation Grants Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://neo3.azureedge.net/images/eco/grants/neo-gui.png?v=9CE5F28EE29CDFBFACEAFDAC260675E95F7A7C8692A734FC973E7727A2389A78"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://neo.org/eco#general-grants"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://neo3.azureedge.net/images/eco/grants/neo-gui.png?v=9CE5F28EE29CDFBFACEAFDAC260675E95F7A7C8692A734FC973E7727A2389A78"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://neo3.azureedge.net/images/eco/grants/neo-gui.png?v=9CE5F28EE29CDFBFACEAFDAC260675E95F7A7C8692A734FC973E7727A2389A78"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Neo General Grants Program
                      </div>
                      <div className="opacity-7">
                        Neo General Grants Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://neo.org/eco#research-grants"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Neo Research Grants
                      </div>
                      <div className="opacity-7">
                        Neo Research Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/4948.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/1s44tAcdH1XVIk8EPUV5AOhvaLi97BLffZgTmYAcsWTU/viewform?edit_requested=true"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/4948.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/4948.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Nervos Grants
                      </div>
                      <div className="opacity-7">
                        Nervos Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSeyc6yhvOqcgFcI5DQe3C-5-JhWLOfsBy2U0IzHGgvc2DYUCA/viewform"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Notional Community Application
                      </div>
                      <div className="opacity-7">
                        Notional Community Application details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://gitcoin.co/dynamic/avatar/oasisprotocol"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shrds85lC23z5Bwy2"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://gitcoin.co/dynamic/avatar/oasisprotocol"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://gitcoin.co/dynamic/avatar/oasisprotocol"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Grants Program Application
                      </div>
                      <div className="opacity-7">
                        Grants Program Application details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg?1547038686"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://seed.oceandao.org/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg?1547038686"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg?1547038686"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        OceanDAO Seed Grants
                      </div>
                      <div className="opacity-7">
                        OceanDAO Seed Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/o7zt4skfceb6d005doom"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://notionforms.io/forms/olympus-grants-program-application-form-1"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/o7zt4skfceb6d005doom"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/o7zt4skfceb6d005doom"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Olympus Grants Program Application Form · NotionForms
                      </div>
                      <div className="opacity-7">
                        Olympus Grants Program Application Form · NotionForms
                        details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/ZJZZK5B2ZNF25LYQHMUTBTOMLU.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://opengrants.com/explore"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/ZJZZK5B2ZNF25LYQHMUTBTOMLU.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/ZJZZK5B2ZNF25LYQHMUTBTOMLU.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Open Grants
                      </div>
                      <div className="opacity-7">Open Grants details here</div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shrC6zgCb3lZo51as"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        OpenSea Ecosystem Grants Program
                      </div>
                      <div className="opacity-7">
                        OpenSea Ecosystem Grants Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://uploads-ssl.webflow.com/62aba8dc00fdd48273d4c874/62b327d14f4b5887c5a0c359_osmosis-logomark-white.svg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://grants.osmosis.zone/apply"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://uploads-ssl.webflow.com/62aba8dc00fdd48273d4c874/62b327d14f4b5887c5a0c359_osmosis-logomark-white.svg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://uploads-ssl.webflow.com/62aba8dc00fdd48273d4c874/62b327d14f4b5887c5a0c359_osmosis-logomark-white.svg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Apply For a Grant
                      </div>
                      <div className="opacity-7">
                        Apply For a Grant details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://gitcoin.co/dynamic/avatar/pooltogether"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://poolgrants.org/apply"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://gitcoin.co/dynamic/avatar/pooltogether"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://gitcoin.co/dynamic/avatar/pooltogether"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Application Form
                      </div>
                      <div className="opacity-7">
                        Application Form details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1372563232850870274/aREQff_C_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://radicle.mirror.xyz/7RDTvdxABVndpZge9VT09Ku5JXD8lCCCpLRRZaVrtJU"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1372563232850870274/aREQff_C_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1372563232850870274/aREQff_C_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Radicle Grants
                      </div>
                      <div className="opacity-7">
                        Radicle Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://www.crypto-reporter.com/wp-content/uploads/2021/10/rally_400x400_.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://forum.rally.io/t/guide-how-to-submit-a-funding-proposal/1407"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://www.crypto-reporter.com/wp-content/uploads/2021/10/rally_400x400_.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://www.crypto-reporter.com/wp-content/uploads/2021/10/rally_400x400_.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        RLY Ecosystem DAO
                      </div>
                      <div className="opacity-7">
                        RLY Ecosystem DAO details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cdn-customers.getro.com/samsungnext/images/logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://samsungnext.typeform.com/to/FFh0nIgy?typeform-source=www.samsungnext.com"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cdn-customers.getro.com/samsungnext/images/logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cdn-customers.getro.com/samsungnext/images/logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Samsung Next Global Innovators
                      </div>
                      <div className="opacity-7">
                        Samsung Next Global Innovators details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://grants.art/social.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://grants.art/program"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://grants.art/social.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://grants.art/social.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Sevens Foundation
                      </div>
                      <div className="opacity-7">
                        Sevens Foundation details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="http://www.socialalphafoundation.org/wp-content/uploads/2021/04/logo_100h.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://www.socialalphafoundation.org/saf-grant/#"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="http://www.socialalphafoundation.org/wp-content/uploads/2021/04/logo_100h.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="http://www.socialalphafoundation.org/wp-content/uploads/2021/04/logo_100h.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Social Alpha foundation
                      </div>
                      <div className="opacity-7">
                        Social Alpha foundation details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://imageio.forbes.com/specials-images/imageserve/60be57e131b5299078734980/0x0.jpg?format=jpg&amp;width=1200"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://solana.org/grants"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://imageio.forbes.com/specials-images/imageserve/60be57e131b5299078734980/0x0.jpg?format=jpg&amp;width=1200"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://imageio.forbes.com/specials-images/imageserve/60be57e131b5299078734980/0x0.jpg?format=jpg&amp;width=1200"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Solana Foundation Grants Program
                      </div>
                      <div className="opacity-7">
                        Solana Foundation Grants Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://spiral.xyz/blog/square-crypto-grants-for-everybody/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Square Crypto Grants
                      </div>
                      <div className="opacity-7">
                        Square Crypto Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cryptomode.com/wp-content/uploads/2018/10/makerdao-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://medium.com/@stablefund/introducing-the-stable-fund-45-million-for-the-makerdao-ecosystem-def79afc4b2a"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cryptomode.com/wp-content/uploads/2018/10/makerdao-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cryptomode.com/wp-content/uploads/2018/10/makerdao-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        The MakerDAO Stable Fund
                      </div>
                      <div className="opacity-7">
                        The MakerDAO Stable Fund details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://stacks.org/grants"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Grants - Stacks Foundation
                      </div>
                      <div className="opacity-7">
                        Grants - Stacks Foundation details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/5/56/Stellar_Symbol.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://www.stellar.org/foundation/grants-and-funding"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/5/56/Stellar_Symbol.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/5/56/Stellar_Symbol.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Stellar Development Foundation
                      </div>
                      <div className="opacity-7">
                        Stellar Development Foundation details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/2586.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://grants.synthetix.io/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/2586.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/2586.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        The grantsDAO
                      </div>
                      <div className="opacity-7">
                        The grantsDAO details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/6/68/Tezos_Logo_2022.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://tezos.foundation/grants/#working-group-block"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/6/68/Tezos_Logo_2022.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/6/68/Tezos_Logo_2022.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Tezos Foundation Grants
                      </div>
                      <div className="opacity-7">
                        Tezos Foundation Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cdn-images-1.medium.com/max/1200/1*uOUrHJwAlNPBiGzl3slkWw.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://thegraph.typeform.com/applynow?typeform-source=grantr.app"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cdn-images-1.medium.com/max/1200/1*uOUrHJwAlNPBiGzl3slkWw.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cdn-images-1.medium.com/max/1200/1*uOUrHJwAlNPBiGzl3slkWw.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        The Graph: Grants Application
                      </div>
                      <div className="opacity-7">
                        The Graph: Grants Application details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cryptologos.cc/logos/the-sandbox-sand-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://www.sandbox.game/fund/en/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cryptologos.cc/logos/the-sandbox-sand-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cryptologos.cc/logos/the-sandbox-sand-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Metaverse Game Maker Fund
                      </div>
                      <div className="opacity-7">
                        Metaverse Game Maker Fund details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://miro.medium.com/max/400/0*Z_BXpFf10-UuxaDA"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSesA1qtjbWKAlhlhovlG0u2toMH2jGv1vUcrXM97OPHfyx6vw/closedform"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://miro.medium.com/max/400/0*Z_BXpFf10-UuxaDA"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://miro.medium.com/max/400/0*Z_BXpFf10-UuxaDA"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Tribe DAO Grants Program
                      </div>
                      <div className="opacity-7">
                        Tribe DAO Grants Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://images-ihjoz-com.s3.amazonaws.com/companies/logo/943/company_logo_Uberhaus_logo_300x300.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://forum.daohaus.club/t/dao-grants-thread/1030/6"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://images-ihjoz-com.s3.amazonaws.com/companies/logo/943/company_logo_Uberhaus_logo_300x300.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://images-ihjoz-com.s3.amazonaws.com/companies/logo/943/company_logo_Uberhaus_logo_300x300.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        DAO Grants Thread UberHAUS DAOhaus
                      </div>
                      <div className="opacity-7">
                        DAO Grants Thread UberHAUS DAOhaus details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://mvpworkshop.co/wp-content/uploads/2021/04/Thumbnail-Uniswap-v3-Explained@3x-1.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://airtable.com/shrWrSFRs6t1q1s9v"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://mvpworkshop.co/wp-content/uploads/2021/04/Thumbnail-Uniswap-v3-Explained@3x-1.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://mvpworkshop.co/wp-content/uploads/2021/04/Thumbnail-Uniswap-v3-Explained@3x-1.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        UGP Application
                      </div>
                      <div className="opacity-7">
                        UGP Application details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/4747.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://docs.velas.com/grant-process/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/4747.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://s2.coinmarketcap.com/static/img/coins/200x200/4747.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Velas Grants Process
                      </div>
                      <div className="opacity-7">
                        Velas Grants Process details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cdn-images-1.medium.com/max/1200/1*fIzg0ShjGtI-iKt5JF1xBA@2x.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://github.com/w3f/Grants-Program#1-application"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cdn-images-1.medium.com/max/1200/1*fIzg0ShjGtI-iKt5JF1xBA@2x.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cdn-images-1.medium.com/max/1200/1*fIzg0ShjGtI-iKt5JF1xBA@2x.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Web3 Foundation Grants Program
                      </div>
                      <div className="opacity-7">
                        Web3 Foundation Grants Program details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://www.augustineformayor.ca/wp-content/uploads/2022/08/Twitch-streamers-can-now-create-NFTs-and-generate-tokens-on.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://xrplgrants.org/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://www.augustineformayor.ca/wp-content/uploads/2022/08/Twitch-streamers-can-now-create-NFTs-and-generate-tokens-on.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://www.augustineformayor.ca/wp-content/uploads/2022/08/Twitch-streamers-can-now-create-NFTs-and-generate-tokens-on.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        XRPL Software Dev Grants
                      </div>
                      <div className="opacity-7">
                        {' '}
                        XRPL Software Dev Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://cryptologos.cc/logos/zcash-zec-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://zcashgrants.org/login?returnUrl=%2Fsubmit%2F211910%2Fzcash-community-grants-program"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://cryptologos.cc/logos/zcash-zec-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://cryptologos.cc/logos/zcash-zec-logo.png"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        Zcash Foundation
                      </div>
                      <div className="opacity-7">
                        Zcash Foundation details here
                      </div>
                      <br />
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Card className="card-box p-4">
                      <div className="card-tr-actions">
                        <Button
                          className="p-0 d-20 mr-1 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fars', 'fingerprint']}
                            className="font-size-sm"
                          />
                        </Button>
                        <Button
                          className="p-0 d-20 btn-transition-none opacity-6 btn-link"
                          variant="text">
                          <FontAwesomeIcon
                            icon={['fas', 'charging-station']}
                            className="font-size-sm"
                          />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper bg-primary text-white btn-icon text-center">
                          <FontAwesomeIcon
                            icon={['fas', 'dollar-sign']}
                            className="font-size-xxl"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-circle badge-primary d-20 btn-icon p-0 mr-0 mr-xl-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                          <span className="font-size-xs text-primary d-none d-xl-block">
                            Grants Available
                          </span>
                        </div>
                      </div>
                      <NavLink to="/Grwalk" className="mr-2">
                        <img
                          src="https://www.crypto-reporter.com/wp-content/uploads/2020/01/ziliqa_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <a
                        href="https://www.zilhive.org/zilhive-grant-application-form/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                          src="https://www.crypto-reporter.com/wp-content/uploads/2020/01/ziliqa_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </a>
                      <NavLink to="/Grwalk" className="ml-2 mr-2">
                        <img
                          src="https://www.crypto-reporter.com/wp-content/uploads/2020/01/ziliqa_400x400.jpg"
                          width={70}
                          height={70}
                          alt="example"
                        />
                      </NavLink>
                      <div className="font-size-xl font-weight-bold pt-2 text-black">
                        ZILHive Grants
                      </div>
                      <div className="opacity-7">
                        ZILHive Grants details here
                      </div>
                      <br />
                    </Card>
                  </Grid>









                </Grid>
              </div>
































              </div>
              <div className="shape-container-top-1 w-100">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path
                    fill="var(--white)"
                    fillOpacity="1"
                    d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,202.7C672,213,768,235,864,213.3C960,192,1056,128,1152,133.3C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
