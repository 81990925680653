import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card } from '@material-ui/core';

// import avatar1 from '../../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../../assets/images/avatars/avatar2.jpg';
import avatar3 from '../../../assets/images/avatars/avatar3.jpg';

// import profile1 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-054.png';
import profile2 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-048.png';
// import profileImg011 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-011.png' ;
import profileImg003 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png' ;


export default function LivePreviewExample() {
  return (
    <>
      <Grid container spacing={6}>

        <Grid item xl={6}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank" 
rel="noopener noreferrer"

                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                      SmartLaw_API Web3 Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                    Web3Skills
                  </h5>
                  <p className="font-size-lg my-3 text-white-50">
                  Learn the best of Web3 without it becoming too complex. Fun, like infortainment.BI7a
                  </p>
                  <p className="text-light">
                  Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.BI7
                  </p>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profileImg003}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>
        <Grid item xl={6}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank" 
rel="noopener noreferrer"

                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar3} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                      SmartLaw Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                    SmartLaw
                  </h5>
                  <p className="font-size-lg my-3 text-white-50">
                  Learn the best of Web3 without it becoming too complex. Fun, like infortainment.BI7b
                  </p>
                  <p className="text-light">
                  Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.BI7
                  </p>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profile2}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
