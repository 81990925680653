import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardContent,
  Menu,
  Button,
  List,
  ListItem
} from '@material-ui/core';

import CountUp from 'react-countup';
import PerfectScrollbar from 'react-perfect-scrollbar';


import avatar1 from '../../../assets/images/avatars/ethereans-00136-w-hex-88-200x.png';
import avatar2 from '../../../assets/images/avatars/ethereans-00259-r-hex-103-200x.png';
import avatar5 from '../../../assets/images/avatars/ethereans-01410-r-hex-582-200x.png';
import avatar6 from '../../../assets/images/avatars/ethereans-02762-w-hex-ACA-200x.png';
import avatar9 from '../../../assets/images/avatars/ethereans-04201-r-hex-1069-200x.png';
import avatar10 from '../../../assets/images/avatars/ethereans-04651-t-hex-122B-200x.png';
import avatar19 from '../../../assets/images/avatars/ethereans-08694-w-hex-21F6-200x.png';
import avatar20 from '../../../assets/images/avatars/ethereans-09327-r-hex-246F-200x.png';
import avatar21 from '../../../assets/images/avatars/ethereans-10697-r-hex-29C9-200x.png';



export default function LivePreviewExample() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card className="card-box">
        <div className="card-header">
          <div className="card-header--title">
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              Users list
            </h4>
          </div>
          <div className="card-header--actions">
            <div>
              <Button onClick={handleClick} size="small" className="btn-first">
                <span className="btn-wrapper--label">Earned Achievements</span>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-down']}
                    className="opacity-8 font-size-xs"
                  />
                </span>
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                classes={{ list: 'p-0' }}
                onClose={handleClose}>
                <div className="dropdown-menu-xl">
                  <List
                    component="div"
                    className="nav-pills nav-neutral-dark flex-column px-3">
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"

                    >

                      <span>View all reports</span>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"

                      selected>
                      <span>View active items</span>
                      <div className="badge badge-pill badge-success ml-auto">
                        23
                      </div>
                    </ListItem>

                    <div className="divider" />

                    <ListItem
                      component="a"
                      button
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"

                    >

                      <div className="nav-link-icon">
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </div>
                      <span>Download</span>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"

                    >

                      <div className="nav-link-icon">
                        <FontAwesomeIcon icon={['far', 'file-excel']} />
                      </div>
                      <span>Export to CSV</span>
                    </ListItem>
                  </List>
                </div>
              </Menu>
            </div>
          </div>
        </div>

        <CardContent>
          <div className="px-3 pb-3">
            <div className="scroll-area-xl rounded bg-white shadow-overflow">
              <PerfectScrollbar>





                <div className="divider my-3" />





                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar19} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/41"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary font-size-lg"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">0x1300...8178</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for worldlaw.eth account
                          </a>
                        </span>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://hackmd.io/G2KsIkYGQ5O31dMdlzaZuA"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-first font-size-md"
                            title="...">
                            Consulting Rates for worldlaw.eth account
                          </a>
                        </span>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://tenfinney.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-first font-size-sm"
                            title="...">
                            Sample project for worldlaw.eth account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>



                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar20} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/20"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">0x1C0A...5A20</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for z0r0z.eth account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>


                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar2} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/2"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">jordanteague.eth</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for jordanteague.eth account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>






                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar6} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/6"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">erichdylus.eth</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for erichdylus.eth account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>







                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar1} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/1"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">taxdao.eth</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for taxdao.eth account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>


                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar19} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/129"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">JupiterBlock</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for JupiterBlock account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar9} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/141"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">cryptotaxguy.eth</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for cryptotaxguy.eth account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar10} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/142"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">jmasonbump.eth</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for jmasonbump.eth account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar21} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/assets/matic/0x9378f978c691f0247dd75f5efa4c77efb31b26c6/153"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member account: <span className="font-weight-bold text-first">dunsmoor.eth</span>
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            Access token for dunsmoor.eth account
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-2">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar5} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://opensea.io/collection/lexdao-cred"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-weight-bold text-primary"
                          title="...">
                          Member Accout: UNISSUED
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://opensea.io/assets/matic/0xa77e11b845e31e2c24ddc004fb8f93759c097274/496925736968920592695302422083945832576479845996267831971701744113549326"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-weight-bold text-black"
                            title="...">
                            UNISSUED AS OF 12/12/2022
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold text-first font-size-lg">
                    <CountUp
                      start={0}
                      end={10.0}
                      duration={6}
                      delay={2}
                      separator=""
                      decimals={0}
                      decimal=","
                    />
                    <a
                      href="https://opensea.io/collection/web3skills-smartlaw-clones-v4"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Web3skills Dashboard "
                      className="text-first">
                      {' '}
                      RNFT
                    </a>
                    <FontAwesomeIcon
                      icon={['fas', 'fingerprint']}
                      className="font-size-sm text-primary opacity-5 ml-1"
                    />
                  </div>
                </div>














              </PerfectScrollbar>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
