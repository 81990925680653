import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card } from '@material-ui/core';

// import avatar1 from '../../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../../assets/images/avatars/avatar2.jpg';
import avatar3 from '../../../assets/images/avatars/avatar3.jpg';

// import profile1 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-054.png';
import profile2 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-048.png';
// import profileImg011 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-011.png' ;
import profileImg003 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';

export default function LivePreviewExample() {
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xl={12}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                        SmartLaw_API Web3 Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                  Establishing a comprehensive database of all US and state-specific laws, legislation, and regulations pertaining to cryptography can be a daunting task. The following cards define a method of establishing such a database.
                  </h5>
                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profileImg003}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>
        <Grid item xl={6}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar3} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                        SmartLaw Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                  Identify relevant laws and regulations: 
                  </h5>
                  <p className="font-size-lg my-3 text-white-50">
                  Identify relevant laws and regulations: Start by identifying all relevant federal and state laws and regulations that relate to cryptography, digital tokens, decentralized finance, and non-fungible tokens. This can include laws related to securities, money laundering, privacy, and consumer protection, among others.
                  </p>
                  <p className="text-light">
                  Identify relevant laws and regulations: Start by identifying all relevant federal and state laws and regulations that relate to cryptography, digital tokens, decentralized finance, and non-fungible tokens. This can include laws related to securities, money laundering, privacy, and consumer protection, among others.                  </p>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profile2}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>
        <Grid item xl={6}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                        SmartLaw_API Web3 Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                  Gather the laws and regulations:
                  </h5>
                  <p className="font-size-lg my-3 text-white-50">
                  Gather the laws and regulations: Once the relevant laws and regulations have been identified, gather them in one place. This can be done by accessing online databases such as the Library of Congress, state government websites, and regulatory agency websites.
                  </p>
                  <p className="text-light">
                  Gather the laws and regulations: Once the relevant laws and regulations have been identified, gather them in one place. This can be done by accessing online databases such as the Library of Congress, state government websites, and regulatory agency websites.
                  </p>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profileImg003}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>

        <Grid item xl={6}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                        SmartLaw_API Web3 Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                  Create a searchable database: 
                  </h5>
                  <p className="font-size-lg my-3 text-white-50">
                  Create a searchable database: Create a database that allows users to search for laws and regulations related to a particular topic or theme. This can be done by creating a spreadsheet or using a specialized database management tool.
                  </p>
                  <p className="text-light">
                  Create a searchable database: Create a database that allows users to search for laws and regulations related to a particular topic or theme. This can be done by creating a spreadsheet or using a specialized database management tool.
                  </p>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profileImg003}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>

        <Grid item xl={6}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                        SmartLaw_API Web3 Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                  Update the database regularly: 
                  </h5>
                  <p className="font-size-lg my-3 text-white-50">
                  Update the database regularly: Laws and regulations related to cryptography, digital tokens, decentralized finance, and non-fungible tokens are constantly evolving. It is important to update the database regularly to ensure that it remains current and accurate.
                  </p>
                  <p className="text-light">
                  Update the database regularly: Laws and regulations related to cryptography, digital tokens, decentralized finance, and non-fungible tokens are constantly evolving. It is important to update the database regularly to ensure that it remains current and accurate.
                  </p>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profileImg003}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>
        <Grid item xl={6}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                        SmartLaw_API Web3 Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                  Make the database accessible: 
                  </h5>
                  <p className="font-size-lg my-3 text-white-50">
                  Make the database accessible: Once the database has been created and updated, make it accessible to the public. This can be done by hosting it on a website or making it available through a specialized platform.
                  </p>
                  <p className="text-light">
                  Make the database accessible: Once the database has been created and updated, make it accessible to the public. This can be done by hosting it on a website or making it available through a specialized platform.
                  </p>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profileImg003}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>

        <Grid item xl={6}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                        SmartLaw_API Web3 Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                  Promote the database: 
                  </h5>
                  <p className="font-size-lg my-3 text-white-50">
                  Promote the database: Finally, promote the database to ensure that it is widely used and valuable to the community. This can be done through social media, industry groups, and other channels.
                  </p>
                  <p className="text-light">
                  Promote the database: Finally, promote the database to ensure that it is widely used and valuable to the community. This can be done through social media, industry groups, and other channels.
                  </p>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profileImg003}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>

        <Grid item xl={12}>
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="card-img-wrapper rounded">
              <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="img-wrapper-overlay img-wrapper-overlay--visible shadow-none rounded">
                <div className="overlay-btn-wrapper p-4 p-lg-5 text-left text-white">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div className="avatar-icon shadow-sm-dark">
                        <img alt="..." src={avatar2} />
                      </div>
                    </div>
                    <div>
                      <div>Scott A3 Stevenson</div>
                      <span className="text-white-50">
                        SmartLaw_API Web3 Legal Engineer
                      </span>
                    </div>
                  </div>

                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <h5 className="font-weight-bold display-3 text-white">
                  By following these steps, it is possible to create a comprehensive and searchable database of all US and state-specific laws, legislation, and regulations pertaining to cryptography, digital tokens, decentralized finance, and non-fungible tokens.
                  </h5>
                  <div className="divider bg-white opacity-2 my-3 my-lg-5" />

                  <div className="d-flex align-items-center text-white-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-2" />
                    <small>added 3 days ago</small>
                  </div>
                </div>
                <div className="card-badges card-badges-bottom">
                  <div className="badge badge-first">Articles</div>
                </div>
              </a>
              <img
                src={profileImg003}
                className="card-overlay-image img-fit-container rounded"
                alt="..."
              />
            </div>
          </Card>
        </Grid>

      </Grid>
    </>
  );
}
