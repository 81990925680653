import React from 'react';

import { Grid, Container, Card } from '@material-ui/core';

// import stock1 from '../../../assets/images/stock-photos/stock-1.jpg';
// import stock2 from '../../../assets/images/stock-photos/stock-2.jpg';
// import stock3 from '../../../assets/images/stock-photos/stock-3.jpg';
// import stock4 from '../../../assets/images/stock-photos/stock-4.jpg';

// import legsol01 from '../../../assets/images/legal-solutions/legal-solutions-001.png';
// import legsol02 from '../../../assets/images/legal-solutions/legal-solutions-002.png';
// import legsol03 from '../../../assets/images/legal-solutions/legal-solutions-003.png';
// import legsol04 from '../../../assets/images/legal-solutions/legal-solutions-004.png';
import legsol05 from '../../../assets/images/legal-solutions/legal-solutions-005.png';
// import legsol06 from '../../../assets/images/legal-solutions/legal-solutions-006.png';
// import legsol07 from '../../../assets/images/legal-solutions/legal-solutions-007.png';
// import legsol08 from '../../../assets/images/legal-solutions/legal-solutions-008.png';
// import legsol09 from '../../../assets/images/legal-solutions/legal-solutions-009.png';
// import legsol10 from '../../../assets/images/legal-solutions/legal-solutions-010.png';
// import legsol11 from '../../../assets/images/legal-solutions/legal-solutions-011.png';
// import legsol12 from '../../../assets/images/legal-solutions/legal-solutions-012.png';
import legsol13 from '../../../assets/images/legal-solutions/legal-solutions-013.png';
// import legsol14 from '../../../assets/images/legal-solutions/legal-solutions-014.png';
// import legsol15 from '../../../assets/images/legal-solutions/legal-solutions-015.png';
// import legsol16 from '../../../assets/images/legal-solutions/legal-solutions-016.png';
import legsol17 from '../../../assets/images/legal-solutions/legal-solutions-017.png';
import legsol18 from '../../../assets/images/legal-solutions/legal-solutions-018.png';
// import legsol19 from '../../../assets/images/legal-solutions/legal-solutions-019.png';
// import legsol20 from '../../../assets/images/legal-solutions/legal-solutions-020.png';
// import legsol21 from '../../../assets/images/legal-solutions/legal-solutions-021.png';
// import legsol22 from '../../../assets/images/legal-solutions/legal-solutions-022.png';
// import legsol23 from '../../../assets/images/legal-solutions/legal-solutions-023.png';
// import legsol24 from '../../../assets/images/legal-solutions/legal-solutions-024.png';
import legsol25 from '../../../assets/images/legal-solutions/legal-solutions-025.png';
// import legsol26 from '../../../assets/images/legal-solutions/legal-solutions-026.png';




export default function LivePreviewExample() {
  return (
    <>
      <Card className="pt-5 mb-spacing-6-x2">
        <Container className="py-5">
          <Grid container spacing={6}>
            <Grid item md={6} xl={3}>
              <a
                href="#/"
                target="_blank" 
rel="noopener noreferrer"

                className="card bg-white shadow-xxl card-box-hover-rise mb-5">
                <img src={legsol18} className="card-img-top" alt="..." style={{ height: 150 }} />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                <img src={legsol17} alt="..." style={{ width: 200 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                  <span className="text-primary font-weight-bold font-size-lg opacity-9">
                  {'Metacollab Gigs '}
                </span>
                  allows unlimited layouts using any of the 500+
                    included components and elements. Powerful, unique template
                    built for React and Material-UI.
                  </p>
                </div>
              </a>
            </Grid>
            <Grid item md={6} xl={3}>
              <a
                href="#/"
                target="_blank" 
rel="noopener noreferrer"

                className="card bg-white shadow-xxl card-box-hover-rise mb-5">
                <img src={legsol25} className="card-img-top" alt="..." style={{ height: 150 }} />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={legsol17} alt="..." style={{ width: 200 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                  <span className="text-primary font-weight-bold font-size-lg opacity-9">
                  {'SmartLaw Tokens '}
                </span>
                    allows unlimited SmartLaw Tokens layouts using any of the 500+
                    included components and elements. Powerful, unique template
                    built for React and Material-UI.
                  </p>
                </div>
              </a>
            </Grid>
            <Grid item md={6} xl={3}>
              <a
                href="#/"
                target="_blank" 
rel="noopener noreferrer"

                className="card bg-white shadow-xxl card-box-hover-rise mb-5">
                <img src={legsol05} className="card-img-top" alt="..." style={{ height: 150 }} />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                <img src={legsol17} alt="..." style={{ width: 200 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                  <span className="text-primary font-weight-bold font-size-lg opacity-9">
                  {'Digital Evidence Management '}
                </span>
                    allows unlimited Digital Evidence layouts using any of the 500+
                    included components and elements. Powerful, unique template
                    built for React and Material-UI.
                  </p>
                </div>
              </a>
            </Grid>
            <Grid item md={6} xl={3}>
              <a
                href="#/"
                target="_blank" 
rel="noopener noreferrer"

                className="card bg-white shadow-xxl card-box-hover-rise mb-5">
                <img src={legsol13} className="card-img-top" alt="..." style={{ height: 150 }} />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={legsol17} alt="..." style={{ width: 200 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                  <span className="text-primary font-weight-bold font-size-lg opacity-9">
                  {'Alternative Dispute Resolution '}
                </span>
                    allows unlimited Dispute Resolution layouts using any of the 500+
                    included components and elements. Powerful, unique template
                    built for React and Material-UI.
                  </p>
                </div>
              </a>
            </Grid>
          </Grid>
        </Container>
      </Card>
    </>
  );
}
