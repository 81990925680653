import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Tooltip } from '@material-ui/core';

import CameraTwoToneIcon from '@material-ui/icons/CameraTwoTone';
import LocalPlayTwoToneIcon from '@material-ui/icons/LocalPlayTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';

const SidebarFooter = () => {
  return (
    <>
      <div className="app-sidebar--footer">
        <ul>
          <li>
            <Tooltip arrow placement="top" title="Web3Skills Courses">

            <Button
                component={NavLink}
                to="/About"
                size="large"
                variant="text"
                className="btn-transition-none d-40 mx-2 p-0">
                <CameraTwoToneIcon />
              </Button>
            </Tooltip>
          </li>
          <li>
            <Tooltip arrow placement="top" title="SmartLaw Tokens">
            <Button
                component={NavLink}
                to="/Mint"
                size="large"
                variant="text"
                className="btn-transition-none d-40 mx-2 p-0">
                <LocalPlayTwoToneIcon />
              </Button>

            </Tooltip>
          </li>
          <li>
            <Tooltip arrow placement="top" title="Metaverse Courthouse">
              <Button
                component={NavLink}
                to="/Metaverse"
                size="large"
                variant="text"
                className="btn-transition-none d-40 mx-2 p-0">
                <AccountBalanceTwoToneIcon />
              </Button>
            </Tooltip>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarFooter;
