import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';

import MarketingIcons1 from '../../example-components/Apps/MarketingIcons1';
import MarketingIcons6 from '../../example-components/Apps/MarketingIcons6';
import MarketingIcons11 from '../../example-components/Apps/MarketingIcons11';

export default function Apps() {
  return (
    <>
      <PageTitle
        titleHeading="SmartLaw Web3 Legal Solutions"
        titleDescription="A collection of Web3 courses, tools, applications and tokens for you journey into the new internet."
      />

      <ExampleWrapperSeamless>
        <MarketingIcons6 />
      </ExampleWrapperSeamless>
      <ExampleWrapperSeamless>
        <MarketingIcons1 />
      </ExampleWrapperSeamless>
      <ExampleWrapperSeamless>
        <MarketingIcons11 />
      </ExampleWrapperSeamless>
    </>
  );
}
