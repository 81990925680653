import React, { useState } from 'react';
import {
  Grid,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import Iframe from 'react-iframe';

export default function LivePreviewExample() {
  const [value, setValue] = useState('Controlled');
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={7}
                      xl={6}
                      className="d-flex align-items-center ">
                      <Grid item md={10} lg={8} xl={7} className="mx-auto">
                        <h1 className="display-4 mb-3 font-weight-bold">
                          Beneficiary Community Information
                        </h1>
                        <div className="mb-3">
                          <Iframe
                            id=""
                            src="https://airtable.com/embed/shr55OFqSvv0MnRhC?backgroundColor=gray"
                            frameborder="0"
                            onmousewheel=""
                            width="100%"
                            height="600"
                            // height="3485"
                            className="mb-3"
                            display="block"
                            position="relative"
                            background="transparent"
                            border="1px"
                          />

                          <label className="font-weight-bold mt-3 mb-2">
                            Community Narrative | A
                          </label>
                          <TextField
                            variant="outlined"
                            className=""
                            fullWidth
                            id="standard-multiline-flexible"
                            label="Multiline"
                            multiline
                            rowsMax="4"
                            value={value}
                            onChange={handleChange}
                          />
                          <label className="font-weight-bold mt-3 mb-2">
                            Community Narrative | B
                          </label>
                          <TextField
                            variant="outlined"
                            className=""
                            fullWidth
                            id="standard-textarea"
                            label="Multiline Placeholder"
                            placeholder="Placeholder"
                            multiline
                          />
                          <label className="font-weight-bold mt-3 mb-3">
                            Community Narrative | C
                          </label>
                          <TextField
                            variant="outlined"
                            className=""
                            fullWidth
                            id="standard-multiline-static"
                            label="Multiline"
                            multiline
                            rows="4"
                            defaultValue="Grant field default value"
                          />
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Community Narrative | 044
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 044 | "
                            name="community_narrative_formfield_044"
                            type="textarea"
                            helperText="Helper note sits just below the form field 044 | "
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Describe your community | 045
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 045 | "
                            name="describe_the_community_formfield_045"
                            type="textarea"
                            helperText="Helper note sits just below the form field 045 | "
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Do you intend to include more than one project for
                              the grant? Yes/No | 046
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 046 | "
                            name="more_than_one_project_formfield_046"
                            type="boolean"
                            helperText="Helper note sits just below the form field 046 | Do you intend to include more than one project for the SmartLaw grant?* Yes/No"
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Explain why SmartLaw is a good fit for your
                              community. | 047
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 047 | "
                            name="why_community_good_formfield_047"
                            type="textarea"
                            helperText="Helper note sits just below the form field 047 | "
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Why are you interested in implementing a project
                              for SmartLaw? | 048
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 048 | "
                            name="why_implementing_a_project_formfield_048"
                            type="textarea"
                            helperText="Helper note sits just below the form field 048 | "
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              What do you hope the process will accomplish for
                              your community? | 049
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 049 | "
                            name="intended_community_accomplish_formfield_049"
                            type="textarea"
                            helperText="Helper note sits just below the form field 049 | Tell us about the group of resident leaders involved in submitting this application and your plans to build for SmartLaw.*"
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Describe the partner organizations supporting this
                              project. | 050
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 050 | "
                            name="partner_org_desc_formfield_050"
                            type="textarea"
                            helperText="Helper note sits just below the form field 050 | Who are your partners and what do they bring to the table? What, if any, experience do the partners have in collaborating on community issues or projects?"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item lg={5} xl={6} className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image opacity-5"
                            style={{
                              backgroundImage: 'url(' + sideImage + ')'
                            }}
                          />
                          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                          <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                          <div className="bg-composed-wrapper--content text-center p-5">
                            <div className="text-white px-0 px-lg-2 px-xl-4">
                              <h1 className="display-3 mb-4 font-weight-bold text-white">
                              Beneficiary Community Information
                              </h1>
                              <p className="font-size-lg mb-0 opacity-8">
                                SmartLaw provides tools for Web3 ecosystem to
                                advance legaltech. 11.
                              </p>
                              <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                              <div>
                                <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                                  <span className="btn-wrapper--label">
                                    Learn more about Grant Beneficiary Community Information
                                  </span>
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon
                                      icon={['fas', 'arrow-right']}
                                    />
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hero-footer pb-4">
                          <List
                            component="div"
                            className="nav-pills nav-neutral-secondary d-flex">
                            <Tooltip title="Github" arrow>
                              <ListItem
                                component="a"
                                button
                                href="#/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-size-lg text-white-50">
                                <FontAwesomeIcon icon={['fab', 'github']} />
                              </ListItem>
                            </Tooltip>
                          </List>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
    </>
  );
}
