import React from 'react';

import { Container, Card } from '@material-ui/core';

import noob_lev1 from '../../../assets/images/badges2020/01-noob-level_1-200w.png';
import noob_lev2 from '../../../assets/images/badges2020/01-noob-level_2-200w.png';
import noob_lev3 from '../../../assets/images/badges2020/01-noob-level_3-200w.png';
import noob_lev4 from '../../../assets/images/badges2020/01-noob-level_4-200w.png';
import noob_lev5 from '../../../assets/images/badges2020/01-noob-level_5-200w.png';
import noob_lev6 from '../../../assets/images/badges2020/01-noob-level_6-200w.png';
import accountmaker_lev1 from '../../../assets/images/badges2020/02-account_maker-level_1-200w.png';
import accountmaker_lev2 from '../../../assets/images/badges2020/02-account_maker-level_2-200w.png';
import accountmaker_lev3 from '../../../assets/images/badges2020/02-account_maker-level_3-200w.png';
import accountmaker_lev4 from '../../../assets/images/badges2020/02-account_maker-level_4-200w.png';
import accountmaker_lev5 from '../../../assets/images/badges2020/02-account_maker-level_5-200w.png';
import accountmaker_lev6 from '../../../assets/images/badges2020/02-account_maker-level_6-200w.png';
import airdropsurprise_lev1 from '../../../assets/images/badges2020/03-airdrop_surprise-level_1-200w.png';
import airdropsurprise_lev2 from '../../../assets/images/badges2020/03-airdrop_surprise-level_2-200w.png';
import airdropsurprise_lev3 from '../../../assets/images/badges2020/03-airdrop_surprise-level_3-200w.png';
import airdropsurprise_lev4 from '../../../assets/images/badges2020/03-airdrop_surprise-level_4-200w.png';
import airdropsurprise_lev5 from '../../../assets/images/badges2020/03-airdrop_surprise-level_5-200w.png';
import airdropsurprise_lev6 from '../../../assets/images/badges2020/03-airdrop_surprise-level_6-200w.png';
import decentralizer_lev1 from '../../../assets/images/badges2020/04-decentralizer-level_1-200w.png';
import decentralizer_lev2 from '../../../assets/images/badges2020/04-decentralizer-level_2-200w.png';
import decentralizer_lev3 from '../../../assets/images/badges2020/04-decentralizer-level_3-200w.png';
import decentralizer_lev4 from '../../../assets/images/badges2020/04-decentralizer-level_4-200w.png';
import decentralizer_lev5 from '../../../assets/images/badges2020/04-decentralizer-level_5-200w.png';
import decentralizer_lev6 from '../../../assets/images/badges2020/04-decentralizer-level_6-200w.png';
import ethereum_lev1 from '../../../assets/images/badges2020/05-ethereum-level_1-200w.png';
import ethereum_lev2 from '../../../assets/images/badges2020/05-ethereum-level_2-200w.png';
import ethereum_lev3 from '../../../assets/images/badges2020/05-ethereum-level_3-200w.png';
import ethereum_lev4 from '../../../assets/images/badges2020/05-ethereum-level_4-200w.png';
import ethereum_lev5 from '../../../assets/images/badges2020/05-ethereum-level_5-200w.png';
import ethereum_lev6 from '../../../assets/images/badges2020/05-ethereum-level_6-200w.png';
import web3vmuser_lev1 from '../../../assets/images/badges2020/06-web3vm_user-level_1-200w.png';
import web3vmuser_lev2 from '../../../assets/images/badges2020/06-web3vm_user-level_2-200w.png';
import web3vmuser_lev3 from '../../../assets/images/badges2020/06-web3vm_user-level_3-200w.png';
import web3vmuser_lev4 from '../../../assets/images/badges2020/06-web3vm_user-level_4-200w.png';
import web3vmuser_lev5 from '../../../assets/images/badges2020/06-web3vm_user-level_5-200w.png';
import web3vmuser_lev6 from '../../../assets/images/badges2020/06-web3vm_user-level_6-200w.png';
import nodist_lev1 from '../../../assets/images/badges2020/07-nodist-level_1-200w.png';
import nodist_lev2 from '../../../assets/images/badges2020/07-nodist-level_2-200w.png';
import nodist_lev3 from '../../../assets/images/badges2020/07-nodist-level_3-200w.png';
import nodist_lev4 from '../../../assets/images/badges2020/07-nodist-level_4-200w.png';
import nodist_lev5 from '../../../assets/images/badges2020/07-nodist-level_5-200w.png';
import nodist_lev6 from '../../../assets/images/badges2020/07-nodist-level_6-200w.png';
import transfertech_lev1 from '../../../assets/images/badges2020/08-transfer_tech-level_1-200w.png';
import transfertech_lev2 from '../../../assets/images/badges2020/08-transfer_tech-level_2-200w.png';
import transfertech_lev3 from '../../../assets/images/badges2020/08-transfer_tech-level_3-200w.png';
import transfertech_lev4 from '../../../assets/images/badges2020/08-transfer_tech-level_4-200w.png';
import transfertech_lev5 from '../../../assets/images/badges2020/08-transfer_tech-level_5-200w.png';
import transfertech_lev6 from '../../../assets/images/badges2020/08-transfer_tech-level_6-200w.png';
import tidbits_lev1 from '../../../assets/images/badges2020/09-tidbits-level_1-200w.png';
import tidbits_lev2 from '../../../assets/images/badges2020/09-tidbits-level_2-200w.png';
import tidbits_lev3 from '../../../assets/images/badges2020/09-tidbits-level_3-200w.png';
import tidbits_lev4 from '../../../assets/images/badges2020/09-tidbits-level_4-200w.png';
import tidbits_lev5 from '../../../assets/images/badges2020/09-tidbits-level_5-200w.png';
import tidbits_lev6 from '../../../assets/images/badges2020/09-tidbits-level_6-200w.png';
import lawdler_lev1 from '../../../assets/images/badges2020/10-lawdler-level_1-200w.png';
import lawdler_lev2 from '../../../assets/images/badges2020/10-lawdler-level_2-200w.png';
import lawdler_lev3 from '../../../assets/images/badges2020/10-lawdler-level_3-200w.png';
import lawdler_lev4 from '../../../assets/images/badges2020/10-lawdler-level_4-200w.png';
import lawdler_lev5 from '../../../assets/images/badges2020/10-lawdler-level_5-200w.png';
import lawdler_lev6 from '../../../assets/images/badges2020/10-lawdler-level_6-200w.png';
import magician_lev1 from '../../../assets/images/badges2020/11-magician-level_1-200w.png';
import magician_lev2 from '../../../assets/images/badges2020/11-magician-level_2-200w.png';
import magician_lev3 from '../../../assets/images/badges2020/11-magician-level_3-200w.png';
import magician_lev4 from '../../../assets/images/badges2020/11-magician-level_4-200w.png';
import magician_lev5 from '../../../assets/images/badges2020/11-magician-level_5-200w.png';
import magician_lev6 from '../../../assets/images/badges2020/11-magician-level_6-200w.png';
import wardens_lev1 from '../../../assets/images/badges2020/12-wardens-level_1-200w.png';
import wardens_lev2 from '../../../assets/images/badges2020/12-wardens-level_2-200w.png';
import wardens_lev3 from '../../../assets/images/badges2020/12-wardens-level_3-200w.png';
import wardens_lev4 from '../../../assets/images/badges2020/12-wardens-level_4-200w.png';
import wardens_lev5 from '../../../assets/images/badges2020/12-wardens-level_5-200w.png';
import wardens_lev6 from '../../../assets/images/badges2020/12-wardens-level_6-200w.png';
import brickler_lev1 from '../../../assets/images/badges2020/13-brickler-level_1-200w.png';
import brickler_lev2 from '../../../assets/images/badges2020/13-brickler-level_2-200w.png';
import brickler_lev3 from '../../../assets/images/badges2020/13-brickler-level_3-200w.png';
import brickler_lev4 from '../../../assets/images/badges2020/13-brickler-level_4-200w.png';
import brickler_lev5 from '../../../assets/images/badges2020/13-brickler-level_5-200w.png';
import brickler_lev6 from '../../../assets/images/badges2020/13-brickler-level_6-200w.png';
import servicepro_lev1 from '../../../assets/images/badges2020/14-service_pro-level_1-200w.png';
import servicepro_lev2 from '../../../assets/images/badges2020/14-service_pro-level_2-200w.png';
import servicepro_lev3 from '../../../assets/images/badges2020/14-service_pro-level_3-200w.png';
import servicepro_lev4 from '../../../assets/images/badges2020/14-service_pro-level_4-200w.png';
import servicepro_lev5 from '../../../assets/images/badges2020/14-service_pro-level_5-200w.png';
import servicepro_lev6 from '../../../assets/images/badges2020/14-service_pro-level_6-200w.png';
import insurer_lev1 from '../../../assets/images/badges2020/15-insurer-level_1-200w.png';
import insurer_lev2 from '../../../assets/images/badges2020/15-insurer-level_2-200w.png';
import insurer_lev3 from '../../../assets/images/badges2020/15-insurer-level_3-200w.png';
import insurer_lev4 from '../../../assets/images/badges2020/15-insurer-level_4-200w.png';
import insurer_lev5 from '../../../assets/images/badges2020/15-insurer-level_5-200w.png';
import insurer_lev6 from '../../../assets/images/badges2020/15-insurer-level_6-200w.png';
import helpinghand_lev1 from '../../../assets/images/badges2020/16-helping_hand-level_1-200w.png';
import helpinghand_lev2 from '../../../assets/images/badges2020/16-helping_hand-level_2-200w.png';
import helpinghand_lev3 from '../../../assets/images/badges2020/16-helping_hand-level_3-200w.png';
import helpinghand_lev4 from '../../../assets/images/badges2020/16-helping_hand-level_4-200w.png';
import helpinghand_lev5 from '../../../assets/images/badges2020/16-helping_hand-level_5-200w.png';
import helpinghand_lev6 from '../../../assets/images/badges2020/16-helping_hand-level_6-200w.png';
import documenteer_lev1 from '../../../assets/images/badges2020/17-documenteer-level_1-200w.png';
import documenteer_lev2 from '../../../assets/images/badges2020/17-documenteer-level_2-200w.png';
import documenteer_lev3 from '../../../assets/images/badges2020/17-documenteer-level_3-200w.png';
import documenteer_lev4 from '../../../assets/images/badges2020/17-documenteer-level_4-200w.png';
import documenteer_lev5 from '../../../assets/images/badges2020/17-documenteer-level_5-200w.png';
import documenteer_lev6 from '../../../assets/images/badges2020/17-documenteer-level_6-200w.png';
import judiciary_lev1 from '../../../assets/images/badges2020/18-judiciary-level_1-200w.png';
import judiciary_lev2 from '../../../assets/images/badges2020/18-judiciary-level_2-200w.png';
import judiciary_lev3 from '../../../assets/images/badges2020/18-judiciary-level_3-200w.png';
import judiciary_lev4 from '../../../assets/images/badges2020/18-judiciary-level_4-200w.png';
import judiciary_lev5 from '../../../assets/images/badges2020/18-judiciary-level_5-200w.png';
import judiciary_lev6 from '../../../assets/images/badges2020/18-judiciary-level_6-200w.png';
import resolver_lev1 from '../../../assets/images/badges2020/19-resolver-level_1-200w.png';
import resolver_lev2 from '../../../assets/images/badges2020/19-resolver-level_2-200w.png';
import resolver_lev3 from '../../../assets/images/badges2020/19-resolver-level_3-200w.png';
import resolver_lev4 from '../../../assets/images/badges2020/19-resolver-level_4-200w.png';
import resolver_lev5 from '../../../assets/images/badges2020/19-resolver-level_5-200w.png';
import resolver_lev6 from '../../../assets/images/badges2020/19-resolver-level_6-200w.png';
import teamplayer_lev1 from '../../../assets/images/badges2020/20-team_player-level_1-200w.png';
import teamplayer_lev2 from '../../../assets/images/badges2020/20-team_player-level_2-200w.png';
import teamplayer_lev3 from '../../../assets/images/badges2020/20-team_player-level_3-200w.png';
import teamplayer_lev4 from '../../../assets/images/badges2020/20-team_player-level_4-200w.png';
import teamplayer_lev5 from '../../../assets/images/badges2020/20-team_player-level_5-200w.png';
import teamplayer_lev6 from '../../../assets/images/badges2020/20-team_player-level_6-200w.png';
import internationaler_lev1 from '../../../assets/images/badges2020/21-internationaler-level_1-200w.png';
import internationaler_lev2 from '../../../assets/images/badges2020/21-internationaler-level_2-200w.png';
import internationaler_lev3 from '../../../assets/images/badges2020/21-internationaler-level_3-200w.png';
import internationaler_lev4 from '../../../assets/images/badges2020/21-internationaler-level_4-200w.png';
import internationaler_lev5 from '../../../assets/images/badges2020/21-internationaler-level_5-200w.png';
import internationaler_lev6 from '../../../assets/images/badges2020/21-internationaler-level_6-200w.png';
import miner_lev1 from '../../../assets/images/badges2020/22-miner-level_1-200w.png';
import miner_lev2 from '../../../assets/images/badges2020/22-miner-level_2-200w.png';
import miner_lev3 from '../../../assets/images/badges2020/22-miner-level_3-200w.png';
import miner_lev4 from '../../../assets/images/badges2020/22-miner-level_4-200w.png';
import miner_lev5 from '../../../assets/images/badges2020/22-miner-level_5-200w.png';
import miner_lev6 from '../../../assets/images/badges2020/22-miner-level_6-200w.png';
import architect_lev1 from '../../../assets/images/badges2020/23-architect-level_1-200w.png';
import architect_lev2 from '../../../assets/images/badges2020/23-architect-level_2-200w.png';
import architect_lev3 from '../../../assets/images/badges2020/23-architect-level_3-200w.png';
import architect_lev4 from '../../../assets/images/badges2020/23-architect-level_4-200w.png';
import architect_lev5 from '../../../assets/images/badges2020/23-architect-level_5-200w.png';
import architect_lev6 from '../../../assets/images/badges2020/23-architect-level_6-200w.png';
import bigbrain_lev1 from '../../../assets/images/badges2020/24-big_brain-level_1-200w.png';
import bigbrain_lev2 from '../../../assets/images/badges2020/24-big_brain-level_2-200w.png';
import bigbrain_lev3 from '../../../assets/images/badges2020/24-big_brain-level_3-200w.png';
import bigbrain_lev4 from '../../../assets/images/badges2020/24-big_brain-level_4-200w.png';
import bigbrain_lev5 from '../../../assets/images/badges2020/24-big_brain-level_5-200w.png';
import bigbrain_lev6 from '../../../assets/images/badges2020/24-big_brain-level_6-200w.png';
import announcer_lev1 from '../../../assets/images/badges2020/25-announcer-level_1-200w.png';
import announcer_lev2 from '../../../assets/images/badges2020/25-announcer-level_2-200w.png';
import announcer_lev3 from '../../../assets/images/badges2020/25-announcer-level_3-200w.png';
import announcer_lev4 from '../../../assets/images/badges2020/25-announcer-level_4-200w.png';
import announcer_lev5 from '../../../assets/images/badges2020/25-announcer-level_5-200w.png';
import announcer_lev6 from '../../../assets/images/badges2020/25-announcer-level_6-200w.png';
import beaconer_lev1 from '../../../assets/images/badges2020/26-beaconer-level_1-200w.png';
import beaconer_lev2 from '../../../assets/images/badges2020/26-beaconer-level_2-200w.png';
import beaconer_lev3 from '../../../assets/images/badges2020/26-beaconer-level_3-200w.png';
import beaconer_lev4 from '../../../assets/images/badges2020/26-beaconer-level_4-200w.png';
import beaconer_lev5 from '../../../assets/images/badges2020/26-beaconer-level_5-200w.png';
import beaconer_lev6 from '../../../assets/images/badges2020/26-beaconer-level_6-200w.png';
import detector_lev1 from '../../../assets/images/badges2020/27-detector-level_1-200w.png';
import detector_lev2 from '../../../assets/images/badges2020/27-detector-level_2-200w.png';
import detector_lev3 from '../../../assets/images/badges2020/27-detector-level_3-200w.png';
import detector_lev4 from '../../../assets/images/badges2020/27-detector-level_4-200w.png';
import detector_lev5 from '../../../assets/images/badges2020/27-detector-level_5-200w.png';
import detector_lev6 from '../../../assets/images/badges2020/27-detector-level_6-200w.png';
import hunter_lev1 from '../../../assets/images/badges2020/28-hunter-level_1-200w.png';
import hunter_lev2 from '../../../assets/images/badges2020/28-hunter-level_2-200w.png';
import hunter_lev3 from '../../../assets/images/badges2020/28-hunter-level_3-200w.png';
import hunter_lev4 from '../../../assets/images/badges2020/28-hunter-level_4-200w.png';
import hunter_lev5 from '../../../assets/images/badges2020/28-hunter-level_5-200w.png';
import hunter_lev6 from '../../../assets/images/badges2020/28-hunter-level_6-200w.png';
import iamidea_lev1 from '../../../assets/images/badges2020/29-iam_idea-level_1-200w.png';
import iamidea_lev2 from '../../../assets/images/badges2020/29-iam_idea-level_2-200w.png';
import iamidea_lev3 from '../../../assets/images/badges2020/29-iam_idea-level_3-200w.png';
import iamidea_lev4 from '../../../assets/images/badges2020/29-iam_idea-level_4-200w.png';
import iamidea_lev5 from '../../../assets/images/badges2020/29-iam_idea-level_5-200w.png';
import iamidea_lev6 from '../../../assets/images/badges2020/29-iam_idea-level_6-200w.png';
import researcher_lev1 from '../../../assets/images/badges2020/30-researcher-level_1-200w.png';
import researcher_lev2 from '../../../assets/images/badges2020/30-researcher-level_2-200w.png';
import researcher_lev3 from '../../../assets/images/badges2020/30-researcher-level_3-200w.png';
import researcher_lev4 from '../../../assets/images/badges2020/30-researcher-level_4-200w.png';
import researcher_lev5 from '../../../assets/images/badges2020/30-researcher-level_5-200w.png';
import researcher_lev6 from '../../../assets/images/badges2020/30-researcher-level_6-200w.png';
import networker_lev1 from '../../../assets/images/badges2020/31-networker-level_1-200w.png';
import networker_lev2 from '../../../assets/images/badges2020/31-networker-level_2-200w.png';
import networker_lev3 from '../../../assets/images/badges2020/31-networker-level_3-200w.png';
import networker_lev4 from '../../../assets/images/badges2020/31-networker-level_4-200w.png';
import networker_lev5 from '../../../assets/images/badges2020/31-networker-level_5-200w.png';
import networker_lev6 from '../../../assets/images/badges2020/31-networker-level_6-200w.png';
import renewer_lev1 from '../../../assets/images/badges2020/32-renewer-level_1-200w.png';
import renewer_lev2 from '../../../assets/images/badges2020/32-renewer-level_2-200w.png';
import renewer_lev3 from '../../../assets/images/badges2020/32-renewer-level_3-200w.png';
import renewer_lev4 from '../../../assets/images/badges2020/32-renewer-level_4-200w.png';
import renewer_lev5 from '../../../assets/images/badges2020/32-renewer-level_5-200w.png';
import renewer_lev6 from '../../../assets/images/badges2020/32-renewer-level_6-200w.png';
import mechanic_lev1 from '../../../assets/images/badges2020/33-mechanic-level_1-200w.png';
import mechanic_lev2 from '../../../assets/images/badges2020/33-mechanic-level_2-200w.png';
import mechanic_lev3 from '../../../assets/images/badges2020/33-mechanic-level_3-200w.png';
import mechanic_lev4 from '../../../assets/images/badges2020/33-mechanic-level_4-200w.png';
import mechanic_lev5 from '../../../assets/images/badges2020/33-mechanic-level_5-200w.png';
import mechanic_lev6 from '../../../assets/images/badges2020/33-mechanic-level_6-200w.png';
import heavymech_lev1 from '../../../assets/images/badges2020/34-heavy_mech-level_1-200w.png';
import heavymech_lev2 from '../../../assets/images/badges2020/34-heavy_mech-level_2-200w.png';
import heavymech_lev3 from '../../../assets/images/badges2020/34-heavy_mech-level_3-200w.png';
import heavymech_lev4 from '../../../assets/images/badges2020/34-heavy_mech-level_4-200w.png';
import heavymech_lev5 from '../../../assets/images/badges2020/34-heavy_mech-level_5-200w.png';
import heavymech_lev6 from '../../../assets/images/badges2020/34-heavy_mech-level_6-200w.png';
import firestarter_lev1 from '../../../assets/images/badges2020/35-fire_starter-level_1-200w.png';
import firestarter_lev2 from '../../../assets/images/badges2020/35-fire_starter-level_2-200w.png';
import firestarter_lev3 from '../../../assets/images/badges2020/35-fire_starter-level_3-200w.png';
import firestarter_lev4 from '../../../assets/images/badges2020/35-fire_starter-level_4-200w.png';
import firestarter_lev5 from '../../../assets/images/badges2020/35-fire_starter-level_5-200w.png';
import firestarter_lev6 from '../../../assets/images/badges2020/35-fire_starter-level_6-200w.png';
import navigator_lev1 from '../../../assets/images/badges2020/36-navigator-level_1-200w.png';
import navigator_lev2 from '../../../assets/images/badges2020/36-navigator-level_2-200w.png';
import navigator_lev3 from '../../../assets/images/badges2020/36-navigator-level_3-200w.png';
import navigator_lev4 from '../../../assets/images/badges2020/36-navigator-level_4-200w.png';
import navigator_lev5 from '../../../assets/images/badges2020/36-navigator-level_5-200w.png';
import navigator_lev6 from '../../../assets/images/badges2020/36-navigator-level_6-200w.png';
import strategist_lev1 from '../../../assets/images/badges2020/37-strategist-level_1-200w.png';
import strategist_lev2 from '../../../assets/images/badges2020/37-strategist-level_2-200w.png';
import strategist_lev3 from '../../../assets/images/badges2020/37-strategist-level_3-200w.png';
import strategist_lev4 from '../../../assets/images/badges2020/37-strategist-level_4-200w.png';
import strategist_lev5 from '../../../assets/images/badges2020/37-strategist-level_5-200w.png';
import strategist_lev6 from '../../../assets/images/badges2020/37-strategist-level_6-200w.png';
import needlethreader_lev1 from '../../../assets/images/badges2020/38-needle_threader-level_1-200w.png';
import needlethreader_lev2 from '../../../assets/images/badges2020/38-needle_threader-level_2-200w.png';
import needlethreader_lev3 from '../../../assets/images/badges2020/38-needle_threader-level_3-200w.png';
import needlethreader_lev4 from '../../../assets/images/badges2020/38-needle_threader-level_4-200w.png';
import needlethreader_lev5 from '../../../assets/images/badges2020/38-needle_threader-level_5-200w.png';
import needlethreader_lev6 from '../../../assets/images/badges2020/38-needle_threader-level_6-200w.png';
import socialsupporter_lev1 from '../../../assets/images/badges2020/39-social_supporter-level_1-200w.png';
import socialsupporter_lev2 from '../../../assets/images/badges2020/39-social_supporter-level_2-200w.png';
import socialsupporter_lev3 from '../../../assets/images/badges2020/39-social_supporter-level_3-200w.png';
import socialsupporter_lev4 from '../../../assets/images/badges2020/39-social_supporter-level_4-200w.png';
import socialsupporter_lev5 from '../../../assets/images/badges2020/39-social_supporter-level_5-200w.png';
import socialsupporter_lev6 from '../../../assets/images/badges2020/39-social_supporter-level_6-200w.png';
import spacetraveler_lev1 from '../../../assets/images/badges2020/40-space_traveler-level_1-200w.png';
import spacetraveler_lev2 from '../../../assets/images/badges2020/40-space_traveler-level_2-200w.png';
import spacetraveler_lev3 from '../../../assets/images/badges2020/40-space_traveler-level_3-200w.png';
import spacetraveler_lev4 from '../../../assets/images/badges2020/40-space_traveler-level_4-200w.png';
import spacetraveler_lev5 from '../../../assets/images/badges2020/40-space_traveler-level_5-200w.png';
import spacetraveler_lev6 from '../../../assets/images/badges2020/40-space_traveler-level_6-200w.png';
import entertainer_lev1 from '../../../assets/images/badges2020/41-entertainer-level_1-200w.png';
import entertainer_lev2 from '../../../assets/images/badges2020/41-entertainer-level_2-200w.png';
import entertainer_lev3 from '../../../assets/images/badges2020/41-entertainer-level_3-200w.png';
import entertainer_lev4 from '../../../assets/images/badges2020/41-entertainer-level_4-200w.png';
import entertainer_lev5 from '../../../assets/images/badges2020/41-entertainer-level_5-200w.png';
import entertainer_lev6 from '../../../assets/images/badges2020/41-entertainer-level_6-200w.png';
import seenandheard_lev1 from '../../../assets/images/badges2020/42-seen_and_heard-level_1-200w.png';
import seenandheard_lev2 from '../../../assets/images/badges2020/42-seen_and_heard-level_2-200w.png';
import seenandheard_lev3 from '../../../assets/images/badges2020/42-seen_and_heard-level_3-200w.png';
import seenandheard_lev4 from '../../../assets/images/badges2020/42-seen_and_heard-level_4-200w.png';
import seenandheard_lev5 from '../../../assets/images/badges2020/42-seen_and_heard-level_5-200w.png';
import seenandheard_lev6 from '../../../assets/images/badges2020/42-seen_and_heard-level_6-200w.png';
import futureself_lev1 from '../../../assets/images/badges2020/43-future_self-level_1-200w.png';
import futureself_lev2 from '../../../assets/images/badges2020/43-future_self-level_2-200w.png';
import futureself_lev3 from '../../../assets/images/badges2020/43-future_self-level_3-200w.png';
import futureself_lev4 from '../../../assets/images/badges2020/43-future_self-level_4-200w.png';
import futureself_lev5 from '../../../assets/images/badges2020/43-future_self-level_5-200w.png';
import futureself_lev6 from '../../../assets/images/badges2020/43-future_self-level_6-200w.png';
import pawntokener_lev1 from '../../../assets/images/badges2020/44-pawn_tokener-level_1-200w.png';
import pawntokener_lev2 from '../../../assets/images/badges2020/44-pawn_tokener-level_2-200w.png';
import pawntokener_lev3 from '../../../assets/images/badges2020/44-pawn_tokener-level_3-200w.png';
import pawntokener_lev4 from '../../../assets/images/badges2020/44-pawn_tokener-level_4-200w.png';
import pawntokener_lev5 from '../../../assets/images/badges2020/44-pawn_tokener-level_5-200w.png';
import pawntokener_lev6 from '../../../assets/images/badges2020/44-pawn_tokener-level_6-200w.png';
import bigshow_lev1 from '../../../assets/images/badges2020/45-big_show-level_1-200w.png';
import bigshow_lev2 from '../../../assets/images/badges2020/45-big_show-level_2-200w.png';
import bigshow_lev3 from '../../../assets/images/badges2020/45-big_show-level_3-200w.png';
import bigshow_lev4 from '../../../assets/images/badges2020/45-big_show-level_4-200w.png';
import bigshow_lev5 from '../../../assets/images/badges2020/45-big_show-level_5-200w.png';
import bigshow_lev6 from '../../../assets/images/badges2020/45-big_show-level_6-200w.png';
import feast_lev1 from '../../../assets/images/badges2020/46-feast-level_1-200w.png';
import feast_lev2 from '../../../assets/images/badges2020/46-feast-level_2-200w.png';
import feast_lev3 from '../../../assets/images/badges2020/46-feast-level_3-200w.png';
import feast_lev4 from '../../../assets/images/badges2020/46-feast-level_4-200w.png';
import feast_lev5 from '../../../assets/images/badges2020/46-feast-level_5-200w.png';
import feast_lev6 from '../../../assets/images/badges2020/46-feast-level_6-200w.png';
import surprise_lev1 from '../../../assets/images/badges2020/47-surprise-level_1-200w.png';
import surprise_lev2 from '../../../assets/images/badges2020/47-surprise-level_2-200w.png';
import surprise_lev3 from '../../../assets/images/badges2020/47-surprise-level_3-200w.png';
import surprise_lev4 from '../../../assets/images/badges2020/47-surprise-level_4-200w.png';
import surprise_lev5 from '../../../assets/images/badges2020/47-surprise-level_5-200w.png';
import surprise_lev6 from '../../../assets/images/badges2020/47-surprise-level_6-200w.png';
import onfire_lev1 from '../../../assets/images/badges2020/48-on_fire-level_1-200w.png';
import onfire_lev2 from '../../../assets/images/badges2020/48-on_fire-level_2-200w.png';
import onfire_lev3 from '../../../assets/images/badges2020/48-on_fire-level_3-200w.png';
import onfire_lev4 from '../../../assets/images/badges2020/48-on_fire-level_4-200w.png';
import onfire_lev5 from '../../../assets/images/badges2020/48-on_fire-level_5-200w.png';
import onfire_lev6 from '../../../assets/images/badges2020/48-on_fire-level_6-200w.png';
import goodshow_lev1 from '../../../assets/images/badges2020/49-good_show-level_1-200w.png';
import goodshow_lev2 from '../../../assets/images/badges2020/49-good_show-level_2-200w.png';
import goodshow_lev3 from '../../../assets/images/badges2020/49-good_show-level_3-200w.png';
import goodshow_lev4 from '../../../assets/images/badges2020/49-good_show-level_4-200w.png';
import goodshow_lev5 from '../../../assets/images/badges2020/49-good_show-level_5-200w.png';
import goodshow_lev6 from '../../../assets/images/badges2020/49-good_show-level_6-200w.png';
import leisurelypursuits_lev1 from '../../../assets/images/badges2020/50-leisurely_pursuits-level_1-200w.png';
import leisurelypursuits_lev2 from '../../../assets/images/badges2020/50-leisurely_pursuits-level_2-200w.png';
import leisurelypursuits_lev3 from '../../../assets/images/badges2020/50-leisurely_pursuits-level_3-200w.png';
import leisurelypursuits_lev4 from '../../../assets/images/badges2020/50-leisurely_pursuits-level_4-200w.png';
import leisurelypursuits_lev5 from '../../../assets/images/badges2020/50-leisurely_pursuits-level_5-200w.png';
import leisurelypursuits_lev6 from '../../../assets/images/badges2020/50-leisurely_pursuits-level_6-200w.png';
import bondedpig_lev1 from '../../../assets/images/badges2020/51-bonded_pig-level_1-200w.png';
import bondedpig_lev2 from '../../../assets/images/badges2020/51-bonded_pig-level_2-200w.png';
import bondedpig_lev3 from '../../../assets/images/badges2020/51-bonded_pig-level_3-200w.png';
import bondedpig_lev4 from '../../../assets/images/badges2020/51-bonded_pig-level_4-200w.png';
import bondedpig_lev5 from '../../../assets/images/badges2020/51-bonded_pig-level_5-200w.png';
import bondedpig_lev6 from '../../../assets/images/badges2020/51-bonded_pig-level_6-200w.png';
import whocdc_lev1 from '../../../assets/images/badges2020/52-who_cdc-level_1-200w.png';
import whocdc_lev2 from '../../../assets/images/badges2020/52-who_cdc-level_2-200w.png';
import whocdc_lev3 from '../../../assets/images/badges2020/52-who_cdc-level_3-200w.png';
import whocdc_lev4 from '../../../assets/images/badges2020/52-who_cdc-level_4-200w.png';
import whocdc_lev5 from '../../../assets/images/badges2020/52-who_cdc-level_5-200w.png';
import whocdc_lev6 from '../../../assets/images/badges2020/52-who_cdc-level_6-200w.png';
import bughunter_lev1 from '../../../assets/images/badges2020/53-bug_hunter-level_1-200w.png';
import bughunter_lev2 from '../../../assets/images/badges2020/53-bug_hunter-level_2-200w.png';
import bughunter_lev3 from '../../../assets/images/badges2020/53-bug_hunter-level_3-200w.png';
import bughunter_lev4 from '../../../assets/images/badges2020/53-bug_hunter-level_4-200w.png';
import bughunter_lev5 from '../../../assets/images/badges2020/53-bug_hunter-level_5-200w.png';
import bughunter_lev6 from '../../../assets/images/badges2020/53-bug_hunter-level_6-200w.png';
import bugkiller_lev1 from '../../../assets/images/badges2020/54-bug_killer-level_1-200w.png';
import bugkiller_lev2 from '../../../assets/images/badges2020/54-bug_killer-level_2-200w.png';
import bugkiller_lev3 from '../../../assets/images/badges2020/54-bug_killer-level_3-200w.png';
import bugkiller_lev4 from '../../../assets/images/badges2020/54-bug_killer-level_4-200w.png';
import bugkiller_lev5 from '../../../assets/images/badges2020/54-bug_killer-level_5-200w.png';
import bugkiller_lev6 from '../../../assets/images/badges2020/54-bug_killer-level_6-200w.png';
import ontimer_lev1 from '../../../assets/images/badges2020/55-on_timer-level_1-200w.png';
import ontimer_lev2 from '../../../assets/images/badges2020/55-on_timer-level_2-200w.png';
import ontimer_lev3 from '../../../assets/images/badges2020/55-on_timer-level_3-200w.png';
import ontimer_lev4 from '../../../assets/images/badges2020/55-on_timer-level_4-200w.png';
import ontimer_lev5 from '../../../assets/images/badges2020/55-on_timer-level_5-200w.png';
import ontimer_lev6 from '../../../assets/images/badges2020/55-on_timer-level_6-200w.png';
import ontarget_lev1 from '../../../assets/images/badges2020/56-on_target-level_1-200w.png';
import ontarget_lev2 from '../../../assets/images/badges2020/56-on_target-level_2-200w.png';
import ontarget_lev3 from '../../../assets/images/badges2020/56-on_target-level_3-200w.png';
import ontarget_lev4 from '../../../assets/images/badges2020/56-on_target-level_4-200w.png';
import ontarget_lev5 from '../../../assets/images/badges2020/56-on_target-level_5-200w.png';
import ontarget_lev6 from '../../../assets/images/badges2020/56-on_target-level_6-200w.png';

export default function LivePreviewExample() {
  return (
    <>

{/* <Grid item sm={6} md={6} lg={6} xl={6}> */}


<Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 01 Newb Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 1"
                    className="img-fluid rounded mr-3 shadow-sm"
src={noob_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 2"
                    className="img-fluid rounded mr-3 shadow-sm"
src={noob_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 3"
                    className="img-fluid rounded mr-3 shadow-sm"
src={noob_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 4"
                    className="img-fluid rounded mr-3 shadow-sm"
src={noob_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 5"
                    className="img-fluid rounded mr-3 shadow-sm"
src={noob_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 6"
                    className="img-fluid rounded mr-3 shadow-sm"
src={noob_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 02 Account Maker Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 7"
                    className="img-fluid rounded mr-3 shadow-sm"
src={accountmaker_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 8"
                    className="img-fluid rounded mr-3 shadow-sm"
src={accountmaker_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 9"
                    className="img-fluid rounded mr-3 shadow-sm"
src={accountmaker_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 10"
                    className="img-fluid rounded mr-3 shadow-sm"
src={accountmaker_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 11"
                    className="img-fluid rounded mr-3 shadow-sm"
src={accountmaker_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 12"
                    className="img-fluid rounded mr-3 shadow-sm"
src={accountmaker_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 03 Airdrop Surprise Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 13"
                    className="img-fluid rounded mr-3 shadow-sm"
src={airdropsurprise_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 14"
                    className="img-fluid rounded mr-3 shadow-sm"
src={airdropsurprise_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 15"
                    className="img-fluid rounded mr-3 shadow-sm"
src={airdropsurprise_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 16"
                    className="img-fluid rounded mr-3 shadow-sm"
src={airdropsurprise_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 17"
                    className="img-fluid rounded mr-3 shadow-sm"
src={airdropsurprise_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 18"
                    className="img-fluid rounded mr-3 shadow-sm"
src={airdropsurprise_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 04 Decentralizer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 19"
                    className="img-fluid rounded mr-3 shadow-sm"
src={decentralizer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 20"
                    className="img-fluid rounded mr-3 shadow-sm"
src={decentralizer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 21"
                    className="img-fluid rounded mr-3 shadow-sm"
src={decentralizer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 22"
                    className="img-fluid rounded mr-3 shadow-sm"
src={decentralizer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 23"
                    className="img-fluid rounded mr-3 shadow-sm"
src={decentralizer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 24"
                    className="img-fluid rounded mr-3 shadow-sm"
src={decentralizer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 05 Ethereum Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 25"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ethereum_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 26"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ethereum_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 27"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ethereum_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 28"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ethereum_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 29"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ethereum_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 30"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ethereum_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 06 Web3Vm User Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 31"
                    className="img-fluid rounded mr-3 shadow-sm"
src={web3vmuser_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 32"
                    className="img-fluid rounded mr-3 shadow-sm"
src={web3vmuser_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 33"
                    className="img-fluid rounded mr-3 shadow-sm"
src={web3vmuser_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 34"
                    className="img-fluid rounded mr-3 shadow-sm"
src={web3vmuser_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 35"
                    className="img-fluid rounded mr-3 shadow-sm"
src={web3vmuser_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 36"
                    className="img-fluid rounded mr-3 shadow-sm"
src={web3vmuser_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 07 Nodist Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 37"
                    className="img-fluid rounded mr-3 shadow-sm"
src={nodist_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 38"
                    className="img-fluid rounded mr-3 shadow-sm"
src={nodist_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 39"
                    className="img-fluid rounded mr-3 shadow-sm"
src={nodist_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 40"
                    className="img-fluid rounded mr-3 shadow-sm"
src={nodist_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 41"
                    className="img-fluid rounded mr-3 shadow-sm"
src={nodist_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 42"
                    className="img-fluid rounded mr-3 shadow-sm"
src={nodist_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 08 Transfer Tech Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 43"
                    className="img-fluid rounded mr-3 shadow-sm"
src={transfertech_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 44"
                    className="img-fluid rounded mr-3 shadow-sm"
src={transfertech_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 45"
                    className="img-fluid rounded mr-3 shadow-sm"
src={transfertech_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 46"
                    className="img-fluid rounded mr-3 shadow-sm"
src={transfertech_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 47"
                    className="img-fluid rounded mr-3 shadow-sm"
src={transfertech_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 48"
                    className="img-fluid rounded mr-3 shadow-sm"
src={transfertech_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 09 Tidbits Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 49"
                    className="img-fluid rounded mr-3 shadow-sm"
src={tidbits_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 50"
                    className="img-fluid rounded mr-3 shadow-sm"
src={tidbits_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 51"
                    className="img-fluid rounded mr-3 shadow-sm"
src={tidbits_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 52"
                    className="img-fluid rounded mr-3 shadow-sm"
src={tidbits_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 53"
                    className="img-fluid rounded mr-3 shadow-sm"
src={tidbits_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 54"
                    className="img-fluid rounded mr-3 shadow-sm"
src={tidbits_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 10 Lawdler Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 55"
                    className="img-fluid rounded mr-3 shadow-sm"
src={lawdler_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 56"
                    className="img-fluid rounded mr-3 shadow-sm"
src={lawdler_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 57"
                    className="img-fluid rounded mr-3 shadow-sm"
src={lawdler_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 58"
                    className="img-fluid rounded mr-3 shadow-sm"
src={lawdler_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 59"
                    className="img-fluid rounded mr-3 shadow-sm"
src={lawdler_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 60"
                    className="img-fluid rounded mr-3 shadow-sm"
src={lawdler_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 11 Magician Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 61"
                    className="img-fluid rounded mr-3 shadow-sm"
src={magician_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 62"
                    className="img-fluid rounded mr-3 shadow-sm"
src={magician_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 63"
                    className="img-fluid rounded mr-3 shadow-sm"
src={magician_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 64"
                    className="img-fluid rounded mr-3 shadow-sm"
src={magician_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 65"
                    className="img-fluid rounded mr-3 shadow-sm"
src={magician_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 66"
                    className="img-fluid rounded mr-3 shadow-sm"
src={magician_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 12 Wardens Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 67"
                    className="img-fluid rounded mr-3 shadow-sm"
src={wardens_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 68"
                    className="img-fluid rounded mr-3 shadow-sm"
src={wardens_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 69"
                    className="img-fluid rounded mr-3 shadow-sm"
src={wardens_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 70"
                    className="img-fluid rounded mr-3 shadow-sm"
src={wardens_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 71"
                    className="img-fluid rounded mr-3 shadow-sm"
src={wardens_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 72"
                    className="img-fluid rounded mr-3 shadow-sm"
src={wardens_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 13 Brickler Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 73"
                    className="img-fluid rounded mr-3 shadow-sm"
src={brickler_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 74"
                    className="img-fluid rounded mr-3 shadow-sm"
src={brickler_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 75"
                    className="img-fluid rounded mr-3 shadow-sm"
src={brickler_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 76"
                    className="img-fluid rounded mr-3 shadow-sm"
src={brickler_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 77"
                    className="img-fluid rounded mr-3 shadow-sm"
src={brickler_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 78"
                    className="img-fluid rounded mr-3 shadow-sm"
src={brickler_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 14 Service Pro Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 79"
                    className="img-fluid rounded mr-3 shadow-sm"
src={servicepro_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 80"
                    className="img-fluid rounded mr-3 shadow-sm"
src={servicepro_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 81"
                    className="img-fluid rounded mr-3 shadow-sm"
src={servicepro_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 82"
                    className="img-fluid rounded mr-3 shadow-sm"
src={servicepro_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 83"
                    className="img-fluid rounded mr-3 shadow-sm"
src={servicepro_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 84"
                    className="img-fluid rounded mr-3 shadow-sm"
src={servicepro_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 15 Insurer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 85"
                    className="img-fluid rounded mr-3 shadow-sm"
src={insurer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 86"
                    className="img-fluid rounded mr-3 shadow-sm"
src={insurer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 87"
                    className="img-fluid rounded mr-3 shadow-sm"
src={insurer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 88"
                    className="img-fluid rounded mr-3 shadow-sm"
src={insurer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 89"
                    className="img-fluid rounded mr-3 shadow-sm"
src={insurer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 90"
                    className="img-fluid rounded mr-3 shadow-sm"
src={insurer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 16 Helping Hand Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 91"
                    className="img-fluid rounded mr-3 shadow-sm"
src={helpinghand_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 92"
                    className="img-fluid rounded mr-3 shadow-sm"
src={helpinghand_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 93"
                    className="img-fluid rounded mr-3 shadow-sm"
src={helpinghand_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 94"
                    className="img-fluid rounded mr-3 shadow-sm"
src={helpinghand_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 95"
                    className="img-fluid rounded mr-3 shadow-sm"
src={helpinghand_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 96"
                    className="img-fluid rounded mr-3 shadow-sm"
src={helpinghand_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 17 Documenteer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 97"
                    className="img-fluid rounded mr-3 shadow-sm"
src={documenteer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 98"
                    className="img-fluid rounded mr-3 shadow-sm"
src={documenteer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 99"
                    className="img-fluid rounded mr-3 shadow-sm"
src={documenteer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 100"
                    className="img-fluid rounded mr-3 shadow-sm"
src={documenteer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 101"
                    className="img-fluid rounded mr-3 shadow-sm"
src={documenteer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 102"
                    className="img-fluid rounded mr-3 shadow-sm"
src={documenteer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 18 Judiciary Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 103"
                    className="img-fluid rounded mr-3 shadow-sm"
src={judiciary_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 104"
                    className="img-fluid rounded mr-3 shadow-sm"
src={judiciary_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 105"
                    className="img-fluid rounded mr-3 shadow-sm"
src={judiciary_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 106"
                    className="img-fluid rounded mr-3 shadow-sm"
src={judiciary_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 107"
                    className="img-fluid rounded mr-3 shadow-sm"
src={judiciary_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 108"
                    className="img-fluid rounded mr-3 shadow-sm"
src={judiciary_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 19 Resolver Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 109"
                    className="img-fluid rounded mr-3 shadow-sm"
src={resolver_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 110"
                    className="img-fluid rounded mr-3 shadow-sm"
src={resolver_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 111"
                    className="img-fluid rounded mr-3 shadow-sm"
src={resolver_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 112"
                    className="img-fluid rounded mr-3 shadow-sm"
src={resolver_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 113"
                    className="img-fluid rounded mr-3 shadow-sm"
src={resolver_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 114"
                    className="img-fluid rounded mr-3 shadow-sm"
src={resolver_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 20 Team Player Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 115"
                    className="img-fluid rounded mr-3 shadow-sm"
src={teamplayer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 116"
                    className="img-fluid rounded mr-3 shadow-sm"
src={teamplayer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 117"
                    className="img-fluid rounded mr-3 shadow-sm"
src={teamplayer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 118"
                    className="img-fluid rounded mr-3 shadow-sm"
src={teamplayer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 119"
                    className="img-fluid rounded mr-3 shadow-sm"
src={teamplayer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 120"
                    className="img-fluid rounded mr-3 shadow-sm"
src={teamplayer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 21 Internationaler Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 121"
                    className="img-fluid rounded mr-3 shadow-sm"
src={internationaler_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 122"
                    className="img-fluid rounded mr-3 shadow-sm"
src={internationaler_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 123"
                    className="img-fluid rounded mr-3 shadow-sm"
src={internationaler_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 124"
                    className="img-fluid rounded mr-3 shadow-sm"
src={internationaler_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 125"
                    className="img-fluid rounded mr-3 shadow-sm"
src={internationaler_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 126"
                    className="img-fluid rounded mr-3 shadow-sm"
src={internationaler_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 22 Miner Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 127"
                    className="img-fluid rounded mr-3 shadow-sm"
src={miner_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 128"
                    className="img-fluid rounded mr-3 shadow-sm"
src={miner_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 129"
                    className="img-fluid rounded mr-3 shadow-sm"
src={miner_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 130"
                    className="img-fluid rounded mr-3 shadow-sm"
src={miner_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 131"
                    className="img-fluid rounded mr-3 shadow-sm"
src={miner_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 132"
                    className="img-fluid rounded mr-3 shadow-sm"
src={miner_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 23 Architect Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 133"
                    className="img-fluid rounded mr-3 shadow-sm"
src={architect_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 134"
                    className="img-fluid rounded mr-3 shadow-sm"
src={architect_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 135"
                    className="img-fluid rounded mr-3 shadow-sm"
src={architect_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 136"
                    className="img-fluid rounded mr-3 shadow-sm"
src={architect_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 137"
                    className="img-fluid rounded mr-3 shadow-sm"
src={architect_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 138"
                    className="img-fluid rounded mr-3 shadow-sm"
src={architect_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 24 Big Brain Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 139"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigbrain_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 140"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigbrain_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 141"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigbrain_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 142"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigbrain_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 143"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigbrain_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 144"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigbrain_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 25 Announcer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 145"
                    className="img-fluid rounded mr-3 shadow-sm"
src={announcer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 146"
                    className="img-fluid rounded mr-3 shadow-sm"
src={announcer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 147"
                    className="img-fluid rounded mr-3 shadow-sm"
src={announcer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 148"
                    className="img-fluid rounded mr-3 shadow-sm"
src={announcer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 149"
                    className="img-fluid rounded mr-3 shadow-sm"
src={announcer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 150"
                    className="img-fluid rounded mr-3 shadow-sm"
src={announcer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 26 Beaconer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 151"
                    className="img-fluid rounded mr-3 shadow-sm"
src={beaconer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 152"
                    className="img-fluid rounded mr-3 shadow-sm"
src={beaconer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 153"
                    className="img-fluid rounded mr-3 shadow-sm"
src={beaconer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 154"
                    className="img-fluid rounded mr-3 shadow-sm"
src={beaconer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 155"
                    className="img-fluid rounded mr-3 shadow-sm"
src={beaconer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 156"
                    className="img-fluid rounded mr-3 shadow-sm"
src={beaconer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 27 Detector Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 157"
                    className="img-fluid rounded mr-3 shadow-sm"
src={detector_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 158"
                    className="img-fluid rounded mr-3 shadow-sm"
src={detector_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 159"
                    className="img-fluid rounded mr-3 shadow-sm"
src={detector_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 160"
                    className="img-fluid rounded mr-3 shadow-sm"
src={detector_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 161"
                    className="img-fluid rounded mr-3 shadow-sm"
src={detector_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 162"
                    className="img-fluid rounded mr-3 shadow-sm"
src={detector_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 28 Hunter Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 163"
                    className="img-fluid rounded mr-3 shadow-sm"
src={hunter_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 164"
                    className="img-fluid rounded mr-3 shadow-sm"
src={hunter_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 165"
                    className="img-fluid rounded mr-3 shadow-sm"
src={hunter_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 166"
                    className="img-fluid rounded mr-3 shadow-sm"
src={hunter_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 167"
                    className="img-fluid rounded mr-3 shadow-sm"
src={hunter_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 168"
                    className="img-fluid rounded mr-3 shadow-sm"
src={hunter_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 29 Iam Idea Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 169"
                    className="img-fluid rounded mr-3 shadow-sm"
src={iamidea_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 170"
                    className="img-fluid rounded mr-3 shadow-sm"
src={iamidea_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 171"
                    className="img-fluid rounded mr-3 shadow-sm"
src={iamidea_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 172"
                    className="img-fluid rounded mr-3 shadow-sm"
src={iamidea_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 173"
                    className="img-fluid rounded mr-3 shadow-sm"
src={iamidea_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 174"
                    className="img-fluid rounded mr-3 shadow-sm"
src={iamidea_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 30 Researcher Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 175"
                    className="img-fluid rounded mr-3 shadow-sm"
src={researcher_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 176"
                    className="img-fluid rounded mr-3 shadow-sm"
src={researcher_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 177"
                    className="img-fluid rounded mr-3 shadow-sm"
src={researcher_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 178"
                    className="img-fluid rounded mr-3 shadow-sm"
src={researcher_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 179"
                    className="img-fluid rounded mr-3 shadow-sm"
src={researcher_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 180"
                    className="img-fluid rounded mr-3 shadow-sm"
src={researcher_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 31 Networker Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 181"
                    className="img-fluid rounded mr-3 shadow-sm"
src={networker_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 182"
                    className="img-fluid rounded mr-3 shadow-sm"
src={networker_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 183"
                    className="img-fluid rounded mr-3 shadow-sm"
src={networker_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 184"
                    className="img-fluid rounded mr-3 shadow-sm"
src={networker_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 185"
                    className="img-fluid rounded mr-3 shadow-sm"
src={networker_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 186"
                    className="img-fluid rounded mr-3 shadow-sm"
src={networker_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 32 Renewer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 187"
                    className="img-fluid rounded mr-3 shadow-sm"
src={renewer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 188"
                    className="img-fluid rounded mr-3 shadow-sm"
src={renewer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 189"
                    className="img-fluid rounded mr-3 shadow-sm"
src={renewer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 190"
                    className="img-fluid rounded mr-3 shadow-sm"
src={renewer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 191"
                    className="img-fluid rounded mr-3 shadow-sm"
src={renewer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 192"
                    className="img-fluid rounded mr-3 shadow-sm"
src={renewer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 33 Mechanic Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 193"
                    className="img-fluid rounded mr-3 shadow-sm"
src={mechanic_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 194"
                    className="img-fluid rounded mr-3 shadow-sm"
src={mechanic_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 195"
                    className="img-fluid rounded mr-3 shadow-sm"
src={mechanic_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 196"
                    className="img-fluid rounded mr-3 shadow-sm"
src={mechanic_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 197"
                    className="img-fluid rounded mr-3 shadow-sm"
src={mechanic_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 198"
                    className="img-fluid rounded mr-3 shadow-sm"
src={mechanic_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 34 Heavy Mech Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 199"
                    className="img-fluid rounded mr-3 shadow-sm"
src={heavymech_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 200"
                    className="img-fluid rounded mr-3 shadow-sm"
src={heavymech_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 201"
                    className="img-fluid rounded mr-3 shadow-sm"
src={heavymech_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 202"
                    className="img-fluid rounded mr-3 shadow-sm"
src={heavymech_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 203"
                    className="img-fluid rounded mr-3 shadow-sm"
src={heavymech_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 204"
                    className="img-fluid rounded mr-3 shadow-sm"
src={heavymech_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 35 Fire Starter Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 205"
                    className="img-fluid rounded mr-3 shadow-sm"
src={firestarter_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 206"
                    className="img-fluid rounded mr-3 shadow-sm"
src={firestarter_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 207"
                    className="img-fluid rounded mr-3 shadow-sm"
src={firestarter_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 208"
                    className="img-fluid rounded mr-3 shadow-sm"
src={firestarter_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 209"
                    className="img-fluid rounded mr-3 shadow-sm"
src={firestarter_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 210"
                    className="img-fluid rounded mr-3 shadow-sm"
src={firestarter_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 36 Navigator Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 211"
                    className="img-fluid rounded mr-3 shadow-sm"
src={navigator_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 212"
                    className="img-fluid rounded mr-3 shadow-sm"
src={navigator_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 213"
                    className="img-fluid rounded mr-3 shadow-sm"
src={navigator_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 214"
                    className="img-fluid rounded mr-3 shadow-sm"
src={navigator_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 215"
                    className="img-fluid rounded mr-3 shadow-sm"
src={navigator_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 216"
                    className="img-fluid rounded mr-3 shadow-sm"
src={navigator_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 37 Strategist Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 217"
                    className="img-fluid rounded mr-3 shadow-sm"
src={strategist_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 218"
                    className="img-fluid rounded mr-3 shadow-sm"
src={strategist_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 219"
                    className="img-fluid rounded mr-3 shadow-sm"
src={strategist_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 220"
                    className="img-fluid rounded mr-3 shadow-sm"
src={strategist_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 221"
                    className="img-fluid rounded mr-3 shadow-sm"
src={strategist_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 222"
                    className="img-fluid rounded mr-3 shadow-sm"
src={strategist_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 38 Needle Threader Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 223"
                    className="img-fluid rounded mr-3 shadow-sm"
src={needlethreader_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 224"
                    className="img-fluid rounded mr-3 shadow-sm"
src={needlethreader_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 225"
                    className="img-fluid rounded mr-3 shadow-sm"
src={needlethreader_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 226"
                    className="img-fluid rounded mr-3 shadow-sm"
src={needlethreader_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 227"
                    className="img-fluid rounded mr-3 shadow-sm"
src={needlethreader_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 228"
                    className="img-fluid rounded mr-3 shadow-sm"
src={needlethreader_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 39 Social Supporter Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 229"
                    className="img-fluid rounded mr-3 shadow-sm"
src={socialsupporter_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 230"
                    className="img-fluid rounded mr-3 shadow-sm"
src={socialsupporter_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 231"
                    className="img-fluid rounded mr-3 shadow-sm"
src={socialsupporter_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 232"
                    className="img-fluid rounded mr-3 shadow-sm"
src={socialsupporter_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 233"
                    className="img-fluid rounded mr-3 shadow-sm"
src={socialsupporter_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 234"
                    className="img-fluid rounded mr-3 shadow-sm"
src={socialsupporter_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 40 Space Traveler Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 235"
                    className="img-fluid rounded mr-3 shadow-sm"
src={spacetraveler_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 236"
                    className="img-fluid rounded mr-3 shadow-sm"
src={spacetraveler_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 237"
                    className="img-fluid rounded mr-3 shadow-sm"
src={spacetraveler_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 238"
                    className="img-fluid rounded mr-3 shadow-sm"
src={spacetraveler_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 239"
                    className="img-fluid rounded mr-3 shadow-sm"
src={spacetraveler_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 240"
                    className="img-fluid rounded mr-3 shadow-sm"
src={spacetraveler_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 41 Entertainer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 241"
                    className="img-fluid rounded mr-3 shadow-sm"
src={entertainer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 242"
                    className="img-fluid rounded mr-3 shadow-sm"
src={entertainer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 243"
                    className="img-fluid rounded mr-3 shadow-sm"
src={entertainer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 244"
                    className="img-fluid rounded mr-3 shadow-sm"
src={entertainer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 245"
                    className="img-fluid rounded mr-3 shadow-sm"
src={entertainer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 246"
                    className="img-fluid rounded mr-3 shadow-sm"
src={entertainer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 42 Seen And Heard Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 247"
                    className="img-fluid rounded mr-3 shadow-sm"
src={seenandheard_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 248"
                    className="img-fluid rounded mr-3 shadow-sm"
src={seenandheard_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 249"
                    className="img-fluid rounded mr-3 shadow-sm"
src={seenandheard_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 250"
                    className="img-fluid rounded mr-3 shadow-sm"
src={seenandheard_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 251"
                    className="img-fluid rounded mr-3 shadow-sm"
src={seenandheard_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 252"
                    className="img-fluid rounded mr-3 shadow-sm"
src={seenandheard_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 43 Future Self Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 253"
                    className="img-fluid rounded mr-3 shadow-sm"
src={futureself_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 254"
                    className="img-fluid rounded mr-3 shadow-sm"
src={futureself_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 255"
                    className="img-fluid rounded mr-3 shadow-sm"
src={futureself_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 256"
                    className="img-fluid rounded mr-3 shadow-sm"
src={futureself_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 257"
                    className="img-fluid rounded mr-3 shadow-sm"
src={futureself_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 258"
                    className="img-fluid rounded mr-3 shadow-sm"
src={futureself_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 44 Pawn Tokener Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 259"
                    className="img-fluid rounded mr-3 shadow-sm"
src={pawntokener_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 260"
                    className="img-fluid rounded mr-3 shadow-sm"
src={pawntokener_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 261"
                    className="img-fluid rounded mr-3 shadow-sm"
src={pawntokener_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 262"
                    className="img-fluid rounded mr-3 shadow-sm"
src={pawntokener_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 263"
                    className="img-fluid rounded mr-3 shadow-sm"
src={pawntokener_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 264"
                    className="img-fluid rounded mr-3 shadow-sm"
src={pawntokener_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 45 Big Show Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 265"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigshow_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 266"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigshow_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 267"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigshow_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 268"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigshow_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 269"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigshow_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 270"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bigshow_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 46 Feast Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 271"
                    className="img-fluid rounded mr-3 shadow-sm"
src={feast_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 272"
                    className="img-fluid rounded mr-3 shadow-sm"
src={feast_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 273"
                    className="img-fluid rounded mr-3 shadow-sm"
src={feast_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 274"
                    className="img-fluid rounded mr-3 shadow-sm"
src={feast_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 275"
                    className="img-fluid rounded mr-3 shadow-sm"
src={feast_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 276"
                    className="img-fluid rounded mr-3 shadow-sm"
src={feast_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 47 Surprise Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 277"
                    className="img-fluid rounded mr-3 shadow-sm"
src={surprise_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 278"
                    className="img-fluid rounded mr-3 shadow-sm"
src={surprise_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 279"
                    className="img-fluid rounded mr-3 shadow-sm"
src={surprise_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 280"
                    className="img-fluid rounded mr-3 shadow-sm"
src={surprise_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 281"
                    className="img-fluid rounded mr-3 shadow-sm"
src={surprise_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 282"
                    className="img-fluid rounded mr-3 shadow-sm"
src={surprise_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 48 On Fire Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 283"
                    className="img-fluid rounded mr-3 shadow-sm"
src={onfire_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 284"
                    className="img-fluid rounded mr-3 shadow-sm"
src={onfire_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 285"
                    className="img-fluid rounded mr-3 shadow-sm"
src={onfire_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 286"
                    className="img-fluid rounded mr-3 shadow-sm"
src={onfire_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 287"
                    className="img-fluid rounded mr-3 shadow-sm"
src={onfire_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 288"
                    className="img-fluid rounded mr-3 shadow-sm"
src={onfire_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 49 Good Show Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 289"
                    className="img-fluid rounded mr-3 shadow-sm"
src={goodshow_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 290"
                    className="img-fluid rounded mr-3 shadow-sm"
src={goodshow_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 291"
                    className="img-fluid rounded mr-3 shadow-sm"
src={goodshow_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 292"
                    className="img-fluid rounded mr-3 shadow-sm"
src={goodshow_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 293"
                    className="img-fluid rounded mr-3 shadow-sm"
src={goodshow_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 294"
                    className="img-fluid rounded mr-3 shadow-sm"
src={goodshow_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 50 Leisurely Pursuits Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 295"
                    className="img-fluid rounded mr-3 shadow-sm"
src={leisurelypursuits_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 296"
                    className="img-fluid rounded mr-3 shadow-sm"
src={leisurelypursuits_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 297"
                    className="img-fluid rounded mr-3 shadow-sm"
src={leisurelypursuits_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 298"
                    className="img-fluid rounded mr-3 shadow-sm"
src={leisurelypursuits_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 299"
                    className="img-fluid rounded mr-3 shadow-sm"
src={leisurelypursuits_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 300"
                    className="img-fluid rounded mr-3 shadow-sm"
src={leisurelypursuits_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 51 Bonded Pig Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 301"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bondedpig_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 302"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bondedpig_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 303"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bondedpig_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 304"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bondedpig_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 305"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bondedpig_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 306"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bondedpig_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 52 Who Cdc Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 307"
                    className="img-fluid rounded mr-3 shadow-sm"
src={whocdc_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 308"
                    className="img-fluid rounded mr-3 shadow-sm"
src={whocdc_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 309"
                    className="img-fluid rounded mr-3 shadow-sm"
src={whocdc_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 310"
                    className="img-fluid rounded mr-3 shadow-sm"
src={whocdc_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 311"
                    className="img-fluid rounded mr-3 shadow-sm"
src={whocdc_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 312"
                    className="img-fluid rounded mr-3 shadow-sm"
src={whocdc_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 53 Bug Hunter Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 313"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bughunter_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 314"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bughunter_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 315"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bughunter_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 316"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bughunter_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 317"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bughunter_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 318"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bughunter_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 54 Bug Killer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 319"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bugkiller_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 320"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bugkiller_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 321"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bugkiller_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 322"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bugkiller_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 323"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bugkiller_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 324"
                    className="img-fluid rounded mr-3 shadow-sm"
src={bugkiller_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 55 On Timer Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 325"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontimer_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 326"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontimer_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 327"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontimer_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 328"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontimer_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 329"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontimer_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 330"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontimer_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills Group 56 On Target Achievement Tokens
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 331"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontarget_lev1}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 332"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontarget_lev2}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 333"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontarget_lev3}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 334"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontarget_lev4}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 335"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontarget_lev5}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 336"
                    className="img-fluid rounded mr-3 shadow-sm"
src={ontarget_lev6}
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>

      {/* </Grid> */}


    </>
  );
}
