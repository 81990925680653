import React from 'react';

import { Grid, Card, Button } from '@material-ui/core';

import avatar3 from '../../../assets/images/avatars/avatar3.jpg';
import avatar2 from '../../../assets/images/avatars/avatar2.jpg';

import lj600a from '../../../assets/images/lady-justice/phase03-01-lady-justice-history-600w.png';
import lj600b from '../../../assets/images/lady-justice/phase03-02-lady-justice-history-600w.png';




export default function LivePreviewExample() {
  return (
    <>
      <Grid container spacing={6}>



        <Grid item lg={6} className="d-flex">
        
        
          <Card>
            <div className="card-badges">
              <div className="badge badge-pill badge-primary h-auto px-3 py-1">
                Popular
              </div>
            </div>
            <a
              href="#/"
              target="_blank"
              rel="noopener noreferrer"
              className="image-title-overlay d-block h-280px"
              title="...">
              <div className="image-title-overlay--bottom px-5 py-4">
                <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                  Popular Smart-Contracts
                </h3>
              </div>
              <img
                alt="..."
                className="card-img-top img-fit-container"
                src={lj600a}
              />
            </a>


            <div className="p-5">
              <div className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper mr-2">
                    <div className="avatar-icon">
                      <img alt="..." src={avatar2} />
                    </div>
                  </div>
                  {/* <div>
                    <a
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold"
                      title="...">
                      Scott W Stevenson
                    </a>
                    <span className="text-black-50 d-block">
                      SmartLaw Legal Engineer
                    </span>
                  </div> */}
                </div>
                <div>
                  <Button className="btn-neutral-primary py-1 px-2 d-block">
                  <span className="d-block opacity-6">Earn</span>
                    <span className="font-size-lg text-danger font-weight-bold">20+</span>
                    <span className="d-block opacity-6">Ponts</span>
                  </Button>
                </div>
              </div>
              <p className="text-black font-size-lg">
                What are the most popular and widely used ERC standard
                smart-contracts used on the Ethereum blockchain available in
                OpenZeppelin's Github repository?
              </p>
              <p className="card-text font-size-lg">
                The Ethereum blockchain has several ERC (Ethereum Request for
                Comment) standards for creating and managing different types of
                tokens. OpenZeppelin is a popular open-source framework for
                finding and building smart contracts on the Ethereum blockchain.
                Its Github repository contains a variety of smart contracts that
                developers can use as building blocks for their own
                applications. Some of the most widely used smart contracts found
                at OpenZeppelin's Github repository include:
              </p>

              <div className="font-size-md font-weight-bold mt-2 mb-2">
                AccessControl
              </div>

              <p className="card-text font-size-md">
                OpenZeppelin's AccessControl smart contract provides a flexible
                and secure way to manage roles and permissions within a smart
                contract. It includes features such as role-based access
                control, permissioned functions, and role revocation.
              </p>

              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Ownable
              </div>

              <p className="card-text font-size-md">
                OpenZeppelin's Ownable smart contract provides a basic ownership
                mechanism for smart contracts. It includes features such as an
                owner address and modifier functions that can be used to
                restrict access to certain functions or data.
              </p>

              <div className="font-size-md font-weight-bold mt-2 mb-2">
                SafeMath
              </div>

              <p className="card-text font-size-md">
                OpenZeppelin's SafeMath smart contract provides a library of
                secure math functions for use in smart contracts. It includes
                functions such as add, subtract, multiply, and divide, all of
                which are designed to prevent common vulnerabilities such as
                integer overflow and underflow.
              </p>

              <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-20
              </div>

              <p className="card-text font-size-md">
                OpenZeppelin's implementation of the ERC-20 token standard is
                one of the most widely used smart contracts in the Ethereum
                ecosystem. It defines a set of rules for creating and managing
                fungible tokens, which can be used for a variety of purposes
                such as cryptocurrencies, loyalty points, and more. It provides
                a standard interface for creating fungible tokens, and includes
                features such as a transfer function, approval mechanism, and
                balance tracking.
              </p>

              <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-721
              </div>

              <p className="card-text font-size-md">
                OpenZeppelin's implementation of the ERC-721 token standard
                provides a standard interface for creating non-fungible tokens
                (NFTs), which represent unique and indivisible digital artifacts
                such as collectibles, art, and other assets that can be used in
                a wide range of applications, including gaming, art, and
                personal & business records.
              </p>

              <div className="font-size-md font-weight-bold mt-2 mb-2">
                ERC-1155
              </div>

              <p className="card-text font-size-md">
                The ERC-1155 standard is a newer standard that allows for the
                creation of both fungible and non-fungible tokens in a single
                contract. It offers increased flexibility and efficiency
                compared to the other standards.
              </p>

              <hr />

              <p className="card-text font-size-md">
                Overall, these ERC standards provide a solid foundation for
                creating and managing different types of tokens on the Ethereum
                blockchain, and are widely used in a variety of blockchain
                applications and platforms.
              </p>

              <div className="d-flex justify-content-between pt-2">
                <Button className="btn-first hover-scale-lg">Learn more</Button>
              </div>
            </div>
          </Card>
        </Grid>






        <Grid item lg={6} className="d-flex">



          <Card>





            <div className="card-badges">
              <div className="badge badge-pill badge-primary h-auto px-3 py-1">
              Token Standards
              </div>
            </div>
            <a
              href="#/"
              target="_blank"
              rel="noopener noreferrer"
              className="image-title-overlay d-block h-280px"
              title="...">
              <div className="image-title-overlay--bottom px-5 py-4">
                <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                ERC-20, 721, & 1155
                </h3>
              </div>
              <img
                alt="..."
                className="card-img-top img-fit-container"
                src={lj600b}
              />
            </a>








            <div className="p-5">
              <div className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper mr-2">
                    <div className="avatar-icon">
                      <img alt="..." src={avatar3} />
                    </div>
                  </div>
                  {/* <div>
                    <a
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bold"
                      title="...">
                      Scott W Stevenson
                    </a>
                    <span className="text-black-50 d-block">
                      SmartLaw Legal Engineer
                    </span>
                  </div> */}
                </div>







                <div>
                  <Button className="btn-neutral-primary py-1 px-2 d-block">
                  <span className="d-block opacity-6">Earn</span>
                    <span className="font-size-lg text-danger font-weight-bold">20+</span>
                    <span className="d-block opacity-6">Ponts</span>
                  </Button>
                </div>
              </div>







              <p className="text-black font-size-lg">
                An ERC-20 token is a standard for fungible tokens on the
                Ethereum blockchain. It allows developers to create and manage
                digital assets that are interchangeable and can represent any
                type of asset or utility.
              </p>
              <p className="card-text font-size-lg">
                Here are some of the key functions of an ERC-20 token:
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Interchangeability
              </div>
              <p className="card-text font-size-md">
                Each ERC-20 token is identical and interchangeable with every
                other token of the same type. This means that one token is
                always worth the same as any other token of the same type,
                regardless of who owns it.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Transferability
              </div>
              <p className="card-text font-size-md">
                ERC-20 tokens can be transferred between users, just like any
                other cryptocurrency. This allows for the easy exchange of
                tokens for other assets or currencies.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Divisibility
              </div>
              <p className="card-text font-size-md">
                ERC-20 tokens can be divided into smaller units, making them
                highly divisible and flexible. This allows for the easy exchange
                of tokens for other assets or currencies.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Smart contract integration
              </div>
              <p className="card-text font-size-md">
                ERC-20 tokens can be integrated into smart contracts, allowing
                for the creation of complex decentralized applications (dApps)
                that can manage, exchange, or utilize these tokens.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Wide adoption
              </div>
              <p className="card-text font-size-md">
                The ERC-20 standard is widely adopted by many blockchain
                projects, making it easy to integrate with other platforms and
                services.
              </p>
              <hr />
              <p className="card-text font-size-md">
                Overall, ERC-20 tokens provide a powerful and flexible way to
                represent any type of asset or utility on the Ethereum
                blockchain. They offer new opportunities for creators and
                businesses to create digital assets, incentivize users, and
                build decentralized applications.
              </p>
              <hr />
              <p className="text-black font-size-lg">
                An ERC-721 token is a non-fungible token standard on the
                Ethereum blockchain, meaning that each token is unique and not
                interchangeable. It allows developers to create digital assets
                that are one-of-a-kind and can represent ownership of real-world
                or virtual items.
              </p>
              <p className="card-text font-size-lg">
                Here are some of the key functions of an ERC-721 token:
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Unique ownership
              </div>
              <p className="card-text font-size-md">
                Each ERC-721 token is unique, with its own distinct identifier.
                This means that ownership of a particular token can be verified
                on the blockchain, and the token itself can represent ownership
                of a specific asset, such as a piece of art, a collectible, or a
                real estate property.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Transferability
              </div>
              <p className="card-text font-size-md">
                ERC-721 tokens can be transferred between users, just like any
                other cryptocurrency. This allows for the ownership of the
                underlying asset to be transferred without the need for a
                physical transfer.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Built-in metadata
              </div>
              <p className="card-text font-size-md">
                ERC-721 tokens can include built-in metadata, such as
                descriptions, images, and other information about the token.
                This can be useful for creating rich and interactive user
                experiences.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Interoperability
              </div>
              <p className="card-text font-size-md">
                ERC-721 tokens are interoperable with other Ethereum-based
                standards, such as ERC-20 and ERC-1155. This means that they can
                be used in a wide range of applications and platforms, making
                them more versatile and valuable.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Scarcity and rarity
              </div>
              <p className="card-text font-size-md">
                ERC-721 tokens can be used to create digital assets that are
                scarce and rare, which can increase their value and appeal to
                collectors.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Customizable attributes
              </div>
              <p className="card-text font-size-md">
                ERC-721 tokens can have customizable attributes that can be used
                to describe the underlying asset, such as the artist, year of
                creation, and other relevant details.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
                Wide adoption
              </div>
              <p className="card-text font-size-md">
                The ERC-721 standard is widely adopted by many blockchain
                projects, making it easy to integrate with other platforms and
                services.
              </p>
              <hr />
              <p className="card-text font-size-md">
                Overall, ERC-721 tokens provide a powerful way to represent
                ownership of unique digital assets on the Ethereum blockchain.
                They offer new opportunities for creators and collectors alike,
                and have the potential to revolutionize ownership and asset
                management in a variety of industries.
              </p>

              <hr />

              <p className="text-black font-size-lg">
              An ERC-1155 token is a standard for fungible and non-fungible
              tokens on the Ethereum blockchain. It was introduced as an
              improvement over the ERC-20 and ERC-721 token standards, offering
              more flexibility and efficiency.
              </p>
              <p className="card-text font-size-lg">
              Here are some of the key functions of an ERC-1155 token:
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
              Transferability
              </div>
              <p className="card-text font-size-md">
              ERC-1155 tokens can be transferred between users, just like any other cryptocurrency. This allows for the ownership of the underlying asset to be transferred without the need for a physical transfer.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
              Multi-token support
              </div>
              <p className="card-text font-size-md">
              ERC-1155 allows for multiple tokens to be managed by a single contract. This means that developers can create a single contract to manage a variety of different assets, including fungible tokens (such as cryptocurrencies) and non-fungible tokens (such as collectibles).
              </p>              
              <div className="font-size-md font-weight-bold mt-2 mb-2">
              Batch transfers
              </div>
              <p className="card-text font-size-md">
              ERC-1155 tokens allow for batch transfers, meaning that multiple tokens can be transferred in a single transaction. This can help to reduce transaction fees and improve efficiency.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
              Reduced gas costs
              </div>
              <p className="card-text font-size-md">
              ERC-1155 tokens use a more efficient transfer mechanism than previous token standards. This reduces the amount of gas required for transactions, making it cheaper and faster to use.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
              Built-in metadata
              </div>
              <p className="card-text font-size-md">
              ERC-1155 tokens can include built-in metadata, such as descriptions, images, and other information about the token. This can be useful for creating rich and interactive user experiences.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
              Interoperability
              </div>
              <p className="card-text font-size-md">
              ERC-1155 tokens are interoperable with other Ethereum-based standards, such as ERC-20 and ERC-721. This means that they can be used in a wide range of applications and platforms, making them more versatile and valuable.
              </p>
              <div className="font-size-md font-weight-bold mt-2 mb-2">
              Wide adoption
              </div>
              <p className="card-text font-size-md">
              The ERC-1155 standard is widely adopted by many blockchain projects, making it easy to integrate with other platforms and services.
              </p>
              <hr />
              <p className="card-text font-size-md">
              Overall, ERC-1155 tokens provide a more flexible, efficient, and powerful way to manage a variety of different assets on the Ethereum blockchain. 
              </p>





              <div className="d-flex justify-content-between pt-2">
                <Button className="btn-first hover-scale-lg">Learn more</Button>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
