import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@material-ui/core';

import Slider from 'react-slick';

import lexautomaticastory002100w from '../../../assets/images/lexautomatica/lexautomatica-story-002-200w.png';
import lexautomaticastory003100w from '../../../assets/images/lexautomatica/lexautomatica-story-003-200w.png';
import lexautomaticastory004100w from '../../../assets/images/lexautomatica/lexautomatica-story-004-200w.png';
import lexautomaticastory005100w from '../../../assets/images/lexautomatica/lexautomatica-story-005-200w.png';
import lexautomaticastory006100w from '../../../assets/images/lexautomatica/lexautomatica-story-006-200w.png';
import lexautomaticastory007100w from '../../../assets/images/lexautomatica/lexautomatica-story-007-200w.png';
import lexautomaticastory008100w from '../../../assets/images/lexautomatica/lexautomatica-story-008-200w.png';
import lexautomaticastory009100w from '../../../assets/images/lexautomatica/lexautomatica-story-009-200w.png';
import lexautomaticastory010100w from '../../../assets/images/lexautomatica/lexautomatica-story-010-200w.png';
import lexautomaticastory011100w from '../../../assets/images/lexautomatica/lexautomatica-story-011-200w.png';
import lexautomaticastory012100w from '../../../assets/images/lexautomatica/lexautomatica-story-012-200w.png';
import lexautomaticastory013100w from '../../../assets/images/lexautomatica/lexautomatica-story-013-200w.png';


import course1 from '../../../assets/images/courses/course-did.png';
import course2 from '../../../assets/images/courses/course-blono.png';
import course3 from '../../../assets/images/courses/course-core.png';
import course4 from '../../../assets/images/courses/course-legtec.png';
import course5 from '../../../assets/images/courses/course-daoj.png';
import course6 from '../../../assets/images/courses/course-dest.png';
import course7 from '../../../assets/images/courses/course-desa.png';
import course8 from '../../../assets/images/courses/course-defij.png';
import course9 from '../../../assets/images/courses/course-devj.png';
import course10 from '../../../assets/images/courses/course-mfp.png';
import course11 from '../../../assets/images/courses/course-bore.png';
import course12 from '../../../assets/images/courses/course-oxoo.png';

function SliderArrowNext(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  );
}

export default function LivePreviewExample() {


  
  const widgetsCarousels4A = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 1450,
        settings: { slidesToShow: 3, slidesToScroll: 1 }
      },
      {
        breakpoint: 1100,
        settings: { slidesToShow: 2, slidesToScroll: 1 }
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ]
  };

  return (
    <>
      <Card className="bg-night-sky py-4 mb-spacing-6-x2">
        <div className="py-4">
          <Slider
            className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
            {...widgetsCarousels4A}>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory002100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={course1} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory003100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course2} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory004100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course3} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory005100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course4} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory006100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course5} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory007100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course6} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://desa.web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory008100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course7} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory009100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course8} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory010100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course9} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory011100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course10} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory012100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course11} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://web3skills.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={lexautomaticastory013100w} className="card-img-top" alt="..." />
                <div className="p-3 bg-secondary rounded-bottom p-xl-4">
                  <img src={course12} alt="..." style={{ height: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
                    Web3Skills_SmartLaw course and service descriptions. Text block here
                  </p>
                </div>
              </a>
            </div>
          </Slider>
        </div>
      </Card>
    </>
  );
}
