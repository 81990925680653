import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import Iframe from 'react-iframe';

const topAchievments = [
  { title: 'Newb', level: 'Level 1', year: 2020 },
  { title: 'Newb', level: 'Level 2', year: 2020 },
  { title: 'Newb', level: 'Level 3', year: 2020 },
  { title: 'Newb', level: 'Level 4', year: 2020 },
  { title: 'Newb', level: 'Level 5', year: 2020 },
  { title: 'Newb', level: 'Level 6', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 1', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 2', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 3', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 4', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 5', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 6', year: 2020 },
  { title: 'Ethereum', level: 'Level 1', year: 2020 },
  { title: 'Ethereum', level: 'Level 2', year: 2020 },
  { title: 'Ethereum', level: 'Level 3', year: 2020 },
  { title: 'Ethereum', level: 'Level 4', year: 2020 },
  { title: 'Ethereum', level: 'Level 5', year: 2020 },
  { title: 'Ethereum', level: 'Level 6', year: 2020 },
  { title: 'Nodist', level: 'Level 1', year: 2020 },
  { title: 'Nodist', level: 'Level 2', year: 2020 },
  { title: 'Nodist', level: 'Level 3', year: 2020 },
  { title: 'Nodist', level: 'Level 4', year: 2020 },
  { title: 'Nodist', level: 'Level 5', year: 2020 },
  { title: 'Nodist', level: 'Level 6', year: 2020 },
  { title: 'Tidbits', level: 'Level 1', year: 2020 },
  { title: 'Tidbits', level: 'Level 2', year: 2020 },
  { title: 'Tidbits', level: 'Level 3', year: 2020 },
  { title: 'Tidbits', level: 'Level 4', year: 2020 },
  { title: 'Tidbits', level: 'Level 5', year: 2020 },
  { title: 'Tidbits', level: 'Level 6', year: 2020 },
  { title: 'Wardens', level: 'Level 1', year: 2020 },
  { title: 'Wardens', level: 'Level 2', year: 2020 },
  { title: 'Wardens', level: 'Level 3', year: 2020 },
  { title: 'Wardens', level: 'Level 4', year: 2020 },
  { title: 'Wardens', level: 'Level 5', year: 2020 },
  { title: 'Wardens', level: 'Level 6', year: 2020 },
  { title: 'Judiciary', level: 'Level 1', year: 2020 },
  { title: 'Judiciary', level: 'Level 2', year: 2020 },
  { title: 'Judiciary', level: 'Level 3', year: 2020 },
  { title: 'Judiciary', level: 'Level 4', year: 2020 },
  { title: 'Judiciary', level: 'Level 5', year: 2020 },
  { title: 'Judiciary', level: 'Level 6', year: 2020 },
  { title: 'Space Traveler', level: 'Level 1', year: 2020 },
  { title: 'Space Traveler', level: 'Level 2', year: 2020 },
  { title: 'Space Traveler', level: 'Level 3', year: 2020 },
  { title: 'Space Traveler', level: 'Level 4', year: 2020 },
  { title: 'Space Traveler', level: 'Level 5', year: 2020 },
  { title: 'Space Traveler', level: 'Level 6', year: 2020 },
  { title: 'Miner', level: 'Level 1', year: 2020 },
  { title: 'Miner', level: 'Level 2', year: 2020 },
  { title: 'Miner', level: 'Level 3', year: 2020 },
  { title: 'Miner', level: 'Level 4', year: 2020 },
  { title: 'Miner', level: 'Level 5', year: 2020 },
  { title: 'Miner', level: 'Level 6', year: 2020 },
  { title: 'Architect', level: 'Level 1', year: 2020 },
  { title: 'Architect', level: 'Level 2', year: 2020 },
  { title: 'Architect', level: 'Level 3', year: 2020 },
  { title: 'Architect', level: 'Level 4', year: 2020 },
  { title: 'Architect', level: 'Level 5', year: 2020 },
  { title: 'Architect', level: 'Level 6', year: 2020 },
  { title: 'Beaconer', level: 'Level 1', year: 2020 },
  { title: 'Beaconer', level: 'Level 2', year: 2020 },
  { title: 'Beaconer', level: 'Level 3', year: 2020 },
  { title: 'Beaconer', level: 'Level 4', year: 2020 },
  { title: 'Beaconer', level: 'Level 5', year: 2020 },
  { title: 'Beaconer', level: 'Level 6', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 1', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 2', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 3', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 4', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 5', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 6', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 1', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 2', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 3', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 4', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 5', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 6', year: 2020 },
  { title: 'Future Self', level: 'Level 1', year: 2020 },
  { title: 'Future Self', level: 'Level 2', year: 2020 },
  { title: 'Future Self', level: 'Level 3', year: 2020 },
  { title: 'Future Self', level: 'Level 4', year: 2020 },
  { title: 'Future Self', level: 'Level 5', year: 2020 },
  { title: 'Future Self', level: 'Level 6', year: 2020 },
  { title: 'Mechanic', level: 'Level 1', year: 2020 },
  { title: 'Mechanic', level: 'Level 2', year: 2020 },
  { title: 'Mechanic', level: 'Level 3', year: 2020 },
  { title: 'Mechanic', level: 'Level 4', year: 2020 },
  { title: 'Mechanic', level: 'Level 5', year: 2020 },
  { title: 'Mechanic', level: 'Level 6', year: 2020 },
  { title: 'Renewer', level: 'Level 1', year: 2020 },
  { title: 'Renewer', level: 'Level 2', year: 2020 },
  { title: 'Renewer', level: 'Level 3', year: 2020 },
  { title: 'Renewer', level: 'Level 4', year: 2020 },
  { title: 'Renewer', level: 'Level 5', year: 2020 },
  { title: 'Renewer', level: 'Level 6', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 1', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 2', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 3', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 4', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 5', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 6', year: 2020 },
  { title: 'Who Cdc', level: 'Level 1', year: 2020 },
  { title: 'Who Cdc', level: 'Level 2', year: 2020 },
  { title: 'Who Cdc', level: 'Level 3', year: 2020 },
  { title: 'Who Cdc', level: 'Level 4', year: 2020 },
  { title: 'Who Cdc', level: 'Level 5', year: 2020 },
  { title: 'Who Cdc', level: 'Level 6', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 1', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 2', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 3', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 4', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 5', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 6', year: 2020 },
  { title: 'Bug Killer', level: 'Level 1', year: 2020 },
  { title: 'Bug Killer', level: 'Level 2', year: 2020 },
  { title: 'Bug Killer', level: 'Level 3', year: 2020 },
  { title: 'Bug Killer', level: 'Level 4', year: 2020 },
  { title: 'Bug Killer', level: 'Level 5', year: 2020 },
  { title: 'Bug Killer', level: 'Level 6', year: 2020 },
  { title: 'On Target', level: 'Level 1', year: 2020 },
  { title: 'On Target', level: 'Level 2', year: 2020 },
  { title: 'On Target', level: 'Level 3', year: 2020 },
  { title: 'On Target', level: 'Level 4', year: 2020 },
  { title: 'On Target', level: 'Level 5', year: 2020 },
  { title: 'On Target', level: 'Level 6', year: 2020 },
  { title: 'Account Maker', level: 'Level 1', year: 2020 },
  { title: 'Account Maker', level: 'Level 2', year: 2020 },
  { title: 'Account Maker', level: 'Level 3', year: 2020 },
  { title: 'Account Maker', level: 'Level 4', year: 2020 },
  { title: 'Account Maker', level: 'Level 5', year: 2020 },
  { title: 'Account Maker', level: 'Level 6', year: 2020 },
  { title: 'Announcer', level: 'Level 1', year: 2020 },
  { title: 'Announcer', level: 'Level 2', year: 2020 },
  { title: 'Announcer', level: 'Level 3', year: 2020 },
  { title: 'Announcer', level: 'Level 4', year: 2020 },
  { title: 'Announcer', level: 'Level 5', year: 2020 },
  { title: 'Announcer', level: 'Level 6', year: 2020 },
  { title: 'Big Brain', level: 'Level 1', year: 2020 },
  { title: 'Big Brain', level: 'Level 2', year: 2020 },
  { title: 'Big Brain', level: 'Level 3', year: 2020 },
  { title: 'Big Brain', level: 'Level 4', year: 2020 },
  { title: 'Big Brain', level: 'Level 5', year: 2020 },
  { title: 'Big Brain', level: 'Level 6', year: 2020 },
  { title: 'Big Show', level: 'Level 1', year: 2020 },
  { title: 'Big Show', level: 'Level 2', year: 2020 },
  { title: 'Big Show', level: 'Level 3', year: 2020 },
  { title: 'Big Show', level: 'Level 4', year: 2020 },
  { title: 'Big Show', level: 'Level 5', year: 2020 },
  { title: 'Big Show', level: 'Level 6', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 1', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 2', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 3', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 4', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 5', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 6', year: 2020 },
  { title: 'Brickler', level: 'Level 1', year: 2020 },
  { title: 'Brickler', level: 'Level 2', year: 2020 },
  { title: 'Brickler', level: 'Level 3', year: 2020 },
  { title: 'Brickler', level: 'Level 4', year: 2020 },
  { title: 'Brickler', level: 'Level 5', year: 2020 },
  { title: 'Brickler', level: 'Level 6', year: 2020 },
  { title: 'Decentralizer', level: 'Level 1', year: 2020 },
  { title: 'Decentralizer', level: 'Level 2', year: 2020 },
  { title: 'Decentralizer', level: 'Level 3', year: 2020 },
  { title: 'Decentralizer', level: 'Level 4', year: 2020 },
  { title: 'Decentralizer', level: 'Level 5', year: 2020 },
  { title: 'Decentralizer', level: 'Level 6', year: 2020 },
  { title: 'Detector', level: 'Level 1', year: 2020 },
  { title: 'Detector', level: 'Level 2', year: 2020 },
  { title: 'Detector', level: 'Level 3', year: 2020 },
  { title: 'Detector', level: 'Level 4', year: 2020 },
  { title: 'Detector', level: 'Level 5', year: 2020 },
  { title: 'Detector', level: 'Level 6', year: 2020 },
  { title: 'Documenteer', level: 'Level 1', year: 2020 },
  { title: 'Documenteer', level: 'Level 2', year: 2020 },
  { title: 'Documenteer', level: 'Level 3', year: 2020 },
  { title: 'Documenteer', level: 'Level 4', year: 2020 },
  { title: 'Documenteer', level: 'Level 5', year: 2020 },
  { title: 'Documenteer', level: 'Level 6', year: 2020 },
  { title: 'Entertainer', level: 'Level 1', year: 2020 },
  { title: 'Entertainer', level: 'Level 2', year: 2020 },
  { title: 'Entertainer', level: 'Level 3', year: 2020 },
  { title: 'Entertainer', level: 'Level 4', year: 2020 },
  { title: 'Entertainer', level: 'Level 5', year: 2020 },
  { title: 'Entertainer', level: 'Level 6', year: 2020 },
  { title: 'Feast', level: 'Level 1', year: 2020 },
  { title: 'Feast', level: 'Level 2', year: 2020 },
  { title: 'Feast', level: 'Level 3', year: 2020 },
  { title: 'Feast', level: 'Level 4', year: 2020 },
  { title: 'Feast', level: 'Level 5', year: 2020 },
  { title: 'Feast', level: 'Level 6', year: 2020 },
  { title: 'Fire Starter', level: 'Level 1', year: 2020 },
  { title: 'Fire Starter', level: 'Level 2', year: 2020 },
  { title: 'Fire Starter', level: 'Level 3', year: 2020 },
  { title: 'Fire Starter', level: 'Level 4', year: 2020 },
  { title: 'Fire Starter', level: 'Level 5', year: 2020 },
  { title: 'Fire Starter', level: 'Level 6', year: 2020 },
  { title: 'Good Show', level: 'Level 1', year: 2020 },
  { title: 'Good Show', level: 'Level 2', year: 2020 },
  { title: 'Good Show', level: 'Level 3', year: 2020 },
  { title: 'Good Show', level: 'Level 4', year: 2020 },
  { title: 'Good Show', level: 'Level 5', year: 2020 },
  { title: 'Good Show', level: 'Level 6', year: 2020 },
  { title: 'Helping Hand', level: 'Level 1', year: 2020 },
  { title: 'Helping Hand', level: 'Level 2', year: 2020 },
  { title: 'Helping Hand', level: 'Level 3', year: 2020 },
  { title: 'Helping Hand', level: 'Level 4', year: 2020 },
  { title: 'Helping Hand', level: 'Level 5', year: 2020 },
  { title: 'Helping Hand', level: 'Level 6', year: 2020 },
  { title: 'Hunter', level: 'Level 1', year: 2020 },
  { title: 'Hunter', level: 'Level 2', year: 2020 },
  { title: 'Hunter', level: 'Level 3', year: 2020 },
  { title: 'Hunter', level: 'Level 4', year: 2020 },
  { title: 'Hunter', level: 'Level 5', year: 2020 },
  { title: 'Hunter', level: 'Level 6', year: 2020 },
  { title: 'Iam Idea', level: 'Level 1', year: 2020 },
  { title: 'Iam Idea', level: 'Level 2', year: 2020 },
  { title: 'Iam Idea', level: 'Level 3', year: 2020 },
  { title: 'Iam Idea', level: 'Level 4', year: 2020 },
  { title: 'Iam Idea', level: 'Level 5', year: 2020 },
  { title: 'Iam Idea', level: 'Level 6', year: 2020 },
  { title: 'Insurer', level: 'Level 1', year: 2020 },
  { title: 'Insurer', level: 'Level 2', year: 2020 },
  { title: 'Insurer', level: 'Level 3', year: 2020 },
  { title: 'Insurer', level: 'Level 4', year: 2020 },
  { title: 'Insurer', level: 'Level 5', year: 2020 },
  { title: 'Insurer', level: 'Level 6', year: 2020 },
  { title: 'Internationaler', level: 'Level 1', year: 2020 },
  { title: 'Internationaler', level: 'Level 2', year: 2020 },
  { title: 'Internationaler', level: 'Level 3', year: 2020 },
  { title: 'Internationaler', level: 'Level 4', year: 2020 },
  { title: 'Internationaler', level: 'Level 5', year: 2020 },
  { title: 'Internationaler', level: 'Level 6', year: 2020 },
  { title: 'Lawdler', level: 'Level 1', year: 2020 },
  { title: 'Lawdler', level: 'Level 2', year: 2020 },
  { title: 'Lawdler', level: 'Level 3', year: 2020 },
  { title: 'Lawdler', level: 'Level 4', year: 2020 },
  { title: 'Lawdler', level: 'Level 5', year: 2020 },
  { title: 'Lawdler', level: 'Level 6', year: 2020 },
  { title: 'Magician', level: 'Level 1', year: 2020 },
  { title: 'Magician', level: 'Level 2', year: 2020 },
  { title: 'Magician', level: 'Level 3', year: 2020 },
  { title: 'Magician', level: 'Level 4', year: 2020 },
  { title: 'Magician', level: 'Level 5', year: 2020 },
  { title: 'Magician', level: 'Level 6', year: 2020 },
  { title: 'Navigator', level: 'Level 1', year: 2020 },
  { title: 'Navigator', level: 'Level 2', year: 2020 },
  { title: 'Navigator', level: 'Level 3', year: 2020 },
  { title: 'Navigator', level: 'Level 4', year: 2020 },
  { title: 'Navigator', level: 'Level 5', year: 2020 },
  { title: 'Navigator', level: 'Level 6', year: 2020 },
  { title: 'Needle Threader', level: 'Level 1', year: 2020 },
  { title: 'Needle Threader', level: 'Level 2', year: 2020 },
  { title: 'Needle Threader', level: 'Level 3', year: 2020 },
  { title: 'Needle Threader', level: 'Level 4', year: 2020 },
  { title: 'Needle Threader', level: 'Level 5', year: 2020 },
  { title: 'Needle Threader', level: 'Level 6', year: 2020 },
  { title: 'Networker', level: 'Level 1', year: 2020 },
  { title: 'Networker', level: 'Level 2', year: 2020 },
  { title: 'Networker', level: 'Level 3', year: 2020 },
  { title: 'Networker', level: 'Level 4', year: 2020 },
  { title: 'Networker', level: 'Level 5', year: 2020 },
  { title: 'Networker', level: 'Level 6', year: 2020 },
  { title: 'On Fire', level: 'Level 1', year: 2020 },
  { title: 'On Fire', level: 'Level 2', year: 2020 },
  { title: 'On Fire', level: 'Level 3', year: 2020 },
  { title: 'On Fire', level: 'Level 4', year: 2020 },
  { title: 'On Fire', level: 'Level 5', year: 2020 },
  { title: 'On Fire', level: 'Level 6', year: 2020 },
  { title: 'On Timer', level: 'Level 1', year: 2020 },
  { title: 'On Timer', level: 'Level 2', year: 2020 },
  { title: 'On Timer', level: 'Level 3', year: 2020 },
  { title: 'On Timer', level: 'Level 4', year: 2020 },
  { title: 'On Timer', level: 'Level 5', year: 2020 },
  { title: 'On Timer', level: 'Level 6', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 1', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 2', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 3', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 4', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 5', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 6', year: 2020 },
  { title: 'Researcher', level: 'Level 1', year: 2020 },
  { title: 'Researcher', level: 'Level 2', year: 2020 },
  { title: 'Researcher', level: 'Level 3', year: 2020 },
  { title: 'Researcher', level: 'Level 4', year: 2020 },
  { title: 'Researcher', level: 'Level 5', year: 2020 },
  { title: 'Researcher', level: 'Level 6', year: 2020 },
  { title: 'Resolver', level: 'Level 1', year: 2020 },
  { title: 'Resolver', level: 'Level 2', year: 2020 },
  { title: 'Resolver', level: 'Level 3', year: 2020 },
  { title: 'Resolver', level: 'Level 4', year: 2020 },
  { title: 'Resolver', level: 'Level 5', year: 2020 },
  { title: 'Resolver', level: 'Level 6', year: 2020 },
  { title: 'Service Pro', level: 'Level 1', year: 2020 },
  { title: 'Service Pro', level: 'Level 2', year: 2020 },
  { title: 'Service Pro', level: 'Level 3', year: 2020 },
  { title: 'Service Pro', level: 'Level 4', year: 2020 },
  { title: 'Service Pro', level: 'Level 5', year: 2020 },
  { title: 'Service Pro', level: 'Level 6', year: 2020 },
  { title: 'Social Supporter', level: 'Level 1', year: 2020 },
  { title: 'Social Supporter', level: 'Level 2', year: 2020 },
  { title: 'Social Supporter', level: 'Level 3', year: 2020 },
  { title: 'Social Supporter', level: 'Level 4', year: 2020 },
  { title: 'Social Supporter', level: 'Level 5', year: 2020 },
  { title: 'Social Supporter', level: 'Level 6', year: 2020 },
  { title: 'Strategist', level: 'Level 1', year: 2020 },
  { title: 'Strategist', level: 'Level 2', year: 2020 },
  { title: 'Strategist', level: 'Level 3', year: 2020 },
  { title: 'Strategist', level: 'Level 4', year: 2020 },
  { title: 'Strategist', level: 'Level 5', year: 2020 },
  { title: 'Strategist', level: 'Level 6', year: 2020 },
  { title: 'Surprise', level: 'Level 1', year: 2020 },
  { title: 'Surprise', level: 'Level 2', year: 2020 },
  { title: 'Surprise', level: 'Level 3', year: 2020 },
  { title: 'Surprise', level: 'Level 4', year: 2020 },
  { title: 'Surprise', level: 'Level 5', year: 2020 },
  { title: 'Surprise', level: 'Level 6', year: 2020 },
  { title: 'Team Player', level: 'Level 1', year: 2020 },
  { title: 'Team Player', level: 'Level 2', year: 2020 },
  { title: 'Team Player', level: 'Level 3', year: 2020 },
  { title: 'Team Player', level: 'Level 4', year: 2020 },
  { title: 'Team Player', level: 'Level 5', year: 2020 },
  { title: 'Team Player', level: 'Level 6', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 1', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 2', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 3', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 4', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 5', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 6', year: 2020 },
  { title: 'Web3VM User', level: 'Level 1', year: 2020 },
  { title: 'Web3VM User', level: 'Level 2', year: 2020 },
  { title: 'Web3VM User', level: 'Level 3', year: 2020 },
  { title: 'Web3VM User', level: 'Level 4', year: 2020 },
  { title: 'Web3VM User', level: 'Level 5', year: 2020 },
  { title: 'Web3VM User', level: 'Level 6', year: 2020 }
];
export default function LivePreviewExample() {
  const options = topAchievments.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option
    };
  });
  return (
    <>
      <div className="app-content p-0">
        <div className="app-inner-content-layout--main">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content">
              <Grid container spacing={0} className="min-vh-100">
                <Grid item lg={7} xl={6} className="d-flex align-items-center">
                  <Grid item md={10} lg={8} xl={7} className="mx-auto">
                    <Iframe
                      id=""
                      src="https://airtable.com/embed/shr9n51gpclpXEUe2?backgroundColor=gray"
                      frameborder="0"
                      onmousewheel="mb-3"
                      width="100%"
                      height="600"
                      // height="4617"
                      className=""
                      display="block"
                      position="relative"
                      background="transparent"
                      border="1px"
                    />

                    <h1 className="display-4 mb-1 font-weight-bold">
                      Grant FastTrack
                    </h1>

                    <div className="mb-3">
                      <Autocomplete
                        id="grouped-demo"
                        options={options.sort(
                          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                        )}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Grants available for these topics."
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Letter of support from the governing bodies | 020
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 020 | "
                        name="supprt_letters_formfield_020"
                        type="textarea"
                        helperText="Helper note sits just below the form field 020 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Applicants may submit any document that verifies
                          support of the project or service. | 021
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 021 | Drop files here or SELECT FILES."
                        name="other_docs_formfield_021"
                        type="textarea"
                        helperText="Helper note sits just below the form field 021 | Accepted file types: doc, docx, pdf, Max. file size: 1 MB."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Letter of support from fiscal sponsor | 022
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 022 | "
                        name="support_letter_formfield_022"
                        type="textarea"
                        helperText="Helper note sits just below the form field 022 | Materials Note: Following grant approval, successful applicants must demonstrate municipal support for Community SmartLaw with a Memorandum of Understanding and/or an adopted resolution by the governing body. Municipal support must be established before funds will be disbursed."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          White paper | 023
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 023 | White paper or technical description."
                        name="whitepaper_formfield_023"
                        type="textarea"
                        helperText="Helper note sits just below the form field 023 | Accepted file types: doc, docx, pdf, Max. file size: 1 MB."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Letter of intent for the matching fund commitment |
                          024
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 024 | Letter of intent for the matching fund commitment."
                        name="letter_of_intent_sponsor_formfield_024"
                        type="textarea"
                        helperText="Helper note sits just below the form field 024 | Accepted file types: doc, docx, pdf, Max. file size: 1 MB."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Letters of support from partners | 025
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 025 | "
                        name="letter_of_support_partner_formfield_025"
                        type="textarea"
                        helperText="Helper note sits just below the form field 025 | Accepted file types: doc, docx, pdf, Max. file size: 1 MB."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          IRS Letter of Determination | 026
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 026 | IRS Letter of Determination."
                        name="irs_determination_letter_formfield_026"
                        type="textarea"
                        helperText="Helper note sits just below the form field 026 | Accepted file types: doc, docx, pdf, Max. file size: 1 MB."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Is there anything else you'd like us to know about
                          your application? | 027
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 027 | "
                        name="misc_info_formfield_027"
                        type="textarea"
                        helperText="Helper note sits just below the form field 027 | You may use this space to describe key factors or circumstances that could have an impact on Community SmartLaw in your community."
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid item lg={5} xl={6} className="d-flex">
                  <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image opacity-5"
                        style={{
                          backgroundImage: 'url(' + sideImage + ')'
                        }}
                      />
                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                      <div className="bg-composed-wrapper--content text-center p-5">
                        <div className="text-white px-0 px-lg-2 px-xl-4">
                          <h1 className="display-3 mb-4 font-weight-bold text-white">
                          Grant FastTrack
                          </h1>
                          <p className="font-size-lg mb-0 opacity-8">
                            SmartLaw provides tools for Web3 ecosystem to
                            advance legaltech . 11.
                          </p>
                          <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                          <div>
                            <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                              <span className="btn-wrapper--label">
                                Learn more about Grant FastTrack
                              </span>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'arrow-right']}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero-footer pb-4">
                      <List
                        component="div"
                        className="nav-pills nav-neutral-secondary d-flex">
                        <Tooltip title="Github" arrow>
                          <ListItem
                            component="a"
                            button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-size-lg text-white-50">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
