import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';

import BlocksStatistics4 from '../../example-components/Basicstats/BlocksStatistics4';
export default function Basicstats() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills SmartLaw Token Statistics"
        titleDescription="Web3Skills SmartLaw offers a reward system for learning about Web3 technologies and interacting with Web3 applications."
      />

      <ExampleWrapperSeamless>
        <BlocksStatistics4 />
      </ExampleWrapperSeamless>

    </>
  );
}
