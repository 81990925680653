import React from 'react';
import { Grid } from '@material-ui/core';

import { PageTitle } from 'layout-components';
import MarketingHero9 from '../../example-components/About/MarketingHero9';


import { ExampleWrapperSeamless } from 'layout-components';



import WidgetsCarousels4 from '../../example-components/Catalog/Carousels4';
import WidgetsCarousels5 from '../../example-components/Catalog/Carousels5';
import WidgetsCarousels21 from '../../example-components/Catalog/Carousels21';
// import WidgetsCarousels41 from '../../example-components/WidgetsCarousels/Carousels41';
import WidgetsCarousels411 from '../../example-components/Catalog/Carousels411';
import MarketingHero2222 from '../../example-components/About/MarketingHero2222';
import MarketingPartners211 from '../../example-components/MarketingPartners/MarketingPartners211';


export default function Catalog() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills Course Catalog"
        titleDescription="Quickly browse courses by clicking through the items."
      />


      <ExampleWrapperSeamless>
        <WidgetsCarousels411 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <WidgetsCarousels4 />
      </ExampleWrapperSeamless>

      {/* <ExampleWrapperSeamless>
        <WidgetsCarousels41 />
      </ExampleWrapperSeamless> */}


      <ExampleWrapperSeamless>
        <MarketingHero2222 />
      </ExampleWrapperSeamless>
      <hr />


      <Grid item md={12} lg={12}>
          <div className="p-1 mb-1">
            <ExampleWrapperSeamless>
              <MarketingPartners211 />
            </ExampleWrapperSeamless>
          </div>
        </Grid>


      <ExampleWrapperSeamless>
        <WidgetsCarousels5 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <WidgetsCarousels21 />
      </ExampleWrapperSeamless>


      <ExampleWrapperSeamless>
        <MarketingHero9 />
      </ExampleWrapperSeamless>



    </>
  );
}
