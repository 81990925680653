import React from 'react';

import { PageTitle } from 'layout-components';
import { Grid } from '@material-ui/core';
import { ExampleWrapperSeamless } from 'layout-components';

import BlocksComposed4 from '../../example-components/Connect/BlocksComposed4';



export default function Connect() {
  return (
    <>
      <PageTitle
        titleHeading="Connect to Web3"
        titleDescription="Connecting to Web3 applications is easy."
      />
      <div className="mb-spacing-6">
        <Grid container spacing={6}>

      <ExampleWrapperSeamless>
        <BlocksComposed4 />
      </ExampleWrapperSeamless>


        </Grid>
      </div>
    </>
  );
}
