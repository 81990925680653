import React from 'react';

import { Grid, Container, Card, Button } from '@material-ui/core';

import { NavLink } from 'react-router-dom';


export default function LivePreviewExample() {
  return (
    <>

      <Container className="py-5 pt-lg-0 z-over" style={{ marginTop: '-50px' }}>





        <Grid container spacing={6} className="text-center text-second pb-5">
          <Grid item lg={10} xl={8} className="mx-auto">
            <div className="font-size-sm d-inline-flex bg-neutral-primary text-primary px-4 rounded-lg text-white py-2 text-uppercase">
              Packages and Features
            </div>
            <h3 className="display-4 font-weight-bold my-4 text-uppercase">
              Web3Skills SmartLaw Consultations
            </h3>
            <p className="text-black-50 line-height-2 font-size-xl px-3 px-xl-5 mb-0">
              Web3 Legal Engineering offers consultations Web3 starter packages.
              Packages are designed to be modular and includes many of the
              features of the new internet.
            </p>
          </Grid>
        </Grid>

        <Grid container spacing={0} className="z-over pb-lg-5">
          <Grid item lg={3} className="d-flex align-items-center">
            <div className="text-white w-100 overflow-hidden pt-3 pt-lg-5 mb-5 mb-lg-0 bg-night-sky rounded br-lg-left-0">
              <div className="text-center py-3 py-lg-5">
                <div className="font-weight-bold display-4 mb-3">
                  Get Started
                </div>
                <p className="text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mb-0">
                  Say goodbye to antiquated centralized server based computing.
                  Centralized server-companies exposed their true colors and now
                  there's a better way to transact with sovereignty.
                </p>
              </div>
              <div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
              <div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-5">
                <div className="display-2 font-weight-bold">
                  <span>Free</span>
                  <span className="font-size-lg">Consultation</span>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item lg={5}>
            <Card className="bg-second text-white bg-night-skyer rounded mb-5 mb-lg-0">
              <div className="text-center rounded-top p-3 p-lg-5">
                <div className="font-weight-bold display-4">
                  Web3-connected Metaverse
                </div>
                <p className="text-white line-height-2 font-size-xl px-2 px-xl-3 mb-0">
                  A Web3 connected metaverse allows user to create their own
                  access and asset account using private-key cryptography. User
                  access information and content through an automated signing
                  system verifying each user. This is usually called private-key
                  encryption and is distinct from server-based authentication.
                  With Web3, authentication is primarily a login and connecting
                  exercise. While Web2 websites usually use an email address
                  stored in a centralized server in combination with a password;
                  Web3 appplications use a private key.
                </p>
              </div>
              <div className="divider bg-white opacity-2" />
              <div className="p-4 p-lg-5">
                <div className="d-flex align-items-center justify-content-between pb-3">
                  <div className="font-weight-bold font-size-lg">
                    Custom Spaces
                  </div>
                  <div className="bg-primary text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Ready Now
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between py-3">
                  <div className="font-weight-bold font-size-lg">
                    Custom Assets
                  </div>
                  <div className="bg-primary text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Ready Now
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    Hyperlinked Content
                  </div>
                  <div className="bg-primary text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Ready Now
                  </div>
                </div>
              </div>
              <div className="divider bg-white opacity-2" />
              <div className="d-flex bg-white-10 rounded-bottom align-items-center justify-content-between py-4 px-5">
                <div className="display-1 font-weight-bold">
                  <span>Live</span>
                  <span className="font-size-md"> Events</span>
                </div>
                <div>
                  <Button
                    component={NavLink}
                    to="/Members"
                    className="rounded-sm font-weight-bold hover-scale-lg px-4 btn-first"
                    size="large">
                    SmartLaw Dashboard
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>





          <Grid item lg={4} className="d-flex align-items-center">
            <div className="text-white w-100 overflow-hidden pt-3 pt-lg-5 mb-5 mb-lg-0 bg-night-sky rounded br-lg-left-0">
              <div className="text-center py-3 py-lg-5">
                <div className="font-weight-bold display-4 mb-3">
                  Next Level
                </div>
                <p className="text-white-50 line-height-2 font-size-lg px-2 px-xl-3 mb-0">
                  Don't just stop at the metaverse. Web3 provides a large
                  library of practical use applications that do not require much
                  overhead costs. Using smart-contracts and node-based servers
                  allow for a rich experience for the new internet of services.
                  Explore Web3Skills SmartLaw and get started with Web3 today!
                </p>
              </div>
              <div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
              <div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-2">
                <div className="display-2 font-weight-bold">
                  <span>NFT</span>
                  <span className="font-size-lg"> Airdrops</span>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

















        <Grid container spacing={0} className="z-over pb-lg-5">
          <Grid item lg={5} className="d-flex align-items-center">
            <div className="text-white w-100 overflow-hidden pt-3 pt-lg-5 mb-5 mb-lg-0 bg-grow-early-darker rounded br-lg-left-0">
              <div className="text-center py-3 py-lg-5">
                <div className="font-weight-bold display-4 mb-3">
                  Web3 Introduction
                </div>
                <p className="text-white-50 line-height-2 font-size-lg px-3 px-xl-5 mb-0">
                  This includes a live presentation of some of the newest Web3
                  applications and a Q&#38;A on how these applications are
                  focused on specific use cases.
                </p>
              </div>
              <div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
              <div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-5">
                <div className="display-4 font-weight-bold">
                  <span>Free Consultation</span>
                </div>

                <div>
                  <Button
                    component={NavLink}
                    to="/Metaverse"
                    className="rounded-sm font-weight-bold hover-scale-lg px-4 btn-success"
                    size="large">
                    Pricing Tables
                  </Button>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item lg={7}>
            <Card className="bg-second text-white bg-deep-sky rounded mb-5 mb-lg-0">
              <div className="text-center rounded-top p-3 p-lg-5">
                <div className="font-weight-bold display-3">
                  SmartLaw_API Starter Jam
                </div>
                <p className="text-white-50 line-height-2 font-size-xl px-3 px-xl-5 mb-0">
                  This package inclused a self-guided journey with
                  easy-to-follow lessons on basic Web3 applications. Lessons
                  include transacting with a blockchain and saving content to
                  nodes. Starter Jam includes 2-hour live consultation on how to
                  implent basic Web3 feautures to the use-case.
                </p>
              </div>
              <div className="divider bg-white opacity-2" />
              <div className="p-4 p-lg-5">
                <div className="d-flex align-items-center justify-content-between pb-3">
                  <div className="font-weight-bold font-size-lg">
                    2-hour SmartLaw_API Consultation
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between py-3">
                  <div className="font-weight-bold font-size-lg">
                    Custom NFT Minting (ERC-1155 Standard up to 100 addresses )
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    Avatar Wearable in Voxels SmartLaw_API with 100 airdropped
                    to 100 addresses.
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
              </div>
              <div className="divider bg-white opacity-2" />
              <div className="d-flex bg-white-10 rounded-bottom align-items-center justify-content-between py-4 px-5">
                <div className="display-1 font-weight-bold">
                  <span>$475</span>
                  <span className="font-size-md"> USD</span>
                </div>
                <div>
                  <Button
                    component={NavLink}
                    to="/Members"
                    className="rounded-sm font-weight-bold hover-scale-lg px-4 btn-first"
                    size="large">
                    SmartLaw Dashboard
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={0} className="z-over pb-lg-5">
          <Grid item lg={7}>
            <Card className="bg-second text-white bg-deep-sky rounded mb-5 mb-lg-0">
              <div className="text-center rounded-top p-3 p-lg-5">
                <div className="font-weight-bold display-3">
                  Starter Jam + SmartLaw_API Lease
                </div>
                <p className="text-white-50 line-height-2 font-size-xl px-3 px-xl-5 mb-0">
                  Everything from the Starter Jam Package andwith a customized
                  space in a leading Web3 metaverse. Select your location as you
                  would for a convention or special event. Each client will
                  recive a three 2-meter x 2-meter wall where source assets are
                  placed with corresponing hyperlinks to the contents. Spaces
                  can be accessed anonymously or through a Web3 connected
                  account. The metaverse Lease Package also includes a live
                  curated event to introcued the client's guests to learn about
                  the metaverse. Live audio feed and either a YouTube or Twitch
                  video feed is included.
                </p>
              </div>
              <div className="divider bg-white opacity-2" />
              <div className="p-4 p-lg-5">
                <div className="d-flex align-items-center justify-content-between pb-3">
                  <div className="font-weight-bold font-size-lg">
                    2-hour SmartLaw_API Consultation
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between py-3">
                  <div className="font-weight-bold font-size-lg">
                    Custom NFT Mint
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    Avatar Wearable
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    SmartLaw_Templatemotional Booth for 6-months
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    Organization Hyperlinked Assets in the Space (up to 6)
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    Live Curated Event with Live In-world Audio/Video Feed
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
              </div>
              <div className="divider bg-white opacity-2" />
              <div className="d-flex bg-white-10 rounded-bottom align-items-center justify-content-between py-4 px-5">
                <div className="display-1 font-weight-bold">
                  <span>$2,500</span>
                  <span className="font-size-md"> USD</span>
                </div>
                <div>
                  <Button
                    component={NavLink}
                    to="/Members"
                    className="rounded-sm font-weight-bold hover-scale-lg px-4 btn-first"
                    size="large">
                    SmartLaw Dashboard
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item lg={5} className="d-flex align-items-center">
            <div className="text-white w-100 overflow-hidden pt-3 pt-lg-5 mb-5 mb-lg-0 bg-light-pure-darker rounded br-lg-left-0">
              <div className="text-center py-3 py-lg-5">
                <div className="font-weight-bold display-4 mb-3">
                  SmartLaw_API Lease Details
                </div>
                <p className="text-white-50 line-height-2 font-size-lg px-3 px-xl-5 mb-0">
                  The Voxels lease includes a dedicated space to display the
                  client assets with hyperlinks to the underlying source. The
                  Voxels metaverse is high fidelity responsive and interactive
                  experience. Accessing the Voxels metaverse only required an
                  internet connection. No usernames, passwords, or credentials
                  are required for the open-world experience. Voxels in one of
                  the few open-world metaverses that also provides Web3
                  connected accounts to hold, show, wear and trade assets from
                  within the metaverse. All Web3Skills SmartLaw customers get
                  traing sessions in the metaverse as well as securing long term
                  gallery and advertising space.
                </p>
              </div>
              <div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
              <div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-5">
                <div className="display-2 font-weight-bold">
                  <span>6-Month</span>
                  <span className="font-size-md"> Lease</span>
                </div>
                <div>
                  <Button
                    component={NavLink}
                    to="/Metaverse"
                    className="rounded-sm font-weight-bold hover-scale-lg px-4 btn-success"
                    size="large">
                    Pricing Tables
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={0} className="z-over pb-lg-5">
          <Grid item lg={5} className="d-flex align-items-center">
            <div className="text-white w-100 overflow-hidden pt-3 pt-lg-5 mb-5 mb-lg-0 bg-nice-redora-darker rounded br-lg-left-0">
              <div className="text-center py-3 py-lg-5">
                <div className="font-weight-bold display-4 mb-3">
                  Community Web3 Profiles
                </div>
                <p className="text-white-50 line-height-2 font-size-lg px-3 px-xl-5 mb-0">
                  The cornerstone of any good Web3 project is anchored by a
                  community. This can be employees, social clubs, or
                  professional organizations. One way to harness the vlaue of
                  Web3 technology is to deploy applications that are set up with
                  a group of particiants connected to sryptographically secure
                  accounts. These accounts are either set up as custodial
                  accounts, or private accounts and can be mutiple use or single
                  purpose. Web3 Legal Engineering helps clients determine the
                  best use cases for Web3 implementation and offers practical
                  ways to engage a community of users.
                </p>
              </div>
              <div className="divider bg-white mt-3 mt-lg-5 opacity-2" />
              <div className="d-flex bg-white-10 align-items-center justify-content-between py-4 px-5">
                <div className="display-3 font-weight-bold">
                  <span>Token Airdrops</span>
                  <span className="font-size-lg"> Achievements</span>
                </div>
                <div>
                  <Button
                    component={NavLink}
                    to="/Metaverse"
                    className="rounded-sm font-weight-bold hover-scale-lg px-4 btn-success"
                    size="large">
                    Pricing Tables
                  </Button>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item lg={7}>
            <Card className="bg-second text-white bg-deep-sky rounded mb-5 mb-lg-0">
              <div className="text-center rounded-top p-3 p-lg-5">
                <div className="font-weight-bold display-3">
                  Web3 Profiles &#38; Achievements
                </div>
                <p className="text-white-50 line-height-2 font-size-xl px-3 px-xl-5 mb-0">
                  One of the best ways to experience Web3 is to connect to the
                  chains and nodes through cryptographic addresses. Each address
                  is privatley generated using sophisticated crtpography that
                  ensures a safe experience. Use a one time key for access to
                  content or special events, or use them for holding and trading
                  digital assets. Each user has a sovereign identity for each
                  freely minted account that is rooting in mathematics.
                </p>
              </div>
              <div className="divider bg-white opacity-2" />
              <div className="p-4 p-lg-5">
                <div className="d-flex align-items-center justify-content-between pb-3">
                  <div className="font-weight-bold font-size-lg">
                    10-hours Web3-Ready User Profiles Training
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between py-3">
                  <div className="font-weight-bold font-size-lg">
                    User Token Minting (up to 100 users)
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    Access Token Minted (up to 100 users)
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    Superuser token minting with administrative roles
                    assignments.
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
                <div className="divider opacity-2" />
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div className="font-weight-bold font-size-lg">
                    Decentralized user identities (up to 100 users).
                  </div>
                  <div className="bg-success text-uppercase font-size-sm font-weight-bold py-2 px-4 rounded-pill">
                    Included
                  </div>
                </div>
              </div>
              <div className="divider bg-white opacity-2" />
              <div className="d-flex bg-white-10 rounded-bottom align-items-center justify-content-between py-4 px-5">
                <div className="display-1 font-weight-bold">
                  <span>$7,600</span>
                  <span className="font-size-md"> USD</span>
                </div>
                <div>
                  <Button
                    component={NavLink}
                    to="/Members"
                    className="rounded-sm font-weight-bold hover-scale-lg px-4 btn-first"
                    size="large">
                    SmartLaw Dashboard
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
