import React from 'react';

import { Card, CardContent } from '@material-ui/core';

import CountUp from 'react-countup';
// import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
// import ViewCompactTwoToneIcon from '@material-ui/icons/ViewCompactTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
// import LayersTwoToneIcon from '@material-ui/icons/LayersTwoTone';

import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
// import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
import CameraTwoToneIcon from '@material-ui/icons/CameraTwoTone';



export default function LivePreviewExample() {
  return (
    <>
      <Card className="card-box">
        <div className="card-header">
          <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
            Latest Grants
          </h4>
        </div>
        <CardContent>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <CameraTwoToneIcon className="h1 d-block mr-3 text-first" />
              <div>
                <b>Grant Category 1</b>
                <div className="text-black-50">Web3 Advocacy</div>
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              <CountUp
                start={1.000}
                end={5}
                duration={6}
                delay={2}
                separator=""
                decimals={0}
                decimal=","
              />
            </div>
          </div>
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <CameraTwoToneIcon className="h1 d-block mr-3 text-first" />
              <div>
                <b>Grant Category 2</b>
                <div className="text-black-50">Web3Skills Courses</div>
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              <CountUp
                start={0}
                end={5}
                duration={6}
                delay={2}
                separator=""
                decimals={0}
                decimal=","
              />
            </div>
          </div>
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <PeopleAltTwoToneIcon className="h1 d-block mr-3 text-first" />
              <div>
                <b>Grant Category 3</b>
                <div className="text-black-50">Decentralized Identities &#38; Working Groups</div>
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              <CountUp
                start={0}
                end={7}
                duration={6}
                delay={2}
                separator=""
                decimals={0}
                decimal=","
              />
            </div>
          </div>
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <AccountBalanceTwoToneIcon className="h1 d-block mr-3 text-first" />
              <div>
                <b>Grant Category 4</b>
                <div className="text-black-50">Dispute Resolutions</div>
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              <CountUp
                start={0}
                end={10}
                duration={6}
                delay={2}
                separator=""
                decimals={0}
                decimal=","
              />
            </div>
          </div>

        </CardContent>
      </Card>
    </>
  );
}
