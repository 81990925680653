import React from 'react';



import lexautomatico09 from '../../../assets/images/lexautomatica/LEXAUTOMATICO-logo-09-200w.png';
import lexautomatica099200w from '../../../assets/images/lexautomatica/lexautomatica-099-200w.png';




export default function LivePreviewExample() {
  return (
    <>

<div>
        <a
          href="https://web3skills.com"
          target="_blank"
          rel="noopener noreferrer"
          className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
          <img src={lexautomatica099200w} className="" alt="..." />
          <div className="p-3 bg-secondary unded-bottom p-xl-4">
            <img src={lexautomatico09} alt="..." style={{ height: 40 }} />
            <p className="text-second opacity-8 mt-4 mb-0">
              Web3Skills_SmartLaw course and service descriptions. 1568
            </p>
          </div>
        </a>
      </div>

    </>
  );
}
