import React from 'react';
import {
  Card,
  Grid,
  Button,
  Container,
  Divider,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import Iframe from 'react-iframe';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
const topAchievments = [
  { title: 'Newb', level: 'Level 1', year: 2020 },
  { title: 'Newb', level: 'Level 2', year: 2020 },
  { title: 'Newb', level: 'Level 3', year: 2020 },
  { title: 'Newb', level: 'Level 4', year: 2020 },
  { title: 'Newb', level: 'Level 5', year: 2020 },
  { title: 'Newb', level: 'Level 6', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 1', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 2', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 3', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 4', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 5', year: 2020 },
  { title: 'Airdrop Surprise', level: 'Level 6', year: 2020 },
  { title: 'Ethereum', level: 'Level 1', year: 2020 },
  { title: 'Ethereum', level: 'Level 2', year: 2020 },
  { title: 'Ethereum', level: 'Level 3', year: 2020 },
  { title: 'Ethereum', level: 'Level 4', year: 2020 },
  { title: 'Ethereum', level: 'Level 5', year: 2020 },
  { title: 'Ethereum', level: 'Level 6', year: 2020 },
  { title: 'Nodist', level: 'Level 1', year: 2020 },
  { title: 'Nodist', level: 'Level 2', year: 2020 },
  { title: 'Nodist', level: 'Level 3', year: 2020 },
  { title: 'Nodist', level: 'Level 4', year: 2020 },
  { title: 'Nodist', level: 'Level 5', year: 2020 },
  { title: 'Nodist', level: 'Level 6', year: 2020 },
  { title: 'Tidbits', level: 'Level 1', year: 2020 },
  { title: 'Tidbits', level: 'Level 2', year: 2020 },
  { title: 'Tidbits', level: 'Level 3', year: 2020 },
  { title: 'Tidbits', level: 'Level 4', year: 2020 },
  { title: 'Tidbits', level: 'Level 5', year: 2020 },
  { title: 'Tidbits', level: 'Level 6', year: 2020 },
  { title: 'Wardens', level: 'Level 1', year: 2020 },
  { title: 'Wardens', level: 'Level 2', year: 2020 },
  { title: 'Wardens', level: 'Level 3', year: 2020 },
  { title: 'Wardens', level: 'Level 4', year: 2020 },
  { title: 'Wardens', level: 'Level 5', year: 2020 },
  { title: 'Wardens', level: 'Level 6', year: 2020 },
  { title: 'Judiciary', level: 'Level 1', year: 2020 },
  { title: 'Judiciary', level: 'Level 2', year: 2020 },
  { title: 'Judiciary', level: 'Level 3', year: 2020 },
  { title: 'Judiciary', level: 'Level 4', year: 2020 },
  { title: 'Judiciary', level: 'Level 5', year: 2020 },
  { title: 'Judiciary', level: 'Level 6', year: 2020 },
  { title: 'Space Traveler', level: 'Level 1', year: 2020 },
  { title: 'Space Traveler', level: 'Level 2', year: 2020 },
  { title: 'Space Traveler', level: 'Level 3', year: 2020 },
  { title: 'Space Traveler', level: 'Level 4', year: 2020 },
  { title: 'Space Traveler', level: 'Level 5', year: 2020 },
  { title: 'Space Traveler', level: 'Level 6', year: 2020 },
  { title: 'Miner', level: 'Level 1', year: 2020 },
  { title: 'Miner', level: 'Level 2', year: 2020 },
  { title: 'Miner', level: 'Level 3', year: 2020 },
  { title: 'Miner', level: 'Level 4', year: 2020 },
  { title: 'Miner', level: 'Level 5', year: 2020 },
  { title: 'Miner', level: 'Level 6', year: 2020 },
  { title: 'Architect', level: 'Level 1', year: 2020 },
  { title: 'Architect', level: 'Level 2', year: 2020 },
  { title: 'Architect', level: 'Level 3', year: 2020 },
  { title: 'Architect', level: 'Level 4', year: 2020 },
  { title: 'Architect', level: 'Level 5', year: 2020 },
  { title: 'Architect', level: 'Level 6', year: 2020 },
  { title: 'Beaconer', level: 'Level 1', year: 2020 },
  { title: 'Beaconer', level: 'Level 2', year: 2020 },
  { title: 'Beaconer', level: 'Level 3', year: 2020 },
  { title: 'Beaconer', level: 'Level 4', year: 2020 },
  { title: 'Beaconer', level: 'Level 5', year: 2020 },
  { title: 'Beaconer', level: 'Level 6', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 1', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 2', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 3', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 4', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 5', year: 2020 },
  { title: 'Heavy Mech', level: 'Level 6', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 1', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 2', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 3', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 4', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 5', year: 2020 },
  { title: 'Seen And Heard', level: 'Level 6', year: 2020 },
  { title: 'Future Self', level: 'Level 1', year: 2020 },
  { title: 'Future Self', level: 'Level 2', year: 2020 },
  { title: 'Future Self', level: 'Level 3', year: 2020 },
  { title: 'Future Self', level: 'Level 4', year: 2020 },
  { title: 'Future Self', level: 'Level 5', year: 2020 },
  { title: 'Future Self', level: 'Level 6', year: 2020 },
  { title: 'Mechanic', level: 'Level 1', year: 2020 },
  { title: 'Mechanic', level: 'Level 2', year: 2020 },
  { title: 'Mechanic', level: 'Level 3', year: 2020 },
  { title: 'Mechanic', level: 'Level 4', year: 2020 },
  { title: 'Mechanic', level: 'Level 5', year: 2020 },
  { title: 'Mechanic', level: 'Level 6', year: 2020 },
  { title: 'Renewer', level: 'Level 1', year: 2020 },
  { title: 'Renewer', level: 'Level 2', year: 2020 },
  { title: 'Renewer', level: 'Level 3', year: 2020 },
  { title: 'Renewer', level: 'Level 4', year: 2020 },
  { title: 'Renewer', level: 'Level 5', year: 2020 },
  { title: 'Renewer', level: 'Level 6', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 1', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 2', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 3', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 4', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 5', year: 2020 },
  { title: 'Leisurely Pursuits', level: 'Level 6', year: 2020 },
  { title: 'Who Cdc', level: 'Level 1', year: 2020 },
  { title: 'Who Cdc', level: 'Level 2', year: 2020 },
  { title: 'Who Cdc', level: 'Level 3', year: 2020 },
  { title: 'Who Cdc', level: 'Level 4', year: 2020 },
  { title: 'Who Cdc', level: 'Level 5', year: 2020 },
  { title: 'Who Cdc', level: 'Level 6', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 1', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 2', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 3', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 4', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 5', year: 2020 },
  { title: 'Bug Hunter', level: 'Level 6', year: 2020 },
  { title: 'Bug Killer', level: 'Level 1', year: 2020 },
  { title: 'Bug Killer', level: 'Level 2', year: 2020 },
  { title: 'Bug Killer', level: 'Level 3', year: 2020 },
  { title: 'Bug Killer', level: 'Level 4', year: 2020 },
  { title: 'Bug Killer', level: 'Level 5', year: 2020 },
  { title: 'Bug Killer', level: 'Level 6', year: 2020 },
  { title: 'On Target', level: 'Level 1', year: 2020 },
  { title: 'On Target', level: 'Level 2', year: 2020 },
  { title: 'On Target', level: 'Level 3', year: 2020 },
  { title: 'On Target', level: 'Level 4', year: 2020 },
  { title: 'On Target', level: 'Level 5', year: 2020 },
  { title: 'On Target', level: 'Level 6', year: 2020 },
  { title: 'Account Maker', level: 'Level 1', year: 2020 },
  { title: 'Account Maker', level: 'Level 2', year: 2020 },
  { title: 'Account Maker', level: 'Level 3', year: 2020 },
  { title: 'Account Maker', level: 'Level 4', year: 2020 },
  { title: 'Account Maker', level: 'Level 5', year: 2020 },
  { title: 'Account Maker', level: 'Level 6', year: 2020 },
  { title: 'Announcer', level: 'Level 1', year: 2020 },
  { title: 'Announcer', level: 'Level 2', year: 2020 },
  { title: 'Announcer', level: 'Level 3', year: 2020 },
  { title: 'Announcer', level: 'Level 4', year: 2020 },
  { title: 'Announcer', level: 'Level 5', year: 2020 },
  { title: 'Announcer', level: 'Level 6', year: 2020 },
  { title: 'Big Brain', level: 'Level 1', year: 2020 },
  { title: 'Big Brain', level: 'Level 2', year: 2020 },
  { title: 'Big Brain', level: 'Level 3', year: 2020 },
  { title: 'Big Brain', level: 'Level 4', year: 2020 },
  { title: 'Big Brain', level: 'Level 5', year: 2020 },
  { title: 'Big Brain', level: 'Level 6', year: 2020 },
  { title: 'Big Show', level: 'Level 1', year: 2020 },
  { title: 'Big Show', level: 'Level 2', year: 2020 },
  { title: 'Big Show', level: 'Level 3', year: 2020 },
  { title: 'Big Show', level: 'Level 4', year: 2020 },
  { title: 'Big Show', level: 'Level 5', year: 2020 },
  { title: 'Big Show', level: 'Level 6', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 1', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 2', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 3', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 4', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 5', year: 2020 },
  { title: 'Bonded Pig', level: 'Level 6', year: 2020 },
  { title: 'Brickler', level: 'Level 1', year: 2020 },
  { title: 'Brickler', level: 'Level 2', year: 2020 },
  { title: 'Brickler', level: 'Level 3', year: 2020 },
  { title: 'Brickler', level: 'Level 4', year: 2020 },
  { title: 'Brickler', level: 'Level 5', year: 2020 },
  { title: 'Brickler', level: 'Level 6', year: 2020 },
  { title: 'Decentralizer', level: 'Level 1', year: 2020 },
  { title: 'Decentralizer', level: 'Level 2', year: 2020 },
  { title: 'Decentralizer', level: 'Level 3', year: 2020 },
  { title: 'Decentralizer', level: 'Level 4', year: 2020 },
  { title: 'Decentralizer', level: 'Level 5', year: 2020 },
  { title: 'Decentralizer', level: 'Level 6', year: 2020 },
  { title: 'Detector', level: 'Level 1', year: 2020 },
  { title: 'Detector', level: 'Level 2', year: 2020 },
  { title: 'Detector', level: 'Level 3', year: 2020 },
  { title: 'Detector', level: 'Level 4', year: 2020 },
  { title: 'Detector', level: 'Level 5', year: 2020 },
  { title: 'Detector', level: 'Level 6', year: 2020 },
  { title: 'Documenteer', level: 'Level 1', year: 2020 },
  { title: 'Documenteer', level: 'Level 2', year: 2020 },
  { title: 'Documenteer', level: 'Level 3', year: 2020 },
  { title: 'Documenteer', level: 'Level 4', year: 2020 },
  { title: 'Documenteer', level: 'Level 5', year: 2020 },
  { title: 'Documenteer', level: 'Level 6', year: 2020 },
  { title: 'Entertainer', level: 'Level 1', year: 2020 },
  { title: 'Entertainer', level: 'Level 2', year: 2020 },
  { title: 'Entertainer', level: 'Level 3', year: 2020 },
  { title: 'Entertainer', level: 'Level 4', year: 2020 },
  { title: 'Entertainer', level: 'Level 5', year: 2020 },
  { title: 'Entertainer', level: 'Level 6', year: 2020 },
  { title: 'Feast', level: 'Level 1', year: 2020 },
  { title: 'Feast', level: 'Level 2', year: 2020 },
  { title: 'Feast', level: 'Level 3', year: 2020 },
  { title: 'Feast', level: 'Level 4', year: 2020 },
  { title: 'Feast', level: 'Level 5', year: 2020 },
  { title: 'Feast', level: 'Level 6', year: 2020 },
  { title: 'Fire Starter', level: 'Level 1', year: 2020 },
  { title: 'Fire Starter', level: 'Level 2', year: 2020 },
  { title: 'Fire Starter', level: 'Level 3', year: 2020 },
  { title: 'Fire Starter', level: 'Level 4', year: 2020 },
  { title: 'Fire Starter', level: 'Level 5', year: 2020 },
  { title: 'Fire Starter', level: 'Level 6', year: 2020 },
  { title: 'Good Show', level: 'Level 1', year: 2020 },
  { title: 'Good Show', level: 'Level 2', year: 2020 },
  { title: 'Good Show', level: 'Level 3', year: 2020 },
  { title: 'Good Show', level: 'Level 4', year: 2020 },
  { title: 'Good Show', level: 'Level 5', year: 2020 },
  { title: 'Good Show', level: 'Level 6', year: 2020 },
  { title: 'Helping Hand', level: 'Level 1', year: 2020 },
  { title: 'Helping Hand', level: 'Level 2', year: 2020 },
  { title: 'Helping Hand', level: 'Level 3', year: 2020 },
  { title: 'Helping Hand', level: 'Level 4', year: 2020 },
  { title: 'Helping Hand', level: 'Level 5', year: 2020 },
  { title: 'Helping Hand', level: 'Level 6', year: 2020 },
  { title: 'Hunter', level: 'Level 1', year: 2020 },
  { title: 'Hunter', level: 'Level 2', year: 2020 },
  { title: 'Hunter', level: 'Level 3', year: 2020 },
  { title: 'Hunter', level: 'Level 4', year: 2020 },
  { title: 'Hunter', level: 'Level 5', year: 2020 },
  { title: 'Hunter', level: 'Level 6', year: 2020 },
  { title: 'Iam Idea', level: 'Level 1', year: 2020 },
  { title: 'Iam Idea', level: 'Level 2', year: 2020 },
  { title: 'Iam Idea', level: 'Level 3', year: 2020 },
  { title: 'Iam Idea', level: 'Level 4', year: 2020 },
  { title: 'Iam Idea', level: 'Level 5', year: 2020 },
  { title: 'Iam Idea', level: 'Level 6', year: 2020 },
  { title: 'Insurer', level: 'Level 1', year: 2020 },
  { title: 'Insurer', level: 'Level 2', year: 2020 },
  { title: 'Insurer', level: 'Level 3', year: 2020 },
  { title: 'Insurer', level: 'Level 4', year: 2020 },
  { title: 'Insurer', level: 'Level 5', year: 2020 },
  { title: 'Insurer', level: 'Level 6', year: 2020 },
  { title: 'Internationaler', level: 'Level 1', year: 2020 },
  { title: 'Internationaler', level: 'Level 2', year: 2020 },
  { title: 'Internationaler', level: 'Level 3', year: 2020 },
  { title: 'Internationaler', level: 'Level 4', year: 2020 },
  { title: 'Internationaler', level: 'Level 5', year: 2020 },
  { title: 'Internationaler', level: 'Level 6', year: 2020 },
  { title: 'Lawdler', level: 'Level 1', year: 2020 },
  { title: 'Lawdler', level: 'Level 2', year: 2020 },
  { title: 'Lawdler', level: 'Level 3', year: 2020 },
  { title: 'Lawdler', level: 'Level 4', year: 2020 },
  { title: 'Lawdler', level: 'Level 5', year: 2020 },
  { title: 'Lawdler', level: 'Level 6', year: 2020 },
  { title: 'Magician', level: 'Level 1', year: 2020 },
  { title: 'Magician', level: 'Level 2', year: 2020 },
  { title: 'Magician', level: 'Level 3', year: 2020 },
  { title: 'Magician', level: 'Level 4', year: 2020 },
  { title: 'Magician', level: 'Level 5', year: 2020 },
  { title: 'Magician', level: 'Level 6', year: 2020 },
  { title: 'Navigator', level: 'Level 1', year: 2020 },
  { title: 'Navigator', level: 'Level 2', year: 2020 },
  { title: 'Navigator', level: 'Level 3', year: 2020 },
  { title: 'Navigator', level: 'Level 4', year: 2020 },
  { title: 'Navigator', level: 'Level 5', year: 2020 },
  { title: 'Navigator', level: 'Level 6', year: 2020 },
  { title: 'Needle Threader', level: 'Level 1', year: 2020 },
  { title: 'Needle Threader', level: 'Level 2', year: 2020 },
  { title: 'Needle Threader', level: 'Level 3', year: 2020 },
  { title: 'Needle Threader', level: 'Level 4', year: 2020 },
  { title: 'Needle Threader', level: 'Level 5', year: 2020 },
  { title: 'Needle Threader', level: 'Level 6', year: 2020 },
  { title: 'Networker', level: 'Level 1', year: 2020 },
  { title: 'Networker', level: 'Level 2', year: 2020 },
  { title: 'Networker', level: 'Level 3', year: 2020 },
  { title: 'Networker', level: 'Level 4', year: 2020 },
  { title: 'Networker', level: 'Level 5', year: 2020 },
  { title: 'Networker', level: 'Level 6', year: 2020 },
  { title: 'On Fire', level: 'Level 1', year: 2020 },
  { title: 'On Fire', level: 'Level 2', year: 2020 },
  { title: 'On Fire', level: 'Level 3', year: 2020 },
  { title: 'On Fire', level: 'Level 4', year: 2020 },
  { title: 'On Fire', level: 'Level 5', year: 2020 },
  { title: 'On Fire', level: 'Level 6', year: 2020 },
  { title: 'On Timer', level: 'Level 1', year: 2020 },
  { title: 'On Timer', level: 'Level 2', year: 2020 },
  { title: 'On Timer', level: 'Level 3', year: 2020 },
  { title: 'On Timer', level: 'Level 4', year: 2020 },
  { title: 'On Timer', level: 'Level 5', year: 2020 },
  { title: 'On Timer', level: 'Level 6', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 1', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 2', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 3', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 4', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 5', year: 2020 },
  { title: 'Pawn Tokener', level: 'Level 6', year: 2020 },
  { title: 'Researcher', level: 'Level 1', year: 2020 },
  { title: 'Researcher', level: 'Level 2', year: 2020 },
  { title: 'Researcher', level: 'Level 3', year: 2020 },
  { title: 'Researcher', level: 'Level 4', year: 2020 },
  { title: 'Researcher', level: 'Level 5', year: 2020 },
  { title: 'Researcher', level: 'Level 6', year: 2020 },
  { title: 'Resolver', level: 'Level 1', year: 2020 },
  { title: 'Resolver', level: 'Level 2', year: 2020 },
  { title: 'Resolver', level: 'Level 3', year: 2020 },
  { title: 'Resolver', level: 'Level 4', year: 2020 },
  { title: 'Resolver', level: 'Level 5', year: 2020 },
  { title: 'Resolver', level: 'Level 6', year: 2020 },
  { title: 'Service Pro', level: 'Level 1', year: 2020 },
  { title: 'Service Pro', level: 'Level 2', year: 2020 },
  { title: 'Service Pro', level: 'Level 3', year: 2020 },
  { title: 'Service Pro', level: 'Level 4', year: 2020 },
  { title: 'Service Pro', level: 'Level 5', year: 2020 },
  { title: 'Service Pro', level: 'Level 6', year: 2020 },
  { title: 'Social Supporter', level: 'Level 1', year: 2020 },
  { title: 'Social Supporter', level: 'Level 2', year: 2020 },
  { title: 'Social Supporter', level: 'Level 3', year: 2020 },
  { title: 'Social Supporter', level: 'Level 4', year: 2020 },
  { title: 'Social Supporter', level: 'Level 5', year: 2020 },
  { title: 'Social Supporter', level: 'Level 6', year: 2020 },
  { title: 'Strategist', level: 'Level 1', year: 2020 },
  { title: 'Strategist', level: 'Level 2', year: 2020 },
  { title: 'Strategist', level: 'Level 3', year: 2020 },
  { title: 'Strategist', level: 'Level 4', year: 2020 },
  { title: 'Strategist', level: 'Level 5', year: 2020 },
  { title: 'Strategist', level: 'Level 6', year: 2020 },
  { title: 'Surprise', level: 'Level 1', year: 2020 },
  { title: 'Surprise', level: 'Level 2', year: 2020 },
  { title: 'Surprise', level: 'Level 3', year: 2020 },
  { title: 'Surprise', level: 'Level 4', year: 2020 },
  { title: 'Surprise', level: 'Level 5', year: 2020 },
  { title: 'Surprise', level: 'Level 6', year: 2020 },
  { title: 'Team Player', level: 'Level 1', year: 2020 },
  { title: 'Team Player', level: 'Level 2', year: 2020 },
  { title: 'Team Player', level: 'Level 3', year: 2020 },
  { title: 'Team Player', level: 'Level 4', year: 2020 },
  { title: 'Team Player', level: 'Level 5', year: 2020 },
  { title: 'Team Player', level: 'Level 6', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 1', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 2', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 3', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 4', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 5', year: 2020 },
  { title: 'Transfer Tech', level: 'Level 6', year: 2020 },
  { title: 'Web3VM User', level: 'Level 1', year: 2020 },
  { title: 'Web3VM User', level: 'Level 2', year: 2020 },
  { title: 'Web3VM User', level: 'Level 3', year: 2020 },
  { title: 'Web3VM User', level: 'Level 4', year: 2020 },
  { title: 'Web3VM User', level: 'Level 5', year: 2020 },
  { title: 'Web3VM User', level: 'Level 6', year: 2020 }
];
export default function LivePreviewExample() {
  return (
    <>
      <div className="app-content p-0">
        <Card className="py-4">
          <Container className="py-3">
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={topAchievments}
              getOptionLabel={(option) => option.title}
              defaultValue={[topAchievments[13]]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="The Grant Tags"
                  placeholder="Select all the categories that apply to the proposed grant."
                  fullWidth
                />
              )}
            />
          </Container>
          <Divider className="opacity-7 my-4" />
          <Container className="py-3 mb-3">
            <Autocomplete
              multiple
              id="size-small-filled-multi"
              size="small"
              options={topAchievments}
              getOptionLabel={(option) => option.title}
              defaultValue={[topAchievments[13]]}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.title}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="The Grants Working Group"
                  placeholder="Select all the SmartLaw grants committee members you would like to work with."
                  fullWidth
                />
              )}
            />
          </Container>
        </Card>

        <div className="app-inner-content-layout--main">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content">
              <Grid container spacing={0} className="min-vh-100">
                <Grid item lg={7} xl={6} className="d-flex align-items-center">
                  <Grid item md={10} lg={8} xl={7} className="mx-auto">
                    <h1 className="display-4 mb-1 mt-5 font-weight-bold">
                      Needs &#38; Specific Objectives of the Project
                    </h1>

                    <Iframe
                      id=""
                      src="https://airtable.com/embed/shr3FOPnJr85YFqyd?backgroundColor=gray"
                      frameborder="0"
                      onmousewheel=""
                      width="100%"
                      height="600"
                      // height="1989"
                      className="mb-3 mt-3"
                      display="block"
                      position="relative"
                      background="transparent"
                      border="1px"
                    />

                    <div className="mb-3 mt-">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Specific Description of Proposed Project | 074
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 074 | "
                        name="project_focus_formfield_074"
                        type="textarea"
                        helperText="Helper note sits just below the form field 074 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Clearly identify the means of evaluating the data or
                          the conclusions. | 075
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 075 | "
                        name="means_of_eval_formfield_075"
                        type="textarea"
                        helperText="Helper note sits just below the form field 075 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Clearly describe the connection between the objectives
                          and the methods to show that the approach is carefully
                          developed and thought out. | 076
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 076 | "
                        name="connection_btwn_methods_formfield_076"
                        type="textarea"
                        helperText="Helper note sits just below the form field 076 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Project Description a.k.a. Action Plan, Management
                          Plan, Operating Plan, Project Narrative, Research
                          Design | 077
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 077 | "
                        name="project_attachement_categories_formfield_077"
                        type="textarea"
                        helperText="Helper note sits just below the form field 077 | Describes the methods and procedures, plan of work, project activities, and timelines of the proposed project."
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Statement of Need | 078
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 078 | "
                        name="need_statement_formfield_078"
                        type="textarea"
                        helperText="Helper note sits just below the form field 078 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Why your organization does what it does and why
                          funding is needed | 079
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 079 | "
                        name="org_does_what_funding_need_formfield_079"
                        type="textarea"
                        helperText="Helper note sits just below the form field 079 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Project Purpose and Statement of Need | 080
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 080 | "
                        name="project_purpose_need_stmnt_formfield_080"
                        type="text"
                        helperText="Helper note sits just below the form field 080 | This section clearly describes the general problem or need to be addressed by your project. Such a problem might be homelessness in Utica. "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Problem Statement a.k.a. Needs Assessment, Needs
                          Statement | 081
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 081 | "
                        name="problem_stmnt_needs_assessment_formfield_081"
                        type="textarea"
                        helperText="Helper note sits just below the form field 081 | Defines a problem addressed by implementation of the proposed project. Considers community, organizational or systemic implications. Demonstrates need through data analysis. Explains relevance of project to funder’s mission. Includes primary and secondary target populations and means of access."
                      />
                    </div>

                    <Iframe
                      id=""
                      src="https://airtable.com/embed/shrnwBef96yypuTsa?backgroundColor=gray"
                      frameborder="0"
                      onmousewheel=""
                      width="100%"
                      height="600"
                      // height="1713"
                      className="mt-3 mb-3"
                      display="block"
                      position="relative"
                      background="transparent"
                      border="1px"
                    />

                    <h1 className="display-4 mb-1 font-weight-bold">
                      Expected Outcomes, Positive Impact, Evaluation
                    </h1>
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Resources a.k.a. Equipment and Facilities, Space
                          Requirements | 082
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 082 | "
                        name="resources_formfield_082"
                        type="textarea"
                        helperText="Helper note sits just below the form field 082 | Explains availability and accessibility of equipment, facilities, and/or other resources necessary for successful implementation of the proposed project. Sample Proposal 10/2014"
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          Appendices a.k.a. Attachments, Background Materials,
                          Supporting Documents | 083
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 083 | "
                        name="additional_materials_formfield_083"
                        type="textarea"
                        helperText="Helper note sits just below the form field 083 | "
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          The Work Abstract | 084
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 084 | "
                        name="The Work Abstract_formfield_084"
                        type="textarea"
                        helperText="Helper note sits just below the form field 084 | Why and/or how the work was done (the basic problem), the specific purpose or objective, and the scope of the work if that is relevant. (College lab reports may not require this part of the abstract.)"
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          How the work was done, the test methods or means of
                          investigation | 085
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 085 | "
                        name="test_methods_investigation_formfield_085"
                        type="textarea"
                        helperText="Helper note sits just below the form field 085 | Evaluating Abstracts: Methodology: Activities (Where Appropriate)"
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2">
                          States what was done and what results were found | 086
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="font-weight-bold mb-2"></label>
                      </div>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Placeholder in the form field 086 | "
                        name="process_and_results_formfield_086"
                        type="textarea"
                        helperText="Helper note sits just below the form field 086"
                      />
                    </div>

                    <Card className="py-4">
                      <Container className="py-3">
                        <Autocomplete
                          multiple
                          id="size-small-outlined-multi"
                          size="small"
                          options={topAchievments}
                          getOptionLabel={(option) => option.title}
                          defaultValue={[topAchievments[13]]}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="The Grant Tags"
                              placeholder="Select all the categories that apply to the proposed grant."
                              fullWidth
                            />
                          )}
                        />
                      </Container>
                      <Divider className="opacity-7 my-4" />
                      <Container className="py-3">
                        <Autocomplete
                          multiple
                          id="size-small-filled-multi"
                          size="small"
                          options={topAchievments}
                          getOptionLabel={(option) => option.title}
                          defaultValue={[topAchievments[13]]}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.title}
                                size="small"
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              label="The Grants Working Group"
                              placeholder="Select all the SmartLaw grants committee members you would like to work with."
                              fullWidth
                            />
                          )}
                        />
                      </Container>
                    </Card>
                  </Grid>
                </Grid>

                <Grid item lg={5} xl={6} className="d-flex">
                  <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image opacity-5"
                        style={{
                          backgroundImage: 'url(' + sideImage + ')'
                        }}
                      />
                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                      <div className="bg-composed-wrapper--content text-center p-5">
                        <div className="text-white px-0 px-lg-2 px-xl-4">
                          <h1 className="display-3 mb-4 font-weight-bold text-white">
                          Needs &#38; Specific Objectives of the Project
                          </h1>
                          <p className="font-size-lg mb-0 opacity-8">
                            SmartLaw provides tools for Web3 ecosystem to
                            advance legaltech . 11.
                          </p>
                          <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                          <div>
                            <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                              <span className="btn-wrapper--label">
                                Learn more about Grant Needs &#38; Specific Objectives of the Project
                              </span>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'arrow-right']}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero-footer pb-4">
                      <List
                        component="div"
                        className="nav-pills nav-neutral-secondary d-flex">
                        <Tooltip title="Github" arrow>
                          <ListItem
                            component="a"
                            button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-size-lg text-white-50">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
