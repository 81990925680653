import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip
} from '@material-ui/core';


import ak_flag from '../../../assets/images/us-w80/ak.png';
import al_flag from '../../../assets/images/us-w80/al.png';
import ar_flag from '../../../assets/images/us-w80/ar.png';
import az_flag from '../../../assets/images/us-w80/az.png';
import ca_flag from '../../../assets/images/us-w80/ca.png';
import co_flag from '../../../assets/images/us-w80/co.png';
import ct_flag from '../../../assets/images/us-w80/ct.png';
import de_flag from '../../../assets/images/us-w80/de.png';
import fl_flag from '../../../assets/images/us-w80/fl.png';
import ga_flag from '../../../assets/images/us-w80/ga.png';
import hi_flag from '../../../assets/images/us-w80/hi.png';
import ia_flag from '../../../assets/images/us-w80/ia.png';
import id_flag from '../../../assets/images/us-w80/id.png';
import il_flag from '../../../assets/images/us-w80/il.png';
import in_flag from '../../../assets/images/us-w80/in.png';
import ks_flag from '../../../assets/images/us-w80/ks.png';
import ky_flag from '../../../assets/images/us-w80/ky.png';
import la_flag from '../../../assets/images/us-w80/la.png';
import ma_flag from '../../../assets/images/us-w80/ma.png';
import md_flag from '../../../assets/images/us-w80/md.png';
import me_flag from '../../../assets/images/us-w80/me.png';
import mi_flag from '../../../assets/images/us-w80/mi.png';
import mn_flag from '../../../assets/images/us-w80/mn.png';
import mo_flag from '../../../assets/images/us-w80/mo.png';
import ms_flag from '../../../assets/images/us-w80/ms.png';
import mt_flag from '../../../assets/images/us-w80/mt.png';
import nc_flag from '../../../assets/images/us-w80/nc.png';
import nd_flag from '../../../assets/images/us-w80/nd.png';
import ne_flag from '../../../assets/images/us-w80/ne.png';
import nh_flag from '../../../assets/images/us-w80/nh.png';
import nj_flag from '../../../assets/images/us-w80/nj.png';
import nm_flag from '../../../assets/images/us-w80/nm.png';
import nv_flag from '../../../assets/images/us-w80/nv.png';
import ny_flag from '../../../assets/images/us-w80/ny.png';
import oh_flag from '../../../assets/images/us-w80/oh.png';
import ok_flag from '../../../assets/images/us-w80/ok.png';
import or_flag from '../../../assets/images/us-w80/or.png';
import pa_flag from '../../../assets/images/us-w80/pa.png';
import ri_flag from '../../../assets/images/us-w80/ri.png';
import sc_flag from '../../../assets/images/us-w80/sc.png';
import sd_flag from '../../../assets/images/us-w80/sd.png';
import tn_flag from '../../../assets/images/us-w80/tn.png';
import tx_flag from '../../../assets/images/us-w80/tx.png';
import ut_flag from '../../../assets/images/us-w80/ut.png';
import va_flag from '../../../assets/images/us-w80/va.png';
import vt_flag from '../../../assets/images/us-w80/vt.png';
import wa_flag from '../../../assets/images/us-w80/wa.png';
import wi_flag from '../../../assets/images/us-w80/wi.png';
import wv_flag from '../../../assets/images/us-w80/wv.png';
import wy_flag from '../../../assets/images/us-w80/wy.png';



export default function LivePreviewExample() {
  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header pr-2">
          <div className="card-header--title">SmartLaw State Bar Administrators</div>
          <div className="card-header--actions">
            <Tooltip title="Refresh">
              <Button size="small" className="btn-neutral-primary">
                <FontAwesomeIcon icon={['fas', 'cog']} spin />
              </Button>
            </Tooltip>
          </div>
        </div>
        <CardContent>
          <div className="table-responsive-md">
            <Table className="table table-borderless table-hover text-nowrap mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="text-left">Flag, Name & State Bar</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Progress</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>


                <tr>
                  <td>1</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Alabama-flag" src={al_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.state.al.us" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Alabama">
                          The state of Alabama
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.alabar.org/find-a-member/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Alabama">
                            Alabama Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={20}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Alaska-flag" src={ak_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://www.commerce.alaska.gov/web/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Alaska">
                          The state of Alaska
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://alaskabar.org/member-services/member-directories/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Alaska">
                            Alaska Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={40}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Arizona-flag" src={az_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://ecorp.azcc.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Arizona">
                          The state of Arizona
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://azbar.legalserviceslink.com/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Arizona">
                            Arizona Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={80}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Arkansas-flag" src={ar_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.arkansas.gov/Pages/default.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Arkansas">
                          The state of Arkansas
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://attorneyinfo.aoc.arkansas.gov/info/attorney_search/info/attorney/attorneysearch.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Arkansas">
                            Arkansas Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={25}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="California-flag" src={ca_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.ca.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of California">
                          The state of California
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="http://members.calbar.ca.gov/fal/LicenseeSearch/QuickSearch?ResultType=0&amp;SearchType=0&amp;SoundsLike=False" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of California">
                            California Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={60}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Colorado-flag" src={co_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.state.co.us" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Colorado">
                          The state of Colorado
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="http://www.coloradosupremecourt.com/Search/AttSearch.asp" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Colorado">
                            Colorado Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={45}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Connecticut-flag" src={ct_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://portal.ct.gov/sots" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Connecticut">
                          The state of Connecticut
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.jud.ct.gov/attorneyfirminquiry/AttorneyFirmInquiry.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Connecticut">
                            Connecticut Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={15}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Delaware-flag" src={de_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.corp.delaware.gov" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Delaware">
                          The state of Delaware
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://rp470541.doelegal.com/vwPublicSearch/Show-VwPublicSearch-Table.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Delaware">
                            Delaware Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={85}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Florida-flag" src={fl_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.dos.state.fl.us" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Florida">
                          The state of Florida
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.floridabar.org/directories/find-mbr/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Florida">
                            Florida Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={90}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Georgia-flag" src={ga_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://sos.ga.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Georgia">
                          The state of Georgia
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.gabar.org/membership/membersearch.cfm" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Georgia">
                            Georgia Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={15}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Hawaii-flag" src={hi_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://portal.ehawaii.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Hawaii">
                          The state of Hawaii
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://hsba.org/HSBA_2020/For_the_Public/Find_a_Lawyer/HSBA_2020/Public/Find_a_Lawyer.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Hawaii">
                            Hawaii Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={55}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Idaho-flag" src={id_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.idaho.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Idaho">
                          The state of Idaho
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://isb.idaho.gov/licensing-mcle/attorney-roster-search/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Idaho">
                            Idaho Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={85}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Illinois-flag" src={il_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://www.ilsos.gov/home.html" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Illinois">
                          The state of Illinois
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.iardc.org/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Illinois">
                            Illinois Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={65}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Indiana-flag" src={in_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.in.gov/sos/business/index.htm" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Indiana">
                          The state of Indiana
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://courtapps.in.gov/rollofattorneys" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Indiana">
                            Indiana Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={30}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>15</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Iowa-flag" src={ia_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://sos.iowa.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Iowa">
                          The state of Iowa
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.iacourtcommissions.org/ords/f?p=106:1" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Iowa">
                            Iowa Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={70}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>16</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Kansas-flag" src={ks_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.ks.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Kansas">
                          The state of Kansas
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://directory-kard.kscourts.org/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Kansas">
                            Kansas Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={55}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>17</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Kentucky-flag" src={ky_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.ky.gov/Pages/default.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Kentucky">
                          The state of Kentucky
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.kybar.org/search/custom.asp?id=2947" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Kentucky">
                            Kentucky Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={45}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>18</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Louisiana-flag" src={la_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.la.gov/Pages/default.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Louisiana">
                          The state of Louisiana
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.ladb.org/Search/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Louisiana">
                            Louisiana Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={15}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>19</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Maine-flag" src={me_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.state.me.us/sos/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Maine">
                          The state of Maine
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www1.maine.gov/cgi-bin/online/maine_bar/attorney_directory.pl" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Maine">
                            Maine Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={20}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>20</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Maryland-flag" src={md_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.state.md.us" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Maryland">
                          The state of Maryland
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.courts.state.md.us/lawyers/attylist" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Maryland">
                            Maryland Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={40}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>21</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Massachusetts-flag" src={ma_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sec.state.ma.us/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Massachusetts">
                          The state of Massachusetts
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.massbbo.org/s/attorney-registration" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Massachusetts">
                            Massachusetts Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={80}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>22</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Michigan-flag" src={mi_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.michigan.gov/lara/0,4601,7-154-35299_61343_35413---,00.html" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Michigan">
                          The state of Michigan
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.michbar.org/memberdirectory/home" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Michigan">
                            Michigan Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={25}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>23</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Minnesota-flag" src={mn_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.state.mn.us" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Minnesota">
                          The state of Minnesota
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.lro.mn.gov/for-the-public/lawyer-registration-database-search-public/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Minnesota">
                            Minnesota Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={60}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>24</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Mississippi-flag" src={ms_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.ms.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Mississippi">
                          The state of Mississippi
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.msbar.org/lawyer-directory.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Mississippi">
                            Mississippi Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={45}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>25</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Missouri-flag" src={mo_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.mo.gov" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Missouri">
                          The state of Missouri
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://mobar.org/site/content/For-the-Public/Lawyer_Directory.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Missouri">
                            Missouri Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={15}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>26</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Montana-flag" src={mt_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.mt.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Montana">
                          The state of Montana
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.montanabar.org/search/custom.asp?id=2249" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Montana">
                            Montana Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={85}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>27</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Nebraska-flag" src={ne_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.ne.gov/dyindex.html" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Nebraska">
                          The state of Nebraska
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://mcle.wcc.ne.gov/ext/SearchLawyer.do" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Nebraska">
                            Nebraska Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={90}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>28</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Nevada-flag" src={nv_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.nvsos.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Nevada">
                          The state of Nevada
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="http://www.nvbar.org/find-a-lawyer/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Nevada">
                            Nevada Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={15}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>29</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="New Hampshire-flag" src={nh_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://sos.nh.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of New Hampshire">
                          The state of New Hampshire
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.nhbar.org/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of New Hampshire">
                            New Hampshire Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={55}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>30</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="New Jersey-flag" src={nj_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.nj.gov/treasury/revenue/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of New Jersey">
                          The state of New Jersey
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://portalattysearch-cloud.njcourts.gov/prweb/PRServletPublicAuth/-amRUHgepTwWWiiBQpI9_yQNuum4oN16*/!STANDARD?AppName=AttorneySearch" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of New Jersey">
                            New Jersey Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={85}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>31</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="New Mexico-flag" src={nm_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.state.nm.us/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of New Mexico">
                          The state of New Mexico
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.sbnm.org/For-Public/I-Need-a-Lawyer" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of New Mexico">
                            New Mexico Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={65}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>32</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="New York-flag" src={ny_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.dos.ny.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of New York">
                          The state of New York
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="http://iapps.courts.state.ny.us/attorney/captcha" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of New York">
                            New York Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={30}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>33</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="North Carolina-flag" src={nc_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://www.sosnc.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of North Carolina">
                          The state of North Carolina
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://portal.ncbar.gov/verification/search.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of North Carolina">
                            North Carolina Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={70}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>34</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="North Dakota-flag" src={nd_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.nd.gov/sos/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of North Dakota">
                          The state of North Dakota
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.ndcourts.gov/lawyers" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of North Dakota">
                            North Dakota" Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={55}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>35</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Ohio-flag" src={oh_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.state.oh.us" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Ohio">
                          The state of Ohio
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.supremecourt.ohio.gov/AttorneySearch/#/search" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Ohio">
                            Ohio Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={45}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>36</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Oklahoma-flag" src={ok_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://www.sos.ok.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Oklahoma">
                          The state of Oklahoma
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://ams.okbar.org/eweb/startpage.aspx?site=FALWEB" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Oklahoma">
                            Oklahoma Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={15}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>37</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Oregon-flag" src={or_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://sos.oregon.gov/Pages/default.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Oregon">
                          The state of Oregon
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="http://www.osbar.org/members/membersearch_start.asp" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Oregon">
                            Oregon Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={20}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>38</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Pennsylvania-flag" src={pa_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://www.dos.pa.gov/Pages/default.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Pennsylvania">
                          The state of Pennsylvania
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.padisciplinaryboard.org/for-the-public/find-attorney" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Pennsylvania">
                            Pennsylvania" Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={40}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>39</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Rhode Island-flag" src={ri_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://www.sos.ri.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Rhode Island">
                          The state of Rhode Island
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.ribar.com/Members/Directory/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Rhode Island">
                            Rhode Island" Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={80}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>40</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="South Carolina-flag" src={sc_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.scsos.com" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of South Carolina">
                          The state of South Carolina
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.sccourts.org/attorneys/dspSearchAttorneys.cfm" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of South Carolina">
                            South Carolina Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={25}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>41</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="South Dakota-flag" src={sd_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://sdsos.gov/default.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of South Dakota">
                          The state of South Dakota
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="http://statebarofsouthdakota.com/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of South Dakota">
                            South Dakota" Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={60}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>42</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Tennessee-flag" src={tn_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.state.tn.us/sos/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Tennessee">
                          The state of Tennessee
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.tbpr.org/for-the-public/online-attorney-directory" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Tennessee">
                            Tennessee Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={45}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>43</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Texas-flag" src={tx_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.sos.state.tx.us" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Texas">
                          The state of Texas
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.texasbar.com/AM/Template.cfm?Section=Find_A_Lawyer&amp;Template=/CustomSource/MemberDirectory/Search_Form_Client_Main.cfm" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Texas">
                            Texas Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={15}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>44</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Utah-flag" src={ut_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://www.utah.gov/business/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Utah">
                          The state of Utah
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://services.utahbar.org/Member-Directory" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Utah">
                            Utah Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={85}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>45</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Vermont-flag" src={vt_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://sos.vermont.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Vermont">
                          The state of Vermont
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.vermontjudiciary.org/attorneys/attorney-licensing" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Vermont">
                            Vermont Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={90}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>46</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Virginia-flag" src={va_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.scc.virginia.gov/index.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Virginia">
                          The state of Virginia
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="http://member.vsb.org/attsearch/search.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Virginia">
                            Virginia Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={15}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>47</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Washington-flag" src={wa_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://www.sos.wa.gov/" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Washington">
                          The state of Washington
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.mywsba.org/PersonifyEbusiness/Default.aspx?TabID=1536" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Washington">
                            Washington Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={55}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>48</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="West Virginia-flag" src={wv_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://sos.wv.gov/Pages/default.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of West Virginia">
                          The state of West Virginia
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.mywvbar.org/membership-search-members" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of West Virginia">
                            West Virginia Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={85}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>49</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Wisconsin-flag" src={wi_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://www.wdfi.org" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Wisconsin">
                          The state of Wisconsin
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.wisbar.org/Pages/BasicLawyerSearch.aspx" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Wisconsin">
                            Wisconsin Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={65}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>50</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon-sm">
                          <img alt="Wyoming-flag" src={wy_flag} />
                        </div>
                      </div>
                      <div>
                        <a
                          href="http://soswy.state.wy.us/Business/Business.aspx" rel="noopener noreferrer" target="_blank"

                          className="font-weight-bold text-black"
                          title="The state of Wyoming">
                          The state of Wyoming
                        </a>
                        <span className="text-black-50 d-block">
                          <a
                            href="https://www.wyomingbar.org/for-the-public/hire-a-lawyer/membership-directory/" rel="noopener noreferrer" target="_blank"

                            className="text-first ml-3"
                            title="The state Bar of Wyoming">
                            Wyoming Bar Association
                          </a>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="badge badge-neutral-primary text-primary px-4">
                      In progress
                    </div>
                  </td>
                  <td>
                    <LinearProgress
                      variant="determinate"
                      value={30}
                      className="progress-bar-rounded progress-bar-primary"
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      size="small"
                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </td>
                </tr>



              </tbody>
            </Table>
          </div>
        </CardContent>

      </Card>
    </>
  );
}
