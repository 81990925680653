import React from 'react';

import { PageTitle } from 'layout-components';


import DashboardCommerce2 from '../../example-components/Tokens/DashboardCommerce2';
import DashboardCommerce5 from '../../example-components/Tokens/DashboardCommerce5';
import DashboardCommerce6 from '../../example-components/Tokens/DashboardCommerce6';
import DashboardCommerce21 from '../../example-components/Tokens/DashboardCommerce21';
import DashboardCommerce22 from '../../example-components/Tokens/DashboardCommerce22';

export default function Tokens() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills Achievements Dashboard"
        titleDescription="This dashboard provides an overview of the progress and status of Web3Skills courses."
      />
      <DashboardCommerce21 />
      <DashboardCommerce5 />
      <DashboardCommerce2 />
      <DashboardCommerce6 />
      <DashboardCommerce22 />
    </>
  );
}
