import React from 'react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container } from '@material-ui/core';
// import ReactPlayer from 'react-player';

// import { NavLink } from 'react-router-dom';

// import particles1 from '../../../assets/images/hero-bg/particles-1.svg';
// import hero1 from '../../../assets/images/hero-bg/hero-9.jpg';
import hero1 from '../../../assets/images/hero-bg/hero-10.png';

import CameraTwoToneIcon from '@material-ui/icons/CameraTwoTone';
import LocalPlayTwoToneIcon from '@material-ui/icons/LocalPlayTwoTone';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import NewReleasesTwoToneIcon from '@material-ui/icons/NewReleasesTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUser';
import PolicyTwoToneIcon from '@material-ui/icons/PolicyTwoTone';

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';




// import OverviewHeader from './OverviewHeader.js';

// import illustration1 from '../../../assets/images/illustrations/0-web3skills-hub-relocator-directory.png';
// import illustration2 from '../../../assets/images/illustrations/0-web3skills-hub-relocator-directory-overlay-blue.png';
// import illustration3 from '../../../assets/images/illustrations/0-web3skills-hub-relocator-directory-overlay-green.png';

// import sidebarmenu from '../../../assets/images/supersite/sidebar-menu-600w.png';

// import logo1 from '../../../assets/images/logos/metaverse-pro-logo-orig-01-wh.png';
// import logo1 from '../../../assets/images/logos/smartlaw-automation-w-1200.png';
// import logo1 from '../../../assets/images/logos/web3skills-automation-w-1200.png';
// import logo2 from '../../../assets/images/logos/smartlaw-web3skills-white1-1200w.png';

export default function LivePreviewExample() {
  return (
    <>


<div className="hero-wrapper bg-composed-wrapper bg-second">
<Container>
<Container className="z-over mb-3 text-white text-center pt-5">
<Grid item md={11} lg={10} xl={12} className="mx-auto py-1 py-lg-1">
<h4 className="display-4 text-info font-weight-bold">
Create & Publish CLEs
</h4>
<Grid item md={12} align-items-center>
<h2 className="display-2 text-white font-weight-bold mt-1">
The process to create and publish CLE course content
</h2>
<p className="font-size-xl py-1 text-white-50">
What is the process to create and publish CLE content for the California State Bar association? Is the process to create and publish CLE course content for the ABA any different from the California State Bar rules?
</p>
</Grid>
</Grid>
</Container>
</Container>
<div className="hero-wrapper--content">
<div
className="bg-composed-wrapper--image opacity-9"
style={{ backgroundImage: 'url(' + hero1 + ')' }}
/>
<div className="bg-composed-wrapper--bg bg-deep-sky opacity-1" />
<div className="bg-composed-wrapper--bg bg-sunrise-purple opacity-1" />
{/* <div
className="bg-composed-wrapper--image opacity-9"
style={{ backgroundImage: 'url(' + particles1 + ')' }}
/> */}
<div className="bg-composed-wrapper--content">
<Container className="z-over shadow-container-content-5 text-white text-center pt-2">
<Grid item lg={12} className="mx-auto">
<div className="p-4 p-xl-5 hover-scale-rounded bg-mean-fruit rounded-lg modal-content">
<Grid container spacing={0}>
<Grid item xs={12} md={3} className="p-3">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-center">
<div>
<FingerprintIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<b className="font-size-xxl pb-2 text-white">
Big Title 14
</b>
<span className="text-white-50 font-size-lg d-block">
Text1
</span>
<p className="font-size-md py-1 text-white-50 mt-2">
The process to create and publish CLE content for the California State Bar Association can vary based on the specific provider or organization seeking approval for their CLE program. However, the California State Bar Association has established guidelines and requirements that must be followed to obtain approval for CLE courses.
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={3} className="p-3">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-center">
<div>
<AccountBalanceIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<b className="font-size-xxl text-white pb-2">
Big Title 15
</b>
<span className="text-white-50 font-size-lg d-block">
Text3
</span>
<p className="font-size-md py-1 text-white-50 mt-2">
According to the California State Bar Association, to create and publish CLE content, providers must submit an application for approval at least 60 days before the proposed course date. The application must include information on the course title, content, and objectives, as well as the qualifications of the presenter(s) and the anticipated number of credit hours.
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={3} className="p-3">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-center">
<div>
<CameraTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<b className="font-size-xxl pb-2 text-white">
Big Title 16
</b>
<span className="text-white-50 font-size-lg d-block">
Text5
</span>
<p className="font-size-md py-1 text-white-50 mt-2">
Once the application is submitted, it will be reviewed by the State Bar's MCLE staff, who will determine whether the program meets the requirements for approval. The provider may be asked to make revisions to the program before it is approved.
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={3} className="p-3">
<div className="text-center">
<div>
<LocalPlayTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<b className="font-size-xxl text-white pb-2">NFTs</b>
<span className="text-white-50 font-size-lg d-block">
Big Title 17
</span>
<p className="font-size-md py-1 text-white-50 mt-2">
The process to create and publish CLE course content for the American Bar Association (ABA) may be different from the California State Bar rules, as the ABA has its own guidelines and requirements for CLE programs. Providers seeking ABA accreditation must meet specific criteria, including compliance with the ABA's Standards for Approval of CLE Sponsors and the ABA's Model Rules for Continuing Legal Education.
</p>
</div>
</div>
</Grid>
</Grid>
</div>
<p className="font-size-xl py-1 text-white-50 mt-5">
However, some aspects of the approval process may be similar, such as the requirement to provide information on the course content, objectives, and presenter qualifications. It is important for providers to review and follow the specific requirements and guidelines established by each organization when seeking approval for CLE courses.
</p>
</Grid>
</Container>
<Container className="z-over mb-3 text-white text-center pt-5">
<Grid
item
md={11}
lg={10}
xl={12}
className="mx-auto py-1 py-lg-1">
<h4 className="display-4 text-info font-weight-bold">
MCLE Single Activity Provider
</h4>
<Grid item md={12} align-items-center>
<h3 className="display-3 text-white font-weight-bold mt-1">
MCLE Single Activity Provider
</h3>
<p className="font-size-xl py-1 text-white-50">
http://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Single-Activity-Provider-Status
</p>
<p className="font-size-xl py-1 text-white-50">
Qualifying Activities & Credit Descriptions – http://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Qualifying-Activities
</p>
<p className="font-size-xl py-1 text-white-50">
Administration Requirements & Recordkeeping – http://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Provider-Records
</p>
</Grid>
</Grid>
<Grid item lg={12} className="mx-auto">
<div className="p-2 p-xl-5 hover-scale-rounded bg-mean-fruit rounded-lg modal-content">
<Grid container spacing={0}>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-left">
<div>
<FingerprintIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<b className="font-size-xxl pb-2 text-white">
MCLE Provider Application
</b>
<p className="font-size-xs p-1 text-white-50 mt-2">
LSCLE Single Activity Provider - http://www.calbar.ca.gov/Attorneys/Legal-Specialization/Education-Providers
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Check, made payable to the “State Bar of California” or Credit Card Authorization Form enclosed.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Copy of program schedule/agenda, including list of topics with descriptions, for the activity
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Copy of speaker biographies for the activity
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Copy of written materials (substantive materials are required for all activities that are one hour or more in length)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
If seeking retroactive approval for an activity already presented, copies of the promotional materials used for that activity
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Claimed MCLE activity credit hours correspond to times on agenda.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Application is dated and has an original signature (section below)
</p>
<p className="font-size-md p-1 text-white-50 mt-2">
MCLE Single Activity Provider – http://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Single-Activity-Provider-Status
</p>
<b className="font-size-xxl pb-2 text-white">
Provider Contact Information
</b>
<p className="font-size-xs p-1 text-white-50 mt-2">
FOR STATE BAR OF CALIFORNIA USE ONLY
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
DATE STAMP HERE
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
PROVIDER CONTACT INFORMATION
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
State: Zip:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Provider Name:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Provider Address:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
City:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Contact Name:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Contact E-mail:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Provider Phone: Provider Fax:
</p>
<b className="font-size-xxl pb-2 text-white">
Submission Checklist
</b>
<p className="font-size-xs p-1 text-white-50 mt-2">
Submission Checklist:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Please indicate, by placing a checkmark in the box next to the item, that the following are included in this application or have been verified:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
THE STATE BAR OF CALIFORNIA
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
MCLE Providers
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
180 Howard St  San Francisco, CA 94105-1617
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
1-888-800-3400  providers@calbar.ca.gov
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Single Activity Provider Approval Application for Minimum Continuing Legal Education and Legal Specialization Continuing Legal Education Application
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
APPLICATION TYPE / FEES
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
New Single Activity Fee - $90 (Single Activity without added Specialty Area, as list below):
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Legal Specialization Add On Fee - $22 for each Specialty Area: *(please choose from below list)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Legal Specialization Credit (Add On) Specialty Area*
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Admiralty & Maritime Law Estate Planning, Trust & Probate Law Legal Malpractice Law
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Appellate Law Family Law Taxation Law
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Bankruptcy Law Franchise & Distribution Law Workers’ Compensation Law
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Criminal Law Immigration & Nationality Law
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
1. You MUST submit this form if your activity is held inside California, transmitted to California by any means, offered for downloading or viewing on the Internet, or offered in any other format, whether for sale or for free, within California.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
2. You do NOT need to submit this form if your activity is held outside of California AND California attorneys are outside of California while participating AND your activity has received MCLE approval (including any subfield credit) by an “Approved Jurisdiction” AND the activity meets our MCLE standards (see MCLE Rule 3.601). California attorneys may be issued an Approved Jurisdiction’s credit on a “Uniform Certificate of Attendance for MCLE” form. A list of Approved Jurisdictions may be found at http://www.calbar.ca.gov/Attorneys/MCLE-CLE/Requirements/Approved-Jurisdictions.
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-lg d-none d-lg-block" />
<div className="text-left">
<div>
<LocalPlayTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<b className="font-size-xxl text-white pb-2">
Types of MCLE Credit:
</b>
<p className="font-size-sm p-1 text-white-50 mt-2">
<span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
General Credit:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
To issue general credit, the content of the program must be legal education intended for attorneys and the practice of law and provide appropriate legal content or practical legal skills.
</p>
<p className="font-size-sm p-1 text-white-50 mt-2">
<span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
Legal Ethics Credit:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Legal ethics must focus on the professional responsibility of attorneys and not on the ethics of business, corporate or government affairs or society in general. The content of the program must be legal ethics for attorneys based upon either the California Rules of
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Professional Conduct or the ABA Model Rules. Legal ethic rule(s) must be referenced in the content of the program. For example, activities that educate attorneys on the California Rules of Professional Conduct are eligible for ethics credit, but activities that focus on ethical dilemmas encountered in society, a business or a non-legal profession do not qualify for MCLE legal ethics credit.
</p>
<p className="font-size-sm p-1 text-white-50 mt-2">
<span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
Recognition and Elimination of Bias Credit:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activities that now qualify for credit include education in the recognition and elimination of bias in the courtroom, law office, attorney-client relationships and relationships with other attorneys, legal and non-legal employment, the workplace, the hiring, management and termination of employees, housing, accommodations or the sale of goods and services. Courses required by
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
AB1825 (mandatory sexual harassment awareness and prevention training for personnel managers) may be approved for credit regarding the recognition and elimination of bias in society.
</p>
<p className="font-size-sm p-1 text-white-50 mt-2">
<span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
Competence Issues Credit:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Approved activities must consist of education that identifies and discusses the detection of substance abuse, mental illness or other mental or physical issue that impairs a member’s ability to perform legal services with competence. Courses designed and offered that discuss or provide treatment for any of the mental, emotional, or physical issues that affect professional competence, or offer methods of stress relief do not qualify for credit in this subfield (e.g. alcohol and drug treatment, meditation or other forms of stress management.)
</p>
<p className="font-size-sm p-1 text-white-50 mt-2">
<span className="sidebar-icon text-white"><ChevronRightTwoToneIcon /></span>
Legal Specialist Credit (add-on):
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activities that also focus on certain areas of law may be eligible for LSCLE in addition to MCLE credit if they provide high quality content in any one of these eleven areas of law: Admiralty & Maritime; Appellate; Bankruptcy; Criminal; Estate Planning, Trust & Probate; Family; Franchise & Distribution; Immigration & Nationality; Legal Malpractice; Taxation; and Workers’ Compensation. The State Bar certifies attorneys as specialists in these areas of law. To maintain their certification, certified specialist attorneys take and report 36 hours of courses approved for LSCLE credits during each compliance period.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
A provider can apply for this add-on credit in the same application along with the request for MCLE credit using the same support materials requested above ($90 for MCLE credit application plus $22 per LSCLE specialty area).
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Single Activity Providers seeking to retroactively add LSCLE credit to a program previously approved for MCLE credit may do so, but will need to submit a full review fee of $90 and resubmit the support materials and promotional materials described above ($90 for the first specialty area added, and $22 for each specialty area thereafter).
</p>
<b className="font-size-xxl text-white pb-2">
Credit Card Authorization
</b>
<p className="font-size-md p-1 text-white-50 mt-2">
https://www.calbar.ca.gov/Portals/0/documents/forms/MCLE-Provider-Credit-Card-Authorization-Form.pdf
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(This form may not be used to pay dues or other attorney fees; it only may be used to pay fees related to MCLE Provider Certification.)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Credit Card Number:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Credit Card Security Code: Expiration Date:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Master Card VisaCheck Credit Card Type:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Address:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Foreign Address (if applicable):
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
City, State, Zip:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Signature of Card Holder:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Name of Provider:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(Please Print)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
E-Mail:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Telephone #:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Please submit to: The State Bar of California
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
MCLE Provider Certification
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
180 Howard Street
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
San Francisco, CA 94105-1617
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
mcleproviderccauth.0419
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
American Express
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
OFFICE OF ATTORNEY REGULATION & CONSUMER RESOURCES
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
888-800-3400
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
AttorneyRegulation@calbar.ca.gov180 Howard Street, San Francisco, CA 94105
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-left">
<div>
<CameraTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<b className="font-size-xxl pb-2 text-white">
ACTIVITY INFORMATION
</b>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activity Name:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activity Date(s): Activity Location (city, state):
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
If retroactive approval is sought, total number of attendees , including lawyers attending
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activity Format (check one or both): Participatory
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Delivery Method (if participatory): In person
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Self-Study
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Online Conference Call Interactive CD/Video
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Other (indicate method)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Total minutes of instruction (minus breaks and meals) = , then divide by 60 and round to nearest quarter hour =
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
hours including any of the following subfield credits, if offered:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
hours of Legal Ethics;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
hours of Recognition and Elimination of Bias;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
hours of Competence Issues.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Single Activity Appication Fee: $90 + Legal Specialization Add On Fee:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
TOTAL APPLICATION AMOUNT DUE: (please submit total amount due with application)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Provider Name:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Provide a short summary of the content of the activity and how it relates to MCLE Provider Rule 3.601:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(If you need more space than the three lines below, please attach a separate piece of paper.)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Provide a short summary to support any credit hours claimed for Legal Ethics or Recognition and Elimination of Bias; if seeking Legal Ethics credit, please cite specific ethics rule(s) being referenced:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Records: Using the State Bar's sample Record of Attendance, Certificate of Attendance, and Evaluation Form
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Independent Record of Attendance, Certificate of Attendance, and Evaluation Form
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(please attach a sample)
</p>
<b className="font-size-xxl pb-2 text-white">
Final Submission Checklist
</b>
<p className="font-size-xs p-1 text-white-50 mt-2">
Please indicate, by placing a check mark in the box next to the item, that the following are included in this application or have been verified:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Check, made payable to the “State Bar of California”
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Copy of schedule/agenda, including list of topics with descriptions, for the activity
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Copy of speaker biographies for the activity
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Include a copy of written materials (substantive materials are required for all activities that are one hour or more in length)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
If seeking retroactive approval for an activity already presented, please include copies of the promotional materials used for that activity
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Claimed MCLE Activity credit hours correspond to times on agenda
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Application is dated and has an original signature (section below)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
If you are seeking Legal Specialization Education please attach the specialty activity content attachment. This attachment is available at http://www.calbar.ca.gov/About-Us/Forms in the Legal Specialist Education Providers section.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Provider acknowledges that its approved activity status may be revoked for non-compliance with Title 3, Division 5 of the Rules of the State Bar of California (MCLE Provider Rules) and amendments thereto, or for failure to comply with the agreements and certifications contained in this form. Provider agrees to comply with all other rules applicable to providers of Continuing Legal Education that are promulgated by the State Bar of California.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Completion of this form does not constitute MCLE approval for your educational activity. If granted, approval will become effective on the date set forth in the notification of approval. Please allow up to 6 weeks for your application to be reviewed and a determination made as to whether MCLE credit will be allowed.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
I declare, under penalty of perjury, under the laws of the State of California, that the foregoing and any attachments
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
to it are true and correct.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Signature: Date:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Print Name: Title:
</p>
</div>
</div>
</Grid>
</Grid>
</div>
</Grid>
</Container>
<Container className="z-over mb-3 text-white text-center pt-5">
<Grid
item
md={11}
lg={10}
xl={12}
className="mx-auto py-1 py-lg-1">
<h4 className="display-4 text-info font-weight-bold">
Provider Activities
</h4>
<Grid item md={12} align-items-center>
<h3 className="display-3 text-white font-weight-bold mt-1">
Provider Activities
</h3>
<p className="font-size-md p-0 text-white mt-0">
https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Qualifying-Activities
</p>
<p className="font-size-md p-0 text-white mt-0">
https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Qualifying-Activities
</p>
<h5 className="font-size-lg pb-2 text-white">
Qualifying MCLE Activities for Providers
</h5>
<p className="font-size-lg p-1 text-white-50 mt-2">
Before allowing providers to issue Minimum Continuing Legal Education (MCLE) credit, the State Bar evaluates the provider’s plans to present to practicing attorneys.
</p>
<p className="font-size-lg p-1 text-white-50 mt-2">
To qualify as legal education, a program must pertain to rules, regulations, standards, and/or policies and be directly relevant to active attorneys licensed to practice law by the State Bar of California. A significant portion of the program's content must enhance the professional ability to practice law and be directly relevant to attorneys’ current knowledge of the law, including their obligations and professional standards.
</p>
<p className="font-size-lg p-1 text-white-50 mt-2">
For example, programs that help an attorney prepare for public speaking may not count because the central theme is something other than the practice of law. But a program on avoiding malpractice does count for MCLE credit because it deals with the professional standards attorneys are expected to maintain.
</p>
<p className="font-size-lg p-1 text-white-50 mt-2">
Additionally, a provider may request MCLE credit for welcoming remarks, introductions of speakers, closing remarks, and question and answer periods.
</p>
<p className="font-size-lg p-1 text-white-50 mt-2">
For a program or activity that is one hour or more in length to be approved, substantive written materials relevant to the program or activity must be prepared and made available to attendees, either before or during the activity. Such materials must be relevant to the program’s subject matter and will be considered sufficiently substantive if they offer citations to or a discussion of any legal authority, published case law, statutes, law review articles, or other published research or commentary that augment the educational experience of attorneys.
</p>
</Grid>
</Grid>
<Grid item lg={12} className="mx-auto">
<div className="p-2 p-xl-5 hover-scale-rounded bg-mean-fruit rounded-lg modal-content">
<Grid container spacing={0}>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-left">
<div>
<PolicyTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<h4 className="font-size-xl pb-2 text-white">
Big Title 21
</h4>
<h5 className="font-size-lg pb-2 text-white">
Types of MCLE credit considered by the State Bar
</h5>
<h5 className="font-size-lg pb-2 text-white">
General credit
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activities that qualify include courses that are directly relevant to active attorneys licensed to practice law in California. A significant portion of the program's content must enhance the attorney's professional ability to practice law and be directly relevant to the attorney's current knowledge of the law, including their obligations and professional standards.
</p>
<h5 className="font-size-lg pb-2 text-white">
Other activities that may be eligible for general MCLE credit include those that provide education or practical instruction in:
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
The practice of law
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Litigation or the management of a solo law practice
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Management of a law firm or corporate legal department
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The ethical management of client trust accounts
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Law firm finances and attorney-client communications
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Case management
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Effective calendaring
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The avoidance of malpractice
</p>
<h5 className="font-size-lg pb-2 text-white">
Legal ethics—subfield credit
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Legal ethics must focus on the professional responsibility of attorneys and not on the ethics of business, corporate or government affairs, or society in general. For example, activities that educate attorneys on the state's Rules of Professional Conduct are eligible for MCLE legal ethics credit, but programs that focus on ethical dilemmas encountered in society, a business, or a nonlegal profession do not.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Make sure to cite the specific Rules of Professional Conduct for attorneys that the program relates to or discusses.
</p>
<h5 className="font-size-lg pb-2 text-white">
Recognition and elimination of bias—subfield credit
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activities that qualify include courses on any form of bias found in the legal profession or society in general. Activities that qualify for credit include education in the recognition and elimination of impermissible bias in the courtroom, law office, attorney-client relationships and relationships with other attorneys, legal and nonlegal employment, the workplace, hiring, managing, and terminating employees, and in housing, accommodations, and services.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Courses required by AB 1825—mandatory sexual harassment awareness and prevention training for personnel managers—are approved for elimination of bias credit.
</p>
<h5 className="font-size-lg pb-2 text-white">
Implicit bias—subfield credit
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activities that qualify include courses with a focus on implicit bias and the promotion of bias-reducing strategies to address how unintended biases regarding race, ethnicity, gender identity, sexual orientation, socioeconomic status, or other characteristics undermine confidence in the legal system.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Please see Section 6070.5 of the Business and Professions Code for additional information and detailed requirements.
</p>
<h5 className="font-size-lg pb-2 text-white">
Competence issues—subfield credit
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Approved activities must consist of education that identifies and discusses the detection of substance abuse, mental illness, or other mental or physical issue that impairs an attorney's ability to perform legal services with competence.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Courses that discuss or provide treatment for any of the mental, emotional, or physical issues that affect professional competence do not qualify for credit in this area. This includes programs primarily about alcohol and drug treatment, meditation, or other forms of stress management.
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-lg d-none d-lg-block" />
<div className="text-left">
<div>
<NewReleasesTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<h4 className="font-size-xl pb-2 text-white">
Big Title 22
</h4>
<h5 className="font-size-lg pb-2 text-white">
Legal Specialist—add-on credit
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activities that also focus on certain areas of law may be eligible for Legal Specialist Continuing Legal Education (LSCLE) in addition to MCLE credit, if they provide high-quality content in any of these 11 areas of the law:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Admiralty & Maritime
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Appellate
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Bankruptcy
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Criminal
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Estate Planning, Trust & Probate
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Family
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Franchise & Distribution
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Immigration & Nationality
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Legal Malpractice
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Taxation
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Workers' Compensation
</p>
<h5 className="font-size-lg pb-2 text-white">
Online activities
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Online activities may be eligible for either self-study or verified (participatory) credit. Providers must ensure that:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activities offer attendees a reasonable opportunity to both view and participate in the program.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Credit is limited to the actual time spent in an online legal education activity.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Attorneys can claim MCLE credit for speaking at or instructing in accordance with Rule 2.81. For example, an attorney who is an instructor of an online course may receive credit for the actual time spent speaking, multiplied by four.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Attendees are provided with written materials for all activities that are one hour or more in length.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Attendees are provided with an Evaluation Form along with information on how to return them, and the forms are retained for one year from the date of the activity.*
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Attendees who successfully complete the online activity are provided with a Certificates of Attendance form that can be printed. The certificate must include the provider's name, title of the MCLE activity, date of activity, total hours awarded, any credits awarded for legal ethics, elimination of bias, implicit bias, or competence issues as a component of the topics of the online activity, and whether the activity is participatory or self-study.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Attendees receive adequate advance written or electronic notification about accessing course materials.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Materials provided remain available and accessible online for at least 30 calendar days following the activity.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Record of Attendance is collected and retained for four years from the date of the activity.*
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Copies of all written materials, agendas, and promotional materials are retained for four years from the date of the activity. *
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
* Please note: copies of these documents may be requested by the State Bar at any time during the specified retention period.
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-left">
<div>
<VerifiedUserTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<h4 className="font-size-xl pb-2 text-white">
Big Title 23
</h4>
<h5 className="font-size-lg pb-2 text-white">
Helpful hints to get your MCLE activity qualified for MCLE credit
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Make sure that:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The activity is primarily aimed at attorneys admitted to practice law in California. Courses intended for paralegals or other professionals do not qualify.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The activity relates to legal subjects relevant to licensees of the State Bar or has significant current professional or practical content.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The presenters of the MCLE activity have significant professional or academic experience related to its content.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The promotional material state that the activity is approved for MCLE credit or that a request for approval is pending; specify the amount of time credits offered; and indicate whether any of the credit may be claimed for required MCLE in legal ethics, elimination of bias, implicit bias, or competence issues.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The written or electronic materials used are suitable and easily understood. All activities that are one hour or more must have substantive written materials available either before or during the activity.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Materials provided online remain online for at least 30 calendar days following MCLE activity.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Note: On and after January 1, 2022, providers are also required to meet, at a minimum, all of the following requirements, before they are approved to offer credit for activities addressing implicit bias (Business and Professions Code Section 6070.5 (a)):
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The MCLE provider shall make reasonable efforts to recruit and hire trainers who are representative of the diversity of persons that California's legal system serves.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The trainers shall have either academic training in implicit bias or experience educating legal professionals about implicit bias and its effects on people accessing and interacting with the legal system.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The training shall include a component regarding the impact of implicit bias, explicit bias, and systemic bias on the legal system and the effect this can have on people accessing and interacting with the legal system.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
The training shall include actionable steps licensees can take to recognize and address their own implicit biases.
</p>
<h5 className="font-size-lg pb-2 text-white">
Activities not acceptable for MCLE credit
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
A legal subject taught to nonattorneys
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Programs or portions of an activity that teach attorneys how to improve marketing, develop new clients or increase revenue
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Nonlegal functions such as business development, team building, committee meetings or any recreational activity
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Writing for, to, or on behalf of a client in the regular practice of law
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Programs that teach or enhance a person's ability to prepare and present an MCLE activity
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Review and evaluation of the legal work of associates or other attorneys by more experienced attorneys (for example, performance evaluations or a mentoring program)
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Programs that discuss pending court case or litigation, an appeal, or any client matter by the participants in the pending case, litigation appeal, or client matter
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Activities primarily intended to market or sell the presenter's technology, products, services, or skills
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Time spent in conducting a roll call or connecting to the teleconference
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Online discussions or written comments posted on public forums
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Courses on general business writing or basic English grammar
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Informal discussion groups
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Preparation for admission to practice law in any state, the District of Columbia, any territory of the U.S., or any foreign jurisdiction, or for time spent actually taking such examinations
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Educational programs on mentoring new attorneys
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Registering for a program or viewing vendor exhibits and demonstrations
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Breaks or rest periods lasting more than five minutes, mealtimes without a scheduled speaker, receptions, evaluations, tours, certificates and awards, or vendor demonstrations
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Networking, the use of social media to network or for marketing
</p>
</div>
</div>
</Grid>
</Grid>
</div>
</Grid>
</Container>
<Container className="z-over mb-3 text-white text-center pt-5">
<Grid
item
md={11}
lg={10}
xl={12}
className="mx-auto py-1 py-lg-1">
<h4 className="display-4 text-info font-weight-bold">
TITLE 3. PROGRAMS AND SERVICES
</h4>
<Grid item md={12} align-items-center>
<h3 className="display-3 text-white font-weight-bold mt-1">
DIVISION 5. PROVIDERS OF PROGRAMS AND SERVICES
</h3>
<p className="font-size-xl py-1 text-white-50">
Qualifying MCLE Activities for Providers
</p>
</Grid>
</Grid>
<Grid item lg={12} className="mx-auto">
<div className="p-2 p-xl-5 hover-scale-rounded bg-mean-fruit rounded-lg modal-content">
<Grid container spacing={0}>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-left">
<div>
<PolicyTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<h4 className="font-size-xl pb-2 text-white">
Big Title 219
</h4>
<h5 className="font-size-lg pb-2 text-white">
Qualifying MCLE Activities for Providers
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Qualifying-Activities
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
https://www.calbar.ca.gov/Portals/0/documents/rules/Rules_Title3_Div5-Ch1-CLE-Providers.pdf
</p>
<h5 className="font-size-lg pb-2 text-white">
TITLE 3. PROGRAMS AND SERVICES
</h5>
<p className="font-size-sm font-weight-bold p-1 text-white-50 mt-2">
Adopted July 2007
</p>
<p className="font-size-sm font-weight-bold p-1 text-white-50 mt-2">
DIVISION 5. PROVIDERS OF PROGRAMS AND SERVICES
</p>
<p className="font-size-sm font-weight-bold p-1 text-white-50 mt-2">
Chapter 1. Providers of Continuing Legal Education
</p>
<h5 className="font-size-xl pb-2 text-white">
Article 1. Global provisions
</h5>
<h5 className="font-size-lg pb-2 text-white">
Rule 3.600 Definitions
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
(A) An “MCLE activity” is minimum continuing legal education that the State Bar approves as meeting standards for MCLE credit.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(B) A “provider” is an individual or entity approved by the State Bar to grant MCLE credit for an MCLE activity.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(C) A “Single Activity Provider” is approved to grant credit for a single MCLE activity after submitting an application for approval of the activity in accordance with State Bar procedures and paying the appropriate processing fee.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(D) A “Multiple Activity Provider” is approved to grant credit for any MCLE activity that complies with the terms of the Multiple Activity Provider Agreement.1
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(E) “MCLE credit” is the number of credit hours that a licensee may claim to meet the requirements of these rules.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(F) A “credit hour” is sixty minutes actually spent in an MCLE activity, less any time for breaks or other activities that lack educational content. A credit hour is reported to the nearest quarter hour in decimals. MCLE credit includes time for introductory and concluding remarks and for questions and answers.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(G) An “approved jurisdiction” is recognized by the State Bar as having MCLE requirements that substantially meet State Bar standards for MCLE activities and computing MCLE credit hours in a manner acceptable to the State Bar. Approved jurisdictions are listed on the State Bar Web site.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(H) A “participatory activity” is an MCLE activity for which the provider must verify attendance. Participatory activities may be presented in person or delivered by electronic means. Methods for verification include sign-in in writing or electronically at the state of the course.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
1 Business & Professions Code § 6070 (b) provides that programs offered by the California District Attorneys Association and the California Public Defenders Association are deemed to be approved MCLE.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(I) A “self‐study activity” is any MCLE activity identified in Rule 2.83. Self‐study activities may be presented in person or delivered by electronic means.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(J) “State Bar MCLE Activity Auditors” are individuals designated by the State Bar to conduct audits of MCLE activities on behalf of the State Bar for the purpose of evaluating compliance by providers with these rules.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Rule 3.600 adopted as Rule 3.500 effective January 1, 2008; renumbered as Rule 3.600 effective November 4, 2011; amended effective July 1, 2014; amended effective January 25, 2019; amended effective December 1, 2020.
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-lg d-none d-lg-block" />
<div className="text-left">
<div>
<NewReleasesTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<h4 className="font-size-xl pb-2 text-white">
Big Title 22
</h4>
<h5 className="font-size-lg pb-2 text-white">
Rule 3.601 MCLE Activities
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
To be approved for MCLE credit, an MCLE activity must meet State Bar standards.2
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(A) The MCLE activity must relate to legal subjects directly relevant to licensees of the State Bar or have significant current professional and practical content.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(B) The presenter of the MCLE activity must have significant professional or academic experience related to its content.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(C) Promotional material must state that the MCLE activity is approved for MCLE credit or that a request for approval is pending; specify the amount of credit offered; and indicate whether any of the credit may be claimed for required MCLE in legal ethics, elimination of bias, or competence issues.3
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(D) If the activity lasts one hour or more, the provider must make substantive written materials relevant to the MCLE activity available either before or during the activity. Any materials provided online must remain online for at least thirty calendar days following the MCLE activity.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(E) Programs and classes must be scheduled so that participants are free of interruptions.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
2 Business & Professions Code § 6070(b) provides that programs offered by the California District Attorneys Association and the California Public Defenders Association are deemed to be approved MCLE. State Bar Rule 2.84 provides that “A licensee may claim MCLE credit for educational activities that the California Board of Legal Specialization approves for certification or recertification.”
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
3 See State Bar Rule 2.72 for a description of competence issues and elimination of bias.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(F) On and after January 1, 2022, for all training dealing with, or including a component dealing with, implicit bias and the promotion of bias-reducing strategies, an MCLE provider must meet the requirements of Business and Professions Code section 6070.5. Rule 3.601 adopted as Rule 3.501 effective January 1, 2008; renumbered as Rule 3.601 effective November 4, 2011; amended effective January 1, 2013; amended effective July 1, 2014; amended effective January 25, 2019; amended effective September 24, 2020.
</p>
<h5 className="font-size-lg pb-2 text-white">
Rule 3.602 Responsibilities of every provider
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
Every provider must
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(A) comply with any State Bar rules and terms applicable to an approved MCLE activity;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(B) retain the Record of Attendance for an MCLE activity for four years from the date of the activity and submit it to the State Bar upon request. The record must include the title of the MCLE activity, date, total hours awarded, any credits awarded for legal ethics, elimination of bias, or competence issues as a component of the topic of the activity, and whether the activity is participatory or self-study and the name and State Bar number of each attendee;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(C) provide the State Bar of California with electronic attendance records in a form acceptable to the State Bar of California within 60 days of completion of the activity;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(D) furnish an MCLE Certificate of Attendance upon completion of the program to each attendee who has met the requirements for the MCLE activity. The certificate must include the provider name, title of the MCLE activity, date the course was completed, total hours awarded, any credits awarded for legal ethics, elimination of bias, or competence issues as a component of the topic of the activity, and whether the activity is participatory or self-study;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(E) give each attendee who completes an MCLE activity a State Bar MCLE Activity Evaluation Form or its equivalent; retain the completed form for at least one year; and submit it to the State Bar upon request;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(F) notify the State Bar in writing of any change in the name, address, or other contact information required by the State Bar; and
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(G) beginning January 1, 2022, if providing training dealing with, or including a component dealing with, implicit bias and the promotion of bias-reducing strategies to address how unintended biases regarding race, ethnicity, gender identity, sexual orientation, socioeconomic status, or other characteristics undermine confidence in the legal system, attest to the following:
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(1) The provider makes reasonable efforts to recruit and hire trainers who are representative of the diversity of persons that California’s legal system serves.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(2) The trainers have either academic training in implicit bias or experience educating legal professionals about implicit bias and its effects on people accessing and interacting with the legal system.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(3) The training includes a component regarding the impact of implicit bias, explicit bias, and systemic bias on the legal system and the effect this can have on people accessing and interacting with the legal system.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(4) The training includes actionable steps licensees can take to recognize and address their own implicit biases.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Rule 3.602 adopted as Rule 3.502 effective January 1, 2008; renumbered as Rule 3.602 effective November 4, 2011; amended effective July 1, 2014; amended effective September 24, 2020; amended effective December 1, 2020.
</p>
</div>
</div>
</Grid>
<Grid item xs={12} md={4} className="p-2">
<div className="divider-v bg-white-10 divider-v-md d-none d-lg-block" />
<div className="text-left">
<div>
<VerifiedUserTwoToneIcon className="d-30 text-white" />
</div>
<div className="mt-3 line-height-sm">
<h4 className="font-size-xl pb-2 text-white">
Big Title 23
</h4>
<h5 className="font-size-lg pb-2 text-white">
Rule 3.603 State Bar MCLE Activity Auditors
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
A State Bar MCLE Activity Auditor may be a State Bar staff member, Board of Trustees member, California Board of Legal Specialization member, or other person designated by the State Bar to conduct an audit of a particular MCLE program or class on behalf of the State Bar. A State Bar MCLE Activity Auditor may not have a business, financial or personal relationship with or oversight responsibility for the provider of the program or class being audited. A State Bar MCLE Activity Auditor may audit the particular MCLE program or class at no cost. Rule 3.603 adopted effective July 1, 2014; amended effective September 24, 2020.
</p>
<h5 className="font-size-lg pb-2 text-white">
Rule 3.604 Suspension or revocation of provider approval
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
The State Bar may revoke a provider’s approval for failure to comply with these rules or the terms of any applicable State Bar agreement only by majority vote of the Board of Trustees, after notice and hearing, and for good cause shown.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Rule 3.604 adopted as Rule 3.503 effective January 1, 2008; renumbered as Rule 3.603 effective November 4, 2011; renumbered as Rule 3.604 effective July 1, 2014; amended effective March 10, 2017; amended effective September 24, 2020.
</p>
<h5 className="font-size-lg pb-2 text-white">
Rule 3.605 Complaints about Providers
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
The State Bar does not intervene in disputes between a provider and an attendee or potential attendee, but complaints the State Bar receives regarding a provider are considered in assessing the provider’s compliance with these rules.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Rule 3.605 adopted effective July 1, 2014.
</p>
<h4 className="font-size-xl pb-2 text-white">
Article 2. Multiple Activity Providers
</h4>
<h5 className="font-size-lg pb-2 text-white">
Rule 3.620 Applying for Multiple Activity Provider status
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
To be considered for Multiple Activity Provider status, a provider must
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(A) within a two-year period receive State Bar approval for four different MCLE activities and hold them on four different dates; and
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(B) submit an application and processing fee for Multiple Activity Provider status within that same period.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Rule 3.620 adopted as Rule 3.520 effective January 1, 2008; renumbered as Rule 3.620 effective November 4, 2011.
</p>
<h5 className="font-size-lg pb-2 text-white">
Rule 3.621 Renewing Multiple Activity Provider status
</h5>
<p className="font-size-xs p-1 text-white-50 mt-2">
To be eligible for renewal of up to three years, a Multiple Activity Provider must
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(A) apply for renewal using the State Bar form for Multiple Activity Provider Renewal;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(B) submit evidence that it has offered four different MCLE activities that meet the requirements of these rules within the two years preceding its application for renewal;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(C) submit the completed form and any required documentation by the deadline set by the State Bar;
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(D) submit any complaints it may have received regarding compliance with these rules; and
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
(E) pay the appropriate fees.
</p>
<p className="font-size-xs p-1 text-white-50 mt-2">
Rule 3.621 adopted as Rule 3.521 effective January 1, 2008; renumbered as Rule 3.621 effective November 4, 2011; amended effective July 1, 2014.
</p>
</div>
</div>
</Grid>
</Grid>
</div>
</Grid>
</Container>
{/* <div className="shadow-container-blocks-5 z-below"> */}
<div className="">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
<path
fill="var(--light)"
fillOpacity="1"
d="M0,32L120,58.7C240,85,480,139,720,138.7C960,139,1200,85,1320,58.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
</svg>
</div>
</div>
</div>
</div>
</>
);
}
