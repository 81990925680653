import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';

import BlocksImages2 from '../../example-components/Credcert/BlocksImages2';
import BlocksImages3 from '../../example-components/Credcert/BlocksImages3';
import BlocksImages5 from '../../example-components/Credcert/BlocksImages5';
import BlocksImages7 from '../../example-components/Credcert/BlocksImages7';
import OverviewCurriculum from '../../example-components/Overview/OverviewCurriculum';


export default function Credcert() {
  return (
    <>
      <PageTitle
        titleHeading="Web3 Legal Engineering Certification"
        titleDescription="Legal Engineering requires proficiency in law and tech. Prerequisites include LexDAO membership, a 3L or greater legal education (or equivalent) and a basic understanding of web3 technologies found in the LexClinic House of Automation."
      />


      <ExampleWrapperSeamless>
      <OverviewCurriculum/>
      </ExampleWrapperSeamless>
      <ExampleWrapperSeamless>
        <BlocksImages5 />
      </ExampleWrapperSeamless>
      <ExampleWrapperSeamless>
        <BlocksImages2 />
      </ExampleWrapperSeamless>
      <ExampleWrapperSeamless>
        <BlocksImages3 />
      </ExampleWrapperSeamless>
      <ExampleWrapperSeamless>
        <BlocksImages7 />
      </ExampleWrapperSeamless>
    </>
  );
}
