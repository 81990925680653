import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Button } from '@material-ui/core';

export default function LivePreviewExample() {
  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item lg={4}>
            <Card className="card-box card-box-alt">
              <div className="card-content-overlay text-center pb-4">
                <div className="d-50 rounded border-0 mb-1 card-icon-wrapper bg-night-skyer text-white btn-icon mx-auto text-center shadow-warning">
                  <FontAwesomeIcon
                    icon={['fas', 'charging-station']}
                    className="display-4"
                  />
 
                </div>
                <div className="font-weight-bold text-primary display-5 mt-4 mb-1">
                All Web3SKills Courses
                </div>
                <div className="font-weight-bold font-size-lg text-primary opacity-8">
                777 MATIC
                </div>
                <div className="divider mx-4 my-4" />
                <div className="text-center">
                  <Button
                    className="p-0 text-uppercase btn-link-success font-weight-bold font-size-sm btn-link"
                    variant="text">
                    <span>View details</span>
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card className="card-box card-box-alt">
              <div className="card-content-overlay text-center pb-4">
                <div className="d-50 rounded border-0 mb-1 card-icon-wrapper bg-night-sky text-white btn-icon mx-auto text-center shadow-warning">
                  <FontAwesomeIcon
                    icon={['fas', 'landmark']}
                    className="display-4"
                  />
                </div>
                <div className="font-weight-bold text-primary display-5 mt-4 mb-1">
                Web3 Legal Engineering Token
                </div>
                <div className="font-weight-bold font-size-lg text-primary opacity-8">
                1999 MATIC
                </div>
                <div className="divider mx-4 my-4" />
                <div className="text-center">
                  <Button
                    className="p-0 text-uppercase btn-link-first font-weight-bold font-size-sm btn-link"
                    variant="text">
                    <span>View details</span>
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card className="card-box card-box-alt">
              <div className="card-content-overlay text-center pb-4">
                <div className="d-50 rounded border-0 mb-1 card-icon-wrapper bg-asteroid text-white btn-icon mx-auto text-center shadow-warning">
                  <FontAwesomeIcon
                    icon={['fas', 'fingerprint']}
                    className="display-4"
                  />
                </div>
                <div className="font-weight-bold text-primary display-5 mt-4 mb-1">
                  Profiles &#38; Achievements
                </div>
                <div className="font-weight-bold font-size-lg text-primary opacity-8">
                  7,777 MATIC
                </div>
                <div className="divider mx-4 my-4" />
                <div className="text-center">
                  <Button
                    className="p-0 text-uppercase btn-link-warning font-weight-bold font-size-sm btn-link"
                    variant="text">
                    <span>View details</span>
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
