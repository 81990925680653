import React from 'react';

import { PageTitle } from 'layout-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ExampleWrapperSeamless } from 'layout-components';

import BlocksChartsSmall2 from '../../example-components/BlocksChartsSmall/BlocksChartsSmall2';
import BlocksChartsSmall3 from '../../example-components/BlocksChartsSmall/BlocksChartsSmall3';

import Iframe from 'react-iframe';
import { Grid, Button, Tooltip, Link } from '@material-ui/core';

// https://airtable.com/appBBhQFafyI3oHIG/pagdr4kQMbBqMveLN?h2OVl=rec27PORA6CPRU9MM
// https://airtable.com/appMWDVEdxPz6SDFU/pag086Ozg9UMuqTcz?MZ9yD=recmvCyVWJTW5SjWd
// https://airtable.com/appMWDVEdxPz6SDFU/pagWgXY4NHYbQFcO0?W6bsu=recmvCyVWJTW5SjWd

export default function Signups() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills Sign-up Form"
        titleDescription="This is the starting point in applying to the automation clinic. Fill out the following form and it will be reviewed by group members."
      />

      <Grid>
        <hr />
        <Iframe
          id=""
          src="https://airtable.com/embed/shrSf9u04ONsEnufo?backgroundColor=cyan"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="4168"
          className=""
          display="block"
          position="relative"
          background="transparent"
          border="1px"
        />
      </Grid>


      <br />
      <br />
      <hr />

      <Grid>
        <Grid>
          <div className="p-2">
            <h5 className="font-size-xl mb-1 font-weight-bold">
              Profiles and Integrations
            </h5>
            <p className="text-black-50 mb-4">Links to a current databases.</p>
          </div>

          <Tooltip arrow title="Github">
            <Button
              className="m-2 d-40 p-0 btn-icon btn-github"
              href="https://airtable.com/appBBhQFafyI3oHIG/pagdr4kQMbBqMveLN?h2OVl=rec27PORA6CPRU9MM"
              rel="noopener nofollow"
              target="_blank">
              <FontAwesomeIcon
                icon={['fab', 'github']}
                className="font-size-xl"
              />
            </Button>
          </Tooltip>

          <Link
            href="https://airtable.com/appBBhQFafyI3oHIG/pagdr4kQMbBqMveLN?h2OVl=rec27PORA6CPRU9MM"
            rel="noopener nofollow"
            target="_blank">
            SmartLaw Decentralized ID
          </Link>
        </Grid>

        <hr />

        <Grid>
          <Tooltip arrow title="Github">
            <Button
              className="m-2 d-40 p-0 btn-icon btn-github"
              href="https://airtable.com/appMWDVEdxPz6SDFU/pag086Ozg9UMuqTcz?MZ9yD=recmvCyVWJTW5SjWd"
              rel="noopener nofollow"
              target="_blank">
              <FontAwesomeIcon
                icon={['fab', 'github']}
                className="font-size-xl"
              />
            </Button>
          </Tooltip>

          <Link
            href="https://airtable.com/appMWDVEdxPz6SDFU/pag086Ozg9UMuqTcz?MZ9yD=recmvCyVWJTW5SjWd"
            rel="noopener nofollow"
            target="_blank">
            Test Clinic Members Intake
          </Link>
        </Grid>

        <hr />

        <Grid>
          <Tooltip arrow title="Github">
            <Button
              className="m-2 d-40 p-0 btn-icon btn-github"
              href="https://airtable.com/appMWDVEdxPz6SDFU/pagWgXY4NHYbQFcO0?W6bsu=recmvCyVWJTW5SjWd"
              rel="noopener nofollow"
              target="_blank">
              <FontAwesomeIcon
                icon={['fab', 'github']}
                className="font-size-xl"
              />
            </Button>
          </Tooltip>

          <Link
            href="https://airtable.com/appMWDVEdxPz6SDFU/pagWgXY4NHYbQFcO0?W6bsu=recmvCyVWJTW5SjWd"
            rel="noopener nofollow"
            target="_blank">
            Clinic Participant Profile
          </Link>
        </Grid>
      </Grid>

      <br />
      <br />
      <hr />

      <Grid>
        <div className="p-2">
          <h5 className="font-size-xl mb-1 font-weight-bold">
            Current Web3Skills Achievement Tokens
          </h5>
          <p className="text-black-50 mb-4">
            Assets and tokens are queried from public blockchains.
          </p>
        </div>

        <ExampleWrapperSeamless>
          <BlocksChartsSmall2 />
        </ExampleWrapperSeamless>

        <ExampleWrapperSeamless>
          <BlocksChartsSmall3 />
        </ExampleWrapperSeamless>
      </Grid>

    </>
  );
}
