import React, { Component } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Iframe from 'react-iframe';

export default class LivePreviewExample extends Component {
  state = {
    value:
      'The sender of this message attests that the information is true and correct.',
    copied: false
  };
  render() {
    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Iframe
              id=""
              src="https://airtable.com/embed/shrfdiBLO6c72Tk1h?backgroundColor=gray"
              frameborder="0"
              onmousewheel=""
              width="100%"
              height="600"
              // height="1525"
              className="mb-1"
              display="block"
              position="relative"
              background="transparent"
              border="1px"
            />

            <div className="font-weight-bold font-size-lg mt-5 mb-3">
              Affirmation and Signature block to file a claim
            </div>
            <TextField
              label="Copy the text below and paste it in the box to the right"
              multiline
              rows="4"
              fullWidth
              onChange={({ target: { value } }) =>
                this.setState({ value, copied: false })
              }
              defaultValue={this.state.value}
              variant="outlined"
            />
            <div className="text-center mt-4">
              <CopyToClipboard
                text={this.state.value}
                onCopy={() => this.setState({ copied: true })}>
                <Button size="large" className="btn-primary bg-deep-sky">
                  Copy content from text area
                </Button>
              </CopyToClipboard>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <label className="font-weight-bold mb-2">Today's Date</label>
              </div>
              <div className="d-flex justify-content-between">
                <label className="font-weight-bold mb-2"></label>
              </div>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="timeline_formfield_059"
                type="date"
                helperText="Enter today's date here. It will start the statute of limitations for the claim."
              />
            </div>

            {this.state.copied ? (
              <Alert className="mb-4" severity="success">
                <span>
                  The input had the <b>{this.state.value}</b> content which was
                  copied successfully!
                </span>
              </Alert>
            ) : null}

            <Alert className="mb-4" severity="warning">
              Paste the text block into the box below below. It will be included
              in your on-chain transaction.
            </Alert>
            <TextField
              label="Affirmation and Signature"
              multiline
              rows="4"
              fullWidth
              defaultValue="Paste the transaction message here."
              variant="outlined"
            />

            <div className="text-center mt-4">
              <Button
                href="http://tokens.freeweb3.com/#/"
                rel="noopener noreferrer"
                target="_blank"
                size="large"
                className="btn-first bg-serious-blue text-white shadow-second-sm btn-pill">
                <span className="text-white btn-wrapper--label">
                  Submit Claim
                </span>
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
