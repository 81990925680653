import React from 'react';

import { Container, Card } from '@material-ui/core';


export default function LivePreviewExample() {
  return (
    <>



      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills SmartLaw Tokens Group 1
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 1"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/Qmc1tYpfY35R1fDvYgT3io7gQxNSPGcgSjU63mxXrTDDkk"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 2"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmNXTQmogRe1L5N5pXh9QADG2mpQWe3VcwkGXwun4XpPiq"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 3"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmPyJSMb6hNNQ5YmYHneoZeQoWBaAmXxSzuPKpE4P6w5eg"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 4"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmdFdpsazvyZSzLtfEkSGfgFf1kwxMnHn4Q7NX2z7UsXgC"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 5"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmPV9gs8WZbTTDNJdg1EGSmg3g5JQQxzXnhudDmvv1LvC6"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 6"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmNUpZqZyAbaJnT995JQuBWP2kZhv63sEyxE4Do9VqGE2r"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 7"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmSzGsQyua1P1YmBHcfYQ3ZHdjH61RFBF6t8pFjTWaTrC7"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 8"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmTAk8bUW58aEom3biAfjbGMjuHCGRjRkzxH4TnkHjHtrP"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 9"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmahLrkyvYTms3hx7U7KRAwx6LbScVxPhh6SfcXLhw2jFX"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 10"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmTqbkTwbpoPZuq1n482VrHsxMs3BoY5zrC9cq6pZE42jJ"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 11"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmcATg5FjEEXJ6CCYdZ5VPGK3TyAYJpKbv87Ki8ek6xg5k"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 12"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmYUEDFpJEYQEPD8PZBkYncY3Ub5HFmgLyrpksW68rvLtU"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>

      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills SmartLaw Tokens Group 2
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 13"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/Qmcenpa2eLvQ5qwTCJXaMyAHtxgVSRsG2hYxrgzwUDotbE"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 14"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmYwzSK4ap7FQ7owgQ28QQhruYgoFEMjDqWLvgQ3wwr5SK"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 15"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmSdfpYwx48CYTJyHpwqeGJQpGxnrME2pou1mboq1dZ9Uw"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 16"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmenawtZPhEjTEsFBDFNUPupkR4RxcqmnYCCzfWK7zr18M"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 17"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmQLWTEGhY4c3eqMXHBjxbRSd1ripuPSm3eWYQ1G4A454V"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 18"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmVJXCGiDA78FLTbPLroTq6Yyj7Pw8FnS9mbdnbfa4ZdJ6"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 19"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmSFzCREkFVz5U41WgX59X2Daoy5NGrMvrYTtkJUzm5dPt"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 20"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmeUrkMT9ALhgnfsLFF3muftdv2Ym2upzBi7QRQRA9k4v4"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 21"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmQ42JuZ3zdyYKC9PtMqK7VEGhtksDAmgikWTmcgtpCtKj"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 22"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmTygPB467njVSzUZeHN6oeMjgssBAFSwEQUstuGZ2gm3x"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 23"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmQSExn74knbsdYd4QSJTcGGKG3ptXUG59Suo3uoipwDmo"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 24"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmXkybDG4D1YAk7TjwMujB7dyMz6bBKXiRvU13Q6hp773o"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>




      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills SmartLaw Tokens Group 3
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 25"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmRekc6vyyVF7ScuaSi5EX2MyTxnQpTXPvXTpm7ruX7RJt"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 26"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmUCZc67AYMFTaAtF1WJ4kXBD9rqrN6qHHLHVBR2mBaJuS"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 27"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmSgQc1r6xxn5TpsdnY9kkogFVtSfDLpKvbZs82naPUkT5"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 28"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmauuVzxXQV71ERAtQbJKbedGeRgH3w7ySnDEXz6fzA6yr"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 29"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmTpEkyn6x6gMryGBTQgfum24RT2yuQvw5nUfTfVwXm35b"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 30"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmSoDDMjeXUkgQpdEpyZN3yc1aNQvjP9w1YcSSdFErbthz"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 31"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmQvGfbx7GyGVHghvX6TcFgjz3h2YbFHoUKZtpiQvSi8sW"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 32"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmZNqvCHPk7qyGzT35XrU7CCXBX2JN3mnfqLdx914MwMn1"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 33"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmaZHpbvFscodaKYeA4RxR83bVKhXAPDHEz7RRvACW5GyS"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 34"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmXexc6tZ9U8wvD58WTXgp34KTJNQbDnhUXU8KTynsXXXX"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 35"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmUMzYaE42W18gB6Nf6c7HAcRGpWMdNAaWCu9qf4g56eya"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 36"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmeXScrBkvW6gUuG971VcNDqoU9KzegaUoedNDSShrguVt"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>


      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
            <h2 className="font-weight-bold mb-4">
              2023 Web3Skills SmartLaw Tokens Group 4
            </h2>
            <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse   and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 37"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmRQ1udJjxzemg8ymyUCQCD2SJoSrsW2egiDipBPeXaidt"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 38"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmUL1CChqTQb3uto1r3T95c2wmDrmXvfDok4RSvmVeZgW7"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 39"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/Qmcf5jVV58FyX2jwmQuvEnSWZc8FviS9XaqhTSBY4h8xKH"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 40"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmahGbh2MQA8DcMJouRnDHNuTAdnyJjKJJLuW8mwDi27kq"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 41"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmPTRQq9cK3ysnDCAvYYeydLhhybsiGanVL3AxiAaAYZn3"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 42"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/Qma9wtgYLNXNXtSxv3JwCbirvYb21roSbLNTLqM6wMqhFY"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 43"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmSiFLza5eXCYSaFkCw8w23139TEocmUzmKe2RTkJPNRHG"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 44"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmeGvm9sZkmHDuAAkvPdgP5GfbEh3iY5Q2Z5qSnFChC8Ex"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <a
                  href="https://artifacts.freeweb3.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    alt="Web3Skills SmartLaw Achievement 45"
                    className="img-fluid rounded mr-3 shadow-sm"
                    src="https://freeweb3.infura-ipfs.io/ipfs/QmfL8oXfbm3eoHxiZY26e86AVJqJ1EjSwMdexff1opi3Ck"
                    style={{ width: `120` }}
                    width="120"
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Card>



    </>
  );
}
