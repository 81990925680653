import React from 'react';
// import hero4 from '../../../assets/images/hero-bg/hero-2.jpg';

import {
  Grid,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import Iframe from 'react-iframe';

export default function LivePreviewExample() {
  return (
    <>
      <div className="app-content p-0">
        <div className="app-inner-content-layout--main">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content">
              <Grid container spacing={0} className="min-vh-100">
                <Grid item lg={7} xl={6} className="d-flex align-items-center">
                  <Grid item md={10} lg={8} xl={7} className="mx-auto">
                    <Grid container spacing={0} className="min-vh-50">
                      <Grid item className="d-flex">
                        <div className="py-4">
                          <div className="text-center">
                            <h1 className="display-3 my-3 font-weight-bold text-primary">
                              Create a Grant Profile
                            </h1>
                            <p className="font-size-lg mb-0 px-4 text-primary-50">
                              SmartLaw provides tools for Web3 ecosystem to
                              advance legaltech . 7 .
                            </p>

                            <Iframe
                              id=""
                              src="https://airtable.com/embed/shrtuVvCBSGsL68yQ?backgroundColor=gray"
                              frameborder="0"
                              onmousewheel=""
                              width="100%"
                              height="600"
                              // height="2454"
                              className=""
                              display="block"
                              position="relative"
                              background="transparent"
                              border="1px"
                            />
                          </div>
                          <div className="text-center my-4">
                            <h1 className="display-4 mb-1 font-weight-bold">
                              Create your Grant Profile Here:
                            </h1>
                            <p className="font-size-lg mb-0 text-black-50">
                              Start benefitting from Web3 right away. Accounts
                              are free and are used to collect achievment and
                              access tokens.
                            </p>
                          </div>
                          <div className="mb-3">
                            <label className="font-weight-bold mb-2">
                              Grant Title
                            </label>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Enter a short title of the project"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="font-weight-bold mb-2">
                              Project Introduction
                            </label>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Describe the project in a few sentences"
                            />
                          </div>
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                Password (optional)
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                Save your password. It is unrecoverable if you
                                lose it. We do not save or store it.
                              </label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Enter a password if you want the data to be encrypted."
                              type="password"
                              helperText="The password is used to encrypt the data. It is not stored."
                            />
                          </div>
                          <hr />
                          <h1 className="display-4 mb-1 font-weight-bold">
                            The Report Abstract and Executive Summary
                          </h1>
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                Summary or Abstract | 028
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2"></label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Placeholder in the form field 028 | "
                              name="project_title_formfield_028"
                              type="textarea"
                              helperText="Helper note sits just below the form field 028 | "
                            />
                          </div>

                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                Project Members and Staff | 029
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2"></label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Placeholder in the form field 029 | "
                              name="memebers_staff_formfield_029"
                              type="textarea"
                              helperText="Helper note sits just below the form field 029 | Most important is to briefly overview why your values and goals align with the organization's and how you will help them. You should also touch on how you match the position requirements. By reviewing how you align with the organization and how your skills match what they're looking for, you can forecast the contents of your cover letter before you move into your argument."
                            />
                          </div>

                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                The Argument | 030
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2"></label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Placeholder in the form field 030 | "
                              name="argument_for_formfield_030"
                              type="textarea"
                              helperText="Helper note sits just below the form field 030 | This section establishes your qualifications to carry out the project being proposed. For example, how does this project fit into the context of your long-term research objectives? What relevant experience do you bring to the activities you plan to carry out with project funding? Experience can be defined broadly to include teaching, administrative, scholarly, and community activities. "
                            />
                          </div>

                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                how you will help with the Grantors mission? |
                                031
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2"></label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Placeholder in the form field 031 | "
                              name="grantors_mission_help_formfield_031"
                              type="textarea"
                              helperText="Helper note sits just below the form field 031 | Avoid explaining your entire resume but use your resume as a source of data to support your argument (the two documents should work together)."
                            />
                          </div>

                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                The Closing | 032
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2"></label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Placeholder in the form field 032 | "
                              name="restate_proposal_formfield_032"
                              type="textarea"
                              helperText="Helper note sits just below the form field 032 | Restate why you align with the organization's mission/goals."
                            />
                          </div>

                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                signature | 033
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2"></label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Placeholder in the form field 033 | "
                              name="signanture_formfield_033"
                              type="textarea"
                              helperText="Helper note sits just below the form field 033 | Sign your name in ink between your salutation and typed name"
                            />
                          </div>

                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                signature_block | 034
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2"></label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Placeholder in the form field 034 | "
                              name="signature_block_formfield_034"
                              type="textarea"
                              helperText="Helper note sits just below the form field 034 | Signature block for authorized administrative official or PII"
                            />
                          </div>

                          <Grid container spacing={6}>
                            <Grid item md={6}>
                              <div>
                                <label className="font-weight-bold mb-2">
                                  Web3 Account
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="It should be an alpha numeric string starting with 0x."
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div>
                                <label className="font-weight-bold mb-2">
                                  Chaind Id
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Blockchain or node system the Web3 Account is used with."
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div>
                                <label className="font-weight-bold mb-2">
                                  Time to Complete Milestones
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Enter the required time days"
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div>
                                <label className="font-weight-bold mb-2">
                                  Budget to Complete Milestones
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Enter the required time in days"
                                />
                              </div>
                            </Grid>
                            <Grid item sm={12} lg={6}>
                              <div className="mb-3">
                                <label className="font-weight-bold mb-2">
                                  Sponsor
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Name of the sponsor championing the proposal."
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className="mb-3">
                                <label className="font-weight-bold mb-2">
                                  Sponsor Matching Funds
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Enter the amount of money the Sponsor or Grantee will bond."
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className="mb-3">
                                <label className="font-weight-bold mb-2">
                                  Content Website
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Enter the project website."
                                />
                              </div>
                            </Grid>
                            <Grid item sm={12} lg={6}>
                              <div className="mb-3">
                                <label className="font-weight-bold mb-2">
                                  IPFS Content Identifier (CID)
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Provide a IPFS hash or CID"
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className="mb-3">
                                <label className="font-weight-bold mb-2">
                                  Country of Grant Recipient
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Enter a legal jurisdiction to where the grant can be forwarded."
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className="mb-3">
                                <label className="font-weight-bold mb-2">
                                  IPFS URL
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Enter an immutable hash"
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <div className="form-group my-3">
                            By clicking the <strong>Create account</strong>{' '}
                            button below you agree to our terms of service and
                            privacy statement.
                          </div>
                          <div className="text-center mb-4">
                            <Button className="btn-primary text-uppercase font-weight-bold font-size-sm my-3">
                              Create a Decentralized ID now
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={5} xl={6} className="d-flex">
                  <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image opacity-5"
                        style={{
                          backgroundImage: 'url(' + sideImage + ')'
                        }}
                      />
                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                      <div className="bg-composed-wrapper--content text-center p-5">
                        <div className="text-white px-0 px-lg-2 px-xl-4">
                          <h1 className="display-3 mb-4 font-weight-bold text-white">
                          Create a Grant Profile
                          </h1>
                          <p className="font-size-lg mb-0 opacity-8">
                            SmartLaw provides tools for Web3 ecosystem to
                            advance legaltech . 11.
                          </p>
                          <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                          <div>
                            <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                              <span className="btn-wrapper--label">
                                Learn more about Creating a Grant Profile
                              </span>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'arrow-right']}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero-footer pb-4">
                      <List
                        component="div"
                        className="nav-pills nav-neutral-secondary d-flex">
                        <Tooltip title="Github" arrow>
                          <ListItem
                            component="a"
                            button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-size-lg text-white-50">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
