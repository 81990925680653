import React from 'react';

import { PageTitle } from 'layout-components';
import { Grid } from '@material-ui/core';
import { ExampleWrapperSeamless } from 'layout-components';
import Iframe from 'react-iframe';

import ElementsScrollable1 from '../../example-components/Achengine/Scrollable1';
import ElementsScrollable2 from '../../example-components/Achengine/Scrollable2';

import MarketingPartners3 from '../../example-components/MarketingPartners/MarketingPartners3';
import MarketingPartners21 from '../../example-components/MarketingPartners/MarketingPartners21';
import MarketingPartners22 from '../../example-components/MarketingPartners/MarketingPartners22';

export default function Achengine() {
  return (
    <>
      <PageTitle
        titleHeading="Digital Achievement Tokens"
        titleDescription="Use the scrolling areas to view all the available achievement tokens."
      />
      <Grid container spacing={12}>

        <Grid item md={6} lg={6}>
          <div className="p-1 mb-1">
            <ExampleWrapperSeamless>
              <ElementsScrollable1 />
            </ExampleWrapperSeamless>
          </div>
        </Grid>

        <Grid item md={6} lg={6}>
          <div className="p-2 mb-1">
            <ExampleWrapperSeamless>
              <ElementsScrollable2 />
            </ExampleWrapperSeamless>
          </div>
        </Grid>

        <Grid item md={12}>
          <div className="p-2 mb-1">
            <ExampleWrapperSeamless>
              <Iframe
                id=""
                src="https://airtable.com/embed/shrjElLoiwKnIbS1o?backgroundColor=gray"
                frameborder="0"
                onmousewheel=""
                width="100%"
                height="900"
                className=""
                display="block"
                position="relative"
                background="transparent"
                border="1px"
              />
            </ExampleWrapperSeamless>
          </div>
        </Grid>

        <Grid item md={12} lg={12}>
          <div className="p-1 mb-1">
            <ExampleWrapperSeamless>
              <MarketingPartners21 />
            </ExampleWrapperSeamless>
          </div>
        </Grid>

        <Grid item md={12} lg={12}>
          <div className="p-1 mb-1">
            <ExampleWrapperSeamless>
              <MarketingPartners3 />
            </ExampleWrapperSeamless>
          </div>
        </Grid>
        
        <Grid item md={12} lg={12}>
          <div className="p-2 mb-1">
            <ExampleWrapperSeamless>
              <MarketingPartners22 />
            </ExampleWrapperSeamless>
          </div>
        </Grid>

      </Grid>
    </>
  );
}
