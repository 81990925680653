import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@material-ui/core';

import Slider from 'react-slick';

import w3logo from '../../../assets/images/logos/web3-legal-eng-001-200w.png';


import topicthmb001 from '../../../assets/images/legal-dao/topic-thmb-001.png';
import topicthmb002 from '../../../assets/images/legal-dao/topic-thmb-002.png';
import topicthmb003 from '../../../assets/images/legal-dao/topic-thmb-003.png';
import topicthmb004 from '../../../assets/images/legal-dao/topic-thmb-004.png';
import topicthmb005 from '../../../assets/images/legal-dao/topic-thmb-005.png';
import topicthmb006 from '../../../assets/images/legal-dao/topic-thmb-006.png';
import topicthmb007 from '../../../assets/images/legal-dao/topic-thmb-007.png';
import topicthmb008 from '../../../assets/images/legal-dao/topic-thmb-008.png';
import topicthmb009 from '../../../assets/images/legal-dao/topic-thmb-009.png';
import topicthmb010 from '../../../assets/images/legal-dao/topic-thmb-010.png';
import topicthmb011 from '../../../assets/images/legal-dao/topic-thmb-011.png';
import topicthmb012 from '../../../assets/images/legal-dao/topic-thmb-012.png';
import topicthmb013 from '../../../assets/images/legal-dao/topic-thmb-013.png';
import topicthmb014 from '../../../assets/images/legal-dao/topic-thmb-014.png';
import topicthmb015 from '../../../assets/images/legal-dao/topic-thmb-015.png';
import topicthmb016 from '../../../assets/images/legal-dao/topic-thmb-016.png';
import topicthmb017 from '../../../assets/images/legal-dao/topic-thmb-017.png';
import topicthmb018 from '../../../assets/images/legal-dao/topic-thmb-018.png';
import topicthmb019 from '../../../assets/images/legal-dao/topic-thmb-019.png';
import topicthmb020 from '../../../assets/images/legal-dao/topic-thmb-020.png';
import topicthmb021 from '../../../assets/images/legal-dao/topic-thmb-021.png';
import topicthmb022 from '../../../assets/images/legal-dao/topic-thmb-022.png';
import topicthmb023 from '../../../assets/images/legal-dao/topic-thmb-023.png';
import topicthmb024 from '../../../assets/images/legal-dao/topic-thmb-024.png';
import topicthmb025 from '../../../assets/images/legal-dao/topic-thmb-025.png';
import topicthmb026 from '../../../assets/images/legal-dao/topic-thmb-026.png';
import topicthmb027 from '../../../assets/images/legal-dao/topic-thmb-027.png';
import topicthmb028 from '../../../assets/images/legal-dao/topic-thmb-028.png';
import topicthmb029 from '../../../assets/images/legal-dao/topic-thmb-029.png';
import topicthmb030 from '../../../assets/images/legal-dao/topic-thmb-030.png';
import topicthmb031 from '../../../assets/images/legal-dao/topic-thmb-031.png';
import topicthmb032 from '../../../assets/images/legal-dao/topic-thmb-032.png';
import topicthmb033 from '../../../assets/images/legal-dao/topic-thmb-033.png';
import topicthmb034 from '../../../assets/images/legal-dao/topic-thmb-034.png';
import topicthmb035 from '../../../assets/images/legal-dao/topic-thmb-035.png';
import topicthmb036 from '../../../assets/images/legal-dao/topic-thmb-036.png';
import topicthmb037 from '../../../assets/images/legal-dao/topic-thmb-037.png';
import topicthmb038 from '../../../assets/images/legal-dao/topic-thmb-038.png';
import topicthmb039 from '../../../assets/images/legal-dao/topic-thmb-039.png';
import topicthmb040 from '../../../assets/images/legal-dao/topic-thmb-040.png';
import topicthmb041 from '../../../assets/images/legal-dao/topic-thmb-041.png';
import topicthmb042 from '../../../assets/images/legal-dao/topic-thmb-042.png';
import topicthmb043 from '../../../assets/images/legal-dao/topic-thmb-043.png';
import topicthmb044 from '../../../assets/images/legal-dao/topic-thmb-044.png';
import topicthmb045 from '../../../assets/images/legal-dao/topic-thmb-045.png';
import topicthmb046 from '../../../assets/images/legal-dao/topic-thmb-046.png';
import topicthmb047 from '../../../assets/images/legal-dao/topic-thmb-047.png';
import topicthmb048 from '../../../assets/images/legal-dao/topic-thmb-048.png';
import topicthmb049 from '../../../assets/images/legal-dao/topic-thmb-049.png';
import topicthmb050 from '../../../assets/images/legal-dao/topic-thmb-050.png';
import topicthmb051 from '../../../assets/images/legal-dao/topic-thmb-051.png';
import topicthmb052 from '../../../assets/images/legal-dao/topic-thmb-052.png';
import topicthmb053 from '../../../assets/images/legal-dao/topic-thmb-053.png';
import topicthmb054 from '../../../assets/images/legal-dao/topic-thmb-054.png';
import topicthmb055 from '../../../assets/images/legal-dao/topic-thmb-055.png';
import topicthmb056 from '../../../assets/images/legal-dao/topic-thmb-056.png';
import topicthmb057 from '../../../assets/images/legal-dao/topic-thmb-057.png';
import topicthmb058 from '../../../assets/images/legal-dao/topic-thmb-058.png';
import topicthmb059 from '../../../assets/images/legal-dao/topic-thmb-059.png';
import topicthmb060 from '../../../assets/images/legal-dao/topic-thmb-060.png';
import topicthmb061 from '../../../assets/images/legal-dao/topic-thmb-061.png';
import topicthmb062 from '../../../assets/images/legal-dao/topic-thmb-062.png';
import topicthmb063 from '../../../assets/images/legal-dao/topic-thmb-063.png';
import topicthmb064 from '../../../assets/images/legal-dao/topic-thmb-064.png';
import topicthmb065 from '../../../assets/images/legal-dao/topic-thmb-065.png';
import topicthmb066 from '../../../assets/images/legal-dao/topic-thmb-066.png';
import topicthmb067 from '../../../assets/images/legal-dao/topic-thmb-067.png';
import topicthmb068 from '../../../assets/images/legal-dao/topic-thmb-068.png';
import topicthmb069 from '../../../assets/images/legal-dao/topic-thmb-069.png';
import topicthmb070 from '../../../assets/images/legal-dao/topic-thmb-070.png';
import topicthmb071 from '../../../assets/images/legal-dao/topic-thmb-071.png';
import topicthmb072 from '../../../assets/images/legal-dao/topic-thmb-072.png';
import topicthmb073 from '../../../assets/images/legal-dao/topic-thmb-073.png';
import topicthmb074 from '../../../assets/images/legal-dao/topic-thmb-074.png';
import topicthmb075 from '../../../assets/images/legal-dao/topic-thmb-075.png';
import topicthmb076 from '../../../assets/images/legal-dao/topic-thmb-076.png';
import topicthmb077 from '../../../assets/images/legal-dao/topic-thmb-077.png';
import topicthmb078 from '../../../assets/images/legal-dao/topic-thmb-078.png';
import topicthmb079 from '../../../assets/images/legal-dao/topic-thmb-079.png';
import topicthmb080 from '../../../assets/images/legal-dao/topic-thmb-080.png';
import topicthmb081 from '../../../assets/images/legal-dao/topic-thmb-081.png';
import topicthmb082 from '../../../assets/images/legal-dao/topic-thmb-082.png';
import topicthmb083 from '../../../assets/images/legal-dao/topic-thmb-083.png';
import topicthmb084 from '../../../assets/images/legal-dao/topic-thmb-084.png';
import topicthmb085 from '../../../assets/images/legal-dao/topic-thmb-085.png';
import topicthmb086 from '../../../assets/images/legal-dao/topic-thmb-086.png';
import topicthmb087 from '../../../assets/images/legal-dao/topic-thmb-087.png';
import topicthmb088 from '../../../assets/images/legal-dao/topic-thmb-088.png';
import topicthmb089 from '../../../assets/images/legal-dao/topic-thmb-089.png';
import topicthmb090 from '../../../assets/images/legal-dao/topic-thmb-090.png';
import topicthmb091 from '../../../assets/images/legal-dao/topic-thmb-091.png';
import topicthmb092 from '../../../assets/images/legal-dao/topic-thmb-092.png';
import topicthmb093 from '../../../assets/images/legal-dao/topic-thmb-093.png';
import topicthmb094 from '../../../assets/images/legal-dao/topic-thmb-094.png';
import topicthmb095 from '../../../assets/images/legal-dao/topic-thmb-095.png';
import topicthmb096 from '../../../assets/images/legal-dao/topic-thmb-096.png';
import topicthmb097 from '../../../assets/images/legal-dao/topic-thmb-097.png';
import topicthmb098 from '../../../assets/images/legal-dao/topic-thmb-098.png';
import topicthmb099 from '../../../assets/images/legal-dao/topic-thmb-099.png';
import topicthmb100 from '../../../assets/images/legal-dao/topic-thmb-100.png';
import topicthmb101 from '../../../assets/images/legal-dao/topic-thmb-101.png';
import topicthmb102 from '../../../assets/images/legal-dao/topic-thmb-102.png';
import topicthmb103 from '../../../assets/images/legal-dao/topic-thmb-103.png';
import topicthmb104 from '../../../assets/images/legal-dao/topic-thmb-104.png';
import topicthmb105 from '../../../assets/images/legal-dao/topic-thmb-105.png';
import topicthmb106 from '../../../assets/images/legal-dao/topic-thmb-106.png';
import topicthmb107 from '../../../assets/images/legal-dao/topic-thmb-107.png';
import topicthmb108 from '../../../assets/images/legal-dao/topic-thmb-108.png';
import topicthmb109 from '../../../assets/images/legal-dao/topic-thmb-109.png';
import topicthmb110 from '../../../assets/images/legal-dao/topic-thmb-110.png';
import topicthmb111 from '../../../assets/images/legal-dao/topic-thmb-111.png';
import topicthmb112 from '../../../assets/images/legal-dao/topic-thmb-112.png';
import topicthmb113 from '../../../assets/images/legal-dao/topic-thmb-113.png';
import topicthmb114 from '../../../assets/images/legal-dao/topic-thmb-114.png';
import topicthmb115 from '../../../assets/images/legal-dao/topic-thmb-115.png';
import topicthmb116 from '../../../assets/images/legal-dao/topic-thmb-116.png';
import topicthmb117 from '../../../assets/images/legal-dao/topic-thmb-117.png';
import topicthmb118 from '../../../assets/images/legal-dao/topic-thmb-118.png';
import topicthmb119 from '../../../assets/images/legal-dao/topic-thmb-119.png';
import topicthmb120 from '../../../assets/images/legal-dao/topic-thmb-120.png';
import topicthmb121 from '../../../assets/images/legal-dao/topic-thmb-121.png';
import topicthmb122 from '../../../assets/images/legal-dao/topic-thmb-122.png';
import topicthmb123 from '../../../assets/images/legal-dao/topic-thmb-123.png';
import topicthmb124 from '../../../assets/images/legal-dao/topic-thmb-124.png';
import topicthmb125 from '../../../assets/images/legal-dao/topic-thmb-125.png';
import topicthmb126 from '../../../assets/images/legal-dao/topic-thmb-126.png';
import topicthmb127 from '../../../assets/images/legal-dao/topic-thmb-127.png';
import topicthmb128 from '../../../assets/images/legal-dao/topic-thmb-128.png';
import topicthmb129 from '../../../assets/images/legal-dao/topic-thmb-129.png';
import topicthmb130 from '../../../assets/images/legal-dao/topic-thmb-130.png';
import topicthmb131 from '../../../assets/images/legal-dao/topic-thmb-131.png';
import topicthmb132 from '../../../assets/images/legal-dao/topic-thmb-132.png';
import topicthmb133 from '../../../assets/images/legal-dao/topic-thmb-133.png';
import topicthmb134 from '../../../assets/images/legal-dao/topic-thmb-134.png';
import topicthmb135 from '../../../assets/images/legal-dao/topic-thmb-135.png';
import topicthmb136 from '../../../assets/images/legal-dao/topic-thmb-136.png';
import topicthmb137 from '../../../assets/images/legal-dao/topic-thmb-137.png';
import topicthmb138 from '../../../assets/images/legal-dao/topic-thmb-138.png';
import topicthmb139 from '../../../assets/images/legal-dao/topic-thmb-139.png';
import topicthmb140 from '../../../assets/images/legal-dao/topic-thmb-140.png';
import topicthmb141 from '../../../assets/images/legal-dao/topic-thmb-141.png';
import topicthmb142 from '../../../assets/images/legal-dao/topic-thmb-142.png';
import topicthmb143 from '../../../assets/images/legal-dao/topic-thmb-143.png';
import topicthmb144 from '../../../assets/images/legal-dao/topic-thmb-144.png';
import topicthmb145 from '../../../assets/images/legal-dao/topic-thmb-145.png';
import topicthmb146 from '../../../assets/images/legal-dao/topic-thmb-146.png';
import topicthmb147 from '../../../assets/images/legal-dao/topic-thmb-147.png';
import topicthmb148 from '../../../assets/images/legal-dao/topic-thmb-148.png';
import topicthmb149 from '../../../assets/images/legal-dao/topic-thmb-149.png';
import topicthmb150 from '../../../assets/images/legal-dao/topic-thmb-150.png';
import topicthmb151 from '../../../assets/images/legal-dao/topic-thmb-151.png';
import topicthmb152 from '../../../assets/images/legal-dao/topic-thmb-152.png';
import topicthmb153 from '../../../assets/images/legal-dao/topic-thmb-153.png';
import topicthmb154 from '../../../assets/images/legal-dao/topic-thmb-154.png';
import topicthmb155 from '../../../assets/images/legal-dao/topic-thmb-155.png';
import topicthmb156 from '../../../assets/images/legal-dao/topic-thmb-156.png';
import topicthmb157 from '../../../assets/images/legal-dao/topic-thmb-157.png';
import topicthmb158 from '../../../assets/images/legal-dao/topic-thmb-158.png';
import topicthmb159 from '../../../assets/images/legal-dao/topic-thmb-159.png';
import topicthmb160 from '../../../assets/images/legal-dao/topic-thmb-160.png';
import topicthmb161 from '../../../assets/images/legal-dao/topic-thmb-161.png';
import topicthmb162 from '../../../assets/images/legal-dao/topic-thmb-162.png';
import topicthmb163 from '../../../assets/images/legal-dao/topic-thmb-163.png';
import topicthmb164 from '../../../assets/images/legal-dao/topic-thmb-164.png';
import topicthmb165 from '../../../assets/images/legal-dao/topic-thmb-165.png';
import topicthmb166 from '../../../assets/images/legal-dao/topic-thmb-166.png';
import topicthmb167 from '../../../assets/images/legal-dao/topic-thmb-167.png';
import topicthmb168 from '../../../assets/images/legal-dao/topic-thmb-168.png';
import topicthmb169 from '../../../assets/images/legal-dao/topic-thmb-169.png';
import topicthmb170 from '../../../assets/images/legal-dao/topic-thmb-170.png';
import topicthmb171 from '../../../assets/images/legal-dao/topic-thmb-171.png';
import topicthmb172 from '../../../assets/images/legal-dao/topic-thmb-172.png';
import topicthmb173 from '../../../assets/images/legal-dao/topic-thmb-173.png';
import topicthmb174 from '../../../assets/images/legal-dao/topic-thmb-174.png';
import topicthmb175 from '../../../assets/images/legal-dao/topic-thmb-175.png';
import topicthmb176 from '../../../assets/images/legal-dao/topic-thmb-176.png';
import topicthmb177 from '../../../assets/images/legal-dao/topic-thmb-177.png';
import topicthmb178 from '../../../assets/images/legal-dao/topic-thmb-178.png';
import topicthmb179 from '../../../assets/images/legal-dao/topic-thmb-179.png';
import topicthmb180 from '../../../assets/images/legal-dao/topic-thmb-180.png';
import topicthmb181 from '../../../assets/images/legal-dao/topic-thmb-181.png';
import topicthmb182 from '../../../assets/images/legal-dao/topic-thmb-182.png';
import topicthmb183 from '../../../assets/images/legal-dao/topic-thmb-183.png';
import topicthmb184 from '../../../assets/images/legal-dao/topic-thmb-184.png';
import topicthmb185 from '../../../assets/images/legal-dao/topic-thmb-185.png';
import topicthmb186 from '../../../assets/images/legal-dao/topic-thmb-186.png';
import topicthmb187 from '../../../assets/images/legal-dao/topic-thmb-187.png';
import topicthmb188 from '../../../assets/images/legal-dao/topic-thmb-188.png';




function SliderArrowNext(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  );
}

export default function LivePreviewExample() {
  const widgetsCarousels4A = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 1450,
        settings: { slidesToShow: 3, slidesToScroll: 1 }
      },
      {
        breakpoint: 1100,
        settings: { slidesToShow: 2, slidesToScroll: 1 }
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ]
  };

  return (
    <>
      <Card className="bg-night-sky py-4 mb-spacing-6-x2">
        <div className="py-4">
          <Slider
            className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
            {...widgetsCarousels4A}>





<div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb001} className="card-img-top" alt="LexAutomatico Topic 1" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  1
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb002} className="card-img-top" alt="LexAutomatico Topic 2" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  2
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb003} className="card-img-top" alt="LexAutomatico Topic 3" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  3
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb004} className="card-img-top" alt="LexAutomatico Topic 4" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  4
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb005} className="card-img-top" alt="LexAutomatico Topic 5" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  5
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb006} className="card-img-top" alt="LexAutomatico Topic 6" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  6
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb007} className="card-img-top" alt="LexAutomatico Topic 7" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  7
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb008} className="card-img-top" alt="LexAutomatico Topic 8" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  8
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb009} className="card-img-top" alt="LexAutomatico Topic 9" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  9
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb010} className="card-img-top" alt="LexAutomatico Topic 10" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  10
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb011} className="card-img-top" alt="LexAutomatico Topic 11" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  11
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb012} className="card-img-top" alt="LexAutomatico Topic 12" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  12
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb013} className="card-img-top" alt="LexAutomatico Topic 13" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  13
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb014} className="card-img-top" alt="LexAutomatico Topic 14" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  14
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb015} className="card-img-top" alt="LexAutomatico Topic 15" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  15
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb016} className="card-img-top" alt="LexAutomatico Topic 16" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  16
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb017} className="card-img-top" alt="LexAutomatico Topic 17" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  17
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb018} className="card-img-top" alt="LexAutomatico Topic 18" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  18
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb019} className="card-img-top" alt="LexAutomatico Topic 19" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  19
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb020} className="card-img-top" alt="LexAutomatico Topic 20" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  20
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb021} className="card-img-top" alt="LexAutomatico Topic 21" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  21
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb022} className="card-img-top" alt="LexAutomatico Topic 22" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  22
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb023} className="card-img-top" alt="LexAutomatico Topic 23" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  23
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb024} className="card-img-top" alt="LexAutomatico Topic 24" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  24
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb025} className="card-img-top" alt="LexAutomatico Topic 25" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  25
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb026} className="card-img-top" alt="LexAutomatico Topic 26" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  26
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb027} className="card-img-top" alt="LexAutomatico Topic 27" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  27
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb028} className="card-img-top" alt="LexAutomatico Topic 28" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  28
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb029} className="card-img-top" alt="LexAutomatico Topic 29" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  29
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb030} className="card-img-top" alt="LexAutomatico Topic 30" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  30
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb031} className="card-img-top" alt="LexAutomatico Topic 31" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  31
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb032} className="card-img-top" alt="LexAutomatico Topic 32" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  32
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb033} className="card-img-top" alt="LexAutomatico Topic 33" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  33
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb034} className="card-img-top" alt="LexAutomatico Topic 34" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  34
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb035} className="card-img-top" alt="LexAutomatico Topic 35" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  35
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb036} className="card-img-top" alt="LexAutomatico Topic 36" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  36
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb037} className="card-img-top" alt="LexAutomatico Topic 37" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  37
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb038} className="card-img-top" alt="LexAutomatico Topic 38" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  38
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb039} className="card-img-top" alt="LexAutomatico Topic 39" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  39
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb040} className="card-img-top" alt="LexAutomatico Topic 40" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  40
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb041} className="card-img-top" alt="LexAutomatico Topic 41" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  41
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb042} className="card-img-top" alt="LexAutomatico Topic 42" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  42
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb043} className="card-img-top" alt="LexAutomatico Topic 43" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  43
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb044} className="card-img-top" alt="LexAutomatico Topic 44" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  44
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb045} className="card-img-top" alt="LexAutomatico Topic 45" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  45
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb046} className="card-img-top" alt="LexAutomatico Topic 46" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  46
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb047} className="card-img-top" alt="LexAutomatico Topic 47" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  47
                  </p>
                </div>
              </a>
            </div>





















          </Slider>
        </div>
      </Card>




      <Card className="bg-night-sky py-4 mb-spacing-6-x2">
        <div className="py-4">
          <Slider
            className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
            {...widgetsCarousels4A}>






            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb048} className="card-img-top" alt="LexAutomatico Topic 48" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  48
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb049} className="card-img-top" alt="LexAutomatico Topic 49" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  49
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb050} className="card-img-top" alt="LexAutomatico Topic 50" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  50
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb051} className="card-img-top" alt="LexAutomatico Topic 51" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  51
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb052} className="card-img-top" alt="LexAutomatico Topic 52" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  52
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb053} className="card-img-top" alt="LexAutomatico Topic 53" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  53
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb054} className="card-img-top" alt="LexAutomatico Topic 54" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  54
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb055} className="card-img-top" alt="LexAutomatico Topic 55" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  55
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb056} className="card-img-top" alt="LexAutomatico Topic 56" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  56
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb057} className="card-img-top" alt="LexAutomatico Topic 57" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  57
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb058} className="card-img-top" alt="LexAutomatico Topic 58" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  58
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb059} className="card-img-top" alt="LexAutomatico Topic 59" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  59
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb060} className="card-img-top" alt="LexAutomatico Topic 60" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  60
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb061} className="card-img-top" alt="LexAutomatico Topic 61" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  61
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb062} className="card-img-top" alt="LexAutomatico Topic 62" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  62
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb063} className="card-img-top" alt="LexAutomatico Topic 63" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  63
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb064} className="card-img-top" alt="LexAutomatico Topic 64" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  64
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb065} className="card-img-top" alt="LexAutomatico Topic 65" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  65
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb066} className="card-img-top" alt="LexAutomatico Topic 66" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  66
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb067} className="card-img-top" alt="LexAutomatico Topic 67" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  67
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb068} className="card-img-top" alt="LexAutomatico Topic 68" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  68
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb069} className="card-img-top" alt="LexAutomatico Topic 69" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  69
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb070} className="card-img-top" alt="LexAutomatico Topic 70" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  70
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb071} className="card-img-top" alt="LexAutomatico Topic 71" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  71
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb072} className="card-img-top" alt="LexAutomatico Topic 72" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  72
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb073} className="card-img-top" alt="LexAutomatico Topic 73" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  73
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb074} className="card-img-top" alt="LexAutomatico Topic 74" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  74
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb075} className="card-img-top" alt="LexAutomatico Topic 75" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  75
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb076} className="card-img-top" alt="LexAutomatico Topic 76" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  76
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb077} className="card-img-top" alt="LexAutomatico Topic 77" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  77
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb078} className="card-img-top" alt="LexAutomatico Topic 78" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  78
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb079} className="card-img-top" alt="LexAutomatico Topic 79" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  79
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb080} className="card-img-top" alt="LexAutomatico Topic 80" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  80
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb081} className="card-img-top" alt="LexAutomatico Topic 81" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  81
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb082} className="card-img-top" alt="LexAutomatico Topic 82" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  82
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb083} className="card-img-top" alt="LexAutomatico Topic 83" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  83
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb084} className="card-img-top" alt="LexAutomatico Topic 84" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  84
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb085} className="card-img-top" alt="LexAutomatico Topic 85" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  85
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb086} className="card-img-top" alt="LexAutomatico Topic 86" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  86
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb087} className="card-img-top" alt="LexAutomatico Topic 87" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  87
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb088} className="card-img-top" alt="LexAutomatico Topic 88" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  88
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb089} className="card-img-top" alt="LexAutomatico Topic 89" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  89
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb090} className="card-img-top" alt="LexAutomatico Topic 90" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  90
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb091} className="card-img-top" alt="LexAutomatico Topic 91" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  91
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb092} className="card-img-top" alt="LexAutomatico Topic 92" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  92
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb093} className="card-img-top" alt="LexAutomatico Topic 93" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  93
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb094} className="card-img-top" alt="LexAutomatico Topic 94" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  94
                  </p>
                </div>
              </a>
            </div>



          </Slider>
        </div>
      </Card>














      <Card className="bg-night-sky py-4 mb-spacing-6-x2">
        <div className="py-4">
          <Slider
            className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
            {...widgetsCarousels4A}>






            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb095} className="card-img-top" alt="LexAutomatico Topic 95" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  95
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb096} className="card-img-top" alt="LexAutomatico Topic 96" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  96
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb097} className="card-img-top" alt="LexAutomatico Topic 97" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  97
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb098} className="card-img-top" alt="LexAutomatico Topic 98" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  98
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb099} className="card-img-top" alt="LexAutomatico Topic 99" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  99
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb100} className="card-img-top" alt="LexAutomatico Topic 100" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  100
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb101} className="card-img-top" alt="LexAutomatico Topic 101" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  101
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb102} className="card-img-top" alt="LexAutomatico Topic 102" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  102
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb103} className="card-img-top" alt="LexAutomatico Topic 103" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  103
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb104} className="card-img-top" alt="LexAutomatico Topic 104" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  104
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb105} className="card-img-top" alt="LexAutomatico Topic 105" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  105
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb106} className="card-img-top" alt="LexAutomatico Topic 106" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  106
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb107} className="card-img-top" alt="LexAutomatico Topic 107" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  107
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb108} className="card-img-top" alt="LexAutomatico Topic 108" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  108
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb109} className="card-img-top" alt="LexAutomatico Topic 109" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  109
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb110} className="card-img-top" alt="LexAutomatico Topic 110" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  110
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb111} className="card-img-top" alt="LexAutomatico Topic 111" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  111
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb112} className="card-img-top" alt="LexAutomatico Topic 112" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  112
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb113} className="card-img-top" alt="LexAutomatico Topic 113" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  113
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb114} className="card-img-top" alt="LexAutomatico Topic 114" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  114
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb115} className="card-img-top" alt="LexAutomatico Topic 115" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  115
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb116} className="card-img-top" alt="LexAutomatico Topic 116" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  116
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb117} className="card-img-top" alt="LexAutomatico Topic 117" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  117
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb118} className="card-img-top" alt="LexAutomatico Topic 118" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  118
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb119} className="card-img-top" alt="LexAutomatico Topic 119" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  119
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb120} className="card-img-top" alt="LexAutomatico Topic 120" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  120
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb121} className="card-img-top" alt="LexAutomatico Topic 121" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  121
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb122} className="card-img-top" alt="LexAutomatico Topic 122" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  122
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb123} className="card-img-top" alt="LexAutomatico Topic 123" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  123
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb124} className="card-img-top" alt="LexAutomatico Topic 124" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  124
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb125} className="card-img-top" alt="LexAutomatico Topic 125" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  125
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb126} className="card-img-top" alt="LexAutomatico Topic 126" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  126
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb127} className="card-img-top" alt="LexAutomatico Topic 127" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  127
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb128} className="card-img-top" alt="LexAutomatico Topic 128" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  128
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb129} className="card-img-top" alt="LexAutomatico Topic 129" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  129
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb130} className="card-img-top" alt="LexAutomatico Topic 130" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  130
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb131} className="card-img-top" alt="LexAutomatico Topic 131" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  131
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb132} className="card-img-top" alt="LexAutomatico Topic 132" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  132
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb133} className="card-img-top" alt="LexAutomatico Topic 133" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  133
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb134} className="card-img-top" alt="LexAutomatico Topic 134" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  134
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb135} className="card-img-top" alt="LexAutomatico Topic 135" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  135
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb136} className="card-img-top" alt="LexAutomatico Topic 136" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  136
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb137} className="card-img-top" alt="LexAutomatico Topic 137" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  137
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb138} className="card-img-top" alt="LexAutomatico Topic 138" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  138
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb139} className="card-img-top" alt="LexAutomatico Topic 139" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  139
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb140} className="card-img-top" alt="LexAutomatico Topic 140" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  140
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb141} className="card-img-top" alt="LexAutomatico Topic 141" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  141
                  </p>
                </div>
              </a>
            </div>




          </Slider>
        </div>
      </Card>






      <Card className="bg-night-sky py-4 mb-spacing-6-x2">
        <div className="py-4">
          <Slider
            className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
            {...widgetsCarousels4A}>





            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb142} className="card-img-top" alt="LexAutomatico Topic 142" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  142
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb143} className="card-img-top" alt="LexAutomatico Topic 143" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  143
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb144} className="card-img-top" alt="LexAutomatico Topic 144" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  144
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb145} className="card-img-top" alt="LexAutomatico Topic 145" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  145
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb146} className="card-img-top" alt="LexAutomatico Topic 146" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  146
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb147} className="card-img-top" alt="LexAutomatico Topic 147" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  147
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb148} className="card-img-top" alt="LexAutomatico Topic 148" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  148
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb149} className="card-img-top" alt="LexAutomatico Topic 149" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  149
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb150} className="card-img-top" alt="LexAutomatico Topic 150" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  150
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb151} className="card-img-top" alt="LexAutomatico Topic 151" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  151
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb152} className="card-img-top" alt="LexAutomatico Topic 152" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  152
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb153} className="card-img-top" alt="LexAutomatico Topic 153" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  153
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb154} className="card-img-top" alt="LexAutomatico Topic 154" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  154
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb155} className="card-img-top" alt="LexAutomatico Topic 155" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  155
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb156} className="card-img-top" alt="LexAutomatico Topic 156" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  156
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb157} className="card-img-top" alt="LexAutomatico Topic 157" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  157
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb158} className="card-img-top" alt="LexAutomatico Topic 158" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  158
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb159} className="card-img-top" alt="LexAutomatico Topic 159" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  159
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb160} className="card-img-top" alt="LexAutomatico Topic 160" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  160
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb161} className="card-img-top" alt="LexAutomatico Topic 161" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  161
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb162} className="card-img-top" alt="LexAutomatico Topic 162" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  162
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb163} className="card-img-top" alt="LexAutomatico Topic 163" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  163
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb164} className="card-img-top" alt="LexAutomatico Topic 164" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  164
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb165} className="card-img-top" alt="LexAutomatico Topic 165" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  165
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb166} className="card-img-top" alt="LexAutomatico Topic 166" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  166
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb167} className="card-img-top" alt="LexAutomatico Topic 167" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  167
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb168} className="card-img-top" alt="LexAutomatico Topic 168" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  168
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb169} className="card-img-top" alt="LexAutomatico Topic 169" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  169
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb170} className="card-img-top" alt="LexAutomatico Topic 170" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  170
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb171} className="card-img-top" alt="LexAutomatico Topic 171" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  171
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb172} className="card-img-top" alt="LexAutomatico Topic 172" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  172
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb173} className="card-img-top" alt="LexAutomatico Topic 173" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  173
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb174} className="card-img-top" alt="LexAutomatico Topic 174" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  174
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb175} className="card-img-top" alt="LexAutomatico Topic 175" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  175
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb176} className="card-img-top" alt="LexAutomatico Topic 176" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  176
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb177} className="card-img-top" alt="LexAutomatico Topic 177" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  177
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb178} className="card-img-top" alt="LexAutomatico Topic 178" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  178
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb179} className="card-img-top" alt="LexAutomatico Topic 179" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  179
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb180} className="card-img-top" alt="LexAutomatico Topic 180" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  180
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb181} className="card-img-top" alt="LexAutomatico Topic 181" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  181
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb182} className="card-img-top" alt="LexAutomatico Topic 182" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  182
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb183} className="card-img-top" alt="LexAutomatico Topic 183" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  183
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb184} className="card-img-top" alt="LexAutomatico Topic 184" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  184
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb185} className="card-img-top" alt="LexAutomatico Topic 185" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  185
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb186} className="card-img-top" alt="LexAutomatico Topic 186" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  186
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb187} className="card-img-top" alt="LexAutomatico Topic 187" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  187
                  </p>
                </div>
              </a>
            </div>
            <div>
              <a
                href="https://lexdao-topics/freeweb3.com"
                target="_blank"
                rel="noopener noreferrer"
                className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
                <img src={topicthmb188} className="card-img-top" alt="LexAutomatico Topic 188" />
                <div className="p-3 bg-secondary unded-bottom p-xl-4">
                  <img src={w3logo} alt="W3 logo" style={{ width: 100 }} />
                  <p className="text-second opacity-8 mt-4 mb-0">
Web3 Legal Engineering discussion description for topic  188
                  </p>
                </div>
              </a>
            </div>




          </Slider>
        </div>
      </Card>




    </>
  );
}
