import React from 'react';
import {
  Grid,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import Iframe from 'react-iframe';

// import hero4 from '../../../assets/images/hero-bg/hero-2.jpg';
export default function LivePreviewExample() {
  return (
    <>
      <div className="app-content p-0">
        <div className="app-inner-content-layout--main">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content">
              <Grid container spacing={0} className="min-vh-100">
                <Grid item lg={7} xl={6} className="d-flex align-items-center">
                  <Grid item md={10} lg={8} xl={7} className="mx-auto">
                    <div className="py-4">
                      <div className="text-center">
                        <h1 className="display-3 my-3 font-weight-bold text-primary">
                          Create a Grantee Profile
                        </h1>
                        <p className="font-size-lg mb-0 px-4 text-primary-50">
                          SmartLaw provides tools for Web3 ecosystem to advance
                          legaltech . 6 .
                        </p>
                      </div>
                      <div className="text-center my-4">
                        <h1 className="display-4 mb-1 font-weight-bold">
                          Create your Grantee Profile Here:
                        </h1>
                        <p className="font-size-lg mb-0 text-black-50">
                          Start benefitting from Web3 right away. Accounts are
                          free and are used to collect achievment and access
                          tokens.
                        </p>
                      </div>

                      <Iframe
                        id=""
                        src="https://airtable.com/embed/shrCLoqI5JlNEEuWx?backgroundColor=gray"
                        frameborder="0"
                        onmousewheel=""
                        width="100%"
                        height="600"
                        // height="3240"
                        className="mb-4"
                        display="block"
                        position="relative"
                        background="transparent"
                        border="1px"
                      />

                      <div className="mb-3">
                        <label className="font-weight-bold mb-2">
                          Grantee Name
                        </label>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Enter a legal name here."
                        />
                      </div>
                      <div className="mb-3">
                        <label className="font-weight-bold mb-2">
                          Decentralized ID
                        </label>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Enter a Decentralized ID"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="font-weight-bold mb-2">
                          ID Issuer
                        </label>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Decentralized ID Issuer"
                        />
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Password
                            </label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Enter a password to encrypt the data."
                            type="password"
                          />
                        </div>
                      </div>
                      <div className="form-group my-3">
                        <h1 className="display-4 mb-1 font-weight-bold">
                          Organizational Background
                        </h1>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Contact Name | 001
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 001 | "
                            name="contact_name_formfield_001"
                            type="textarea"
                            helperText="Helper note sits just below the form field 001 | Please enter the name of the lead contact within the organization."
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Mailing Address | 002
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 002 | "
                            name="mailing_address_formfield_002"
                            type="textarea | us_state_dropdown_"
                            helperText="Helper note sits just below the form field 002 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Legal Name or Web3 Address | 003
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 003 | "
                            name="legal_name_or_web3_address_formfield_003"
                            type="textarea"
                            helperText="Helper note sits just below the form field 003 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Address | 004
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 004 | "
                            name="address_formfield_004"
                            type="textarea"
                            helperText="Helper note sits just below the form field 004 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Phone number (for 2FA) | 005
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 005 | "
                            name="phone_number_for_2fa_formfield_005"
                            type="textarea"
                            helperText="Helper note sits just below the form field 005 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Email address | 006
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 006 | "
                            name="contact_email_address_formfield_006"
                            type="textarea"
                            helperText="Helper note sits just below the form field 006 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Discord | 007
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 007 | "
                            name="discordId_formfield_007"
                            type="textarea"
                            helperText="Helper note sits just below the form field 007 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Twitter | 008
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 008 | "
                            name="twitterId_formfield_008"
                            type="textarea"
                            helperText="Helper note sits just below the form field 008 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              GithubId | 009
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 009 | "
                            name="githubId_formfield_009"
                            type="textarea"
                            helperText="Helper note sits just below the form field 009 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Github repository | 010
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 010 | "
                            name="github_repository_formfield_010"
                            type="textarea"
                            helperText="Helper note sits just below the form field 010 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Website | 011
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 011 | "
                            name="website__formfield_011"
                            type="textarea"
                            helperText="Helper note sits just below the form field 011 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              ENS Domain name | 012
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 012 | "
                            name="ens_domain_name_formfield_012"
                            type="textarea"
                            helperText="Helper note sits just below the form field 012 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              LinkedIn | 013
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 013 | "
                            name="linkedin__formfield_013"
                            type="textarea"
                            helperText="Helper note sits just below the form field 013 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              SelfID | 014
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 014 | "
                            name="selfid__formfield_014"
                            type="textarea"
                            helperText="Helper note sits just below the form field 014 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Gitcoin Passport | 015
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 015 | "
                            name="gitcoin_passport_formfield_015"
                            type="textarea"
                            helperText="Helper note sits just below the form field 015 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Other Decentralized Id Hyperlink | 016
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 016 | "
                            name="other_did_link_formfield_016"
                            type="textarea"
                            helperText="Helper note sits just below the form field 016 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Blockchain identifier | 017
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 017 | "
                            name="blockchain_identifier_formfield_017"
                            type="textarea"
                            helperText="Helper note sits just below the form field 017 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              BrightId | 018
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 018 | "
                            name="brightId_formfield_018"
                            type="textarea"
                            helperText="Helper note sits just below the form field 018 | "
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2">
                              Web3 Address or other chain identifier | 019
                            </label>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold mb-2"></label>
                          </div>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Placeholder in the form field 019 | "
                            name="web3_address_formfield_019"
                            type="textarea"
                            helperText="Helper note sits just below the form field 019 | "
                          />
                        </div>
                        By clicking the <strong>Create account</strong> button
                        below you agree to our terms of service and privacy
                        statement.
                      </div>
                      <div className="text-center mb-4">
                        <Button className="btn-primary text-uppercase font-weight-bold font-size-sm my-3">
                          Create a Grantee Profile Now
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid item lg={5} xl={6} className="d-flex">
                  <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image opacity-5"
                        style={{
                          backgroundImage: 'url(' + sideImage + ')'
                        }}
                      />

                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                      <div className="bg-composed-wrapper--content text-center p-5">
                        <div className="text-white px-0 px-lg-2 px-xl-4">
                          <h1 className="display-3 mb-4 font-weight-bold text-white">
                          Create a Grantee Profile
                          </h1>
                          <p className="font-size-lg mb-0 opacity-8">
                            SmartLaw provides tools for Web3 ecosystem to
                            advance legaltech . 11.
                          </p>

                          <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                          <div>
                            <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                              <span className="btn-wrapper--label">
                                Learn more about Creating a Grantee Profile
                              </span>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'arrow-right']}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero-footer pb-4">
                      <List
                        component="div"
                        className="nav-pills nav-neutral-secondary d-flex">
                        <Tooltip title="Github" arrow>
                          <ListItem
                            component="a"
                            button
                            href="#/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-size-lg text-white-50">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
