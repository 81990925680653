import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Card, Container, Button } from '@material-ui/core';
import ReactPlayer from 'react-player';


// import { NavLink } from 'react-router-dom';

import Slider from 'react-slick';

import lexautomaticawlogo006400w from '../../../assets/images/lexautomatica/lexautomatica-w-logo-006-400w.png';
import lexautomaticawlogo018400w from '../../../assets/images/lexautomatica/lexautomatica-w-logo-018-400w.png';
import lexautomaticawlogo020400w from '../../../assets/images/lexautomatica/lexautomatica-w-logo-020-400w.png';
import lexautomaticawlogo029400w from '../../../assets/images/lexautomatica/lexautomatica-w-logo-029-400w.png';


import lexautomatica002200w from '../../../assets/images/lexautomatica/lexautomatica-002-200w.png';
import lexautomatica003200w from '../../../assets/images/lexautomatica/lexautomatica-003-200w.png';
import lexautomatica006200w from '../../../assets/images/lexautomatica/lexautomatica-006-200w.png';
import lexautomatica007200w from '../../../assets/images/lexautomatica/lexautomatica-007-200w.png';
import lexautomatica017200w from '../../../assets/images/lexautomatica/lexautomatica-017-200w.png';
import lexautomatica018200w from '../../../assets/images/lexautomatica/lexautomatica-018-200w.png';
import lexautomatica019200w from '../../../assets/images/lexautomatica/lexautomatica-019-200w.png';
import lexautomatica020200w from '../../../assets/images/lexautomatica/lexautomatica-020-200w.png';
import lexautomatica021200w from '../../../assets/images/lexautomatica/lexautomatica-021-200w.png';
import lexautomatica022200w from '../../../assets/images/lexautomatica/lexautomatica-022-200w.png';
import lexautomatica023200w from '../../../assets/images/lexautomatica/lexautomatica-023-200w.png';
import lexautomatica024200w from '../../../assets/images/lexautomatica/lexautomatica-024-200w.png';
import lexautomatica025200w from '../../../assets/images/lexautomatica/lexautomatica-025-200w.png';
import lexautomatica026200w from '../../../assets/images/lexautomatica/lexautomatica-026-200w.png';
import lexautomatica027200w from '../../../assets/images/lexautomatica/lexautomatica-027-200w.png';
import lexautomatica028200w from '../../../assets/images/lexautomatica/lexautomatica-028-200w.png';
import lexautomatica029200w from '../../../assets/images/lexautomatica/lexautomatica-029-200w.png';
import lexautomatica030200w from '../../../assets/images/lexautomatica/lexautomatica-030-200w.png';
import lexautomatica031200w from '../../../assets/images/lexautomatica/lexautomatica-031-200w.png';
import lexautomatica032200w from '../../../assets/images/lexautomatica/lexautomatica-032-200w.png';
import lexautomatica033200w from '../../../assets/images/lexautomatica/lexautomatica-033-200w.png';
import lexautomatica034200w from '../../../assets/images/lexautomatica/lexautomatica-034-200w.png';
import lexautomatica035200w from '../../../assets/images/lexautomatica/lexautomatica-035-200w.png';
import lexautomatica042200w from '../../../assets/images/lexautomatica/lexautomatica-042-200w.png';
import lexautomatica043200w from '../../../assets/images/lexautomatica/lexautomatica-043-200w.png';
import lexautomatica044200w from '../../../assets/images/lexautomatica/lexautomatica-044-200w.png';
import lexautomatica045200w from '../../../assets/images/lexautomatica/lexautomatica-045-200w.png';
import lexautomatica046200w from '../../../assets/images/lexautomatica/lexautomatica-046-200w.png';
import lexautomatica047200w from '../../../assets/images/lexautomatica/lexautomatica-047-200w.png';
import lexautomatica048200w from '../../../assets/images/lexautomatica/lexautomatica-048-200w.png';
import lexautomatica049200w from '../../../assets/images/lexautomatica/lexautomatica-049-200w.png';
import lexautomatica056200w from '../../../assets/images/lexautomatica/lexautomatica-056-200w.png';
import lexautomatica057200w from '../../../assets/images/lexautomatica/lexautomatica-057-200w.png';
import lexautomatica058200w from '../../../assets/images/lexautomatica/lexautomatica-058-200w.png';
import lexautomatica059200w from '../../../assets/images/lexautomatica/lexautomatica-059-200w.png';
import lexautomatica060200w from '../../../assets/images/lexautomatica/lexautomatica-060-200w.png';
import lexautomatica061200w from '../../../assets/images/lexautomatica/lexautomatica-061-200w.png';
import lexautomatica062200w from '../../../assets/images/lexautomatica/lexautomatica-062-200w.png';
import lexautomatica063200w from '../../../assets/images/lexautomatica/lexautomatica-063-200w.png';
import lexautomatica064200w from '../../../assets/images/lexautomatica/lexautomatica-064-200w.png';
import lexautomatica065200w from '../../../assets/images/lexautomatica/lexautomatica-065-200w.png';
import lexautomatica066200w from '../../../assets/images/lexautomatica/lexautomatica-066-200w.png';
import lexautomatica067200w from '../../../assets/images/lexautomatica/lexautomatica-067-200w.png';
import lexautomatica068200w from '../../../assets/images/lexautomatica/lexautomatica-068-200w.png';
import lexautomatica069200w from '../../../assets/images/lexautomatica/lexautomatica-069-200w.png';
import lexautomatica070200w from '../../../assets/images/lexautomatica/lexautomatica-070-200w.png';
import lexautomatica071200w from '../../../assets/images/lexautomatica/lexautomatica-071-200w.png';
import lexautomatica072200w from '../../../assets/images/lexautomatica/lexautomatica-072-200w.png';
import lexautomatica073200w from '../../../assets/images/lexautomatica/lexautomatica-073-200w.png';
import lexautomatica074200w from '../../../assets/images/lexautomatica/lexautomatica-074-200w.png';
import lexautomatica075200w from '../../../assets/images/lexautomatica/lexautomatica-075-200w.png';
import lexautomatica076200w from '../../../assets/images/lexautomatica/lexautomatica-076-200w.png';
import lexautomatica077200w from '../../../assets/images/lexautomatica/lexautomatica-077-200w.png';
import lexautomatica078200w from '../../../assets/images/lexautomatica/lexautomatica-078-200w.png';
import lexautomatica079200w from '../../../assets/images/lexautomatica/lexautomatica-079-200w.png';
import lexautomatica091200w from '../../../assets/images/lexautomatica/lexautomatica-091-200w.png';
import lexautomatica092200w from '../../../assets/images/lexautomatica/lexautomatica-092-200w.png';
import lexautomatica093200w from '../../../assets/images/lexautomatica/lexautomatica-093-200w.png';
import lexautomatica094200w from '../../../assets/images/lexautomatica/lexautomatica-094-200w.png';
import lexautomatica095200w from '../../../assets/images/lexautomatica/lexautomatica-095-200w.png';


function SliderArrowNext(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  );
}

export default function LivePreviewExample() {
  const widgetsCarousels4A = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 1450,
        settings: { slidesToShow: 3, slidesToScroll: 1 }
      },
      {
        breakpoint: 1100,
        settings: { slidesToShow: 2, slidesToScroll: 1 }
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ]
  };

  return (
    <>




<Card className="bg-night-sky py-4 mb-spacing-6-x2">
<Grid item lg={12} className="d-flex align-items-center"> 
<Container maxWidth="lg" justify="center">
<div className="video-wrapper"> 
<ReactPlayer
url={
'https://freeweb3.infura-ipfs.io/ipfs/QmQ5W3QS2UdWGVs8h3f4pC8XGStUbHnfENjigekAcCjPJc'
}
width="100%"
height="100%"
controls={true}
/>
</div>
</Container>
</Grid>

<div className="py-4">
<Slider
className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
{...widgetsCarousels4A}>
<div>
<a
href="http://web3legaltools.com/product/course-101-how-web3-technology-has-affected-the-legal-profession/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo006400w} className="card-img-top" alt="lexautomaticawlogo006400w" />
<div className="p-3 bg-secondary unded-bottom p-xl-4">
<img src={lexautomatica091200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
How Web3 Technology has affected the Legal Profession
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 101 for California MCLE credit is pending approval from the State Bar of California. Course 101 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 101 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-102-web3-for-legal-professionals/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo006400w} className="card-img-top" alt="lexautomaticawlogo006400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica092200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Web3 for Legal Professionals
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 102 for California MCLE credit is pending approval from the State Bar of California. Course 102 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 102 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-103-web3-technology-impact-on-the-legal-profession/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo006400w} className="card-img-top" alt="lexautomaticawlogo006400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica093200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Web3 Technology Impact on the Legal Profession
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 103 for California MCLE credit is pending approval from the State Bar of California. Course 103 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 103 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-104-web3-compliance-strategies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo006400w} className="card-img-top" alt="lexautomaticawlogo006400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica094200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Web3 Compliance Strategies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 104 for California MCLE credit is pending approval from the State Bar of California. Course 104 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 104 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-201-using-web3-technology-asset-protection-for-digital-assets/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo006400w} className="card-img-top" alt="lexautomaticawlogo006400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica095200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technology Asset Protection for Digital Assets
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 201 for California MCLE credit is pending approval from the State Bar of California. Course 201 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 201 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-202-asset-protection-for-web3-cryptocurrencies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo006400w} className="card-img-top" alt="lexautomaticawlogo006400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica006200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Asset Protection for Web3 Cryptocurrencies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 202 for California MCLE credit is pending approval from the State Bar of California. Course 202 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 202 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-203-web3-tools-used-to-understand-and-plan-for-asset-protection/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo006400w} className="card-img-top" alt="lexautomaticawlogo006400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica007200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Web3 Tools used to Understand and Plan for Asset Protection
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 203 for California MCLE credit is pending approval from the State Bar of California. Course 203 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 203 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
</Slider>
</div>
<div className="py-4">
<Slider
className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
{...widgetsCarousels4A}>
<div>
<a
href="http://web3legaltools.com/product/course-301-authenticating-electronic-signatures-stored-in-digital-records-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary unded-bottom p-xl-4">
<img src={lexautomatica017200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Authenticating Electronic Signatures stored in Digital Records using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 301 for California MCLE credit is pending approval from the State Bar of California. Course 301 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 301 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-302-digital-device-forensic-evidence-of-location-and-content-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica018200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Digital Device Forensic Evidence of Location and Content using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 302 for California MCLE credit is pending approval from the State Bar of California. Course 302 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 302 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-303-digital-device-forensics-and-the-federal-rules-of-evidence/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica019200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Digital Device Forensics and the Federal Rules of Evidence
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 303 for California MCLE credit is pending approval from the State Bar of California. Course 303 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 303 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-304-using-web3-technologies-for-creating-disputable-collaboration-agreements-for-small-businesses/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica020200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 technologies for Creating Disputable Collaboration Agreements for Small Businesses
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 304 for California MCLE credit is pending approval from the State Bar of California. Course 304 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 304 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-305-documenting-the-attorney-client-privilege-work-product-and-confidentiality-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica021200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Documenting the Attorney-Client Privilege, Work Product and Confidentiality using Web3 technologies.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 305 for California MCLE credit is pending approval from the State Bar of California. Course 305 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 305 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-306-using-electronic-documents-in-legal-cases-from-pdfs-to-nfts-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica022200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Electronic Documents in Legal Cases from PDFs to NFTs using Web3 Technologies.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 306 for California MCLE credit is pending approval from the State Bar of California. Course 306 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 306 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-307-using-web3-technologies-for-presenting-electronic-evidence-in-social-media-posts-and-server-archives-to-prove-a-claim-in-a-lawsuit/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica023200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 technologies for presenting Electronic Evidence in Social Media Posts and Server Archives to prove a claim in a lawsuit.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 307 for California MCLE credit is pending approval from the State Bar of California. Course 307 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 307 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-308-using-web3-technologies-for-presenting-electronic-evidence-to-affect-settlements-and-prove-damages-in-a-legal-claim/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica024200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 technologies for presenting Electronic Evidence to Affect Settlements and Prove Damages in a legal claim.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 308 for California MCLE credit is pending approval from the State Bar of California. Course 308 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 308 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-309-using-web3-technologies-to-manage-electronically-stored-information-under-the-federal-rules-of-evidence/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica025200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technologies to manage Electronically Stored Information under the Federal Rules of Evidence.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 309 for California MCLE credit is pending approval from the State Bar of California. Course 309 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 309 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-310-using-web3-technologies-to-store-and-present-the-findings-of-an-examination-of-electronic-documents/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica026200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technologies to Store and Present the Findings of an Examination of Electronic Documents
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 310 for California MCLE credit is pending approval from the State Bar of California. Course 310 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 310 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-311-expert-forensic-opinions-for-digital-records-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica027200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Expert Forensic Opinions for Digital Records using Web3 Technologies.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 311 for California MCLE credit is pending approval from the State Bar of California. Course 311 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 311 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-312-forensic-examination-of-blockchain-and-node-based-evidence/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary unded-bottom p-xl-4">
<img src={lexautomatica028200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Forensic Examination of Blockchain and Node Based Evidence
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 312 for California MCLE credit is pending approval from the State Bar of California. Course 312 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 312 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-313-forensic-imaging-strategies-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica029200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Forensic Imaging Strategies using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 313 for California MCLE credit is pending approval from the State Bar of California. Course 313 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 313 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-314-the-importance-of-metadata-and-digital-evidence-contained-in-consumer-electronic-device-for-proving-claims-in-lawsuits/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica030200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
The importance of Metadata and Digital Evidence contained in Consumer Electronic device for proving claims in lawsuits.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 314 for California MCLE credit is pending approval from the State Bar of California. Course 314 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 314 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-315-using-web3-self-authenticating-records-to-make-a-case-to-a-jury/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica031200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Self Authenticating Records to Make a Case to a Jury
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 315 for California MCLE credit is pending approval from the State Bar of California. Course 315 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 315 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-316-understanding-device-location-and-metadata-on-electronic-devices-for-evidence-in-lawsuits/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo020400w} className="card-img-top" alt="lexautomaticawlogo020400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica032200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Understanding Device Location and Metadata on Electronic Devices for Evidence in lawsuits.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 316 for California MCLE credit is pending approval from the State Bar of California. Course 316 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 316 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
</Slider>
</div>
<div className="py-4">
<Slider
className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
{...widgetsCarousels4A}>
<div>
<a
href="http://web3legaltools.com/product/course-401-basics-of-using-web3-technologies-for-creating-marketing-and-selling-nfts-and-digital-assets-under-us-law/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary unded-bottom p-xl-4">
<img src={lexautomatica033200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Basics of using Web3 technologies for Creating, Marketing and Selling NFTs and digital assets under US Law.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 401 for California MCLE credit is pending approval from the State Bar of California. Course 401 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 401 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-402-using-web3-technologies-in-litigating-automobile-collision-claims/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica034200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technologies in Litigating Automobile Collision Claims
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 402 for California MCLE credit is pending approval from the State Bar of California. Course 402 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 402 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-403-identifying-conflicts-and-tensions-related-to-the-preservation-retention-of-evidential-records/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica035200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Identifying Conflicts and Tensions related to the preservation & Retention of Evidential Records
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 403 for California MCLE credit is pending approval from the State Bar of California. Course 403 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 403 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-404-drafting-disputable-business-agreements-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica042200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Drafting Disputable Business Agreements using Web3 Technologies.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 404 for California MCLE credit is pending approval from the State Bar of California. Course 404 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 404 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-405-drafting-motions-in-civil-litigation/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica043200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Drafting Motions in Civil Litigation
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 405 for California MCLE credit is pending approval from the State Bar of California. Course 405 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 405 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-406-electronic-discovery-challenges-in-a-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica044200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Electronic Discovery Challenges in a Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 406 for California MCLE credit is pending approval from the State Bar of California. Course 406 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 406 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-407-fundamentals-of-electronic-discovery-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica045200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Fundamentals of Electronic Discovery using Web3 Technologies.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 407 for California MCLE credit is pending approval from the State Bar of California. Course 407 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 407 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-408-using-web3-technologies-for-efficient-electronic-discovery/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica046200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technologies for Efficient Electronic Discovery.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 408 for California MCLE credit is pending approval from the State Bar of California. Course 408 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 408 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-409-effective-trust-management-for-beneficiaries-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica047200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Effective Trust Management for Beneficiaries using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 409 for California MCLE credit is pending approval from the State Bar of California. Course 409 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 409 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-410-effectively-using-visual-presentation-of-evidence-in-jury-trials/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica048200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Effectively Using Visual Presentation of Evidence in Jury Trials
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 410 for California MCLE credit is pending approval from the State Bar of California. Course 410 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 410 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-411-general-principals-and-drafting-arbitration-settlements/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica049200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
General Principals and Drafting Arbitration Settlements
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 411 for California MCLE credit is pending approval from the State Bar of California. Course 411 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 411 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-412-general-principals-of-alternative-dispute-resolution/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary unded-bottom p-xl-4">
<img src={lexautomatica056200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
General Principals of Alternative Dispute Resolution
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 412 for California MCLE credit is pending approval from the State Bar of California. Course 412 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 412 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-413-using-web3-technologies-for-managing-a-small-law-practice/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica057200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technologies for Managing a Small Law Practice
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 413 for California MCLE credit is pending approval from the State Bar of California. Course 413 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 413 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-414-using-web3-technologies-to-verify-and-maintain-the-accuracy-of-mass-communication-and-ethics-under-us-law/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica058200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technologies to verify and maintain the accuracy of Mass Communication and Ethics under US Law.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 414 for California MCLE credit is pending approval from the State Bar of California. Course 414 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 414 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-415-pre-constructing-a-legal-case-in-a-web3-ready-metaverse/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica059200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Pre-constructing a Legal Case in a Web3-ready Metaverse
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 415 for California MCLE credit is pending approval from the State Bar of California. Course 415 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 415 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-416-preparing-for-a-successful-mediation-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica060200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Preparing for a Successful Mediation using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 416 for California MCLE credit is pending approval from the State Bar of California. Course 416 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 416 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-417-settlement-of-employment-disputes-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica061200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Settlement of Employment Disputes using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 417 for California MCLE credit is pending approval from the State Bar of California. Course 417 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 417 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-418-tips-techniques-and-tools-for-efficient-evidence-management-in-personal-injury-cases-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica062200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Tips, Techniques and Tools for Efficient Evidence Management in Personal Injury Cases using Web3 Technologies.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 418 for California MCLE credit is pending approval from the State Bar of California. Course 418 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 418 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-419-using-psychology-and-the-law-in-dispute-resolution/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica063200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Psychology and the Law in Dispute Resolution
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 419 for California MCLE credit is pending approval from the State Bar of California. Course 419 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 419 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-420-using-web3-technology-to-settle-employment-disputes/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica064200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technology to Settle Employment Disputes
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 420 for California MCLE credit is pending approval from the State Bar of California. Course 420 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 420 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-421-using-web3-decentralized-mediation-to-avoid-being-sued/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica065200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Decentralized Mediation to Avoid Being Sued
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 421 for California MCLE credit is pending approval from the State Bar of California. Course 421 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 421 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-422-using-web3-to-achieve-the-most-favorable-outcome-in-mediation/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo018400w} className="card-img-top" alt="lexautomaticawlogo018400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica066200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 to Achieve the Most Favorable Outcome in Mediation
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 422 for California MCLE credit is pending approval from the State Bar of California. Course 422 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 422 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
</Slider>
</div>
<div className="py-4">
<Slider
className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
{...widgetsCarousels4A}>
<div>
<a
href="http://web3legaltools.com/product/course-501-using-web3-technologies-for-efficiencies-under-california-residential-real-estate-law/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary unded-bottom p-xl-4">
<img src={lexautomatica067200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 technologies for Efficiencies under California Residential Real Estate Law.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 501 for California MCLE credit is pending approval from the State Bar of California. Course 501 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 501 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-601-ip-questions-for-every-business-and-how-web3-tools-may-help/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica068200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
IP Questions for Every Business and how Web3 tools may help.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 601 for California MCLE credit is pending approval from the State Bar of California. Course 601 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 601 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-602-trademark-fundamentals-of-digital-assets-and-non-fungible-tokens-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica069200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Trademark Fundamentals of Digital Assets and Non-Fungible Tokens using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 602 for California MCLE credit is pending approval from the State Bar of California. Course 602 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 602 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-701-estate-planning-financial-security-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica070200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Estate Planning & Financial Security using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 701 for California MCLE credit is pending approval from the State Bar of California. Course 701 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 701 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-702-independent-trustee-strategies-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica071200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Independent Trustee Strategies using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 702 for California MCLE credit is pending approval from the State Bar of California. Course 702 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 702 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-703-trustee-duties-to-inform-and-account-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica072200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Trustee Duties to Inform and Account using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 703 for California MCLE credit is pending approval from the State Bar of California. Course 703 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 703 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-704-trustors-estate-planning-using-web3-tools/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica073200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Trustors Estate Planning using Web3 Tools
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 704 for California MCLE credit is pending approval from the State Bar of California. Course 704 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 704 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-705-using-web3-tools-for-trust-administration/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica074200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Tools for Trust Administration
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 705 for California MCLE credit is pending approval from the State Bar of California. Course 705 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 705 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-801-forensic-imaging-strategies-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica075200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Forensic Imaging Strategies using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 801 for California MCLE credit is pending approval from the State Bar of California. Course 801 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 801 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-802-privacy-rights-in-the-metaverse-using-web3-technologies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica076200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Privacy Rights in the Metaverse using Web3 Technologies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 802 for California MCLE credit is pending approval from the State Bar of California. Course 802 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 802 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-803-tips-for-working-with-web3-technology-companies/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica077200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Tips for Working with Web3 Technology Companies
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 803 for California MCLE credit is pending approval from the State Bar of California. Course 803 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 803 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-804-using-web3-technology-to-dismantle-a-prosecutors-criminal-case/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary unded-bottom p-xl-4">
<img src={lexautomatica078200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Technology to Dismantle a Prosecutor's Criminal Case
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 804 for California MCLE credit is pending approval from the State Bar of California. Course 804 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 804 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-805-using-web3-titling-for-immutable-records-and-the-chain-of-custody/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica079200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 Titling for Immutable Records and the Chain of Custody
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 805 for California MCLE credit is pending approval from the State Bar of California. Course 805 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 805 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-806-using-web3-to-leverage-the-first-amendment/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica002200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
Using Web3 to Leverage the First Amendment
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 806 for California MCLE credit is pending approval from the State Bar of California. Course 806 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 806 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
<div>
<a
href="http://web3legaltools.com/product/course-807-what-every-attorney-should-know-about-web3-technologies-and-digital-evidence/"
target="_blank"
rel="noopener noreferrer"
className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
<img src={lexautomaticawlogo029400w} className="card-img-top" alt="lexautomaticawlogo029400w" />
<div className="p-3 bg-secondary rounded-bottom p-xl-4">
<img src={lexautomatica003200w} alt="web3skills" style={{ width: 160 }} />
<p className="text-primary font-size-lg font-weight-bold mt-4 mb-0">
What Every Attorney Should Know About Web3 Technologies and Digital Evidence.
</p>
<p className="text-second text-size-sm opacity-8 mt-4 mb-0">
LexAutomatica Course 807 for California MCLE credit is pending approval from the State Bar of California. Course 807 can be taken before approval, and if ultimately the course is approved credit will be granted retroactively through the completion of an on-line quiz where the answers are recorded to satisfy the State Bar’s record-keeping rules for Minimum Continued Legal Education (MCLE).
</p>
<p className="text-second opacity-8 mt-4 mb-0">
<span className="font-size-xs">Course Delivery Method:</span> &nbsp; <span className="font-size-sm font-weight-bold text-primary">Live Streaming Video, MP4 Video Download</span>
</p>
<h3 className="display-4 text-center font-weight-bold mt-4 mb-1 text-primary">
1.25 MCLE Credits
</h3>
<h3 className="display-5 text-center font-weight-bold my-1 text-danger">
On-Sale! $16.95
</h3>
</div>
<div className="d-block mt-4">














<form>
<Button


className="btn-primary align-center bg-night-sky py-3 px-3 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none hover-scale-sm hover-scale-lg ml-4 mr-3 mb-3">
<span className="btn-wrapper--label">
Order Course 807 Now
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
</form>
</div>
</a>
</div>
</Slider>
</div>
</Card>
    </>
  );
}
