import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip
} from '@material-ui/core';

import ak_flag from '../../../assets/images/us-w80/ak.png';
import al_flag from '../../../assets/images/us-w80/al.png';
import ar_flag from '../../../assets/images/us-w80/ar.png';
import az_flag from '../../../assets/images/us-w80/az.png';
import ca_flag from '../../../assets/images/us-w80/ca.png';
import co_flag from '../../../assets/images/us-w80/co.png';
import ct_flag from '../../../assets/images/us-w80/ct.png';
import de_flag from '../../../assets/images/us-w80/de.png';
import fl_flag from '../../../assets/images/us-w80/fl.png';
import ga_flag from '../../../assets/images/us-w80/ga.png';
import hi_flag from '../../../assets/images/us-w80/hi.png';
import ia_flag from '../../../assets/images/us-w80/ia.png';
import id_flag from '../../../assets/images/us-w80/id.png';
import il_flag from '../../../assets/images/us-w80/il.png';
import in_flag from '../../../assets/images/us-w80/in.png';
import ks_flag from '../../../assets/images/us-w80/ks.png';
import ky_flag from '../../../assets/images/us-w80/ky.png';
import la_flag from '../../../assets/images/us-w80/la.png';
import ma_flag from '../../../assets/images/us-w80/ma.png';
import md_flag from '../../../assets/images/us-w80/md.png';
import me_flag from '../../../assets/images/us-w80/me.png';
import mi_flag from '../../../assets/images/us-w80/mi.png';
import mn_flag from '../../../assets/images/us-w80/mn.png';
import mo_flag from '../../../assets/images/us-w80/mo.png';
import ms_flag from '../../../assets/images/us-w80/ms.png';
import mt_flag from '../../../assets/images/us-w80/mt.png';
import nc_flag from '../../../assets/images/us-w80/nc.png';
import nd_flag from '../../../assets/images/us-w80/nd.png';
import ne_flag from '../../../assets/images/us-w80/ne.png';
import nh_flag from '../../../assets/images/us-w80/nh.png';
import nj_flag from '../../../assets/images/us-w80/nj.png';
import nm_flag from '../../../assets/images/us-w80/nm.png';
import nv_flag from '../../../assets/images/us-w80/nv.png';
import ny_flag from '../../../assets/images/us-w80/ny.png';
import oh_flag from '../../../assets/images/us-w80/oh.png';
import ok_flag from '../../../assets/images/us-w80/ok.png';
import or_flag from '../../../assets/images/us-w80/or.png';
import pa_flag from '../../../assets/images/us-w80/pa.png';
import ri_flag from '../../../assets/images/us-w80/ri.png';
import sc_flag from '../../../assets/images/us-w80/sc.png';
import sd_flag from '../../../assets/images/us-w80/sd.png';
import tn_flag from '../../../assets/images/us-w80/tn.png';
import tx_flag from '../../../assets/images/us-w80/tx.png';
import ut_flag from '../../../assets/images/us-w80/ut.png';
import va_flag from '../../../assets/images/us-w80/va.png';
import vt_flag from '../../../assets/images/us-w80/vt.png';
import wa_flag from '../../../assets/images/us-w80/wa.png';
import wi_flag from '../../../assets/images/us-w80/wi.png';
import wv_flag from '../../../assets/images/us-w80/wv.png';
import wy_flag from '../../../assets/images/us-w80/wy.png';

export default function LivePreviewExample() {
  return (
    <>
<Card className="card-box mb-spacing-6-x2">
<div className="card-header pr-2">
<div className="card-header--title">
State Legislation Regarding Cryptography and Digital Assets
</div>
<div className="card-header--actions">
<Tooltip title="Refresh">
<Button size="small" className="btn-neutral-primary">
<FontAwesomeIcon icon={['fas', 'cog']} spin />
</Button>
</Tooltip>
</div>
</div>
<CardContent>
<div className="table-responsive-md">
<Table className="table table-borderless table-hover text-nowrap mb-0">
<thead>
<tr>
<th>---</th>
<th className="text-left">Western USA</th>
<th className="text-center">Status</th>
<th className="text-center">Progress</th>
<th className="text-center">Actions</th>
</tr>
</thead>
<tbody>
<tr>
<td>CA</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="California-flag" src={ca_flag} />
</div>
</div>
<div>
<a
href="http://leginfo.legislature.ca.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of California">
The state of California
</a>
<span className="text-black-50 d-block">
<a
href="http://leginfo.legislature.ca.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of California">
California Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={60}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>ID</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Idaho-flag" src={id_flag} />
</div>
</div>
<div>
<a
href="https://legislature.idaho.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Idaho">
The state of Idaho
</a>
<span className="text-black-50 d-block">
<a
href="https://legislature.idaho.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Idaho">
Idaho Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={85}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>WY</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Wyoming-flag" src={wy_flag} />
</div>
</div>
<div>
<a
href="https://www.wyoleg.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Wyoming">
The state of Wyoming
</a>
<span className="text-black-50 d-block">
<a
href="https://www.wyoleg.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Wyoming">
Wyoming Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={30}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>CO</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Colorado-flag" src={co_flag} />
</div>
</div>
<div>
<a
href="http://leg.colorado.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Colorado">
The state of Colorado
</a>
<span className="text-black-50 d-block">
<a
href="http://leg.colorado.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Colorado">
Colorado Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={45}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>HI</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Hawaii-flag" src={hi_flag} />
</div>
</div>
<div>
<a
href="http://www.capitol.hawaii.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Hawaii">
The state of Hawaii
</a>
<span className="text-black-50 d-block">
<a
href="http://www.capitol.hawaii.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Hawaii">
Hawaii Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={55}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>NV</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Nevada-flag" src={nv_flag} />
</div>
</div>
<div>
<a
href="https://www.leg.state.nv.us/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Nevada">
The state of Nevada
</a>
<span className="text-black-50 d-block">
<a
href="https://www.leg.state.nv.us/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Nevada">
Nevada Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={15}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>WA</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Washington-flag" src={wa_flag} />
</div>
</div>
<div>
<a
href="http://leg.wa.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Washington">
The state of Washington
</a>
<span className="text-black-50 d-block">
<a
href="http://leg.wa.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Washington">
Washington Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={55}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>UT</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Utah-flag" src={ut_flag} />
</div>
</div>
<div>
<a
href="https://le.utah.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Utah">
The state of Utah
</a>
<span className="text-black-50 d-block">
<a
href="https://le.utah.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Utah">
Utah Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={85}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>OR</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Oregon-flag" src={or_flag} />
</div>
</div>
<div>
<a
href="https://www.oregonlegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Oregon">
The state of Oregon
</a>
<span className="text-black-50 d-block">
<a
href="https://www.oregonlegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Oregon">
Oregon Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
</tbody>
</Table>
<hr />
<Table className="table table-borderless table-hover text-nowrap mb-0">
<thead>
<tr>
<th>---</th>
<th className="text-left">Southwest USA</th>
<th className="text-center">Status</th>
<th className="text-center">Progress</th>
<th className="text-center">Actions</th>
</tr>
</thead>
<tbody>
<tr>
<td>AZ</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Arizona-flag" src={az_flag} />
</div>
</div>
<div>
<a
href="https://www.azleg.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Arizona">
The state of Arizona
</a>
<span className="text-black-50 d-block">
<a
href="https://www.azleg.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Arizona">
Arizona Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={80}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>NM</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="New Mexico-flag" src={nm_flag} />
</div>
</div>
<div>
<a
href="https://www.nmlegis.gov/ "
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of New Mexico">
The state of New Mexico
</a>
<span className="text-black-50 d-block">
<a
href="https://www.nmlegis.gov/ "
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of New Mexico">
New Mexico Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={65}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>OK</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Oklahoma-flag" src={ok_flag} />
</div>
</div>
<div>
<a
href="http://www.oklegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Oklahoma">
The state of Oklahoma
</a>
<span className="text-black-50 d-block">
<a
href="http://www.oklegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Oklahoma">
Oklahoma Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={15}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>TX</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Texas-flag" src={tx_flag} />
</div>
</div>
<div>
<a
href="https://capitol.texas.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Texas">
The state of Texas
</a>
<span className="text-black-50 d-block">
<a
href="https://capitol.texas.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Texas">
Texas Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={15}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
</tbody>
</Table>
<hr />
<Table className="table table-borderless table-hover text-nowrap mb-0">
<thead>
<tr>
<th>---</th>
<th className="text-left">Midwest USA</th>
<th className="text-center">Status</th>
<th className="text-center">Progress</th>
<th className="text-center">Actions</th>
</tr>
</thead>
<tbody>
<tr>
<td>IL</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Illinois-flag" src={il_flag} />
</div>
</div>
<div>
<a
href="http://www.ilga.gov/ "
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Illinois">
The state of Illinois
</a>
<span className="text-black-50 d-block">
<a
href="http://www.ilga.gov/ "
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Illinois">
Illinois Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={65}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>IN</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Indiana-flag" src={in_flag} />
</div>
</div>
<div>
<a
href="http://iga.in.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Indiana">
The state of Indiana
</a>
<span className="text-black-50 d-block">
<a
href="http://iga.in.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Indiana">
Indiana Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={30}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>IA</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Iowa-flag" src={ia_flag} />
</div>
</div>
<div>
<a
href="https://www.legis.iowa.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Iowa">
The state of Iowa
</a>
<span className="text-black-50 d-block">
<a
href="https://www.legis.iowa.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Iowa">
Iowa Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={70}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>KS</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Kansas-flag" src={ks_flag} />
</div>
</div>
<div>
<a
href="http://www.kslegislature.org/li/ "
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Kansas">
The state of Kansas
</a>
<span className="text-black-50 d-block">
<a
href="http://www.kslegislature.org/li/ "
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Kansas">
Kansas Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={55}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>MI</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Michigan-flag" src={mi_flag} />
</div>
</div>
<div>
<a
href="http://www.legislature.mi.gov/(S(vyioe1zsf0d4hlnnu5y3k0c2))/mileg.aspx?page=home"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Michigan">
The state of Michigan
</a>
<span className="text-black-50 d-block">
<a
href="http://www.legislature.mi.gov/(S(vyioe1zsf0d4hlnnu5y3k0c2))/mileg.aspx?page=home"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Michigan">
Michigan Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={25}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>MN</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Minnesota-flag" src={mn_flag} />
</div>
</div>
<div>
<a
href="https://www.leg.state.mn.us/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Minnesota">
The state of Minnesota
</a>
<span className="text-black-50 d-block">
<a
href="https://www.leg.state.mn.us/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Minnesota">
Minnesota Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={60}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>MO</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Missouri-flag" src={mo_flag} />
</div>
</div>
<div>
<a
href="https://www.mo.gov/government/legislative-branch/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Missouri">
The state of Missouri
</a>
<span className="text-black-50 d-block">
<a
href="https://www.mo.gov/government/legislative-branch/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Missouri">
Missouri Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={15}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>NE</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Nebraska-flag" src={ne_flag} />
</div>
</div>
<div>
<a
href="https://nebraskalegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Nebraska">
The state of Nebraska
</a>
<span className="text-black-50 d-block">
<a
href="https://nebraskalegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Nebraska">
Nebraska Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={90}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>ND</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="North Dakota-flag" src={nd_flag} />
</div>
</div>
<div>
<a
href="https://www.legis.nd.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of North Dakota">
The state of North Dakota
</a>
<span className="text-black-50 d-block">
<a
href="https://www.legis.nd.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of North Dakota">
North Dakota" Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={55}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>OH</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Ohio-flag" src={oh_flag} />
</div>
</div>
<div>
<a
href="https://www.legislature.ohio.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Ohio">
The state of Ohio
</a>
<span className="text-black-50 d-block">
<a
href="https://www.legislature.ohio.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Ohio">
Ohio Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={45}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>SD</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="South Dakota-flag" src={sd_flag} />
</div>
</div>
<div>
<a
href="http://sdlegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of South Dakota">
The state of South Dakota
</a>
<span className="text-black-50 d-block">
<a
href="http://sdlegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of South Dakota">
South Dakota" Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={60}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>WI</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Wisconsin-flag" src={wi_flag} />
</div>
</div>
<div>
<a
href="http://legis.wisconsin.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Wisconsin">
The state of Wisconsin
</a>
<span className="text-black-50 d-block">
<a
href="http://legis.wisconsin.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Wisconsin">
Wisconsin Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={65}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
</tbody>
</Table>
<hr />
<Table className="table table-borderless table-hover text-nowrap mb-0">
<thead>
<tr>
<th>---</th>
<th className="text-left">Northesast USA</th>
<th className="text-center">Status</th>
<th className="text-center">Progress</th>
<th className="text-center">Actions</th>
</tr>
</thead>
<tbody>
<tr>
<td>NY</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="New York-flag" src={ny_flag} />
</div>
</div>
<div>
<a
href="https://nyassembly.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of New York">
The state of New York
</a>
<span className="text-black-50 d-block">
<a
href="https://nyassembly.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of New York">
New York Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={30}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>MA</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Massachusetts-flag" src={ma_flag} />
</div>
</div>
<div>
<a
href="https://malegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Massachusetts">
The state of Massachusetts
</a>
<span className="text-black-50 d-block">
<a
href="https://malegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Massachusetts">
Massachusetts Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={80}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>CT</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Connecticut-flag" src={ct_flag} />
</div>
</div>
<div>
<a
href="https://www.cga.ct.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Connecticut">
The state of Connecticut
</a>
<span className="text-black-50 d-block">
<a
href="https://www.cga.ct.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Connecticut">
Connecticut Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={15}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>RI</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Rhode Island-flag" src={ri_flag} />
</div>
</div>
<div>
<a
href="http://www.rilin.state.ri.us/Pages/Default.aspx"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Rhode Island">
The state of Rhode Island
</a>
<span className="text-black-50 d-block">
<a
href="http://www.rilin.state.ri.us/Pages/Default.aspx"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Rhode Island">
Rhode Island" Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={80}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>VT</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Vermont-flag" src={vt_flag} />
</div>
</div>
<div>
<a
href="https://legislature.vermont.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Vermont">
The state of Vermont
</a>
<span className="text-black-50 d-block">
<a
href="https://legislature.vermont.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Vermont">
Vermont Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={90}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>NH</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="New Hampshire-flag" src={nh_flag} />
</div>
</div>
<div>
<a
href="http://www.gencourt.state.nh.us/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of New Hampshire">
The state of New Hampshire
</a>
<span className="text-black-50 d-block">
<a
href="http://www.gencourt.state.nh.us/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of New Hampshire">
New Hampshire Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={55}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>ME</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Maine-flag" src={me_flag} />
</div>
</div>
<div>
<a
href="http://legislature.maine.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Maine">
The state of Maine
</a>
<span className="text-black-50 d-block">
<a
href="http://legislature.maine.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Maine">
Maine Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>GA</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Georgia-flag" src={ga_flag} />
</div>
</div>
<div>
<a
href="http://www.legis.ga.gov/en-US/default.aspx"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Georgia">
The state of Georgia
</a>
<span className="text-black-50 d-block">
<a
href="http://www.legis.ga.gov/en-US/default.aspx"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Georgia">
Georgia Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={15}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<hr />
</tbody>
</Table>
<hr />
<Table className="table table-borderless table-hover text-nowrap mb-0">
<thead>
<tr>
<th>---</th>
<th className="text-left">Southern USA</th>
<th className="text-center">Status</th>
<th className="text-center">Progress</th>
<th className="text-center">Actions</th>
</tr>
</thead>
<tbody>
<tr>
<td>FL</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Florida-flag" src={fl_flag} />
</div>
</div>
<div>
<a
href="http://www.leg.state.fl.us/Welcome/index.cfm?CFID=156970768&amp;CFTOKEN=65926224"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Florida">
The state of Florida
</a>
<span className="text-black-50 d-block">
<a
href="http://www.leg.state.fl.us/Welcome/index.cfm?CFID=156970768&amp;CFTOKEN=65926224"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Florida">
Florida Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={90}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>VA</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Virginia-flag" src={va_flag} />
</div>
</div>
<div>
<a
href="https://virginiageneralassembly.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Virginia">
The state of Virginia
</a>
<span className="text-black-50 d-block">
<a
href="https://virginiageneralassembly.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Virginia">
Virginia Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={15}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>NC</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="North Carolina-flag" src={nc_flag} />
</div>
</div>
<div>
<a
href="https://www.ncleg.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of North Carolina">
The state of North Carolina
</a>
<span className="text-black-50 d-block">
<a
href="https://www.ncleg.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of North Carolina">
North Carolina Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={70}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>LA</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Louisiana-flag" src={la_flag} />
</div>
</div>
<div>
<a
href="http://www.legis.la.gov/legis/home.aspx"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Louisiana">
The state of Louisiana
</a>
<span className="text-black-50 d-block">
<a
href="http://www.legis.la.gov/legis/home.aspx"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Louisiana">
Louisiana Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={15}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>MS</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Mississippi-flag" src={ms_flag} />
</div>
</div>
<div>
<a
href="http://www.legislature.ms.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Mississippi">
The state of Mississippi
</a>
<span className="text-black-50 d-block">
<a
href="http://www.legislature.ms.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Mississippi">
Mississippi Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={45}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>AL</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Alabama-flag" src={al_flag} />
</div>
</div>
<div>
<a
href="http://www.legislature.state.al.us/aliswww/default.aspx"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Alabama">
The state of Alabama
</a>
<span className="text-black-50 d-block">
<a
href="http://www.legislature.state.al.us/aliswww/default.aspx"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Alabama">
Alabama Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={20}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>AR</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Arkansas-flag" src={ar_flag} />
</div>
</div>
<div>
<a
href="http://www.arkleg.state.ar.us/assembly/2019/2019R/Pages/Home.aspx"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Arkansas">
The state of Arkansas
</a>
<span className="text-black-50 d-block">
<a
href="http://www.arkleg.state.ar.us/assembly/2019/2019R/Pages/Home.aspx"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Arkansas">
Arkansas Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={25}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<hr />
</tbody>
</Table>
<hr />
<Table className="table table-borderless table-hover text-nowrap mb-0">
<thead>
<tr>
<th>---</th>
<th className="text-left">Regulatory Environment Unknown</th>
<th className="text-center">Status</th>
<th className="text-center">Progress</th>
<th className="text-center">Actions</th>
</tr>
</thead>
<tbody>
<hr />
<tr>
<td>AK</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Alaska-flag" src={ak_flag} />
</div>
</div>
<div>
<a
href="http://w3.legis.state.ak.us/index.php"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Alaska">
The state of Alaska
</a>
<span className="text-black-50 d-block">
<a
href="http://w3.legis.state.ak.us/index.php"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Alaska">
Alaska Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={40}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>DE</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Delaware-flag" src={de_flag} />
</div>
</div>
<div>
<a
href="http://legis.delaware.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Delaware">
The state of Delaware
</a>
<span className="text-black-50 d-block">
<a
href="http://legis.delaware.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Delaware">
Delaware Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={85}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>KY</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Kentucky-flag" src={ky_flag} />
</div>
</div>
<div>
<a
href="https://legislature.ky.gov/Pages/index.aspx"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Kentucky">
The state of Kentucky
</a>
<span className="text-black-50 d-block">
<a
href="https://legislature.ky.gov/Pages/index.aspx"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Kentucky">
Kentucky Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={45}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>MD</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Maryland-flag" src={md_flag} />
</div>
</div>
<div>
<a
href="http://mgaleg.maryland.gov/webmga/frm1st.aspx?tab=home"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Maryland">
The state of Maryland
</a>
<span className="text-black-50 d-block">
<a
href="http://mgaleg.maryland.gov/webmga/frm1st.aspx?tab=home"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Maryland">
Maryland Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={40}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>MT</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Montana-flag" src={mt_flag} />
</div>
</div>
<div>
<a
href="https://leg.mt.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Montana">
The state of Montana
</a>
<span className="text-black-50 d-block">
<a
href="https://leg.mt.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Montana">
Montana Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={85}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>NJ</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="New Jersey-flag" src={nj_flag} />
</div>
</div>
<div>
<a
href="https://www.njleg.state.nj.us/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of New Jersey">
The state of New Jersey
</a>
<span className="text-black-50 d-block">
<a
href="https://www.njleg.state.nj.us/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of New Jersey">
New Jersey Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={85}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>PA</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Pennsylvania-flag" src={pa_flag} />
</div>
</div>
<div>
<a
href="https://www.legis.state.pa.us/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Pennsylvania">
The state of Pennsylvania
</a>
<span className="text-black-50 d-block">
<a
href="https://www.legis.state.pa.us/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Pennsylvania">
Pennsylvania" Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={40}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>SC</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="South Carolina-flag" src={sc_flag} />
</div>
</div>
<div>
<a
href="https://www.scstatehouse.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of South Carolina">
The state of South Carolina
</a>
<span className="text-black-50 d-block">
<a
href="https://www.scstatehouse.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of South Carolina">
South Carolina Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={25}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>TN</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="Tennessee-flag" src={tn_flag} />
</div>
</div>
<div>
<a
href="http://www.legislature.state.tn.us/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of Tennessee">
The state of Tennessee
</a>
<span className="text-black-50 d-block">
<a
href="http://www.legislature.state.tn.us/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of Tennessee">
Tennessee Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={45}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
<tr>
<td>WV</td>
<td>
<div className="d-flex align-items-center">
<div className="avatar-icon-wrapper mr-3">
<div className="avatar-icon-sm">
<img alt="West Virginia-flag" src={wv_flag} />
</div>
</div>
<div>
<a
href="http://www.wvlegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="font-weight-bold text-black"
title="The state of West Virginia">
The state of West Virginia
</a>
<span className="text-black-50 d-block">
<a
href="http://www.wvlegislature.gov/"
rel="noopener noreferrer"
target="_blank"
className="text-first ml-3"
title="The state Bar of West Virginia">
West Virginia Legislation &#38; Regulations
</a>
</span>
</div>
</div>
</td>
<td className="text-center">
<div className="badge badge-neutral-primary text-primary px-4">
In progress
</div>
</td>
<td>
<LinearProgress
variant="determinate"
value={85}
className="progress-bar-rounded progress-bar-primary"
/>
</td>
<td className="text-center">
<Button
size="small"
className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</Button>
</td>
</tr>
</tbody>
</Table>
</div>
</CardContent>
<div className="card-footer d-flex justify-content-between">
<Button variant="text" className="btn-outline-primary" size="small">
Export
</Button>
<div>
<Button size="small" color="primary" variant="contained">
See full list
</Button>
</div>
</div>
</Card>





    </>
  );
}
