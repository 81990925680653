import React from 'react';

import { PageTitle } from 'layout-components';
import { Grid } from '@material-ui/core';

import { ExampleWrapperSimple } from 'layout-components';

import ElementsRatings5 from '../../example-components/Slcourses/Ratings5';
import ElementsRatings6 from '../../example-components/Slcourses/Ratings6';

import Tables21 from '../../example-components/Pmdash/Tables21';

import BlocksProfilesSmall12 from '../../example-components/BlocksProfilesSmall/BlocksProfilesSmall12';
import BlocksProfilesSmall14 from '../../example-components/BlocksProfilesSmall/BlocksProfilesSmall14';

import Iframe from 'react-iframe';

export default function Slcourses() {
  return (
    <>
      <PageTitle
        titleHeading="SmartLaw Clinic Courses"
        titleDescription="Browse the Web3SKills SmartLaw course content."
      />

      <Grid item lg={12}>
        <ExampleWrapperSimple>
          <BlocksProfilesSmall14 />
        </ExampleWrapperSimple>
      </Grid>


      <Grid item lg={12}>
        <ExampleWrapperSimple>
          <BlocksProfilesSmall12 />
        </ExampleWrapperSimple>
      </Grid>



        <Grid item md={6}>
          <ExampleWrapperSimple>
            <ElementsRatings6 />
          </ExampleWrapperSimple>
        </Grid>


      <Grid container spacing={6}>
        <Grid item md={12}>
          <ExampleWrapperSimple>
            <Tables21 />
          </ExampleWrapperSimple>
        </Grid>


        <Grid item md={6}>
          <ExampleWrapperSimple>
            <ElementsRatings5 />
          </ExampleWrapperSimple>
        </Grid>


        <Grid item md={6}>
          <ExampleWrapperSimple>
        <Iframe
        class="airtable-embed"
        src="https://airtable.com/embed/shrDz1esRcfmh9fzq?backgroundColor=blue&viewControls=on"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="533"></Iframe>
          </ExampleWrapperSimple>
        </Grid>






      </Grid>
    </>
  );
}
