import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSimple } from 'layout-components';

import WidgetsDragDrop2 from '../../example-components/Redes/DragDrop2';

import FormsClipboard3 from '../../example-components/FormsClipboard/FormsClipboard3';
import FormsValidation3 from '../../example-components/Claimant/FormsValidation3';

import FormsTypeahead21 from '../../example-components/FormsTypeahead/FormsTypeahead21';



export default function Redes() {
  return (
    <>
      <PageTitle
        titleHeading="Build &#38; Recover Access Keys"
        titleDescription="Use the drag and drop features to answer the questions."
      />

      <ExampleWrapperSimple sectionHeading="Set Recovery Phrase">
        <FormsValidation3 />
      </ExampleWrapperSimple>

      <ExampleWrapperSimple sectionHeading="Attestation, Guarantee, Assurance & Promise">
        <FormsClipboard3 />
      </ExampleWrapperSimple>

      <ExampleWrapperSimple sectionHeading="Build a recovery mnemonic phrase to recover private key">
        <FormsTypeahead21 />
      </ExampleWrapperSimple>


      <ExampleWrapperSimple sectionHeading="Private key rebuilder">
        <WidgetsDragDrop2 />
      </ExampleWrapperSimple>

    </>
  );
}
