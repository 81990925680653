import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, Card, Button } from '@material-ui/core';

// import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
// import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
// import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
import LocalPlayTwoToneIcon from '@material-ui/icons/LocalPlayTwoTone';
import LocalPharmacyTwoToneIcon from '@material-ui/icons/LocalPharmacyTwoTone';
import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';



export default function LivePreviewExample() {
  return (
    <>
      <Container className="py-5">
        <div className="mb-5 text-center">
          <h1 className="display-3 text-black mb-2 font-weight-bold">
          Metaverse Pro Plans &#38; Pricing
          </h1>
          <p className="font-size-lg text-black-50">
          <span className="text-primary font-weight-bold font-size-lg opacity-7">
                  {'Review '}
                </span>and select the plan that works best for an introduction into the metaverse.
          </p>
        </div>

        <Grid container spacing={0}>
          <Grid item xl={4} className="mb-5 mb-lg-0 d-flex align-items-center">
            <Card className="text-center p-4 w-100 br-xl-right-0">
              <div className="d-100 rounded-lg bg-night-sky btn-icon mx-auto">
                <RoomTwoToneIcon className="d-70 text-white" />
              </div>
              <div className="divider my-4" />
              <div className="px-3">
                <div className="d-flex align-items-center pb-2">
                  <div className="d-30 rounded-sm btn-icon bg-neutral-success text-success mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-second">Feature 1</div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="d-30 rounded-sm btn-icon bg-neutral-success text-success mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-second">Feature 2</div>
                </div>
                <div className="d-flex align-items-center pt-2">
                  <div className="d-30 rounded-sm btn-icon bg-neutral-success text-success mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-second opacity-6">Feature 3</div>
                </div>
              </div>
              <div className="divider my-4" />
              <div>
                <div className="text-center">
                  <div className="text-uppercase font-size-xl font-weight-bold">
                  Starter Jam
                  </div>
                  <div className="display-3 line-height-1 font-weight-bold my-2">
                    <span className="font-size-sm"></span>475
                  </div>
                  <span className="font-size-sm">MATIC</span>
                </div>
                <Button
                  fullWidth
                  className="btn-primary mt-4 px-3 font-size-sm font-weight-bold text-uppercase rounded shadow-none py-3">
                  <span className="btn-wrapper--label">Purchase Now</span>
                </Button>
              </div>
            </Card>
          </Grid>


          <Grid item xl={4} className="mb-5 mb-lg-0 d-flex align-items-center">
            <Card className="text-center shadow-xxl px-4 bg-night-sky text-second py-5 w-100">
              <div className="d-100 rounded-lg bg-white btn-icon mx-auto">
                <LocalPlayTwoToneIcon className="d-70 text-primary" />
              </div>
              <div className="divider bg-white-10 my-4" />
              <div className="px-3">
                <div className="d-flex align-items-center pb-2">
                  <div className="d-30 rounded-sm btn-icon bg-white-10 text-white mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-white">Feature 1</div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="d-30 rounded-sm btn-icon bg-white-10 text-white mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-white">Feature 2</div>
                </div>
                <div className="d-flex align-items-center pt-2">
                  <div className="d-30 rounded-sm btn-icon bg-white-10 text-white mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-white opacity-6">Feature 3</div>
                </div>
              </div>
              <div className="divider bg-white-10 my-4" />
              <div>
                <div className="text-center text-white">
                  <div className="text-uppercase font-size-xl font-weight-bold">
                  Starter Jam + Lease
                  </div>
                  <div className="display-3 line-height-1 font-weight-bold my-2">
                    2,500
                  </div>
                  <span className="font-size-sm">MATIC</span>
                </div>
                <Button
                  fullWidth
                  className="btn-primary mt-4 px-3 font-size-sm font-weight-bold text-uppercase rounded shadow-none py-3">
                  <span className="btn-wrapper--label">Purchase Now</span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                  </span>
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid item xl={4} className="mb-5 mb-lg-0 d-flex align-items-center">
            <Card className="text-center p-4 w-100 bl-xl-left-0">
              <div className="d-100 rounded-lg bg-night-sky btn-icon mx-auto">
                <LocalPharmacyTwoToneIcon className="d-70 text-white " />
              </div>
              <div className="divider my-4" />
              <div className="px-3">
                <div className="d-flex align-items-center pb-2">
                  <div className="d-30 rounded-sm btn-icon bg-neutral-success text-success mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-second">Feature 1</div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="d-30 rounded-sm btn-icon bg-neutral-success text-success mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-second">Feature 2</div>
                </div>
                <div className="d-flex align-items-center pt-2">
                  <div className="d-30 rounded-sm btn-icon bg-neutral-success text-success mr-3">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="font-size-xs"
                    />
                  </div>
                  <div className="text-second opacity-6">Feature 3</div>
                </div>
              </div>
              <div className="divider my-4" />
              <div>
                <div className="text-center">
                  <div className="text-uppercase font-size-xl font-weight-bold">
                  Web3 Profiles &#38; Achievements
                  </div>
                  <div className="display-3 line-height-1 font-weight-bold my-2">
                    7,600
                  </div>
                  <span className="font-size-sm">MATIC</span>
                </div>
                <Button
                  fullWidth
                  className="btn-primary mt-4 px-3 font-size-sm font-weight-bold text-uppercase rounded shadow-none py-3">
                  <span className="btn-wrapper--label">Purchase Now</span>
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
