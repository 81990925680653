import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  InputAdornment,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem,
  TextField
} from '@material-ui/core';

import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

export default function LivePreviewExample() {
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  return (
    <>





<Grid container spacing={6}>
<Grid item xl={6} className="d-flex">
<Card className="card-box w-100">
<div className="card-header">
<div className="card-header--title">
<small className="d-block text-uppercase mt-1">Legal Engineering Certification</small>
<b>The Emotional Path to Web3 Legal Engineering Certification</b>
</div>
<div>
<Button className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
<FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
</Button>
</div>
</div>
<List component="div" className="list-group-flush">
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/Qmba8cQzriZRxvN8wNJjmpNUeE1v15NR69vcHQzMYRqMk9"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 1 - Excitement
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={4}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Believe you can do it!
</div>
<div className="opacity-5">
96<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmaiineHGWcQVQm1esbbMmey6covKMyKND7G6RdAVMSZgg"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 2 - Confidence
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={8}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Legal Hackers Unite
</div>
<div className="opacity-5">
92<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmWHdiHQHS9PPj5HCVwpe3xo1v3cLizcFb4H4xczNyDJYh"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 3 - Creativity
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={12}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Let’s be crazy together.
</div>
<div className="opacity-5">
88<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmWHdiHQHS9PPj5HCVwpe3xo1v3cLizcFb4H4xczNyDJYh"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 4 - Obsession
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={16}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
We are the Law
</div>
<div className="opacity-5">
84<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmRVdLGQCUCNYVUryEpEzRjL31oAhaMXjKyKkfYZLTQizd"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 5 - Delusion
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={20}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Put some leaves on that little rascal.
</div>
<div className="opacity-5">
80<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmbF3TcKvJjZT8r9MKLpRhBwL1YJkm7eZC6bahHdgLG2BE"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 6 - Confusion
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={24}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
It’s math.
</div>
<div className="opacity-5">
76<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmcTfk6FYyqJTW4huy3Jz6ascMf1tMg5BxkjKAeKDFTRw7"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 7 - Mania
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={28}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
So many rabbit holes.
</div>
<div className="opacity-5">
72<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmdG6JQTYwSVBLeWQchD7J36cgEnxCS1Y3D1f5WLzd2zvq"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 8 – Being Lost
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={32}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Easy to get lost when travelling fast.
</div>
<div className="opacity-5">
68<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmZfvWaeaWgNBnDxiDasdy5tEJk18uX7BJGHjuWfPyfxVb"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 9 - Defeat
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={36}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Diminishing returns.
</div>
<div className="opacity-5">
64<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmQiXf3QdnGitKY5Zi4RByQYSbcgAWpzFPhCPMqnNUr6yh"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 10 - Disbelief
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={40}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
We can make it worse.
</div>
<div className="opacity-5">
60<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmTmAGNxyUsnNJcQXp97ghoqyTCsX5JmZwTL6vQHXLDVBf"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 11 - Bewilderment
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={44}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Frozen in place.
</div>
<div className="opacity-5">
56<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmZQQRZEjM3e9odbbhW5wUEXPWE1AjC4JBCv9noC6j8GPt"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 12 - Shock
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={48}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
In to the fire.
</div>
<div className="opacity-5">
52<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmWvgQbUwFzdccAF5y5R4sHVNkTseFQpyAeugbkwoEfHJx"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 13 - Denial
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={52}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
It’s fine in here. Stand the heat.
</div>
<div className="opacity-5">
48<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmXro3eK7BvuSQp1WYtvx3PeDZMqS6MozvkMy5T3sUz2Uv"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 14 - Outrage
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={56}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
True colors eventually show.
</div>
<div className="opacity-5">
44<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmULtL3BUVDw7DKBgnLCEmScCnd3XSaeZF9J1bf1F2z6GJ"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 15 - Annoyance
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={60}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Can’t please everyone.
</div>
<div className="opacity-5">
40<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmQHhV2apGNNEC15bA3VSTxYVwf5fDj4d38p1pdv1zr7yM"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 16 - Regret
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={64}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
It’s easy to go to far.
</div>
<div className="opacity-5">
36<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmR2uj553UVjMsRaALpsVomzXCu7rFFGm1ZAAJYqtYADFU"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 17 - Bargaining
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={68}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Permission vs foregiveness
</div>
<div className="opacity-5">
32<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmXgyLTbuPVP15TYdiZZjiYyq7v5YEbuJc4cyguiBnKs1k"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 18 - Fear
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={72}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
The villagers may come for you
</div>
<div className="opacity-5">
28<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmQAomTjZxWZtvBq6Tw2P1Z6dr3DyHR25JkjX6i8aMiGaY"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 19 - Anger
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={76}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Sometimes we can’t back down
</div>
<div className="opacity-5">
24<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmaSQhvW1ZJMViSzqPDfE1m5M39yCR5DUdjNcPk6gjN9FF"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 20 - Resolution
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={80}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Sometimes it feel like it’s over.
</div>
<div className="opacity-5">
20<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmXvJF5sYd1fmrqHRQW4yYWEzJUG14TxiDb68QyxZdfrV8"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 21 - Depression
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={84}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Other times it’s hard to go on.
</div>
<div className="opacity-5">
16<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmXajPzMgxCzDq3dG35hBsVb3DHfp1gcE8pDFJcxbRkNR6"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 22 - Remorse
</div>
<LinearProgress
variant="determinate"
className="progress-xs my-2 progress-bar-success"
value={88}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
All those moments will be lost in time.
</div>
<div className="opacity-5">
12<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmTXRJ9fBqm6BnAhgs1E3k96VtJsF7r8krfoS9kchzhkYZ"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 23 - Exhilaration
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={92}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
The suspense is terrible. I hope it will last.
</div>
<div className="opacity-5">
8<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmTKFDGfZgExz6HcDGauvVhNnHhBmjQVZeKgPDuYfmnvJw"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold text-black">
Stage 24 - Acceptance
</div>
<LinearProgress
variant="determinate"
className="progress-xs progress-animated-alt my-2 progress-bar-success"
value={96}
/>
<div className="d-flex justify-content-between font-size-xs">
<div className="text-primary">
Everything in its right place.
</div>
<div className="opacity-5">
4<sup>%</sup> to go
</div>
</div>
</div>
</ListItem>
</List>
</Card>
</Grid>


<Grid item xl={6} className="d-flex">
<Card className="card-box w-100">
<div className="card-header">
<div className="card-header--title">
<small className="d-block text-uppercase mt-1">
Web3Skills SmartLaw
</small>
<b>DAO Emotional Journey Discussions</b>
</div>
<div className="avatar-icon-wrapper avatar-initials shadow-none d-40 mr-0">
<div className="avatar-icon text-white bg-night-sky d-40 font-size-sm">
SS
</div>
<div
className="badge badge-warning badge-position badge-position--bottom-center badge-circle-inner"
title="Badge bottom center">
Online
</div>
</div>
</div>
<div
className={clsx(
'd-flex transition-base align-items-center justify-content-between py-2 px-4',
{ 'bg-secondary': !inputBg }
)}>
<div>
<Button
size="small"
className="btn-link p-0 btn-transition-none btn-link-warning">
<span className="btn-wrapper--label font-size-sm">
Web3 Legal Engineering Certification
</span>
</Button>
</div>
<div className="font-size-sm font-weight-bold">
Supervisor: Scott Stevenson
</div>
</div>
<div className="divider" />
<div
className={clsx(
'd-flex align-items-center transition-base px-4 py-1',
{ 'py-3 bg-secondary': inputBg }
)}>
<div className="search-wrapper w-100">
<TextField
variant="outlined"
size="small"
className="bg-white w-100"
classes={{ root: 'input-border-0' }}
id="input-with-icon-textfield225-1"
placeholder="Search Web3 Legal Engineering …"
onFocus={toggleInputBg}
onBlur={toggleInputBg}
InputProps={{
startAdornment: (
<InputAdornment position="start">
<SearchTwoToneIcon />
</InputAdornment>
)
}}
/>
</div>
</div>
<div className="divider" />
<List component="div" className="list-group-flush">
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/Qmba8cQzriZRxvN8wNJjmpNUeE1v15NR69vcHQzMYRqMk9"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 1 - Excitement
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 1
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmaiineHGWcQVQm1esbbMmey6covKMyKND7G6RdAVMSZgg"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 2 - Confidence
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 2
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmWHdiHQHS9PPj5HCVwpe3xo1v3cLizcFb4H4xczNyDJYh"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 3 - Creativity
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 3
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmYx9AAQUcztjjfnuG6RiYdkggkLGFYc829rsQ5aGY7FXB"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 4 - Obsession
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 4
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmRVdLGQCUCNYVUryEpEzRjL31oAhaMXjKyKkfYZLTQizd"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 5 - Delusion
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 5
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmbF3TcKvJjZT8r9MKLpRhBwL1YJkm7eZC6bahHdgLG2BE"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 6 - Confusion
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 6
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmcTfk6FYyqJTW4huy3Jz6ascMf1tMg5BxkjKAeKDFTRw7"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 7 - Mania
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 7
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmdG6JQTYwSVBLeWQchD7J36cgEnxCS1Y3D1f5WLzd2zvq"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 8 – Being Lost
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 8
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmZfvWaeaWgNBnDxiDasdy5tEJk18uX7BJGHjuWfPyfxVb"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 9 - Defeat
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 9
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmQiXf3QdnGitKY5Zi4RByQYSbcgAWpzFPhCPMqnNUr6yh"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 10 - Disbelief
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 10
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmTmAGNxyUsnNJcQXp97ghoqyTCsX5JmZwTL6vQHXLDVBf"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 11 - Bewilderment
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 11
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmZQQRZEjM3e9odbbhW5wUEXPWE1AjC4JBCv9noC6j8GPt"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 12 - Shock
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 12
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmWvgQbUwFzdccAF5y5R4sHVNkTseFQpyAeugbkwoEfHJx"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 13 - Denial
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 13
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmXro3eK7BvuSQp1WYtvx3PeDZMqS6MozvkMy5T3sUz2Uv"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 14 - Outrage
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 14
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmULtL3BUVDw7DKBgnLCEmScCnd3XSaeZF9J1bf1F2z6GJ"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 15 - Annoyance
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 15
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmQHhV2apGNNEC15bA3VSTxYVwf5fDj4d38p1pdv1zr7yM"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 16 - Regret
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 16
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmR2uj553UVjMsRaALpsVomzXCu7rFFGm1ZAAJYqtYADFU"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 17 – Bargaining
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 17
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmXgyLTbuPVP15TYdiZZjiYyq7v5YEbuJc4cyguiBnKs1k"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 18 - Fear
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 18
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmQAomTjZxWZtvBq6Tw2P1Z6dr3DyHR25JkjX6i8aMiGaY"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 19 - Anger
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 19
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmaSQhvW1ZJMViSzqPDfE1m5M39yCR5DUdjNcPk6gjN9FF"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 20 - Resolution
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 20
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmXvJF5sYd1fmrqHRQW4yYWEzJUG14TxiDb68QyxZdfrV8"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 21 - Depression
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 21
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmXajPzMgxCzDq3dG35hBsVb3DHfp1gcE8pDFJcxbRkNR6"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 22 - Remorse
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 22
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmTXRJ9fBqm6BnAhgs1E3k96VtJsF7r8krfoS9kchzhkYZ"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 23 - Exhilaration
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 23
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
<ListItem
component="a"
button
href="https://lexdao-history.freeweb3.com/"
disableRipple
target="_blank"
rel="noopener noreferrer"
className="d-flex bg-white hover-scale-rounded align-items-center py-3">
<div className="avatar-icon-wrapper avatar-initials d-100 mr-3">
<div className="text-center py-1">
<div className="rounded-circle text-white d-100">
<img
className="d-100 rounded-lg"
alt="web3-journey"
src="https://freeweb3.infura-ipfs.io/ipfs/QmTKFDGfZgExz6HcDGauvVhNnHhBmjQVZeKgPDuYfmnvJw"
/>
</div>
</div>
</div>
<div className="flex-fill">
<div className="font-weight-bold font-size-sm text-black">
<div className="badge badge-first badge-circle border-white border-1 mr-2">
Completed
</div>
Stage 24 - Acceptance
</div>
<div className="d-flex align-items-center font-size-xs">
<div className="text-primary">
Contribute to a LexAutomatico journey discussion 24
</div>
</div>
<div className="d-flex justify-content-between font-size-xs">
</div>
</div>
<Button size="small" className="btn-neutral-dark px-3">
<span className="btn-wrapper—icon ml-2 mr-2">
<FontAwesomeIcon icon={['fas', 'landmark']} />
</span>
<span className="btn-wrapper—label mr-2">Join</span>
</Button>
</ListItem>
</List>
</Card>
</Grid>

</Grid>




    </>
  );
}
