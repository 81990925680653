import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';

import milestone1 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-004.png';
import milestone2 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-005.png';
import milestone3 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-006.png';
import milestone4 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-007.png';
import milestone5 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-008.png';
import milestone6 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-009.png';
import milestone7 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-020.png';
import milestone8 from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-047.png';

export default function LivePreviewExample() {
  return (
    <>
      <div className="pt-3 pb-1">
        <div className="timeline-list timeline-list-horizontal">
          <ul className="d-flex justify-content-center flex-wrap">
            <li className="timeline-item timeline-item-icon">
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'fire']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                Web3Skills Milestone 21
                </h4>
                <p>
                Web3Skills presents a framework to onboard legacy system users to the new internet.21.
                </p>
                <div className="mt-3">
                                              <a 
                            href="#/" 
                            target="_blank" 
rel="noopener noreferrer"

                            >
                    <img
                      alt="..."
                      className="img-fluid rounded mr-3 shadow-sm"
                      src={milestone1}
                      width="70"
                    />
                  </a>
                                              <a 
                            href="#/" 
                            target="_blank" 
rel="noopener noreferrer"

                            >
                    <img
                      alt="..."
                      className="img-fluid rounded shadow-sm"
                      src={milestone2}
                      width="70"
                    />
                  </a>
                </div>
              </div>
            </li>
            <li className="timeline-item timeline-item-icon">
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'gas-pump']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                Web3Skills Milestone 22
                </h4>
                <p>Web3Skills presents a framework to onboard legacy system users to the new internet.22.</p>
                <div className="mt-3">
                                              <a 
                            href="#/" 
                            target="_blank" 
rel="noopener noreferrer"

                            >
                    <img
                      alt="..."
                      className="img-fluid rounded mr-3 shadow-sm"
                      src={milestone3}
                      width="70"
                    />
                  </a>
                                              <a 
                            href="#/" 
                            target="_blank" 
rel="noopener noreferrer"

                            >
                    <img
                      alt="..."
                      className="img-fluid rounded shadow-sm"
                      src={milestone4}
                      width="70"
                    />
                  </a>
                </div>
              </div>
            </li>
            <li className="timeline-item timeline-item-icon">
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'fingerprint']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                Web3Skills Milestone 23
                </h4>
                <p>Web3Skills presents a framework to onboard legacy system users to the new internet.23.</p>
                <div className="mt-3">
                                              <a 
                            href="#/" 
                            target="_blank" 
rel="noopener noreferrer"

                            >
                    <img
                      alt="..."
                      className="img-fluid rounded mr-3 shadow-sm"
                      src={milestone5}
                      width="70"
                    />
                  </a>
                                              <a 
                            href="#/" 
                            target="_blank" 
rel="noopener noreferrer"

                            >
                    <img
                      alt="..."
                      className="img-fluid rounded shadow-sm"
                      src={milestone6}
                      width="70"
                    />
                  </a>
                </div>
              </div>
            </li>
            <li className="timeline-item timeline-item-icon">
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-primary text-white">
                  <FontAwesomeIcon icon={['fas', 'plug']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                Web3Skills Milestone 24
                </h4>
                <p>Web3Skills presents a framework to onboard legacy system users to the new internet.24.</p>
                <div className="mt-3">
                                              <a 
                            href="#/" 
                            target="_blank" 
rel="noopener noreferrer"

                            >
                    <img
                      alt="..."
                      className="img-fluid rounded mr-3 shadow-sm"
                      src={milestone7}
                      width="70"
                    />
                  </a>
                                              <a 
                            href="#/" 
                            target="_blank" 
rel="noopener noreferrer"

                            >
                    <img
                      alt="..."
                      className="img-fluid rounded shadow-sm"
                      src={milestone8}
                      width="70"
                    />
                  </a>
                </div>
              </div>
            </li>
            <li className="timeline-item timeline-item-icon">
              <div className="timeline-item--content">
                <div className="timeline-item--icon-wrapper bg-first text-white">
                  <FontAwesomeIcon icon={['fas', 'landmark']} />
                </div>
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                Web3Skills Milestone 25
                </h4>
                <p>
                Web3Skills presents a framework to onboard legacy system users to the new internet.25.
                </p>
                <div className="mt-2">
                  <Button size="small" className="btn-first">
                    Get more info about Web3Skills SmartLaw
                  </Button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
