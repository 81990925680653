import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  // Grid,
  Container,
  Button,
  List,
  ListItem
} from '@material-ui/core';

// import { FlagIcon } from 'react-flag-kit';
import projectLogo from '../../../assets/images/logos/color-wheel.svg';

// import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
// import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

// import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
// import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
// import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
// import GridOnTwoToneIcon from '@material-ui/icons/GridOnTwoTone';

export default function LivePreviewExample() {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  return (
    <>
      <Container className="pt-3">
        <div className="header-nav-wrapper h-auto my-3 py-3 rounded navbar-dark">
          <div className="app-nav-logo flex-grow-0 mr-3">
            <a
              href="#/"
              target="_blank" 
rel="noopener noreferrer"

              title="web3skills  Dashboard "
              className="app-nav-logo">
              <div className="app-nav-logo--icon rounded-circle">
                <img
                  alt="web3skills  Dashboard "
                  src={projectLogo}
                />
              </div>
              <div className="app-nav-logo--text">
                <span>LegalTech</span>
                <b>Web3Skills SmartLaw</b>
              </div>
            </a>
          </div>
          <div className="header-nav-menu flex-grow-0 ml-auto d-none d-lg-block">
          </div>
          <div className="header-nav-actions ml-auto ml-xl-4 flex-grow-0">
            <span className="d-none d-lg-block">
              <Button
                href="#/"
                target="_blank" 
rel="noopener noreferrer"

                className="btn-pill font-weight-bold text-nowrap font-size-sm hover-scale-sm shadow-second-sm btn-first">
                Connect to Web3
              </Button>
            </span>
            <span className="d-block d-lg-none">
              <button
                onClick={toggle}
                className={clsx('navbar-toggler hamburger hamburger--elastic', {
                  'is-active': collapse
                })}>
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </span>
          </div>
          <div className="d-flex d-lg-none">
            <Collapse
              in={collapse}
              className="nav-collapsed-wrapper navbar-collapse">
              <div className="nav-inner-wrapper">
                <Button
                  onClick={toggle}
                  className="btn-danger btn-icon d-40 shadow-sm hover-scale-lg btn-animated-icon-sm nav-toggle-inner-btn p-0">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'times']} />
                  </span>
                </Button>

                <List
                  component="div"
                  className="nav-pills nav-neutral-primary nav-pills-rounded nav-lg flex-column p-3">
                  <ListItem
                    component="a"
                    button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    className="px-4 d-flex align-items-center">
                    <span>Courses</span>
                    <FontAwesomeIcon
                      icon={['fas', 'angle-right']}
                      className="opacity-6 ml-auto"
                    />
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    className="px-4 d-flex align-items-center">
                    <span>Listings</span>
                    <FontAwesomeIcon
                      icon={['fas', 'angle-right']}
                      className="opacity-6 ml-auto"
                    />
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    className="px-4 d-flex align-items-center">
                    <span>Workplace</span>
                    <FontAwesomeIcon
                      icon={['fas', 'angle-right']}
                      className="opacity-6 ml-auto"
                    />
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    className="px-4 d-flex align-items-center">
                    <span>Software</span>
                    <FontAwesomeIcon
                      icon={['fas', 'angle-right']}
                      className="opacity-6 ml-auto"
                    />
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    className="px-4 d-flex align-items-center">
                    <span>Exchanges</span>
                    <FontAwesomeIcon
                      icon={['fas', 'angle-right']}
                      className="opacity-6 ml-auto"
                    />
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    className="px-4 d-flex align-items-center">
                    <span>Services</span>
                    <FontAwesomeIcon
                      icon={['fas', 'angle-right']}
                      className="opacity-6 ml-auto"
                    />
                  </ListItem>
                </List>
              </div>
            </Collapse>
          </div>
        </div>
      </Container>
      <div
        className={clsx('collapse-page-trigger', { 'is-active': collapse })}
        onClick={toggle}
      />
    </>
  );
}
