import React, { useState } from 'react';

import {
  Grid,
  Container,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Card,
  Button,
  TextField
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';


import hero1 from '../../../assets/images/hero-bg/hero-1.jpg';
import userseth from '../../../assets/images/stock-photos/users-eth-400w.png';




// import MarketingHeaders5 from '../../MarketingHeaders/MarketingHeaders5';
import MarketingHeaders1 from '../../MarketingHeaders/MarketingHeaders1';

export default function LivePreviewExample() {
  const [checked1, setChecked1] = useState(true);

  const handleChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-midnight-bloom">
        <Container className="header-top-section py-2">
          <MarketingHeaders1 />
        </Container>
        <div className="divider bg-white-5" />
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image"
            style={{ backgroundImage: 'url(' + hero1 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
          <div className="bg-composed-wrapper--content">
            <Container className="z-over">
              <Container className="text-white py-5">
                <Grid container spacing={6} className="pb-5">
                  <Grid
                    item
                    lg={6}
                    md={12}
                    className="d-flex align-items-center pr-0 pr-xl-3">

                    <div className="text-center text-lg-left">

                    <a
                            href="https://users.freeweb3.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="card-img-wrapper rounded">
                            <div className="img-wrapper-overlay">
                              <div className="overlay-btn-wrapper">
                                <div className="rounded-circle d-40 mx-auto d-flex align-items-center justify-content-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'plus-circle']}
                                    className="font-size-lg"
                                  />
                                </div>
                              </div>
                            </div>
                            <img
                              alt="..."
                              className="card-img-top rounded"
                              src={userseth}
                              style={{ width: 400 }}
                            />
                         </a>





                      <h2 className="display-3 font-weight-bold text-white">
                        Login the way you want
                      </h2>
                      <p className="font-size-xl py-3 text-white-50">
                        Web3Skills SmartLaw is a training tool and set of
                        protocols to advance the adoption of Web3 technologies.
                        Users can freely browse and use Web3 applications
                        anonymously or with decentralized profiles that capture
                        the spirit of the user.
                      </p>
                      <div className="pt-3">
                        <Button
                          href="https://users.freeweb3.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          size="large"
                          className="btn-pill shadow-second-sm btn-first">
                          <span className="btn-wrapper--label">
                            Get a Web3 Account for free
                          </span>
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                          </span>
                        </Button>
                        <Button
                          href="https://users.freeweb3.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          size="large"
                          className="bg-white-10 text-white btn-pill ml-3">
                          <span>Documentation</span>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={12}
                    className="d-flex align-items-center justify-content-center">
                    <Card className="rounded-sm shadow-none mt-5 mt-lg-0 p-1 bg-white-10">
                      <Card className="rounded-sm font-size-sm p-1">
                        <div className="text-center py-4 rounded bg-secondary my-4">
                          <Button
                            className="m-2 btn-pill px-4 font-weight-bold btn-facebook"
                            size="small">
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={['fab', 'ethereum']} />
                            </span>
                            <span className="btn-wrapper--label">
                              Login with Web3
                            </span>
                          </Button>
                          <Button
                            className="m-2 btn-pill px-4 font-weight-bold btn-google"
                            size="small">
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={['fab', 'google']} />
                            </span>
                            <span className="btn-wrapper--label">
                              Login with Google
                            </span>
                          </Button>
                          <Button
                            className="m-2 btn-pill px-4 font-weight-bold btn-twitter"
                            size="small">
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={['fab', 'twitter']} />
                            </span>
                            <span className="btn-wrapper--label">
                              Login with Twitter
                            </span>
                          </Button>
                        </div>

                        <div className="py-1">
                          <div className="text-center py-1 text-primary mb-4">
                            or sign in with credentials
                          </div>
                          <div>

                          <div className="mb-3">
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-web3-address"
                                label="Web3 address 0x+40_characters....."
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FingerprintIcon />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-email"
                                label="Email address (Not Required for Web3 Login)"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailOutlineTwoToneIcon />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-password"
                                label="Password (Not Required for Web3 Login)"
                                type="password"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LockTwoToneIcon />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center font-size-md">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checked1}
                                    onChange={handleChange1}
                                    value="checked1"
                                    color="primary"
                                  />
                                }
                                label="Remember me"
                              />
                              <div className= "mr-2">
                                <a
                                  href="#/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-first">
                                  Recover password
                                </a>
                              </div>
                            </div>
                            <div className="text-center py-4">
                              <Button className="btn-first font-weight-bold w-50 my-2">
                                Sign in
                              </Button>
                            </div>
                            <div className="text-center text-black-50 mt-3">
                              Don't have an account?{' '}
                              <a
                                href="#/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-first">
                                Sign up
                              </a>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </Container>
            <div
              className="shape-container-top-1 z-below"
              style={{ marginTop: '-320px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="var(--light)"
                  fillOpacity="1"
                  d="M0,224L288,192L576,224L864,256L1152,128L1440,160L1440,320L1152,320L864,320L576,320L288,320L0,320Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
