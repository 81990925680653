import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, Grid, Container } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import SidebarUserbox from '../SidebarUserbox';

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import CollectionsTwoToneIcon from '@material-ui/icons/CollectionsTwoTone';
import LinkTwoToneIcon from '@material-ui/icons/LinkTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import CameraTwoToneIcon from '@material-ui/icons/CameraTwoTone';
import LocalPlayTwoToneIcon from '@material-ui/icons/LocalPlayTwoTone';
import MemoryTwoToneIcon from '@material-ui/icons/MemoryTwoTone';
import PublicTwoToneIcon from '@material-ui/icons/PublicTwoTone';
import GavelIcon from '@material-ui/icons/Gavel';
import LanguageIcon from '@material-ui/icons/Language';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import MoveToInboxTwoToneIcon from '@material-ui/icons/MoveToInboxTwoTone';
// import LocalGroceryStoreTwoToneIcon from '@material-ui/icons/LocalGroceryStoreTwoTone';
// import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
// import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
// import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';
// import CameraAltTwoToneIcon from '@material-ui/icons/CameraAltTwoTone';
// import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone';
// import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';
// import SecurityTwoToneIcon from '@material-ui/icons/SecurityTwoTone';
// import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
// import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
// import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
// import EvStationTwoToneIcon from '@material-ui/icons/EvStationTwoTone';
// import Filter1TwoToneIcon from '@material-ui/icons/Filter1TwoTone';
// import Filter2TwoToneIcon from '@material-ui/icons/Filter2TwoTone';
// import Filter3TwoToneIcon from '@material-ui/icons/Filter3TwoTone';
// import Filter4TwoToneIcon from '@material-ui/icons/Filter4TwoTone';
// import Filter5TwoToneIcon from '@material-ui/icons/Filter5TwoTone';
// import Filter6TwoToneIcon from '@material-ui/icons/Filter6TwoTone';
// import Filter7TwoToneIcon from '@material-ui/icons/Filter7TwoTone';
// import Filter8TwoToneIcon from '@material-ui/icons/Filter8TwoTone';
// import Filter9TwoToneIcon from '@material-ui/icons/Filter9TwoTone';
// import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';
// import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
// import NewReleasesTwoToneIcon from '@material-ui/icons/NewReleasesTwoTone';
// import PaletteTwoToneIcon from '@material-ui/icons/PaletteTwoTone';
// import PolicyTwoToneIcon from '@material-ui/icons/PolicyTwoTone';
// import RedeemTwoToneIcon from '@material-ui/icons/RedeemTwoTone';
// import WbSunnyTwoToneIcon from '@material-ui/icons/WbSunnyTwoTone';
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import AdjustIcon from '@material-ui/icons/Adjust';
// import AlbumIcon from '@material-ui/icons/Album';
// import ChildCareIcon from '@material-ui/icons/ChildCare';
// import ColorLensIcon from '@material-ui/icons/ColorLens';
// import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
// import EvStationIcon from '@material-ui/icons/EvStation';
// import ExtensionIcon from '@material-ui/icons/Extension';
// import FilterIcon from '@material-ui/icons/Filter';
// import Filter1Icon from '@material-ui/icons/Filter1';
// import Filter2Icon from '@material-ui/icons/Filter2';
// import Filter3Icon from '@material-ui/icons/Filter3';
// import Filter4Icon from '@material-ui/icons/Filter4';
// import Filter5Icon from '@material-ui/icons/Filter5';
// import Filter6Icon from '@material-ui/icons/Filter6';
// import Filter7Icon from '@material-ui/icons/Filter7';
// import Filter8Icon from '@material-ui/icons/Filter8';
// import Filter9Icon from '@material-ui/icons/Filter9';
// import FireplaceIcon from '@material-ui/icons/Fireplace';
// import FlareIcon from '@material-ui/icons/Flare';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
// import LocalPlayIcon from '@material-ui/icons/LocalPlay';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// import PermMediaIcon from '@material-ui/icons/PermMedia';
// import PolicyIcon from '@material-ui/icons/Policy';
// import SecurityIcon from '@material-ui/icons/Security';
// import TuneIcon from '@material-ui/icons/Tune';
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox } = props;
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);
  const [dashboardOpen, setDashboardOpen] = useState(false);
  const toggleDashboard = (event) => {
    setDashboardOpen(!dashboardOpen);
    event.preventDefault();
  };

  const [elementsOpen, setElementsOpen] = useState(false);
  const toggleElements = (event) => {
    setElementsOpen(!elementsOpen);
    event.preventDefault();
  };

  const [applicationOpen, setApplicationOpen] = useState(false);
  const toggleApplication = (event) => {
    setApplicationOpen(!applicationOpen);
    event.preventDefault();
  };

  const [metaverseOpen, setMetaverseOpen] = useState(false);
  const toggleMetaverse = (event) => {
    setMetaverseOpen(!metaverseOpen);
    event.preventDefault();
  };

  const [designSystemOpen, setDesignSystemOpen] = useState(false);
  const toggleDesignSystem = (event) => {
    setDesignSystemOpen(!designSystemOpen);
    event.preventDefault();
  };
  const [blocksOpen, setBlocksOpen] = useState(false);
  const toggleBlocks = (event) => {
    setBlocksOpen(!blocksOpen);
    event.preventDefault();
  };
  const [widgetsOpen, setWidgetsOpen] = useState(false);
  const toggleWidgets = (event) => {
    setWidgetsOpen(!widgetsOpen);
    event.preventDefault();
  };

  const [chartsOpen, setChartsOpen] = useState(false);
  const toggleCharts = (event) => {
    setChartsOpen(!chartsOpen);
    event.preventDefault();
  };
  const [uiKitComponentsOpen, setUiKitComponents] = useState(false);
  const toggleUiKitComponents = (event) => {
    setUiKitComponents(!uiKitComponentsOpen);
    event.preventDefault();
  };
  const [formsComponentsOpen, setFormsComponents] = useState(false);
  const toggleFormsComponents = (event) => {
    setFormsComponents(!formsComponentsOpen);
    event.preventDefault();
  };
  // const [formsComponentsOpen1, setFormsComponents1] = useState(false);
  // const toggleFormsComponents1 = (event) => {
  //   setFormsComponents1(!formsComponentsOpen1);
  //   event.preventDefault();
  // };


  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox && <SidebarUserbox />}
        <div className="sidebar-navigation">
          <div className="sidebar-header text-white">
            <span>Core Applications</span>
          </div>
          <ul>

            <li>
              <a
                href="#/"
                onClick={toggleApplication}
                className={clsx({ active: applicationOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <GavelIcon />
                </span>
                <span className="sidebar-item-label">
                  SmartLaw Evidencer
                  <div className="badge badge-success rounded-circle text-white bg-serious-blue mr-4">
                    2
                  </div>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>

              <Collapse in={applicationOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Forensics">
                      <span className="sidebar-item-label">
                        Forensic Image Mgmt.
                        <div className="badge badge-success rounded-circle text-white bg-serious-blue">
                          2
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://web3vm.com/#magicEncryption"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        AES-256 Encryption
                        <div className="badge badge-primary bg-night-sky ">
                          ws
                        </div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://web3vm.com/#ipfseth"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        IPFS Content Storage
                        <div className="badge badge-primary bg-night-sky">
                          ws
                        </div>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://evidencer.freeweb3.com/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Evidencer
                        <div className="badge badge-primary bg-night-sky">
                          ws
                        </div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://evidencemanager.freeweb3.com/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        WorldLaw
                        <div className="badge badge-primary bg-night-sky">
                          ws
                        </div>
                      </span>
                    </a>
                  </li>

                </ul>
              </Collapse>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleFormsComponents}
                className={clsx({ active: formsComponentsOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <LocalPlayTwoToneIcon />
                </span>
                <span className="sidebar-item-label">
                  NFT Minting Store
                  <div className="badge badge-info rounded-circle text-white bg-nice-redora-darker mr-4">
                    4
                  </div>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={formsComponentsOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Mint">
                      <span className="sidebar-item-label">
                        Token Minting Tour
                        <div className="badge badge-info text-white bg-nice-redora-darker">
                          1
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Metadata">
                      <span className="sidebar-item-label">
                        Prepare Metadata
                        <div className="badge badge-info text-white bg-nice-redora-darker">
                          2
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Process">
                      <span className="sidebar-item-label">
                        Processing Transactions
                        <div className="badge badge-info text-white bg-nice-redora-darker">
                          3
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://tokens.freeweb3.com"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Token Minter &#40;ws&#41;
                        <div className="badge badge-info text-white bg-nice-redora-darker">
                          4
                        </div>
                      </span>
                    </a>
                  </li>

                </ul>
              </Collapse>
            </li>
          </ul>

          <div className="sidebar-header text-white">
            <span>Web3 Working Groups</span>
          </div>
          <ul>
            <li>
              <a
                href="#/"
                onClick={toggleElements}
                className={clsx({ active: elementsOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <CollectionsTwoToneIcon />
                </span>
                <span className="sidebar-item-label">
                  LexAutomatico
                  <span className="sidebar-item-label">
                    <div className="badge badge-light rounded-circle text-white bg-sunrise-purple mr-4">
                      5
                    </div>
                  </span>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={elementsOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Autosignup">
                      <span className="sidebar-item-label">
                        ABA Chapters
                        <div className="badge badge-light text-white">1</div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Nav">
                      <span className="sidebar-item-label">
                        Navigating Web3
                        <div className="badge badge-light text-white">2</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Slcourses">
                      <span className="sidebar-item-label">
                        SmartLaw Courses
                        <div className="badge badge-light text-white">3</div>
                      </span>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink
                    onClick={toggleSidebarMobile}
                    to="/Grmake">
                    Achievements Progress
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Achengine">
                      <span className="sidebar-item-label">
                        Achievement Artifacts
                        <div className="badge badge-light text-white">7</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Lexag">
                      <span className="sidebar-item-label">
                        Lex LegalTech
                        <div className="badge badge-light text-white">6</div>
                      </span>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/MarketingPartners">
                      <span className="sidebar-item-label">
                        LexQ Achievements
                        <div className="badge badge-light text-white">7</div>
                      </span>
                    </NavLink>
                  </li>
                   */}
                </ul>
              </Collapse>
            </li>

            {/* <li>
              <a
                href="#/"
                onClick={toggleFormsComponents1}
                className={clsx({ active: formsComponentsOpen1 })}>
                <span className="sidebar-icon-light mr-3">
                  <CameraTwoToneIcon />
                </span>
                <span className="sidebar-item-label">
                  Grant Management
                  <div className="badge badge-light rounded-circle text-white bg-sunrise-purple mr-4">
                    6
                  </div>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={formsComponentsOpen1}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Grwalk">
                      <span className="sidebar-item-label">
                        Grants Walkthrough
                        <div className="badge badge-light text-white">1</div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Grmake">
                      <span className="sidebar-item-label">
                        Grant Proposal Maker
                        <div className="badge badge-light text-white">2</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Grmachine">
                      <span className="sidebar-item-label">
                        Open Grants Report
                        <div className="badge badge-light text-white">3</div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/FormsStepperScoring">
                      <span className="sidebar-item-label">
                        Proposal Scoring System
                        <div className="badge badge-light text-white">4</div>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li> */}

            <li>
              <a
                href="#/"
                onClick={toggleBlocks}
                className={clsx({ active: blocksOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <MemoryTwoToneIcon />
                </span>
                <span className="sidebar-item-label">
                  SmartLaw Tools
                  <span className="sidebar-item-label">
                    <div className="badge badge-light rounded-circle text-white bg-sunrise-purple mr-4">
                      7
                    </div>
                  </span>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={blocksOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Statereg">
                      <span className="sidebar-item-label">
                        State Regulations USA
                        <div className="badge badge-light text-white">2</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Fedreg">
                      <span className="sidebar-item-label">
                        Fed. 18 USC &#38; 17 CFR
                        <div className="badge badge-light text-white">3</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Tstandards">
                      <span className="sidebar-item-label">
                        Nodes & Token Standards
                        <div className="badge badge-light text-white">4</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Apps">
                      <span className="sidebar-item-label">
                        Web3 Legal Solutions
                        <div className="badge badge-light text-white">6</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Members">
                      <span className="sidebar-item-label">
                        SmartLaw Dashboard
                        <div className="badge badge-success rounded-circle text-white bg-serious-blue">
                          1
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Sltoks">
                      <span className="sidebar-item-label">
                        SmartLaw Tokens
                        <div className="badge badge-light text-white">7</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Clecs">
                      <span className="sidebar-item-label">
                        MCLE & CLE Programs
                        <div className="badge badge-light text-white">8</div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Credcert">
                      <span className="sidebar-item-label">
                        Credits & Certifications
                        <div className="badge badge-light text-white">9</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Calendar">
                      <span className="sidebar-item-label">
                        Event Calendar
                        <div className="badge badge-light text-white">11</div>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleUiKitComponents}
                className={clsx({ active: uiKitComponentsOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <AccountBalanceTwoToneIcon />
                </span>
                <span className="sidebar-item-label text-white-50 ">
                  Claim Resolution
                  <span className="sidebar-item-label">
                    <div className="badge badge-light rounded-circle text-white bg-sunrise-purple mr-4">
                      8
                    </div>
                  </span>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={uiKitComponentsOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Ddr">
                      <span className="sidebar-item-label">
                        Resolution Framework
                        <div className="badge badge-light text-white">1</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Claim">
                      <span className="sidebar-item-label">
                        State your Claim
                        <div className="badge badge-light text-white">2</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Jury">
                      <span className="sidebar-item-label">
                        Jury Instructions
                        <div className="badge badge-light text-white">3</div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Claimant">
                      <span className="sidebar-item-label">
                        Claimant Login
                        <div className="badge badge-light text-white">4</div>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleMetaverse}
                className={clsx({ active: metaverseOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <LanguageIcon />
                </span>
                <span className="sidebar-item-label">
                  Web3 3D Workspaces
                  <div className="badge badge-success rounded-circle text-white bg-serious-blue mr-4">
                    3
                  </div>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>

              <Collapse in={metaverseOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Metaverse">
                      <span className="sidebar-item-label font-size-lg text-info">
                        SmartLaw Metaverse
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://3dcourses.web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        3d Asset Manager
                        <div className="badge badge-primary">ws</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://3dcourses.web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        3d Courses
                        <div className="badge badge-primary">Stageverse</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://3dcourses.web3skills.com/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Wearable 3d Patterns
                        <div className="badge badge-primary">ws</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/play?coords=SE@262E,1S"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Metaverse Hub (w)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/play?coords=NW@345E,140S"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Red Carpet (w)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/1697"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Event Center (p)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/997"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        W3S Funhouse (p)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/2111"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        W3S Training (p)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/play?coords=SE@426E,98S"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        W3S Training (w)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/493"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        SL Clubhouse (p)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/2353"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Gated SL Center (p)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/1064"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Municipal Center (p)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/play?coords=NE@363W"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        MetaCollab (w)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/play?coords=W@465W,100S,7.5U"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        Courtroom (w)
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </a>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/FormsDatepicker">
                      <span className="sidebar-item-label">
                        mV Mock Court
                        <div className="badge badge-primary">Voxels</div>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>




          </ul>

          <div className="sidebar-header text-white">
            <span>Resources</span>
          </div>
          <ul>
          <li>
              <a
                href="#/"
                onClick={toggleDashboard}
                className={clsx({ active: dashboardOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <CameraTwoToneIcon />
                </span>
                <span className="sidebar-item-label">
                  Web3Skills Courses
                  <div className="badge badge-success rounded-circle bg-serious-blue text-white mr-4">
                    1
                  </div>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={dashboardOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/About">
                      <span className="sidebar-item-label">
                        About Web3Skills
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white">
                          1
                        </div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Connect">
                      <span className="sidebar-item-label">
                        Connect to Web3
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white">
                          2
                        </div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Achieve">
                      <span className="sidebar-item-label">
                        Course Achievements
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white">
                          3
                        </div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Superv">
                      <span className="sidebar-item-label">
                        Course Supervisors
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white">
                          4
                        </div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Catalog">
                      <span className="sidebar-item-label">
                        Course Catalog
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white ">
                          5
                        </div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Tokens">
                      <span className="sidebar-item-label">
                        Tokens Dashboard
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white">
                          6
                        </div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Signups">
                      <span className="sidebar-item-label">
                        Web3Skills Signup
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white">
                          7
                        </div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Did">
                      <span className="sidebar-item-label">
                        Decentralized ID
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white">
                          8
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://web3skills.com"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        EVM Blockchains
                        <div className="badge badge-primary bg-night-sky">
                          ws
                        </div>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://blockchaindashboarddemo.freeweb3.com/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-item-label">
                        LexQ Blockchain
                        <div className="badge badge-primary bg-night-sky">
                          ws
                        </div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Login">
                      <span className="sidebar-item-label">
                        Web3Skills Login &nbsp;
                        <span role="img" aria-labelledby="compass">
                          🧭
                        </span>
                        <div className="badge badge-success rounded-circle bg-night-skyer text-white">
                          9
                        </div>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleDesignSystem}
                className={clsx('font-weight-normal', {
                  active: designSystemOpen
                })}>
                <span className="sidebar-icon-light mr-3">
                  <PublicTwoToneIcon />
                </span>
                <span className="sidebar-item-label text-capitalize text-white">
                  Contact Information
                  <div className="badge badge-primary bg-serious-blue mr-3">
                    11
                  </div>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={designSystemOpen}>
                <ul className="pb-0">
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/FormsInputMask">
                      <span className="sidebar-icon">
                        <LinkTwoToneIcon />
                      </span>
                      <span className="sidebar-item-label">
                        General
                        <div className="badge badge-info text-white bg-serious-blue">
                          1
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/2248"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-icon">
                        <LinkTwoToneIcon />
                      </span>
                      <span className="sidebar-item-label">
                        mV SL Tokens
                        <div className="badge badge-info text-white bg-serious-blue">
                          2
                        </div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/2353"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-icon">
                        <LinkTwoToneIcon />
                      </span>
                      <span className="sidebar-item-label">
                        mV SL Training
                        <div className="badge badge-info text-white bg-serious-blue">
                          3
                        </div>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.voxels.com/parcels/2393"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-icon">
                        <LinkTwoToneIcon />
                      </span>
                      <span className="sidebar-item-label">
                        mV SL ADR
                        <div className="badge badge-info text-white bg-serious-blue">
                          4
                        </div>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.sandbox.game/en/map/?liteMap&currentX=2486&currentY=789&zoom=0.5&x=90&y=-90"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="sidebar-icon">
                        <LinkTwoToneIcon />
                      </span>
                      <span className="sidebar-item-label">
                        mV Sandbox W3S
                        <div className="badge badge-info text-white bg-serious-blue">
                          5
                        </div>
                      </span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </li>

          </ul>

          <hr />

          <div className="sidebar-header text-white">
            <span>Web3 Engineering Tools</span>
          </div>
          <ul>
            <li>
              <a
                href="#/"
                onClick={toggleWidgets}
                className={clsx({ active: widgetsOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <MemoryTwoToneIcon />
                </span>
                <span className="sidebar-item-label">
                  Administration
                  <div className="badge badge-primary bg-happy-green mr-3">
                    14
                  </div>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={widgetsOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Pmdash">
                      <span className="sidebar-item-label">
                        Project Management
                        <div className="badge badge-info text-white bg-serious-blue">
                          1
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Calplus">
                      <span className="sidebar-item-label">
                        Calendar &nbsp;
                        <span role="img" aria-labelledby="compass">
                          🧭
                        </span>
                        <div className="badge badge-info text-white bg-serious-blue">
                          2
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Contmgr">
                      <span className="sidebar-item-label">
                        Content Manager &nbsp;
                        <span role="img" aria-labelledby="compass">
                          🧭
                        </span>
                        <div className="badge badge-info text-white bg-serious-blue">
                          3
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Profile">
                      <span className="sidebar-item-label">
                        Profile Overview &nbsp;
                        <span role="img" aria-labelledby="compass">
                          🧭
                        </span>
                        <div className="badge badge-info text-white bg-serious-blue">
                          4
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Invoice">
                      <span className="sidebar-item-label">
                        Sample Invoice
                        <div className="badge badge-info text-white bg-serious-blue">
                          5
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Homelinks">
                      <span className="sidebar-item-label">
                        HomeLife Links
                        <div className="badge badge-info text-white bg-serious-blue">
                          6
                        </div>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleCharts}
                className={clsx({ active: chartsOpen })}>
                <span className="sidebar-icon-light mr-3">
                  <AssessmentTwoToneIcon />
                </span>
                <span className="sidebar-item-label">
                  Live Data Feeds
                  <div className="badge badge-primary bg-happy-green mr-3">
                    15
                  </div>
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={chartsOpen}>
                <ul>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={toggleSidebarMobile}
                      to="/Livemarkets">
                      <span className="sidebar-item-label">
                        Web3 Markets
                        <div className="badge badge-info text-white bg-serious-blue">
                          1
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Stats">
                      <span className="sidebar-item-label">
                        Statistics &#38; Analytics
                        <div className="badge badge-info text-white bg-serious-blue">
                          2
                        </div>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Opstats">
                      <span className="sidebar-item-label">
                        Snapshot Statistics
                        <div className="badge badge-info text-white bg-serious-blue">
                          3
                        </div>
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Basicstats">
                      <span className="sidebar-item-label">
                        Token Statistics
                        <div className="badge badge-info text-white bg-serious-blue">
                          4
                        </div>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul>

          <hr />


          {/* <div className="sidebar-menu-box">
            <span className="sidebar-item-label text-white">
              Special Characters used with Web3Skills-SmartLaw
            </span>
            <br />

            <span className="sidebar-item-label text-info">
              Unicode emoji characters
            </span>

            <br />

            <span role="img" aria-labelledby="keycap 0">
              0️⃣
            </span>
            <span role="img" aria-labelledby="keycap 1">
              1️⃣
            </span>
            <span role="img" aria-labelledby="keycap 2">
              2️⃣
            </span>
            <span role="img" aria-labelledby="keycap 3">
              3️⃣
            </span>
            <span role="img" aria-labelledby="keycap 4">
              4️⃣
            </span>
            <span role="img" aria-labelledby="keycap 5">
              5️⃣
            </span>
            <span role="img" aria-labelledby="keycap 6">
              6️⃣
            </span>
            <span role="img" aria-labelledby="keycap 7">
              7️⃣
            </span>
            <span role="img" aria-labelledby="keycap 8">
              8️⃣
            </span>
            <span role="img" aria-labelledby="keycap 9">
              9️⃣
            </span>
            <span role="img" aria-labelledby="keycap *">
              *️⃣
            </span>
            <span role="img" aria-labelledby="keycap #">
              #️⃣
            </span>

            <br />

            <span role="img" aria-labelledby="black circle">
              ⚫
            </span>
            <span role="img" aria-labelledby="red circle">
              🔴
            </span>
            <span role="img" aria-labelledby="red square">
              🟥
            </span>
            <span role="img" aria-labelledby="brown circle">
              🟤
            </span>
            <span role="img" aria-labelledby="brown square">
              🟫
            </span>
            <span role="img" aria-labelledby="orange circle">
              🟠
            </span>
            <span role="img" aria-labelledby="orange square">
              🟧
            </span>
            <span role="img" aria-labelledby="yellow circle">
              🟡
            </span>
            <span role="img" aria-labelledby="yellow square">
              🟨
            </span>
            <span role="img" aria-labelledby="green circle">
              🟢
            </span>
            <span role="img" aria-labelledby="green square">
              🟩
            </span>
            <span role="img" aria-labelledby="blue circle">
              🔵
            </span>
            <span role="img" aria-labelledby="blue square">
              🟦
            </span>
            <span role="img" aria-labelledby="purple circle">
              🟣
            </span>
            <span role="img" aria-labelledby="purple square">
              🟪
            </span>

            <br />

            <span role="img" aria-labelledby="bust in silhouette">
              👤
            </span>
            <span role="img" aria-labelledby="puzzle piece">
              🧩
            </span>
            <span role="img" aria-labelledby="identification card">
              🪪
            </span>
            <span role="img" aria-labelledby="globe showing Americas">
              🌎
            </span>
            <span role="img" aria-labelledby="globe showing Asia-Australia">
              🌏
            </span>
            <span role="img" aria-labelledby="globe showing Europe-Africa">
              🌍
            </span>
            <span role="img" aria-labelledby="bank">
              🏦
            </span>
            <span role="img" aria-labelledby="fox">
              🦊
            </span>
            <span role="img" aria-labelledby="magnifying glass tilted left">
              🔍
            </span>
            <span role="img" aria-labelledby="locked">
              🔒
            </span>
            <span role="img" aria-labelledby="locked with key">
              🔐
            </span>
            <span role="img" aria-labelledby="magnifying glass tilted right">
              🔎
            </span>
            <span role="img" aria-labelledby="compass">
              🧭
            </span>
            <span role="img" aria-labelledby="fish cake with swirl">
              🍥
            </span>

            <span role="img" aria-labelledby="trident emblem">
              🔱
            </span>

            <br />

            <span role="img" aria-labelledby="gem stone">
              💎
            </span>
            <span role="img" aria-labelledby="coin">
              🪙
            </span>
            <span role="img" aria-labelledby="star">
              ⭐
            </span>
            <span role="img" aria-labelledby="glowing star">
              🌟
            </span>
            <span role="img" aria-labelledby="trophy">
              🏆
            </span>
            <span role="img" aria-labelledby="money bag">
              💰
            </span>
            <span role="img" aria-labelledby="bagel">
              🥯
            </span>
            <span role="img" aria-labelledby="doughnut">
              🍩
            </span>
            <span role="img" aria-labelledby="chocolate bar">
              🍫
            </span>
            <span role="img" aria-labelledby="taco">
              🌮
            </span>
            <span role="img" aria-labelledby="pancakes">
              🥞
            </span>
            <span role="img" aria-labelledby="fire">
              🔥
            </span>
            <span role="img" aria-labelledby="collision">
              💥
            </span>
            <span role="img" aria-labelledby="party popper">
              🎉
            </span>
            <span role="img" aria-labelledby="bottle with popping cork">
              🍾
            </span>

            <br />

            <span role="img" aria-labelledby="bullseye">
              🎯
            </span>
            <span role="img" aria-labelledby="circus tent">
              🎪
            </span>
            <span role="img" aria-labelledby="billed cap">
              🧢
            </span>
            <span role="img" aria-labelledby="military helmet">
              🪖
            </span>
            <span role="img" aria-labelledby="pager">
              📟
            </span>
            <span role="img" aria-labelledby="artist palette">
              🎨
            </span>
            <span role="img" aria-labelledby="butterfly">
              🦋
            </span>
            <span role="img" aria-labelledby="palm tree">
              🌴
            </span>
            <span role="img" aria-labelledby="joker">
              🃏
            </span>
            <span role="img" aria-labelledby="barber pole">
              💈
            </span>
            <span role="img" aria-labelledby="game die">
              🎲
            </span>
            <span role="img" aria-labelledby="mirror ball">
              🪩
            </span>
            <span role="img" aria-labelledby="mushroom">
              🍄
            </span>
            <span role="img" aria-labelledby="yarn">
              🧶
            </span>
            <span role="img" aria-labelledby="wrench">
              🔧
            </span>

            <br />

            <span role="img" aria-labelledby="books">
              📚
            </span>
            <span role="img" aria-labelledby="canned food">
              🥫
            </span>
            <span role="img" aria-labelledby="backpack">
              🎒
            </span>
            <span role="img" aria-labelledby="cyclone">
              🌀
            </span>
            <span role="img" aria-labelledby="ice">
              🧊
            </span>
            <span role="img" aria-labelledby="brick">
              🧱
            </span>

            <span role="img" aria-labelledby="OK button">
              🆗
            </span>
            <span role="img" aria-labelledby="ID button">
              🆔
            </span>
            <span role="img" aria-labelledby="SOS button">
              🆘
            </span>
            <span role="img" aria-labelledby="heart decoration">
              💟
            </span>
            <span role="img" aria-labelledby="hollow red circle">
              ⭕
            </span>
            <span role="img" aria-labelledby="cross mark">
              ❌
            </span>
            <span role="img" aria-labelledby="cross mark button">
              ❎
            </span>
            <span role="img" aria-labelledby="large blue diamond">
              🔷
            </span>
            <span role="img" aria-labelledby="large orange diamond">
              🔶
            </span>
            <span role="img" aria-labelledby="bright button">
              🔆
            </span>

            <br />

            <span role="img" aria-labelledby="A button (blood type)">
              🅰
            </span>
            <span role="img" aria-labelledby="B button (blood type)">
              🅱
            </span>
            <span role="img" aria-labelledby="P button">
              🅿
            </span>
            <span role="img" aria-labelledby="BACK arrow">
              🔙
            </span>
            <span role="img" aria-labelledby="balance scale">
              ⚖
            </span>
            <span role="img" aria-labelledby="building construction">
              🏗
            </span>
            <span role="img" aria-labelledby="circled M">
              Ⓜ
            </span>
            <span role="img" aria-labelledby="classical building">
              🏛
            </span>
            <span role="img" aria-labelledby="END arrow">
              🔚
            </span>
            <span role="img" aria-labelledby="hammer and wrench">
              🛠
            </span>
            <span role="img" aria-labelledby="pick">
              ⛏
            </span>
            <span role="img" aria-labelledby="plus">
              ➕
            </span>
            <span role="img" aria-labelledby="rescue worker’s helmet">
              ⛑
            </span>
            <span role="img" aria-labelledby="shield">
              🛡
            </span>
            <span role="img" aria-labelledby="sunglasses">
              🕶
            </span>
            <span role="img" aria-labelledby="footprints">
              👣
            </span>

            <span className="sidebar-item-label text-info">
              Material-UI Icons
            </span>
            <br />
            <span className="sidebar-icon text-white">
              <CollectionsTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter1TwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter2TwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter3TwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter4TwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter5TwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter6TwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter7TwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter8TwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter9TwoToneIcon />
            </span>

            <br />

            <span className="sidebar-icon text-white">
              <FilterIcon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter1Icon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter2Icon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter3Icon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter4Icon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter5Icon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter6Icon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter7Icon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter8Icon />
            </span>
            <span className="sidebar-icon text-white">
              <Filter9Icon />
            </span>
            <br />

            <span className="sidebar-icon text-white">
              <AccountBoxTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <CameraAltTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <EvStationTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <AccountBalanceWalletTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LibraryBooksTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <AssessmentTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <BallotTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LocalPharmacyTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LocalPlayTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LocalAtmTwoToneIcon />
            </span>

            <br />

            <span className="sidebar-icon text-white">
              <AccountBoxIcon />
            </span>
            <span className="sidebar-icon text-white">
              <FingerprintIcon />
            </span>
            <span className="sidebar-icon text-white">
              <EvStationIcon />
            </span>
            <span className="sidebar-icon text-white">
              <AccountBalanceWalletIcon />
            </span>
            <span className="sidebar-icon text-white">
              <DynamicFeedIcon />
            </span>
            <span className="sidebar-icon text-white">
              <TuneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <PermMediaIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LocalPharmacyIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LocalPlayIcon />
            </span>
            <span className="sidebar-icon text-white">
              <MonetizationOnIcon />
            </span>

            <br />

            <span className="sidebar-icon text-white">
              <CheckCircleTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <AccountCircleTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <CameraTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <PublicTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <NewReleasesTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <PaletteTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <PolicyTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <SecurityTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <VerifiedUserTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <FavoriteTwoToneIcon />
            </span>

            <br />

            <span className="sidebar-icon text-white">
              <AlbumIcon />
            </span>
            <span className="sidebar-icon text-white">
              <AccountCircleIcon />
            </span>
            <span className="sidebar-icon text-white">
              <AdjustIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LanguageIcon />
            </span>
            <span className="sidebar-icon text-white">
              <FlareIcon />
            </span>
            <span className="sidebar-icon text-white">
              <ColorLensIcon />
            </span>
            <span className="sidebar-icon text-white">
              <PolicyIcon />
            </span>
            <span className="sidebar-icon text-white">
              <SecurityIcon />
            </span>
            <span className="sidebar-icon text-white">
              <VerifiedUserIcon />
            </span>
            <span className="sidebar-icon text-white">
              <FireplaceIcon />
            </span>

            <br />

            <span className="sidebar-icon text-white">
              <SettingsTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LocalGroceryStoreTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <RedeemTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LockOpenTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LockTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <MemoryTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <MoveToInboxTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <RoomTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <AccountBalanceTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LinkTwoToneIcon />
            </span>

            <br />

            <span className="sidebar-icon text-white">
              <ExtensionIcon />
            </span>
            <span className="sidebar-icon text-white">
              <ChildCareIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LinkedInIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LockOpenTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <LockTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <GitHubIcon />
            </span>
            <span className="sidebar-icon text-white">
              <MoveToInboxTwoToneIcon />
            </span>
            <span className="sidebar-icon text-white">
              <GavelIcon />
            </span>
            <span className="sidebar-icon text-white">
              <AccountBalanceIcon />
            </span>
            <span className="sidebar-icon text-white">
              <WbSunnyTwoToneIcon />
            </span>

            <span className="sidebar-item-label text-info">
              FontAwesome Icons
            </span>
            <br />

            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'fire']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'lock']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'lock-open']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'bullhorn']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'bullseye']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'address-card']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'fingerprint']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'charging-station']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'gas-pump']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'city']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'landmark']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'plug']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'spinner']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'user-check']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'user-plus']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'user-shield']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'users']}
            />
            <FontAwesomeIcon
              className="font-size-lg text-white mr-2"
              icon={['fas', 'dollar-sign']}
            />


            <br />
            <span className="sidebar-icon text-white">
              <ChevronRightTwoToneIcon />
            </span>

            <br />
            <br />
          </div> */}

          <br />
          <br />

          <Container>
            <Grid>
              <div className="climg mb-3">
                {/* <a href="https://chn.lk/3R7BPNw" target="_blank"                     rel="noopener noreferrer">
  <img
    src="https://chain.link/badge-automation-white"
    alt="automation secured with chainlink"
  />
</a> */}
                <a
                  align="center"
                  href="https://chn.lk/3R7BPNw"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    width="200px"
                    src="https://chain.link/badge-automation-black"
                    alt="automation secured with chainlink"
                  />
                </a>

                {/* <a href="https://chn.lk/3BAfa6R" target="_blank"                     rel="noopener noreferrer">>
  <img
    src="https://chain.link/badge-proof-of-reserve-white"
    alt="proof of reserve secured with chainlink"
  />
</a> */}
                <br />

                <br />
                <a
                  align="center"
                  href="https://chn.lk/3BAfa6R"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    mt="20px"
                    width="200px"
                    src="https://chain.link/badge-proof-of-reserve-black"
                    alt="proof of reserve secured with chainlink"
                  />
                </a>
              </div>
            </Grid>
          </Container>
        </div>
      </PerfectScrollbar>
    </>
  );
};
const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});
const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
