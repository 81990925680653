import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Button, List, ListItem } from '@material-ui/core';

import projectLogo from '../../../assets/images/logos/color-wheel.svg';

// import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
// import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

// import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
// import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
// import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
// import GridOnTwoToneIcon from '@material-ui/icons/GridOnTwoTone';

export default function LivePreviewExample() {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  return (
    <>
      <div className="header-nav-wrapper header-nav-wrapper-lg rounded navbar-light">

      <div className="app-nav-logo app-nav-logo--dark">
          <a
            href="#/"
            target="_blank" 
rel="noopener noreferrer"

            title="web3skills  Dashboard "
            className="app-nav-logo">
            <div className="app-nav-logo--icon rounded-circle">
              <img
                alt="web3skills  Dashboard "
                src={projectLogo}
              />
            </div>
            <div className="app-nav-logo--text">
              <span>Learn the new internet with</span>
              <b>Web3Skills Tech Courses</b>
            </div>
          </a>
        </div>


        <div className="header-nav-menu flex-grow-0 ml-auto d-none d-lg-block">
        </div>
        <div className="header-nav-actions ml-auto ml-xl-4 flex-grow-0">
          <span className="d-none d-lg-block">
            <Button
              href="#/"
              target="_blank" 
rel="noopener noreferrer"

              className="btn-pill text-uppercase font-weight-bold text-nowrap font-size-xs btn-first">
              Connect to Web3Skills
            </Button>
          </span>
          <span className="d-block d-lg-none">
            <button
              onClick={toggle}
              className={clsx('navbar-toggler hamburger hamburger--elastic', {
                'is-active': collapse
              })}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </span>
        </div>
        <div className="d-flex d-lg-none">
          <Collapse
            in={collapse}
            className="nav-collapsed-wrapper bg-second shadow-xxl navbar-collapse">
            <div className="nav-inner-wrapper">
              <Button
                onClick={toggle}
                className="btn-danger btn-icon d-40 shadow-sm hover-scale-lg btn-animated-icon-sm nav-toggle-inner-btn p-0">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'times']} />
                </span>
              </Button>

              <List
                component="div"
                className="nav-pills nav-transparent nav-lg flex-column p-3">
                <ListItem
                  component="a"
                  button
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="px-4 d-flex align-items-center text-white-50">
                  <span>Courses</span>
                  <FontAwesomeIcon
                    icon={['fas', 'angle-right']}
                    className="opacity-6 ml-auto"
                  />
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="px-4 d-flex align-items-center text-white-50">
                  <span>Listings</span>
                  <FontAwesomeIcon
                    icon={['fas', 'angle-right']}
                    className="opacity-6 ml-auto"
                  />
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="px-4 d-flex align-items-center text-white-50">
                  <span>Workplace</span>
                  <FontAwesomeIcon
                    icon={['fas', 'angle-right']}
                    className="opacity-6 ml-auto"
                  />
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="px-4 d-flex align-items-center text-white-50">
                  <span>Software</span>
                  <FontAwesomeIcon
                    icon={['fas', 'angle-right']}
                    className="opacity-6 ml-auto"
                  />
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="px-4 d-flex align-items-center text-white-50">
                  <span>Exchanges</span>
                  <FontAwesomeIcon
                    icon={['fas', 'angle-right']}
                    className="opacity-6 ml-auto"
                  />
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  target="_blank" 
rel="noopener noreferrer"

                  className="px-4 d-flex align-items-center text-white-50">
                  <span>Services</span>
                  <FontAwesomeIcon
                    icon={['fas', 'angle-right']}
                    className="opacity-6 ml-auto"
                  />
                </ListItem>
              </List>
            </div>
          </Collapse>
        </div>
      </div>
      <div
        className={clsx('collapse-page-trigger', { 'is-active': collapse })}
        onClick={toggle}
      />
    </>
  );
}
