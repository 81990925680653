import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';

import MarketingHero1 from '../../example-components/About/MarketingHero1';
import MarketingHero6 from '../../example-components/About/MarketingHero6';
import MarketingHero9 from '../../example-components/About/MarketingHero9';
import MarketingHero10 from '../../example-components/About/MarketingHero10';
import MarketingHero11 from '../../example-components/About/MarketingHero11';
import MarketingHero16 from '../../example-components/About/MarketingHero16';
import MarketingHero22 from '../../example-components/About/MarketingHero22';

export default function About() {
  return (
    <>
      <PageTitle
        titleHeading="Learning with Web3Skills &#38; SmartLaw"
        titleDescription="Web3Skills offers courses on more than Web3 technologies. Explore what you can do with Legal Engineering."
      />

      <ExampleWrapperSeamless>
        <MarketingHero10 />
      </ExampleWrapperSeamless>
      <hr />
      <ExampleWrapperSeamless>
        <MarketingHero22 />
      </ExampleWrapperSeamless>
      <hr />
      <ExampleWrapperSeamless>
        <MarketingHero1 />
      </ExampleWrapperSeamless>
      <hr />
      <ExampleWrapperSeamless>
        <MarketingHero6 />
      </ExampleWrapperSeamless>
      <hr />
      <ExampleWrapperSeamless>
        <MarketingHero11 />
      </ExampleWrapperSeamless>
      <hr />
      <ExampleWrapperSeamless>
        <MarketingHero16 />
      </ExampleWrapperSeamless>
      <hr />
      <ExampleWrapperSeamless>
        <MarketingHero9 />
      </ExampleWrapperSeamless>

    </>
  );
}
