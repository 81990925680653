import React from 'react';

import { PageTitle } from 'layout-components';
import { Grid } from '@material-ui/core';
import { ExampleWrapperSeamless } from 'layout-components';

import ElementsRibbons3 from '../../example-components/Lexag/Ribbons3';
// import ElementsRibbons4 from '../../example-components/ElementsRibbons/Ribbons4';
import BlocksComposed21 from '../../example-components/Connect/BlocksComposed21';
import BlocksComposed22 from '../../example-components/Connect/BlocksComposed22';
import BlocksComposed23 from '../../example-components/Connect/BlocksComposed23';
import BlocksComposed24 from '../../example-components/Connect/BlocksComposed24';
import BlocksComposed25 from '../../example-components/Connect/BlocksComposed25';
import BlocksComposed26 from '../../example-components/Connect/BlocksComposed26';
import BlocksComposed27 from '../../example-components/Connect/BlocksComposed27';
import BlocksComposed28 from '../../example-components/Connect/BlocksComposed28';
import BlocksComposed29 from '../../example-components/Connect/BlocksComposed29';

export default function Lexag() {
  return (
    <>
      <PageTitle
        titleHeading="Web3vm Verified Applications"
        titleDescription="Special offers for incentivizing users to try Web3 in the metaverse."
      />
      <Grid container spacing={0}>

{/* 
        <Grid item md={12}>
          <ExampleWrapperSeamless>
            <ElementsRibbons4 />
          </ExampleWrapperSeamless>
        </Grid> */}

        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed21 />
        </ExampleWrapperSeamless>
        </Grid>


        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed22 />
        </ExampleWrapperSeamless>
        </Grid>


        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed23 />
        </ExampleWrapperSeamless>
        </Grid>


        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed24 />
        </ExampleWrapperSeamless>
        </Grid>


        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed25 />
        </ExampleWrapperSeamless>
        </Grid>


        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed26 />
        </ExampleWrapperSeamless>
        </Grid>


        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed27 />
        </ExampleWrapperSeamless>
        </Grid>


        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed28 />
        </ExampleWrapperSeamless>
        </Grid>


        <Grid item md={12}>
        <ExampleWrapperSeamless>
          <BlocksComposed29 />
        </ExampleWrapperSeamless>
        </Grid>



        <Grid item md={12}>
          <ExampleWrapperSeamless>
            <ElementsRibbons3 />
          </ExampleWrapperSeamless>
        </Grid>




      </Grid>
    </>
  );
}
