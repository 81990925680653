import React from 'react';
import lexautomatico01 from '../../../assets/images/lexautomatica/LEXAUTOMATICO-logo-01-200w.png';
import lexautomatica062200w from '../../../assets/images/lexautomatica/lexautomatica-062-200w.png';


export default function LivePreviewExample() {
  return (
    <>
      <div>
        <a
          href="https://lexautomatico.com"
          target="_blank"
          rel="noopener noreferrer"
          className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
          <img src={lexautomatica062200w} className="" alt="..." />
          <div className="p-3 bg-secondary unded-bottom p-xl-4">
            <img src={lexautomatico01} alt="..." style={{ height: 40 }} />
            <p className="text-second opacity-8 mt-4 mb-0">
              Web3Skills_SmartLaw course and service descriptions. 5686
            </p>
          </div>
        </a>
      </div>

    </>
  );
}
