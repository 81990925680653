import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent } from '@material-ui/core';

import CountUp from 'react-countup';
import { Line } from 'react-chartjs-2';
import projectLogo from '../../../assets/images/logos/color-wheel.svg';

const data3Success = {
  labels: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August'
  ],
  datasets: [
    {
      backgroundColor: 'rgba(27, 201, 67, 0.1)',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 2,
      borderColor: '#1bc943',
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#1bc943',
      pointBackgroundColor: '#ffffff',
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#1bc943',
      pointHoverBorderWidth: 0,
      pointRadius: 0,
      pointHitRadius: 0,
      data: [65, 59, 80, 81, 55, 38, 59, 80, 46]
    }
  ]
};
const data3SuccessOptions = {
  layout: {
    padding: {
      left: -10,
      right: 0,
      top: 0,
      bottom: -10
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ]
  },
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false
};

export default function LivePreviewExample() {
  return (
    <>
      <Card className="card-box">
        <div className="card-header">
          <h4 className="font-size-lg mb-0 py-2 font-weight-bold">Inventory</h4>
        </div>
        <CardContent className="pb-0">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"
              />
              <b>Membership Tokens Issued</b>
            </div>
            <div className="font-weight-bold text-primary font-size-lg">
              <CountUp
                start={0}
                end={263}
                duration={3}
                delay={2}
                separator=""
                decimals={0}
                decimal=","
              />
            </div>
          </div>
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'chart-bar']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"
              />
              <b>Digital Assets Minted</b>
            </div>
            <div className="font-weight-bold text-primary font-size-lg">
              <CountUp
                start={0}
                end={643}
                duration={6}
                delay={2}
                separator=""
                decimals={0}
                decimal=","
              />
            </div>
          </div>
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'address-card']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"
              />
              <b>Digital Assets Distributed</b>
            </div>
            <div className="font-weight-bold text-primary font-size-lg">
              <CountUp
                start={0}
                end={1.483}
                duration={6}
                delay={2}
                separator=""
                decimals={3}
                decimal=","
              />
            </div>
          </div>
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'object-group']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"
              />
              <b>Proof of Reserve &#40;A&#41;</b>
            </div>
            <div className="font-weight-bold text-first font-size-lg">
              
            <a
              href="https://app.balancer.fi/#/polygon/pool/0xba07d63875d0c2319d43cbb8a897f89bdafd7e87000100000000000000000077"
              rel="noopener noreferrer"
              target="_blank"
          title="web3skills  Dashboard "
              className="app-nav-logo">
              <div className="app-nav-logo--icon rounded-sm mr-2 ">
                <img
                  alt="web3skills  Dashboard "
                  src={projectLogo}
                  className="text-first"

                />
              </div>
              {' '}
              Balancer Pool on Polygon
            </a>
          </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"
              />
              <b>Proof of Reserve &#40;B&#41;</b>
            </div>
            <div className="font-weight-bold text-first font-size-lg">
              <CountUp
                start={0}
                end={1.000}
                duration={6}
                delay={2}
                separator=""
                decimals={3}
                decimal=","
              />
              <a
              href="https://polygonscan.com/address/0x00a2ddfa736214563cea9aef5100f2e90c402918"
              rel="noopener noreferrer"
              target="_blank"
          title="web3skills  Dashboard "
          className="text-first"
              >
              {' '}
              MATIC             
              </a> 
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"
              />
              <b>Proof of Reserve &#40;C&#41;</b>
            </div>
            <div className="font-weight-bold text-first font-size-lg">
              <CountUp
                start={0}
                end={3.000}
                duration={6}
                delay={2}
                separator=""
                decimals={3}
                decimal=","
              />
              <a
              href="https://etherscan.io/tx/0x31e01444d7b69d09a460b45aa004c00d46c4f5ca8dfd006bcfccc47d531015ba"
              rel="noopener noreferrer"
              target="_blank"
          title="web3skills  Dashboard "
          className="text-first"

              >
              {' '}
              LINK             
              </a>            
              </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"
              />
              <b>Proof of Reserve &#40;D&#41;</b>
            </div>
            <div className="font-weight-bold text-first font-size-lg">
              <CountUp
                start={0}
                end={16}
                duration={6}
                delay={2}
                separator=""
                decimals={0}
                decimal=","
              />
              <a
              href="https://opensea.io/0x00a2Ddfa736214563CEa9AEf5100f2e90c402918/cryptovoxels"
              rel="noopener noreferrer"
              target="_blank"
          title="web3skills  Dashboard "
          className="text-first"

              >
              {' '}
              VOXELS             
              </a>            
              </div>
          </div>
        </CardContent>
        <div className="sparkline-full-wrapper" style={{ height: 103 }}>
          <Line data={data3Success} options={data3SuccessOptions} />
        </div>
      </Card>
    </>
  );
}
