import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Grid,
  Button,
  List,
  ListItem,
  Tooltip,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Input,
  Checkbox,
  ListItemText,
  TextField
} from '@material-ui/core';


import {
  // AlphaPicker,
  // BlockPicker,
  // ChromePicker,
  // CirclePicker,
  // CompactPicker,
  // GithubPicker,
  // HuePicker,
  // PhotoshopPicker,
  // SketchPicker,
  // SliderPicker,
  // SwatchesPicker,
  TwitterPicker
} from 'react-color';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sideImage from '../../../assets/images/illustrations/scott-stevenson-tenfinney-profile-003.png';
import Slider from '@material-ui/core/Slider';
import Chip from '@material-ui/core/Chip';

import 'date-fns';

function valuetext(value) {
  return <span>${{ value }}</span>;
}

const ITEM_HEIGHT = 24;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5,
      width: 200
    }
  }
};

const SelfDefined = [
  { title: 'Winter', level: 'Level 1', year: 2023 },
  { title: 'Spring', level: 'Level 1', year: 2023 },
  { title: 'Summer', level: 'Level 1', year: 2023 },
  { title: 'Fall', level: 'Level 1', year: 2023 },
  { title: 'VoidSeason', level: 'Level 1', year: 2023 },
  { title: 'Red', level: 'Level 1', year: 2023 },
  { title: 'Green', level: 'Level 2', year: 2023 },
  { title: 'Blue', level: 'Level 2', year: 2023 },
  { title: 'Purple', level: 'Level 2', year: 2023 },
  { title: 'Yellow', level: 'Level 2', year: 2023 },
  { title: 'VoidColor', level: 'Level 1', year: 2023 },
  { title: 'Skateboard', level: 'Level 3', year: 2023 },
  { title: 'Bicycle', level: 'Level 3', year: 2023 },
  { title: 'Car', level: 'Level 3', year: 2023 },
  { title: 'Truck', level: 'Level 3', year: 2023 },
  { title: 'Boat', level: 'Level 3', year: 2023 },
  { title: 'Airplane', level: 'Level 3', year: 2023 },
  { title: 'Spaceship', level: 'Level 3', year: 2023 },
  { title: 'VoidMode', level: 'Level 3', year: 2023 }
];

const names = [
  'Winter',
  'Spring',
  'Summer',
  'Fall',
  'VoidSeason',
  'Red',
  'Green',
  'Blue',
  'Purple',
  'Yellow',
  'VoidColor',
  'Skateboard',
  'Bicycle',
  'Car',
  'Truck',
  'Boat',
  'Airplane',
  'Spaceship',
  'VoidMode'
];

const Seasons = [
  { title: 'Winter', salt: 'Level 1', value: 2023 },
  { title: 'Spring', salt: 'Level 1', value: 2023 },
  { title: 'Summer', salt: 'Level 1', value: 2023 },
  { title: 'Fall', salt: 'Level 1', value: 2023 },
  { title: 'Void', salt: 'Level 1', value: 2023 }
];

const Colors = [
  { title: 'Red', salt: 'Level 2', value: 2023 },
  { title: 'Green', salt: 'Level 2', value: 2023 },
  { title: 'Blue', salt: 'Level 2', value: 2023 },
  { title: 'Purple', salt: 'Level 2', value: 2023 },
  { title: 'Yellow', salt: 'Level 2', value: 2023 },
  { title: 'Void', salt: 'Level 2', value: 2023 }
];

const Modes = [
  { title: 'Skateboard', salt: 'Level 3', value: 2023 },
  { title: 'Bicycle', salt: 'Level 3', value: 2023 },
  { title: 'Car', salt: 'Level 3', value: 2023 },
  { title: 'Truck', salt: 'Level 3', value: 2023 },
  { title: 'Boat', salt: 'Level 3', value: 2023 },
  { title: 'Airplane', salt: 'Level 3', value: 2023 },
  { title: 'Spaceship', salt: 'Level 3', value: 2023 },
  { title: 'Void', salt: 'Level 3', value: 2023 }
];






export default function LivePreviewExample() {

  const [setAge] = useState('');

  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
    setPersonName(event.target.value);
  };

  const options = Seasons.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option
    };
  });

  const options2 = Colors.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option
    };
  });

  const options3 = Modes.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option
    };
  });





  return (
    <>
      <div className="app-content p-0">
        <div className="app-inner-content-layout--main">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content">
              <Grid container spacing={0} className="min-vh-100">
                <Grid item lg={12} xl={12} className="d-flex">
                  <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image opacity-5"
                        style={{
                          backgroundImage: 'url(' + sideImage + ')'
                        }}
                      />
                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                      <div className="bg-composed-wrapper--content text-center p-5">
                        <div className="text-white px-0 px-lg-2 px-xl-4">
                          <h1 className="display-3 mb-4 font-weight-bold text-white">
                            Mnemonic Assembly
                          </h1>
                          <p className="font-size-lg mb-0 opacity-8">
                            SmartLaw provides tools for Web3 ecosystem to
                            advance legaltech. 11.
                          </p>
                          <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                          <div>
                            <Button className="btn-warning bg-happy-green px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                              <span className="btn-wrapper--label">
                                Learn more about Grant FastTrack
                              </span>
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={['fas', 'arrow-right']}
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero-footer pb-4">
                      <List
                        component="div"
                        className="nav-pills nav-neutral-secondary d-flex">
                        <Tooltip title="Github" arrow>
                          <ListItem
                            component="a"
                            button
                            href="https://github.com/tenfinney"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-size-lg text-white-50">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </div>
                  </div>
                </Grid>

                <Grid item md={12} lg={12} xl={12} className="mx-auto">
                  <h1 className="display-4 mb-1 mt-5 font-weight-bold">
                    Mnemonic Assembly
                  </h1>

                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        Select a season
                      </label>
                    </div>

                    <Autocomplete
                      id="grouped-demo"
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      // groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Winter, Spring, Summer Fall, or Void."
                          variant="outlined"
                          fullWidth
                          helperText="Select a season you will remeber over all others on the list."
                        />
                      )}
                    />
                  </div>

                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        Select a color
                      </label>
                    </div>
                    <br/>                    
                    <TwitterPicker />
{/* <br/> */}
                    {/* <CirclePicker /> */}
                  </div>

                  <div className="mb-3">
                    <Autocomplete
                      id="grouped-demo"
                      options={options2.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      // groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Red, Green, Blue, Purple or Yellow."
                          variant="outlined"
                          fullWidth
                          helperText="Select a color you will remember over all the others on the list."
                        />
                      )}
                    />
                  </div>

                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                        Select a mode
                      </label>
                    </div>

                    <Autocomplete
                      id="grouped-demo"
                      options={options3.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      // groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a mode you will remember above all others."
                          variant="outlined"
                          fullWidth
                          helperText="Select a mode that you will remember over all others in the list."
                        />
                      )}
                    />
                  </div>


                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mb-2">
                      Build the Recovery Mnemonic
                      </label>
                    </div>



                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select the mnemonic tags in order you want to be able to
                      recover the password.
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<Input />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}>
                      {names.map((name) => (
                        <MenuItem className="mx-2 px-2" key={name} value={name}>
                          <Checkbox
                            checked={personName.indexOf(name) > -1}
                            className="p-0 mr-2"
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <div className="mt-5 mb-3">
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={SelfDefined}
                      getOptionLabel={(option) => option.title}
                      defaultValue={[
                        SelfDefined[0],
                        SelfDefined[1],
                        SelfDefined[2],
                        SelfDefined[3],
                        SelfDefined[4],
                        SelfDefined[5],
                        SelfDefined[6],
                        SelfDefined[7],
                        SelfDefined[8],
                        SelfDefined[9],
                        SelfDefined[10],
                        SelfDefined[11],
                        SelfDefined[12],
                        SelfDefined[13],
                        SelfDefined[14],
                        SelfDefined[15],
                        SelfDefined[16],
                        SelfDefined[17],
                        SelfDefined[18]
                      ]}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.title}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Build your mnemonic here."
                          variant="outlined"
                          placeholder="Remove all the terms you do not want in the recovery mnemonic."
                          fullWidth
                        />
                      )}
                    />
                  </div>

                  <div className="mb-3 mt-3">
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mt-2 mb-2">
                        Set a date you will remember over all others.
                      </label>
                    </div>

                    <TextField
                      id="date"
                      label="Memorable Day"
                      type="date"
                      defaultValue="2001-01-01"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>

                  <Grid item md={12}>
                    <div className="d-flex justify-content-between">
                      <label className="font-weight-bold mt-3">
                        Select a 4-digit number
                      </label>
                    </div>
                    <Slider
                      defaultValue={2876}
                      getAriaValueText={valuetext}
                      valueLabelDisplay="auto"
                      step={1}
                      min={1000}
                      max={9999}
                      className="slider-first my-3"
                    />
                  </Grid>

                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
