import React from 'react';

import { PageTitle } from 'layout-components';
import { Grid } from '@material-ui/core';
import { ExampleWrapperSeamless } from 'layout-components';

import BlocksProgressHorizontal3 from '../../example-components/BlocksProgressHorizontal/BlocksProgressHorizontal3';
import BlocksProgressHorizontal5 from '../../example-components/BlocksProgressHorizontal/BlocksProgressHorizontal5';
import BlocksProgressHorizontal6 from '../../example-components/BlocksProgressHorizontal/BlocksProgressHorizontal6';
import BlocksProgressHorizontal8 from '../../example-components/BlocksProgressHorizontal/BlocksProgressHorizontal8';
export default function BlocksProgressHorizontal() {
  return (
    <>
      <PageTitle
        titleHeading="Web3Skills Course Progress"
        titleDescription="Courses are tracked by user participation in live events and completing curriculum requirements. Any information collected to this end is held in a secure database under the terms of use and privacy policy. https://www.airtable.com/security"
      />

      <ExampleWrapperSeamless>
        <BlocksProgressHorizontal8 />
      </ExampleWrapperSeamless>

      <ExampleWrapperSeamless>
        <BlocksProgressHorizontal3 />
      </ExampleWrapperSeamless>

      <Grid container spacing={6}>
        <Grid item lg={6}>
          <ExampleWrapperSeamless>
            <BlocksProgressHorizontal5 />
          </ExampleWrapperSeamless>
        </Grid>
        <Grid item lg={6}>
          <ExampleWrapperSeamless>
            <BlocksProgressHorizontal6 />
          </ExampleWrapperSeamless>
        </Grid>
      </Grid>


    </>
  );
}
