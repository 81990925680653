import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, Card, Button } from '@material-ui/core';

import particles1 from '../../../assets/images/hero-bg/particles-1.svg';
import logo1 from '../../../assets/images/achievements/web3vm-newb-1.png';
import logo2 from '../../../assets/images/achievements/account_maker-level_1.png';
import logo3 from '../../../assets/images/achievements/web3vm_user-level_1.png';
import logo4 from '../../../assets/images/achievements/transfer_tech-level_1.png';


import supersite05 from '../../../assets/images/supersite/supersite-demos-005.png';


import MarketingHeaders1 from '../../MarketingHeaders/MarketingHeaders1';

export default function LivePreviewExample() {
  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-plum-plate mb-5">
        <div className="w-100 px-4">
          <MarketingHeaders1 />
        </div>
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image opacity-4 bg-composed-filter-rm"
            style={{ backgroundImage: 'url(' + particles1 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-7" />
          <div className="bg-composed-wrapper--content">
            <Container className="z-over text-white text-center py-5">



            <Grid
                        item
                        lg={8}
                        className="d-none d-lg-flex object-skew align-items-center ml-5">
                        <img
                          alt="..."
                          className="w-70 mx-auto d-block img-fluid"
                          src={supersite05}
                        />
                      </Grid>




              <Grid item md={10} lg={8} className="mx-auto py-5">
                <h2 className="display-3 font-weight-bold text-white mt-5 ">
                  Typical Course Curriculum
                </h2>
                <p className="font-size-xl py-3 text-white-50">
                Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.MH16a
                </p>
                <div className="pt-3">
                  <Button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    size="large"
                    className="btn-pill shadow-second-sm btn-first">
                    <span className="btn-wrapper--label">Browse Course Achievement Tokens</span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </span>
                  </Button>
                  <Button
                    href="#/"
                    target="_blank" 
rel="noopener noreferrer"

                    size="large"
                    className="bg-white-10 text-white btn-pill ml-3">
                    <span>Course Documentation</span>
                  </Button>
                </div>
              </Grid>
              <Card className="text-left my-5 bg-white shadow-sm p-4">
                <Grid container spacing={0}>
                  <Grid item lg={6}>
                    <div className="d-flex p-4">
                      <div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
                        <div className="d-70">
                          <img
                            alt="..."
                            className="img-fit-container"
                            src={logo1}
                          />
                        </div>
                      </div>
                      <div className="pt-2 pl-4">
                        <a
                          href="#/"
                          target="_blank" 
rel="noopener noreferrer"

                          className="font-weight-bold d-flex align-items-center mb-2 d-flex">
                          <div className="font-size-lg">Newb Web3 Starter Course</div>
                          <div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                        </a>
                        <p className="mb-0 text-second opacity-7">
                        Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.MH16b
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={6}>
                    <div className="d-flex p-4">
                      <div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
                        <div className="d-70">
                          <img
                            alt="..."
                            className="img-fit-container"
                            src={logo2}
                          />
                        </div>
                      </div>
                      <div className="pt-2 pl-4">
                        <a
                          href="#/"
                          target="_blank" 
rel="noopener noreferrer"

                          className="font-weight-bold d-flex align-items-center mb-2 d-flex">
                          <div className="font-size-lg">Account Maker Course</div>
                          <div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                        </a>
                        <p className="mb-0 text-second opacity-7">
                        Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.MH16c
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={6}>
                    <div className="d-flex p-4">
                      <div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
                        <div className="d-70">
                          <img
                            alt="..."
                            className="img-fit-container"
                            src={logo3}
                          />
                        </div>
                      </div>
                      <div className="pt-2 pl-4">
                        <a
                          href="#/"
                          target="_blank" 
rel="noopener noreferrer"

                          className="font-weight-bold d-flex align-items-center mb-2 d-flex">
                          <div className="font-size-lg">Web3 User Course</div>
                          <div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                        </a>
                        <p className="mb-0 text-second opacity-7">
                        Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.MH16d
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={6}>
                    <div className="d-flex p-4">
                      <div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
                        <div className="d-70">
                          <img
                            alt="..."
                            className="img-fit-container"
                            src={logo4}
                          />
                        </div>
                      </div>
                      <div className="pt-2 pl-4">
                        <a
                          href="#/"
                          target="_blank" 
rel="noopener noreferrer"

                          className="font-weight-bold d-flex align-items-center mb-2 d-flex">
                          <div className="font-size-lg">Web3 Transfer Token Course</div>
                          <div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
                            <FontAwesomeIcon
                              icon={['fas', 'check']}
                              className="font-size-sm"
                            />
                          </div>
                        </a>
                        <p className="mb-0 text-second opacity-7">
                        Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.MH16d
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>



            </Container>
            <div className="shadow-container-blocks-2 z-below">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="var(--light)"
                  fillOpacity="1"
                  d="M0,32L120,58.7C240,85,480,139,720,138.7C960,139,1200,85,1320,58.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
