import React from 'react';

import { Container, Card } from '@material-ui/core';

import logo1 from '../../../assets/images/achievements/web3vm-newb-6.png';
import logo2 from '../../../assets/images/achievements/account_maker-level_6.png';
import logo3 from '../../../assets/images/achievements/ethereum-level_6.png';
import logo4 from '../../../assets/images/achievements/decentralizer-level_6.png';
import logo5 from '../../../assets/images/achievements/airdrop_surprise-level_6.png';
import logo6 from '../../../assets/images/achievements/web3vm_user-level_6.png';

import logo7 from '../../../assets/images/achievements/web3vm-newb-1.png';
import logo8 from '../../../assets/images/achievements/account_maker-level_1.png';
import logo9 from '../../../assets/images/achievements/ethereum-level_1.png';
import logo10 from '../../../assets/images/achievements/decentralizer-level_1.png';
import logo11 from '../../../assets/images/achievements/airdrop_surprise-level_1.png';
import logo12 from '../../../assets/images/achievements/web3vm_user-level_1.png';

export default function LivePreviewExample() {
  return (
    <>
          <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
          <h2 className="font-weight-bold mb-4">
          Web3Skills SmartLaw - Level 1 "Starter" Tokens
                </h2>
              <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.MP3b
              </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo7}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo8}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo9}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo10}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo11}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo12}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
            </div>
          </Container>
        </div>
      </Card>
      <Card className="mb-spacing-6-x2">
        <div className="py-5">
          <Container className="py-5">
          <h2 className="font-weight-bold mb-4">
                  Web3Skills SmartLaw - Level 6 "Master" Tokens
                </h2>
              <p className="text-black-50 font-size-lg">
              Web3Skills SmartLaw is a training tool and set of protocols to advance the adoption of Web3 technologies. Users can freely browse and use Web3 applications anonymously or with decentralized profiles that capture the spirit of the user. The core of the Web3Skills SmartLaw system is a ERC-20 token that was minted in 2020 at 0xA0fda74439F4b285517A5bBD594D79eBAD1D29FA and has lived a dormant life until now.MP3b
              </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo1}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo2}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo3}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo4}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo5}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
              <div className="rounded-circle btn-icon d-100 shadow-xxl m-4 hover-scale-lg d-flex align-self-stretch">
                <img
                  src={logo6}
                  style={{ height: 90 }}
                  className="m-auto img-fluid"
                  alt="..."
                />
              </div>
            </div>
          </Container>
        </div>
      </Card>

    </>
  );
}
