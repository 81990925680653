import React from 'react';

import { PageTitle } from 'layout-components';

import { ExampleWrapperSeamless } from 'layout-components';


import MarketingIconsWeb3Oppys from '../../example-components/Apps/MarketingIconsWeb3Oppys';

export default function Fedreg() {
  return (
    <>
      <PageTitle
        titleHeading="Regulations to be aware of in the USA"
        titleDescription="Typical claims under 18 USC, and 17 CFR that have been brought against digital token issuers the remedies available. 
        There have been several claims under 18 USC (United States Code) and 17 CFR (Code of Federal Regulations) that have been brought against digital token issuers in the United States. These claims can vary depending on the specific circumstances of each case. Here are some of the typical claims and remedies available:" 
      />

      <ExampleWrapperSeamless>
        <MarketingIconsWeb3Oppys />
      </ExampleWrapperSeamless>

    </>

  );
}
