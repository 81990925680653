import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, Card, Button } from '@material-ui/core';

import particles1 from '../../../assets/images/hero-bg/particles-1.svg';
import lexautomaticastory026100w from '../../../assets/images/lexautomatica/lexautomatica-story-026-100w.png';
import lexautomaticastory027100w from '../../../assets/images/lexautomatica/lexautomatica-story-027-100w.png';
import lexautomaticastory028100w from '../../../assets/images/lexautomatica/lexautomatica-story-028-100w.png';
import lexautomaticastory029100w from '../../../assets/images/lexautomatica/lexautomatica-story-029-100w.png';
import lexautomaticastory030100w from '../../../assets/images/lexautomatica/lexautomatica-story-030-100w.png';
import lexautomaticastory031100w from '../../../assets/images/lexautomatica/lexautomatica-story-031-100w.png';
import lexautomaticastory032100w from '../../../assets/images/lexautomatica/lexautomatica-story-032-100w.png';
import lexautomaticastory033100w from '../../../assets/images/lexautomatica/lexautomatica-story-033-100w.png';
import lexautomaticastory034100w from '../../../assets/images/lexautomatica/lexautomatica-story-034-100w.png';
import lexautomaticastory035100w from '../../../assets/images/lexautomatica/lexautomatica-story-035-100w.png';
import lexautomaticastory036100w from '../../../assets/images/lexautomatica/lexautomatica-story-036-100w.png';
import lexautomaticastory037100w from '../../../assets/images/lexautomatica/lexautomatica-story-037-100w.png';




import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';


import smartlawautomation from '../../../assets/images/logos/smartlaw-automation-w-1200.png';

import MarketingHeaders1 from '../../MarketingHeaders/MarketingHeaders1';

export default function LivePreviewExample() {
  return (
    <>



<div className="hero-wrapper bg-composed-wrapper bg-plum-plate mb-5">
<div className="w-100 px-4">
<MarketingHeaders1 />
</div>
<div className="hero-wrapper--content">
<div
className="bg-composed-wrapper--image opacity-4 bg-composed-filter-rm"
style={{ backgroundImage: 'url(' + particles1 + ')' }}
/>
<div className="bg-composed-wrapper--bg bg-second opacity-7" />
<div className="bg-composed-wrapper--content">
<Container className="z-over text-white text-center py-5">
<Grid
item
lg={8}
className="d-none d-lg-flex align-items-center ml-5">
<img
alt="smartlawautomation"
className="w-70 mx-auto d-block img-fluid"
src={smartlawautomation}
/>
</Grid>
<Grid item md={10} lg={8} className="mx-auto py-5">
<h2 className="display-3 font-weight-bold text-white mt-5 ">
Types of MCLE Credit
</h2>
<p className="font-size-xxl py-3 text-white-50">
Minimum Continuing Legal Education (MCLE) is classified under different categories, participatory or self-study credit.
</p>
<p className="font-size-xl py-3 text-white-50 mb-3">
The difference is generally whether you take a course in person or through electronic media with other participants, or if you study the material alone. However, many activities can qualify for either participatory or self-study credit.
</p>
<p className="font-size-xl py-3 text-white-50 mb-3">
Under the requirements, at least half of the 25 MCLE hours you report must be activities known as “participatory.” Also, you cannot take more than 12.5 hours of credit in “self-study” activities.
</p>
<div className="pt-3">
<Button
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/Requirements/Types-of-MCLE-Credit"
target="_blank"
rel="noopener noreferrer"
size="large"
className="btn-pill shadow-second-sm btn-first">
<span className="btn-wrapper--label">
California MCLE Requirements
</span>
<span className="btn-wrapper--icon">
<FontAwesomeIcon icon={['fas', 'arrow-right']} />
</span>
</Button>
<Button
href="https://tokens.freeweb3.com"
target="_blank"
rel="noopener noreferrer"
size="large"
className="bg-white-10 text-white btn-pill ml-3">
<span>Get Course Token</span>
</Button>
</div>
</Grid>
<hr />
<hr />
<hr />
<hr />
<hr />
<Card className="text-left my-5 bg-white shadow-sm p-4">
<Grid container spacing={0}>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory026100w"
className="d-100 rounded-lg"
src={lexautomaticastory026100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/Requirements/Types-of-MCLE-Credit"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
Participatory Credit
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
The provider can verify your participatory credit if:
</div>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
You sign in at the time of the activity (electronic sign-in is acceptable)
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
The provider keeps record of participants who signed in
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
The provider issues a certificate of attendance to participants
</p>
<p className="mb-0 text-second opacity-7">
Courses taken online or with the help of media such as CDs and tapes may also count towards participatory credit if the provider has been approved for it. Make sure to contact the provider beforehand to verify if participatory credit has been approved and if the information is still current.
</p>
</div>
</div>
</Grid>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory027100w"
className="d-100 rounded-lg"
src={lexautomaticastory027100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/Requirements/Types-of-MCLE-Credit"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
Self-study credit
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
The MCLE rules specify that some types of activities qualify for self-study credit.
</div>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
They include:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Studying online materials and taking an assessment test
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Preparing written materials for a law book or legal course
</p>
<p className="mb-0 text-second opacity-7">
See more detailed information on the specific MCLE rules that apply for each type of credit.
</p>
</div>
</div>
</Grid>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory028100w"
className="d-100 rounded-lg"
src={lexautomaticastory028100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/Requirements/Types-of-MCLE-Credit"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
Approval for credit
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
Attorney’s resposibilitiy to report MCLE hours.
</div>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Attorneys are responsible for making sure the MCLE hours they report are approved for credit by the State Bar or by another U.S. state or country that the bar has designated as an “approved jurisdiction.” See the list of approved jurisdictions.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
If the State Bar has not approved the provider, activity or jurisdiction, attorneys can complete and file a MCLE Credit Request Form to get approval for their attendance.
</p>
<div className="font-weight-bold display-5 p-2 mb-3">
For questions contact the State Bar’s MCLE Program at
</div>
<p className="text-left text-primary font-size-lg px-2 px-lg-1 mt-2 mb-2">
MCLE Program
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
The State Bar of California
<br/>
180 Howard St.
<br/>
San Francisco, CA 94105
<br/>
Email: MCLE@calbar.ca.gov
</p>
</div>
</div>
</Grid>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory029100w"
className="d-100 rounded-lg"
src={lexautomaticastory029100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/Requirements/Types-of-MCLE-Credit"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
Nonapproved activities
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
The State Bar does not approve the following activities:
</div>
<p className="text-left text-black font-size-md px-2 px-md-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Jury duty
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Grading the California Bar Examination
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Preparing for or taking a bar exam for admission in any jurisdiction [Rule 2.87]
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Acting as a judge pro tempore, mediator, arbitrator or settlement judge
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Acting as a supervising attorney in the State Bar Law Office Study Program (pursuant to Business and Professions Code section 6060(e)(3)(ii))
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Supervising, reviewing or evaluating the legal work of associates and other less experienced attorneys on a one-on-one basis (for example, a mentor program)
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Judging or participating in moot court competitions
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Performing educational activities on legal topics presented to non-lawyers, such as:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Taking correspondence courses
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1">
<span className="sidebar-icon text-danger"><ChevronRightTwoToneIcon /></span>
Taking general computer, acting and writing courses that are not specifically for legal applications teaching business law at a community college or a university reading (advance sheets, books or articles)
</p>
<p className="mb-0 text-second opacity-7">
x
</p>
</div>
</div>
</Grid>
</Grid>
</Card>
<hr />
<hr />
<hr />
<hr />
<hr />
<Card className="text-left my-5 bg-white shadow-sm p-4">
<Grid container spacing={0}>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory030100w"
className="d-100 rounded-lg"
src={lexautomaticastory030100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Single-Activity-Providers"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
MCLE Single Activity Provider
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
Becoming a California MCLE Single Activity Provider
</div>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
What is an MCLE Single Activity Provider?
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
A Single Activity Provider (SAP) is an entity approved to hold and grant credit for a single Minimum Continuing Legal Education (MCLE) activity during a two-year period, provided that there are no changes made to the program.
</p>
<p className="text-left text-black font-weight-bold  font-size-md px-2 px-lg-1 mt-2 mb-2">
To be approved for MCLE credit, an MCLE activity must meet the following State Bar standards:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
The activity must relate to legal subjects directly relevant to licensees of the State Bar or have significant current professional and practical content.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
The presenter of the MCLE activity must have significant professional or academic experience related to its content.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Promotional material must state that the activity is approved for MCLE credit or that a request for approval is pending; specify the amount of credit offered; and indicate whether any of the credit may be claimed for required MCLE in legal ethics, elimination of bias, or competence issues.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
If the activity lasts one hour or more, the provider must make substantive written materials relevant to the MCLE activity available either before or during the activity. Any materials provided online must remain online for at least 30 calendar days following the MCLE activity.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Programs and classes must be scheduled so that participants are free of interruptions.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
On and after January 1, 2022, for all training dealing with, or including a component dealing with, implicit bias and the promotion of bias-reducing strategies, an MCLE provider must meet the requirements of Business and Professions Code section 6070.5.
</p>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon font-weight-bold text-primary"><ChevronRightTwoToneIcon /></span>
Applying for SAP status
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Providers interested in becoming an SAP in California must first complete and submit a Single Activity Provider application for review, for each activity they plan to provide, along with the $90 processing fee* for each application. When approved, the provider may either offer the approved single activity once or multiple times, provided that there are no changes made to the program, during a two-year period.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Providers who plan to offer on-demand, multiday or multitrack, subscription-based, or bundled MCLE activities must submit a separate SAP application along with the associated processing fee* for each activity offered as part of that service. Applications listing multiple activities, multitrack programs, or multiday activities will be deemed insufficient and returned to the provider.
</p>
</div>
</div>
</Grid>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory031100w"
className="d-100 rounded-lg"
src={lexautomaticastory031100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Single-Activity-Providers"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
MCLE Single Activity Provider (cont.)
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
Becoming a California MCLE Single Activity Provider (cont.)
</div>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
In addition to the application and the associated processing fee, the following supporting materials are required and must be submitted with the application, otherwise the application will be deemed incomplete and returned to the provider:
</p>
<p className="text-left text-black font-weight-bold  font-size-md px-2 px-lg-1 mt-2 mb-2">
Agenda: The proposed agenda must include the title of the activity and its start and end time. The proposed agenda must also include a time breakdown for each topic the activity covers, along with a brief description for each topic.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Biographies: Each speaker’s biography must contain their professional credentials, including all relevant work experience and academic achievements. A resumé may be submitted as a biography.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Written materials: If an activity is one hour or more in length, substantive written materials are required and must be included with the application. The required materials must include all syllabi and/or handouts that are made available to all attendees before or during the activity. Written materials must discuss the content and/or legal authorities discussed during the MCLE activity. A PowerPoint presentation may be submitted as written material.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Promotional materials: If retroactive approval for an activity that has been offered is being sought, the promotional materials used for the activity must be submitted with the application.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Number of credits: The total number of hour(s) of credit intending to be issued for the activity must be indicated. The total amount of credit that is being sought must correspond with the times listed on the activity’s agenda as submitted. Please note, credit for breaks or lunch is not permitted.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Signature: The application must be signed in the appropriate fields. Failure to sign the application will delay processing.
</p>
<p className="mb-0 text-second opacity-7">
* Please note that the $90 application processing fee is nonrefundable and cannot be waived.
</p>
</div>
</div>
</Grid>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory032100w"
className="d-100 rounded-lg"
src={lexautomaticastory032100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Single-Activity-Providers"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
MCLE Single Activity Provider (cont.)
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
Becoming a California MCLE Single Activity Provider (cont.)
</div>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
Additional tips for completing a Single Activity Provider application:
</p>
<p className="text-left text-black font-weight-bold  font-size-md px-2 px-lg-1 mt-2 mb-2">
The name of the activity should match the activity name field found on the application.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
If there is not a prospective date for the activity, “to be determined” should be inserted in the prospective date field. Please note that if approved, the program or activity has a two-year approval period and may be offered at any time during the approval period, as long as it is presented identically each time. If the date of the activity is not indicated, the approval period will begin as of the date the application is received.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Only list one contact person.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Customized Record of Attendance, Certificate of Attendance, and Evaluation Forms may be created. Each form must include the same fields found on the samples available on the State Bar’s Forms webpage. Samples of the customized forms must be included with the application.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
If submitting by mail, do not staple or bind the application or any of the required documents.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Do not include originals of the Record of Attendance and Evaluation Forms with the application. The original forms must be maintained by the provider in the event the State Bar requests copies of those records.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Biographies and written materials may be submitted on a flash drive.
</p>
<p className="font-weight-bold mb-3 text-primary opacity-9">
Once an application is received complete, the review process is between four to six weeks and cannot be expedited. During that time, the provider may be contacted if additional information or documentation is needed. Upon conclusion of the review process, a determination letter will be emailed to the provider, to the contact person listed on the application.
</p>
<p className="font-weight-bold mt-3 text-primary opacity-9">
A provider may submit their application before or after the activity has taken place but may not offer or grant participants credit for the activity until they have been notified by the State Bar that their activity has been approved. If approval is not received before the activity date, the promotional materials must state that the activity is pending approval from the State Bar of California. Promotional materials must also state the number of credit hours along with the type of credit pending State Bar approval. The provider may take attendance on the State Bar’s Record of Attendance and hand out State Bar Evaluation Forms, but Certificates of Attendance may not be issued until the activity is approved and the provider has received an approval letter from the State Bar.
</p>
</div>
</div>
</Grid>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory033100w"
className="d-100 rounded-lg"
src={lexautomaticastory033100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Single-Activity-Providers"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
MCLE Single Activity Provider (cont.)
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
Becoming a California MCLE Single Activity Provider (cont.)
</div>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
Additional tips for completing a Single Activity Provider application:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
The name of the activity should match the activity name field found on the application.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
If there is not a prospective date for the activity, “to be determined” should be inserted in the prospective date field. Please note that if approved, the program or activity has a two-year approval period and may be offered at any time during the approval period, as long as it is presented identically each time. If the date of the activity is not indicated, the approval period will begin as of the date the application is received.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Only list one contact person.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Customized Record of Attendance, Certificate of Attendance, and Evaluation Forms may be created. Each form must include the same fields found on the samples available on the State Bar’s Forms webpage. Samples of the customized forms must be included with the application.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
If submitting by mail, do not staple or bind the application or any of the required documents.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Do not include originals of the Record of Attendance and Evaluation Forms with the application. The original forms must be maintained by the provider in the event the State Bar requests copies of those records.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Biographies and written materials may be submitted on a flash drive.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Once an application is received complete, the review process is between four to six weeks and cannot be expedited. During that time, the provider may be contacted if additional information or documentation is needed. Upon conclusion of the review process, a determination letter will be emailed to the provider, to the contact person listed on the application.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
A provider may submit their application before or after the activity has taken place but may not offer or grant participants credit for the activity until they have been notified by the State Bar that their activity has been approved. If approval is not received before the activity date, the promotional materials must state that the activity is pending approval from the State Bar of California. Promotional materials must also state the number of credit hours along with the type of credit pending State Bar approval. The provider may take attendance on the State Bar’s Record of Attendance and hand out State Bar Evaluation Forms, but Certificates of Attendance may not be issued until the activity is approved and the provider has received an approval letter from the State Bar.
</p>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
Application submittal information
</p>
<p className="mb-3 text-black opacity-7">
Although electronic submittals are preferred, providers may either send their Single Activity Provider Application via email or via regular mail. If emailed, a completed and signed Credit Card Authorization Form must be included with the application and sent to: providers@calbar.ca.gov.
</p>
<p className="font-weight-bold mb-3 text-black opacity-7">
If sent via U.S. mail, please include either a check in the amount of $90, made payable to The State Bar of California, or a completed and signed Credit Card Authorization Form with the application and send it to:
</p>
<p className="text-left font-weight-bold text-primary font-size-xl px-2 px-lg-1 mt-2 mb-2">
The State Bar of California
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
MCLE Providers
<br/>
180 Howard St.
<br/>
San Francisco, CA 94105
<br/>
Email: MCLE@calbar.ca.gov
</p>
</div>
</div>
</Grid>
</Grid>
</Card>
<hr />
<hr />
<hr />
<hr />
<hr />
<Card className="text-left my-5 bg-white shadow-sm p-4">
<Grid container spacing={0}>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory034100w"
className="d-100 rounded-lg"
src={lexautomaticastory034100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Multiple-Activity-Providers"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
Becoming a California MCLE Multiple Activity Provider
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
What is an MCLE Multiple Activity Provider?
</div>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
A Multiple Activity Provider (MAP) is an approved provider that is given authority to offer and grant credit for any Minimum Continuing Legal Education (MCLE) activity that complies with the terms of the MAP agreement, without having to submit separate applications requesting approval for each program offered.
</p>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
To be considered for a MAP status, within a two-year period a provider must:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Receive State Bar approval for four different MCLE single activity programs.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Ensure that the four approved MCLE activities are each different and not repeat presentations of the same program.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Hold each of the MCLE activities on four different dates.
</p>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
Which providers would benefit from MAP status?
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Unlike a Single Activity Provider (SAP), a provider holding MAP status is allowed to present new activities and grant California MCLE credit without first having to submit an SAP application for each new activity during their three-year approval period. As such, providers who hold several different educational activities would benefit from MAP status. In addition, as an MAP, providers have the flexibility to change activity length or activity content and are allowed to determine California MCLE credits to be offered based on the California MCLE Rules.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Providers who think that they may be interested in becoming an MAP in the future should maintain copies of the Notification of Approval letters they receive from the State Bar for each of their qualifying single educational activities, as they will be required to include a copy of these with their Multiple Activity Provider application.
</p>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
Applying for MAP status
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Providers who meet the above qualifying criteria may apply for MAP status by submitting a Multiple Activity Provider application. The MAP application fee is $360.* Once approved, a provider’s MAP status runs on a three-year renewal cycle, expiring on June 30 at the end of the cycle.
</p>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
In addition to the application and the associated processing fee, the following supporting materials are required and must be submitted with the application, otherwise, the application will be deemed incomplete and returned:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Notice of Approval letters: Copies of Notification of Approval letters received from the State Bar for each of the four qualifying single educational activities.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Records of Attendance for each of the four activities must include the title of the MCLE activity, date, total hours awarded, any credits awarded for legal ethics, elimination of bias, implicit bias, or competence issues as a component of the activity, whether the activity is participatory or self-study, and the name and State Bar number of each California licensee.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Promotional material: Copies of printed or electronic promotional materials for each of the four activities. The materials must state that the activity is approved for MCLE credit or that a request for approval is pending; specify the amount of credit offered and indicate whether any of the credit may be claimed for required MCLE in legal ethics, elimination of bias, implicit bias, or competence issues.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Signature: The application must be signed in the appropriate fields. Failure to sign the application will delay processing.
</p>
<p className="font-weight-bold mb-3 text-primary opacity-9">
* Please note that the $360 application processing fee is nonrefundable and cannot be waived.
</p>
</div>
</div>
</Grid>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory035100w"
className="d-100 rounded-lg"
src={lexautomaticastory035100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Single-Activity-Providers"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
Becoming a California MCLE Multiple Activity Provider (cont)
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
MAP status renewal
</div>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
At the end of the three-year cycle, an approved MAP provider may submit a request to renew their MAP status for another three-year period.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
To be eligible for renewal, a provider must submit evidence that it has offered four different MCLE activities that meet the requirements of the MCLE rules within the two years preceding the Multiple Activity Provider Renewal Application.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
The provider must submit their renewal application along with the associated processing fee of $360* on or before the date that their MAP status expires. Failure to do so will result in expiration of the provider’s MAP status. Once expired, the provider will no longer be eligible to renew their status and must re-apply to become a MAP.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
In addition to the timely submittal of the Multiple Activity Provider Renewal Application and the associated processing fee, the following supporting materials for four separate activities are required and must be submitted with the application on or before the deadline set by the State Bar, otherwise, the application will be deemed incomplete and returned to the provider:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Agenda: The agenda for each of the four activities must include the title of the activity and its start and end time. All sessions for which MCLE credit is given must be adequately identified, described, and counted. Credit for concurrent MCLE sessions cannot be counted more than once, because licensees are not able to attend more than one session at a time.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Written materials: Substantive written materials are required for each activity one hour or more in length. The required materials must include all syllabi and/or handouts that were made available to all attendees before or during the activity and must discuss the content and/or legal authorities discussed during the MCLE activity. A PowerPoint presentation may be submitted as written material.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Records of Attendance for each of the activities must include the title of the MCLE activity, date, total hours awarded, any credits awarded for legal ethics, elimination of bias, implicit bias, or competence issues as a component of the activity, and whether the activity is participatory or self-study. For activities or programs offered during half-day or multi-day events, each session and its credit must be identified so that the total number of hours of MCLE credit given can be verified.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Promotional materials: Promotional materials must state that the activity is approved for MCLE credit or that a request for approval is pending; adequately identify, describe, and specify the amount of credit offered and indicate whether any of the credit may be claimed for required MCLE in legal ethics, elimination of bias, implicit bias, or competence issues.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Please note that the MCLE Provider Rules also require that providers seeking to renew their MAP status disclose and submit complaints they have received, if any, regarding their compliance with said rules, along with their MAP renewal application.
</p>
<p className="mb-3 text-black opacity-7">
* Please note that the $360 application processing fee is nonrefundable and cannot be waived.
</p>
<p className="text-left text-black font-size-lg font-weight-bold px-2 px-lg-1 mt-2 mb-2">
Application information
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Regardless of the type of application, once received complete, the review process is between four to six weeks and cannot be expedited. During that time, the provider may be contacted if additional information or documentation is needed. Upon conclusion of the review process, a determination letter will be emailed to the provider, to the contact person listed on the application.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
Providers interested in becoming a MAP must submit their request for an application in writing and send it via email to: providers@calbar.ca.gov.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
Although electronic submittals are preferred, providers may also submit their Multiple Activity Provider Renewal Application via U.S. mail along with either a check in the amount of $360, made payable to ‘The State Bar of California’ or a completed and signed Credit Card Authorization Form with the application and send it to:
</p>
<p className="text-left font-weight-bold text-primary font-size-xl px-2 px-lg-1 mt-2 mb-2">
The State Bar of California
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
MCLE Providers
<br/>
180 Howard St.
<br/>
San Francisco, CA 94105
<br/>
Email: MCLE@calbar.ca.gov
</p>
<p className="mb-3 text-black opacity-7">
To avoid lapse in status, MAP renewal applications are due on or before the date that a provider’s MAP status is set to expire.
</p>
</div>
</div>
</Grid>
</Grid>
</Card>
<hr />
<hr />
<hr />
<hr />
<hr />
<Card className="text-left my-5 bg-white shadow-sm p-4">
<Grid container spacing={0}>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory036100w"
className="d-100 rounded-lg"
src={lexautomaticastory036100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Provider-Record-Keeping"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
Provider Record Keeping
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
Recordkeeping
</div>
<p className="text-left text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
Part of a provider’s responsibilities include keeping track of the attorneys who participate in a Minimum Continuing Legal Education (MCLE) activity and the certificates they issue to verify attendance.
</p>
<p className="text-left font-weight-bold text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
Providers need to keep these types of MCLE records:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Records of attendance: Providers must take attendance using the State Bar’s record of attendance form. Attorneys must sign in with their name, bar number and signature. For electronically transmitted activities, a signature is not required.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Evaluation form: Providers must make evaluation forms available to all attorneys. If any forms are filled out and returned, you must keep them for one year from the date the program is held.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
Certificate of attendance: Providers must issue a certificate of attendance to each attendee who completed a participatory MCLE activity. Preferably, the top portion of the certificates of attendance must be preprinted with provider information and details about the activity. Providers do not need to keep copies of certificates of attendance.
X
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
Providers must keep all records, including the records of attendance, agendas, copies of written materials and promotional materials (if any) for four years from the date the program is held.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
Providers may create and use their own records of attendance, certificates of attendance and/or evaluation forms. Each form must include the same fields found on the samples. Providers should not send any records to the State Bar unless specifically requested to do so.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
<span className="sidebar-icon text-primary"><ChevronRightTwoToneIcon /></span>
If the State Bar audits an attorney, we may request the copies of his/her certificates. To verify that the attorney’s name is on a provider’s record of attendance, an auditor will look at the certificate to find provider contact information. You may be contacted if there is a question.
</p>
</div>
</div>
</Grid>
<Grid item lg={6}>
<div className="d-flex p-4">
<div className="d-80 flex-shrink-0 rounded-circle btn-icon bg-primary">
<div className="rounded-circle text-white d-100">
<img
alt="lexautomaticastory037100w"
className="d-100 rounded-lg"
src={lexautomaticastory037100w}
/>
</div>
</div>
<div className="pt-2 pl-4">
<a
href="https://www.calbar.ca.gov/Attorneys/MCLE-CLE/MCLE-Providers/Provider-Record-Keeping"
target="_blank"
rel="noopener noreferrer"
className="font-weight-bold d-flex align-items-center mb-2 d-flex">
<div className="font-size-lg">
Provider Record Keeping (cont.)
</div>
<div className="d-30 rounded-pill btn-icon bg-neutral-primary font-size-xs text-primary ml-2">
<FontAwesomeIcon
icon={['fas', 'check']}
className="font-size-sm"
/>
</div>
</a>
<div className="font-weight-bold display-5 p-2 mb-3">
Advertising an MCLE activity
</div>
<p className="text-left font-weight-bold text-black font-size-lg px-2 px-lg-1 mt-2 mb-2">
Providers also need to understand the requirements regarding listing or advertising an MCLE activity. Here are some rules:
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
A provider cannot advertise that a program is MCLE-approved until it receives a statement from the State Bar about an application or program. A provider may state that an activity is “pending approval” if it advertises after submitting an application is submitted and before a decision is made
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
Rule 3.601 (C) requires that promotional materials state that the activity is approved or approval is pending, specify the amount of credit or subfield credit
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
If an individual education program is denied, the provider must immediately delete any MCLE statement from all materials to be distributed after the denial and inform participants of the denial at the beginning of the program
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
In-house promotional materials must contain the information required by Rule 3.601 (C) (that the activity is approved or approval is pending, the amount of credit offered and the amount of subfield credit, if any). However, not all in-house materials must contain the approval statement, for example, a reminder memorandum does not have to contain this information.
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
Providers are strongly discouraged from co-sponsoring MCLE activities. If you need to make any changes to the provider’s name, address or contact information, contact the State Bar at:
</p>
<p className="text-left font-weight-bold text-primary font-size-xl px-2 px-lg-1 mt-2 mb-2">
The State Bar of California
</p>
<p className="text-left text-black font-size-md px-2 px-lg-1 mt-2 mb-2">
MCLE Providers
<br/>
180 Howard St.
<br/>
San Francisco, CA 94105
<br/>
Email: providers@calbar.ca.gov
</p>
</div>
</div>
</Grid>
</Grid>
</Card>
<hr />
<hr />
<hr />
<hr />
<hr />
</Container>
<div className="shadow-container-blocks-2 z-below">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
<path
fill="var(--light)"
fillOpacity="1"
d="M0,32L120,58.7C240,85,480,139,720,138.7C960,139,1200,85,1320,58.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
</svg>
</div>
</div>
</div>
</div>


    </>
  );
}
